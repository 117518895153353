import { connect } from 'react-redux';
import Permission from '../../../../permission.config';

const ShowForPermissionComponent = ({ permissionKey, role, children }) => {
    return Permission[permissionKey]?.includes(role) ? children : null;
};

function mapStateToProps (state) {
    return {
        role: state.auth.role
    };
}
const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);

export default ShowForPermission;
