import { Table } from 'antd';
import moment from 'moment';
import Button from '@atlaskit/button';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import departmentService from './services/department.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';
function DepartmentTable ({ role, refetch, hierarchy, reset, setIsEdit, setValue, parentDepartments, departmentHeads }) {
    const customSwal = Swal.mixin({
        customClass: {
            cancelButton: 'btn btn-outline-danger',
            confirmButton: 'btn btn-primary ml-2'
        },
        buttonsStyling: false
    });
    const handleDelete = (code) => {
        customSwal
            .fire({
                title: 'Delete' + ' ' + code + ' ?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                reverseButtons: true
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await departmentService.deleteDepartment(code);
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: 'Delete successfully!'
                        });
                        refetch();
                    } catch (error) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
                        });
                    }
                }
            });
    };
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Tên phòng ban',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Ngày thành lập',
            dataIndex: 'startedOn',
            key: 'startedOn',
            render: (item) => item && moment.utc(item).format('DD/MM/YYYY')
        },
        {
            title: 'Người lãnh đạo',
            dataIndex: 'departmentHead',
            key: 'departmentHead',
            render: (item) => item && item?.firstname + ' ' + item?.lastname
        },
        {
            title: 'Phòng ban cấp trên',
            dataIndex: 'parentDepartmentId',
            key: 'parentDepartmentId',
            render: (item) => item?.name
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) => {
                return (
                    record?.name &&
                    <div>
                        <Button className='mr-2'
                            data-toggle="modal"
                            onClick={ () => {
                                reset();
                                setIsEdit(record?.code);
                                setValue('startedOn', new Date(moment(record?.startedOn).format('MM/DD/YYYY')));
                                setValue('parentDepartmentId', parentDepartments.filter((parent) => parent.value === record?.parentDepartmentId?._id));
                                setValue('departmentHead', departmentHeads.filter(dpH => dpH.value === record?.departmentHead?._id));
                                setValue('name', record?.name);
                                setValue('code', record?.code);
                            }}
                            data-target={'#edit-department'}
                        >
                            <i className="fa fa-edit"></i>
                        </Button>
                        <Button onClick={() => handleDelete(record?.code)}>
                            <i className="far fa-trash-alt" aria-hidden="true"></i>
                        </Button>
                    </div>
                );
            }
        }
    ];

    const addKeys = (item, baseKey) => {
        item.key = baseKey;
        if (item.children && item.children.length) {
            item.children = item.children.map((child, index) => addKeys(child, baseKey * 10 + (index + 1)));
        }
        return item;
    };
    const transformedData = {
        key: 1,
        ...hierarchy,
        children: hierarchy?.children?.map((child, index) => addKeys(child, 11 + index))
    };
    return (
        <div>
            <Table
                className='hierarchy-table'
                columns={columns}
                dataSource={[transformedData]}
            />
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(DepartmentTable);
