import './PersonalForm.css';
import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { differenceInDays } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
// import MyForm from './From';

export default function PerSonalForm ({
    isNew,
    selectedEmployee,
    closeModal,
    refetch,
    hookForm,
    handleUpdateEmployee,
    handleCreateEmployee,
    setSelectedEmployee,
    internshipType,
    isLoading
}) {
    useEffect(() => {
        refetch();
    }, [selectedEmployee]);

    const selectStyle = {
        control: base => ({
            ...base,
            height: '56px',
            borderColor: hookForm.formState.errors.status ? 'red' : '#ced4da',
            borderRadius: '0.3rem'
        })
    };
    // format
    useEffect(() => {
        const joinedDate = hookForm.getValues('joinedDate');
        const today = new Date();

        if (joinedDate) {
            const workingDays = differenceInDays(today, new Date(joinedDate));

            let formattedDays;
            if (workingDays < 365) {
                formattedDays = `${workingDays} days`;
            } else {
                const years = Math.floor(workingDays / 365);
                const days = workingDays % 365;
                formattedDays = `${years} year${years > 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''}`;
            }

            hookForm.setValue('working_date', formattedDays);
        }
    }, [hookForm.watch('joinedDate')]);

    const { fields, append, remove } = useFieldArray({
        control: hookForm.control,
        name: 'bank_info'
    });

    return (
        <>
            {isLoading
                ? <div>
                    <Skeleton height={40} count={10} />
                </div>
                : <form
                className="container-fluid"
                onSubmit={hookForm.handleSubmit(
                    isNew ? handleCreateEmployee : handleUpdateEmployee
                )}
            >
                <div className="form-horizontal">
                    <div className="form-group row">
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label={
                                    <FormattedMessage id="HRM.detail.HR.official.label.mobile" />
                                }
                                {...hookForm.register('mobile', {
                                    pattern: {
                                        value: /^(?!\s+$)[0-9(),  ]+$/gi,
                                        message: TranslateHelper.getMessage(
                                            'validation.error.numberic'
                                        )
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: TranslateHelper.getMessage(
                                            'validation.error.notMobilePhone'
                                        )
                                    },
                                    minLength: {
                                        value: 9,
                                        message: TranslateHelper.getMessage(
                                            'validation.error.notMobilePhone'
                                        )
                                    }
                                })}
                                fullWidth
                                type="tel"
                                error={hookForm.formState.errors.mobile}
                                helperText={
                                    hookForm.formState.errors.mobile
                                        ?.message
                                }
                            />
                        </div>
                        <div className="col-sm-6 day_picker-cs">
                            <label>Day of birth</label>
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors
                                                .birthDay
                                                ? 'is-invalid form-control  p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Day of birth"
                                        popperPlacement="right-end"
                                    />
                                )}
                                name="birthDay"
                            />
                            {hookForm.formState.errors.birthDay && (
                                <span className="error text-danger">
                                    {
                                        hookForm.formState.errors.birthDay
                                            .message
                                    }
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <TextField
                            variant="outlined"
                            label={
                                <FormattedMessage id="HRM.detail.HR.official.label.address" />
                            }
                            {...hookForm.register('address', {
                                maxLength: {
                                    value: 250,
                                    message: TranslateHelper.getMessage(
                                        'validation.error.max250characters'
                                    )
                                }
                            })}
                            fullWidth
                            type="tel"
                            error={hookForm.formState.errors.address}
                            helperText={
                                hookForm.formState.errors.address?.message
                            }
                        />
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="Tax Code"
                                {...hookForm.register('tax_code', {})}
                                fullWidth
                                type="tel"
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="Temporary Address  "
                                {...hookForm.register(
                                    'temporary_address',
                                    {}
                                )}
                                fullWidth
                                type="tel"
                            />
                        </div>
                    </div>
                    {/* ACCOUNT BANK  */}
                    <h5 className=" text-gray   mb-4  font-weight-bold  ">
                        Bank Info
                    </h5>
                    {fields.map((item, index) => (
                        <div
                            className="row personal_form--bank-account"
                            key={item.id}
                        >
                            <div className="col-sm-4">
                                <TextField
                                    variant="outlined"
                                    label="Bank Account"
                                    {...hookForm.register(
                                        `bank_info.${index}.account`
                                    )}
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4">
                                <TextField
                                    variant="outlined"
                                    label="Bank Name"
                                    {...hookForm.register(
                                        `bank_info.${index}.bank_name`
                                    )}
                                    fullWidth
                                />
                            </div>
                            <div className="col-sm-4">
                                <TextField
                                    variant="outlined"
                                    label="Bank Branch"
                                    {...hookForm.register(
                                        `bank_info.${index}.bank_branch`
                                    )}
                                    fullWidth
                                />
                            </div>
                            <div className="personal_form--btn-minus">
                                <button
                                    type="button"
                                    onClick={() => remove(index)}
                                >
                                    <i className="fa-solid fa-minus"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="row mb-3">
                        <div className="col-12 text-end personal_form--btn-add">
                            <button
                                type="button"
                                onClick={() =>
                                    append({
                                        account: '',
                                        bank_name: '',
                                        bank_branch: ''
                                    })
                                }
                            >
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {/*  */}
                    <div className="form-group row">
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="BHXH Code"
                                {...hookForm.register('bhxh_code', {})}
                                fullWidth
                                error={hookForm.formState.errors.bhxh_code}
                                helperText={
                                    hookForm.formState.errors.bhxh_code
                                        ?.message
                                }
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="Contract Number"
                                {...hookForm.register('contract_no', {})}
                                fullWidth
                                type="tel"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-4 day_picker-cs">
                            <label>Contract 1 Date</label>
                            <Controller
                                control={hookForm.control}
                                name="contract_1_date"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.contract_1_date
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Contract 1 Date"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.contract_1_date && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.contract_1_date.message}
                                </span>
                            )}
                        </div>
                        <div className="col-sm-4 day_picker-cs">
                            <label>Contract 2 Date</label>
                            <Controller
                                control={hookForm.control}
                                name="contract_2_date"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.contract_2_date
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Contract 2 Date"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.contract_1_date && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.contract_1_date.message}
                                </span>
                            )}
                        </div><div className="col-sm-4 day_picker-cs">
                            <label>Contract 3 Date</label>
                            <Controller
                                control={hookForm.control}
                                name="contract_3_date"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.contract_3_date
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Contract 3 Date"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.contract_3_date && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.contract_3_date.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="Contract Sign Times"
                                {...hookForm.register(
                                    'contract_sign_times',
                                    {}
                                )}
                                fullWidth
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextField
                                variant="outlined"
                                label="Level"
                                {...hookForm.register('level_title', {})}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-4 day_picker-cs">
                            <label>Last Working Day</label>
                            <Controller
                                control={hookForm.control}
                                name="last_working_date"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.last_working_date
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Last Working Day"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.last_working_date && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.last_working_date.message}
                                </span>
                            )}
                        </div>
                        <div className="col-sm-4 day_picker-cs">
                            <label>Working Day</label>
                            <Controller
                                control={hookForm.control}
                                name="working_date"
                                render={({ field: { value, ref } }) => (
                                    <input
                                        type="text"
                                        ref={ref}
                                        value={value || ''}
                                        className={
                                            hookForm.formState.errors.working_date
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control p-2'
                                        }
                                        placeholder="Working Day"
                                        readOnly
                                        style={{ height: '55px' }}
                                    />
                                )}
                            />
                            {hookForm.formState.errors.working_date && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.working_date.message}
                                </span>
                            )}
                        </div>

                    </div>
                    {/*  */}
                    <div className="form-horizontal  row">
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="CV "
                                    />
                                )}
                                name="is_cv"
                            />
                            {hookForm.formState.errors.is_cv && (
                                <span className="error text-danger">
                                    <FormattedMessage id="validation.error.select" />
                                </span>
                            )}
                        </div>
                        {/*  */}
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="SYLL "
                                    />
                                )}
                                name="is_syll"
                            />
                            {hookForm.formState.errors.is_syll && (
                                <span className="error text-danger">
                                    <FormattedMessage id="validation.error.select" />
                                </span>
                            )}
                        </div>
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="Registration book"
                                    />
                                )}
                                name="is_registration_book"
                            />
                            {hookForm.formState.errors
                                .is_registration_book && (
                                    <span className="error text-danger">
                                        <FormattedMessage id="validation.error.select" />
                                    </span>
                            )}
                        </div>
                    </div>
                    <div className="form-horizontal  row">
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="Job Application"
                                    />
                                )}
                                name="is_job_application"
                            />
                            {hookForm.formState.errors
                                .is_job_application && (
                                    <span className="error text-danger">
                                        <FormattedMessage id="validation.error.select" />
                                    </span>
                            )}
                        </div>
                        {/*  */}
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="Degree"
                                    />
                                )}
                                name="is_degree"
                            />
                            {hookForm.formState.errors.is_degree && (
                                <span className="error text-danger">
                                    <FormattedMessage id="validation.error.select" />
                                </span>
                            )}
                        </div>
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="Health Certification"
                                    />
                                )}
                                name="is_health_certification"
                            />
                            {hookForm.formState.errors
                                .is_health_certification && (
                                    <span className="error text-danger">
                                        <FormattedMessage id="validation.error.select" />
                                    </span>
                            )}
                        </div>
                    </div>
                    <div className="form-horizontal row">
                        <div className="form-group col sm-4">
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        }
                                        label="Portrait"
                                    />
                                )}
                                name="is_portrait"
                            />
                            {hookForm.formState.errors.is_portrait && (
                                <span className="error text-danger">
                                    <FormattedMessage id="validation.error.select" />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-4">
                            <TextField
                                variant="outlined"
                                label="CMND/CCCD "
                                {...hookForm.register('identify_id', {})}
                                fullWidth
                                type="tel"
                            />
                        </div>
                        <div className="col-sm-4 day_picker-cs">
                            <label>CMND / CCCD issued date</label>
                            <Controller
                                control={hookForm.control}
                                render={({
                                    field: { onChange, value, ref }
                                }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value}
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors
                                                .identify_issued_date
                                                ? 'is-invalid form-control  p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="CMND / CCCD issued date"
                                        popperPlacement="right-end"
                                    />
                                )}
                                name="identify_issued_date"
                            />
                            {hookForm.formState.errors
                                .dentify_issued_date && (
                                    <span className="error text-danger">
                                        {
                                            hookForm.formState.errors
                                                .dentify_issued_date.message
                                        }
                                    </span>
                            )}
                        </div>
                        <div className="col-sm-4">
                            <TextField
                                variant="outlined"
                                label="CMND / CCCD issued by"
                                {...hookForm.register(
                                    'identify_issued_by',
                                    {}
                                )}
                                fullWidth
                                type="tel"
                                error={hookForm.formState.errors.mobile}
                                helperText={
                                    hookForm.formState.errors.mobile
                                        ?.message
                                }
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-sm-4 day_picker-cs">
                            <label>Contract Ended Day</label>
                            <Controller
                                control={hookForm.control}
                                name="contractEndedDay"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null} // Để trống nếu giá trị là null
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.contractEndedDay
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Contract Ended Day"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.contractEndedDay && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.contractEndedDay.message}
                                </span>
                            )}
                        </div>

                        <div className="col-sm-4 day_picker-cs">
                            <label>Joined Date</label>
                            <Controller
                                control={hookForm.control}
                                name="joinedDate"
                                render={({ field: { onChange, value, ref } }) => (
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        ref={ref}
                                        selected={value ? new Date(value) : null} // Để trống nếu giá trị là null
                                        onChange={onChange}
                                        className={
                                            hookForm.formState.errors.contractEndedDay
                                                ? 'is-invalid form-control p-2'
                                                : 'form-control'
                                        }
                                        placeholderText="Joined Date"
                                        popperPlacement="right-end"
                                    />
                                )}
                            />
                            {hookForm.formState.errors.joinedDate && (
                                <span className="error text-danger">
                                    {hookForm.formState.errors.joinedDate.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <h5 className=" text-gray mb-4 mt-4 font-weight-bold">
                            Internship
                        </h5>
                        <div className='row mt-3'>
                            <div className="col-sm-4 form-group custom-controller">
                                <Controller
                                    control={hookForm.control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            options={internshipType}
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.value}
                                            onChange={onChange}
                                            value={value}
                                            styles={selectStyle}
                                            placeholder='Internship Type'
                                        />
                                    )}
                                    name="internship_type"
                                />
                                {hookForm.formState.errors.internship_type && <span className='error text-danger'><FormattedMessage id="validation.error.select" /></span>}
                            </div>

                            <div className="col-sm-4 day_picker-cs">
                                <Controller
                                    control={hookForm.control}
                                    name="internship_date"
                                    render={({ field: { onChange, value, ref } }) => (
                                        <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            ref={ref}
                                            selected={value ? new Date(value) : null}
                                            onChange={onChange}
                                            className={
                                                hookForm.formState.errors.internship_date
                                                    ? 'is-invalid form-control p-2'
                                                    : 'form-control'
                                            }
                                            placeholderText="Internship Date"
                                            popperPlacement="right-end"
                                        />
                                    )}
                                />
                                {hookForm.formState.errors.internship_date && (
                                    <span className="error text-danger">
                                        {hookForm.formState.errors.internship_date.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end">
                        <Button
                            type="button"
                            onClick={() => {
                                closeModal();
                                setSelectedEmployee(null);
                            }}
                            className="justify-content-center mr-2 col-xl-2 col-4"
                        >
                            <FormattedMessage id="button.name.common.cancel" />
                        </Button>
                        <Button
                            type="submit"
                            appearance="primary"
                            className="col-xl-2 col-5"
                        >
                            {' '}
                            {isNew
                                ? (
                                    <FormattedMessage
                                        id={'button.name.common.create'}
                                    />
                                    )
                                : (
                                    <FormattedMessage
                                        id={'button.name.common.save'}
                                    />
                                    )}{' '}
                        </Button>
                    </div>
                </div>
            </form>}
        </>
    );
}
