import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';

export default function MenuExpandSandbox ({ id, match, hostname }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Tooltip title='Sandbox menu'>
                <IconButton
                    onClick={handleClick}
                    size='small'
                    aria-controls={open ? 'sandbox-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    >
                    <LinearScaleIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id='sandbox-menu'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Link
                        to={`${match.url}/${id}/${
                            hostname?.split('.')[0]
                        }`}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <ListItemIcon>
                                <EditIcon fontSize='small'/>
                            </ListItemIcon>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="sandbox.operations.button.view" />
                            </Typography>
                        </Box>
                    </Link>
                </MenuItem>
            </Menu>
        </>
    );
}
