import { FormControl, TextField } from '@mui/material';

export default function InputField ({ label, value, variant, id, onChange, className, error, size = '', multiline }) {
    return (
        <FormControl fullWidth>
            <TextField
                onChange={onChange}
                size={size}
                value={value}
                id={id}
                label={label}
                variant={variant}
                error={error}
                helperText={error?.message}
                multiline={multiline}
                maxRows={4}
            />
        </FormControl>
    );
}
