import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class StructureService {
    constructor () {
        serviceHelpers();
    }

    getOrganizationStructure () {
        return axiosService.get(API_URL + 'organizationStructure');
    }
}

export default new StructureService();
