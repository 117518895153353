import AxiosService from '../../../common/services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnerowner';

class AccountService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
    }

    getAll (page) {
        return AxiosService.get(API_URL + '/accounts', { params: { page } });
    }

    getPartnerMember (limit) {
        return AxiosService.get(API_URL + '/accounts', { params: { limit } });
    }

    getOne (accountId) {
        return AxiosService.get(API_URL + '/accounts/' + accountId);
    }

    inviteMember (payload) {
        return AxiosService.post(API_URL + '/accounts/invite', payload);
    }

    reinviteMember (payload) {
        return AxiosService.post(API_URL + '/accounts/re-invite', payload);
    }

    grantPermissionAcount (accountId, payload) {
        return AxiosService.patch(API_URL + '/accounts/' + accountId + '/grant-permission', payload);
    }

    destroyAccount (accountId) {
        return AxiosService.delete(API_URL + '/accounts/' + accountId);
    }
}

export default new AccountService();
