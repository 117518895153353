import React from 'react';
import serviceHelpers from '../../../helpers/serviceHelpers';
import Files from 'react-files';
import $ from 'jquery';
import Button from '@atlaskit/button';
class AddFile extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            pageNumber: 1,
            numPage: null
        };
        serviceHelpers();
    }

    filesRemoveAll () {
        // eslint-disable-next-line react/no-string-refs
        this.refs.files.removeFiles();
    }

    onPreview (file) {
        this.setState({
            url: (URL.createObjectURL(file)),
            filePreviewing: file
        });
    }

    filesRemoveOne (file) {
        // eslint-disable-next-line react/no-string-refs
        this.refs.files?.removeFile(file);
    }

    onDeleteInputFile (index, file) {
        this.filesRemoveOne(file);
        this.props.setSelectFIle(this.props.selectedFile.filter((_, i) => i !== index));
    }

    handleInputFileChange (files) {
        this.props?.setSelectFIle(files);
        files.length < 3 && $('.slideouter').css({ 'overflow-x': 'auto' });
    }

    onError (e) {
        console.log(e, 'error in file-viewer');
    }

    render () {
        return (
            <>
                <div className="files">
                    <Files
                    className='files-dropzone'
                    onChange={(file) => this.handleInputFileChange(file)}
                    accepts={['image/png', '.pdf', 'image/jpg', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.ms-excel']}
                    multiple
                    maxFileSize={999999999999999}
                    minFileSize={0}
                    style={{ cursor: 'pointer' }}
                    // eslint-disable-next-line react/no-string-refs
                    ref='files'
                    >
                        <Button color = 'true'>Choose File</Button>
                    </Files>
                </div>

                {this.props.selectedFile[0]?.preview &&
                     <div className='slide-sample'>
                        <div className='slideouter overflow-auto'>
                            { this.props.selectedFile.map((file, index) => {
                                return (
                                <div key={index}>
                                        <div className="slideinner srcl">
                                            {
                                                file.type.includes('image')
                                                    ? <div>
                                                        <a href={this.state.url ? this.state.url : ''} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                                                            <img className='previewImg' src={file.preview?.url} data-toggle="modal" data-target="#preview-file" onClick={() => this.onPreview(file)}/>
                                                        </a>
                                                        <span className="delete" style={{ textAlign: 'center', display: 'inline-block', zIndex: '9999' }} onClick={() => this.onDeleteInputFile(index, file)}><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                                    </div>
                                                    : <div>
                                                        <a href={this.state.url ? this.state.url : ''} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                                                            <div onClick={() => this.onPreview(file)}>
                                                                <span className="material-icons" style={{ textAlign: 'center', display: 'inline-block' }} onClick={() => this.onPreview(file)}> picture_as_pdf</span>
                                                                <span className='preview-fileName'>{String(file.name).length > 30 ? String(file.name).substring(0, 20) + '...' + file.extension : file.name}</span>
                                                            </div>
                                                        </a>
                                                        <span className="delete" style={{ textAlign: 'center', display: 'inline-block' }} onClick={() => this.onDeleteInputFile(index, file)}><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                                    </div>
                                            }
                                        </div>
                                </div>
                                );
                            })
                        }
                     </div>
                 </div>
                }
            </>
        );
    }

    componentDidUpdate () {
        if (this.props.resultCreate === true) {
            this.filesRemoveAll();
            this.props.setSelectFIle('');
            this.props.resultCreate === true && this.props.setResultCreate(false);
        }
    }
}
export default AddFile;
