import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './index.reducer';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

const composeEnhancers = compose;
export const history = createBrowserHistory();

const SetStore = () => {
    const middlewares = [thunk, routerMiddleware(history)];
    const enhancers = [applyMiddleware(...middlewares)];
    const store = createStore(
        rootReducer(history),
        composeEnhancers(...enhancers)
    );
    return store;
};

export default SetStore;
