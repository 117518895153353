import React, { Component } from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2';
import validator from 'validator';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import AccountService from '../services/account.service';
import { ROLES } from '../../../common/constants/user.constant';
import RightSidebar from '../../../common/components/common/RightSidebar';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../common/actions/breadcrumb.action';
import { ToastTopEnd } from '../../../common/helpers/ToastTimer';
import Select from '@atlaskit/select';
import ShowForPermission from '../../../common/components/common/ShowForPermissionComponent';
import Button from '@atlaskit/button';
import ReactPagination from '../../../common/components/ReactPagination';
const selectError = {
    container: (provided, state) => ({
        ...provided,
        border: '1px solid red',
        borderRadius: '4px'
    })
};

const selectNormal = {
    container: (provided, state) => ({
        ...provided
    })
};

class Account extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSetTable: [],
            form: {
                firstname: '',
                lastname: '',
                email: '',
                role: '',
                id: ''
            },
            isError: {
                firstname: '',
                lastname: '',
                email: '',
                role: ''
            },
            role: [
                {
                    value: ROLES.PARTNEROWNER,
                    label: 'Owner'
                },
                {
                    value: ROLES.PARTNERMANAGER,
                    label: 'Manager'
                },
                {
                    value: ROLES.PARTNERMEMBER,
                    label: 'Member'
                }
            ],
            isEdit: false
        };
    }

    getDefaultRole () {
        if (this.state.form.role === ROLES.MEMBER) {
            return this.state.role.filter((item) => item.value === ROLES.PARTNERMEMBER);
        }
        if (this.state.form.role === ROLES.MANAGER) {
            return this.state.role.filter((item) => item.value === ROLES.PARTNERMANAGER);
        }
        return this.state.role.filter((item) => this.state.form.role === item.value) || this.state.role[0];
    }

    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.account');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.account',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.account',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        this.getListAccount();
    }

    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '450px' });
        }
    }

    onClickInvite () {
        this.setState({
            form: {
                firstname: '',
                lastname: '',
                email: '',
                role: ''

            },
            isError: {
                firstname: '',
                lastname: '',
                email: '',
                role: ''
            },
            isEdit: false
        });
        this.openSidebar();
    }

    onClickEdit (item) {
        this.setState({
            form: {
                firstname: item.firstname,
                lastname: item.lastname,
                email: item.username,
                role: item.role,
                id: item._id
            },
            isEdit: true
        });
        this.openSidebar();
    }

    async getListAccount (page = 1) {
        await AccountService.getAll(page).then((res) => {
            if (res.status === 200) {
                this.setState({ dataSetTable: res.data.data, loadingStatus: true });
                this.forceUpdate();
            }
        });
    }

    handleDeteleClick (id, email) {
        Swal.fire({
            title: 'Delete "' + email + '" ?',
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                AccountService.destroyAccount(id).then((res) => {
                    // this.state.table.destroy();
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                    this.getListAccount();
                }).catch((error) => {
                    if (error.response) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: TranslateHelper.getMessage('respond.message.failed')
                        });
                    }
                });
            }
        });
        this.forceUpdate();
    }

    handleGrantPermissionClick (id) {
        const { form } = this.state;
        const payload = {
            role: form.role
        };
        AccountService.grantPermissionAcount(id, payload).then((res) => {
            // this.state.table.destroy();
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
            this.getListAccount();
        }).catch((error) => {
            if (error.response) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage('respond.message.failed')
                });
            }
        });
    }

    handleInviteMember () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                firstname: form.firstname.trim(),
                lastname: form.lastname.trim(),
                email: form.email.trim(),
                role: form.role
            };
            AccountService.inviteMember(payload).then((res) => {
                // this.state.table.destroy();
                this.getListAccount();
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                this.setState({ form: { firstname: '', lastname: '', email: '' } });
                $('.sidebar-right').css({ width: '0' });
            }).catch((err) => {
                if (err.response) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: err.response.data.message
                    });
                    $('.sidebar-right').css({ width: '0' });
                }
            });
        }
        this.getListAccount();
        this.forceUpdate();
    }

    handleReinviteMember (email) {
        const payload = { email };
        AccountService.reinviteMember(payload).then((res) => {
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.reinvite.successed')
            });
        }).catch((err) => {
            if (err.response.data) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage('respond.message.failed')
                });
            }
        });
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'email': case 'firstname': case 'lastname':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            case 'role':
                form[name] = event.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'firstname': case 'lastname': case 'role':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                case 'email':
                    if (!validator.isEmail(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.invalided');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    render () {
        const { isError } = this.state;
        const spanRole = (item) => {
            switch (item.role) {
                case ROLES.MEMBER: case ROLES.HR:
                    return (<span className="badge badge-success"><FormattedMessage id="account.lable.role.membership" /></span>);
                case ROLES.MANAGER:
                    return (<span className="badge badge-primary"><FormattedMessage id="account.lable.role.management" /></span>);
                case ROLES.PARTNERMEMBER:
                    return (<span className="badge badge-success"><FormattedMessage id="account.lable.role.partnerMember" /></span>);
                case ROLES.PARTNEROWNER: case ROLES.PARTNERMANAGER:
                    return (<span className="badge badge-primary"><FormattedMessage id="account.lable.role.partnerOwner" /></span>);
                default:
                    return (<span className="badge badge-secondary"><FormattedMessage id="account.lable.role.unknown" /></span>);
            }
        };
        const buttonAction = (item) => {
            return (
                <div className="btn-group pointer">
                    <span data-toggle="dropdown" aria-expanded="true"><i className="nav-icon fas fa-ellipsis-h"></i></span>
                    <ul className="dropdown-menu dropdown-menu-right">
                        {item.confirmed ? (<></>) : (<li style={{ cursor: 'pointer' }} onClick={() => { this.handleReinviteMember(item.username); }}><span className="dropdown-item"><FormattedMessage id="button.name.common.reinvite" /></span></li>) }
                        <ShowForPermission permissionKey='editGrantPermission'><li style={{ cursor: 'pointer' }} onClick={() => { this.onClickEdit(item); }}><span className="dropdown-item"><FormattedMessage id="button.name.common.edit" /></span></li></ShowForPermission>
                        <li style={{ cursor: 'pointer' }} onClick={() => { this.handleDeteleClick(item._id, item.username); }}><span className="dropdown-item"><FormattedMessage id="button.name.common.delete" /></span></li>
                    </ul>
                </div>);
        };
        const rowTables = this.state.dataSetTable.items?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.firstname + ' ' + item.lastname}</td>
                    <td>{item.username}</td>
                    <td><FormattedDate
                        value={item.createdAt}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                    /></td>
                    <td>{item.confirmed
                        ? <i className="fas fa-check ml-5" style={{ color: 'green' }}></i>
                        : <i className="far fa-times-circle ml-5" style={{ color: 'red' }}></i>}
                    </td>
                    <td>{spanRole(item)}</td>
                    <td>
                        {this.props.user.email === item.username ? <></> : buttonAction(item)}
                    </td>
                </tr>
            );
        });
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <Button type="button" onClick={() => { this.onClickInvite(); }} appearance='primary'>&nbsp;<FormattedMessage id="button.name.common.invite"/></Button>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className='table-scrollX'>
                                    {this.state.loadingStatus
                                        ? (<>
                                            {this.state.dataSetTable.items.length
                                                ? (<table id="account-list" className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th className="sorting_asc"><FormattedMessage id="account.table.column.name" /></th>
                                                            <th className="sorting_asc"><FormattedMessage id="account.table.column.email" /></th>
                                                            <th className="sorting_asc">Created At</th>
                                                            <th className="sorting_asc">Email Confirmed</th>
                                                            <th className="sorting_asc"><FormattedMessage id="account.table.column.role" /></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{rowTables}</tbody>
                                                </table>)
                                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                        </>)
                                        : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                    <div style={{ height: '30px' }}></div>
                                </div>
                                {this.state.dataSetTable.totalPages > 1 && (
                                    <ReactPagination
                                        totalPages={this.state.dataSetTable.totalPages}
                                        handlePageChange={(e, page) => this.getListAccount(page)}
                                        pageNumber={this.state.dataSetTable.page}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <RightSidebar title={TranslateHelper.getMessage('account.modals.invite.title')}>
                    <div>
                        <div className="row">
                            <div className="col-6 form-group">
                                <label className="label-400 form-check-label"><FormattedMessage id="account.form.label.firstname"/><span style={{ color: 'red' }}>*</span></label>
                                <input
                                    readOnly={this.state.isEdit}
                                    placeholder={TranslateHelper.getMessage('placeholder.form.firstname')}
                                    value={this.state.form.firstname}
                                    type="text"
                                    name="firstname"
                                    onChange={this.handleInputChange.bind(this, 'firstname')}
                                    className={isError.firstname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                />
                                {isError.firstname.length > 0 && (<span className="error invalid-feedback">{isError.firstname}</span>)}
                            </div>
                            <div className="col-6 form-group">
                                <label className="label-400 form-check-label"><FormattedMessage id="account.form.label.lastname"/><span style={{ color: 'red' }}>*</span></label>
                                <input
                                    readOnly={this.state.isEdit}
                                    placeholder={TranslateHelper.getMessage('placeholder.form.lastname')}
                                    value={this.state.form.lastname}
                                    type="text"
                                    name="lastname"
                                    onChange={this.handleInputChange.bind(this, 'lastname')}
                                    className={isError.lastname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                />
                                {isError.lastname.length > 0 && (<span className="error invalid-feedback">{isError.lastname}</span>)}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="label-400 form-check-label"><FormattedMessage id="account.form.label.email"/><span style={{ color: 'red' }}>*</span></label>
                            <input
                                readOnly={this.state.isEdit}
                                placeholder={TranslateHelper.getMessage('placeholder.form.email')}
                                value={this.state.form.email}
                                type="email"
                                name="email"
                                onChange={this.handleInputChange.bind(this, 'email')}
                                className={isError.email.length > 0 ? 'is-invalid form-control' : 'form-control'}
                            />
                            {isError.email.length > 0 && (<span className="error invalid-feedback">{isError.email}</span>)}
                        </div>
                        <div className='form-group w-50'>
                        <label className="label-400 form-check-label"><FormattedMessage id="account.table.column.role"/><span style={{ color: 'red' }}>*</span></label>
                            <Select
                                placeholder={TranslateHelper.getMessage('account.form.placeholder.role')}
                                onChange={this.handleInputChange.bind(this, 'role')}
                                options={this.state.role}
                                value = {this.getDefaultRole()}
                                styles={isError.role.length > 0 ? selectError : selectNormal}
                            />
                            {isError.role.length > 0 && (
                                <span className='error error-select'>{isError.role}</span>
                            )}
                        </div>
                        <Button type="button" appearance='primary' onClick={() => { this.state.isEdit ? this.handleGrantPermissionClick(this.state.form.id) : this.handleInviteMember(); }}>{this.state.isEdit ? <FormattedMessage id="button.name.common.edit"/> : <FormattedMessage id="button.name.common.invite"/>}</Button>
                    </div>
                </RightSidebar>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(Account);
