class TokenService {
    contructor () {}

    parseJwt (token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    checkExpired (token) {
        const decodedJwt = this.parseJwt(token);
        if (decodedJwt.exp * 1000 < Date.now()) {
            return true;
        } else {
            return false;
        }
    }
}

export default new TokenService();
