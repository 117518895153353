
import { connect } from 'react-redux';
import { useCallback, useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import { setBreadcrumb } from '../../../common/actions/breadcrumb.action';
import packageService from './Services/package.service';
import { FormattedMessage } from 'react-intl';
import CurrencyFormat from 'react-currency-format';
import { ToastTopEnd } from '../../../common/helpers/ToastTimer';
import Swal from 'sweetalert2';
import Editor from '../../../common/components/EdittorToolbar';
import Button from '@atlaskit/button';

function Package ({ dispatch }) {
    const [packages, setPK] = useState([]);
    const [packagesIn4, setPKIn4] = useState();
    const [profit, setProfit] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [onChange, setOnChange] = useState();
    const hideModalButtonRef = useRef();
    const defaultFormValue = { name: '', price: '', durations: 0 };
    const { handleSubmit, control, formState: { errors }, watch, reset, setValue, setError } = useForm(defaultFormValue);

    const fetchPK = useCallback((page = 1) => {
        packageService.getAllPackage(page).then(res => {
            setPK(res);
        });
    }, []);

    const handleChange = (html) => {
        setOnChange(html);
    };

    useEffect(() => {
        document.title = TranslateHelper.getMessage('menu.admin.name.package');
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.dashboard',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: TranslateHelper.getMessage('menu.admin.name.package'),
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch]);

    useEffect(fetchPK, [fetchPK]);

    const handleDelete = (id, name) => {
        try {
            Swal.fire({
                title: TranslateHelper.getMessage('alert.delete') + ' "' + name + '" ?',
                text: TranslateHelper.getMessage('alert.detete.text'),
                icon: 'warning',
                reverseButtons: true,
                confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
                showCancelButton: true,
                cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
                buttonsStyling: false,
                customClass: {
                    cancelButton: 'btn btn-outline-danger',
                    confirmButton: 'btn btn-primary ml-2'
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    packageService.deletePackage(id).then(res => {
                        if (res.status === 200) {
                            ToastTopEnd.fire({
                                icon: 'success',
                                title: res.data.message
                            });
                            return fetchPK();
                        }
                    }).catch((error) => {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response.data.message || TranslateHelper.getMessage('respond.message.failed')
                        });
                    });
                }
            });
        } catch (errors) {
            console.log(errors);
        }
    };

    const getOnePackage = (id) => {
        packageService.getOnePackage(id).then(res => {
            setPKIn4(res);
            setError('name', ''); setError('price', ''); setError('durations', '');
            setError('limitedUser', '');
            setValue('name', res?.name);
            setValue('price', res?.price);
            setValue('durations', res?.durations);
            setValue('description', '');
            setValue('description', res?.description);
            setValue('limitedUser', res?.limitedUser);
            setProfit(res?.price);
        });
    };

    const handleCreatePackage = (formdata) => {
        if (isEdit === false) {
            const payload = {
                name: formdata.name,
                durations: parseInt(formdata.durations),
                price: profit,
                limitedUser: formdata.limitedUser,
                description: onChange
            };
            try {
                packageService.createPackage(payload).then(res => {
                    if (res.status === 200) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        hideModalButtonRef.current?.click();
                        reset(defaultFormValue);
                        fetchPK();
                    }
                }).catch((error) => {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: error.response.data.message || TranslateHelper.getMessage('respond.message.failed')
                    });
                });
            } catch (errors) {
                console.log(errors);
            }
        } else if (isEdit === true) {
            const payload = {
                name: formdata.name,
                durations: parseInt(formdata.durations),
                price: profit,
                limitedUser: formdata.limitedUser,
                description: onChange
            };
            try {
                packageService.editPackage(packagesIn4._id, payload).then(res => {
                    if (res.status === 200) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        hideModalButtonRef.current?.click();
                        reset(defaultFormValue);
                        fetchPK();
                    }
                }).catch((error) => {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: error.response.data.message || TranslateHelper.getMessage('respond.message.failed')
                    });
                });
            } catch (errors) {
                console.log(errors);
            }
        }
    };

    const handleSetDefaultPackage = (id) => {
        const payload = {
            isDefault: true
        };
        packageService.editPackage(id, payload).then((res) => {
            if (res.status === 200) {
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                fetchPK();
            }
        });
    };

    useEffect(() => {
        const subscription = watch((value) => value);
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
       <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-12">
                    <Button appearance='primary' type="button" onClick = { () => { setIsEdit(false); setProfit(0); setValue('name', ''); setValue('description', ''); setValue('price', ''); setValue('durations', 0); setValue('limitedUser', 0); setError('name', ''); setError('price', ''); setError('durations', ''); setError('limitedUser', ''); setError('description', ''); } }data-toggle="modal" data-target="#create-package" >&nbsp;<FormattedMessage id="button.name.common.create"/></Button>
                </div>
            </div>
            <div className="modal fade" id="create-package">
                <div className="modal-dialog modal-md">
                    <form onSubmit={handleSubmit(handleCreatePackage)} className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{isEdit === true ? 'Edit Package' : 'Create Package'}</h4>
                            <button
                                type="button"
                                className="close"
                                ref={hideModalButtonRef}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">
                                    <FormattedMessage id="package.create.package.name" />
                                </label>
                                <div className="col-sm-7">
                                    <Controller
                                        name='name'
                                        control={control}
                                        rules={{
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            ),
                                            pattern: {
                                                value: /^(?!\s+$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.containSpecialCharacter'
                                                )
                                            }
                                        }}
                                        render={({ field }) => (
                                            <input
                                                placeholder='Name of package ?'
                                                onChange={ e => field.onChange(e) }
                                                value={watch('name')}
                                                className={
                                                    errors.name?.message ? 'is-invalid form-control' : 'form-control'
                                                }
                                            />
                                        )}

                                    />
                                    {errors.name && (
                                        <span className="error text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="price" className="col-sm-3 col-form-label">
                                    <FormattedMessage id="package.create.package.price" />
                                </label>
                                <div className="col-sm-7">
                                    <Controller
                                        control={control}
                                        name="price"
                                        rules={{
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        }}
                                        render={({ field }) => (
                                            <CurrencyFormat
                                                className={
                                                    errors.price?.message ? 'is-invalid form-control' : 'form-control'
                                                }
                                                thousandSeparator={true}
                                                onChange={(e) => field.onChange(e)}
                                                onValueChange={(values) => { const { value } = values; setProfit(value); }}
                                                value={watch('price')}
                                                placeholder="Price of package ?"
                                                prefix={TranslateHelper.getMessage('prefix.price')}
                                            />
                                        )}
                                    />
                                    {errors.price && (
                                        <span className="error text-danger">
                                            {errors.price.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="durations" className="col-sm-3 col-form-label">
                                    <FormattedMessage id="package.create.package.duration" />
                                </label>
                                <div className="col-sm-7">
                                    <Controller
                                        control={control}
                                        name="durations"
                                        rules={{
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        }}
                                        render={({ field }) => (
                                            <CurrencyFormat
                                                className={
                                                    errors.durations?.message ? 'is-invalid form-control' : 'form-control'
                                                }
                                                onChange={(e) => field.onChange(e)}
                                                format="###"
                                                value={ watch('durations')}
                                                placeholder="Duration of package ?"
                                            />
                                        )}
                                />
                                    {errors.durations && (
                                        <span className="error text-danger">
                                            {errors.durations.message}
                                        </span>
                                    )}
                                </div>
                                <div className='col-sm-2' style={{ alignSelf: 'center' }}>day(s)</div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="limitedUser" className="col-sm-3 col-form-label">
                                    <FormattedMessage id="package.create.package.limitUser" />
                                </label>
                                <div className="col-sm-7">
                                    <Controller
                                        name='limitedUser'
                                        control={control}
                                        rules={{
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            ),
                                            pattern: {
                                                value: /^(?!\s+$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.containSpecialCharacter'
                                                )
                                            }
                                        }}
                                        render={({ field }) => (
                                            <input
                                                type={'number'}
                                                placeholder='Limited user ?'
                                                onChange={ e => field.onChange(e) }
                                                value={watch('limitedUser')}
                                                className={
                                                    errors.limitedUser?.message ? 'is-invalid form-control' : 'form-control'
                                                }
                                            />
                                        )}

                                    />
                                    {errors.limitedUser && (
                                        <span className="error text-danger">
                                            {errors.limitedUser.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="description" className="col-sm-12 col-form-label">
                                    <FormattedMessage id="project.new.label.description" />
                                </label>
                                <div className="col-sm-12">
                                    <div
                                    className={'bg-white'}
                                    >
                                        <Controller
                                            control = {control}
                                            name = 'description'
                                            render={({ field }) => (
                                                <>
                                                    <Editor onChange = {handleChange} defaultValue={ field.value } toolbarId={'toolbar'} placeholder={'Type ( [] + space ) to add checkbox'} />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button
                                type="button"
                                data-dismiss="modal"
                            >
                                <FormattedMessage id="button.name.common.cancel" />
                            </Button>
                            <Button
                                type="submit"
                                appearance='primary'
                                disabled = { (watch('name') === packagesIn4?.name && watch('price') === packagesIn4?.price && watch('durations') === packagesIn4?.durations && watch('limitedUser') === packagesIn4?.limitedUser && onChange === packagesIn4?.description && isEdit)}
                            >
                                {isEdit === true ? TranslateHelper.getMessage('button.name.common.save') : TranslateHelper.getMessage('button.name.common.create')}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-2'>
                <div className='card-header'>
                    <h3 className='card-title'>{TranslateHelper.getMessage('package.table.name')}</h3>
                </div>
                <div className='card-body'>
                    <div style={{ overflowX: 'auto' }}>
                        <table id="packge" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="package.table.column.id" /></th>
                                    <th><FormattedMessage id="package.table.column.name" /></th>
                                    <th><FormattedMessage id="package.table.column.price" /></th>
                                    <th><FormattedMessage id="package.table.column.duration" /></th>
                                    <th><FormattedMessage id="package.table.column.default" /></th>
                                    <th><FormattedMessage id="package.table.column.action" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {packages?.items?.length > 0
                                    ? packages.items.map((pk, index) => {
                                        return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{pk?.name}</td>
                                            <td><CurrencyFormat value={pk?.price} displayType={'text'} thousandSeparator={true} prefix={TranslateHelper.getMessage('prefix.price')} /></td>
                                            <td>{pk?.durations}</td>
                                            <td>{pk.isDefault
                                                ? (<i className="far fa-check-square"></i>)
                                                : (<i onClick={() => handleSetDefaultPackage(pk._id) } className="far fa-square pointer"></i>) }</td>
                                            <td>
                                                <Button className="mr-2" onClick={() => { setIsEdit(true); getOnePackage(pk?._id); }} data-toggle="modal" data-target='#create-package'>
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                                <Button
                                                    style={ pk.isDefault ? { display: 'none' } : { display: 'inline-block' }}
                                                    onClick={() => handleDelete(pk?._id, pk?.name)}
                                                >
                                                    <i className="far fa-trash-alt" aria-hidden="true"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                        );
                                    })
                                    : <tr><td colSpan="6" style={{ textAlign: 'center' }}>{TranslateHelper.getMessage('table.norecord')}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
       </div>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(Package);
