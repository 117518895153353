import { connect, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import FooterSection from '../components/FooterSection';
import HeaderSection from '../components/HeaderSection';
import AsidebarSection from '../components/AsidebarSection';
import MainContentSection from '../components/MainContentSection';
import { useService } from '../helpers/serviceHelpers';
import authService from '../services/auth.service';
import { ROLES } from '../constants/user.constant';
import { setClientIdActived } from '../components/sandbox/actions/client-id.action';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { logout } from '../actions/auth.action';
import Swal from 'sweetalert2';
import tokenService from '../components/auth/token.service';

function MasterLayout ({ isLoggedIn, connection, role }) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    if (!connection) {
        return <Redirect to="/no-connection" />;
    }
    if (!isLoggedIn) {
        return <Redirect to="/landing" />;
    }

    if (role === ROLES.PARTNERMEMBER || role === ROLES.PARTNEROWNER) {
        const { isLoading, error } = useService(authService.checkExpire, undefined, undefined, { defaultValue: undefined });

        const isExpired = !isLoading && error;
        const isInSubscriptionPage = pathname.includes('/subscription');

        if (isExpired && !isInSubscriptionPage) {
            dispatch(setClientIdActived('none'));
            return <Redirect to={{
                pathname: '/subscription',
                state: { isExpired: true }
            }} />;
        }
    }

    useEffect(() => {
        let isExpiredToken = false;
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            if (tokenService.checkExpired(accessToken)) {
                isExpiredToken = true;
            }
        }
        if (isExpiredToken) {
            let timerInterval;
            Swal.fire({
                title: 'Session expired',
                html: 'Please re-login in <b></b> milliseconds.',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector('b');
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                    }, 100);
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    dispatch(logout());
                }
            });
        }
    });

    return (
        <div className="wrapper">
            <HeaderSection></HeaderSection>
            <AsidebarSection></AsidebarSection>
            <MainContentSection ></MainContentSection>
            <FooterSection></FooterSection>
        </div>
    );
}

function mapStateToProps (state) {
    const { isLoggedIn, role } = state.auth;
    return {
        isLoggedIn,
        role
    };
}

export default connect(mapStateToProps)(MasterLayout);
