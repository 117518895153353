import serviceHelpers from '../../../helpers/serviceHelpers';
import AxiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects';

class MemberService {
    constructor () {
        serviceHelpers();
    }

    getAllMembers (projectId) {
        const endpoint = `${API_URL}/${projectId}/members/kimei`;
        return AxiosService.get(endpoint, { params: { limit: 1000 } }).then(response => response.data.data.items);
    }

    getMembers (projectId) {
        const endpoint = `${API_URL}/${projectId}/members`;
        return AxiosService.get(endpoint).then(response => response.data.data);
    }

    getRoles () {
        const endpoint = `${API_URL}/roles`;
        return AxiosService.get(endpoint).then(response => response.data.data);
    }

    createMember (projectId, data) {
        const endpoint = `${API_URL}/${projectId}/members`;
        return AxiosService.post(endpoint, data).then(response => response.data.data);
    }

    deleteMember (projectId, memberId) {
        const endpoint = `${API_URL}/${projectId}/members/${memberId}`;
        return AxiosService.delete(endpoint).then(response => response.data.data);
    }
}

export default new MemberService();
