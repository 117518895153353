import DashboardRoutes, { dashboardMenuLinks } from './dashboard/routes';
import ActivityRoutes, { activityMenuLinks } from '../manager/activity/routes';
import SettingRoutes from './setting/routes';
import ProjectRoutes, { projectMenuLinks } from '../common/components/project/routes';
import HrmRoutes, { hrmMenuLinks } from '../common/components/hrm/routes';
import RecruitmentRoutes, { recruitmentMenuLinks } from '../manager/recruitment/routes';
import AttendanceRoutes, { attendanceMenuLinks } from '../../modules/manager/attendance/routes';

export const memberMenuLinks = [
    ...dashboardMenuLinks,
    ...activityMenuLinks,
    ...hrmMenuLinks,
    ...recruitmentMenuLinks,
    ...projectMenuLinks,
    ...attendanceMenuLinks
];

const routes = [
    ...DashboardRoutes,
    ...ActivityRoutes,
    ...HrmRoutes,
    ...RecruitmentRoutes,
    ...ProjectRoutes,
    ...SettingRoutes,
    ...AttendanceRoutes
];

export default routes;
