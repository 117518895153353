import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth.action';
import TranslateHelper from '../../helpers/TranslateHelper';
import Cookies from 'js-cookie';
import { ROLES } from '../../../common/constants/user.constant';

const roleLabel = ['Request', 'Manager', 'Member', 'Partner owner', 'Partner manager', 'Partner member'];

class SwitchingRole extends Component {
    constructor (props) {
        super(props);
        this.changeRole = this.changeRole.bind(this);
    }

    changeRole (role) {
        Cookies.set('roleChanged', role, { expires: 1 / 48 });
        const { dispatch } = this.props;
        dispatch(logout());
    }

    renderSwitchRoleButton (role) {
        if ((role === ROLES.MANAGER || role === ROLES.PARTNEROWNER)) {
            switch (role) {
                case ROLES.MANAGER:
                    return (
                        <a onClick={() => { this.changeRole(ROLES.PARTNEROWNER); }}>
                            {TranslateHelper.getMessage('switch.to.label')} {roleLabel[ROLES.PARTNEROWNER]}
                        </a>
                    );
                case ROLES.PARTNEROWNER:
                    return (
                        <a onClick={() => { this.changeRole(ROLES.MANAGER); }}>
                            {TranslateHelper.getMessage('switch.to.label')} {roleLabel[ROLES.MANAGER]}
                        </a>
                    );
                default:
                    break;
            }
        }
        if ((role === ROLES.MEMBER || role === ROLES.PARTNERMEMBER)) {
            switch (role) {
                case ROLES.MEMBER:
                    return (
                        <a onClick={() => { this.changeRole(ROLES.PARTNERMEMBER); }}>
                            {TranslateHelper.getMessage('switch.to.label')} {roleLabel[ROLES.PARTNERMEMBER]}
                        </a>
                    );
                case ROLES.PARTNERMEMBER:
                    return (
                        <a onClick={() => { this.changeRole(ROLES.MEMBER); }}>
                            {TranslateHelper.getMessage('switch.to.label')} {roleLabel[ROLES.MEMBER]}
                        </a>
                    );
                default:
                    break;
            }
        } else {
            return (
                <div className="dropdown">
                    <p data-toggle="dropdown">
                        <span>{roleLabel[role]}</span>
                    </p>
                    <div className="dropdown-menu dropdown-menu-right p-0">
                        <p onClick={() => { this.changeRole(ROLES.MANAGER); }} className="dropdown-item dropdown-item-lang dropdown-item-en">
                            {roleLabel[ROLES.MANAGER]}
                        </p>
                        <p onClick={() => { this.changeRole(ROLES.MEMBER); }} className="dropdown-item dropdown-item-lang dropdown-item-ja">
                            {roleLabel[ROLES.MEMBER]}
                        </p>
                        <p onClick={() => { this.changeRole(ROLES.PARTNEROWNER); }} className="dropdown-item dropdown-item-lang dropdown-item-vi">
                            {roleLabel[ROLES.PARTNEROWNER]}
                        </p>
                        <p onClick={() => { this.changeRole(ROLES.PARTNERMEMBER); }} className="dropdown-item dropdown-item-lang dropdown-item-vi">
                            {roleLabel[ROLES.PARTNERMEMBER]}
                        </p>
                    </div>
                </div>
            );
        }
    }

    render () {
        return (
            <>
                {this.renderSwitchRoleButton(this.props.role)}
            </>
        );
    }
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(SwitchingRole);
