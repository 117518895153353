import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomInputDate } from '../../../../components/common/CustomInputDatepicker';
import { useForm, Controller } from 'react-hook-form';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import departmentService from './services/department.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';
import { vi } from 'date-fns/locale';
import DepartmentDetail from './DepartmentDetail';
import DepartmentTable from './DepartmentTable';
import hierarchyService from './services/hierarchy.service';
import { useService } from '../../../../helpers/serviceHelpers';
function Departments ({ role }) {
    const { refetch, data: hierarchy } = useService(hierarchyService.getDepartmentHierarchy);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty, isValid },
        reset,
        setValue,
        clearErrors
    } = useForm({
        defaultValues: {
            parentDepartmentId: '',
            departmentHead: ''
        },
        mode: 'onChange'
    });
    const hideModalButtonRef = useRef();
    const [parentDepartments, setParentDepartments] = useState([]);
    const [departmentHeads, setDepartmentHead] = useState([]);
    const [isEdit, setIsEdit] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [detail, setDetail] = useState(null);
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchManager = useCallback(() => {
        departmentService.getDepartmentHead().then((res) => {
            const list = res.data.data.map((item) => ({
                value: item._id,
                label: item.firstname + ' ' + item.lastname
            }));
            setDepartmentHead(list);
        });
    }, []);

    const fetchDepartment = useCallback((page = 1) => {
        departmentService.getAllDepartment({ page }).then((res) => {
            const list = res.data.data.items.map((item) => ({ value: item?._id, label: item?.name }));
            setParentDepartments(list);
        });
    }, []);

    useEffect(fetchDepartment, [fetchDepartment]);
    useEffect(fetchManager, [fetchManager]);

    const onSubmit = (formData) => {
        const data = {
            code: formData.code,
            name: formData.name,
            startedOn: formData.startedOn,
            parentDepartmentId: formData?.parentDepartmentId?.value,
            departmentHead: formData.departmentHead.value
        };
        handleCreateDepartment(data);
    };

    useEffect(() => {
        reset();
    }, hideModalButtonRef);

    const handleCreateDepartment = async (data) => {
        if (isEdit) {
            try {
                await departmentService.updateDepartment(data, isEdit);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successed')
                });
                reset({
                    ...data,
                    name: '',
                    code: '',
                    startedOn: '',
                    parentDepartmentId: null,
                    departmentHead: null
                });
                hideModalButtonRef.current?.click();
                refetch();
                fetchManager();
                return fetchDepartment();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            try {
                await departmentService.createDepartment(data);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                reset({
                    ...data,
                    name: '',
                    code: '',
                    startedOn: '',
                    parentDepartmentId: null,
                    departmentHead: null
                });
                hideModalButtonRef.current?.click();
                refetch();
                fetchManager();
                return fetchDepartment();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        }
    };

    return (
        <>
            <Button
                type="button"
                className={role === 1 ? 'mb-3' : 'd-none'}
                onClick={() => { reset(); setIsEdit(undefined); }}
                data-toggle="modal"
                appearance='primary'
                data-target="#create-modal"
            >
                <FormattedMessage id="button.name.common.create" />
            </Button>
            <div className="modal fade" id={isEdit ? 'edit-department' : 'create-modal'}>
                <div className="modal-dialog modal-dialog-centered">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-content custom-width">
                            <div className="modal-header">
                                <h5 className="modal-title">{!isEdit ? 'Thêm phòng ban' : 'Chỉnh sửa phòng ban'}</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    ref={hideModalButtonRef}
                                    onClick={() => clearErrors()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name">
                                        Tên phòng ban
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.name ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('name', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            ),
                                            pattern: {
                                                value: /^(?!\s+$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.containSpecialCharacter'
                                                )
                                            }
                                        })}
                                        id="name"
                                        placeholder="Tên phòng ban"
                                    />
                                    {errors.name && (
                                        <span className="error text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="code">
                                        Mã
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.code ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('code', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            ),
                                            pattern: {
                                                value: /^(?!$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.containSpecialCharacter'
                                                )
                                            },
                                            maxLength: {
                                                value: 7,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.max7characters'
                                                )
                                            }
                                        })}
                                        id="code"
                                        placeholder="Mã phòng ban"
                                    />
                                    {errors.code && (
                                        <span className="error text-danger">
                                            {errors.code.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="startedOn" className='mr-2'>
                                        Ngày thành lập
                                    </label>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <DatePicker
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                ref={ref}
                                                selected={value}
                                                onChange={onChange}
                                                customInput={<CustomInputDate />}
                                                id="startedOn"
                                                locale={vi}
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date()}
                                            />
                                        )}
                                        {...register('startedOn', { required: true })}
                                    />
                                    {errors.startedOn && (
                                        <span className="error text-danger">
                                            <FormattedMessage id="validation.error.select" />
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="parentDepartmentId">
                                        Phòng ban cấp trên
                                    </label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                ref={ref}
                                                options={parentDepartments}
                                                placeholder="Chọn phòng ban cấp trên"
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        name="parentDepartmentId"
                                        {...register('parentDepartmentId')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="departmentHead">
                                        Người lãnh đạo
                                    </label>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                ref={ref}
                                                options={departmentHeads}
                                                placeholder="Chọn người lãnh đạo"
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        name="departmentHead"
                                        {...register('departmentHead')}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-flex-end">
                                <Button
                                    type="button"
                                    ref={hideModalButtonRef}
                                    data-dismiss="modal"
                                    onClick={() => clearErrors()}
                                >
                                    <FormattedMessage id="button.name.common.close" />
                                </Button>
                                <Button
                                    type="submit"
                                    appearance='primary'
                                    disabled={!isDirty || !isValid}
                                >
                                    {isEdit ? <FormattedMessage id="button.name.common.update" /> : <FormattedMessage id="button.name.common.create" />}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DepartmentTable refetch={refetch} hierarchy={hierarchy} departmentHeads={departmentHeads} parentDepartments={parentDepartments} setDetai={setDetail} setIsModalOpen={setIsModalOpen} reset={reset} setIsEdit={setIsEdit} setValue={setValue} />
            <DepartmentDetail open={isModalOpen} closeModal={closeModal} data={detail} />
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(Departments);
