import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import $ from 'jquery';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageDatatables, LanguageFormatDateTime } from '../../../../../../../lang';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import ScheduleService from '../../../services/schedule.service';
import { CustomInputDateTime, CustomInputTime } from '../../../../common/CustomInputDatepicker';
import RightSidebar from '../../../../common/RightSidebar';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import Button from '@atlaskit/button';

const formDefault = {
    name: 'Start/Stop Daily',
    status: 'enable',
    dateFrom: new Date(),
    dateTo: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    daysOfWeek: [
        { value: 1, label: TranslateHelper.getMessage('option.common.monday') },
        { value: 2, label: TranslateHelper.getMessage('option.common.tuesday') },
        { value: 3, label: TranslateHelper.getMessage('option.common.wednesday') },
        { value: 4, label: TranslateHelper.getMessage('option.common.thursday') },
        { value: 5, label: TranslateHelper.getMessage('option.common.friday') }
    ],
    startFlg: true,
    timeRunStart: new Date(),
    stopFlg: true,
    timeRunStop: new Date(Date.now() + 12 * 60 * 60 * 1000)
};
const errorDefault = {
    name: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    daysOfWeek: '',
    startFlg: '',
    timeRunStart: '',
    stopFlg: '',
    timeRunStop: ''
};

class SchedulesSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSet: null,
            filterOptions: [
                { value: 1, label: TranslateHelper.getMessage('option.common.monday') },
                { value: 2, label: TranslateHelper.getMessage('option.common.tuesday') },
                { value: 3, label: TranslateHelper.getMessage('option.common.wednesday') },
                { value: 4, label: TranslateHelper.getMessage('option.common.thursday') },
                { value: 5, label: TranslateHelper.getMessage('option.common.friday') },
                { value: 6, label: TranslateHelper.getMessage('option.common.saturday') },
                { value: 0, label: TranslateHelper.getMessage('option.common.sunday') }
            ],
            isError: errorDefault,
            form: formDefault
        };
    }

    /**
     * Load API
     */
    componentDidMount () {
        this.getListSchedule();
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#schedule-list').DataTable({
                paging: false,
                lengthChange: false,
                searching: false,
                ordering: true,
                info: false,
                autoWidth: true,
                retrieve: true,
                responsive: true,
                language: LanguageDatatables[lang],
                columnDefs: [
                    { className: 'dt-center', targets: [7] },
                    { orderable: false, targets: [2, 3, 4, 5, 6, 7] }
                ]
            });
            this.setState({ table });
        }.bind(this));
    }

    getListSchedule () {
        ScheduleService.getSchedule(this.props.sandboxId).then((res) => {
            this.setState({ dataSet: res.data.data, loadingStatus: true });
            this.initDatatable();
            this.forceUpdate();
        });
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '480px' });
        }
    }

    handleDestroy (id, name) {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.detete.title') + name + ' ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                ScheduleService.destroySchedule(id).then((res) => {
                    this.state.table.destroy();
                    this.getListSchedule();
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                }).catch((error) => {
                    if (error.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: TranslateHelper.getMessage('respond.message.failed')
                        });
                    }
                });
            }
        });
    }

    handleChangeStateSchedule (scheduleId, event) {
        const payload = event.target.checked ? { status: 'enable' } : { status: 'disable' };
        ScheduleService.updateSchedule(scheduleId, payload).then((res) => {
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
            this.getListSchedule();
        });
    }

    handleCreateSchedule () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                sandboxId: this.props.sandboxId,
                clientId: Cookies.get('actived'),
                name: form.name.trim(),
                status: 'enable',
                dateFrom: form.dateFrom,
                dateTo: form.dateTo,
                daysOfWeek: form.daysOfWeek,
                action: {
                    start: {
                        flg: form.startFlg,
                        timeRun: this.formaterTime(form.timeRunStart)
                    },
                    stop: {
                        flg: form.stopFlg,
                        timeRun: this.formaterTime(form.timeRunStop)
                    }
                }
            };
            ScheduleService.createSchedule(payload).then((res) => {
                this.setState({
                    form: {
                        name: 'Start/Stop Daily',
                        status: 'enable',
                        dateFrom: new Date(),
                        dateTo: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                        daysOfWeek: [
                            { value: 1, label: TranslateHelper.getMessage('option.common.monday') },
                            { value: 2, label: TranslateHelper.getMessage('option.common.tuesday') },
                            { value: 3, label: TranslateHelper.getMessage('option.common.wednesday') },
                            { value: 4, label: TranslateHelper.getMessage('option.common.thursday') },
                            { value: 5, label: TranslateHelper.getMessage('option.common.friday') }
                        ],
                        startFlg: true,
                        timeRunStart: new Date(),
                        stopFlg: true,
                        timeRunStop: new Date(Date.now() + 12 * 60 * 60 * 1000)
                    }
                });
                $('.sidebar-right').css({ width: '0' });
                this.state.table.destroy();
                this.getListSchedule();
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.create.successfully')
                });
            }).catch((error) => {
                if (error.response.data) {
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                }
            });
        }
        this.forceUpdate();
    }

    renderRowTable (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item.name}</td>
                    <td>
                        <div><span><strong>From&nbsp;&nbsp;</strong></span>
                            <FormattedDate
                                value={item.dateFrom}
                                year="numeric"
                                month="numeric"
                                day="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                        </div>
                        <div><span><strong>To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></span>
                            <FormattedDate
                                value={item.dateTo}
                                year="numeric"
                                month="numeric"
                                day="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                        </div>
                    </td>
                    <td>
                        <div style={{ width: '150px' }}>
                            {item.daysOfWeek.map((day, idx) => {
                                return <span key={idx}>{day.label}<br/></span>;
                            })}
                        </div>
                    </td>
                    <td>
                        <div className="custom-control custom-switch" style={{ zIndex: 'unset' }}>
                            <input
                                style={{ zIndex: 'unset' }}
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch-status-${index}`}
                                onChange={this.handleChangeStateSchedule.bind(this, item._id)}
                                checked={item.status === 'enable'}
                            />
                            <label className="custom-control-label" htmlFor={`customSwitch-status-${index}`} style={{ fontWeight: 100 }}>On/Off</label>
                        </div>
                    </td>
                    <td>
                        <div>{item.action.start.flg ? (<span><strong>Start:&nbsp;</strong>{item.action.start.timeRun}</span>) : (<></>)}</div>
                        <div>{item.action.stop.flg ? (<span><strong>Stop:&nbsp;</strong>{item.action.stop.timeRun}</span>) : (<></>)}</div>
                    </td>
                    <td>{item.user.username}</td>
                    <td><span style={{ cursor: 'pointer' }} onClick={() => { this.handleDestroy(item._id, item.name); }}><i className="far fa-trash-alt"></i></span></td>
                </tr>
            );
        });
    }

    formaterTime (time) {
        let hour = time.getHours();
        let minute = time.getMinutes();
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        return hour + ':' + minute;
    }

    generateOption (data) {
        const result = [];
        data.map((item, index) => {
            this.state.filterOptions.map((it, id) => {
                if (item === it.value) {
                    result.push(it);
                }
                return 0;
            });
            return 0;
        });
        return result;
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'name':
                    if (value === '') {
                        isError.name = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                case 'daysOfWeek':
                    if (value.length === 0) {
                        isError.daysOfWeek = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                case 'dateTo':
                    if (value < new Date() || value < form.dateFrom) {
                        isError.dateTo = TranslateHelper.getMessage('validation.error.invalided');
                        validation = false;
                    }
                    break;
                case 'startFlg':
                    if (!value && !form.stopFlg) {
                        isError.startFlg = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleMultiSelectChange (name, option) {
        const { form, isError } = this.state;
        switch (name) {
            case 'daysOfWeek':
                form[name] = option;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'name':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            case 'startFlg': case 'stopFlg':
                form[name] = event.target.checked;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleDatePickerChange (name, date) {
        const { form, isError } = this.state;
        switch (name) {
            case 'dateFrom': case 'dateTo': case 'timeRunStart': case 'timeRunStop':
                form[name] = date;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    render () {
        const lang = localStorage.getItem('lang');
        const { isError } = this.state;
        return (
            <div>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}><h4>Schedules sandbox</h4></div>
                <div className="row">
                    <div className="col-sm-12">
                        <Button type="button" onClick={() => { this.openSidebar(); }} appearance='primary'><i className="far fa-plus-square"></i>&nbsp;<FormattedMessage id="button.name.common.create"/></Button>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className="row">
                    <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Schedule list</h3>
                        </div>
                        <div className="card-body p-0">
                            <div className='table-scrollX'>
                                {this.state.loadingStatus
                                    ? (<div>
                                        {this.state.dataSet.length
                                            ? (<table id="schedule-list" className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.name" /></th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.timestartend" /></th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.daysofweek" /></th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.state" /></th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.actiontimerun" /></th>
                                                        <th><FormattedMessage id="sandbox.schedule.table.createdby" /></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.renderRowTable(this.state.dataSet)}</tbody>
                                            </table>)
                                            : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                    </div>)
                                    : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <RightSidebar title={TranslateHelper.getMessage('sandbox.schedule.title.create')}>
                    <div className="form-group">
                        <label><FormattedMessage id="sandbox.schedule.form.lable.scheduleName"/></label>
                        <input
                            value={this.state.form.name}
                            type="text"
                            placeholder="Start/Stop Daily"
                            name="name"
                            onChange={this.handleInputChange.bind(this, 'name')}
                            className={isError.name.length > 0 ? 'is-invalid form-control' : 'form-control'}
                        />
                        {isError.name.length > 0 && (<span className="error invalid-feedback">{isError.name}</span>)}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label><FormattedMessage id="sandbox.schedule.form.lable.from"/></label>
                                <DatePicker
                                    selected={this.state.form.dateFrom}
                                    onChange={this.handleDatePickerChange.bind(this, 'dateFrom')}
                                    popperPlacement="bottom-start"
                                    showTimeSelect
                                    showTime={{ use12Hours: true, format: 'h:mm a' }}
                                    dateFormat={LanguageFormatDateTime[lang]}
                                    locale={lang}
                                    name="dateFrom"
                                    customInput={<CustomInputDateTime/>}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label><FormattedMessage id="sandbox.schedule.form.lable.to"/></label>
                                <DatePicker
                                    selected={this.state.form.dateTo}
                                    onChange={this.handleDatePickerChange.bind(this, 'dateTo')}
                                    popperPlacement="bottom-start"
                                    showTimeSelect
                                    dateFormat={LanguageFormatDateTime[lang]}
                                    locale={lang}
                                    name="dateTo"
                                    customInput={<CustomInputDateTime/>}
                                />
                                <div className={isError.dateTo.length > 0 ? 'is-invalid' : ''}></div>
                                {isError.dateTo.length > 0 && (<span className="error invalid-feedback">{isError.dateTo}</span>)}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label><FormattedMessage id="sandbox.schedule.form.lable.interval"/></label>
                        <Select
                            name="daysOfWeek"
                            placeholder="Filters"
                            value={this.state.form.daysOfWeek}
                            options={this.state.filterOptions}
                            onChange={this.handleMultiSelectChange.bind(this, 'daysOfWeek')}
                            isMulti
                        />
                        <div className={isError.daysOfWeek.length > 0 ? 'is-invalid' : ''}></div>
                        {isError.daysOfWeek.length > 0 && (<span className="error invalid-feedback">{isError.daysOfWeek}</span>)}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label><FormattedMessage id="sandbox.schedule.form.timerunstart"/></label>
                                <DatePicker
                                    selected={this.state.form.timeRunStart}
                                    onChange={this.handleDatePickerChange.bind(this, 'timeRunStart')}
                                    popperPlacement="right-start"
                                    locale={lang}
                                    name="timeRunStart"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    showTime={{ use12Hours: true, format: 'h:mm a' }}
                                    timeIntervals={5}
                                    timeCaption={TranslateHelper.getMessage('schedule.detail.format.timeCaption')}
                                    dateFormat="h:mm a"
                                    customInput={<CustomInputTime/>}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label><FormattedMessage id="sandbox.schedule.form.timerunstop"/></label>
                                <DatePicker
                                    selected={this.state.form.timeRunStop}
                                    onChange={this.handleDatePickerChange.bind(this, 'timeRunStop')}
                                    popperPlacement="right-start"
                                    locale={lang}
                                    name="timeRunStop"
                                    showTimeSelect
                                    showTime={{ use12Hours: true, format: 'h:mm a' }}
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption={TranslateHelper.getMessage('schedule.detail.format.timeCaption')}
                                    dateFormat="h:mm a"
                                    customInput={<CustomInputTime/>}
                                />
                            </div>
                        </div>
                    </div>
                    <Button type="button" appearance='primary' onClick={() => { this.handleCreateSchedule(); }}><FormattedMessage id="button.name.common.create"/></Button>
                </RightSidebar>
            </div>
        );
    }
}

export default SchedulesSbDetail;
