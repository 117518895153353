import SettingRoutes from './setting/routes';
import ProjectRoutes, { projectMenuLinks } from '../common/components/project/routes';

export const guestMenuLinks = [
    ...projectMenuLinks
];

const routes = [
    ...ProjectRoutes,
    ...SettingRoutes
];

export default routes;
