import AxiosService from '../../../common/services/axios.service';
import serviceHelpers from '../../../common/helpers/serviceHelpers';

const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager';

class Partner {
    constructor () {
        serviceHelpers();
    }

    getListPartner (page) {
        return AxiosService.get(API_URL + '/partners', { params: { page } });
    }

    getDataPartnerDetail (id) {
        return AxiosService.get(API_URL + '/partners/' + id);
    }

    createPartner (payload) {
        return AxiosService.post(API_URL + '/partners', payload);
    }

    updatePartner (id, payload) {
        return AxiosService.patch(API_URL + '/partners/' + id, payload);
    }

    deletePartner (id) {
        return AxiosService.delete(API_URL + '/partners/' + id);
    }

    getStatiscticClientId (partnerId, clientId) {
        return AxiosService.get(`${API_URL}/partners/${partnerId}/statistic/${clientId}`);
    }

    getTransactionHistory (id, params) {
        return AxiosService.get(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/subscriptions/partner/history/' + id, { params }).then(res => res);
    }
}

export default new Partner();
