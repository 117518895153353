import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';

export default function ReactPagination ({ totalPages, handlePageChange }) {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
                <Stack spacing={2}>
                    <Pagination size='large' count={totalPages} onChange={handlePageChange} color="primary" />
                </Stack>
            </Box>
        </>
    );
}
