import Slider from 'react-slick';
import ActivityLogsChart from '../../../common/components/hrm/components/organization-detail/ActivityLogsChart';
import UserLoginByDayChart from '../../../common/components/hrm/components/organization-detail/UserLoginByDayChart';

export default function DashboardSlider () {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <div className='card dashboard-slider'>
            <div className='card-body'>
                <Slider {...settings}>
                    <UserLoginByDayChart/>
                    <ActivityLogsChart/>
                </Slider>
            </div>
        </div>
    );
}
