import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Recruitment from './components/Recruitment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export const recruitmentMenuLinks = [
    {
        name: 'menu.admin.name.recruitment',
        classNames: 'nav-item',
        to: '/recruitment',
        icon: (<GroupAddIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER, ROLES.HR],
        key: 11
    }
];

const routes = [
    {
        path: '/recruitment',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS, PERMISSION_ACCESS.MEMBER_ACCESS], <Recruitment/>);
        }
    }
];

export default routes;
