import React, { Component } from 'react';
import metadata from '../../../resources/metadata.json';

class FooterSection extends Component {
    render () {
        return (
            <footer className="main-footer">
                <strong>Copyright &copy; 2021 <a href="/dashboard">Kimei Console</a>.&nbsp; </strong>
             All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}
                </div>
            </footer>
        );
    }
}

export default FooterSection;
