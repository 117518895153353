import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pie, Doughnut } from 'react-chartjs-2';
import SandboxService from '../../../services/sandbox.service';
import TranslateHelper from '../../../../../helpers/TranslateHelper';

class StoragesSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSet: null,
            dataSetChart: null
        };
    }

    /**
     * Load API
     */
    componentDidMount () {
        SandboxService.getSandboxStorage(this.props.sandboxId).then((res) => {
            const data = res.data.data.realmdata ? res.data.data : null;
            this.setState({ dataSet: data, loadingStatus: true });
            if (data) {
                this.setState({
                    dataSetChart: {
                        realmdata: {
                            labels: [TranslateHelper.getMessage('sandbox.detail.storages.space'), TranslateHelper.getMessage('sandbox.detail.storages.used')],
                            datasets: [
                                {
                                    label: 'Rainfall',
                                    backgroundColor: [
                                        '#00A6B4',
                                        '#C9DE00'
                                    ],
                                    hoverBackgroundColor: [
                                        '#003350',
                                        '#4B5000'
                                    ],
                                    data: [data.realmdata.spaceTotal - data.realmdata.spaceUsed, data.realmdata.spaceUsed]
                                }
                            ]
                        },
                        sharedata: {
                            labels: [TranslateHelper.getMessage('sandbox.detail.storages.space'), TranslateHelper.getMessage('sandbox.detail.storages.used')],
                            datasets: [
                                {
                                    label: 'Rainfall',
                                    backgroundColor: [
                                        '#00A6B4',
                                        '#C9DE00'
                                    ],
                                    hoverBackgroundColor: [
                                        '#003350',
                                        '#4B5000'
                                    ],
                                    data: [data.sharedata.spaceTotal - data.sharedata.spaceUsed, data.sharedata.spaceUsed]
                                }
                            ]
                        }
                    }
                });
            }
        });
    }

    render () {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{ height: '10px' }}></div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title"><FormattedMessage id="sandbox.detail.storages.header.storages"/></h3>
                            </div>
                            <div className="card-body p-0">
                                <div className='table-scrollX'>
                                    {this.state.loadingStatus
                                        ? (<div>
                                            {this.state.dataSet
                                                ? (<table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 10 }}>#</th>
                                                            <th><FormattedMessage id="sandbox.detail.storages.type"/></th>
                                                            <th><FormattedMessage id="sandbox.detail.storages.total"/></th>
                                                            <th><FormattedMessage id="sandbox.detail.storages.used"/></th>
                                                            <th><FormattedMessage id="sandbox.detail.storages.space"/></th>
                                                            <th><FormattedMessage id="sandbox.detail.storages.percentUsed"/></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1.</td>
                                                            <td><FormattedMessage id="sandbox.detail.storages.type.realmdata"/></td>
                                                            <td>{this.state.dataSet.realmdata.spaceTotal}</td>
                                                            <td>{this.state.dataSet.realmdata.spaceUsed}</td>
                                                            <td>{this.state.dataSet.realmdata.spaceTotal - this.state.dataSet.realmdata.spaceUsed}</td>
                                                            <td><span className={this.state.dataSet.realmdata.percentageUsed < 50 ? 'badge bg-success' : 'badge bg-warning' }>{this.state.dataSet.realmdata.percentageUsed}%</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2.</td>
                                                            <td><FormattedMessage id="sandbox.detail.storages.type.sharedata"/></td>
                                                            <td>{this.state.dataSet.sharedata.spaceTotal}</td>
                                                            <td>{this.state.dataSet.sharedata.spaceUsed}</td>
                                                            <td>{this.state.dataSet.sharedata.spaceTotal - this.state.dataSet.sharedata.spaceUsed}</td>
                                                            <td><span className={this.state.dataSet.sharedata.percentageUsed < 50 ? 'badge bg-success' : 'badge bg-warning' }>{this.state.dataSet.sharedata.percentageUsed}%</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>)
                                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                        </div>)
                                        : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '30px' }}></div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><FormattedMessage id="sandbox.detail.storages.header.statistics"/></h3>
                                </div>
                                <div className="card-body p-0">
                                    <div className='table-scrollX'>
                                        {this.state.loadingStatus && this.state.dataSetChart
                                            ? (<div className="row">
                                                <div className="col-sm-6" style={{ textAlign: 'center', padding: '0% 10%' }}>
                                                    <Pie
                                                        data={this.state.dataSetChart.realmdata}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Average Rainfall per month',
                                                                fontSize: 20
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                        <h5><FormattedMessage id="sandbox.detail.storages.type.realmdata"/></h5>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" style={{ textAlign: 'center', padding: '0% 10%' }}>
                                                    <Doughnut
                                                        data={this.state.dataSetChart.sharedata}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Average Rainfall per month',
                                                                fontSize: 20
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            }
                                                        }}
                                                    />
                                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                        <h5><FormattedMessage id="sandbox.detail.storages.type.sharedata"/></h5>
                                                    </div>
                                                </div>
                                            </div>)
                                            : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StoragesSbDetail;
