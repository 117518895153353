import serviceHelpers from '../../../common/helpers/serviceHelpers';
import axiosService from '../../../common/services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class CronJobsService {
    constructor () {
        serviceHelpers();
    }

    getAllJobs (page = 1) {
        return axiosService.get(API_URL + '/jobs', { params: { page } }).then(res => res.data.data);
    }

    reRunJob (payload) {
        return axiosService.patch(API_URL + '/jobs/re-run', payload).then(res => res.data);
    }
}

export default new CronJobsService();
