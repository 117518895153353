import { FormControl, FormHelperText, TextField, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';

export default function SelectBox ({ data, value, onChange, label = 'Label', id, error, size = '' }) {
    const [color, setColor] = useState('');
    return (
        <>
            <FormControl fullWidth>
                <Autocomplete
                    value={typeof value?.label === 'string' ? data.find(item => item.label === value?.label) : (value?.label || null)}
                    size={size}
                    options={data}
                    onChange={(e, data) => {
                        onChange(data);
                        setColor(data?.color);
                    }}
                    renderOption={(props, option) => {
                        if (option.color) {
                            return (
                                <Box component='li' sx={{ backgroundColor: option?.color, marginBottom: '10px', color: 'white' }} {...props}>
                                    {option.label}
                                </Box>
                            );
                        } else {
                            return (
                                <Box component='li' {...props}>
                                    {option.label}
                                </Box>
                            );
                        }
                    }}
                    error={error}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            sx={{ backgroundColor: value?.color || color }}
                            label={label}
                            InputLabelProps={{ sx: { backgroundColor: 'white', px: 1, borderRadius: '5px' } }} value={value?.label || ''}/>}
                        />
                <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
        </>
    );
}
