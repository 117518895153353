export const ACTION_AUTH = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',
    UPDATE_USER: 'UPDATE_USER'
};

export const ACTION_BREADCRUMB = {
    SET_BREADCRUMB: 'SET_BREADCRUMB',
    CLEAR_BREADCRUMB: 'CLEAR_BREADCRUMB'
};

export const ACTION_HRM = {
    SET_OPTIONVIEW: 'SET_OPTIONVIEW',
    SET_KIMEI_MEMBER: 'SET_KIMEI_MEMBER'
};
