import { Button, Dialog } from '@mui/material';
import moment from 'moment';

function DepartmentDetail ({ open, closeModal, data }) {
    return (
        <Dialog footer={null} open={open} onClose={closeModal} maxWidth={700}>
            <div className='modal-content custom-width'>
                <div className='modal-header'>
                    <h5 className='mb-0'>{data?.name}</h5>
                </div>
                <div className='modal-body'>
                    <div className="row my-2">
                        <div className="col-xl-5 font-weight-bold">Phòng ban cấp trên</div>
                        <div className="col-xl-5">{data?.parent?.name}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col-xl-5 font-weight-bold">Mã</div>
                        <div className="col-xl-5">{data?.code}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col-xl-5 font-weight-bold">Tên phòng ban</div>
                        <div className="col-xl-5">{data?.name}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col-xl-5 font-weight-bold">Ngày thành lập</div>
                        <div className="col-xl-5">{moment(data?.startedOn).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="row my-2">
                        <div className="col-xl-5 font-weight-bold">Người lãnh đạo</div>
                        <div className="col-xl-5">{data?.departmentHead?.firstname + ' ' + data?.departmentHead?.lastname}</div>
                    </div>
                </div>
                <div className="modal-footer justify-content-flex-end">
                    <Button
                        type="button"
                        className='cancel-btn'
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default DepartmentDetail;
