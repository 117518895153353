import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select from '@atlaskit/select';
import Button from '@atlaskit/button';
import TranslateHelper from '../../../helpers/TranslateHelper';
import projectListService from '../services/project-list.service';
import { ToastTopEnd } from '../../../helpers/ToastTimer';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { regexName } from '../../../constants/validation.constant';
import recruitmentService from '../../hrm/Services/recruitment.service';
import Default from '../images/default.jpg';
import { PROJECT_STATUS } from '../../../constants/project.constant';
import { isAllowEditProject } from '../../../helpers/CheckRoleHelper';

const statuses = [
    {
        value: PROJECT_STATUS.IN_PROGRESS,
        label: 'In progress'
    },
    {
        value: PROJECT_STATUS.HOLDING,
        label: 'Holding'
    },
    {
        value: PROJECT_STATUS.COMPLETED,
        label: 'Completed '
    }
];

const allowStatusNotiOptions = [
    {
        value: 0,
        label: 'Not Allow'
    },
    {
        value: 1,
        label: 'Allow'
    }
];

const CreateProjectForm = ({ project, isNew, projectId, role, userRoleInProject }) => {
    const history = useHistory();
    const [isManager, setIsManager] = useState(false);

    useEffect(() => {
        isAllowEditProject(role, userRoleInProject) && setIsManager(true);
    }, [isManager]);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        defaultValues: {
            status: '',
            blob: ''
        }
    });

    const [image, setImage] = useState(null);
    const imageRef = useRef(null);

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            setImage(URL.createObjectURL(img));
        }
    };

    const handleClick = event => {
        imageRef.current.click();
    };

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('name', data.name.trim().replace(/\s\s+/g, ' '));
        formData.append('description', data.description?.trim());
        formData.append('status', data.status.value);
        formData.append('identifier', data.identifier);
        formData.append('slackChannel', data.slackChannel);
        formData.append('file', imageRef.current?.files[0]);
        formData.append('allowStatusNoti', data.allowStatusNoti.value);
        isNew ? handleCreateProject(formData) : handleUpdateProject(formData);
    };

    const handleUpdateProject = useCallback(async (formData) => {
        try {
            await projectListService.updateOne(projectId, formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successed')
            });
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.update.failed')
            });
        }
    }, []);

    const handleCreateProject = useCallback(async (formData) => {
        try {
            await projectListService.createProject(formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });
            reset();
            history.push('/projects');
        } catch (error) {
            console.warn(error);
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    }, []);

    const selectStyle = {
        control: (base) => ({
            ...base,
            borderColor: errors.status ? 'red' : '#ced4da',
            borderRadius: '0.3rem'
        })
    };

    useEffect(() => {
        if (project) {
            const { name, description, identifier, status, slackChannel, allowStatusNoti } = project;
            setValue('name', name);
            setValue('identifier', identifier);
            const statusOption = statuses.find((el) => el.value === status);
            setValue('status', statusOption);
            setValue('slackChannel', slackChannel);
            setValue('description', description);
            const allowStatusNotiOpt = allowStatusNotiOptions.find((obj) => obj.value === allowStatusNoti);
            setValue('allowStatusNoti', allowStatusNotiOpt);
            if (project.files?.path) {
                const img = project.files.path;
                setImage(recruitmentService.buildFileURL(img));
            }
        }
    }, [project]);

    return (
        <div className="card">
            <div className="card-body ">
                <form onSubmit={handleSubmit(onSubmit)} className='row d-flex align-items-center'>
                    <div className="form-horizontal col-xl-9 col-12">
                        <div className="form-group row">
                            <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.name" />
                            </label>
                            <div className="col-sm-10">
                                <input
                                    disabled={!isManager}
                                    className={
                                        errors.name
                                            ? 'is-invalid form-control  p-2'
                                            : 'form-control'
                                    }
                                    {...register('name', {
                                        required: TranslateHelper.getMessage(
                                            'validation.error.required'
                                        ),
                                        pattern: {
                                            value: regexName,
                                            message: TranslateHelper.getMessage(
                                                'validation.error.containSpecialCharacter'
                                            )
                                        },
                                        maxLength: {
                                            value: 25,
                                            message: TranslateHelper.getMessage(
                                                'validation.error.max25characters'
                                            )
                                        }
                                    })}
                                />
                                {errors.name && (
                                    <span className="error text-danger">{errors.name.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputDescription" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.description" />
                            </label>
                            <div className='col-sm-10'>
                                <textarea
                                    disabled={!isManager}
                                    id='project-editor'
                                    className={`bg-white w-100 border ${
                                        errors.description ? 'border-danger' : ''
                                    }`}
                                    style={{ height: '100px' }}
                                    {...register('description', { maxLength: 100 })}
                                ></textarea>
                                {errors.description && (
                                    <span className="error text-danger">{TranslateHelper.getMessage('validation.error.max100characters')}</span>
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputIdentifier" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.identifier" />
                            </label>
                            <div className="col-sm-10">
                                <input
                                    readOnly={!isNew}
                                    className={
                                        errors.identifier
                                            ? 'is-invalid form-control  p-2 '
                                            : 'form-control'
                                    }
                                    {...register('identifier', {
                                        required: TranslateHelper.getMessage(
                                            'validation.error.required'
                                        ),
                                        pattern: {
                                            value: /^(?!\s+$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                            message: TranslateHelper.getMessage(
                                                'validation.error.containSpecialCharacter'
                                            )
                                        },
                                        maxLength: {
                                            value: 5,
                                            message: TranslateHelper.getMessage(
                                                'validation.error.max5characters'
                                            )
                                        }
                                    })}
                                />
                                {errors.identifier && (
                                    <span className="error text-danger">
                                        {errors.identifier.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputSlackId" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.slackId" />
                            </label>
                            <div className="col-sm-10">
                                <input
                                    className={
                                        errors.identifier
                                            ? 'is-invalid form-control  p-2 '
                                            : 'form-control'
                                    }
                                    {...register('slackChannel')}
                                    disabled={!isManager}
                                />
                                {errors.slackChannel && (
                                    <span className="error text-danger">
                                        {errors.slackChannel.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputStatus" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.status" />
                            </label>
                            <div className="col-xl-3 col-sm-10" htmlFor="inputStatus">
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            options={statuses}
                                            onChange={onChange}
                                            value={value}
                                            selected={value}
                                            styles={selectStyle}
                                            isDisabled={!isManager}
                                        />
                                    )}
                                    name="status"
                                />
                                {errors.status && (
                                    <span className="error text-danger">
                                        <FormattedMessage id="validation.error.select" />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="allowStatusNoti" className="col-sm-2 col-form-label">
                                <FormattedMessage id="project.detail.settings.general.label.issueStatusNoti" />
                            </label>
                            <div className="col-xl-3 col-sm-10" htmlFor="allowStatusNoti">
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            options={allowStatusNotiOptions}
                                            onChange={onChange}
                                            value={value}
                                            selected={value}
                                            styles={selectStyle}
                                            isDisabled={!isManager}
                                        />
                                    )}
                                    name="allowStatusNoti"
                                />
                                {errors.status && (
                                    <span className="error text-danger">
                                        <FormattedMessage id="validation.error.select" />
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='form-horizontal col-xl-3 col-12 '>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <div>
                                <div style={{ background: 'darkGray', borderRadius: '50%', width: '203px', padding: '2px' }}>
                                    <img onClick={handleClick} src={image || Default} style={{ borderRadius: '50%', height: '200px', width: '200px', objectFit: 'cover' }} />
                                </div>
                                <div onClick={handleClick} style={{ borderRadius: '50%', background: '#4e4f50', width: '31px', padding: '5px', marginTop: '-43px', position: 'relative', marginRight: '-138px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <input
                            {...register('file')}
                            ref={imageRef}
                            className='d-none'
                            type="file"
                            name="myImage"
                            accept="image/*"
                            onChange={onImageChange}
                            disabled={!isManager}
                        />
                    </div>
                    {isAllowEditProject(role, userRoleInProject) && (
                            <div className="form-horizontal ">
                                <div className=" col-12">
                                    <Button
                                        type="submit"
                                        appearance='primary'
                                    >
                                        <FormattedMessage
                                            id={
                                                isNew
                                                    ? TranslateHelper.getMessage(
                                                        'button.name.common.create'
                                                    )
                                                    : 'button.name.common.save'
                                            }
                                        />
                                    </Button>
                                </div>
                            </div>
                    )}
                </form>
            </div>
        </div>
    );
};

function mapStateToProps (state) {
    const { role } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        role,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(CreateProjectForm);
