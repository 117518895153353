import { useContext, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import Select from '@atlaskit/select';
import recruitmentService from '../../Services/recruitment.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import RecruitmentContext from '../../../../context/RecruitmentContext';
import Button from '@atlaskit/button';
import { Box } from '@mui/material';

export default function CreateInterviewForm ({ refetch, CVSelect, isNew, CV }) {
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
        watch,
        clearErrors
    } = useForm({ mode: 'onChange', defaultValues: { flag: false } });
    const hideModalButtonRef = useRef();
    const defaultValues = {
        id: null,
        statusInterview: null,
        formatInterview: null,
        locationInterview: null,
        interviewers: null,
        file: undefined
    };
    const onsubmit = async (data) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            if (value && typeof value === 'object' && 'value' in value) {
                formData.append(key, value.value);
                continue;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === 'object' && 'value' in item) {
                        formData.append(key, item.value);
                        continue;
                    }
                }
                continue;
            }
            formData.append(key, value);
        }
        const payload = {
            id: CVSelect._id,
            statusInterview: data.statusInterview.value,
            formatInterview: data.formatInterview.value,
            locationInterview: data.locationInterview.value,
            interviewers: data.interviewers.map(item => item.value),
            dateInterview: data.dateInterview,
            file: CVSelect.fileCVsetting
        };
        try {
            await recruitmentService.createRecruitmentInterview(payload);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });
            reset(defaultValues);
            hideModalButtonRef.current?.click();
            return refetch();
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };

    const { interviewers } = useContext(RecruitmentContext);

    const selectStyle = {
        control: (base) => ({
            ...base,
            borderColor: errors.status ? 'red' : '#ced4da',
            borderRadius: '0.3rem'
        })
    };

    const isGoogleMeetRoom = watch('locationInterview')?.value === '1';

    useEffect(() => {
        if (CVSelect) {
            reset({
                statusInterview: null,
                formatInterview: null,
                locationInterview: null,
                interviewers: null,
                dateInterview: ''
            });
            setValue(
                'id',
                CV.find((item) => item.value === CVSelect._id)
            );
            setValue(
                'statusInterview',
                recruitmentService.statues.find((item) => item.value === CVSelect.statusInterview)
            );
        }
    }, [CVSelect]);

    return (
        <div
            className="modal fade"
            id="modal-employees"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal-employees"
            aria-hidden="true"
        >
            <div
                className="d-flex justify-content-center align-items-center modal-dialog modal-scrollable mw-100 w-75"
                id="interview-modal"
            >
                <div className="modal-content px-3">
                    <div className="modal-header text-uppercase">
                        <h4 className="modal-title">
                            <FormattedMessage id="HRM.detail.recruitment.title.createInterview" />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={hideModalButtonRef}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <form className="row" onSubmit={handleSubmit(onsubmit)}>
                            <div className="form-horizontal col-xl-6 col-12">
                                <div className="form-group row">
                                    <label htmlFor="CV" className="col-form-label col-xl-3 col-5">
                                        <FormattedMessage id="HRM.detail.recruitment.tab.name.interview" />
                                    </label>
                                    <div className="col-xl-6 col-7" htmlFor="CV">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Select
                                                    options={CV}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={selectStyle}
                                                    placeholder="Candidate"
                                                    isDisabled={!isNew}
                                                />
                                            )}
                                            name="id"
                                        />
                                        {errors.id && (
                                            <span className="error text-danger">
                                                <FormattedMessage id="validation.error.select" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="dateInterview"
                                        className="col-xl-3 col-5 col-form-label"
                                    >
                                        <FormattedMessage id="HRM.detail.recruitment.table.name.status" />
                                    </label>
                                    <div className="col-xl-6 col-7">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Select
                                                    options={recruitmentService.statues}
                                                    formatOptionLabel={option => {
                                                        return (
                                                            <Box component='p' sx={{ p: 1, backgroundColor: option?.color, my: 1, color: 'white' }}>
                                                                {option.label}
                                                            </Box>
                                                        );
                                                    }}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={selectStyle}
                                                    placeholder="Status"
                                                />
                                            )}
                                            name="statusInterview"
                                        />
                                        {errors.statusInterview && (
                                            <span className="error text-danger">
                                                <FormattedMessage id="validation.error.select" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="formatInterview"
                                        className="col-form-label col-xl-3 col-5"
                                    >
                                        <FormattedMessage id="HRM.detail.recruitment.table.name.type" />
                                    </label>
                                    <div className="col-xl-6 col-7" htmlFor="formatInterview">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Select
                                                    options={recruitmentService.formatInterviews}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={selectStyle}
                                                    placeholder="Type"
                                                />
                                            )}
                                            name="formatInterview"
                                        />
                                        {errors.formatInterview && (
                                            <span className="error text-danger">
                                                <FormattedMessage id="validation.error.select" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-horizontal col-xl-6 col-12">
                                <div className="form-group row">
                                    <label
                                        htmlFor="dateInterview"
                                        className="col-xl-3 col-5 col-form-label"
                                    >
                                        <FormattedMessage id="HRM.detail.HR.recruitment.label.interviewDateTime" />
                                    </label>
                                    <div className="col-xl-6 col-7">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    selected={value}
                                                    onChange={onChange}
                                                    className="form-control"
                                                    placeholderText={TranslateHelper.getMessage(
                                                        'date.format'
                                                    )}
                                                    popperPlacement="right-end"
                                                    dateFormat={TranslateHelper.getMessage(
                                                        'date.format.hours'
                                                    )}
                                                    minDate={new Date()}
                                                />
                                            )}
                                            name="dateInterview"
                                        />
                                        {errors.dateInterview && (
                                            <span className="error text-danger">
                                                {errors.dateInterview.message}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="locationInterview"
                                        className="col-form-label col-xl-3 col-5"
                                    >
                                        <FormattedMessage id="HRM.detail.recruitment.table.name.location" />
                                    </label>
                                    <div className="col-xl-6 col-7" htmlFor="locationInterview">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Select
                                                    options={recruitmentService.locationInterview}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={selectStyle}
                                                    placeholder="Location"
                                                />
                                            )}
                                            name="locationInterview"
                                        />
                                        {errors.locationInterview && (
                                            <span className="error text-danger">
                                                <FormattedMessage id="validation.error.select" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="interviewers"
                                        className="col-form-label col-xl-3 col-5"
                                    >
                                        <FormattedMessage id="HRM.detail.HR.recruitment.label.interviewers" />
                                    </label>
                                    <div className="col-xl-6 col-7" htmlFor="interviewers">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Select
                                                    isMulti
                                                    options={interviewers}
                                                    onChange={onChange}
                                                    value={value}
                                                    styles={selectStyle}
                                                    placeholder="Select Interviewers"
                                                />
                                            )}
                                            name="interviewers"
                                        />
                                        {errors.interviewers && (
                                            <span className="error text-danger">
                                                <FormattedMessage id="validation.error.select" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {isGoogleMeetRoom && (
                                    <div className="form-group row">
                                        <label
                                            htmlFor="googleMeetUrl"
                                            className="col-form-label col-xl-3 col-5"
                                        >
                                            <FormattedMessage id="HRM.detail.HR.official.label.googleMeetURL" />
                                        </label>
                                        <div className="col-xl-6 col-7">
                                            <input
                                                type="url"
                                                placeholder="https://meet.google.com/xxx-yyyy-zzz"
                                                className={
                                                    errors.name
                                                        ? 'is-invalid form-control p-2'
                                                        : 'form-control'
                                                }
                                                {...register('googleMeetUrl', {
                                                    pattern: {
                                                        value: /^(http|https):\/\/meet.google.com\/(_meet\/)?[a-z]{3}-[a-z]{4}-[a-z]{3}$/gi,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.invalidGoogleMeetURL'
                                                        )
                                                    }
                                                })}
                                            />
                                            {errors.googleMeetUrl && (
                                                <span className="error text-danger">
                                                    {errors.googleMeetUrl.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex w-100 justify-content-end px-4 mt-4">
                                <Button
                                    className="col-xl-2 col-4 mr-2"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        // reset(defaultValues);
                                        clearErrors();
                                    }}
                                >
                                    <FormattedMessage id="button.name.common.cancel" />
                                </Button>
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    className="col-xl-2 col-4 "
                                >
                                    <FormattedMessage id="button.name.common.create" />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
