import axiosService from '../common/services/axios.service';
import serviceHelpers from '../common/helpers/serviceHelpers';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class LandingService {
    constructor () {
        serviceHelpers();
    }

    createSubscription (payload) {
        return axiosService.post(API_URL + '/subscriptions/visitor', payload).then(res => res.data.data);
    }
}

export default new LandingService();
