import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { useLayoutEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormattedMessage } from 'react-intl';
import MyDetail from './general-detail/MyDetail';
import MyHoliday from './general-detail/MyHoliday';
import MyTeam from './general-detail/MyTeam';

export default function General ({ dispatch }) {
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.hrm',
                    haslink: true,
                    path: '/hrm'
                },
                {
                    label: 'app.breadcrumb.common.hrm.general',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            dispatch(clearBreadcrumb());
        };
    }, [dispatch]);
    return (
        <>
            <Tabs className="row">
                <TabList className='col-12 col-xl-2 d-flex flex-column tab-list-sp-general'>
                    <Tab><FormattedMessage id='HRM.general.tab.name.detail'/></Tab>
                    <Tab><FormattedMessage id='HRM.general.tab.name.holiday'/></Tab>
                    <Tab><FormattedMessage id='HRM.general.tab.name.team'/></Tab>
                </TabList>
                <div className="col-xl-10 col-12">
                    <TabPanel><MyDetail/></TabPanel>
                    <TabPanel><MyHoliday/></TabPanel>
                    <TabPanel><MyTeam/></TabPanel>
                </div>
            </Tabs>
        </>
    );
}
