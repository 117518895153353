/* eslint-disable no-unused-vars */
import AvatarEditor from 'react-avatar-editor';
import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import TranslateHelper from '../../helpers/TranslateHelper';
import { FormattedMessage } from 'react-intl';
import recruitmentService from '../hrm/Services/recruitment.service';

function Avatar ({ user, setEditorRef, onClickSave }) {
    const [rotateSelected, setRotate] = useState(0);
    const [avatarUrl, setavatarUrl] = useState('');
    const [scale, setScale] = useState(1);
    const EditorRef = useRef({});
    const changeRotate = (next) => {
        if (next === 3) {
            setRotate(0);
        } else {
            setRotate(next);
        }
    };

    const onAvatarChange = (picture) => {
        picture?.target?.files[0] && setavatarUrl(URL.createObjectURL(picture?.target?.files[0]));
        picture.target.value = null;
    };

    const handleModal = () => {
        $('#editImage').click(function (event) {
            if (!$(event.target).closest('#modalLand').length && !$(event.target).is('#modalLand')) {
                setRotate(0);
                setScale(1);
                setavatarUrl('');
            }
        });
    };

    useEffect(handleModal, [handleModal]);

    const changeScale = (scaleNumber) => {
        setScale(parseFloat(scaleNumber));
    };

    const rotate = [0, 90, 180, 360];

    return (
        <>
                <div className='col-sm-4 col-4' style={{ display: 'inline-flex', alignSelf: 'center', justifyContent: 'center' }}>
                    <div className="avatar-upload">
                        <div className="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={onAvatarChange} data-toggle="modal" data-target="#editImage" />
                            <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview">
                            <div id="imagePreview" style={{ backgroundImage: "url('" + (avatarUrl === '' ? (String(user.imageUrl).includes('uploads') ? recruitmentService.buildFileURL(user.imageUrl) : user.imageUrl) : avatarUrl) + "')" }}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id='editImage' tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" id = 'modalLand'>
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: 'black' }}>
                                <h5 className="modal-title" style={{ color: 'white' }}>Crop & rotate</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setRotate(0); setScale(1); setavatarUrl(''); }}>
                                <span aria-hidden="true" style={{ color: 'white' }}>&times;</span>
                                </button>
                            </div>
                            <div style={{ padding: '0px', alignSelf: 'center', marginBottom: '-6.6px' }} className="modal-body">
                                {
                                    <AvatarEditor
                                        crossOrigin='anonymous'
                                        image={(avatarUrl === '' ? (String(user.imageUrl).includes('uploads') ? recruitmentService.buildFileURL(user.imageUrl) : user.imageUrl) : avatarUrl)}
                                        width={400}
                                        height={400}
                                        ref={EditorRef}
                                        border={[200, 0, 200, 0]}
                                        onLoadFailure={ (e) => { console.log(e); } }
                                        borderRadius={250}
                                        color={[0, 0, 0, 0.6]} // RGBA
                                        scale={scale}
                                        rotate={rotate[rotateSelected]}
                                    />
                                }
                            </div>
                            <div className="modal-footer" style={{ backgroundColor: 'black', position: 'relative', height: '80px' }}>
                                <div className="slidecontainer">
                                    <input type="range" min={1.0} max={3} step={0.01} className="slider" id="myRange" value={scale} onChange={(e) => { changeScale(e.target.value); }}/>
                                </div>
                                <div onClick={() => { changeRotate(rotateSelected + 1); }} style={{ position: 'absolute', left: '45.5%', width: '20%', color: 'white', cursor: 'pointer' }}><span className="material-icons" style={{ fontSize: '12px', color: 'white', alignSelf: 'end' }}>rotate_90_degrees_cw</span > <FormattedMessage id="button.name.common.rotate"/></div>
                                <div style={{ position: 'absolute', right: '0px' }} type="button" id="submitAdd" onClick={ (async () => { await setEditorRef(EditorRef); onClickSave(); })} className="btn btn-info"> {TranslateHelper.getMessage('button.name.common.profile.avatar.save')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    return {
        user, role
    };
}

export default connect(mapStateToProps)(Avatar);
