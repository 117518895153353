import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageDatatables } from '../../../../../../lang';
import ClientIdService from '../../services/client-id.service';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../../actions/breadcrumb.action';
import { setClientIdActived } from '../../actions/client-id.action';
import RightSidebar from '../../../common/RightSidebar';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Select from '@atlaskit/select';
import ShowForPermission from '../../../common/ShowForPermissionComponent';
import Button from '@atlaskit/button';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { IconButton, Tooltip } from '@mui/material';
import MenuExpandClientId from './MenuExpandClientId';
import { withRouter } from 'react-router-dom';
import accountService from '../../../../../manager/account/services/account.service';
class ClientIdManagement extends Component {
    constructor (props) {
        super(props);
        this.state = {
            sidebarState: 'create',
            dataSet: [],
            clientIdActive: [],
            loadingStatus: false,
            form: {
                id: '',
                name: '',
                clientId: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            },
            isError: {
                id: '',
                name: '',
                clientId: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            },
            currentForm: {
                name: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            },
            isDifferentUpdate: {
                name: false,
                clientSecret: false,
                urlSbManagement: false,
                urlSbRealmsManagement: false
            },
            modalData: {
                clientId: '',
                title: '',
                dataMember: []
            },
            memberArray: [],
            memberOptions: []
        };
        this.initDatatable = this.initDatatable.bind(this);
        this.handleChangeSelectMember = this.handleChangeSelectMember.bind(this);
        this.role = props.role;
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.clientId');
        const { dispatch } = this.props;
        dispatch(
            setBreadcrumb({
                title: 'menu.member.name.sandbox.clientid',
                breadcrumbItems: [
                    {
                        label: 'app.breadcrumb.common.home',
                        haslink: true,
                        path: '/dashboard'
                    },
                    {
                        label: 'menu.member.name.sandbox.sandboxes',
                        haslink: false,
                        path: ''
                    },
                    {
                        label: 'menu.member.name.sandbox.clientid',
                        haslink: false,
                        path: ''
                    }
                ]
            })
        );
        this.getListClientId();
        this.getDataMember();
        if (this.props.location.state?.isRedirect) {
            Swal.fire({
                icon: 'warning',
                title: TranslateHelper.getMessage('respond.message.notAuthorize')
            });
        }
    }

    /**
     * Clear data component
     */
    componentWillUnmount () {
        const { dispatch } = this.props;
        // clear breadcrum
        dispatch(clearBreadcrumb());
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '450px' });
        }
    }

    onClickCreate () {
        this.setState({
            sidebarState: 'create',
            form: {
                name: '',
                clientId: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            },
            isError: {
                name: '',
                clientId: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            }
        });
        this.openSidebar();
    }

    onClickUpdate (clientId) {
        this.setState({
            sidebarState: 'update',
            form: {
                id: clientId._id,
                name: clientId.name,
                clientId: clientId.clientId,
                clientSecret: clientId.clientSecret,
                urlSbManagement: clientId.urlSbManagement,
                urlSbRealmsManagement: clientId.urlSbRealmsManagement
            },
            currentForm: {
                name: clientId.name,
                clientSecret: clientId.clientSecret,
                urlSbManagement: clientId.urlSbManagement,
                urlSbRealmsManagement: clientId.urlSbRealmsManagement
            },
            isError: {
                id: '',
                name: '',
                clientId: '',
                clientSecret: '',
                urlSbManagement: '',
                urlSbRealmsManagement: ''
            }
        });
        this.openSidebar();
    }

    onClickCancelUpdate () {
        $('.sidebar-right').css({ width: '0' });
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(
            function () {
                const table = $('#client-ids-list').DataTable({
                    paging: true,
                    lengthChange: false,
                    searching: true,
                    ordering: true,
                    info: false,
                    autoWidth: true,
                    responsive: true,
                    columns: [null, null, null, null, { width: '5%' }, null],
                    columnDefs: [
                        { className: 'dt-center', targets: [4, 5] },
                        { orderable: false, targets: [2, 5] }
                    ],
                    language: LanguageDatatables[lang],
                    fnDrawCallback: function (oSettings) {
                        if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                            $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                        } else {
                            $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                        }
                    }
                });
                this.setState({ table });
            }.bind(this)
        );
    }

    getListClientId () {
        ClientIdService.getList().then((res) => {
            if (res.status === 200) {
                this.setState({ dataSet: res.data.data.items, loadingStatus: true });
                this.initDatatable();
                this.forceUpdate();
            } else {
                //
            }
        });
    }

    getDataMember () {
        accountService.getPartnerMember(9999).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.items?.filter(member => member.username !== this.props.user.email).map(member => (
                    {
                        value: member?.username,
                        label: member?.firstname + ' ' + member?.lastname + ' ' + '(' + member?.username + ')'
                    }
                ));
                this.setState({ memberOptions: list });
            }
        });
    }

    handleAuthorizeClientId (clientId, clientSecret) {
        Swal.fire({
            title: TranslateHelper.getMessage('loading.title.clientid.authorize'),
            html: TranslateHelper.getMessage('loading.html.common'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
                ClientIdService.authorizeClientId(clientId, clientSecret)
                    .then((res) => {
                        Swal.close();
                        const { dispatch } = this.props;
                        dispatch(setClientIdActived(clientId));
                        Cookies.set(clientId, res.data.access_token, { expires: 1 / 48 });
                        Cookies.set('actived', clientId, { expires: 1 / 48 });
                        Cookies.remove('unAuthorized');
                        this.state.table.destroy();
                        this.getListClientId();
                        window.location.reload();
                    })
                    .catch((error) => {
                        Swal.close();
                        if (error.response.data) {
                            if (error.response.data.error === 'invalid_client') {
                                ToastTopEnd.fire({
                                    icon: 'error',
                                    title:
                                        'Invalid client! ' + error.response.data.error_description
                                });
                            } else {
                                ToastTopEnd.fire({
                                    icon: 'error',
                                    title: TranslateHelper.getMessage('respond.message.failed')
                                });
                            }
                        }
                    });
            }
        });
    }

    handleLogoutClientId (clientId) {
        const { dispatch } = this.props;
        dispatch(setClientIdActived(''));
        Cookies.remove('actived');
        Cookies.remove(clientId);
        Cookies.remove('clientId');
        window.location.reload();
        this.forceUpdate();
    }

    handleDeleteClientId (clientId) {
        Swal.fire({
            title: 'Delete ' + clientId + ' ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                ClientIdService.deleteClientId(clientId)
                    .then((rep) => {
                        this.state.table.destroy();
                        this.getListClientId(true);
                        if (this.props.actived === clientId) {
                            const { dispatch } = this.props;
                            dispatch(setClientIdActived(''));
                            Cookies.remove('actived');
                            window.location.reload();
                        }
                        Cookies.remove(clientId);
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.delete.successfully')
                        });
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            ToastTopEnd.fire({
                                icon: 'error',
                                title: TranslateHelper.getMessage('respond.message.failed')
                            });
                            $('.sidebar-right').css({ width: '0' });
                        }
                    });
            }
        });
    }

    handleChangeSelectMember (option) {
        this.setState((state) => {
            return {
                memberArray: option
            };
        });
    }

    handleOpenModelAssignMember (clientId) {
        ClientIdService.getClientId(clientId)
            .then((res) => {
                const data = res.data.data;
                this.clearData();
                this.setState({
                    modalData: {
                        clientId: data._id,
                        title: data.name,
                        dataMember: data.partnerMembers
                    }
                });
            })
            .catch((error) => {
                if (error.response.data) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: TranslateHelper.getMessage('respond.message.fail')
                    });
                }
            });
    }

    clearData () {
        this.setState({
            memberArray: null
        });
    }

    handleAssignMember () {
        const memberExist = this.state.modalData.dataMember;
        const memberNeedToAdd = this.state.memberArray;
        const memberNeedToAddString = JSON.stringify(this.state.memberArray);
        let isValid = true;
        let usernameExist = '';
        for (const key in memberExist) {
            if (Object.hasOwnProperty.call(memberExist, key)) {
                const mem = memberExist[key];
                if (memberNeedToAddString.indexOf(mem.username) > -1 && isValid) {
                    usernameExist = mem.username;
                    isValid = false;
                }
            }
        }
        if (!isValid) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    usernameExist +
                    ' ' +
                    TranslateHelper.getMessage('respond.message.error.partner.member.assign')
            });
        } else {
            const arrayData = [];
            for (const key in memberNeedToAdd) {
                if (Object.hasOwnProperty.call(memberNeedToAdd, key)) {
                    const ele = memberNeedToAdd[key];
                    arrayData.push(ele.value);
                }
            }
            const payload = {
                partnerMemberList: arrayData
            };
            if (this.state.memberArray !== null && this.state.memberArray.length > 0) {
                ClientIdService.assignMemberToClientIds(this.state.modalData.clientId, payload)
                    .then((res) => {
                        const data = res.data.data;
                        this.setState({
                            modalData: {
                                clientId: data._id,
                                title: data.name,
                                dataMember: data.partnerMembers
                            }
                        });
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.update.successfully')
                        });
                        this.clearData();
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            ToastTopEnd.fire({
                                icon: 'error',
                                title: TranslateHelper.getMessage(
                                    'respond.message.assign.member.fail'
                                )
                            });
                        }
                    });
            }
        }
    }

    handleUnAssignMember (clientId, member) {
        const payload = {
            username: member
        };
        ClientIdService.unassignMemberToClientIds(clientId, payload)
            .then((res) => {
                const data = res.data.data;
                this.setState({
                    modalData: {
                        clientId: data._id,
                        title: data.name,
                        dataMember: data.partnerMembers
                    }
                });
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
            })
            .catch((error) => {
                if (error.response.data) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: TranslateHelper.getMessage('respond.message.assign.member.fail')
                    });
                }
            });
    }

    handleUpdateClientId () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                name: form.name.trim(),
                clientId: form.clientId.trim(),
                clientSecret: form.clientSecret.trim(),
                urlSbManagement: form.urlSbManagement.trim(),
                urlSbRealmsManagement: form.urlSbRealmsManagement.trim()
            };
            ClientIdService.updateClientId(form.id, payload)
                .then((rep) => {
                    this.state.table.destroy();
                    this.getListClientId(true);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.update.successfully')
                    });
                    this.setState({
                        sidebarState: 'update',
                        currentForm: {
                            name: form.name.trim(),
                            clientSecret: form.clientSecret.trim(),
                            urlSbManagement: form.urlSbManagement.trim(),
                            urlSbRealmsManagement: form.urlSbRealmsManagement.trim()
                        },
                        isDifferentUpdate: {
                            name: false,
                            clientSecret: false,
                            urlSbManagement: false,
                            urlSbRealmsManagement: false
                        }
                    });
                    $('.sidebar-right').css({ width: '0' });
                })
                .catch((error) => {
                    if (error.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: TranslateHelper.getMessage('respond.message.failed')
                        });
                    }
                });
        }
        this.forceUpdate();
    }

    handleCreateClientId () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                name: form.name.trim(),
                clientId: form.clientId.trim(),
                clientSecret: form.clientSecret.trim(),
                urlSbManagement: form.urlSbManagement.trim(),
                urlSbRealmsManagement: form.urlSbRealmsManagement.trim()
            };
            ClientIdService.createClientId(payload)
                .then((rep) => {
                    this.state.table.destroy();
                    this.getListClientId();
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.create.successfully')
                    });
                    $('.sidebar-right').css({ width: '0' });
                })
                .catch((error) => {
                    if (error.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });
                    }
                });
        }
        this.forceUpdate();
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'name':
            case 'clientId':
            case 'clientSecret':
            case 'urlSbManagement':
            case 'urlSbRealmsManagement':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleInputChangeUpdate (name, event) {
        const { form, isError, currentForm, isDifferentUpdate } = this.state;
        switch (name) {
            case 'name':
            case 'clientSecret':
            case 'urlSbManagement':
            case 'urlSbRealmsManagement':
                form[name] = event.target.value;
                if (currentForm[name] !== event.target.value) {
                    isDifferentUpdate[name] = true;
                    this.setState({ isDifferentUpdate, form });
                } else {
                    isError[name] = '';
                    isDifferentUpdate[name] = false;
                    this.setState({ isError, form, isDifferentUpdate });
                }
                break;
            default:
                break;
        }
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'name':
                case 'clientId':
                case 'clientSecret':
                case 'urlSbManagement':
                case 'urlSbRealmsManagement':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    render () {
        const { isError, isDifferentUpdate } = this.state;
        const rowData = this.state.dataSet.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                        <span className="wordWrap">{item.clientId}</span>
                    </td>
                    <td>********</td>
                    <td>
                        <FormattedDate
                            value={item.createdAt}
                            year="numeric"
                            month="numeric"
                            day="numeric"
                            hour="numeric"
                            minute="numeric"
                        />
                    </td>
                    <td>
                        {this.props.actived && item.clientId === this.props.actived
                            ? (
                                <Tooltip title='Logout'>
                                    <IconButton
                                        onClick={() => {
                                            this.handleLogoutClientId(item.clientId);
                                        }}
                                    >
                                        <CheckBoxOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                                )
                            : (
                                <Tooltip title='Authorize'>
                                    <IconButton
                                        onClick={() => {
                                            this.handleAuthorizeClientId(
                                                item.clientId,
                                                item.clientSecret
                                            );
                                        }}
                                    >
                                        <CheckBoxOutlineBlankOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                                )}
                    </td>
                    <td>
                        <ShowForPermission permissionKey='updateAndDeleteClientId'>
                            <MenuExpandClientId
                                onClickUpdate={() => this.onClickUpdate(item)}
                                handleDeleteClientId={() => this.handleDeleteClientId(item._id)}
                                handleOpenModelAssignMember={() => this.handleOpenModelAssignMember(item._id)}
                            />
                        </ShowForPermission>
                    </td>
                </tr>
            );
        });
        return (
            <div className="container-fluid">
                <ShowForPermission permissionKey="addClientId">
                    <div className="row">
                        <div className="col-sm-12">
                            <Button
                                type="button"
                                onClick={() => {
                                    this.onClickCreate();
                                }}
                                appearance='primary'
                                className="col-xl-1 col-3 "
                            >
                                <FormattedMessage id="button.name.common.add" />
                            </Button>
                        </div>
                    </div>
                </ShowForPermission>
                <div style={{ height: '10px' }}></div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                    <FormattedMessage id="clientid.table.title" />
                                </h3>
                            </div>
                            <div className="card-body">
                                {this.state.loadingStatus
                                    ? (
                                    <div className="table-scrollX-clientId">
                                        {this.state.dataSet.length
                                            ? (
                                            <table
                                                id="client-ids-list"
                                                className="table table-bordered table-hover"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.name" />
                                                        </th>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.clientid" />
                                                        </th>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.clientsecret" />
                                                        </th>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.createdate" />
                                                        </th>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.authorized" />
                                                        </th>
                                                        <th>
                                                            <FormattedMessage id="clientid.table.column.name.action" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>{rowData}</tbody>
                                            </table>
                                                )
                                            : (
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <p>
                                                    <strong>
                                                        <FormattedMessage id="message.no.data.available" />
                                                    </strong>
                                                </p>
                                            </div>
                                                )}
                                    </div>
                                        )
                                    : (
                                    <div className="overlay">
                                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                    </div>
                                        )}
                            </div>
                        </div>
                    </div>
                </div>
                <ShowForPermission permissionKey='assignClientId'>
                    <div className="modal hide fade" id="modal-assign-member">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{this.state.modalData.title}</h4>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="select-member col-xl-5 col-12">
                                            <Select
                                                value={this.state.memberArray}
                                                options={this.state.memberOptions}
                                                placeholder="Choose username"
                                                onChange={(e) => this.handleChangeSelectMember(e)}
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className="table-member col-xl-7 col-12">
                                            <div className="table-scrollX">
                                                <table
                                                    id="milestone.issue-list"
                                                    className="table table-bordered table-hover"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <FormattedMessage id="clientid.table.column.name.name" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="clientid.table.column.name.username" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="clientid.table.column.name.action" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.modalData.dataMember.length <
                                                        1
                                                            ? (
                                                            <></>
                                                                )
                                                            : (
                                                                    this.state.modalData.dataMember.map(
                                                                        (member) => {
                                                                            return (
                                                                        <tr key={member._id}>
                                                                                    <td>
                                                                                        {
                                                                                            member.firstname
                                                                                        }{' '}
                                                                                        {
                                                                                            member.lastname
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            member.username
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            style={{
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            role="group"
                                                                                            onClick={() => {
                                                                                                this.handleUnAssignMember(
                                                                                                    this
                                                                                                        .state
                                                                                                        .modalData
                                                                                                        .clientId,
                                                                                                    member.username
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <i
                                                                                                className="fas fa-unlink"
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title="Unassign issues to milestone"
                                                                                            ></i>{' '}
                                                                                            Unassign
                                                                                        </div>
                                                                                    </td>

                                                                        </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end">
                                    <Button
                                        type="button"
                                        data-dismiss="modal"
                                    >
                                        <FormattedMessage id="button.name.common.cancel" />
                                    </Button>
                                    <Button
                                        type="button"
                                        id="submitAdd"
                                        onClick={() => {
                                            this.handleAssignMember();
                                        }}
                                        appearance='primary'
                                    >
                                        {TranslateHelper.getMessage('button.name.common.create')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ShowForPermission>
                <RightSidebar
                    title={
                        this.state.sidebarState === 'create'
                            ? TranslateHelper.getMessage('clientid.modals.create.title')
                            : TranslateHelper.getMessage('clientid.modals.update.title')
                    }
                >
                    {this.state.sidebarState === 'create'
                        ? (
                        <div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.name" />
                                </label>
                                <input
                                    value={this.state.form.name}
                                    type="text"
                                    name="name"
                                    onChange={this.handleInputChange.bind(this, 'name')}
                                    className={
                                        isError.name.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.name.length > 0 && (
                                    <span className="error invalid-feedback">{isError.name}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.clientid" />
                                </label>
                                <input
                                    value={this.state.form.clientId}
                                    type="text"
                                    name="clientId"
                                    onChange={this.handleInputChange.bind(this, 'clientId')}
                                    className={
                                        isError.clientId.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.clientId.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.clientId}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.clientsecret" />
                                </label>
                                <input
                                    value={this.state.form.clientSecret}
                                    type="password"
                                    name="clientSecret"
                                    onChange={this.handleInputChange.bind(this, 'clientSecret')}
                                    className={
                                        isError.clientSecret.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.clientSecret.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.clientSecret}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.urlSbManagement" />
                                </label>
                                <input
                                    value={this.state.form.urlSbManagement}
                                    type="text"
                                    name="urlSbManagement"
                                    placeholder='Ex: https://admin.km01.dx.commercecloud.salesforce.com/api/v1/sandboxes/'
                                    onChange={this.handleInputChange.bind(this, 'urlSbManagement')}
                                    className={
                                        isError.urlSbManagement?.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.urlSbManagement?.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.urlSbManagement}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.urlRealmsManagement" />
                                </label>
                                <input
                                    value={this.state.form.urlSbRealmsManagement}
                                    type="text"
                                    name="urlSbRealmsManagement"
                                    placeholder='Ex: https://admin.km01.dx.commercecloud.salesforce.com/api/v1/realms/'
                                    onChange={this.handleInputChange.bind(this, 'urlSbRealmsManagement')}
                                    className={
                                        isError.urlSbRealmsManagement?.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.urlSbRealmsManagement?.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.urlSbRealmsManagement}
                                    </span>
                                )}
                            </div>
                            <Button
                                type="button"
                                appearance='primary'
                                onClick={() => {
                                    this.handleCreateClientId();
                                }}
                            >
                                <FormattedMessage id="button.name.common.add" />
                            </Button>
                        </div>
                            )
                        : (
                        <div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.name" />
                                </label>
                                <input
                                    value={this.state.form.name}
                                    type="text"
                                    name="name"
                                    onChange={this.handleInputChangeUpdate.bind(this, 'name')}
                                    className={
                                        isError.name.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.name.length > 0 && (
                                    <span className="error invalid-feedback">{isError.name}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.clientid" />
                                </label>
                                <input
                                    value={this.state.form.clientId}
                                    type="text"
                                    name="clientId"
                                    className="form-control"
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.clientsecret" />
                                </label>
                                <input
                                    value={this.state.form.clientSecret}
                                    type="password"
                                    name="clientSecret"
                                    onChange={this.handleInputChangeUpdate.bind(
                                        this,
                                        'clientSecret'
                                    )}
                                    className={
                                        isError.clientSecret.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.clientSecret.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.clientSecret}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.urlSbManagement" />
                                </label>
                                <input
                                    value={this.state.form.urlSbManagement}
                                    type="text"
                                    name="urlSbManagement"
                                    placeholder='Ex: https://admin.km01.dx.commercecloud.salesforce.com/api/v1/sandboxes/'
                                    onChange={this.handleInputChangeUpdate.bind(this, 'urlSbManagement')}
                                    className={
                                        isError.urlSbManagement?.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.urlSbManagement?.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.urlSbManagement}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id="clientid.modals.form.lable.urlRealmsManagement" />
                                </label>
                                <input
                                    value={this.state.form.urlSbRealmsManagement}
                                    type="text"
                                    name="urlSbRealmsManagement"
                                    placeholder='Ex: https://admin.km01.dx.commercecloud.salesforce.com/api/v1/realms/'
                                    onChange={this.handleInputChangeUpdate.bind(this, 'urlSbRealmsManagement')}
                                    className={
                                        isError.urlSbRealmsManagement?.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError.urlSbRealmsManagement?.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError.urlSbRealmsManagement}
                                    </span>
                                )}
                            </div>
                            <Button
                                type="button"
                                onClick={() => {
                                    this.onClickCancelUpdate();
                                }}
                            >
                                <FormattedMessage id="button.name.common.cancel" />
                            </Button>
                            <span style={{ width: '15px' }}>&nbsp;&nbsp;&nbsp;</span>
                            {isDifferentUpdate.name || isDifferentUpdate.clientSecret || isDifferentUpdate.urlSbManagement || isDifferentUpdate.urlSbRealmsManagement
                                ? (
                                <Button
                                    type="button"
                                    appearance='primary'
                                    onClick={() => {
                                        this.handleUpdateClientId();
                                    }}
                                >
                                    <FormattedMessage id="button.name.common.update" />
                                </Button>
                                    )
                                : (
                                <Button
                                    type="button"
                                    isDisabled
                                    appearance='primary'
                                    onClick={() => {
                                        this.handleUpdateClientId();
                                    }}
                                >
                                    <FormattedMessage id="button.name.common.update" />
                                </Button>
                                    )}
                        </div>
                            )}
                </RightSidebar>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { actived } = state.memberClientId;
    const { role } = state.auth;
    const { user } = state.auth;
    return {
        actived,
        role,
        user
    };
}

export default withRouter(connect(mapStateToProps)(ClientIdManagement));
