import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

export default function CopyText ({ text, title }) {
    const [isCopy, setIsCopy] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000);
    };
    return (
        <Tooltip title={title}>
            <IconButton onClick={handleCopy}>
                { isCopy ? <AssignmentTurnedInIcon/> : <ContentCopyIcon/> }
            </IconButton>
        </Tooltip>
    );
}
