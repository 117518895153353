import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import hrService from '../../Services/hr.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';

function UploadFile ({ refetch }) {
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
    });
    const handleFile = async (e) => {
        try {
            const fileInput = e.target;
            if (fileInput.files.length > 0) {
                const file = fileInput.files[0];
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop().toLowerCase();
                if (fileExtension === 'xlsx') {
                    const formData = new FormData();
                    formData.append('file', file);
                    await hrService.importEmployee(formData)
                        .then((res) => {
                            if (res.success === true) {
                                ToastTopEnd.fire({
                                    icon: 'success',
                                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                                });
                            }
                        });
                    refetch();
                }
            }
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };

    return (
        <>
            <Button
                component='label'
                variant='contained'
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Upload file
                <VisuallyHiddenInput type='file' onChange={(e) => handleFile(e)} />
            </Button>
        </>
    );
}
export default UploadFile;
