import React, { useState, useCallback, useEffect } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import recruitmentService from '../../Services/recruitment.service';
import hierarchyService from './services/hierarchy.service';

export default function Hierarchy () {
    const [hierarchy, setHierarchy] = useState();

    const fetchHierarchy = useCallback(() => {
        hierarchyService.getOrganizationHierarchy().then(res => {
            setHierarchy(res.data.data);
        });
    }, []);

    useEffect(fetchHierarchy, [fetchHierarchy]);

    const renderNode = data => {
        return data.map(data => {
            return (
                <TreeNode
                    key={data._id}
                    label={
                        <div className="node-wrapper mb-4">
                            <img className='employee-img' onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={data?.picture ? (!String(data?.picture).includes('uploads') ? data?.picture : recruitmentService.buildFileURL(data?.picture)) : '/images/default-avatar.png'} alt="avt preview"/>
                            <div className='info-wrapper'>
                                <span title={data.firstname + ' ' + data.lastname} className="employee-name">{data.firstname} {data.lastname}</span>
                                <span title={data.jobTitle} className="job-title">{data.jobTitle}</span>
                            </div>
                        </div>
                    }
                >
                    {data.children !== 0 ? renderNode(data.children) : null}
                </TreeNode>
            );
        });
    };

    const renderhierarchyTree = (hierarchy) => {
        return hierarchy.map(item => {
            return (
                <Tree
                    key={item._id}
                    lineColor={'#F0F0F0'}
                    lineBorderRadius={'0px'}
                    lineWidth='2px'
                    label={
                        <div className="node-wrapper my-4">
                            <img onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} className='employee-img' src={item?.picture ? (!String(item?.picture).includes('uploads') ? item?.picture : recruitmentService.buildFileURL(item?.picture)) : '/images/default-avatar.png'} alt="avt preview"/>
                            <div className='info-wrapper'>
                                <span title={item.firstname + ' ' + item.lastname} className="employee-name">{item.firstname} {item.lastname}</span>
                                <span title={item.jobTitle} className="job-title">{item.jobTitle}</span>
                            </div>
                        </div>
                    }
                    >
                    {item.children !== 0 ? renderNode(item.children) : null}
                </Tree>
            );
        });
    };

    return (
        <div className='container-fluid bg-white mb-3' style={{ overflowX: 'auto', scrollbarWidth: 'auto' }}>
            {hierarchy ? renderhierarchyTree(hierarchy) : null}
        </div>
    );
}
