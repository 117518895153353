import React, { Component } from 'react';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import Information from './settingdetail/Information';
import Integration from './settingdetail/Integration';
import HRM from './settingdetail/HRM';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import { ROLES } from '../../../common/constants/user.constant';

class Setting extends Component {
    render () {
        return (
            <div className="container-fluid">
                <Tabs className="column">
                    <TabList className="d-flex tab-row">
                        <Tab>{TranslateHelper.getMessage('account.form.label.information')}</Tab>
                        {this.props.role === ROLES.MANAGER &&
                            (<><Tab>{TranslateHelper.getMessage('account.form.label.integration')}</Tab>
                            <Tab>{TranslateHelper.getMessage('account.form.label.HRM')}</Tab></>)}
                    </TabList>
                    <div>
                        <TabPanel>
                            <Information />
                        </TabPanel>
                        <TabPanel>
                            <Integration />
                        </TabPanel>
                        <TabPanel>
                            <HRM />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(Setting);
