import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AsidebarPartnerOwner from '../../partnerowner/AsidebarPartnerOwner';
import AsidebarPartnerMember from '../../partnermember/AsidebarPartnerMember';
import AsidebarManager from '../../manager/AsidebarManager';
import AsidebarMember from '../../member/AsidebarMember';
import AsidebarGuest from '../../guest/AsidebarGuest';
import { ROLES } from '../constants/user.constant';

class AsidebarSection extends Component {
    render () {
        const { role } = this.props;
        let menuRendering = '';
        switch (role) {
            case ROLES.MANAGER:
                menuRendering = <AsidebarManager />;
                break;
            case ROLES.MEMBER: case ROLES.HR:
                menuRendering = <AsidebarMember />;
                break;
            case ROLES.PARTNEROWNER: case ROLES.PARTNERMANAGER:
                menuRendering = <AsidebarPartnerOwner />;
                break;
            case ROLES.PARTNERMEMBER:
                menuRendering = <AsidebarPartnerMember />;
                break;
            case ROLES.GUEST:
                menuRendering = <AsidebarGuest />;
                break;
            default:
                break;
        }
        return (
            <aside className="main-sidebar sidebar-dark-primary sidebar-custom" style={{ position: 'fixed', top: '56px' }}>
                <a href={role === ROLES.GUEST ? '/projects' : '/dashboard'} className="brand-link" style={{ backgroundColor: '#505abb' }}>
                    <img src="/logo.png" alt="Avatar-kimei-logo" style={{ maxHeight: '35px' }} className="brand-image img-circle elevation-3" />
                    <span className="brand-text font-weight-light"><FormattedMessage id="asidebar.application.name" /></span>
                </a>
                <div className="sidebar" style={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 56px)'
                }}>
                    <nav className="mt-2">
                        {menuRendering}
                    </nav>
                </div>
            </aside>
        );
    }
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    return {
        user,
        role
    };
}

export default connect(mapStateToProps)(AsidebarSection);
