import AxiosService from '../../../common/services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class attendanceService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
    }

    getAll ({ page, limit, userId, form, to, businessUnitId, search }) {
        return AxiosService.get(API_URL + '/attendence', { params: { page, limit, userId, form, to, businessUnitId, search } });
    }

    getAllEmployees ({ page, limit }) {
        return AxiosService.get(API_URL + '/manager/hrm/employees', { params: { page, limit } });
    }

    getAllBusinessUnits () {
        return AxiosService.get(API_URL + '/manager/hrm/businessUnits');
    }
}

export default new attendanceService();
