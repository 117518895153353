import { useMemo, useState, useCallback, useEffect, useRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@atlaskit/select';
import { CustomInputDate } from '../../../common/CustomInputDatepicker';
import DatePicker from 'react-datepicker';
import issueService from '../../services/issue.service';
import moment from 'moment';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import socket from '../../../common/Socket';
import InlineEdit from '@atlaskit/inline-edit';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import Avatar from '@atlaskit/avatar';
import { ISSUE_PRIORITY } from '../../../../constants/project.constant';
import projectListService from '../../services/project-list.service';
import { Chip } from '@mui/material';

function IssueDetailForm ({
    isManager,
    projectId,
    issueDetail,
    issueId,
    setRefetch,
    refetchIssue,
    projectData,
    isPopup
}) {
    const refStatus = useRef();
    const refAssignTo = useRef();
    const refMilestoneId = useRef();
    const refCategoryId = useRef();
    const refPriority = useRef();
    const refFromDate = useRef();

    const initOptions = useMemo(
        () => ({
            statusTasks: [],
            assignTo: [],
            milestones: [],
            categories: [],
            priority: [
                {
                    value: ISSUE_PRIORITY.HIGH,
                    label: 'High'
                },
                {
                    value: ISSUE_PRIORITY.MEDIUM,
                    label: 'Medium'
                },
                {
                    value: ISSUE_PRIORITY.LOW,
                    label: 'Low'
                }
            ]
        }),
        []
    );

    const [editValue, setEditValue] = useState({});

    useEffect(() => {
        let formatTagLabel = [];
        if (issueDetail?.tagIds?.length) {
            formatTagLabel = issueDetail.tagIds.map((obj) => ({
                label: obj.tagName,
                value: obj._id
            }));
        }
        setEditValue((prevState) => ({
            ...prevState,
            status: issueDetail?.status?.name,
            priority: issueDetail?.priority,
            category: issueDetail?.categoryId?.categoryName,
            assignee: {
                name:
                    issueDetail?.assignTo?.firstname &&
                    `${issueDetail?.assignTo?.firstname} ${issueDetail?.assignTo?.lastname}`,
                picture: issueDetail?.assignTo?.picture
            },
            reporter: {
                name:
                    issueDetail?.createdBy?.firstname &&
                    `${issueDetail?.createdBy?.firstname} ${issueDetail?.createdBy?.lastname}`,
                picture: issueDetail?.createdBy?.picture
            },
            milestoneRelease: issueDetail?.milestoneId?.milestoneName,
            startDate:
                issueDetail?.startDate && new Date(issueDetail?.startDate),
            dueDate: issueDetail?.dueDate && new Date(issueDetail?.dueDate),
            tagIds: formatTagLabel
        }));
    }, [issueDetail]);

    const [onEdit, setOnEdit] = useState('');
    const [options, setOptions] = useState(initOptions);

    useEffect(() => {
        const dataOptions = {};
        setFilter({ fromDate: null, toDate: null });
        if (isPopup) {
            projectListService.getOne(projectId).then((res) => {
                dataOptions.statusTasks = res.statusTasks;
                dataOptions.assignTo = res.members;
                dataOptions.milestones = res.milestones;
                dataOptions.categories = res.categories;
                dataOptions.tags = res.tags;
                mapDataOptions(dataOptions);
            });
        } else {
            dataOptions.statusTasks = projectData.statusTasks;
            dataOptions.assignTo = projectData.members;
            dataOptions.milestones = projectData.milestones;
            dataOptions.categories = projectData.categories;
            dataOptions.tags = projectData.tags;
            mapDataOptions(dataOptions);
        }
    }, [issueId, projectData]);

    const mapDataOptions = (dataOptions) => {
        for (const key of Object.keys(dataOptions)) {
            switch (key) {
                case 'statusTasks':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(
                        ([value, label]) => ({
                            label: label.name,
                            value: label._id
                        })
                    );
                    break;
                case 'milestones':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(
                        ([value, label]) => ({
                            label: label.milestoneName,
                            value: label._id
                        })
                    );
                    break;
                case 'categories':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(
                        ([value, label]) => ({
                            label: label.categoryName,
                            value: label._id
                        })
                    );
                    break;
                case 'assignTo':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(
                        ([value, label]) => ({
                            label:
                                label.user?.firstname +
                                ' ' +
                                label.user?.lastname,
                            value: label?.user?._id,
                            image: label.user?.picture
                        })
                    );
                    break;
                case 'tags':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(
                        ([value, label]) => ({
                            label: label.tagName,
                            value: label._id
                        })
                    );
                    break;
                default:
                // no default
            }
        }
        dataOptions.priority = initOptions.priority;
        setOptions(dataOptions);
    };

    const [filter, setFilter] = useState({
        fromDate: undefined,
        toDate: undefined
    });

    const handleFromDateChange = useCallback(
        (date) => {
            if (date) {
                setFilter((prevState) => ({
                    ...prevState,
                    fromDate: date
                }));
                setEditValue((prevState) => ({
                    ...prevState,
                    startDate: date
                }));
                issueService
                    .updateIssue(projectId, issueId, { startDate: date })
                    .then(() => {
                        setRefetch && setRefetch(true);
                        refetchIssue && refetchIssue();
                    });
            }
        },
        [issueId]
    );

    const handleToDateChange = useCallback(
        (date) => {
            setFilter((prevState) => ({
                ...prevState,
                toDate: date
            }));
            setEditValue((prevState) => ({ ...prevState, dueDate: date }));
            issueService
                .updateIssue(projectId, issueId, { dueDate: date })
                .then(() => {
                    setRefetch && setRefetch(true);
                    refetchIssue && refetchIssue();
                });
        },
        [issueId]
    );

    const handleSelectOptionChange = useCallback(
        (option, target) => {
            if (option) {
                const payload = {};
                let receiverId;
                let formatedTagsPayload = [];
                switch (target) {
                    case 'status':
                        setEditValue((prevState) => ({
                            ...prevState,
                            status: option?.label ? option?.label : option
                        }));
                        payload.status = option.value;
                        payload.changeStatus = true;
                        break;
                    case 'assignTo':
                        setEditValue((prevState) => ({
                            ...prevState,
                            assignee: {
                                name: option?.label,
                                picture: option?.image
                            }
                        }));
                        payload.assignTo = option.value;
                        receiverId = payload.assignTo;
                        socket.emit('sendNotification', {
                            markAll: 1,
                            receiverId
                        });
                        break;
                    case 'categoryId':
                        setEditValue((prevState) => ({
                            ...prevState,
                            category: option?.label ? option?.label : option
                        }));
                        payload.categoryId = option.value;
                        break;
                    case 'tagIds':
                        if (option?.length) {
                            formatedTagsPayload = option.map(
                                (obj) => obj.value
                            );
                        }
                        setEditValue((prevState) => ({
                            ...prevState,
                            tagIds: option
                        }));
                        payload.tagIds = formatedTagsPayload;
                        break;
                    case 'milestoneId':
                        setEditValue((prevState) => ({
                            ...prevState,
                            milestoneRelease: option?.label
                                ? option?.label
                                : option
                        }));
                        payload.milestoneId = option.value;
                        break;
                    case 'priority':
                        setEditValue((prevState) => ({
                            ...prevState,
                            priority: option?.value ? option?.value : option
                        }));
                        payload.priority = option.value;
                        break;
                    default:
                        break;
                }
                issueService
                    .updateIssue(projectId, issueId, payload)
                    .then((res) => {
                        setRefetch && setRefetch(true);
                        refetchIssue && refetchIssue();
                    });
            }
        },
        [issueId]
    );

    const renderPriority = (priority) => {
        switch (priority) {
            case 1:
                return 'fas fa-angle-double-down text-primary';
            case 2:
                return 'fas fa-equals text-warning';
            case 3:
                return 'fas fa-angle-double-up text-danger';
            default:
                break;
        }
    };

    const onConfirm = (option) => {
        if (option) {
            handleSelectOptionChange(option, onEdit);
        }
    };

    const lang = localStorage.getItem('lang');
    return (
        <>
            <div className="row issue-info">
                <div className="col-xl-5">
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.table.status" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={editValue.status}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        options={options.statusTasks}
                                        name="status"
                                        ref={refStatus}
                                        placeholder={
                                            issueDetail?.status?.name
                                                ? (
                                                        issueDetail?.status?.name
                                                    )
                                                : (
                                                <FormattedMessage id="project.detail.new.table.status" />
                                                    )
                                        }
                                    />
                                )}
                                readView={() =>
                                    editValue.status === ''
                                        ? (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select status
                                        </div>
                                            )
                                        : (
                                        <div className="p-1">
                                            {editValue.status}
                                        </div>
                                            )
                                }
                                name="status"
                                onEdit={() => setOnEdit('status')}
                                onConfirm={onConfirm}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.priority" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={editValue.priority}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        options={options.priority}
                                        formatOptionLabel={(item) => (
                                            <>
                                                <i
                                                    className={renderPriority(
                                                        item.value
                                                    )}
                                                ></i>{' '}
                                                {item.label}
                                            </>
                                        )}
                                        name="priority"
                                        ref={refPriority}
                                        placeholder={
                                            <>
                                                <i
                                                    className={renderPriority(
                                                        issueDetail?.priority
                                                    )}
                                                ></i>{' '}
                                                {issueDetail?.priority === 1
                                                    ? 'Low'
                                                    : issueDetail?.priority ===
                                                      2
                                                        ? 'Medium'
                                                        : 'High'}
                                            </>
                                        }
                                    />
                                )}
                                readView={() =>
                                    editValue.priority === ''
                                        ? (
                                        <div>Select priority</div>
                                            )
                                        : (
                                        <div className="p-1">
                                            <i
                                                className={renderPriority(
                                                    editValue?.priority
                                                )}
                                            ></i>{' '}
                                            {editValue?.priority === 1
                                                ? 'Low'
                                                : editValue?.priority === 2
                                                    ? 'Medium'
                                                    : 'High'}
                                        </div>
                                            )
                                }
                                onEdit={() => setOnEdit('priority')}
                                onConfirm={onConfirm}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.category" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={editValue.category}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        options={options.categories}
                                        name="categoryId"
                                        ref={refCategoryId}
                                        placeholder={
                                            issueDetail?.categoryId
                                                ?.categoryName
                                                ? (
                                                        issueDetail?.categoryId
                                                            ?.categoryName
                                                    )
                                                : (
                                                <FormattedMessage id="project.detail.new.label.category" />
                                                    )
                                        }
                                    />
                                )}
                                readView={() =>
                                    !editValue.category
                                        ? (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select category
                                        </div>
                                            )
                                        : (
                                        <div className="p-1">
                                            {editValue?.category}
                                        </div>
                                            )
                                }
                                onEdit={() => setOnEdit('categoryId')}
                                onConfirm={onConfirm}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.startDate" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                cancelButtonLabel="sss"
                                hideActionButtons
                                defaultValue={editValue?.startDate}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <DatePicker
                                        {...fieldProps}
                                        startOpen
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        name="fromDate"
                                        ref={refFromDate}
                                        clearButtonClassName="fromDate"
                                        onChange={handleFromDateChange}
                                        selected={filter.fromDate}
                                        locale={lang}
                                        customInput={<CustomInputDate />}
                                        maxDate={filter.toDate}
                                        placeholderText={
                                            editValue?.startDate
                                                ? moment(
                                                    editValue?.startDate
                                                ).format(
                                                    TranslateHelper.getMessage(
                                                        'date.format'
                                                    )
                                                )
                                                : TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                        }
                                    />
                                )}
                                readView={() =>
                                    !editValue?.startDate
                                        ? (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select start date
                                        </div>
                                            )
                                        : (
                                        <div className="p-1">
                                            {moment(
                                                editValue?.startDate
                                            ).format(
                                                TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                            )}
                                        </div>
                                            )
                                }
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.parenttask" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            {issueDetail?.parentId?.issueCode
                                ? (
                                <span>{issueDetail.parentId?.issueCode}</span>
                                    )
                                : (
                                <FormattedMessage id="project.detail.new.label.notavailable" />
                                    )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-1"></div>
                <div className="col-xl-6">
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.assignee" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={() => ({
                                    label: editValue.assignee?.name,
                                    image: editValue.assignee?.picture
                                })}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        options={options.assignTo}
                                        name="assignTo"
                                        ref={refAssignTo}
                                        formatOptionLabel={(user) => (
                                            <div className="user-option d-flex">
                                                <Avatar
                                                    src={
                                                        user.image
                                                            ? String(
                                                                user.image
                                                            ).includes(
                                                                'uploads'
                                                            )
                                                                ? recruitmentService.buildFileURL(
                                                                    user.image
                                                                )
                                                                : user.image
                                                            : '/images/default-avatar.png'
                                                    }
                                                />
                                                <span className="ml-2 align-self-center">
                                                    {user.label
                                                        ? user.label
                                                        : 'Unassign'}
                                                </span>
                                            </div>
                                        )}
                                        placeholder={
                                            <div className="user-option d-flex">
                                                <Avatar
                                                    src={
                                                        editValue.assignee
                                                            .picture
                                                            ? String(
                                                                editValue
                                                                    .assignee
                                                                    .picture
                                                            ).includes(
                                                                'uploads'
                                                            )
                                                                ? recruitmentService.buildFileURL(
                                                                    editValue
                                                                        .assignee
                                                                        .picture
                                                                )
                                                                : editValue
                                                                    .assignee
                                                                    .picture
                                                            : '/images/default-avatar.png'
                                                    }
                                                />
                                                <span className="ml-2 align-self-center">
                                                    {editValue.assignee.name
                                                        ? editValue.assignee
                                                            .name
                                                        : 'Unassign'}
                                                </span>
                                            </div>
                                        }
                                    />
                                )}
                                readView={() =>
                                    !editValue.assignee?.name
                                        ? (
                                        <div className="user-option d-flex p-1">
                                            <Avatar
                                                src={
                                                    '/images/default-avatar.png'
                                                }
                                            />
                                            <span className="ml-2 align-self-center">
                                                Unassign
                                            </span>
                                        </div>
                                            )
                                        : (
                                        <div className="user-option d-flex p-1">
                                            <Avatar
                                                src={
                                                    editValue.assignee?.picture
                                                        ? String(
                                                            editValue.assignee
                                                                ?.picture
                                                        ).includes('uploads')
                                                            ? recruitmentService.buildFileURL(
                                                                editValue
                                                                    .assignee
                                                                    ?.picture
                                                            )
                                                            : editValue.assignee
                                                                ?.picture
                                                        : '/images/default-avatar.png'
                                                }
                                            />
                                            <span className="ml-2 align-self-center">
                                                {editValue.assignee?.name}
                                            </span>
                                        </div>
                                            )
                                }
                                onEdit={() => setOnEdit('assignTo')}
                                onConfirm={onConfirm}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.reporter" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={() => ({
                                    label: editValue.reporter?.name,
                                    image: editValue.reporter?.picture
                                })}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        formatOptionLabel={(user) => (
                                            <div className="user-option d-flex">
                                                <Avatar
                                                    src={
                                                        user.image
                                                            ? String(
                                                                user.image
                                                            ).includes(
                                                                'uploads'
                                                            )
                                                                ? recruitmentService.buildFileURL(
                                                                    user.image
                                                                )
                                                                : user.image
                                                            : '/images/default-avatar.png'
                                                    }
                                                />
                                                <span className="ml-2 align-self-center">
                                                    {user.label
                                                        ? user.label
                                                        : 'Unassign'}
                                                </span>
                                            </div>
                                        )}
                                        name="createdBy"
                                        placeholder={
                                            <div className="user-option d-flex">
                                                <Avatar
                                                    src={
                                                        editValue.reporter
                                                            .picture
                                                            ? String(
                                                                editValue
                                                                    .reporter
                                                                    .picture
                                                            ).includes(
                                                                'uploads'
                                                            )
                                                                ? recruitmentService.buildFileURL(
                                                                    editValue
                                                                        .reporter
                                                                        .picture
                                                                )
                                                                : editValue
                                                                    .reporter
                                                                    .picture
                                                            : '/images/default-avatar.png'
                                                    }
                                                />
                                                <span className="ml-2 align-self-center">
                                                    {editValue.reporter.name
                                                        ? editValue.reporter
                                                            .name
                                                        : 'Unassign'}
                                                </span>
                                            </div>
                                        }
                                    />
                                )}
                                readView={() =>
                                    !editValue.reporter?.name
                                        ? (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select reporter
                                        </div>
                                            )
                                        : (
                                        <div className="user-option d-flex p-1">
                                            <Avatar
                                                src={
                                                    editValue.reporter?.picture
                                                        ? String(
                                                            editValue.reporter
                                                                ?.picture
                                                        ).includes('uploads')
                                                            ? recruitmentService.buildFileURL(
                                                                editValue
                                                                    .reporter
                                                                    ?.picture
                                                            )
                                                            : editValue.reporter
                                                                ?.picture
                                                        : '/images/default-avatar.png'
                                                }
                                            />
                                            <span className="ml-2 align-self-center">
                                                {editValue.reporter?.name}
                                            </span>
                                        </div>
                                            )
                                }
                                isEditing={false}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.milestoneRelease" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            {isManager
                                ? (
                                <InlineEdit
                                    hideActionButtons
                                    defaultValue={editValue.milestoneRelease}
                                    editView={({
                                        errorMessage,
                                        ...fieldProps
                                    }) => (
                                        <Select
                                            {...fieldProps}
                                            autoFocus
                                            openMenuOnFocus
                                            options={options.milestones}
                                            name="milestoneId"
                                            ref={refMilestoneId}
                                            placeholder={
                                                issueDetail?.milestoneId
                                                    ?.milestoneName
                                                    ? (
                                                            issueDetail?.milestoneId
                                                                ?.milestoneName
                                                        )
                                                    : (
                                                    <FormattedMessage id="project.detail.new.label.milestoneRelease" />
                                                        )
                                            }
                                        />
                                    )}
                                    readView={() =>
                                        !editValue.milestoneRelease
                                            ? (
                                            <div className="p-1 bg-issuedetailform-select">
                                                Select milestone
                                            </div>
                                                )
                                            : (
                                            <div className="p-1">
                                                {editValue?.milestoneRelease}
                                            </div>
                                                )
                                    }
                                    onEdit={() => setOnEdit('milestoneId')}
                                    onConfirm={onConfirm}
                                    readViewFitContainerWidth
                                />
                                    )
                                : !editValue.milestoneRelease
                                        ? (
                                <div className="p-1">no milestones</div>
                                            )
                                        : (
                                <div className="p-1">
                                    {editValue?.milestoneRelease}
                                </div>
                                            )}
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.dueDate" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                defaultValue={editValue?.dueDate}
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <DatePicker
                                        {...fieldProps}
                                        startOpen
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        name="toDate"
                                        selected={filter.toDate}
                                        onChange={handleToDateChange}
                                        locale={lang}
                                        customInput={<CustomInputDate />}
                                        minDate={filter.fromDate}
                                        placeholderText={
                                            issueDetail?.dueDate
                                                ? moment(
                                                    issueDetail?.dueDate
                                                ).format(
                                                    TranslateHelper.getMessage(
                                                        'date.format'
                                                    )
                                                )
                                                : TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                        }
                                    />
                                )}
                                readView={() =>
                                    !editValue?.dueDate
                                        ? (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select due date
                                        </div>
                                            )
                                        : (
                                        <div className="p-1">
                                            {moment(editValue?.dueDate).format(
                                                TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                            )}
                                        </div>
                                            )
                                }
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                    <div className="row row-border">
                        <div className="col-xl-4 align-self-center">
                            <FormattedMessage id="project.detail.new.label.tags" />
                        </div>
                        <div className="col-xl-8 align-self-center">
                            <InlineEdit
                                hideActionButtons
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        isMulti
                                        {...fieldProps}
                                        autoFocus
                                        openMenuOnFocus
                                        options={options.tags}
                                        name="tagIds"
                                    />
                                )}
                                readView={() =>
                                    editValue?.tagIds?.length
                                        ? (
                                                editValue.tagIds.map((ele) => {
                                                    return (
                                                <Chip
                                                    className="mr-2 mb-2"
                                                    key={ele.value}
                                                    label={ele.label}
                                                    color="success"
                                                />
                                                    );
                                                })
                                            )
                                        : (
                                        <div className="p-1 bg-issuedetailform-select">
                                            Select Tags
                                        </div>
                                            )
                                }
                                name="tagIds"
                                onEdit={() => setOnEdit('tagIds')}
                                onConfirm={onConfirm}
                                readViewFitContainerWidth
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(IssueDetailForm);
