import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './calendar.css';

export default function Calendar ({ events, handleEvenClick, lang, handleDateClick, isShowRight }) {
    return (
        <FullCalendar
            locales={allLocales}
            locale={lang}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            events={events}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: !isShowRight ? 'dayGridMonth,timeGridWeek,timeGridDay' : ''
            }}
            dayMaxEvents={2}
            moreLinkClassNames={'fc-morelink-customize'}
            moreLinkContent={(arg) => {
                return (
                    <Tooltip title={arg.text}>
                        <MoreHorizIcon fontSize='small'/>
                    </Tooltip>
                );
            }}
            weekends={true}
            eventClick={handleEvenClick}
            eventContent={(arg) =>
                arg.event.extendedProps?.icon
                    ? (
                    <div className="w-100 text-left p-1 text-white column" style={{ fontSize: '12px', backgroundColor: arg.event.extendedProps?.color || '#fff', borderRadius: '5px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {arg.event.extendedProps.icon}
                        <span className='font-weight-bold text-capitalize' >{arg.event.title}</span>
                        <div className='ml-3 ' style={{ fontSize: 'smaller' }}>
                            {arg.event.extendedProps.type === 'Leave' && arg.event.extendedProps.leaveFor === 0 && <span>AM </span>}
                            {arg.event.extendedProps.type === 'Leave' && arg.event.extendedProps.leaveFor === 2 && <span>PM </span>}
                            {arg.event.extendedProps.type === 'Interview' && moment((arg.event.start)).format('HH:mm') !== '00:00' && moment((arg.event.start)).format('HH:mm').toLocaleString() + ' '}

                        </div>

                    </div>
                        )
                    : (
                    <div className="pl-3">{arg.event.title}</div>
                        )
            }
            eventColor={(arg) => arg.event.extendedProps?.color || 'red'}
            dateClick={handleDateClick}
        />
    );
}
