import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import PartnerManagement from './components/PartnerManagement';
import HandshakeIcon from '@mui/icons-material/Handshake';

export const partnerMenuLinks = [
    {
        name: 'menu.admin.name.partner',
        classNames: 'nav-item',
        to: '/partners',
        icon: (<HandshakeIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: false,
        role: [ROLES.MANAGER],
        key: 2
    }
];

const routes = [
    {
        path: '/partners',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <PartnerManagement match={match}/>);
        }
    }
];

export default routes;
