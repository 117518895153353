import React, { useState, useCallback, useEffect, useRef } from 'react';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import issueService from '../../../services/issue.service';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import IssueTypeDetail from './IssueTypeDetail';
import Button from '@atlaskit/button';
import { isAllowEditProject } from '../../../../../helpers/CheckRoleHelper';

function IssueType ({ projectId, role, userRoleInProject }) {
    const [issueTypes, setIssueTypes] = useState([]);
    const [isView, setIsView] = useState();
    const hideModalButtonRef = useRef();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const fetchIssueTypes = useCallback(() => {
        issueService.getIssueTypes(projectId).then(setIssueTypes);
    }, []);

    useEffect(fetchIssueTypes, [fetchIssueTypes]);

    const handleCreateIssueType = useCallback(
        async (formData) => {
            const payload = {
                issueType: formData.issueType.trim()
            };
            if (payload.issueType) {
                try {
                    await issueService.createIssueType(projectId, payload);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.addnew.successed')
                    });
                    hideModalButtonRef.current?.click();
                    reset();
                    return fetchIssueTypes();
                } catch (error) {
                    console.warn(error);
                    ToastTopEnd.fire({
                        icon: 'error',
                        title:
                            error.response.data.message ||
                            TranslateHelper.getMessage('respond.message.failed')
                    });
                }
            } else {
                setError('issueType');
            }
        },
        [projectId, hideModalButtonRef]
    );

    const handleDelete = (id, name) => {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + name + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await issueService.deleteIssueType(projectId, id);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.delete.successfully')
                });
                return fetchIssueTypes();
            }
        });
    };
    const editFlag = isAllowEditProject(role, userRoleInProject);
    return (
        <div className="container-fluid">
            <div className='card'>
                <div className='card-body'>
                {editFlag && (
                    <Button
                        type="button"
                        appearance='primary'
                        data-toggle="modal"
                        data-target="#modal-issuetype"
                        style={{ margin: '10px 0px' }}
                    >
                        {TranslateHelper.getMessage('button.name.common.create')}
                    </Button>
                )}
                    <div className="modal fade" id="modal-issuetype">
                        <div className="modal-dialog modal-sm">
                            <form onSubmit={handleSubmit(handleCreateIssueType)} className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">
                                        <FormattedMessage id="project.detail.settings.tabs.name.issuetype" />
                                    </h4>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group row">
                                        <label htmlFor="inputType" className="col-sm-3 col-form-label">
                                            <FormattedMessage id="project.detail.settings.issuetype.table.column.name.type" />
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                placeholder="Name of type ?"
                                                className={
                                                    errors.issueType
                                                        ? 'is-invalid form-control'
                                                        : 'form-control'
                                                }
                                                {...register('issueType', { required: TranslateHelper.getMessage('validation.error.required'), maxLength: { value: 25, message: TranslateHelper.getMessage('validation.error.max25characters') } })}
                                            />
                                            {errors.issueType && (
                                                <span className="error text-danger">
                                                    {errors.issueType.message}
                                                        </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end">
                                    <Button
                                        type="button"
                                        ref={hideModalButtonRef}
                                        data-dismiss="modal"
                                    >
                                        <FormattedMessage id="button.name.common.cancel" />
                                    </Button>
                                    <Button
                                        appearance='primary'
                                        type="submit"
                                        disabled={!isDirty || !isValid}
                                    >
                                        {TranslateHelper.getMessage('button.name.common.create')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='table-scrollX'>
                        <table id="issue-type-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.type" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdAt" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdBy" />
                                    </th>
                                {editFlag && <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.action" />
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {issueTypes.length > 0
                                    ? issueTypes?.map((value, index) => {
                                        return (
                                        <tr key={index}>
                                            <td>{value.issueType}</td>
                                            <td>
                                                {moment(value.createdAt).format(
                                                    TranslateHelper.getMessage('date.format')
                                                )}
                                            </td>
                                            <td>
                                                {value.createdBy.lastname + ' ' + value.createdBy.firstname}
                                            </td>
                                            {editFlag && <td>
                                                <Button
                                                    title='Edit'
                                                    className='mr-2'
                                                    data-toggle="modal"
                                                    data-target={'#modal-issuetype-' + value._id}
                                                    onClick={() => setIsView(value)}
                                                >
                                                    <i className='far fa-edit'></i>
                                                </Button>
                                                <Button
                                                    title='Delete'
                                                    onClick={(e) => handleDelete(value._id, value.issueType)}
                                                >
                                                    <i className='far fa-trash-alt'></i>
                                                </Button>
                                            </td>}
                                        </tr>
                                        );
                                    })
                                    : <tr>
                                        <td className='text-center' colSpan="4"><FormattedMessage id='message.no.data.available' /></td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isView && <IssueTypeDetail projectId={projectId} issueType={isView} fetchIssueTypes={fetchIssueTypes}/>}
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        role,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(IssueType);
