import AxiosService from '../../../services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnermember/sandboxes';

class SandboxService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
        AxiosService.setHeader('authorization', 'Bearer ' + Cookies.get(Cookies.get('actived')));
        AxiosService.setHeader('clientId', Cookies.get('actived'));
    }

    getSandboxList (page) {
        return AxiosService.get(API_URL + '/operations', { params: { page } });
    }

    getSandboxId (sandboxId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId);
    }

    setSandboxOperations (sandboxId, payload) {
        return AxiosService.post(API_URL + '/operations/' + sandboxId + '/operations', payload);
    }

    getSandboxAliases (sandboxId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/aliases');
    }

    getSandboxSettings (sandboxId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/settings');
    }

    getSandboxUsage (sandboxId, queryString = '') {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/usage' + queryString);
    }

    getSandboxStorage (sandboxId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/storage');
    }

    getSandboxAliasesDetail (sandboxId, aliaseId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/operations');
    }

    getSandboxOperationsDetail (sandboxId, aliaseId) {
        return AxiosService.get(API_URL + '/operations/' + sandboxId + '/operations');
    }

    postCreateSandbox (payload) {
        return AxiosService.post(API_URL + '/operations', payload);
    }

    deleteSandbox (sandboxId) {
        return AxiosService.delete(API_URL + '/operations/' + sandboxId);
    }

    updateSandboxSchedule (sandboxId, payload) {
        return AxiosService.patch(API_URL + '/operations/' + sandboxId, payload);
    }
}

export default new SandboxService();
