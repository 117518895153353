import './Hero.css';

export default function HeroSection () {
    return (
        <section className="hero" id="hero">
            <div className='container'>
            <h1>Sandboxes management platform  for Salesforce</h1>
            <div className="gallery">
                <div className="inner-fixed">
                    <div className="col col-outer col-left">
                        <div
                            className="card card-a"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/5932d4e90754eb6996bb.jpg)'
                            }}
                        ></div>
                        <div
                            className="card card-b"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/26f33d7afbfcafacaa73.jpg)'
                            }}
                        ></div>
                        <div
                            className="card card-c"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/42b50a45c3cfd815e2aa.png)'
                            }}
                        ></div>
                    </div>
                    <div className="col col-center">
                        <div
                            className="card card-a"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/90e34daecca082eb9b28.png)'
                            }}
                        ></div>
                    </div>
                    <div className="col col-outer col-right">
                        <div
                            className="card card-a"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/7c827ed46aa7efe0109b.png)'
                            }}
                        ></div>
                        <div
                            className="card card-b"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/4e15fea30ba9d8a14208.png)'
                            }}
                        ></div>
                        <div
                            className="card card-c"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/389ff8f9e4d6becae73a.png)'
                            }}
                        ></div>
                        <div
                            className="card card-d"
                            style={{
                                backgroundImage:
                                    'url(https://storage.googleapis.com/cms-storage-bucket/b8bd15d096f0557123b1.jpg)'
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            <h2>
                It&apos;s easy to control your development environment.
             You can managed the cost, time up/down, schedules to start/stop a sandbox.
            </h2>
            <a id="get-started__home-hero-bottom" href="https://docs.flutter.dev/get-started/install" className="btn white">Get started</a>
            </div>

        </section>
    );
}
