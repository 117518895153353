import { ACTION_AUTH } from '../constants/action.constant';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const getRoleFromJWT = (accessToken = '') => {
    try {
        return jwtDecode(accessToken).role;
    } catch (error) {
        console.log(error.message);
        return '';
    }
};

let roleChanged = Cookies.get('roleChanged');
const accessToken = Cookies.get('accessToken');
let role = getRoleFromJWT(accessToken);
if (roleChanged) {
    role = parseInt(roleChanged);
}
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user && accessToken
    ? { isLoggedIn: true, user, role }
    : { isLoggedIn: false, user: null, role };

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_AUTH.LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                role: ''
            };
        }
        case ACTION_AUTH.LOGIN_SUCCESS: {
            let role = getRoleFromJWT(payload.accessToken);
            roleChanged = Cookies.get('roleChanged');
            if (roleChanged) {
                role = parseInt(roleChanged);
            }
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                role
            };
        }
        case ACTION_AUTH.UPDATE_USER: {
            return {
                ...state,
                user: payload.user
            };
        }
        case ACTION_AUTH.LOGIN_FAILED: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                role: ''
            };
        }
        default:
            return state;
    }
};

export default authReducer;
