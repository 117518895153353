import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import hrService from '../../Services/hr.service';

export default function MyDetail () {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data: userInfo = {} } = useService(hrService.getEmployeeDetailByUsername, user?.email);
    const titleDisplay = (title) => {
        switch (title) {
            case 1:
                return 'MR';
            case 2:
                return 'MRS';
            case 3:
                return 'MS';
            default:
                break;
        }
    };

    return (
        <div className="">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="HRM.detail.HR.tab.name.official" />
                    </h4>
                    <p className="card-text text-muted">
                        <div className="row p-3">
                            <div className="col-xl-6 col-12">
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.id" />
                                    </p>
                                    <p>: {userInfo.id}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.firstName" />
                                    </p>
                                    <p>: {userInfo.firstname}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.lastName" />
                                    </p>
                                    <p>: {userInfo.lastname}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.email" />
                                    </p>
                                    <p>: {userInfo.username}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.title" />
                                    </p>
                                    <p>: {titleDisplay(userInfo.title)}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.lineManager" />
                                    </p>
                                    <p>
                                        : {userInfo.lineManager?.firstname}{' '}
                                        {userInfo.lineManager?.lastname}
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12">
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.businessUnit" />
                                    </p>
                                    <p>: {userInfo.businessId?.name}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.department" />
                                    </p>
                                    <p>: {userInfo.departmentId?.name}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.jobTitle" />
                                    </p>
                                    <p>: {userInfo.jobTitle}</p>
                                </div>
                                <div className="row">
                                    <p>
                                        <FormattedMessage id="HRM.detail.HR.official.label.joinedDate" />
                                    </p>
                                    <p>
                                        :{' '}
                                        {moment(userInfo.joinedDate).format(
                                            TranslateHelper.getMessage('date.format')
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="HRM.detail.HR.tab.name.personal" />
                    </h4>
                    <p className="card-text text-muted">
                        <div className="col-xl-12 col-12">
                            <div className="row">
                                <p>
                                    <i title={TranslateHelper.getMessage('HRM.detail.HR.official.label.address')} className="fas fa-map-marked-alt"></i>&nbsp;{userInfo.address}
                                </p>
                            </div>
                            <div className="row">
                                <p>
                                    <i title={TranslateHelper.getMessage('HRM.detail.HR.official.label.mobile')} className="fas fa-mobile-alt"></i>&nbsp;{userInfo.mobile}
                                </p>
                            </div>
                            <div className="row">
                                <p>
                                    <i title={TranslateHelper.getMessage('HRM.detail.HR.official.label.birth')} className="fas fa-birthday-cake"></i>&nbsp;{moment(userInfo.birthDay).format(TranslateHelper.getMessage('date.format'))}
                                </p>
                            </div>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
}
