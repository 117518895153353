import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class NotificationService {
    constructor () {
        serviceHelpers();
    }

    getNotification (limit = 50, page = 1) {
        return axiosService.get(API_URL + '/notifications', {
            params: {
                limit, page
            }
        }).then(response => response.data.data);
    }

    markOneNotification (id, payload) {
        return axiosService.patch(API_URL + '/notifications/item/' + id, payload).then(res => res.data.data);
    }

    readAllNotification () {
        return axiosService.patch(API_URL + '/notifications/readAll').then(res => res.data.data);
    }

    clearNotification () {
        return axiosService.patch(API_URL + '/notifications').then(response => response.data.data);
    }
}

export default new NotificationService();
