import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'admin-lte/plugins/sweetalert2/sweetalert2.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import Select from '@atlaskit/select';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageDatatables } from '../../../../../../lang';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../../actions/breadcrumb.action';
import SandboxService from '../../services/sandbox.service';
import RightSidebar from '../../../common/RightSidebar';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import ShowForPermission from '../../../common/ShowForPermissionComponent';
import Button from '@atlaskit/button';
import Switch from '@mui/material/Switch';
import { CircularProgress, Tooltip } from '@mui/material';
import MenuExpandSandbox from './MenuExpandSandbox';
import ReactPagination from '../../../ReactPagination';

const formResources = [
    {
        resource_id: '/**',
        readAttributes: '(**)',
        methods: [
            { value: 'get', label: TranslateHelper.getMessage('option.common.get') },
            { value: 'post', label: TranslateHelper.getMessage('option.common.post') },
            { value: 'put', label: TranslateHelper.getMessage('option.common.put') },
            { value: 'patch', label: TranslateHelper.getMessage('option.common.patch') },
            { value: 'delete', label: TranslateHelper.getMessage('option.common.delete') }
        ],
        writeAttributes: ''
    }
];
const formPermissions = [
    {
        path: '/cartridges',
        operations: [
            { value: 'read_write', label: TranslateHelper.getMessage('option.common.readwrite') }
        ]
    },
    {
        path: '/impex',
        operations: [
            { value: 'read_write', label: TranslateHelper.getMessage('option.common.readwrite') }
        ]
    },
    {
        path: '/logs',
        operations: [
            { value: 'read_write', label: TranslateHelper.getMessage('option.common.readwrite') }
        ]
    }
];

const parsePayload = function (dataParse, params) {
    const data = dataParse;
    for (const value in data) {
        if (Object.hasOwnProperty.call(data, value)) {
            const element = data[value];
            const array = [];
            if (element[params] !== undefined && element[params].length > 0) {
                element[params].forEach((ele, i) => {
                    array.push(ele.value);
                });
            }
            element[params] = array.concat();
        }
    }
    return data;
};

class SandboxList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSet: [],
            isError: {
                realm: '',
                tll: '',
                autoScheduled: '',
                resourceProfile: '',
                clientId: ''
            },
            form: {
                realm: '',
                tll: 0,
                autoScheduled: false,
                resourceProfile: 'medium',
                clientId: this.props.actived
            },
            isErrorResources: [
                { resource_id: '', readAttributes: '', methods: '', writeAttributes: '' }
            ],
            formResources,
            isErrorPermissions: [
                { path: '', operations: '' },
                { path: '', operations: '' },
                { path: '', operations: '' }
            ],
            formPermissions,
            methodOptions: [
                { value: 'get', label: TranslateHelper.getMessage('option.common.get') },
                { value: 'post', label: TranslateHelper.getMessage('option.common.post') },
                { value: 'put', label: TranslateHelper.getMessage('option.common.put') },
                { value: 'patch', label: TranslateHelper.getMessage('option.common.patch') },
                { value: 'delete', label: TranslateHelper.getMessage('option.common.delete') }
            ],
            operationOptions: [
                { value: 'read', label: TranslateHelper.getMessage('option.common.read') },
                {
                    value: 'read_write',
                    label: TranslateHelper.getMessage('option.common.readwrite')
                }
            ],
            isStarting: false,
            page: 1,
            totalPages: 1
        };
        this.handleCreateSandbox = this.handleCreateSandbox.bind(this);
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.sandboxes');
        const { dispatch } = this.props;
        dispatch(
            setBreadcrumb({
                title: 'menu.member.name.sandbox.sandboxes',
                breadcrumbItems: [
                    {
                        label: 'app.breadcrumb.common.home',
                        haslink: true,
                        path: '/dashboard'
                    },
                    {
                        label: 'menu.member.name.sandbox.sandboxes',
                        haslink: false,
                        path: ''
                    },
                    {
                        label: 'menu.member.name.sandbox.list',
                        haslink: false,
                        path: ''
                    }
                ]
            })
        );
        this.initDatatable();
        let timerInterval;
        if (this.props.actived) {
            this.getSandboxes(this.state.page);
            setInterval(() => {
                this.state.isStarting && this.getSandboxes(this.state.page);
            }, 60000);
        } else {
            Swal.fire({
                title: TranslateHelper.getMessage('respond.message.operationsandbox.refresh'),
                html: TranslateHelper.getMessage(
                    'respond.message.operationsandbox.refreshcountdown'
                ),
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector('b');
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                    }, 100);
                },
                willClose: () => {
                    clearInterval(timerInterval);
                    window.location.reload();
                }
            });
        }
    }

    shouldComponentUpdate (nextProps, nextState) {
        if (this.state.isStarting !== nextState.isStarting) {
            return true;
        }
        return false;
    }

    /**
     * Clear data component
     */
    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '450px' });
        }
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(
            function () {
                const table = $('#sandbox-list').DataTable({
                    paging: false,
                    lengthChange: true,
                    searching: true,
                    ordering: true,
                    info: false,
                    autoWidth: true,
                    responsive: true,
                    language: LanguageDatatables[lang],
                    columnDefs: [
                        { className: 'dt-center', targets: [5, 6] },
                        { orderable: false, targets: [6] }
                    ]
                });
                this.setState({ table });
            }.bind(this)
        );
    }

    getSandboxes (page) {
        SandboxService.getSandboxList(page || this.state.page)
            .then((res) => {
                this.setState({ totalPages: res.data.data.totalPages });
                this.setState({ dataSet: res.data.data.items, loadingStatus: true });
                const list = res.data.data.items.map(item => {
                    return item.state;
                });
                if (list.indexOf('starting')) {
                    this.setState({ isStarting: true });
                } else {
                    this.setState({ isStarting: false });
                }
                this.state.table ?? this.state.table.destroy();
                this.forceUpdate();
            })
            .catch((error) => {
                this.setState({ loadingStatus: true });
                if (error.response?.data) {
                    let timerInterval;
                    Swal.fire({
                        title: TranslateHelper.getMessage(
                            'respond.message.operationsandbox.refresh'
                        ),
                        html: TranslateHelper.getMessage(
                            'respond.message.operationsandbox.refreshcountdown'
                        ),
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b = Swal.getHtmlContainer().querySelector('b');
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                            window.location.reload();
                        }
                    });
                }
            });
    }

    addFormFields (field) {
        if (field === 'permissions') {
            this.setState({
                formPermissions: [
                    ...this.state.formPermissions,
                    {
                        path: '',
                        operations: [
                            {
                                value: 'read',
                                label: TranslateHelper.getMessage('option.common.read')
                            }
                        ]
                    }
                ],
                isErrorPermissions: [...this.state.isErrorPermissions, { path: '', operations: '' }]
            });
        }
        if (field === 'resources') {
            this.setState({
                formResources: [
                    ...this.state.formResources,
                    {
                        resource_id: '',
                        readAttributes: '',
                        methods: [
                            {
                                value: 'get',
                                label: TranslateHelper.getMessage('option.common.get')
                            },
                            {
                                value: 'post',
                                label: TranslateHelper.getMessage('option.common.post')
                            },
                            {
                                value: 'put',
                                label: TranslateHelper.getMessage('option.common.put')
                            },
                            {
                                value: 'patch',
                                label: TranslateHelper.getMessage('option.common.patch')
                            },
                            {
                                value: 'delete',
                                label: TranslateHelper.getMessage('option.common.delete')
                            }
                        ],
                        writeAttributes: ''
                    }
                ],
                isErrorResources: [
                    ...this.state.isErrorResources,
                    { resource_id: '', readAttributes: '', methods: '', writeAttributes: '' }
                ]
            });
        }
    }

    removeFormFields (field, i) {
        const { formPermissions, isErrorPermissions, formResources, isErrorResources } = this.state;
        if (field === 'permissions') {
            formPermissions.splice(i, 1);
            isErrorPermissions.splice(i, 1);
        }
        if (field === 'resources') {
            formResources.splice(i, 1);
            isErrorResources.splice(i, 1);
        }
        this.setState({ formPermissions, isErrorPermissions, formResources, isErrorResources });
    }

    onClickCreateSandbox () {
        this.openSidebar();
    }

    handleCreateSandbox () {
        if (this.handleValidationForm()) {
            const { form } = this.state;
            const payloadResources = parsePayload(
                JSON.parse(JSON.stringify(this.state.formResources)),
                'methods'
            );
            const payloadPermissions = parsePayload(
                JSON.parse(JSON.stringify(this.state.formPermissions)),
                'operations'
            );

            const payload = {
                realm: form.realm,
                ttl: form.tll,
                autoScheduled: form.autoScheduled,
                resourceProfile: form.resourceProfile,
                settings: {
                    ocapi: [
                        {
                            client_id: form.clientId,
                            resources: payloadResources
                        }
                    ],
                    webdav: [
                        {
                            client_id: form.clientId,
                            permissions: payloadPermissions
                        }
                    ]
                }
            };
            Swal.fire({
                title: TranslateHelper.getMessage('loading.title.operationsandbox.create'),
                html: TranslateHelper.getMessage('loading.html.common'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                    SandboxService.postCreateSandbox(payload)
                        .then((res) => {
                            Swal.close();
                            ToastTopEnd.fire({
                                icon: 'success',
                                title: TranslateHelper.getMessage(
                                    'respond.message.operationsandbox.create.successfully'
                                )
                            });
                            this.getSandboxes();
                            $('.sidebar-right').css({ width: '0' });
                            this.setState({
                                form: {
                                    realm: '',
                                    tll: 0,
                                    autoScheduled: false,
                                    resourceProfile: 'medium',
                                    clientId: this.props.actived
                                },
                                formResources,
                                formPermissions
                            });
                        })
                        .catch((err) => {
                            Swal.close();
                            if (err.response.data) {
                                ToastTopEnd.fire({
                                    icon: 'error',
                                    title: TranslateHelper.getMessage(
                                        'respond.message.operationsandbox.create.failed'
                                    )
                                });
                            }
                        });
                }
            });
        }
        this.forceUpdate();
    }

    handleDeleteSandbox (ints, sandboxId) {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete.sandbox.title') + ints + '?',
            text: TranslateHelper.getMessage('alert.delete.sandbox.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: TranslateHelper.getMessage('alert.delete.sandbox.confirm'),
            cancelButtonText: TranslateHelper.getMessage('alert.delete.sandbox.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: TranslateHelper.getMessage('loading.title.operationsandbox.delete'),
                    html: TranslateHelper.getMessage('loading.html.common'),
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                        SandboxService.deleteSandbox(sandboxId)
                            .then((res) => {
                                setTimeout(
                                    function () {
                                        Swal.close();
                                        ToastTopEnd.fire({
                                            icon: 'success',
                                            title: TranslateHelper.getMessage(
                                                'respond.message.operationsandbox.delete.successfully'
                                            )
                                        });
                                        this.getSandboxes();
                                    }.bind(this),
                                    3000
                                );
                            })
                            .catch((err) => {
                                Swal.close();
                                if (err.response.data) {
                                    ToastTopEnd.fire({
                                        icon: 'error',
                                        title: TranslateHelper.getMessage(
                                            'respond.message.operationsandbox.delete.failed'
                                        )
                                    });
                                }
                            });
                    }
                });
            }
        });
        this.forceUpdate();
    }

    handleOperations (sandboxId, action) {
        if (action === 'reset') {
            Swal.fire({
                title: TranslateHelper.getMessage('alert.reset.sandbox.title') + '?',
                text: TranslateHelper.getMessage('alert.reset.sandbox.text'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: TranslateHelper.getMessage('alert.reset.sandbox.confirm'),
                cancelButtonText: TranslateHelper.getMessage('alert.reset.sandbox.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.runOperations(sandboxId, action);
                }
            });
        } else {
            this.runOperations(sandboxId, action);
        }
    }

    runOperations (sandboxId, action) {
        const keyLoading = 'loading.title.operationsandbox.' + action;
        const keySuccessfully = 'respond.message.operationsandbox.' + action + '.successfully';
        const keyFailed = 'respond.message.operationsandbox.' + action + '.failed';
        Swal.fire({
            title: TranslateHelper.getMessage(keyLoading),
            html: TranslateHelper.getMessage('loading.html.common'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
                SandboxService.setSandboxOperations(sandboxId, { operation: action })
                    .then((res) => {
                        Swal.close();
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage(keySuccessfully)
                        });
                        this.getSandboxes();
                    })
                    .catch((error) => {
                        Swal.close();
                        if (error.response.data) {
                            ToastTopEnd.fire({
                                icon: 'error',
                                title: TranslateHelper.getMessage(keyFailed)
                            });
                        }
                    });
            }
        });
    }

    handleValidationForm () {
        let validation = true;
        const {
            form,
            isError,
            formPermissions,
            isErrorPermissions,
            formResources,
            isErrorResources
        } = this.state;
        const reg = /^[0-9]*$/;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'tll':
                    if (!reg.test(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.numberic');
                        validation = false;
                    }
                    break;
                case 'realm':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        formPermissions.map((element, index) => {
            Object.entries(element).map(([key, value], i) => {
                switch (key) {
                    case 'path':
                        if (value === '') {
                            isErrorPermissions[index][key] = TranslateHelper.getMessage(
                                'validation.error.required'
                            );
                            validation = false;
                        }
                        break;
                    case 'operations':
                        if (!value.length) {
                            isErrorPermissions[index][key] = TranslateHelper.getMessage(
                                'validation.error.required'
                            );
                            validation = false;
                        }
                        break;
                    default:
                        break;
                }
                return 0;
            });
            return 0;
        });
        formResources.map((element, index) => {
            Object.entries(element).map(([key, value], i) => {
                switch (key) {
                    case 'resource_id':
                        if (value === '') {
                            isErrorResources[index][key] = TranslateHelper.getMessage(
                                'validation.error.required'
                            );
                            validation = false;
                        }
                        break;
                    case 'methods':
                        if (!value.length) {
                            isErrorResources[index][key] = TranslateHelper.getMessage(
                                'validation.error.required'
                            );
                            validation = false;
                        }
                        break;
                    default:
                        break;
                }
                return 0;
            });
            return 0;
        });
        return validation;
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'realm':
            case 'tll':
            case 'resourceProfile':
            case 'autoScheduled':
                form[name] = event.target.value.trim();
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleInputChangeFieldGroup (name, index, field, event) {
        const { formPermissions, isErrorPermissions, formResources, isErrorResources } = this.state;
        if (field === 'permissions') {
            switch (name) {
                case 'path':
                    formPermissions[index][name] = event.target.value.trim();
                    isErrorPermissions[index][name] = '';
                    break;
                default:
                    break;
            }
        }
        if (field === 'resources') {
            switch (name) {
                case 'resource_id':
                case 'readAttributes':
                case 'writeAttributes':
                    formResources[index][name] = event.target.value.trim();
                    isErrorResources[index][name] = '';
                    break;
                default:
                    break;
            }
        }
        this.setState({ formPermissions, isErrorPermissions, formResources, isErrorResources });
    }

    handleMultiSelectChange (name, index, field, option) {
        const { formPermissions, isErrorPermissions, formResources, isErrorResources } = this.state;
        if (field === 'permissions') {
            switch (name) {
                case 'operations':
                    formPermissions[index][name] = option;
                    isErrorPermissions[index][name] = '';
                    break;
                default:
                    break;
            }
        }
        if (field === 'resources') {
            switch (name) {
                case 'methods':
                    formResources[index][name] = option;
                    isErrorResources[index][name] = '';
                    break;
                default:
                    break;
            }
        }
        this.setState({ formPermissions, isErrorPermissions, formResources, isErrorResources });
    }

    mapElementResource (data, isError) {
        return data.map((element, index) => {
            return (
                <div key={index} className="form-sandbox-group">
                    {index === 0
                        ? (
                        <></>
                            )
                        : (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span
                                className="remove-field-group"
                                onClick={() => {
                                    this.removeFormFields('resources', index);
                                }}
                            >
                                <i className="far fa-times-circle"></i>
                            </span>
                        </div>
                            )}
                    <div>
                        <div className="row">
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.resourceid" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    value={element.resource_id || ''}
                                    type="text"
                                    placeholder="/**"
                                    name="resource_id"
                                    onChange={this.handleInputChangeFieldGroup.bind(
                                        this,
                                        'resource_id',
                                        index,
                                        'resources'
                                    )}
                                    className={
                                        isError[index].resource_id.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError[index].resource_id.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].resource_id}
                                    </span>
                                )}
                            </div>
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.readattributes" />
                                </label>
                                <input
                                    value={element.readAttributes || ''}
                                    type="text"
                                    placeholder="(**)"
                                    name="readAttributes"
                                    onChange={this.handleInputChangeFieldGroup.bind(
                                        this,
                                        'readAttributes',
                                        index,
                                        'resources'
                                    )}
                                    className={
                                        isError[index].readAttributes.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError[index].readAttributes.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].readAttributes}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.methods" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Select
                                    name="methods"
                                    placeholder="Filters"
                                    value={element.methods}
                                    options={this.state.methodOptions}
                                    onChange={this.handleMultiSelectChange.bind(
                                        this,
                                        'methods',
                                        index,
                                        'resources'
                                    )}
                                    isMulti
                                />
                                <div
                                    className={
                                        isError[index].methods.length > 0 ? 'is-invalid' : ''
                                    }
                                ></div>
                                {isError[index].methods.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].methods}
                                    </span>
                                )}
                            </div>
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.writeattributes" />
                                </label>
                                <input
                                    value={element.writeAttributes || ''}
                                    type="text"
                                    placeholder="(**)"
                                    name="writeAttributes"
                                    onChange={this.handleInputChangeFieldGroup.bind(
                                        this,
                                        'writeAttributes',
                                        index,
                                        'resources'
                                    )}
                                    className={
                                        isError[index].writeAttributes.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError[index].writeAttributes.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].writeAttributes}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    mapElementPermission (data, isError) {
        return data.map((element, index) => {
            return (
                <div key={index} className="form-sandbox-group">
                    {index === 0
                        ? (
                        <></>
                            )
                        : (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span
                                className="remove-field-group"
                                onClick={() => {
                                    this.removeFormFields('permissions', index);
                                }}
                            >
                                <i className="far fa-times-circle"></i>
                            </span>
                        </div>
                            )}
                    <div>
                        <div className="row">
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.path" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    value={element.path || ''}
                                    type="text"
                                    placeholder="/cartridges"
                                    name="path"
                                    onChange={this.handleInputChangeFieldGroup.bind(
                                        this,
                                        'path',
                                        index,
                                        'permissions'
                                    )}
                                    className={
                                        isError[index].path.length > 0
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                />
                                {isError[index].path.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].path}
                                    </span>
                                )}
                            </div>
                            <div className="col-6 form-group">
                                <label className="label-400">
                                    <FormattedMessage id="sandbox.form.label.operations" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Select
                                    name="operations"
                                    value={element.operations}
                                    options={this.state.operationOptions}
                                    onChange={this.handleMultiSelectChange.bind(
                                        this,
                                        'operations',
                                        index,
                                        'permissions'
                                    )}
                                    isMulti
                                />
                                <div
                                    className={
                                        isError[index].operations.length > 0 ? 'is-invalid' : ''
                                    }
                                ></div>
                                {isError[index].operations.length > 0 && (
                                    <span className="error invalid-feedback">
                                        {isError[index].operations}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render () {
        const { match } = this.props;
        const { isError } = this.state;
        const renderStatus = (state, id) => {
            switch (state) {
                case 'stopped':
                    return (
                        <Tooltip title={TranslateHelper.getMessage('sandbox.status.stopped')}>
                            <Switch checked={false} onChange={() => this.handleOperations(id, 'start')} inputProps={{ 'aria-label': 'controlled' }}/>
                        </Tooltip>
                    );
                case 'starting':
                    return (
                        <Tooltip title='Starting'>
                            <CircularProgress/>
                        </Tooltip>
                    );
                default:
                    return (
                        <Tooltip title={TranslateHelper.getMessage('sandbox.status.online')}>
                            <Switch checked={true} onChange={() => this.handleOperations(id, 'stop')} inputProps={{ 'aria-label': 'controlled' }}/>
                        </Tooltip>
                    );
            }
        };
        const rowData = this.state.dataSet.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item.realm}</td>
                    <td>
                        <span
                            data-tip
                            data-for={`tooltip-url-sandbox${item.hostName.split('.')[0]}`}
                        >
                            {item.hostName.split('.')[0]}
                        </span>
                        <ReactTooltip id={`tooltip-url-sandbox${item.hostName.split('.')[0]}`}>
                            <span>{item.hostName}</span>
                        </ReactTooltip>
                    </td>
                    <td>
                        <FormattedDate
                            value={item.createdAt}
                            day="numeric"
                            month="numeric"
                            year="numeric"
                        />
                    </td>
                    <td>{item.createdBy}</td>
                    <td>{renderStatus(item.state, item.id)}</td>
                    <td>
                        <MenuExpandSandbox id={item.id} match={match} hostname={item.hostName}/>
                    </td>
                </tr>
            );
        });

        const handlePageChange = (event, page) => {
            this.setState({ page });
            this.getSandboxes(page);
        };
        return (
            <div className="container-fluid">
                <div className="row">
                    <ShowForPermission permissionKey="addSandbox">
                        <div className="col-sm-12">
                            <Button
                                type="button"
                                onClick={() => {
                                    this.onClickCreateSandbox();
                                }}
                                appearance='primary'
                            >
                                <FormattedMessage id="button.name.common.create" />
                            </Button>
                        </div>
                    </ShowForPermission>
                </div>
                <div style={{ height: '20px' }}></div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                    <FormattedMessage id="sandbox.table.title" />
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="table-scrollX">
                                    {this.state.loadingStatus
                                        ? (
                                        <div>
                                            {this.state.dataSet.length
                                                ? (
                                                <table
                                                    id="sandbox-list"
                                                    className="table table-bordered table-hover"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>
                                                                <FormattedMessage id="sandbox.table.column.realm" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="sandbox.table.column.url" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="sandbox.table.column.createdAt" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="sandbox.table.column.createdBy" />
                                                            </th>
                                                            <th>
                                                                <FormattedMessage id="sandbox.table.column.status" />
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{rowData}</tbody>
                                                </table>
                                                    )
                                                : (
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <p>
                                                        <strong>
                                                            <FormattedMessage id="message.no.data.available" />
                                                        </strong>
                                                    </p>
                                                </div>
                                                    )}
                                        </div>
                                            )
                                        : (
                                        <div className="overlay">
                                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                        </div>
                                            )}
                                        {this.state.totalPages > 1 && (
                                            <ReactPagination
                                                totalPages={this.state.totalPages}
                                                handlePageChange={handlePageChange}
                                                pageNumber={this.state.page}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightSidebar title="Create Sandbox">
                    <div className="form-group">
                        <label className="label-400">
                            <FormattedMessage id="sandbox.form.label.realm" />
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            value={this.state.form.realm}
                            type="text"
                            placeholder="zzgm"
                            name="realm"
                            onChange={this.handleInputChange.bind(this, 'realm')}
                            className={
                                isError.realm.length > 0
                                    ? 'is-invalid form-control'
                                    : 'form-control'
                            }
                        />
                        {isError.realm.length > 0 && (
                            <span className="error invalid-feedback">{isError.realm}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-400">
                            <FormattedMessage id="sandbox.form.label.clientid" />
                        </label>
                        <input
                            value={this.state.form.clientId}
                            type="text"
                            name="clientId"
                            className="form-control"
                            readOnly
                        />
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="label-400">
                                <FormattedMessage id="sandbox.form.label.numberofhours" />
                            </label>
                            <input
                                value={this.state.form.tll}
                                type="text"
                                name="tll"
                                onChange={this.handleInputChange.bind(this, 'tll')}
                                className={
                                    isError.tll.length > 0
                                        ? 'is-invalid form-control'
                                        : 'form-control'
                                }
                            />
                            {isError.tll.length > 0 && (
                                <span className="error invalid-feedback">{isError.tll}</span>
                            )}
                        </div>
                        <div className="col-6 form-group">
                            <label className="label-400">
                                <FormattedMessage id="sandbox.form.label.autoscheduled" />
                            </label>
                            <select
                                name="autoScheduled"
                                className="form-control"
                                value={this.state.form.autoScheduled}
                                onChange={this.handleInputChange.bind(this, 'autoScheduled')}
                            >
                                <option value="true">
                                    {TranslateHelper.getMessage('option.common.yes')}
                                </option>
                                <option value="false">
                                    {TranslateHelper.getMessage('option.common.no')}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="label-400">
                                <FormattedMessage id="sandbox.form.label.resourceprofile" />
                            </label>
                            <select
                                name="resourceProfile"
                                className="form-control"
                                value={this.state.form.resourceProfile}
                                onChange={this.handleInputChange.bind(this, 'resourceProfile')}
                            >
                                <option value="medium">
                                    {TranslateHelper.getMessage('option.common.medium')}
                                </option>
                                <option value="large">
                                    {TranslateHelper.getMessage('option.common.large')}
                                </option>
                                <option value="xlarge">
                                    {TranslateHelper.getMessage('option.common.supperlarge')}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style={{ width: '100%', borderBottom: '1px solid #ddd' }}>
                        <h4>
                            <FormattedMessage id="sandbox.form.label.settingsinfo" />
                        </h4>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <h5>
                            <FormattedMessage id="sandbox.form.label.ocapiresources" />
                        </h5>
                    </div>
                    {this.mapElementResource(this.state.formResources, this.state.isErrorResources)}
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span
                            className="add-field-group"
                            onClick={() => {
                                this.addFormFields('resources');
                            }}
                        >
                            <i className="fas fa-plus-circle"></i>
                        </span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <h5>
                            <FormattedMessage id="sandbox.form.label.ocapiresources" />
                        </h5>
                    </div>
                    {this.mapElementPermission(
                        this.state.formPermissions,
                        this.state.isErrorPermissions
                    )}
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span
                            className="add-field-group"
                            onClick={() => {
                                this.addFormFields('permissions');
                            }}
                        >
                            <i className="fas fa-plus-circle"></i>
                        </span>
                    </div>
                    <Button
                        type="button"
                        appearance='primary'
                        onClick={() => {
                            this.handleCreateSandbox();
                        }}
                    >
                        <FormattedMessage id="button.name.common.create" />
                    </Button>
                </RightSidebar>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { actived } = state.memberClientId;
    const { role } = state.auth;
    return { actived, role };
}

export default connect(mapStateToProps)(SandboxList);
