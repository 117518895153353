import DashboardRoutes, { dashboardMenuLinks } from './dashboard/routes';
import AccoutRoutes, { accountMenuLinks } from '../manager/account/routes';
import SandboxRoutes, { sandboxMenuLinks } from '../common/components/sandbox/routes';
import SettingRoute from './setting/routes';
import SubscriptionRoutes, { subscriptionLinks } from './subscription/routes';
import ProjectRoutes, { projectMenuLinks } from '../common/components/project/routes';

export const adminMenuLinks = [
    ...dashboardMenuLinks,
    ...subscriptionLinks,
    ...accountMenuLinks,
    ...sandboxMenuLinks,
    ...projectMenuLinks
];

const routes = [
    ...DashboardRoutes,
    ...SubscriptionRoutes,
    ...AccoutRoutes,
    ...SandboxRoutes,
    ...SettingRoute,
    ...ProjectRoutes
];

export default routes;
