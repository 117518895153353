import { ACTION_BREADCRUMB } from '../constants/action.constant';

const initialState = {
    breadcrumb: {
        title: 'app.breadcrumb.common.dashboard',
        breadcrumbItems: [
            {
                label: 'app.breadcrumb.common.home',
                haslink: true,
                path: '/dashboard'
            },
            {
                label: 'app.breadcrumb.common.dashboard',
                haslink: false,
                path: ''
            }
        ]
    }
};

const breadcrumbReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ACTION_BREADCRUMB.SET_BREADCRUMB:
            return {
                ...state,
                breadcrumb: payload
            };

        case ACTION_BREADCRUMB.CLEAR_BREADCRUMB:
            return {
                ...state,
                breadcrumb: payload
            };

        default:
            return state;
    }
};

export default breadcrumbReducer;
