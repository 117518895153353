import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import Context from '../context';
import { LANGUAGES, LanguageDatepicker } from '../../../lang/index';
import App from '../App';

class MasterWapper extends Component {
    constructor (props) {
        super(props);
        const lang = localStorage.getItem('lang');
        switch (lang) {
            case 'en':
                LanguageDatepicker.en();
                this.state = LANGUAGES.en_US;
                break;
            case 'ja':
                LanguageDatepicker.ja();
                this.state = LANGUAGES.ja_JP;
                break;
            case 'vi':
                LanguageDatepicker.vi();
                this.state = LANGUAGES.vi_VN;
                break;
            default:
                LanguageDatepicker.en();
                localStorage.setItem('lang', 'en');
                this.state = LANGUAGES.en_US;
                break;
        }

        this.selectLanguage = this.selectLanguage.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
    }

    selectLanguage (lang) {
        LanguageDatepicker[lang]();
        switch (lang) {
            case 'en':
                this.setState(LANGUAGES.en_US);
                break;
            case 'ja':
                this.setState(LANGUAGES.ja_JP);
                break;
            case 'vi':
                this.setState(LANGUAGES.vi_VN);
                break;
            default:
                this.setState(LANGUAGES.en_US);
                break;
        }
        localStorage.setItem('lang', lang);
    }

    selectTheme (e) {
        // handle theme
    }

    render () {
        return (
            <Context.Provider value = {{ locale: this.state.locale, updateLanguage: this.selectLanguage }}>
                <IntlProvider locale={this.state.locale} messages={this.state.messages}>
                    <App>
                    </App>
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export default MasterWapper;
