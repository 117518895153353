import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Account from './components/Account';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const accountMenuLinks = [
    {
        name: 'menu.manager.name.account',
        classNames: 'nav-item',
        to: '/accounts',
        icon: (<PeopleAltIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.PARTNEROWNER],
        key: 3
    }
];

const routes = [
    {
        path: '/accounts',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.PARTNEROWNER_ACCESS], <Account/>);
        }
    }
];

export default routes;
