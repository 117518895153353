import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ToastTopEnd } from '../../common/helpers/ToastTimer';
import TranslateHelper from '../../common/helpers/TranslateHelper';
import ladingService from '../landingPage.service';
import './ContactForm.css';
import packageService from '../../manager/package/components/Services/package.service';
import { useService } from '../../common/helpers/serviceHelpers';
import { FormattedMessage } from 'react-intl';
import CurrencyFormat from 'react-currency-format';
import { regexName } from '../../common/constants/validation.constant';
import Button from '@atlaskit/button';

export default function ContactForm ({ clearForm, shouldClearForm, packageName, hiddenContactFormRef }) {
    const { register, handleSubmit, setValue, formState: { errors }, clearErrors, reset } = useForm({
        defaultValues: {
            paymentMethodName: 'paypal',
            packageName,
            longName: ''
        }
    });

    useEffect(() => {
        if (reset) {
            setValue('shortName', '');
            setValue('longName', '');
            setValue('email', '');
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('phoneNumber', '');
            setValue('address', '');
            setValue('country', '');
            setValue('packageName', packageName);
            clearErrors();
            shouldClearForm(false);
        }
    }, [clearForm, packageName]);

    const { data: packageList, isLoading } = useService(
        packageService.getAllPackage,
        {},
        (res) => res.items
    );

    const handleRegister = useCallback(async (formData) => {
        formData.paymentMethodName = 'Paypal';
        formData.ownerEmail = formData.email;

        try {
            await ladingService.createSubscription(formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });
            hiddenContactFormRef.current.click();
            reset();
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    }, []);

    return (
        <section id="contact" className="section welcome-area bg-inherit h-100vh overflow-hidden">
            <div className="shapes-container">
                <div className="bg-shape" />
            </div>
            <div className=" h-100">
                <div className="row align-items-center h-100 content">
                    <div className="col-12 col-md-5">
                        <div className="welcome-intro py-4">
                            <h1 className="pb-3">
                                <FormattedMessage id="landing.title" />
                            </h1>
                            <form onSubmit={handleSubmit(handleRegister)}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.shortName" />
                                        </label>
                                        <input
                                            {...register('shortName', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                pattern: {
                                                    value: regexName,
                                                    message: TranslateHelper.getMessage('validation.error.containSpecialCharacter')
                                                }
                                            })}
                                            placeholder="Short name"
                                            className = {errors.shortName ? 'error' : 'input'}
                                        />
                                        {errors.shortName && <span className='text-danger'>{errors.shortName.message}</span>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.longName" />
                                        </label>
                                        <input
                                            {...register('longName', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                pattern: {
                                                    value: regexName,
                                                    message: TranslateHelper.getMessage('validation.error.containSpecialCharacter')
                                                }
                                            })}
                                            placeholder="Long name"
                                            className={errors.longName ? 'error' : 'input'}
                                        />
                                        {errors.longName && <span className='text-danger'>{errors.longName.message}</span>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <FormattedMessage id="landing.label.email" />
                                    </label>
                                    <input
                                        {...register('email', {
                                            required: true
                                        })}
                                        type="email"
                                        placeholder="email@kimei.vn"
                                        className={errors.email ? 'error' : 'input'}
                                    />
                                    {errors.email && <span className='text-danger'><FormattedMessage id='validation.error.required' /></span>}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.firstname" />
                                        </label>
                                        <input
                                            {...register('firstname', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                pattern: {
                                                    value: regexName,
                                                    message: TranslateHelper.getMessage('validation.error.containSpecialCharacter')
                                                }
                                            })}
                                            placeholder="First name"
                                            className={errors.firstname ? 'error' : 'input'}
                                        />
                                        {errors.firstname && <span className='text-danger'>{errors.firstname.message}</span>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.lastname" />
                                        </label>
                                        <input
                                            {...register('lastname', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                pattern: {
                                                    value: regexName,
                                                    message: TranslateHelper.getMessage('validation.error.containSpecialCharacter')
                                                }
                                            })}
                                            placeholder="Last name"
                                            className={errors.lastname ? 'error' : 'input'}
                                        />
                                        {errors.lastname && <span className='text-danger'>{errors.lastname.message}</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.phoneNumber" />
                                        </label>
                                        <input
                                            {...register('phoneNumber', { required: true })}
                                            placeholder="035xxxxxxx"
                                            className={errors.phoneNumber ? 'error' : 'input'}
                                        />
                                        {errors.phoneNumber && <span className='text-danger'><FormattedMessage id='validation.error.required' /></span>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            <FormattedMessage id="landing.label.country" />
                                        </label>
                                        <input
                                            {...register('country', { required: true })}
                                            placeholder="Viet Nam"
                                            className={errors.country ? 'error' : 'input'}
                                        />
                                        {errors.country && <span className='text-danger'><FormattedMessage id='validation.error.required' /></span>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <FormattedMessage id="landing.label.address" />
                                    </label>
                                    <input
                                        {...register('address', { required: true })}
                                        placeholder="21 Le Trung Nghia"
                                        className={errors.address ? 'error' : 'input'}
                                    />
                                    {errors.address && <span className='text-danger'><FormattedMessage id='validation.error.required' /></span>}
                                </div>
                                <div className="form-group">
                                    <label>
                                        <FormattedMessage id="landing.label.package" />
                                    </label>
                                    {isLoading
                                        ? (
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                            )
                                        : (
                                                packageList.map((item, index) => (
                                            <div className="form-check py-1" key={item._id}>
                                                <input
                                                    {...register('packageName', {
                                                        required: true
                                                    })}
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={item._id}
                                                    value={item.name}
                                                    name="packageName"
                                                    defaultChecked={index === 0}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={item._id}
                                                >
                                                    {item.name} -{' '}
                                                    <CurrencyFormat value={item?.price} displayType={'text'} thousandSeparator={true} prefix={TranslateHelper.getMessage('prefix.price')} />
                                                    (
                                                    {item.durations} days)
                                                </label>
                                            </div>
                                                ))
                                            )}
                                </div>
                                <Button type="submit" appearance='primary'>
                                    <FormattedMessage id="button.name.common.signUp" />
                                </Button>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="welcome-thumb">
                            <img className="bg-img" alt="" src="/images/logo.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
