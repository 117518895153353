import serviceHelpers from '../../../../common/helpers/serviceHelpers';
import axiosService from '../../../../common/services/axios.service';
const API_URL_HRM = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';
const API_URL_PRJ = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects';

class DashboardService {
    constructor () {
        serviceHelpers();
    }

    getDepartment (limit = 1000) {
        return axiosService.get(API_URL_HRM + 'businessDepartments', { params: { limit } }).then((res) => res.data.data);
    }

    getEmployee (limit = 1000) {
        return axiosService.get(API_URL_HRM + 'employees', { params: { limit } }).then((res) => res.data.data);
    }

    async getProjects (limit = 1000) {
        return await axiosService.get(API_URL_PRJ, { params: { limit } }).then((res) => res.data.data);
    }

    getLeaveRequest (fromDate) {
        return axiosService.get(API_URL_HRM + 'services/leaves', { params: { fromDate } }).then((res) => res.data.data);
    }

    getLeaveRequestApproved (fromDate) {
        return axiosService.get(API_URL_HRM + 'services/leaves/approved', { params: { fromDate } }).then(res => res.data.data);
    }

    getAllLeaveRequestApproved (limit = 999) {
        return axiosService.get(API_URL_HRM + 'services/leaves/approved', { params: { limit } }).then(res => res.data.data);
    }

    getBirthday () {
        return axiosService.get(API_URL_HRM + 'employees/birthdays').then((res) => res.data.data);
    }
}

export default new DashboardService();
