import moment from 'moment';
import TranslateHelper from '../../../helpers/TranslateHelper';
import hrService from '../../hrm/Services/hr.service';
import recruitmentService from '../../hrm/Services/recruitment.service';
import Skeleton from 'react-loading-skeleton';
import Comment, { CommentTime, CommentAuthor } from '@atlaskit/comment';
import Avatar from '@atlaskit/avatar';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export default function IssueDetailHistory ({ data, isLoading, issueInfor }) {
    const params = useParams();
    const [dataOptions, setDataOptions] = useState({});
    const fetchEmployeeById = (id, index, picture) => {
        hrService.getEmployeeById(id).then(res => {
            const avt = picture ? !String(picture).includes('uploads') ? picture : recruitmentService.buildFileURL(picture) : '/images/default-avatar.png';
            const popup = document.getElementById('employee-popup-' + index);
            const html = `<div class="popup-container">
                            <div class='popup-img'>
                                <img onerror="this.src = '/images/default-avatar.png';" src=${avt}>
                            </div>
                            <div class="popup-employee-detail">
                                <span>${res.firstname} ${res.lastname}<br>
                                ${res.departmentId.name}<span><br>
                                <span><i class="far fa-envelope"></i> ${res.username}<br>
                                    <i class="far fa-id-badge"></i> ${res.id}
                                </span>
                            </div>
                        </div>`;
            popup.innerHTML = html;
        });
    };

    const activitiesLoading = () => {
        return (
            <div>
                <div className="row pt-2 pl-3">
                        <div className="pr-2">
                            <Skeleton height={'50px'} width={'50px'} circle={true} />
                        </div>
                        <div>
                            <div>
                                <Skeleton width={'100px'} />
                            </div>
                            <div>
                                <Skeleton width={'200px'} />
                            </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className="pr-2 col-8">
                            <Skeleton />
                        </div>
                        <div className="pr-2 col-3">
                            <Skeleton />
                        </div>
                    </div>
            </div>
        );
    };

    const renderPriority = (priority) => {
        switch (priority) {
            case 1:
                return (<span><i className='fas fa-angle-double-down text-primary'></i> Low</span>);
            case 2:
                return (<span><i className='fas fa-equals text-warning'></i> Medium</span>);
            case 3:
                return (<span><i className='fas fa-angle-double-up text-danger'></i> Hight</span>);
            default:
                break;
        }
    };

    const fetchAllInfor = useCallback(() => {
        const dataOptions = {
            statusTasks: issueInfor.statusTasks,
            assignTo: issueInfor.members,
            createdBy: issueInfor.members,
            milestones: issueInfor.milestones,
            categories: issueInfor.categories
        };
        for (const key of Object.keys(dataOptions)) {
            switch (key) {
                case 'statusTasks':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(([value, label]) => ({ label: label.name, value: label._id }));
                    break;
                case 'milestones':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(([value, label]) => ({ label: label.milestoneName, value: label._id }));
                    break;
                case 'categories':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(([value, label]) => ({ label: label.categoryName, value: label._id }));
                    break;
                case 'assignTo':
                    dataOptions[key] = Object.entries(dataOptions[key]).map(([value, label]) => ({ label: label.user?.firstname + ' ' + label.user?.lastname, value: label?.user?._id, image: label.user?.picture }));
                    break;
                default:
                // no default
            }
        }
        setDataOptions(dataOptions);
    }, [params.projectSlug, issueInfor]);

    useEffect(fetchAllInfor, [fetchAllInfor]);

    const detectHistoryActive = (obj, isTitle = true) => {
        if (Object.prototype.hasOwnProperty.call(obj.newData, 'status')) {
            if (isTitle) {
                return 'changed the Status';
            } else {
                let newStatus;
                dataOptions.statusTasks?.forEach(item => {
                    if (obj.newData.status === item?.value) {
                        newStatus = item?.label;
                    }
                });
                return (
                    <p>{obj.oldData[0].status.name} &rarr; <span className='hightlight-activities'>{newStatus}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'assignTo')) {
            if (isTitle) {
                return 'changed the Assign';
            } else {
                const newData = {
                    name: 'Unassign',
                    picture: undefined
                };
                dataOptions.assignTo?.forEach(item => {
                    if (obj.newData.assignTo === item?.value) {
                        newData.name = item?.label;
                        newData.picture = item?.image;
                    }
                });
                return (
                    <div className='d-flex'>
                        {obj.oldData[0].assignTo?.firstname
                            ? (<div className='d-flex'><Avatar src={obj.oldData[0].assignTo?.picture ? (String(obj.oldData[0].assignTo?.picture).includes('uploads') ? recruitmentService.buildFileURL(obj.oldData[0].assignTo?.picture) : obj.oldData[0].assignTo?.picture) : '/images/default-avatar.png'} /><span className='align-self-center'>&nbsp;{obj.oldData[0].assignTo.firstname} {obj.oldData[0].assignTo.lastname}</span></div>)
                            : <span className='none-data align-self-center'>Unassign</span> }<span className='align-self-center'>&nbsp;&rarr;&nbsp;</span> <div className='d-flex'><Avatar src={newData?.picture ? (String(newData?.picture).includes('uploads') ? recruitmentService.buildFileURL(newData?.picture) : newData?.picture) : '/images/default-avatar.png'} /><span className='align-self-center hightlight-activities'>{newData?.name}</span></div>
                    </div>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'priority')) {
            if (isTitle) {
                return 'changed the Priority';
            } else {
                return (
                    <p>{renderPriority(obj.oldData[0].priority)} &rarr; <span className='hightlight-activities'>{renderPriority(obj.newData.priority)}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'categoryId')) {
            if (isTitle) {
                return 'changed the Category';
            } else {
                let newCateogry;
                dataOptions.categories?.forEach(item => {
                    if (obj.newData.categoryId === item?.value) {
                        newCateogry = item?.label;
                    }
                });
                return (
                    <p>{obj.oldData[0].categoryId?.categoryName ? obj.oldData[0].categoryId?.categoryName : <span className='none-data'>None</span>} &rarr; <span className='hightlight-activities'>{newCateogry}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'milestoneId')) {
            if (isTitle) {
                return 'changed the Milestone';
            } else {
                let newMilestone;
                dataOptions.milestones?.forEach(item => {
                    if (obj.newData.milestoneId === item?.value) {
                        newMilestone = item?.label;
                    }
                });
                return (
                    <p>{obj.oldData[0].milestoneId?.milestoneName ? obj.oldData[0].milestoneId?.milestoneName : <span className='none-data'>None</span>} &rarr; <span className='hightlight-activities'>{newMilestone}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'startDate')) {
            if (isTitle) {
                return 'changed the Start Date';
            } else {
                return (
                    <p>{obj.oldData[0].startDate ? moment(obj.oldData[0]?.startDate).format(TranslateHelper.getMessage('date.format')) : <span className='none-data'>None</span>} &rarr; <span className='hightlight-activities'>{moment(obj.newData?.startDate).format(TranslateHelper.getMessage('date.format'))}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'dueDate')) {
            if (isTitle) {
                return 'changed the Due Date';
            } else {
                return (
                    <p>{obj.oldData[0].dueDate ? moment(obj.oldData[0]?.dueDate).format(TranslateHelper.getMessage('date.format')) : <span className='none-data'>None</span>} &rarr; <span className='hightlight-activities'>{moment(obj.newData?.dueDate).format(TranslateHelper.getMessage('date.format'))}</span></p>
                );
            }
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'description')) {
            return 'Updated the description';
        } else if (Object.prototype.hasOwnProperty.call(obj.newData, 'subject')) {
            if (isTitle) {
                return 'updated the subject title';
            } else {
                return (
                    <p>{obj.oldData[0].subject} &rarr; <span className='hightlight-activities'>{obj.newData.subject}</span></p>
                );
            }
        } else {
            if (isTitle) {
                return 'added an attachment';
            } else {
                return (
                    <p>&rarr; {obj.newData.files[0].name}</p>
                );
            }
        }
    };

    const convertToJSON = (str, createdBy, createdAt, index) => {
        const data = JSON.parse(str);
        return (
            <>
                <Comment
                    avatar={<Avatar src={createdBy?.picture ? (String(createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(createdBy?.picture) : createdBy?.picture) : '/images/default-avatar.png'} />}
                    author={<CommentAuthor onClick={() => fetchEmployeeById(createdBy.id, index, createdBy.picture) }>
                        <span data-toggle="dropdown" aria-expanded="true" className='pointer'>{createdBy?.firstname} {createdBy?.lastname}</span>
                        <ul className="dropdown-menu dropdown-menu-right" style={{ zIndex: '9999', backgroundColor: 'transparent' }}>
                            <div id={'employee-popup-' + index}></div>
                        </ul>
                        </CommentAuthor>}
                    time={<CommentTime>{moment(createdAt).format('LLL')}</CommentTime>}
                    type={detectHistoryActive(data)}
                    content={detectHistoryActive(data, false)}
                    afterContent={(
                        <p>
                            {moment(createdAt).fromNow()}
                        </p>
                    )}
                />
            </>
        );
    };

    return (
        <>
            {isLoading
                ? new Array(4).fill(null).map(activitiesLoading)
                : (
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                        {data.items?.map((activity, index, arr) => {
                            const previousDate = arr[index - 1];
                            return (
                                <div className="col-12" key={index}>
                                    {index === 0 ||
                                    (previousDate &&
                                        moment(previousDate.createdAt).format(
                                            TranslateHelper.getMessage('date.format')
                                        ) !==
                                            moment(activity.createdAt).format(
                                                TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                            ))
                                        ? (
                                        <h5
                                            className="pt-4"
                                            style={{
                                                borderBottom: '1px solid #E7E8EC',
                                                paddingBottom: '5px',
                                                color: '#ADAEB6'
                                            }}
                                        >
                                            {moment(activity.createdAt).format(
                                                TranslateHelper.getMessage(
                                                    'date.format'
                                                )
                                            )}
                                        </h5>
                                            )
                                        : null}
                                    <div className="post">
                                        {activity.history && convertToJSON(activity?.history, activity?.createdBy, activity?.createdAt, index)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                    )}
        </>
    );
}
