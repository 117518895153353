import { Bar } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, BarController, Title, Tooltip, Legend } from 'chart.js';
import ReactTooltip from 'react-tooltip';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import moment from 'moment';
import { useEffect, useState } from 'react';
ChartJS.register(CategoryScale, ArcElement, LinearScale, BarElement, BarController, LineElement, PointElement, LineController, Title, Tooltip, Legend);

export default function StatictisChart ({ clientIdInfo }) {
    const [sandboxesName, setSetSandboxesName] = useState();
    const [minutesUp, setMinutesUp] = useState([]);
    const [minutesDown, setMinutesDown] = useState([]);
    const [dataSetUsageChart, setDataSetUsageChart] = useState([]);
    useEffect(() => {
        const listSandboxes = clientIdInfo.sandboxes.map(item => item.hostName.split('.')[0]);
        setSetSandboxesName(listSandboxes);
        clientIdInfo.sandboxes.map(item => setMinutesUp(prevState => [...prevState, item.usage.minutesUp]));
        clientIdInfo.sandboxes.map(item => setMinutesDown(prevState => [...prevState, item.usage.minutesDown]));
        clientIdInfo.sandboxes.map(item => setDataSetUsageChart(prevState => [...prevState, item.usageSandbox]));
    }, [clientIdInfo]);
    const renderStatus = (state) => {
        switch (state) {
            case 'stopped':
                return (
                    <i
                        className="fas fa-power-off status-offline-icon-danger"
                        title={TranslateHelper.getMessage('sandbox.status.stopped')}
                    ></i>
                );
            case 'failed':
                return (
                    <i
                        className="fas fa-times status-offline-icon-danger"
                        title={TranslateHelper.getMessage('sandbox.status.failed')}
                    ></i>
                );
            case 'new':
                return (
                    <i
                        className="fas fa-power-off status-offline-icon-warning"
                        title={TranslateHelper.getMessage('sandbox.status.new')}
                    ></i>
                );
            case 'creating':
                return (
                    <i
                        className="fas fa-power-off status-offline-icon-warning"
                        title={TranslateHelper.getMessage('sandbox.status.creating')}
                    ></i>
                );
            default:
                return (
                    <i
                        className="fas fa-power-off status-online-icon"
                        title={TranslateHelper.getMessage('sandbox.status.online')}
                    ></i>
                );
        }
    };

    const renderSandboxes = data => {
        return data.sandboxes?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.realm}</td>
                    <td>
                        <span
                            data-tip
                            data-for={'tooltip-url-sandbox' + item?.hostName.split('.')[0]}
                        >
                            {item?.hostName.split('.')[0]}
                        </span>
                        <ReactTooltip id={'tooltip-url-sandbox' + item?.hostName.split('.')[0]}>
                            <span>{item?.hostName}</span>
                        </ReactTooltip>
                    </td>
                    <td>{item?.createdBy}</td>
                    <td>{moment(item?.createdAt).format(TranslateHelper.getMessage('date.format'))}</td>
                    <td>{renderStatus(item?.state)}</td>
                    <td>{item?.usageSandbox}</td>
                </tr>
            );
        });
    };
    return (
        <>
            <div className='table-scrollX'>
                <table id="client-ids-list" className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th><FormattedMessage id="subscription.table.column.name.id" /></th>
                            <th><FormattedMessage id="subscription.table.column.name.realm" /></th>
                            <th><FormattedMessage id="subscription.table.column.name.Sandbox" /></th>
                            <th><FormattedMessage id="subscription.table.column.name.createdBy" /></th>
                            <th><FormattedMessage id="subscription.tablecolumn.name.createAt" /></th>
                            <th><FormattedMessage id="partner.table.column.name.status" /></th>
                            <th><FormattedMessage id="partner.table.column.name.usage" /></th>
                        </tr>
                    </thead>
                    <tbody>{renderSandboxes(clientIdInfo)}</tbody>
                </table>
            </div>
            <div className='row'>
                <div className='col-12 col-xl-6'>
                    <Bar
                        height={300}
                        redraw={true}
                        data = {{
                            labels: sandboxesName,
                            datasets: [
                                {
                                    id: 1,
                                    label: 'Minutes Up',
                                    data: minutesUp,
                                    backgroundColor: '#0000ff70'
                                },
                                {
                                    id: 2,
                                    label: 'Minutes Down',
                                    data: minutesDown,
                                    backgroundColor: '#ff0000a1'
                                }
                            ]
                        }}
                        options = {{
                            indexAxis: 'y',
                            elements: {
                                bar: {
                                    borderWidth: 2
                                }
                            },
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top'
                                },
                                title: {
                                    display: true,
                                    text: 'Sandboxes'
                                }
                            }
                        }}
                    />
                </div>
                {/* <div className='col-12 col-xl-6'>
                    <Line height={'300'}
                        redraw={true}
                        data = {{
                            labels: ['Jun', 'Jul', 'Aug'],
                            datasets: [
                                {
                                    id: 1,
                                    label: '',
                                    data: [1, 2, 3]
                                },
                                {
                                    id: 2,
                                    label: '',
                                    data: [2, 1, 4]
                                }
                            ]
                        }}
                        options = {{
                            elements: {
                                line: {
                                    borderWidth: 2
                                }
                            },
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top'
                                },
                                title: {
                                    display: true,
                                    text: 'Cost'
                                }
                            }
                        }}
                    />
                </div> */}
                <div className='col-12 col-xl-6'>
                    <Bar height={'300'}
                        redraw={true}
                        data = {{
                            labels: sandboxesName,
                            datasets: [
                                {
                                    id: 1,
                                    label: 'Sandbox Usage',
                                    data: dataSetUsageChart,
                                    backgroundColor: '#ff0000a1'
                                }
                            ]
                        }}
                        options = {{
                            elements: {
                                bar: {
                                    borderWidth: 2
                                }
                            },
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top'
                                },
                                title: {
                                    display: true,
                                    text: 'Usage'
                                }
                            }
                        }}
                    />
                </div>
                {/* <div className='col-12 col-xl-6'>
                    <Pie
                        redraw={true}
                        data = {{
                            labels: ['Active Sandboxes', 'Created Sandboxes', 'Deleted Sandboxes'],
                            datasets: [
                                {
                                    data: [clientIdInfo.realmUsage.activeSandboxes, clientIdInfo.realmUsage.createdSandboxes, clientIdInfo.realmUsage.deletedSandboxes],
                                    backgroundColor: [
                                        '#ff0000a1',
                                        '#0000ff70',
                                        'rgba(255, 206, 86, 0.2)'
                                    ]
                                }
                            ]
                        }}
                        options = {{
                            elements: {
                                pie: {
                                    borderWidth: 2
                                }
                            },
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top'
                                },
                                title: {
                                    display: true,
                                    text: 'Realm Usage'
                                }
                            }
                        }}
                    />
                </div> */}
            </div>
        </>
    );
}
