import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import StatisticSandbox from '../../common/components/sandbox/components/dashboard/StatisticSandbox';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const dashboardMenuLinks = [
    {
        name: 'menu.admin.name.dashboard',
        classNames: 'nav-item',
        to: '/dashboard',
        icon: (<DashboardIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.PARTNEROWNER, ROLES.PARTNERMEMBER, ROLES.PARTNERMANAGER],
        key: 1
    }
];

const routes = [
    {
        path: '/dashboard',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([
                PERMISSION_ACCESS.PARTNEROWNER_ACCESS,
                PERMISSION_ACCESS.PARTNERMANAGER_ACCESS,
                PERMISSION_ACCESS.PARTNERMEMBER_ACCESS,
                PERMISSION_ACCESS.CLIENTID_ACTIVED
            ], <StatisticSandbox match={ match } />);
        }
    }
];

export default routes;
