import React, { useCallback, useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { ROLES } from '../../../../constants/user.constant';
import { useForm } from 'react-hook-form';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Swal from 'sweetalert2';
import ReactPagination from '../../../ReactPagination';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';
import rankService from './services/rankService';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

function Rank ({ role }) {
    const [ranks, setRanks] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [rankId, setRankId] = useState('');
    const customSwal = Swal.mixin({
        customClass: {
            cancelButton: 'btn btn-outline-danger',
            confirmButton: 'btn btn-primary ml-2'
        },
        buttonsStyling: false
    });
    const hideModalButtonRef = useRef();

    const fetchRank = useCallback(async (page = 1) => {
        rankService.getRanks({
            page
        }).then((res) => {
            setRanks(res.data.data);
        });
    }, []);

    useEffect(() => {
        fetchRank();
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        clearErrors,
        setValue
    } = useForm({
        defaultValues: {
            name: '',
            description: ''
        },
        mode: 'onChange'
    });
    const onSubmit = (formData) => {
        const data = {
            name: formData.name,
            description: formData.description
        };
        handleCreateRank(data);
    };

    const handleCreateRank = async (formData) => {
        if (isEdit) {
            try {
                await rankService.updateRank(formData, rankId);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successed')
                });
                hideModalButtonRef.current?.click();
                return fetchRank();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            try {
                await rankService.createRank(formData);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                reset({ name: '', description: '' });
                hideModalButtonRef.current?.click();
                return fetchRank();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        }
    };

    const handleDelete = (code) => {
        customSwal
            .fire({
                title: TranslateHelper.getMessage('hrm.detail.organization.modal.confirm.delete'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: TranslateHelper.getMessage('button.name.common.cancel'),
                confirmButtonText: TranslateHelper.getMessage('button.name.common.yes'),
                reverseButtons: true
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await rankService.deleteRank(code);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                    return fetchRank();
                }
            });
    };

    const renderRankTable = (data) => {
        if (!data) {
            return (
                <tr>
                    <td align="center" colSpan={8}>
                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.noData" />
                    </td>
                </tr>
            );
        } else {
            return data.map((item) => {
                return (
                    <tr key={item?._id}>
                        <td>
                            <Tooltip placement="top" title={item?.name} className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>
                                {item?.name}
                            </Tooltip>
                        </td>
                        <td>
                            <Tooltip placement="top" title={item?.description} className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>
                                {item?.description}
                            </Tooltip>
                        </td>
                        <td>
                            {item?.created_by?.firstname} {item?.created_by?.lastname}
                        </td>
                        <td>
                            {moment(item?.created_date).format('DD/MM/YYYY')}
                        </td>
                        <td style={{ minWidth: '130px' }}>
                            {role === ROLES.MANAGER && (
                                <Button
                                    className="mr-2"
                                    data-toggle="modal"
                                    onClick={() => {
                                        reset();
                                        clearErrors();
                                        setIsEdit(true);
                                        setRankId(item?._id);
                                        setValue('name', item?.name);
                                        setValue('description', item?.description);
                                    }}
                                    data-target={'#edit-rank'}
                                >
                                    <i className="fa fa-edit"></i>
                                </Button>
                            )}
                            <Button
                                className={`${role !== ROLES.MANAGER && 'd-none'}`}
                                onClick={() => handleDelete(item?._id)}
                            >
                                <i className="far fa-trash-alt" aria-hidden="true"></i>
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
        <Button
                type="button"
                appearance='primary'
                className={`mb-3 ${role !== ROLES.MANAGER && 'd-none'}`}
                data-toggle="modal"
                data-target="#create-modal"
                onClick={() => { reset(); setIsEdit(false); }}
            >
                Thêm
            </Button>
            <div className="modal fade" id={!isEdit ? 'create-modal' : 'edit-rank'}>
                <div className="modal-dialog" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{!isEdit ? <FormattedMessage id="hrm.detail.organization.modal.rank.title.create" /> : <FormattedMessage id="hrm.detail.organization.modal.rank.title.edit" />}</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => clearErrors()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name">
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.name" />
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        className={
                                            errors.name ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('name', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        })}
                                        name="name"
                                        placeholder="Tên cấp bậc"
                                    />
                                    {errors.name && (
                                        <span className="error text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.description" />
                                    </label>
                                    <textarea
                                        className={
                                            errors.description ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('description')}
                                        id="description"
                                        placeholder="Mô tả"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-flex-end">
                                <Button
                                    type="button"
                                    ref={hideModalButtonRef}
                                    data-dismiss="modal"
                                    onClick={() => {
                                        clearErrors();
                                    }}
                                >
                                    <FormattedMessage id="button.name.common.close" />
                                </Button>
                                <Button
                                    type="submit"
                                    appearance='primary'
                                    disabled={ !isDirty }
                                >
                                    {isEdit ? <FormattedMessage id="button.name.common.update" /> : <FormattedMessage id="button.name.common.create" />}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-scrollX'>
                        <table id="business-units-list" className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.description" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.createdBy" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.createdAt" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.rank.table.column.action" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{renderRankTable(ranks?.items)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                ranks?.totalPages > 1 &&
                    <ReactPagination
                        totalPages={ranks?.totalPages}
                        handlePageChange={(e, page) => fetchRank(page)}
                        pageNumber={ranks?.page}
                    />
            }
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(Rank);
