import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import issueService from '../../../services/issue.service';
import Button from '@atlaskit/button';

export default function CategoryDetail ({ projectId, category, fetchCategories }) {
    const hideModalButtonRef = useRef();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const handleSave = useCallback(async (formData) => {
        const payload = {
            categoryName: formData.categoryName.trim()
        };
        if (payload.categoryName) {
            try {
                await issueService.editCategory(projectId, category._id, payload);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                hideModalButtonRef.current?.click();
                return fetchCategories();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            setError('categoryName');
        }
    }, [hideModalButtonRef, category._id]);

    useEffect(() => {
        setValue('categoryName', category.categoryName);
        return () => {
            clearErrors('categoryName');
        };
    }, [setValue, category, fetchCategories]);
    return (
        <div className="modal fade" id={'modal-category-' + category._id}>
            <div className="modal-dialog modal-sm">
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Category</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label htmlFor="inputCategory" className="col-sm-4 col-form-label text-nowrap"><FormattedMessage id="project.detail.settings.category.table.column.name.category" /></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name='categoryName'
                                        placeholder="Name of category ?"
                                        className={
                                            errors.categoryName
                                                ? 'is-invalid form-control'
                                                : 'form-control'
                                        }
                                        {...register('categoryName', { required: TranslateHelper.getMessage('validation.error.required'), maxLength: { value: 30, message: TranslateHelper.getMessage('validation.error.max30characters') } })}
                                    />
                                    {errors.categoryName && (
                                        <span className="error text-danger text-wrap">
                                            {errors.categoryName.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button type="button" data-dismiss="modal" ref={hideModalButtonRef}><FormattedMessage id="button.name.common.cancel" /></Button>
                            <Button type="submit" disabled={!isDirty || !isValid} appearance='primary'>{TranslateHelper.getMessage('button.name.common.save')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
