import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class NoConnection extends React.Component {
    componentDidMount () {
        const body = $('body');
        body.removeClass();
        body.addClass('hold-transition lockscreen');
        body.css({ 'min-height': '496.391px' });
    }

    componentWillUnmount () {
        const body = $('body');
        body.removeClass();
        body.css({ 'min-height': '' });
        body.addClass('hold-transition sidebar-mini layout-navbar-fixed');
        window.location.reload();
    }

    render () {
        const { isLoggedIn, connection } = this.props;
        if (connection && isLoggedIn) {
            return <Redirect to="/" />;
        }
        if (connection && !isLoggedIn) {
            return <Redirect to="/landing" />;
        }
        return (
            <div className="lockscreen-wrapper">
                <div className="lockscreen-logo">
                    <div className="login-logo">
                        <img className="profile-user-img img-fluid img-circle" src="/logo.png" alt="kimei logo"/>
                    </div>
                </div>
                <h1 className="text-center" style={{ color: 'dodgerblue', fontWeight: 'bold' }}><FormattedMessage id="no-connection.whoops"/></h1>
                <img className="" src="/images/no-connection.png" style={{ width: '100%' }} alt="no-connection"/>
                <div className="help-block text-center">
                    <FormattedMessage id="no-connection.info1"/><br/> <FormattedMessage id="no-connection.info2"/>
                </div>
                <div className="text-center">
                    <a href="https://www.facebook.com/KimeiGlobal" target="_blank" rel="noreferrer"><FormattedMessage id="no-connection.contact"/></a>
                    <p>Or</p>
                    <a href="/" className="btn bg-gradient-info btn-sm" style={{ borderRadius: '3rem', padding: '0.25rem 1.5rem' }}><FormattedMessage id="no-connection.tryAgain"/></a>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { isLoggedIn } = state.auth;
    return {
        isLoggedIn
    };
}

export default connect(mapStateToProps)(NoConnection);
