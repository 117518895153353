import React, { useState } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import deviceService from '../../services/device.service';
import { useService } from '../../../../helpers/serviceHelpers';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeviceHistoryModal from './DeviceHistoryModal';
function HistoryTable ({ headers, status, deviceId, actions }) {
    const { data } = useService(() => deviceService.getDeviceHistoryByStatus(deviceId, { status }));
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(118 55 255 / 10%)',
            color: '#444CE7',
            fontSize: 14
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '& th, & td': {
            borderBottom: '1px solid rgb(118 55 255 / 10%)'
        }
    }));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [detail, setDetail] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer className='history-table' component={Paper}>
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {headers.map((item, i) => (
                                <StyledTableCell key={i} align="center">{item}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === 1 &&
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                    const action = actions.find((act) => act.value === item.old_status);
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">{item.created_date ? moment.utc(item.created_date).format('DD/MM/YYYY HH:mm') : ''}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.updated_date ? moment.utc(item.updated_date).format('DD/MM/YYYY HH:mm') : 'Hiện tại'}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{action?.label}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        {status === 2 &&
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                    const action = actions.find((act) => act.value === item.old_status);
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">{item.assign_person?.username}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.update_by?.username}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.created_date ? moment.utc(item.created_date).format('DD/MM/YYYY HH:mm') : ''}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.updated_date ? moment.utc(item.updated_date).format('DD/MM/YYYY HH:mm') : 'Hiện tại'}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{action?.label}</StyledTableCell>
                                            <StyledTableCell>
                                                <button className='visible-btn' onClick={() => { setIsModalOpen(true); setDetail(item); }}>
                                                    <VisibilityIcon className='visible' />
                                                </button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        {status === 3 &&
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                    const action = actions.find((act) => act.value === item.old_status);
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">{item.warranty_company}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.created_date ? moment.utc(item.created_date).format('DD/MM/YYYY HH:mm') : ''}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{item.updated_date ? moment.utc(item.updated_date).format('DD/MM/YYYY HH:mm') : 'Hiện tại'}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center">{action?.label}</StyledTableCell>
                                            <StyledTableCell>
                                                <button className='visible-btn' onClick={() => { setIsModalOpen(true); setDetail(item); }}>
                                                    <VisibilityIcon className='visible' />
                                                </button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length > 0 && <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
            <DeviceHistoryModal status={status} detail={detail} open={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </>
    );
}

export default HistoryTable;
