import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';

class IssueService {
    constructor () {
        serviceHelpers();
    }

    getProjectMileStone (id) {
        return axiosService.get(API_URL + id + '/milestones');
    }

    getProjectStatus (id) {
        return axiosService.get(API_URL + id + '/status');
    }

    getProjectCategory (id) {
        return axiosService.get(API_URL + id + '/categories');
    }

    getProjectTag (id) {
        return axiosService.get(API_URL + id + '/tags');
    }

    getProjectIssueTypes (id) {
        return axiosService.get(API_URL + id + '/issueTypes');
    }

    getProjectIssue (id, limit = 999) {
        return axiosService.get(API_URL + id + '/issues', { params: { limit } }).then(response => response.data.data);
    }

    getAllIssues (projectId, params, limit = 9999, page = 1) {
        return axiosService.get(API_URL + projectId + '/issues', { params: { ...params, limit, page } }).then(response => response.data.data);
    }

    createIssue (id, payload) {
        return axiosService.post(API_URL + id + '/issues', payload);
    }

    updateIssue (projectId, id, payload) {
        return axiosService.patch(API_URL + projectId + '/issues/' + id, payload).then(response => response.data.data);
    }

    getIssueDetail (id) {
        return axiosService.get(API_URL + 'issues/' + id).then(response => response.data.data);
    }

    getIssueHistory (issueCode, limit = 50) {
        return axiosService.get(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/activities/issue/' + issueCode, { params: { limit } }).then(res => res.data.data);
    }

    getIssueTypes (projectId) {
        const endpoint = `${API_URL}${projectId}/issueTypes`;
        return axiosService.get(endpoint).then(response => response.data.data);
    }

    getProjectMember (id) {
        return axiosService.get(API_URL + id + '/members');
    }

    createIssueType (projectId, data) {
        const endpoint = `${API_URL}${projectId}/issueTypes`;
        return axiosService.post(endpoint, data).then(response => response.data.data);
    }

    deleteIssueType (projectId, id) {
        const endpoint = `${API_URL}${projectId}/issueTypes/${id}`;
        return axiosService.delete(endpoint).then(response => response.data.data);
    }

    editIssueType (projectId, id, payload) {
        const endpoint = `${API_URL}${projectId}/issueTypes/${id}`;
        return axiosService.patch(endpoint, payload).then(res => res.data.data);
    }

    createCategory (projectId, data) {
        const endpoint = `${API_URL}${projectId}/categories`;
        return axiosService.post(endpoint, data).then(res => res.data.data);
    }

    createTag (projectId, data) {
        const endpoint = `${API_URL}${projectId}/tags`;
        return axiosService.post(endpoint, data).then(res => res.data.data);
    }

    deleteCategory (projectId, id) {
        const endpoint = `${API_URL}${projectId}/categories/${id}`;
        return axiosService.delete(endpoint).then(res => res.data.data);
    }

    deleteTag (projectId, id) {
        const endpoint = `${API_URL}${projectId}/tags/${id}`;
        return axiosService.delete(endpoint).then(res => res.data.data);
    }

    editCategory (projectId, id, payload) {
        const endpoint = `${API_URL}${projectId}/categories/${id}`;
        return axiosService.patch(endpoint, payload).then(res => res.data.data);
    }

    editTag (projectId, id, payload) {
        const endpoint = `${API_URL}${projectId}/tags/${id}`;
        return axiosService.patch(endpoint, payload).then(res => res.data.data);
    }

    createMilestone (id, payload) {
        const endpoint = `${API_URL}projects/${id}/milestones`;
        return axiosService.post(endpoint, payload).then(res => res.data.data);
    }

    getIssuesMilestone (id) {
        const endpoint = `${API_URL}projects/milestones/${id}/issues`;
        return axiosService.get(endpoint).then(res => res);
    }

    editMilestone (projectId, id, payload) {
        const endpoint = `${API_URL}${projectId}/milestones/${id}`;
        return axiosService.patch(endpoint, payload);
    }

    addComment (id, payload) {
        return axiosService.post(API_URL + 'issues/' + id + '/comment', payload);
    }

    getComments ({ id, ...params }) {
        return axiosService.get(API_URL + 'issues/' + id + '/comment', { params });
    }

    updateComment (id, payload) {
        return axiosService.patch(API_URL + 'issues/comments/' + id, payload);
    }

    deleteComment (id) {
        return axiosService.delete(API_URL + 'issues/comments/' + id);
    }

    getIssueByFilter (id, filter) {
        return axiosService.get(API_URL + id + '/issues', { params: filter });
    }

    markDownHTML (comment) {
        const regx = /(<.+?)(?:\s)on[a-z]+\s*=\s*(?:(['"])(?!\2).+?\2|(?:\S+?\(.*?\)(?=[\s>])))(.*?>)/gi;
        if (regx.test(comment)) {
            return '<span style="text-decoration: line-through;">Content has been hidden</span>';
        } else {
            return comment;
        }
    }

    exportExcel (projectId, params) {
        return axiosService.get(API_URL + projectId + '/issues/export', { params, responseType: 'blob' });
    }
}

export default new IssueService();
