import serviceHelpers from '../../../helpers/serviceHelpers';
import AxiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects';

class BoadrdService {
    constructor () {
        serviceHelpers();
    }

    getBoardProject (projectId, params) {
        const endpoint = `${API_URL}/${projectId}/board`;
        return AxiosService.get(endpoint, { params }).then(response => response.data.data);
    }

    updateIssueStatus (projectId, id, payload) {
        const endpoint = `${API_URL}/${projectId}/issues/${id}`;
        return AxiosService.patch(endpoint, payload);
    }
}

export default new BoadrdService();
