export const PROJECT_ROLE = {
    MANAGER: 1,
    DEVELOPER: 2,
    REPORTER: 3,
    GUEST: 4
};

export const ISSUE_PRIORITY = {
    HIGH: 3,
    MEDIUM: 2,
    LOW: 1
};

export const PROJECT_STATUS = {
    IN_PROGRESS: '1',
    HOLDING: '2',
    COMPLETED: '3'
};

export const REACTION_NUMBER = {
    LIKE: 1,
    HEART: 2,
    FIRE: 3,
    DISLIKE: 4,
    ANGRY: 5
};

export const EMOTIONS_NAME = ['LIKE', 'HEART', 'FIRE', 'DISLIKE', 'ANGRY'];

export const PROJECT_INFO = {
    SET_PROJECT_INFO: 'SET_PROJECT_INFO',
    SET_ROLE_IN_PROJECT_INFO: 'SET_ROLE_IN_PROJECT_INFO'
};

export const MILESTONE_NAME = {
    BACKLOG: 'Backlog'
};
