import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../../actions/breadcrumb.action';
import RealmService from '../../services/realm.service';
import Button from '@atlaskit/button';

class RealmList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            inputRealm: '',
            dataSet: [],
            isError: {
                realm: ''
            },
            form: {
                realm: ''
            }
        };
        this.handleSearchRealm = this.handleSearchRealm.bind(this);
        this.formValChange = this.formValChange.bind(this);
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.realm');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.member.name.sandbox.realm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.sandbox.sandboxes',
                    haslink: false,
                    path: ''
                },
                {
                    label: 'menu.member.name.sandbox.realm',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        const queryString = '?clientId=' + this.props.actived;
        RealmService.getAll(queryString).then((res) => {
            if (res.status === 200) {
                this.setState({ dataSet: res.data.data.items });
            } else {
                let timerInterval;
                Swal.fire({
                    title: TranslateHelper.getMessage('respond.message.operationsandbox.refresh'),
                    html: TranslateHelper.getMessage('respond.message.operationsandbox.refreshcountdown'),
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                        window.location.reload();
                    }
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    /**
     * Clear data component
     */
    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    handleSearchRealm () {
        if (this.formValidation()) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                    const queryString = '?clientId=' + this.props.actived;
                    RealmService.searchRealm(this.state.form.realm, queryString).then((res) => {
                        this.setState({ dataSet: [res.data.data] });
                        Swal.close();
                    }).catch((error) => {
                        if (error.response.data) {
                            const data = error.response.data;
                            this.setState({ isError: { realm: data.message } });
                        }
                        Swal.close();
                    });
                }
            });
        }
        this.forceUpdate();
    }

    handleKeyDown (e) {
        if (e.key === 'Enter') {
            this.handleSearchRealm();
        }
    }

    formValidation () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'realm':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    formValChange (name, event) {
        const form = { ...this.state.form };
        const isError = { ...this.state.isError };
        form[name] = event.target.value.trim();
        switch (name) {
            case 'realm':
                isError.realm = '';
                break;
            default:
                break;
        }
        this.setState({
            isError,
            form
        });
    }

    render () {
        const { match } = this.props;
        const { isError, dataSet } = this.state;
        const rowData = dataSet.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item.id}</td>
                    <td>{item.activeSandboxes}</td>
                    <td>{item.createdSandboxes}</td>
                    <td>{item.deletedSandboxes}</td>
                    <td>
                        <Link to={`${match.url}/${item.id}`} >
                            <Button type="button"><FormattedMessage id="button.name.common.detail" /></Button>
                        </Link>
                    </td>
                </tr>
            );
        });
        return (
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title"><FormattedMessage id="realm.table.title" /></h3>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="col-form-label" ><FormattedMessage id="realm.table.realm" /> </label>
                                <div className="input-group input-group-sm">
                                    <input
                                        onChange={this.formValChange.bind(this, 'realm')}
                                        type="text"
                                        className={isError.realm.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                        name="realm"
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <span className="input-group-append">
                                        <Button onClick={() => { this.handleSearchRealm(); }} type="button" appearance='primary'><FormattedMessage id="button.name.common.show" />!</Button>
                                    </span>
                                </div>
                                <div className={isError.realm.length > 0 ? 'is-invalid' : ''}></div>
                                {isError.realm.length > 0 && (<span className="error invalid-feedback">{isError.realm}</span>)}
                            </div>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>
                        <div style={{ height: '15px' }}></div>
                        {dataSet.length
                            ? (<div className="card">
                                <div className="card-body p-0">
                                    <div className='table-scrollX'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: 10 }}>#</th>
                                                    <th><FormattedMessage id="realm.table.column.name.name" /></th>
                                                    <th><FormattedMessage id="realm.table.column.name.activesandboxes" /></th>
                                                    <th><FormattedMessage id="realm.table.column.name.createdSeconds" /></th>
                                                    <th><FormattedMessage id="realm.table.column.name.deletedSandboxes" /></th>
                                                    <th><FormattedMessage id="realm.table.column.name.action" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>{rowData}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)
                            : (<></>)}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { actived } = state.memberClientId;
    return { actived };
}

export default connect(mapStateToProps)(RealmList);
