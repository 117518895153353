import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MasterLayout from './containers/MasterLayout';
import NoConnection from './containers/NoConnection';
import { logout } from './actions/auth.action';
import EventBus from './helpers/EventBus';
import './App.css';
import AxiosService from './services/axios.service';
import Helmet from 'react-helmet';
import TranslateHelper from './helpers/TranslateHelper';
import LandingPage from '../landing/LandingPage';
import Cookies from 'js-cookie';
import NotFound from './components/common/NotFound';
import tokenService from './components/auth/token.service';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { setKimeiMembers } from './actions/hrm.action';
import hrService from './components/hrm/Services/hr.service';

class App extends Component {
    constructor (props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.checkConnetiton = this.checkConnetiton.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
            isLoggedIn: false,
            callAPIDone: false
        };
    }

    componentDidMount () {
        const { user, isLoggedIn } = this.props;
        hrService.getEmployees({ limit: 9999 }).then(res => {
            this.props.dispatch(setKimeiMembers(res.items));
        });
        this.checkConnetiton();
        if (user) {
            this.setState({
                currentUser: user,
                isLoggedIn
            });
        }
        // check expire token
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            if (tokenService.checkExpired(accessToken)) {
                this.logOut();
            }
        } else {
            this.logOut();
        }
        EventBus.on('logout', () => {
            this.logOut();
        });
    }

    componentWillUnmount () {
        EventBus.remove('logout');
    }

    logOut () {
        this.props.dispatch(logout());
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined
        });
    }

    checkConnetiton () {
        const user = JSON.parse(localStorage.getItem('user'));
        const url = user ? process.env.REACT_APP_API_URL + '?tracking=' + user.userId : process.env.REACT_APP_API_URL;
        AxiosService.get(url).then(res => {
            if (res.status === 200) {
                this.setState({ connection: true });
            } else {
                this.setState({ connection: false });
            }
            this.setState({ callAPIDone: true });
        }, err => {
            if (err) {
                this.setState({ connection: false });
                this.setState({ callAPIDone: true });
            }
        });
    }

    render () {
        if (!this.state.callAPIDone) {
            return null;
        }
        return (
            <>
                <Helmet>
                    <meta name='description' content={TranslateHelper.getMessage('app.page.meta.description')}/>
                </Helmet>
                <Router>
                    <Switch>
                        <LandingPage path="/landing" component={LandingPage} connection={this.state.connection}/>
                        <NoConnection path="/no-connection" component={NoConnection} connection={this.state.connection} />
                        <Route path='/not-found' component={NotFound} />
                        <MasterLayout path="/" component={MasterLayout} connection={this.state.connection}/>
                    </Switch>
                </Router>
            </>
        );
    }
}

function mapStateToProps (state) {
    const { user, isLoggedIn } = state.auth;
    return {
        user, isLoggedIn
    };
}

export default connect(mapStateToProps)(App);
