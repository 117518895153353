import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DeviceList from './DeviceList';
import DeviceDetail from './device-details/DeviceDetail';

export default function DeviceManagement () {
    return (
        <Switch>
            <Route exact path="/device" component={DeviceList} />
            <Route exact path="/device/:deviceId" component={DeviceDetail} />
        </Switch>
    );
}
