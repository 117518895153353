import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../constants/user.constant';
import RouterMiddleware from '../../middlewares/RouterMiddleware';
import ProjectManagement from './components/ProjectManagement';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export const projectMenuLinks = [
    {
        name: 'menu.manager.name.project',
        classNames: 'nav-item',
        to: '/projects',
        icon: (<AccountTreeIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: false,
        role: [ROLES.MANAGER, ROLES.MEMBER, ROLES.GUEST, ROLES.PARTNEROWNER, ROLES.PARTNERMEMBER, ROLES.PARTNERMANAGER],
        key: 9
    }
];

const routes = [
    {
        path: '/projects',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS, PERMISSION_ACCESS.MEMBER_ACCESS, PERMISSION_ACCESS.GUEST_ACCESS, PERMISSION_ACCESS.PARTNEROWNER_ACCESS, PERMISSION_ACCESS.PARTNERMEMBER_ACCESS, PERMISSION_ACCESS.PARTNERMANAGER_ACCESS], <ProjectManagement match={match}/>);
        }
    }
];

export default routes;
