import AxiosService from '../../../services/axios.service';
import { Buffer } from 'buffer';
import * as qs from 'qs';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;
const API_OWNER_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnerowner';
const API_MEMBER_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnermember/sandboxes';

class ClientId {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
    }

    getList () {
        return AxiosService.get(API_OWNER_URL + '/client-ids');
    }

    getListActive () {
        return AxiosService.get(API_OWNER_URL + '/client-ids?status=enable');
    }

    getPartnerInfo () {
        return AxiosService.get(API_URL + '/users');
    }

    createClientId (payload) {
        return AxiosService.post(API_OWNER_URL + '/client-ids', payload);
    }

    authorizeClientId (clientId, clientSecret) {
        const encodedToken = Buffer.from(`${clientId}:${clientSecret}`).toString('base64');
        AxiosService.setHeader('authorization', 'Basic ' + encodedToken);
        return AxiosService.post(API_MEMBER_URL + '/authorization', qs.stringify({ grant_type: 'client_credentials' }));
    }

    updateClientId (clientId, payload) {
        return AxiosService.patch(API_OWNER_URL + '/client-ids/' + clientId, payload);
    }

    deleteClientId (clientId) {
        return AxiosService.delete(API_OWNER_URL + '/client-ids/' + clientId);
    }

    getClientId (clientId) {
        return AxiosService.get(API_OWNER_URL + '/client-ids/' + clientId);
    }

    assignMemberToClientIds (clientId, payload) {
        return AxiosService.post(API_OWNER_URL + '/client-ids/' + clientId + '/assignMember', payload);
    }

    unassignMemberToClientIds (clientId, payload) {
        return AxiosService.delete(API_OWNER_URL + '/client-ids/' + clientId + '/unassignMember', payload);
    }
}

export default new ClientId();
