import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { guestMenuLinks } from './routes';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TranslateHelper from '../common/helpers/TranslateHelper';

class AsidebarManager extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            selectedIndex: 0
        };
        this.showMenus = this.showMenus.bind(this);
    }

    handleClick () {
        this.setState({ open: !this.state.open });
    }

    handleListItemClick (event, index) {
        this.setState(prevState => ({ ...prevState, selectedIndex: index }));
    }

    showMenus (menus) {
        let result = null;
        if (menus.length > 0) {
            result = menus.map((menu, index) => {
                if (menu.role?.indexOf(this.props.role) !== -1) {
                    if (menu.children) {
                        return (
                            <Route
                                key={index}
                                path={menu.to}
                                exact={menu.exact}
                                children={({ match }) => {
                                    return (
                                        <>
                                            <ListItemButton onClick={() => this.handleClick()} className={`${menu.classNames} nav-link-modify`}>
                                                <ListItemIcon>
                                                        {menu.icon}
                                                    </ListItemIcon>
                                                <ListItemText primary={TranslateHelper.getMessage(menu.name)} />
                                                {this.state.open ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={this.state.open} timeout='auto' unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {menu.children ? this.showMenus(menu.children) : null}
                                                </List>
                                            </Collapse>
                                        </>
                                    );
                                }}
                            />
                        );
                    } else {
                        return (
                            <Route
                                key={index}
                                path={menu.to}
                                exact={menu.exact}
                                children = { ({ match }) => {
                                    const active = match ? 'active' : '';
                                    return (
                                        <>
                                            <Link to={menu.to} className={`nav-link-modify ${active}`}>
                                                <ListItemButton selected={active} onClick={e => this.handleListItemClick(e, menu.key)} className={`${menu.classNames} nav-item-modify`}>
                                                    <ListItemIcon>
                                                        {menu.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={TranslateHelper.getMessage(menu.name)} />
                                                </ListItemButton>
                                            </Link>
                                        </>
                                    );
                                }}
                            />
                        );
                    }
                }
                return '';
            });
        }
        return result;
    }

    render () {
        return (
            <List
                sx={{ width: '100%', maxWidth: 360 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                {this.showMenus(guestMenuLinks)}
            </List>
        );
    }
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(AsidebarManager);
