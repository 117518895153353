import React, { Component } from 'react';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { LanguageDatatables } from '../../../../../../../lang';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import SandboxService from '../../../services/sandbox.service';
import RightSidebar from '../../../../common/RightSidebar';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import Button from '@atlaskit/button';

class AliasSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSet: null,
            isError: {
                name: '',
                unique: ''
            },
            form: {
                name: '',
                unique: true
            }
        };
    }

    /**
     * Load API
     */
    componentDidMount () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            $('#alias-list').DataTable({
                paging: false,
                lengthChange: false,
                searching: true,
                ordering: true,
                info: false,
                autoWidth: true,
                responsive: true,
                language: LanguageDatatables[lang]
                // columns: [null, null, {width: "10%"}]
            });
        });
        SandboxService.getSandboxAliases(this.props.sandboxId).then((res) => {
            this.setState({ dataSet: res.data.data, loadingStatus: true });
        });
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '450px' });
        }
    }

    onClickCreateAlias () {
        this.openSidebar();
    }

    handleRemove (id) {
        ToastTopEnd.fire({
            icon: 'error',
            title: TranslateHelper.getMessage('respond.message.failed')
        });
    }

    handleCreateAlias () {
        if (this.handleValidationForm()) {
            ToastTopEnd.fire({
                icon: 'error',
                title: TranslateHelper.getMessage('respond.message.failed')
            });
        }
        $('.sidebar-right').css({ width: '0' });
        this.forceUpdate();
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'name':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'name': case 'unique':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    renderRowTable (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item.name}</td>
                    <td style={{ textAlign: 'center' }}>
                        <Button type="button" onClick={() => { this.handleRemove(item.id); }}><i className="far fa-trash-alt"></i></Button>
                    </td>
                </tr>
            );
        });
    }

    render () {
        const { isError } = this.state;
        return (
            <div>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}><h4><FormattedMessage id="sandbox.detail.tab.alias"/></h4></div>
                <div className="row">
                    <div className="col-sm-12">
                        <Button type="button" onClick={() => { this.onClickCreateAlias(); }} appearance='primary'><i className="far fa-plus-square"></i>  <FormattedMessage id="button.name.common.create"/></Button>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className="row">
                    <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><FormattedMessage id="sandbox.detail.tab.alias"/></h3>
                        </div>
                        <div className="card-body p-0">
                            {this.state.loadingStatus
                                ? (<div>
                                    {this.state.dataSet.length
                                        ? (<table id="alias-list" className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th><FormattedMessage id="sandbox.detail.alias.id"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.alias.name"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.alias.action"/></th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.renderRowTable(this.state.dataSet)}</tbody>
                                        </table>)
                                        : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                </div>)
                                : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                        </div>
                    </div>
                    </div>
                </div>
                <RightSidebar title={TranslateHelper.getMessage('sandbox.detail.alias.form.title')}>
                    <div className="form-group">
                        <label><FormattedMessage id="sandbox.detail.alias.form.name"/><span style={{ color: 'red' }}>*</span></label>
                        <input
                            value={this.state.form.name}
                            type="text"
                            name="name"
                            onChange={this.handleInputChange.bind(this, 'name')}
                            className={isError.name.length > 0 ? 'is-invalid form-control' : 'form-control'}
                        />
                        {isError.name.length > 0 && (<span className="error invalid-feedback">{isError.name}</span>)}
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label className="label-400"><FormattedMessage id="sandbox.detail.alias.form.unique"/></label>
                            <select name="unique" className="form-control" value={this.state.form.unique} onChange={this.handleInputChange.bind(this, 'unique')}>
                                <option value="true">{TranslateHelper.getMessage('option.common.yes')}</option>
                                <option value="false">{TranslateHelper.getMessage('option.common.no')}</option>
                            </select>
                        </div>
                    </div>
                    <Button type="button" appearance='primary' onClick={() => { this.handleCreateAlias(); }}><FormattedMessage id="button.name.common.create"/></Button>
                </RightSidebar>
            </div>
        );
    }
}

export default AliasSbDetail;
