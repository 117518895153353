import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Tooltip } from '@mui/material';
import Editor from '../../../EdittorToolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import wikiService from '../../services/wiki.service';
import Avatar from '@atlaskit/avatar';
import Comment, { CommentAuthor, CommentTime } from '@atlaskit/comment';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import moment from 'moment';
import issueService from '../../services/issue.service';

export default function Wiki ({ projectId }) {
    const [elmHeight, setElmHeight] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        setElmHeight(ref.current?.clientHeight);
    });
    const [isEdit, setEdit] = useState(false);
    const [content, setContent] = useState('');
    const [contentDisplay, setContentDisplay] = useState('');
    const [author, setAuthor] = useState({});

    const fetchWikiContent = useCallback(() => {
        wikiService.getWikiPage(projectId).then(res => {
            setContentDisplay(res);
            setAuthor(res?.createdBy);
        });
    }, [projectId]);

    useEffect(fetchWikiContent, [fetchWikiContent]);

    const handleChangeStateEditor = (isCreate) => {
        if (isEdit && content) {
            if (isCreate) {
                wikiService.createWikiPage(projectId, { wikiContent: content });
                fetchWikiContent();
            } else {
                wikiService.editWikiPage(contentDisplay._id, { wikiContent: content });
            }
            setContentDisplay({ content: content || contentDisplay.content });
        }
        setEdit(!isEdit);
    };
    return (
        <>
            <div className="container-fluid pb-3">
                <div className="card">
                    <Box sx={{
                        m: 1,
                        position: 'sticky',
                        top: '70px',
                        zIndex: 99,
                        marginRight: '20px'
                    }} className='d-flex justify-content-end'>
                        <Tooltip title={ isEdit ? 'Done' : 'Edit'}>
                            <Fab color="primary" aria-label="edit" onClick={() => handleChangeStateEditor(!contentDisplay)}>
                                { isEdit ? <CheckIcon/> : <EditIcon />}
                            </Fab>
                        </Tooltip>
                    </Box>
                    <Divider/>
                    <div className="card-body">
                        <div className="card-text">
                            { isEdit
                                ? (
                                    <Editor
                                        toolbarId={'editCommentEditor'}
                                        placeholder={'Write wiki of project here!'}
                                        onChange={setContent}
                                        defaultValue={contentDisplay?.content}
                                        height={elmHeight}
                                    />
                                    )
                                : (
                                        <div ref={ref} dangerouslySetInnerHTML={{ __html: issueService.markDownHTML(contentDisplay?.content) }}/>
                                    ) }
                        </div>
                    </div>
                </div>
                { author &&
                (
                    <div className='card'>
                        <div className='card-body'>
                            <Comment
                                avatar={<Avatar src={author?.picture ? (String(author?.picture).includes('uploads') ? recruitmentService.buildFileURL(author?.picture) : author?.picture) : '/images/default-avatar.png'} />}
                                author={<CommentAuthor>{author?.firstname} {author?.lastname}</CommentAuthor>}
                                time={<CommentTime>{moment(author?.createdAt || Date.now()).format('LLL')}</CommentTime>}
                                content={'Registered '}
                            />
                        </div>
                    </div>
                ) }
            </div>
        </>
    );
}
