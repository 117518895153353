import { useService } from '../../../../helpers/serviceHelpers';
import hrService from '../../Services/hr.service';
import EmployeeCard from '../Hr-detail/EmployeeCard';

export default function MyTeam () {
    const { data: myTeamMembers } = useService(hrService.getMyteam);

    return (
        <div className=" row">
            {myTeamMembers.items?.map((employee, index) => {
                return (
                <div key={index} className="col-12 col-md-6 col-xl-3 pr- pb-3">
                    <EmployeeCard data={employee} isShowEdit={false}/>
                </div>
                );
            })}
        </div>
    );
}
