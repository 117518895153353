import { useForm } from 'react-hook-form';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { clearBreadcrumb, setBreadcrumb } from '../../../../common/actions/breadcrumb.action';
import UserService from '../../../../common/services/auth.service';
import { ToastTopEnd } from '../../../../common/helpers/ToastTimer';
import Button from '@atlaskit/button';

function Integration ({ dispatch, user }) {
    const { register, handleSubmit, setValue, formState: { errors, isDirty } } = useForm({
        mode: 'onChange'
    });

    const getSettingIntegration = useCallback(() => {
        UserService.getSettingInfo().then(res => {
            setValue('slackToken', res.data.data.settingsDefault['slack-token']);
            setValue('slackChannel', res.data.data.settingsDefault['slack-channel']);
        });
    }, []);

    useEffect(getSettingIntegration, [getSettingIntegration]);

    const saveIntegration = (formData) => {
        const payload = {
            slackToken: formData.slackToken.trim(),
            slackChannel: formData.slackChannel.trim(),
            wfhChannel: formData.wfhChannel.trim(),
            firstname: user.firstname,
            lastname: user.lastname
        };
        UserService.saveSetting(payload).then(res => {
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
        });
    };

    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.setting',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.setting',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            clearBreadcrumb();
        };
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(saveIntegration)}>
                <div className="row">
                    <div className='col-sm-12'>
                        <div className="card card-warning card-outline">
                            <a className="d-block w-100">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                    <FormattedMessage id="account.form.label.integration"/>
                                    </h4>
                                </div>
                            </a>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="inputSlackId" className="col-xl-2 col-12 col-form-label ">{TranslateHelper.getMessage('account.integration.label.input.slackToken')}</label>
                                    <div className="col-xl-6 col-12 pl-3">
                                        <input
                                            className={errors.slackToken ? 'is-invalid form-control  p-2' : 'form-control'}
                                            {...register('slackToken', {
                                                required: TranslateHelper.getMessage('validation.error.required')
                                            })}
                                        />
                                        {errors.slackToken && <span className='error text-danger'>{errors.slackToken.message}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputSlackId" className="col-xl-2 col-12 col-form-label ">{TranslateHelper.getMessage('account.integration.label.input.attendanceChannelID')}</label>
                                    <div className="col-xl-6 col-12 pl-3">
                                        <input
                                            className={errors.slackChannel ? 'is-invalid form-control  p-2' : 'form-control'}
                                            {...register('slackChannel', {
                                                required: TranslateHelper.getMessage('validation.error.required')
                                            })}
                                        />
                                        {errors.slackChannel && <span className='error text-danger'>{errors.slackChannel.message}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputSlackId" className="col-xl-2 col-12 col-form-label ">{TranslateHelper.getMessage('account.integration.label.input.wfhAttendanceChannelID')}</label>
                                    <div className="col-xl-6 col-12 pl-3">
                                        <input
                                            className={errors.wfhChannel ? 'is-invalid form-control  p-2' : 'form-control'}
                                            {...register('wfhChannel', {
                                                required: TranslateHelper.getMessage('validation.error.required')
                                            })}
                                        />
                                        {errors.wfhChannel && <span className='error text-danger'>{errors.wfhChannel.message}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button type="submit" className='justify-content-center col-xl-2 col-4' appearance='primary' isDisabled={ !isDirty }><FormattedMessage id='button.name.common.save' /></Button>
            </form>
        </>
    );
}

function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(Integration);
