import AxiosService from '../../../services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnermember/sandboxes';

class RealmService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
        AxiosService.setHeader('authorization', 'Bearer ' + Cookies.get(Cookies.get('actived')));
    }

    getAll (quetyString = '') {
        return AxiosService.get(API_URL + '/realms' + quetyString);
    }

    searchRealm (realm, quetyString = '') {
        return AxiosService.get(API_URL + '/realms/' + realm + '/search' + quetyString);
    }

    getRealms (realm) {
        return AxiosService.get(API_URL + '/realms/' + realm);
    }

    getRealmConfiguration (realm) {
        return AxiosService.get(API_URL + '/realms/' + realm + '/configuration');
    }

    setRealmConfiguration (realm, payload) {
        return AxiosService.patch(API_URL + '/realms/' + realm + '/configuration', payload);
    }

    getRealmUsage (realm, queryString = '') {
        return AxiosService.get(API_URL + '/realms/' + realm + '/usage' + queryString);
    }
}

export default new RealmService();
