import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';

class ProjectOverview {
    constructor () {
        serviceHelpers();
    }

    getIssueTracking (projectId, page = 1) {
        return axiosService.get(API_URL + projectId + '/overview', { params: { page } });
    }

    getProjectMember (projectId, page = 1) {
        return axiosService.get(API_URL + projectId + '/members', { params: { page } });
    }
}

export default new ProjectOverview();
