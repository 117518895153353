import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class RecruitmentService {
    constructor () {
        serviceHelpers();
        this.formatInterviews = [
            { value: 1, label: 'Online' },
            { value: 2, label: 'Offline' }
        ];

        // lay tu API
        this.forTeams = [
            { value: '1', label: 'SFCC' },
            { value: '2', label: 'PHP' },
            { value: '3', label: 'BOD' },
            { value: '4', label: 'IT Co' }
        ];
        this.titles = [
            { value: 1, label: 'Fresher' },
            { value: 2, label: 'Junior' },
            { value: 3, label: 'Middle' },
            { value: 4, label: 'Senior' }
        ];
        this.gender = [
            { value: 1, label: 'Male' },
            { value: 2, label: 'Female' }
        ];
        this.statues = [
            { value: 5, label: 'Called', color: '#27ae60' },
            { value: 6, label: 'Qualified', color: '#2ecc71' },
            { value: 7, label: 'Not Qualified', color: '#1abc9c' },
            { value: 1, label: 'Pending', color: '#e74c3c' },
            { value: 2, label: 'Passed', color: '#3498db' },
            { value: 3, label: 'Failed', color: '#e84118' },
            { value: 5, label: 'Holding', color: '#d35400' },
            { value: 6, label: 'Screening', color: '#4cd137' },
            { value: 7, label: 'Scheduling', color: '#44bd32' },
            { value: 8, label: 'Interview', color: '#fbc531' },
            { value: 9, label: 'Withdraw', color: '#00a8ff' },
            { value: 10, label: 'Offered', color: '#0097e6' },
            { value: 11, label: 'Accept Offer', color: '#2ecc71' },
            { value: 12, label: 'Reject Offer', color: '#e67e22' },
            { value: 13, label: 'Probation Passed', color: '#353b48' },
            { value: 14, label: 'Done', color: '#7f8fa6' }
        ];
        this.locationInterview = [
            { value: '1', label: 'Google Meet' },
            { value: '2', label: 'Room 1' },
            { value: '3', label: 'Room 2' }
        ];
    }

    createRecruitmentCV (payload) {
        return axiosService.post(API_URL + 'interview', payload, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then(res => res.data.data);
    }

    createRecruitmentInterview (payload) {
        return axiosService.patch(API_URL + 'interview', payload, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then(res => res.data.data);
    }

    getInterviews (params) {
        return axiosService.get(API_URL + 'interview', { params }).then(res => res.data.data);
    }

    updateInterview (payload) {
        return axiosService.patch(API_URL + 'interview', payload).then(res => res.data.data);
    }

    deleteInterviewById (id) {
        return axiosService.delete(API_URL + 'interview/' + id).then(res => res.data.data);
    }

    buildFileURL (path) {
        return process.env.REACT_APP_API_URL + '/' + path;
    }

    getAllProvinces () {
        return axiosService.get(API_URL + 'interview/information/provinces').then(res => res.data.data);
    }
}

export default new RecruitmentService();
