import { Dialog, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import CSelect from '../../../../shared/CSelect';
import { Controller } from 'react-hook-form';

function ModalAction ({ employees, open, closeModal, actionEl, actionForm, handleUpdateAction }) {
    return (
        <Dialog footer={null} open={open} onClose={closeModal} maxWidth={700}>
            <DialogTitle>
                { actionEl === '2' ? 'Xuất kho' : 'Sửa chữa' }
            </DialogTitle>
            <DialogContent>
                <form className="pb-3" onSubmit={actionForm.handleSubmit(handleUpdateAction)}>
                    { actionEl === '2'
                        ? <>
                        <div className='grid'>
                            <div className='form-device'>
                                <Controller
                                    control={actionForm.control}
                                    render={({ field: { onChange, value } }) => (
                                        <CSelect customSelect="custom-select-device" minWidth={290} handleSelect={(e) => onChange(e.target.value)} value={value || ''} label='Người sử dụng'>
                                            {employees?.items?.map((item, i) => (
                                                <MenuItem value={item?._id} key={i}>{item?.username}</MenuItem>
                                            ))}
                                        </CSelect>
                                    )}
                                    {...actionForm.register('assign_person', { required: TranslateHelper.getMessage('validation.error.required') })}
                                    name="assign_person"
                                />
                                {actionForm.formState.errors.assign_person &&
                                    <p className="error text-danger">
                                        {
                                            actionForm.formState.errors.assign_person.message
                                        }
                                    </p>}
                            </div>
                            <div className='form-device custom-textarea'>
                                <label className='custom-label'>Ghi chú</label>
                                <textarea
                                    {...actionForm.register('note', {})}
                                    rows="5"
                                    cols="38"
                                />
                            </div>
                        </div>
                    </>
                        : <div className='form-row'>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label='Công ty bảo hành'
                                    autocomplete="off"
                                    {...actionForm.register('warranty_company', { required: TranslateHelper.getMessage('validation.error.required') })}
                                />
                                {actionForm.formState.errors.warranty_company &&
                                    <p className="error text-danger">
                                        {
                                            actionForm.formState.errors.warranty_company.message
                                        }
                                    </p>}
                            </div>
                            <div className='form-device custom-textarea'>
                                <label className='custom-label'>Ghi chú</label>
                                <textarea
                                    {...actionForm.register('note', {})}
                                    rows="5"
                                    cols="38"
                                />
                            </div>
                    </div>}

                    <div className='d-flex w-100 justify-content-end align-items-center buttons'>
                        <button
                            type="button"
                            onClick={() => {
                                closeModal();
                            }}
                            className="cancel-btn justify-content-center mr-2 px-4 py-2"
                        >
                            Hủy
                        </button>
                        <button type="submit" className='main-btn justify-content-center px-4 py-2'>Xác nhận</button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default ModalAction;
