import { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import recruitmentService from '../../Services/recruitment.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import UpdateInterviewForm from './UpdateInterViewForm';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import ReactPagination from '../../../ReactPagination';
import FilterRecruitment from './FilterRecruitment';
import RecruitmentContext from '../../../../context/RecruitmentContext';
import Button from '@atlaskit/button';
import { Box } from '@mui/material';

export default function Candidates () {
    const [filterOptions, setFilterOptions] = useState({
        team: undefined,
        candidateTitle: undefined,
        statusInterview: undefined
    });

    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);

    const { forTeams, interviewers } = useContext(RecruitmentContext);

    const { data: interviews, refetch } = useService(
        recruitmentService.getInterviews,
        { ...filters, isPage: 2, page },
        (res) => res
    );

    const { data: provinces } = useService(recruitmentService.getAllProvinces);

    const [selectedInterview, setSelectedInterview] = useState(null);

    const memoizedFilter = useDeepCompareMemoize(filterOptions);

    const handleDeleteInterview = useCallback((interview) => {
        Swal.fire({
            title:
                TranslateHelper.getMessage('alert.delete') + ' "' + interview.candidateName + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await recruitmentService.deleteInterviewById(interview._id);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                    return refetch();
                } catch (e) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title:
                            TranslateHelper.getMessage('respond.message.delete.failed') || e.message
                    });
                }
            }
        });
    }, []);

    return (
        <div>
            <div className="card ">
                <FilterRecruitment filterOptions={filterOptions} setFilterOptions={setFilterOptions} setFilters={setFilters} memoizedFilter={memoizedFilter} forTeams={forTeams} setPage={setPage}/>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className="py-4">
                        <div className='table-scrollX mb-3'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.title" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.team" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.interviewers" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.dateTime" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.type" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.location" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.status" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.action" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {interviews?.items?.length > 0
                                        ? interviews.items.map((interview) => (
                                            <tr key={interview._id}>
                                                <th scope="row">{interview.candidateName}</th>
                                                <td>
                                                    {
                                                        recruitmentService.titles.find(
                                                            (item) => item.value === interview.candidateTitle
                                                        )?.label
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        forTeams.find(
                                                            (item) => item.value === interview.forTeam
                                                        )?.label
                                                    }
                                                </td>
                                                <td>
                                                    {interview.interviewers.map((interviewer) => (
                                                        <div key={interviewer.id}>
                                                            <a href="#">
                                                                {interviewer.firstname} {interviewer.lastname}
                                                            </a>
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {moment(interview.dateInterview).format('MM/DD/YYYY HH:mm') || 'a' }
                                                </td>
                                                <td>
                                                    {
                                                        recruitmentService.formatInterviews.find(
                                                            (item) => item.value === interview.formatInterview
                                                        )?.label
                                                    }
                                                </td>
                                                <td>
                                                    <Box sx={{ backgroundColor: recruitmentService.statues.find((item) => item.value === interview.statusInterview)?.color, textAlign: 'center', color: 'white', p: 1 }}>
                                                        {recruitmentService.statues.find((item) => item.value === interview.statusInterview)?.label}
                                                    </Box>
                                                </td>
                                                <td>
                                                    {recruitmentService.statues.find(
                                                        (item) => item.value === interview.statusInterview
                                                    )?.label || 'Pending'}
                                                </td>
                                                <td style={{ minWidth: '130px' }}>
                                                    <Button
                                                        data-toggle="modal"
                                                        data-target={`#modal-interview-${interview._id}`}
                                                        className="mr-2"
                                                        title={TranslateHelper.getMessage('button.name.common.edit')}
                                                        onClick={() => { setSelectedInterview(interview); }}
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </Button>

                                                    <Button
                                                        onClick={() => handleDeleteInterview(interview)}
                                                    >
                                                        <i className="far fa-trash-alt" aria-hidden="true"></i>
                                                    </Button>

                                                </td>
                                            </tr>
                                        ))
                                        : <td className="text-center" colSpan="9">
                                            <FormattedMessage id="message.no.data.available" />
                                        </td>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <UpdateInterviewForm
                            refetch={refetch}
                            interview={selectedInterview}
                            forTeams={forTeams}
                            interviewers={interviewers}
                            provinces={provinces}
                            />
                        {
                            interviews?.totalPages > 1 &&
                                <ReactPagination
                                    totalPages={interviews?.totalPages}
                                    handlePageChange={(e, page) => setPage(page)}
                                    pageNumber={interviews?.page}
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
