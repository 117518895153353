import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class EmployeesService {
    constructor () {
        serviceHelpers();
    }

    createHoliday (payload) {
        return axiosService.post(API_URL + 'holidays', payload).then(res => res.data.data);
    }

    getHoliday (params) {
        return axiosService.get(API_URL + 'holidays', {
            params
        }).then(res => res.data.data);
    }

    getEmployeeById (id) {
        return axiosService.get(API_URL + 'employees/' + id).then(res => res.data.data);
    }

    updateHoliday (id, payload) {
        return axiosService.patch(API_URL + 'holidays/' + id, payload).then(res => res.data.data);
    }

    deleteHolidayById (id) {
        return axiosService.delete(API_URL + 'holidays/' + id).then(res => res.data.data);
    }
}

export default new EmployeesService();
