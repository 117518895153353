import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import MagicUrl from 'quill-magic-url';

// Undo and redo functions for Custom Toolbar
function undoChange () {
    this.quill.history.undo();
}
function redoChange () {
    this.quill.history.redo();
}

// Quill Toolbar component
function QuillToolbar ({ id }) {
    const [size, setSize] = useState();
    const CustomUndo = () => (
        <svg viewBox="0 0 18 18">
          <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
          <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
          />
        </svg>
    );

    const CustomRedo = () => (
        <svg viewBox="0 0 18 18">
          <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
          <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
          />
        </svg>
    );

    // Add sizes to whitelist and register them
    const Size = Quill.import('formats/size');
    Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
    Quill.register(Size, true);

    const handleFontSizeChange = (event) => {
        setSize(event.target.value);
    };

    // Add fonts to whitelist and register them
    const Font = Quill.import('formats/font');
    Font.whitelist = [
        'arial',
        'comic-sans',
        'courier-new',
        'georgia',
        'helvetica',
        'Inter',
        'lucida'
    ];
    Quill.register(Font, true);

    Quill.register('modules/magicUrl', MagicUrl);

    Quill.debug('error');

    return (
        <>
            {id &&
            <>
                <div id={id}>
                    <span className="ql-formats">
                        <button className="ql-bold" />
                        <button className="ql-italic" />
                        <button className="ql-underline" />
                        <button className="ql-strike" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered" />
                        <button className="ql-list" value="bullet" />
                        <button className="ql-indent" value="-1" />
                        <button className="ql-indent" value="+1" />
                        <select className="ql-align" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-blockquote" />
                        <button className="ql-code-block" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-color" />
                        <select className="ql-background" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-font">
                            <option value="arial"> Arial </option>
                            <option value="comic-sans">Comic Sans</option>
                            <option value="courier-new">Courier New</option>
                            <option value="georgia">Georgia</option>
                            <option value="helvetica">Helvetica</option>
                            <option value="Inter">Inter</option>
                            <option value="lucida">Lucida</option>
                        </select>
                        <select className="ql-size" value={size} onChange={handleFontSizeChange}>
                            <option value="extra-small">Extra Small</option>
                            <option value="small">Small</option>
                            <option value="medium" >Medium</option>
                            <option value="large">Large</option>
                        </select>
                        <select className="ql-header">
                            <option value="1">Heading 1</option>
                            <option value="2">Heading 2</option>
                            <option value="3">Heading 3</option>
                            <option value="4">Heading 4</option>
                            <option value="5">Heading 5</option>
                            <option value="6">Heading 6</option>
                            <option value="">Normal</option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-undo">
                            <CustomUndo />
                        </button>
                        <button className="ql-redo">
                            <CustomRedo />
                        </button>
                    </span>
                </div>
            </>
            }
        </>
    );
}

// Modules object for setting up the Quill editor
export default function Editor ({ placeholder, toolbarId, defaultValue, onChange, mentionList, height, setMentionToUser }) {
    const editorRef = useRef();
    const [editor, setEditor] = useState();
    const [options] = useState({
        placeholder,
        theme: 'snow',
        modules: {
            magicUrl: true,
            toolbar: {
                container: '#' + toolbarId,
                handlers: {
                    undo: undoChange,
                    redo: redoChange
                }
            },
            mention: {
                allowedChars: /^[\x5FA-Za-z\s]*$/,
                mentionDenotationChars: ['@'],
                renderItem: useCallback((item, searchTerm) => {
                    return `<div class="mentionLi">
                                <img 
                                    class="mentionAvatar mr-3"
                                    onerror="this.src='/images/default-avatar.png';"
                                    src = "${item?.avatar ? item?.avatar : '/images/default-avatar.png'}"
                                />
                                ${item?.name || item?.label}
                            </div>`
                    ;
                }, [mentionList]),
                renderLoading: () => {
                    return 'Loading...';
                },
                dataAttributes: ['id', 'name', 'value', 'denotationChar', 'link', 'target', 'disabled', 'avatar'],
                source: async (searchTerm, renderList) => {
                    const res = await new Promise(res => {
                        const filtered = mentionList && mentionList.filter(item => item?.name ? item?.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) : item?.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
                        res(filtered);
                    });
                    renderList && renderList(res);
                },
                onSelect: async (item, insertItem) => {
                    const res = await new Promise(res => {
                        item.value = item.name || item.label;
                        const filtered = item;
                        setMentionToUser(prevState => [...prevState, filtered?.id]);
                        res(filtered);
                    });
                    return insertItem(res);
                }
            },
            history: {
                delay: 500,
                maxStack: 100,
                userOnly: true
            }
        }
    });

    const initialEditor = () => {
        const quill = new Quill(editorRef?.current, options);
        setEditor(quill);
        quill.setContents(quill.clipboard.convert(defaultValue) || '');
        const onSelectionChange = range => {
        };
        quill.on('text-change', (content, event, editor) => {
            onChange(quill.root.innerHTML);
        });
        quill.on('selection-change', onSelectionChange);
    };

    useEffect(initialEditor, []);

    useEffect(() => {
        editor?.setContents(editor?.clipboard.convert(defaultValue) || '');
    }, [defaultValue]);

    return (
        <div className="text-editor">
          <QuillToolbar id = {toolbarId} />
          <div id={'quill-container_' + toolbarId} ref={editorRef} style={{ minHeight: '100px' }}/>
        </div>
    );
}

// Formats objects for setting up the Quill editor
Editor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'code-block',
    'mention',
    'redo',
    'undo'
];
