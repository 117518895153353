
import './ModalUpdatePassword.css';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import authService from '../../../../common/services/auth.service';
import { logout } from '../../../../common/actions/auth.action';
import { useDispatch } from 'react-redux';

export default function ModalUpdatePassword () {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [hasPassword, setHasPassword] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        authService
            .checkHasPassword()
            .then((res) => {
                setHasPassword(res.data.message);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [open]);

    const onSubmit = (data) => {
        setIsSubmitting(true);
        setError('');
        authService
            .changePassword({
                oldPassword: data.currentPassword,
                newPassword: data.password
            })
            .then((res) => {
                dispatch(
                    logout()
                );
                handleClose();
            })
            .catch((err) => {
                if (err.response?.data) {
                    const data = err.response.data;
                    setError(data.message);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setHasPassword(false);
        reset();
        setError('');
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ marginLeft: '10px' }}>
                Change Password
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Change Password</h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} id="modal-update-password">
                            {error && (
                                <Alert style={{ marginBottom: '10px' }} severity="error">{error}</Alert>)}
                            {hasPassword && <div className="input-group">
                                <label className="label">
                                    Current password <span>*</span>
                                </label>
                                <input
                                    {...register('currentPassword', {
                                        required: true
                                    })}
                                    placeholder="Enter current password"
                                    className="input"
                                    type='password'
                                />
                                {errors.currentPassword && (
                                    <span className="error-message">
                                        Current password is required
                                    </span>
                                )}
                            </div>}
                            <div className="input-group">
                                <label className="label">
                                    New password <span>*</span>
                                </label>
                                <input
                                    {...register('password', {
                                        required: true,
                                        minLength: 8
                                    })}
                                    placeholder="Enter new password"
                                    type="password"
                                />
                                {errors.password && (
                                    <span className="error-message">
                                        Password is required and must be at
                                        least 8
                                    </span>
                                )}
                            </div>
                            <div className="input-group">
                                <label className="label">
                                    Confirm password <span>*</span>
                                </label>
                                <input
                                    {...register('confirmPassword', {
                                        required: true,
                                        validate: (value) =>
                                            value === document.getElementById('modal-update-password').querySelector('input[name="password"]').value
                                    })}
                                    placeholder="Enter confirm password"
                                    type="password"
                                />
                                {errors.confirmPassword && (
                                    <span className="error-message">
                                        Confirm password is required and must be the same as new password
                                    </span>
                                )}
                            </div>
                            <Button
                                disabled={isSubmitting}
                                className="submit-button"
                                variant="contained"
                                type="submit"
                            >
                                {isSubmitting ? 'Pending...' : 'Change Password'}
                            </Button>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
