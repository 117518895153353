import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { REACTION_NUMBER, EMOTIONS_NAME } from '../../../constants/project.constant';
import { CommentAction } from '@atlaskit/comment';
import issueService from '../services/issue.service';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SentimentDissatisfiedSharpIcon from '@mui/icons-material/SentimentDissatisfiedSharp';
import { IconButton, Tooltip } from '@mui/material';

function IssueCommentReaction ({ dataComment, user, handlePopoverOpen, handlePopoverClose, open, setUserReacted }) {
    const [commentReact, setCommentReact] = useState({});
    const [isReacted, setIsReacted] = useState({});

    useEffect(() => {
        const data = {};
        const emotionReacted = {};
        EMOTIONS_NAME.forEach(item => {
            const emotionData = {
                code: REACTION_NUMBER[item],
                count: dataComment.reaction?.filter(reaction => reaction?.reactionCode === REACTION_NUMBER[item] && reaction.reactionStatus).length,
                users: dataComment.reaction?.filter(reaction => reaction?.reactionCode === REACTION_NUMBER[item] && reaction.reactionStatus).map(user => user.reactionCreatedBy)
            };
            data[item] = emotionData;

            emotionReacted[item] = dataComment.reaction?.some(reaction => reaction.reactionCreatedBy._id === user.userId && reaction.reactionCode === REACTION_NUMBER[item] && reaction.reactionStatus);
        });
        setCommentReact(data);
        setIsReacted(emotionReacted);
    }, [dataComment]);

    const onHoverReaction = (e, users) => {
        handlePopoverOpen(e);
        setUserReacted(users);
    };

    const renderIcon = (reactionCode, users) => {
        switch (reactionCode) {
            case REACTION_NUMBER.LIKE:
                return (<ThumbUpIcon aria-aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => onHoverReaction(e, users)} onMouseLeave={handlePopoverClose} color='primary' fontSize='small'/>);
            case REACTION_NUMBER.HEART:
                return (<FavoriteIcon aria-aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => onHoverReaction(e, users)} onMouseLeave={handlePopoverClose} color='error' fontSize='small'/>);
            case REACTION_NUMBER.FIRE:
                return (<WhatshotIcon aria-aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => onHoverReaction(e, users)} onMouseLeave={handlePopoverClose} color='action' fontSize='small'/>);
            case REACTION_NUMBER.DISLIKE:
                return (<ThumbDownIcon aria-aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => onHoverReaction(e, users)} onMouseLeave={handlePopoverClose} color='success' fontSize='small'/>);
            case REACTION_NUMBER.ANGRY:
                return (<SentimentDissatisfiedSharpIcon aria-aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => onHoverReaction(e, users)} onMouseLeave={handlePopoverClose} color='warning' fontSize='small'/>);
            default:
                break;
        }
    };

    const renderReactions = (commentReact) => {
        const reactionArr = [];

        EMOTIONS_NAME.forEach(item => {
            if (commentReact[item]?.count) {
                reactionArr.push(
                    <CommentAction onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER[item], dataComment.createdBy._id, !isReacted[item])}>
                        <span className={ isReacted[item] && 'reacted-icon' }>{commentReact[item].count} {renderIcon(commentReact[item].code, commentReact[item].users)}</span>
                    </CommentAction>
                );
            }
        });

        return reactionArr;
    };

    const setReactionComment = (commentId, reactType, author, isReact) => {
        switch (reactType) {
            case REACTION_NUMBER.LIKE:
                setCommentReact(prevState => ({
                    ...prevState,
                    LIKE: {
                        ...prevState.LIKE,
                        count: isReact ? prevState.LIKE.count + 1 : prevState.LIKE.count - 1,
                        users: [...prevState.LIKE.users, { firstname: user.firstname, lastname: user.lastname, _id: user.userId, picture: user.imageUrl }].filter((user, index, a) => a.findIndex(user2 => (user2._id === user._id)) === index)
                    }
                }));
                setIsReacted(prevState => ({ ...prevState, LIKE: isReact }));
                break;
            case REACTION_NUMBER.HEART:
                setCommentReact(prevState => ({
                    ...prevState,
                    HEART: {
                        ...prevState.HEART,
                        count: isReact ? prevState.HEART.count + 1 : prevState.HEART.count - 1,
                        users: [...prevState.HEART.users, { firstname: user.firstname, lastname: user.lastname, _id: user.userId, picture: user.imageUrl }].filter((user, index, a) => a.findIndex(user2 => (user2._id === user._id)) === index)
                    }
                }));
                setIsReacted(prevState => ({ ...prevState, HEART: isReact }));
                break;
            case REACTION_NUMBER.FIRE:
                setCommentReact(prevState => ({
                    ...prevState,
                    FIRE: {
                        ...prevState.FIRE,
                        count: isReact ? prevState.FIRE.count + 1 : prevState.FIRE.count - 1,
                        users: [...prevState.FIRE.users, { firstname: user.firstname, lastname: user.lastname, _id: user.userId, picture: user.imageUrl }].filter((user, index, a) => a.findIndex(user2 => (user2._id === user._id)) === index)
                    }
                }));
                setIsReacted(prevState => ({ ...prevState, FIRE: isReact }));
                break;
            case REACTION_NUMBER.DISLIKE:
                setCommentReact(prevState => ({
                    ...prevState,
                    DISLIKE: {
                        ...prevState.DISLIKE,
                        count: isReact ? prevState.DISLIKE.count + 1 : prevState.DISLIKE.count - 1,
                        users: [...prevState.DISLIKE.users, { firstname: user.firstname, lastname: user.lastname, _id: user.userId, picture: user.imageUrl }].filter((user, index, a) => a.findIndex(user2 => (user2._id === user._id)) === index)
                    }
                }));
                setIsReacted(prevState => ({ ...prevState, DISLIKE: isReact }));
                break;
            case REACTION_NUMBER.ANGRY:
                setCommentReact(prevState => ({
                    ...prevState,
                    ANGRY: {
                        ...prevState.ANGRY,
                        count: isReact ? prevState.ANGRY.count + 1 : prevState.ANGRY.count - 1,
                        users: [...prevState.ANGRY.users, { firstname: user.firstname, lastname: user.lastname, _id: user.userId, picture: user.imageUrl }].filter((user, index, a) => a.findIndex(user2 => (user2._id === user._id)) === index)
                    }
                }));
                setIsReacted(prevState => ({ ...prevState, ANGRY: isReact }));
                break;
            default:
                break;
        }
        const payload = {
            reactionCode: reactType,
            reactionStatus: isReact,
            mentionToUser: author
        };
        issueService.updateComment(commentId, payload).then().catch(err => {
            console.log(err);
        });
    };

    return [
        ...renderReactions(commentReact),
        <div key={dataComment._id} className="info btn-group pointer">
            <span data-toggle="dropdown" aria-expanded="true">
                <i className="far fa-smile"></i>
            </span>
            <ul className="dropdown-menu dropdown-menu-left">
                <div className='d-flex justify-content-around'>
                    <li onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER.LIKE, dataComment.createdBy._id, !isReacted.LIKE)} className='nav-item'>
                        <Tooltip title='Like'>
                            <IconButton>
                                <ThumbUpIcon color='primary'/>
                            </IconButton>
                        </Tooltip>
                    </li>
                    <li onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER.HEART, dataComment.createdBy._id, !isReacted.HEART)} className="nav-item">
                        <Tooltip title='Heart'>
                            <IconButton>
                                <FavoriteIcon color='error'/>
                            </IconButton>
                        </Tooltip>
                    </li>
                    <li title='Fire' onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER.FIRE, dataComment.createdBy._id, !isReacted.FIRE)} className="nav-item">
                        <Tooltip title='Fire'>
                            <IconButton>
                                <WhatshotIcon color='success'/>
                            </IconButton>
                        </Tooltip>
                    </li>
                    <li title='Dislike' onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER.DISLIKE, dataComment.createdBy._id, !isReacted.DISLIKE)} className="nav-item">
                        <Tooltip title='Dislike'>
                            <IconButton>
                                <ThumbDownIcon color='action'/>
                            </IconButton>
                        </Tooltip>
                    </li>
                    <li title='Angry' onClick={() => setReactionComment(dataComment._id, REACTION_NUMBER.ANGRY, dataComment.createdBy._id, !isReacted.ANGRY)} className="nav-item">
                        <Tooltip title='Angry'>
                            <IconButton color='warning'>
                                <SentimentDissatisfiedSharpIcon/>
                            </IconButton>
                        </Tooltip>
                    </li>
                </div>
            </ul>
        </div>
    ];
}

function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(IssueCommentReaction);
