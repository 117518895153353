import { PROJECT_ROLE } from '../constants/project.constant';
import { ROLES } from '../constants/user.constant';

const isAllowEditProject = (userRole, roleInProject) => {
    return (
        userRole === ROLES.MANAGER ||
        userRole === ROLES.PARTNEROWNER ||
        userRole === ROLES.PARTNERMANAGER ||
        roleInProject === PROJECT_ROLE.MANAGER
    );
};

export { isAllowEditProject };
