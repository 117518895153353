import React, { useRef, useCallback, useEffect, useState } from 'react';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import CategoryDetail from './CategoryDetail';
import { connect } from 'react-redux';
import issueService from '../../../services/issue.service';
import Button from '@atlaskit/button';
import { isAllowEditProject } from '../../../../../helpers/CheckRoleHelper';

function Category ({ projectId, role, userRoleInProject }) {
    const hideModalButtonRef = useRef();
    const [categories, setCategory] = useState([]);
    const [isView, setIsView] = useState();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const fetchCategories = useCallback(() => {
        issueService.getProjectCategory(projectId).then(res => {
            setCategory(res.data.data);
        });
    }, []);

    useEffect(fetchCategories, [fetchCategories]);

    const handleCreateCategory = useCallback(
        async (formData) => {
            const payload = {
                categoryName: formData.categoryName.trim()
            };
            if (payload.categoryName) {
                try {
                    await issueService.createCategory(projectId, payload);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.addnew.successed')
                    });
                    hideModalButtonRef.current?.click();
                    reset();
                    return fetchCategories();
                } catch (error) {
                    console.warn(error);
                    ToastTopEnd.fire({
                        icon: 'error',
                        title:
                            error.response.data.message ||
                            TranslateHelper.getMessage('respond.message.failed')
                    });
                }
            } else {
                setError('categoryName');
            }
        }, [projectId, hideModalButtonRef]
    );

    const handleDelete = (id, name) => {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + name + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await issueService.deleteCategory(projectId, id);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.delete.successfully')
                });
                return fetchCategories();
            }
        });
    };
    const editFlag = isAllowEditProject(role, userRoleInProject);
    const renderCategoryTable = data => {
        return data?.map((value, index) => {
            return (
                <tr key={index}>
                    <td>{value.categoryName}</td>
                    <td>
                        {moment(value.createdAt).format(
                            TranslateHelper.getMessage('date.format')
                        )}
                    </td>
                    <td>
                        {value.createdBy.firstname + ' ' + value.createdBy.lastname}
                    </td>
                    {editFlag && <td>
                        <Button
                            title='Edit'
                            className="mr-2"
                            data-toggle="modal"
                            data-target={'#modal-category-' + value._id}
                            onClick={() => setIsView(value)}
                        >
                            <i className='far fa-edit'></i>
                        </Button>
                        <Button
                            title='Delete'
                            onClick={(e) => handleDelete(value._id, value.categoryName)}
                        >
                            <i className='far fa-trash-alt'></i>
                        </Button>
                    </td>}
                </tr>
            );
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-body'>
                {editFlag && <Button type="button" appearance='primary' data-toggle="modal" data-target="#modal-category" style={{ margin: '10px 0px' }}>{TranslateHelper.getMessage('button.name.common.create')}</Button>}
                    <div className="modal fade" id="modal-category">
                        <div className="modal-dialog modal-sm">
                            <form onSubmit={handleSubmit(handleCreateCategory)} className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">
                                        <FormattedMessage id="project.detail.settings.category.table.column.name.category" />
                                    </h4>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group row">
                                        <label htmlFor="inputCategory" className="col-sm-4 col-form-label text-nowrap"><FormattedMessage id="project.detail.settings.category.table.column.name.category" /></label>
                                        <div className="col-sm-8">
                                            <input
                                                placeholder="Name of category?"
                                                className={
                                                    errors.categoryName
                                                        ? 'is-invalid form-control'
                                                        : 'form-control'
                                                }
                                                {...register('categoryName', { required: TranslateHelper.getMessage('validation.error.required'), maxLength: { value: 30, message: TranslateHelper.getMessage('validation.error.max30characters') } })}
                                            />
                                            {errors.categoryName && (
                                                <span className="error text-danger">
                                                    {errors.categoryName.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end">
                                    <Button
                                        type="button"
                                        ref={hideModalButtonRef}
                                        className="btn btn-outline-danger"
                                        data-dismiss="modal"
                                    >
                                        <FormattedMessage id="button.name.common.cancel" />
                                    </Button>
                                    <Button
                                        type="submit"
                                        appearance='primary'
                                        disabled={!isDirty || !isValid}
                                    >
                                        {TranslateHelper.getMessage('button.name.common.create')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='table-scrollX'>
                        <table id="category-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="project.detail.settings.category.table.column.name.category" /></th>
                                    <th><FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdAt" /></th>
                                    <th><FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdBy" /></th>
                                    {editFlag && <th><FormattedMessage id="project.detail.settings.issuetype.table.column.name.action" /></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {categories.length > 0
                                    ? renderCategoryTable(categories)
                                    : <td className='text-center' colSpan="4"><FormattedMessage id='message.no.data.available' /></td>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            { isView && <CategoryDetail projectId={projectId} category={isView} fetchCategories={fetchCategories}/> }
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        role,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(Category);
