import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBreadcrumb, clearBreadcrumb } from '../../../../actions/breadcrumb.action';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import GeneralSbDetail from './SandboxDetail/GeneralSbDetail';
import AliasSbDetail from './SandboxDetail/AliasSbDetail';
import SettingsSbDetail from './SandboxDetail/SettingsSbDetail';
import UsagesSbDetail from './SandboxDetail/UsagesSbDetail';
import StoragesSbDetail from './SandboxDetail/StoragesSbDetail';
import SchedulesSbDetail from './SandboxDetail/SchedulesSbDetail';

class SandboxDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            match: this.props.match
        };
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.sandboxes.detail');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.member.name.sandbox.sandboxes',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.sandbox.sandboxes',
                    haslink: true,
                    path: '/sandboxes/operations'
                },
                {
                    label: 'menu.member.name.sandbox.sandboxes.detail',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }

    /**
     * Clear data component
     */
    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    render () {
        const { match } = this.props;
        return (
            <div className="container-fluid">
                <h5 className="text-left" style={{ color: '#007bff' }}><FormattedMessage id="sandbox.detail.title"/>: {match.params.sandboxName}</h5>
                <div className="card card-primary card-outline card-outline-tabs">
                    <div className="card-header p-0 border-bottom-0">
                        <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" href="#custom-tabs-four-general" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true"><FormattedMessage id="sandbox.detail.tab.general"/></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-four-alias-tab" data-toggle="pill" href="#custom-tabs-four-alias" role="tab" aria-controls="custom-tabs-four-alias" aria-selected="false"><FormattedMessage id="sandbox.detail.tab.alias"/></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill" href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings" aria-selected="false"><FormattedMessage id="sandbox.detail.tab.settings"/></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-four-usages-tab" data-toggle="pill" href="#custom-tabs-four-usages" role="tab" aria-controls="custom-tabs-four-usages" aria-selected="false"><FormattedMessage id="sandbox.detail.tab.usages"/></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-four-storages-tab" data-toggle="pill" href="#custom-tabs-four-storages" role="tab" aria-controls="custom-tabs-four-storages" aria-selected="false"><FormattedMessage id="sandbox.detail.tab.storages"/></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-four-schedules-tab" data-toggle="pill" href="#custom-tabs-four-schedules" role="tab" aria-controls="custom-tabs-four-schedules" aria-selected="false">Schedules</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div className="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general-tab">
                                <GeneralSbDetail sandboxId={match.params.sandboxId} history={this.props.history}/>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-four-alias" role="tabpanel" aria-labelledby="custom-tabs-four-alias-tab">
                                <AliasSbDetail sandboxId={match.params.sandboxId}/>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                <SettingsSbDetail sandboxId={match.params.sandboxId}/>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-four-usages" role="tabpanel" aria-labelledby="custom-tabs-four-usages-tab">
                                <UsagesSbDetail sandboxId={match.params.sandboxId}/>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-four-storages" role="tabpanel" aria-labelledby="custom-tabs-four-storages-tab">
                                <StoragesSbDetail sandboxId={match.params.sandboxId}/>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-four-schedules" role="tabpanel" aria-labelledby="custom-tabs-four-schedules-tab">
                                <SchedulesSbDetail sandboxId={match.params.sandboxId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(SandboxDetail);
