import Avatar from '@mui/material/Avatar';
import recruitmentService from '../../Services/recruitment.service';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import hrService from '../../Services/hr.service';

export default function LeftOfficial ({ avatar, status, id, refetch }) {
    const [enable, setEnable] = useState(true);

    useEffect(() => {
        setEnable(status?.value === 'enable');
    }, [status]);

    const handleChangeStatus = (e) => {
        const payload = {
            status: enable ? 'disable' : 'enable'
        };
        hrService.updateEmployee(id, payload).then(() => {
            refetch();
        });
        setEnable(!enable);
    };
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className='d-flex justify-content-end mb-3'>
                        <Chip label={enable ? 'Enable' : 'Disabled'} color={enable ? 'primary' : 'error'}/>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Avatar sx={{ width: 150, height: 150 }} src={avatar
                            ? !String(avatar).includes(
                                    'uploads'
                                )
                                    ? avatar
                                    : recruitmentService.buildFileURL(
                                        avatar
                                    )
                            : '/images/default-avatar.png'}>
                        </Avatar>
                    </div>
                    <div className='switch-status mt-3'>
                        <Box className='d-flex justify-content-between'>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant={'body1'}
                                className='mr-2'
                            >
                                Status
                                <Typography
                                    variant='body2'
                                    className='align-self-center font-weight-bold'
                                    sx={{ color: 'rgb(99, 115, 129);' }}
                                >Enable/disable account</Typography>
                            </Typography>
                            <Switch checked={enable} onChange={handleChangeStatus} className='align-self-center'/>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}
