import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/';

class LeaveRequestService {
    constructor () {
        serviceHelpers();
    }

    getlineManager (username) {
        return axiosService.get(API_URL + 'hrm/employees', { params: { username } });
    }

    getOneHR (id) {
        return axiosService.get(API_URL + 'hrm/employees/' + id);
    }

    assignLRToMember (payload) {
        return axiosService.post(API_URL + 'hrm/services/assign-leave-request', payload);
    }

    getAvailableLeave (_id) {
        return axiosService.get(API_URL + 'projects/members/kimei', { params: { _id } });
    }

    filterLeaveRequest (status, fromDate, toDate, id) {
        return axiosService.get(API_URL + 'hrm/services/leaves', { params: { status, fromDate, toDate, id } });
    }

    filterMyLeave (status, fromDate, toDate) {
        return axiosService.get(API_URL + 'hrm/services/myrequest', { params: { status, fromDate, toDate } });
    }

    cancelLR (id) {
        return axiosService.delete(API_URL + 'hrm/services/leaves/' + id);
    }

    viewLR (id) {
        return axiosService.get(API_URL + 'hrm/services/myrequest/' + id);
    }

    updateLeaveRequest (id, payload) {
        return axiosService.patch(API_URL + 'hrm/services/leaves/' + id + '/status', payload);
    }
}

export default new LeaveRequestService();
