import moment from 'moment';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { clearBreadcrumb, setBreadcrumb } from '../../../common/actions/breadcrumb.action';
import { useService } from '../../../common/helpers/serviceHelpers';
import { ToastTopEnd } from '../../../common/helpers/ToastTimer';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import subscriptionServices from '../subscription.services';
import './subscription.css';
import CurrencyFormat from 'react-currency-format';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '@atlaskit/button';

function Subscription ({ dispatch }) {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.isExpired) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Your subscription has been expired, please renew to continue using the service'
            });
        }
    }, []);

    useLayoutEffect(() => {
        document.title = 'Subscription';
        dispatch(
            setBreadcrumb({
                title: 'menu.admin.name.subscription',
                breadcrumbItems: [
                    {
                        label: 'app.breadcrumb.common.home',
                        haslink: true,
                        path: '/dashboard'
                    },
                    {
                        label: 'menu.admin.name.subscription',
                        haslink: false,
                        path: ''
                    }
                ]
            })
        );
        return () => {
            dispatch(clearBreadcrumb());
        };
    }, []);

    const { data, refetch } = useService(
        subscriptionServices.getSubscription,
        {},
        (res) => res.items
    );

    const { data: dataPackage } = useService(
        subscriptionServices.getAllPackage,
        {},
        (res) => res.items
    );

    const handleSubmit = useCallback(
        async (packageName) => {
            try {
                const data = { packageName, paymentMethodName: 'Paypal' };
                await subscriptionServices.subscription(data);
                refetch();
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                window.location.reload();
            } catch (error) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        },
        [refetch]
    );

    return (
        <div className="pl-3">
            <Button
                appearance='primary'
                data-toggle="modal"
                data-target="#modalSubscription"
            >
                <FormattedMessage id="button.name.common.subscription" />
            </Button>
            <div
                className="modal fade "
                id="modalSubscription"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-scrollable mw-100 w-75 "
                    role="document"
                    id='subscription-modal-sp'
                >
                    <div className="modal-content bg-transparent rounded-20px">
                        <section className="modal-body pricing" style={{ overflowY: 'auto' }}>
                            <div className="py-5">
                                <div className="container pt-5">
                                    <div className="row justify-content-center">
                                        {dataPackage?.map((item, index) => {
                                            const isActivated = data?.some(
                                                (itemData) =>

                                                    itemData.statusTransaction &&
                                                    itemData.packageId?.name === item.name
                                            );
                                            return (
                                                (
                                                    <div key={item._id} className={'col-lg-4 mt-3'}>
                                                    <div className="card mb-5 mb-lg-0 ">
                                                        <div className="card-body ">
                                                            <h5 className="text-muted text-uppercase text-center">
                                                                {item.name}
                                                            </h5>
                                                            <h6 className="card-price text-center ">
                                                            <CurrencyFormat value={item?.price} displayType={'text'} thousandSeparator={true} prefix={TranslateHelper.getMessage('prefix.price')} />
                                                            </h6>
                                                            <hr />
                                                            <div className="d-flex justify-content-center">
                                                                <Button
                                                                    isDisabled={isActivated}
                                                                    appearance='primary'
                                                                    className="text-uppercase col-5 text-nowrap"
                                                                    onClick={() => handleSubmit(item.name)}
                                                                >
                                                                    {isActivated ? 'Activated' : 'Buy'}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className='card mt-3'>
                <div className='card-header'>
                    <div className='card-title'><FormattedMessage id='menu.admin.name.subscription'/></div>
                </div>
                <div className='card-body'>
                    <div className='table-scrollX'>
                        <table className="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"><FormattedMessage id='subscription.table.column.name.packageName' /></th>
                                    <th scope="col"><FormattedMessage id='subscription.table.column.name.durations' /></th>
                                    <th scope="col"><FormattedMessage id='subscription.table.column.name.startDate' /></th>
                                    <th scope="col"><FormattedMessage id='subscription.table.column.name.expireDate' /></th>
                                    <th scope="col"><FormattedMessage id='subscription.table.column.name.status' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((subscription, index) => (
                                    <tr key={subscription?._id}>
                                        <td>{index + 1}</td>
                                        <td>{subscription.packageId?.name}</td>
                                        <td>{subscription.packageId?.durations}</td>
                                        <td>
                                            {moment(subscription?.startDate).format(
                                                TranslateHelper.getMessage('date.format')
                                            )}
                                        </td>
                                        <td>
                                            {moment(subscription?.expireDate)
                                                .format(TranslateHelper.getMessage('date.format'))}
                                        </td>
                                        <td>{subscription?.statusTransaction === 1 ? 'Active' : 'Inactive'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    return {};
}
export default connect(mapStateToProps)(Subscription);
