import Button from '@atlaskit/button';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const inputDate = (props, ref) => {
    return (
        <div className="input-group">
            <input
                onClick={props.onClick}
                type="text"
                className="form-control"
                placeholder={props.value || props.placeholder}
                readOnly={props.editable !== true}
                value={props.value}
                onChange={props.onChange}

            />
            <div className="input-group-append" onClick={props.onClick}>
                <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
            </div>
        </div>
    );
};
export const CustomInputDate = React.forwardRef(inputDate);

const inputTime = (props, ref) => (
    <div className="input-group">
        <input
            onClick={props.onClick}
            ref={ref}
            type="text"
            className="form-control"
            placeholder={props.value || props.placeholder}
            readOnly
            // defaultValue={props.value}
        />
        <div className="input-group-append" onClick={props.onClick}>
            <span className="input-group-text"><i className="far fa-clock"></i></span>
        </div>
    </div>
);
export const CustomInputTime = React.forwardRef(inputTime);

const inputDateTime = (props, ref) => (
    <div className="input-group">
        <input
            onClick={props.onClick}
            ref={ref} type="text"
            className="form-control"
            placeholder={props.value || props.placeholder}
            // readOnly
            // defaultValue={props.value}
        />
        <div className="input-group-append" onClick={props.onClick}>
            <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
        </div>
    </div>
);
export const CustomInputDateTime = React.forwardRef(inputDateTime);

const FilterCustomInput = ({ value, onClick, name }, ref) => (
    <button className='btn btn-light' type="button" onClick={onClick} ref={ref} style={{ float: 'left' }}>
        {(!value && name === 'startDate') ? 'From Date' : (!value && name === 'dueDate') ? 'To Date' : value}
        <i className='fa fa-calendar-days' style={{ alignSelf: 'center', opacity: '0.7', marginLeft: '10px' }}></i>
    </button>
);

export const FilterInputDate = React.forwardRef(FilterCustomInput);

const birthDayCustomInput = ({ value, onClick }, ref) => (
    <>
        <Button appearance='default' onClick={onClick} ref={ref}>{ value || <FormattedMessage id='HRM.detail.HR.official.label.birth'/>}</Button>
    </>
);

export const DOBCustomInput = React.forwardRef(birthDayCustomInput);
// usage
/*
handleChangeDatePicker = (name, date) => {
    var change = {};
    change[name] = date;
    this.setState(change);
}
const lang = localStorage.getItem("lang");
<DatePicker
    selected={this.state.startDate}
    onChange={this.handleChangeDatePicker.bind(this, 'startDate')}
    popperPlacement="bottom"
    locale={lang}
    name="startDate"
    customInput={<CustomInputDate/>}
/>
<DatePicker
    selected={this.state.startDate}
    onChange={this.handleChangeDatePicker.bind(this, 'startDate')}
    popperPlacement="auto"
    showTimeSelect
    dateFormat="dd/MM/yyyy h:mm"
    locale={lang}
    name="startDate"
    customInput={<CustomInputDateTime/>}
/>
<DatePicker
    selected={this.state.startTime}
    onChange={this.handleChangeDatePicker.bind(this, 'startTime')}
    popperPlacement="right-start"
    locale={lang}
    name="startTime"
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={5}
    timeCaption="Gio"
    dateFormat="h:mm"
    customInput={<CustomInputTime/>}
/>
*/
