
import { PROJECT_INFO } from '../constants/project.constant';

const initialState = {
    data: {},
    userRoleInProject: null
};

const projectReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROJECT_INFO.SET_PROJECT_INFO:
            return {
                ...state,
                data: payload
            };
        case PROJECT_INFO.SET_ROLE_IN_PROJECT_INFO:
            return {
                ...state,
                userRoleInProject: payload
            };

        default:
            return state;
    }
};

export default projectReducer;
