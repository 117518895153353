import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Package from './components/Package';
import Inventory2Icon from '@mui/icons-material/Inventory2';

export const packageMenuLinks = [
    {
        name: 'menu.admin.name.package',
        classNames: 'nav-item',
        to: '/package',
        icon: (<Inventory2Icon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER],
        key: 1
    }
];

const routes = [
    {
        path: '/package',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <Package/>);
        }
    }
];

export default routes;
