import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/deviceService/device/';

class DeviceService {
    constructor () {
        serviceHelpers();
    }

    getDevices (params) {
        return axiosService.get(API_URL, {
            params
        }).then(res => res.data.data);
    }

    createDevice (payload) {
        return axiosService.post(API_URL, payload).then(res => res.data);
    }

    getDeviceById (id) {
        return axiosService.get(API_URL + id).then(res => res.data);
    }

    updateDevice (id, payload) {
        return axiosService.patch(API_URL + id, payload).then(res => res.data);
    }

    updateDeviceStatus (id, payload) {
        return axiosService.put(API_URL + 'status/' + id, payload).then(res => res.data);
    }

    getDeviceHistoryByStatus (id, params) {
        return axiosService.get(API_URL + 'history/' + id, { params }).then(res => res.data.data);
    }
}

export default new DeviceService();
