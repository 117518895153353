import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import holidayService from '../../Services/holiday.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Button from '@atlaskit/button';

export default function HolidayForm ({ isNew, refetch, selectedHoliday, setSelectedHoliday }) {
    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors, isDirty, isValid }
    } = useForm({ mode: 'onChange' });

    const hideModalButtonRef = useRef();

    const fromDate = watch('fromDate');
    const endDate = watch('toDate');

    useEffect(() => {
        if (selectedHoliday) {
            setValue('fromDate', new Date(selectedHoliday.fromDate));
            setValue('toDate', new Date(selectedHoliday.toDate));
            setValue('holiday', selectedHoliday.holiday);
        }
    }, [selectedHoliday]);

    const handleCreateHoliday = useCallback(async (formData) => {
        try {
            await holidayService.createHoliday(formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });
            hideModalButtonRef.current.click();
            reset({
                holiday: ''
            });
            return refetch();
        } catch (error) {
            console.warn(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    }, []);
    const handleUpdateHoliday = useCallback(async (formData) => {
        try {
            const id = selectedHoliday._id;
            await holidayService.updateHoliday(id, formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
            hideModalButtonRef.current?.click();
            reset({
                holiday: ''
            });
            refetch();
            setSelectedHoliday(null);
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    }, [selectedHoliday, reset, refetch, setSelectedHoliday]);

    return (
        <div className="modal" id="modal-holiday" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="d-flex justify-content-center align-items-center modal-dialog modal-scrollable mw-100 w-75">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <FormattedMessage
                                id={
                                    isNew
                                        ? 'HRM.general.label.createHoliday'
                                        : 'HRM.general.label.editHoliday'
                                }
                            />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={hideModalButtonRef}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <form onSubmit={handleSubmit(isNew ? handleCreateHoliday : handleUpdateHoliday)}>
                            <div className="form-horizontal">
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-1 col-form-label">
                                        <FormattedMessage id="HRM.general.label.name.holiday" />
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            placeholder={
                                                TranslateHelper.getMessage(
                                                    'HRM.general.label.name.holiday'
                                                )
                                            }
                                            className={
                                                errors.holiday
                                                    ? 'is-invalid form-control  p-2'
                                                    : 'form-control'
                                            }
                                            {...register('holiday', {
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            })}
                                        />
                                        {errors.holiday && (
                                            <span className="error text-danger">
                                                {errors.holiday.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-1 col-form-label">
                                        <FormattedMessage id="HRM.general.label.name.startDate" />
                                    </label>
                                    <div className="col-sm-9">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    ref={ref}
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={
                                                        errors.fromDate
                                                            ? 'is-invalid form-control  p-2'
                                                            : 'form-control'
                                                    }
                                                    placeholderText={TranslateHelper.getMessage('date.format')}
                                                    popperPlacement="right-end"
                                                    maxDate={endDate}
                                                />
                                            )}
                                            name="fromDate"
                                        />
                                        {errors.fromDate && (
                                            <span className="error text-danger">
                                                {errors.fromDate.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-1 col-form-label">
                                        <FormattedMessage id="HRM.general.label.name.endDate" />
                                    </label>
                                    <div className="col-sm-9">
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    ref={ref}
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={
                                                        errors.toDate
                                                            ? 'is-invalid form-control  p-2'
                                                            : 'form-control'
                                                    }
                                                    placeholderText={TranslateHelper.getMessage('date.format')}
                                                    popperPlacement="right-end"
                                                    minDate={fromDate}
                                                />
                                            )}
                                            name="toDate"
                                        />
                                        {errors.toDate && (
                                            <span className="error text-danger">
                                                {errors.toDate.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-1 col-form-label ">
                                        <FormattedMessage id="HRM.general.label.name.day" />
                                    </label>
                                    <p className='pl-3 pt-2 align-item-center justify-content-center'>{endDate ? moment(endDate).diff(moment(fromDate), 'days') + 1 : 0}</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                            <Button type="submit" appearance='primary' className="d-flex justify-content-end" isDisabled={!isDirty || !isValid}>
                                    <FormattedMessage id={isNew ? 'button.name.common.create' : 'button.name.common.save'} />
                            </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
