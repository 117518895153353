import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import { ROLES } from '../../../../constants/user.constant';
import { CustomInputDate } from '../../../../components/common/CustomInputDatepicker';
import businessunitsService from './services/businessunits.service';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Swal from 'sweetalert2';
import BusinessDetail from './BusinessDetail';
import ReactPagination from '../../../ReactPagination';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';

function BusinessUnits ({ role }) {
    const customSwal = Swal.mixin({
        customClass: {
            cancelButton: 'btn btn-outline-danger',
            confirmButton: 'btn btn-primary ml-2'
        },
        buttonsStyling: false
    });
    const lang = localStorage.getItem('lang');
    const hideModalButtonRef = useRef();
    const [businessUnits, setBusinessUnits] = useState();
    const [isEdit, setisEdit] = useState(false);
    const [BUCode, setBUCode] = useState();

    const fetchBU = useCallback((page) => {
        businessunitsService.getBusinessUnits().then((res) => {
            setBusinessUnits(res.data.data);
        });
    }, []);

    const timezones = [
        { value: 1, label: 'GMT +0' },
        { value: 2, label: 'UTC +7' }
    ];
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
        reset,
        clearErrors,
        setValue
    } = useForm({
        defaultValues: {
            timezone: undefined,
            code: '',
            startedOn: undefined,
            street: '',
            city: '',
            country: '',
            name: '',
            latitude: undefined,
            longtitude: undefined
        },
        mode: 'onChange'
    });
    const onSubmit = (formData) => {
        const data = {
            code: formData.code,
            name: formData.name,
            startedOn: formData.startedOn,
            street: formData.street,
            city: formData.city,
            country: formData.country,
            timezone: formData.timezone.value,
            latitude: formData.latitude,
            longtitude: formData.longtitude
        };
        handleCreateBU(data);
    };

    const handleCreateBU = async (formData) => {
        if (isEdit) {
            try {
                await businessunitsService.editBusinessUnit(formData, BUCode);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successed')
                });
                hideModalButtonRef.current?.click();
                return fetchBU();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            try {
                await businessunitsService.createBusinessUnit(formData);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                reset({ name: '', code: '', street: '', city: '', country: '' });
                // clear select timezone
                setValue('timezone', '');
                hideModalButtonRef.current?.click();
                return fetchBU();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response?.data?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        }
    };

    const handleDelete = (code) => {
        customSwal
            .fire({
                title: TranslateHelper.getMessage('button.name.common.delete') + ' ' + code + ' ?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: TranslateHelper.getMessage('button.name.common.cancel'),
                confirmButtonText: TranslateHelper.getMessage('button.name.common.yes'),
                reverseButtons: true
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await businessunitsService.deteleBusinessUnit(code);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                    return fetchBU();
                }
            });
    };

    useEffect(fetchBU, [fetchBU]);

    const displayTimezone = (number) => {
        switch (number) {
            case 1:
                return 'GTM +0';
            case 2:
                return 'UTC +7';
            default:
                break;
        }
    };

    const renderBUTable = (data) => {
        if (!data) {
            return (
                <tr>
                    <td align="center" colSpan={8}>
                        <i className="fas fa-spinner"></i>
                    </td>
                </tr>
            );
        } else {
            return data.map((item) => {
                return (
                    <tr key={item._id}>
                        <td>{item.code}</td>
                        <td className="text-truncate">{item.name}</td>
                        <td>
                            {moment(item.startedOn).format(
                                TranslateHelper.getMessage('date.format')
                            )}
                        </td>
                        <td>{item.street}</td>
                        <td className="text-truncate">{item.city}</td>
                        <td className="text-truncate">{item.country}</td>
                        <td>{displayTimezone(item.timezone)}</td>
                        <td style={{ minWidth: '130px' }}>
                            <Button
                                className="mr-2"
                                data-toggle="modal"
                                    data-target={`#modal-businessUnit-${item.code}`}
                            >
                                <i className="fa fa-eye"></i>
                            </Button>
                            <BusinessDetail businessUnit={item}/>
                            {role === ROLES.MANAGER && (
                                <Button
                                    className="mr-2"
                                    data-toggle="modal"
                                    onClick={ () => {
                                        reset();
                                        clearErrors();
                                        setisEdit(true);
                                        setBUCode(item?.code);
                                        setValue('startedOn', new Date(moment(item?.startedOn).format('MM/DD/YYYY')));
                                        setValue('timezone', timezones.filter(tz => tz.value === item?.timezone));
                                        setValue('name', item?.name);
                                        setValue('code', item?.code);
                                        setValue('street', item?.street);
                                        setValue('city', item?.city);
                                        setValue('country', item?.country);
                                        setValue('latitude', item?.location?.latitude);
                                        setValue('longtitude', item?.location?.longtitude);
                                    }}
                                    data-target={'#edit-businessUnit'}
                                >
                                    <i className="fa fa-edit"></i>
                                </Button>
                            )}
                            <Button
                                className={`${role !== ROLES.MANAGER && 'd-none'}`}
                                onClick={() => handleDelete(item.code)}
                            >
                                <i className="far fa-trash-alt" aria-hidden="true"></i>
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
            <Button
                type="button"
                appearance='primary'
                className={`mb-3 ${role !== ROLES.MANAGER && 'd-none'}`}
                data-toggle="modal"
                data-target="#create-modal"
                onClick={() => { reset(); setisEdit(false); }}
            >
                <FormattedMessage id="button.name.common.create" />
            </Button>
            <div className="modal fade" id={!isEdit ? 'create-modal' : 'edit-businessUnit'}>
                <div className="modal-dialog" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{!isEdit ? 'Create Business unit' : 'Edit Business unit'}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => clearErrors()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.name" />
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.name ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('name', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        })}
                                        name="name"
                                        placeholder="Name of business unit"
                                    />
                                    {errors.name && (
                                        <span className="error text-danger">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="code">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.code" />
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.code ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('code', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            ),
                                            pattern: {
                                                value: /^(?!\s+$)[a-zÀ-ȕ0-9(),  ]+$/gi,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.containSpecialCharacter'
                                                )
                                            },
                                            maxLength: {
                                                value: 7,
                                                message: TranslateHelper.getMessage(
                                                    'validation.error.max7characters'
                                                )
                                            }
                                        })}
                                        id="code"
                                        placeholder="Code of business unit"
                                    />
                                    {errors.code && (
                                        <span className="error text-danger">
                                            {errors.code.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="startedOn">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.startedOn" />
                                    </label>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                selected={value}
                                                onChange={onChange}
                                                locale={lang}
                                                customInput={<CustomInputDate />}
                                            />
                                        )}
                                        name="startedOn"
                                    />
                                    {errors.startedOn && (
                                        <span className="error text-danger">
                                            <FormattedMessage id="validation.error.select" />
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="street">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.street" />
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.street
                                                ? 'is-invalid form-control'
                                                : 'form-control'
                                        }
                                        {...register('street', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        })}
                                        id="street"
                                        placeholder="Lê Trung Nghĩa ..."
                                    />
                                    {errors.street && (
                                        <span className="error text-danger">
                                            {errors.street.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.city" />
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.city ? 'is-invalid form-control' : 'form-control'
                                        }
                                        {...register('city', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        })}
                                        id="city"
                                        placeholder="Ho Chi Minh city ..."
                                    />
                                    {errors.city && (
                                        <span className="error text-danger">
                                            {errors.city.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="latitude">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.location" />
                                    </label>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label htmlFor="latitude">
                                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.location.latitude" />
                                            </label>
                                            <input
                                                className={
                                                    errors.latitude ? 'is-invalid form-control' : 'form-control'
                                                }
                                                {...register('latitude', {
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                })}
                                                id="latitude"
                                                placeholder="Location latitude ..."
                                            />
                                            {errors.latitude && (
                                                <span className="error text-danger">
                                                    {errors.latitude.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="longtitude">
                                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.location.longtitude" />
                                            </label>
                                            <input
                                                className={
                                                    errors.longtitude ? 'is-invalid form-control' : 'form-control'
                                                }
                                                {...register('longtitude', {
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                })}
                                                id="longtitude"
                                                placeholder="Location longtitude ..."
                                            />
                                            {errors.longtitude && (
                                                <span className="error text-danger">
                                                    {errors.longtitude.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="country">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.country" />
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            errors.country
                                                ? 'is-invalid form-control'
                                                : 'form-control'
                                        }
                                        {...register('country', {
                                            required: TranslateHelper.getMessage(
                                                'validation.error.required'
                                            )
                                        })}
                                        id="country"
                                        placeholder="Vietnam ..."
                                    />
                                    {errors.country && (
                                        <span className="error text-danger">
                                            {errors.country.message}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="timezone">
                                        <FormattedMessage id="hrm.detail.organization.businessunit.form.label.timezone" />
                                    </label>
                                    <Controller
                                        control={control}
                                        name="timezone"
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...timezones}
                                                isClearable
                                                options={timezones}
                                                value={field.value}
                                                selected = {field.value}
                                                placeholder="Select timezone"
                                                onChange={(e) => field.onChange(e)}
                                            />
                                        )}
                                    />
                                    {errors.timezone && (
                                        <span className="error text-danger">
                                            <FormattedMessage id="validation.error.select" />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer justify-content-flex-end">
                                <Button
                                    type="button"
                                    ref={hideModalButtonRef}
                                    data-dismiss="modal"
                                    onClick={() => {
                                        clearErrors();
                                    }}
                                >
                                    <FormattedMessage id="button.name.common.close" />
                                </Button>
                                <Button
                                    type="submit"
                                    appearance='primary'
                                    disabled={ !isDirty }
                                >
                                    {isEdit ? <FormattedMessage id="button.name.common.update" /> : <FormattedMessage id="button.name.common.create" />}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-scrollX'>
                        <table id="business-units-list" className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.code" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.startedon" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.street" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.city" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.Country" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.timezone" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.action" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{renderBUTable(businessUnits)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                businessUnits?.totalPages > 1 &&
                    <ReactPagination
                        totalPages={businessUnits?.totalPages}
                        handlePageChange={(e, page) => fetchBU(page)}
                        pageNumber={businessUnits?.page}
                    />
            }
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(BusinessUnits);
