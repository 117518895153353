import './Navbar.css';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { ROLES } from '../../common/constants/user.constant';
import ModalLogin from './ModalLogin';

function HomeNavbar ({ dispatch, isLoggedIn, role, connection }) {
    const menuLinks = useMemo(
        () => [
            {
                label: 'Home',
                href: '#'
            },
            {
                label: 'Feature',
                href: '#feature'
            },
            {
                label: 'Product',
                href: '#product'
            },
            {
                label: 'Reviews',
                href: '#reviews'
            },
            {
                label: 'Docs',
                href: '#docs'
            }
        ],
        []
    );

    if (isLoggedIn && connection) {
        switch (role) {
            case ROLES.MANAGER:
            case ROLES.MEMBER:
            case ROLES.HR:
                return <Redirect to="/dashboard" />;
            case ROLES.PARTNERMANAGER:
            case ROLES.PARTNERMEMBER:
            case ROLES.PARTNEROWNER:
                return <Redirect to="/sandboxes/client-ids" />;
            case ROLES.GUEST:
                return <Redirect to="/projects" />;
            default:
                <Redirect to="/dashboard" />;
        }
    }

    if (!connection) {
        return <Redirect to="/no-connection" />;
    }

    useEffect(() => {
        // Transparent navbar when page is not scrolled over hero section
        const navbar = document.querySelector('.navbar');
        const navbarHeight = navbar.offsetHeight;
        const sectionHero = document.querySelector('section#hero');
        const OVER_SECTION_HERO_HEIGHT =
            sectionHero.offsetHeight - navbarHeight;
        let overSectionHero = false;
        const onScroll = () => {
            if (window.scrollY > OVER_SECTION_HERO_HEIGHT) {
                if (!overSectionHero) {
                    overSectionHero = true;
                    navbar.classList.remove('bg-transparent');
                    navbar.classList.add('purple');
                }
            } else {
                if (overSectionHero) {
                    overSectionHero = false;
                    navbar.classList.remove('purple');
                    navbar.classList.add('bg-transparent');
                }
            }
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <>
            <header className="navbar navbar-sticky navbar-expand-lg navbar-dark bg-transparent navbar-sticky-moved-up navbar-sticky-transitioned navbar-sticky-on ">
                <div className="container position-relative">
                    <a className="navbar-brand" href="/">
                        <img
                            className="navbar-brand-sticky"
                            src={'/images/logo-white.svg'}
                            alt="sticky brand-logo"
                            style={{ width: '123.5px', height: '40px' }}
                        />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-toggler"
                        aria-controls="navbar-toggler"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbar-toggler"
                    >
                        <ul className="navbar-nav" id="navbar-nav">
                            {menuLinks.map((menu, index) => (
                                <li key={index} className="nav-item">
                                    <a
                                        className="nav-link scroll"
                                        href={menu.href}
                                    >
                                        {menu.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <ModalLogin dispatch={dispatch}/>
                    </div>
                </div>
            </header>
        </>
    );
}

function mapStateToProps (state) {
    const { isLoggedIn, role } = state.auth;
    return {
        isLoggedIn,
        role
    };
}

export default connect(mapStateToProps)(HomeNavbar);
