import { useCallback, useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import ReactPagination from '../../../ReactPagination';
import hrService from '../../Services/hr.service';
import { connect } from 'react-redux';
import recruitmentService from '../../Services/recruitment.service';
import Skeleton from 'react-loading-skeleton';
import EmployeesLeaveFilter from './employeesLeaveFilter';
import ModalEmployees from './ModalEmployees';
import SwitchButton from '../../../common/SwitchButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function EmployeesLeave () {
    const defaultOptions = {
        id: undefined,
        lastname: undefined
    };
    const [filters, setFilters] = useState(defaultOptions);

    const [page, setPage] = useState(1);
    const { refetch, data, isLoading } = useService(hrService.getEmployees, { page, limit: 12, ...filters });
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const firstClear = useRef(false);

    const handlePageChange = useCallback((event, page) => {
        setPage(page);
    }, []);

    function cardLoading () {
        return (
            <tr>
                <td>
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <div>
                            <Skeleton height={'30px'} width={'30px'} circle={true}/>
                        </div>
                        <div>
                            <Skeleton width={'200px'}/>
                        </div>
                    </div>
                </td>
                <td className="table-leave"><center><Skeleton width={'50%'}/></center></td>
                <td className="table-leave"><center><Skeleton width={'50%'}/></center></td>
            </tr>

        );
    }

    const [isShowDisabled, setIsShowDisabled] = useState(false);
    const [dataFilter, setDataFilter] = useState();

    useEffect(() => {
        setDataFilter(data?.items?.filter(item => item.status === 'enable'));
    }, [data]);

    const handleClick = () => {
        if (!isShowDisabled) {
            setDataFilter(data.items);
        } else {
            setDataFilter(data.items.filter(item => item.status === 'enable'));
        }
        setIsShowDisabled(!isShowDisabled);
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, marginLeft: '30px' }} >
                <Grid container spacing={4}>
                    <Grid item xs={3}><EmployeesLeaveFilter setFilters={setFilters} refetch={refetch} setPage={setPage}/></Grid>
                    <Grid item xs={3}><SwitchButton onClick={handleClick} label={<FormattedMessage id="hrm.common.label.show.disabled.employee" />}/></Grid>
                </Grid>
            </Box>
            <ModalEmployees
                firstClear={firstClear}
                refetch={refetch}
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
            />
                <div className='card'>
                    <div className='card-body'>
                        <div className='table-scrollX'>
                            <table id="leave-request-list" className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="hrm.services.mydetail.table.column.name.name" /></th>
                                        <th className='head-table-leave'><FormattedMessage id="hrm.services.mydetail.table.column.name.Available Leaves" /></th>
                                        <th className='head-table-leave'><FormattedMessage id="hrm.services.mydetail.table.column.name.Total Day Leave" /></th>
                                    </tr>
                                </thead>
                {isLoading
                    ? (
                       <tbody style={{ width: '100%' }}>
                           {new Array(12).fill(null).map(cardLoading)}
                       </tbody>
                        )
                    : (
                            dataFilter?.map((employee, index) => {
                                return (
                                <tbody key={ index }>
                                    <tr>
                                            <td>
                                            <div className='d-flex' style={{ gap: '5px' }}>
                                            <img
                                                    onError={(e) => {
                                                        e.target.src = '/images/default-avatar.png';
                                                    }}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%'
                                                    }}
                                                    src={
                                                        employee?.picture
                                                            ? !String(employee?.picture).includes(
                                                                    'uploads'
                                                                )
                                                                    ? employee.picture
                                                                    : recruitmentService.buildFileURL(
                                                                        employee.picture
                                                                    )
                                                            : '/images/default-avatar.png'
                                                    }
                                                ></img>
                                            <div
                                                            className="employee-name"
                                                            style={{ width: '180%' }}
                                                            title={
                                                                employee.firstname +
                                                                ' ' +
                                                                employee.lastname
                                                            }
                                                        >
                                                            {employee.firstname} {employee.lastname}
                                                        </div>
                                            </div>
                                            </td>
                                            <td className="table-leave">{employee.availableLeaves}</td>
                                            <td className="table-leave">{employee.totalDayLeave}</td>
                                        </tr>
                                    </tbody>
                                );
                            })
                        )}
                    </table>
                </div>
            </div>
            </div>
            {data.totalPages > 1 && (
                <ReactPagination
                    totalPages={data.totalPages}
                    handlePageChange={handlePageChange}
                    pageNumber={page}
                />
            )}
        </>
    );
}

function mapStateToProps (state) {
    const { role, user } = state.auth;
    return {
        role,
        user
    };
}

export default connect(mapStateToProps)(EmployeesLeave);
