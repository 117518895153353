import { connect } from 'react-redux';
import TranslateHelper from '../../../helpers/TranslateHelper';
import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { useEffect, useLayoutEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import './DeviceList.css';
import { useForm } from 'react-hook-form';
import { useService } from '../../../helpers/serviceHelpers';
import deviceService from '../services/device.service';
import { FilterDevice } from './FilterDevice';
import { TableComponent } from './TableComponent';
import ModalDevice from './ModalDevice';
import { deviceType, offices, status } from '../../../constants/device.constant';
import ReactPagination from '../../ReactPagination';
function DeviceList ({ role, dispatch }) {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({});
    const { refetch, data: devices, isLoading } = useService(deviceService.getDevices, { page, ...filters });
    const [tableData, setTableData] = useState([]);
    useLayoutEffect(() => {
        document.title = TranslateHelper.getMessage('app.page.title.hrm');
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.device',
                    haslink: false,
                    path: '/device'
                }
            ]
        }));
        return () => {
            dispatch(clearBreadcrumb());
        };
    }, []);

    useEffect(() => {
        setTableData(devices);
    }, [filters, devices, page]);

    const hookForm = useForm({
        mode: 'onChange'
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        hookForm.reset();
    }, [isModalOpen, hookForm]);
    const handlePageChange = (e, page) => {
        setPage(page);
    };
    return (
        <>
            <div className='container-fluid'>
                <div>
                    <button className='add-btn' onClick={() => { showModal(); }}>
                        <AddIcon className='add' />
                        Thêm thiết bị
                    </button>
                </div>
                <FilterDevice
                    setFilters={setFilters}
                    filters={filters}
                    setPage={setPage}
                    refetch={refetch}
                    offices={offices}
                    deviceType={deviceType}
                    status={status} />
                <div className='mt-4'>
                    <div className='card'>
                        <div className='card-title'>
                            Danh sách trang thiết bị
                        </div>
                        <div className='card-body'>
                            {!isLoading && <TableComponent data={tableData} offices={offices} deviceType={deviceType} status={status} showModal={showModal} />}
                            <div className='d-flex align-items-end justify-content-end mt-4 pr-5'>
                                {tableData.totalPages > 1 && (
                                    <ReactPagination
                                        totalPages={tableData.totalPages}
                                        handlePageChange={handlePageChange}
                                        pageNumber={page}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDevice refetch={refetch} offices={offices} deviceType={deviceType} hookForm={hookForm} open={isModalOpen} setIsModalOpen={setIsModalOpen} handleCancel={handleCancel} />
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(DeviceList);
