import serviceHelpers from '../../../common/helpers/serviceHelpers';
import axiosService from '../../../common/services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';
class ActivityService {
    constructor () {
        serviceHelpers();
        this.getEmployeeDetailByUsername = this.getEmployeeDetailByUsername.bind(this);
    }

    createEmployee (payload) {
        return axiosService.post(API_URL + 'employees', payload).then(res => res.data.data);
    }

    getActivities (params) {
        return axiosService.get(API_URL + 'activities/user', {
            params
        }).then(res => res.data.data);
    }

    getAllActivities (params) {
        return axiosService.get(API_URL + 'activities', {
            params
        }).then(res => res.data.data);
    }

    getEmployeeById (id) {
        return axiosService.get(API_URL + 'employees/' + id).then(res => res.data.data);
    }

    getEmployeeDetailByUsername (username) {
        return this.getEmployees({ username }).then(data => data.items[0] || {})
            .then(({ id }) => this.getEmployeeById(id));
    }

    updateEmployee (id, payload) {
        return axiosService.patch(API_URL + 'employees/' + id, payload).then(res => res.data.data);
    }

    deleteEmployeeById (id) {
        return axiosService.delete(API_URL + 'employees/' + id).then(res => res.data.data);
    }
}

export default new ActivityService();
