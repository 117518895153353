import moment from 'moment';
import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Swal from 'sweetalert2';
import { useService } from '../../../../helpers/serviceHelpers';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import holidayService from '../../Services/holiday.service';
import HolidayForm from './HolidayForm';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';

function MyHoliday ({ role }) {
    const {
        data: holidays,
        refetch,
        isLoading
    } = useService(holidayService.getHoliday, {}, (res) => res.items);

    const [isNew, setIsNew] = useState(false);
    const [selectedHoliday, setSelectedHoliday] = useState(null);

    const handleDeleteHoliday = useCallback((holiday) => {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + holiday.holiday + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await holidayService.deleteHolidayById(holiday._id);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                    return refetch();
                } catch (e) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title:
                            TranslateHelper.getMessage('respond.message.delete.failed') || e.message
                    });
                }
            }
        });
    }, []);
    if (isLoading) {
        return <div></div>;
    } else {
        return (
            <>
                <Button
                    type="button"
                    appearance='primary'
                    className={`${role !== 1 && 'd-none'}`}
                    data-toggle="modal"
                    data-target="#modal-holiday"
                    onClick={() => {
                        setIsNew(true);
                    }}
                >
                    <FormattedMessage id="button.name.common.create" />
                </Button>
                <HolidayForm
                    isNew={isNew}
                    refetch={refetch}
                    setSelectedHoliday={setSelectedHoliday}
                    selectedHoliday={selectedHoliday}
                />
                <div className="py-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='table-scrollX'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <FormattedMessage id="HRM.general.label.name.holiday" />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage id="HRM.general.label.name.startDate" />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage id="HRM.general.label.name.endDate" />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage id="HRM.general.label.name.day" />
                                            </th>
                                            {role === 1 && (
                                                <th scope="col">
                                                    <FormattedMessage id="HRM.general.table.action" />
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holidays.map((holiday) => (
                                            <tr key={holiday.id}>
                                                <td>{holiday.holiday}</td>
                                                <td>
                                                    {moment(holiday.fromDate).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    )}
                                                </td>
                                                <td>
                                                    {moment(holiday.toDate).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    )}
                                                </td>
                                                <td>
                                                    {moment(holiday.toDate).diff(
                                                        moment(holiday.fromDate),
                                                        'days'
                                                    ) + 1}
                                                </td>
                                                {role === 1 && (
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            className="mr-2"
                                                            data-toggle="modal"
                                                            data-target="#modal-holiday"
                                                            onClick={() => {
                                                                setIsNew(false);
                                                                setSelectedHoliday(holiday);
                                                            }}
                                                            title={TranslateHelper.getMessage('button.name.common.edit')}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            onClick={() => handleDeleteHoliday(holiday)}
                                                        >
                                                            <i
                                                                className="far fa-trash-alt"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(MyHoliday);
