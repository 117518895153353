import { useState, useEffect, useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import issueService from '../project/services/issue.service';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IssueDetailForm from '../project/components/project-detail/issueDetailForm';
import TranslateHelper from '../../helpers/TranslateHelper';
import Editor from '../EdittorToolbar';
import recruitmentService from '../hrm/Services/recruitment.service';
import projectListService from '../project/services/project-list.service';
import MentionEditor from '../project/components/MentionEditor';
import { useLoadMoreService } from '../../helpers/serviceHelpers';
import IssueCommentBox from '../project/components/IssueCommentBox';
import { ISSUE_PRIORITY, PROJECT_ROLE } from '../../constants/project.constant';
import IssueDetailHistory from '../project/components/IssueDetailHistory';
import FileUpload from '../project/components/project-detail/FileUpload';
import { Chip, Tab, Tabs, Typography } from '@mui/material';
import CopyText from './CopyText';
import InlineEdit from '@atlaskit/inline-edit';
import Select from '@atlaskit/select';
import { xcss, Box } from '@atlaskit/primitives';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const renderPriority = (priority) => {
    switch (priority) {
        case ISSUE_PRIORITY.LOW:
            return <i title='Low' className="fas fa-angle-double-down text-primary"></i>;
        case ISSUE_PRIORITY.MEDIUM:
            return <i title='Medium' className="fas fa-equals text-warning"></i>;
        case ISSUE_PRIORITY.HIGH:
            return <i title='High' className="fas fa-angle-double-up text-danger"></i>;
        default:
            break;
    }
};

const renderRow = (data, projectId) => {
    return data.map((item) => {
        return (
            <tr key={item._id}>
                <td>{item.issueCode}</td>
                <td><Link target="_blank" style={{ color: '#4f96ac' }} to={{ pathname: `${projectId}/issues/${item.issueCode}`, state: { issueCode: item.issueCode, subject: item.subject } }}><p title={item.subject} style={{ textTransform: 'none' }}>{item.subject}</p></Link></td>
                <td>
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={(e) => { e.target.src = '/images/default-avatar.png'; }} src={item.assignTo?.picture ? (String(item.assignTo?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.assignTo?.picture) : item.assignTo?.picture) : '/images/default-avatar.png'} alt='user-image' />
                        <div title={(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'} className='employee-name'>{(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'}</div>
                    </div>
                </td>
                <td>{item.status?.name}</td>
                <td align='center'>
                    {renderPriority(item.priority)}
                </td>
                <td>{moment(item.createdAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                <td>{item.startDate && item.startDate && moment(item.startDate).format(TranslateHelper.getMessage('date.format'))}</td>
                <td>{item.dueDate && moment(item.dueDate).format(TranslateHelper.getMessage('date.format'))}</td>
                <td>{item.estimateTime}</td>
                <td>{item.actualTime}</td>
                <td>{moment(item.updatedAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                <td>
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={(e) => { e.target.src = '/images/default-avatar.png'; }} src={item.createdBy?.picture ? (String(item.createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.createdBy?.picture) : item.createdBy?.picture) : '/images/default-avatar.png'} alt='user-image' />
                        <div title={item.createdBy?.firstname + ' ' + item.createdBy?.lastname} className='employee-name'>{item.createdBy?.firstname} {item.createdBy?.lastname}</div>
                    </div>
                </td>
            </tr>
        );
    });
};

function CustomTabPanel (props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function IssueDetail ({ projectId, id, user, projectName, setRefetch, isPopup }) {
    const [issue, setIssue] = useState();
    const [onChange, setOnChange] = useState();
    const [mention, setMention] = useState();
    const editorRefCmt = useRef();
    const btnCommentRef = useRef();
    const initialEdit = { subject: false, description: false, comments: true, typing: false };
    const [isEdit, setIsEdit] = useState(initialEdit);
    const [isManager, setIsManager] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [issueHistory, setIssueHistory] = useState();
    const [projectData, setProjectData] = useState();
    const [tabPanel, setTabPanel] = useState(0);

    const modalCloseRef = useRef();
    const modalRef = useRef();

    const { handleSubmit, errors, register, setValue, control } = useForm({});
    const {
        isLoading: isFetchingComments,
        fetch: fetchComments,
        total: totalComments,
        nextPage: nextCommentsPage,
        items: comments
    } = useLoadMoreService(issueService.getComments, { id }, response => response.data.data);

    const getIssueInfo = useCallback(
        () => {
            id && issueService.getIssueDetail(id).then((res) => {
                if (res) {
                    setIssue(res[0], { comments: res[0]?.comments });
                    setValue('description', res[0]?.description);
                }
            });
        },
        [id]
    );

    const [issueOptions, setIssueOptions] = useState([{ value: null, label: '' }]);
    const getIssueTypes = useCallback(
        () => {
            issueService.getIssueTypes(projectId).then((data) => {
                const nextIssueOpt = data.map(issue => ({ value: issue._id, label: issue.issueType }));
                setIssueOptions(nextIssueOpt);
            });
        },
        []
    );

    useEffect(() => {
        getIssueInfo();
        getIssueTypes();
        setTabPanel(0);
    }, [id]);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsEdit(initialEdit);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    useOutsideAlerter(modalRef);

    const getCurrentContentDes = () => {
        setValue('description', issue.description);
    };

    const setDefaultValue = () => {
        if (issue) {
            setValue('subject', issue?.subject);
        }
    };

    const getProjectMember = useCallback(() => {
        projectListService.getOne(projectId).then(res => {
            setProjectData(res);
            const list = res.members.map(item => ({ id: item.user?._id, name: `${item.user?.firstname} ${item.user?.lastname}`, avatar: item.user?.picture ? (!String(item.user.picture).includes('uploads') ? item.user?.picture : recruitmentService.buildFileURL(item.user?.picture)) : window.location.origin + '/images/default-avatar.png' }));
            setMention(list);
            const userList = res.members.filter(item => item.user?._id === user?.userId);
            if (userList && (userList[0]?.role === PROJECT_ROLE.MANAGER || userList[0]?.role === PROJECT_ROLE.REPORTER)) {
                setIsManager(true);
            }
        });
    }, [id]);

    useEffect(getProjectMember, []);

    const onSubmit = (form) => {
        const payload = {};
        if (isEdit.subject) { payload.subject = form?.subject; }
        if (isEdit.description) {
            payload.description = onChange;
        }
        issueService.updateIssue(projectId, issue?.issueCode, payload).then(res => {
            setRefetch(true);
            setIsEdit(initialEdit);
            setIssue(prevState => ({ ...prevState, subject: res?.subject, description: res?.description }));
        });
    };

    const onShowHistoryIssueDetail = () => {
        setIsEdit(prevState => ({ ...initialEdit, history: true, comments: false }));
        setIsLoading(true);
        issueService.getIssueHistory(id).then(res => {
            setIssueHistory(res);
            setIsLoading(false);
        });
    };

    const onShowComments = () => {
        setIsEdit(prevState => ({ ...initialEdit, comments: true, history: false }));
        editorRefCmt?.current?.focusEditor();
        fetchComments(true);
    };

    const currentIssueType = issueOptions.find(item => item.value === issue?.issueTypeId?._id);

    const handleChangeIssueType = (selected) => {
        if (selected?.value) {
            const payload = {
                issueTypeId: selected.value
            };
            issueService.updateIssue(projectId, issue?.issueCode, payload).then((data) => {
                if (data) {
                    getIssueInfo();
                }
            });
        }
    };
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                modalCloseRef.current.click();
            }
        };
        window.addEventListener('keydown', handleEscKey);
        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, []);
    return (
        <div className="modal fade" id={`issueDetail-${id}`} ref={modalRef}>
            <div className="modal-dialog mw-100 w-75">
                <div className="modal-content">
                    <div className="modal-header justify-content-start">
                        <div className='flex-column' style={{ minWidth: '100px' }}>
                            <InlineEdit
                                hideActionButtons
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        isSearchable={false}
                                        {...fieldProps}
                                        defaultValue={currentIssueType}
                                        options={issueOptions}
                                        autoFocus
                                        openMenuOnFocus
                                        name='test'
                                        placeholder={'check'}
                                    />
                                )}
                                readView={() => (
                                    <Box xcss={xcss({
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: 0
                                    })}><Chip label={issue?.issueTypeId?.issueType} color='success' /></Box>
                                )}
                                onConfirm={value => handleChangeIssueType(value)}
                                readViewFitContainerWidth
                            /></div><div className='p-2'>
                            <Link
                                onClick={() => { modalCloseRef.current?.click(); }}
                                to={{ pathname: `${location.pathname}/issues/${id}`, state: { projectName, issueCode: issue?.issueCode, subject: issue?.subject } }}
                            >
                                <span style={{ opacity: '0.7', padding: '0 10px' }}>{issue?.issueCode}</span>
                            </Link></div><div>
                            <CopyText
                                text={`${issue?.issueCode} ${issue?.subject}`}
                                title={'Copy issue key and title'}
                            /></div>
                        <div style={{ marginLeft: 'auto' }}>
                            <button
                                type="button"
                                className="close"
                                ref={modalCloseRef}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span >&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowY: 'initial' }}>
                            <div className='form-horizontal container-fluid' style={{ height: '75vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {
                                    isEdit.subject === false
                                        ? <div className='text-break' onDoubleClick={() => { setIsEdit(prevState => ({ ...initialEdit, subject: true })); setDefaultValue(); }} style={{ cursor: 'text', padding: '0 10px', backgroundColor: 'aliceblue', fontWeight: '500', fontSize: '1.71429em' }}>{issue?.subject}</div>
                                        : <>
                                            <input
                                                autoFocus
                                                className={errors?.subject
                                                    ? 'is-invalid form-control p-2'
                                                    : 'form-control'}
                                                {...register('subject', {
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                })}
                                            />
                                            <div className='d-inline-block float-right mt-1'>
                                                <div className="btn-group pointer">
                                                    <button type='submit' className='btn btn-light btn-md mr-2'><span><i className="fa fa-check" ></i></span></button>
                                                    <div className='btn btn-light btn-md' onClick={() => { setIsEdit(prevState => ({ ...initialEdit, subject: false })); }}><span><i className="fa fa-times" ></i></span></div>
                                                </div>
                                            </div>
                                        </>
                                }
                                {errors?.subject && (
                                    <span className="error text-danger">
                                        {errors?.subject?.message}
                                    </span>
                                )}
                                <label className='mt-4 pl-2 mb-0 d-block'>{TranslateHelper.getMessage('popup.issue.label.description')}</label>
                                {
                                    isEdit.description === false
                                        ? <Controller
                                            name='description'
                                            control={control}
                                            render={({ field }) => (
                                                <div className='pl-2' onDoubleClick={() => { setIsEdit(prevState => ({ ...initialEdit, description: true })); }} style={{ cursor: 'text', opacity: '0.9' }} dangerouslySetInnerHTML={{ __html: issueService.markDownHTML(issue?.description?.replace(/<[^>k]+>/g, '').trim() !== '' ? issue?.description : TranslateHelper.getMessage('popup.issue.add.description.isBlank')) }} ></div>
                                            )}
                                        />
                                        : <>
                                            <div style={{ border: '1px solid #d3d9e6' }} className="ml-2">
                                                <Editor defaultValue={issue.description} onChange={setOnChange} toolbarId={'toolbar'} placeholder={'Write description here'} />
                                            </div>
                                            <div className="btn-group pointer d-block ml-2 mt-1 mb-2">
                                                <button type='submit' className='btn btn-primary btn-md mr-2'><span>{TranslateHelper.getMessage('button.name.common.save')}</span></button>
                                                <div className='btn btn-light btn-md' style={{ margin: '0 2px' }} onClick={() => { setIsEdit(prevState => ({ ...initialEdit, description: false })); getCurrentContentDes(); }}><span>{TranslateHelper.getMessage('button.name.common.cancel')}</span></div>
                                            </div>
                                        </>
                                }
                                <IssueDetailForm
                                    isManager={isManager}
                                    setRefetch={setRefetch}
                                    projectId={projectId}
                                    issueDetail={issue}
                                    issueId={id}
                                    isPopup={isPopup}
                                />
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs
                                        value={tabPanel}
                                        onChange={(e, val) => {
                                            setTabPanel(val);
                                        }}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab label="Comments" {...a11yProps(0)} />
                                        <Tab label="Subtask" {...a11yProps(1)} />
                                        <Tab label="Attachments" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel className="p-2" value={tabPanel} index={2}>
                                    <FileUpload
                                        projectId={projectId}
                                        files={issue?.files}
                                        issueId={id}
                                    />
                                </CustomTabPanel>
                                <CustomTabPanel className="p-2" value={tabPanel} index={1}>
                                    {issue?.childIssues?.length
                                        ? (<div className='card'>
                                            <div className='card-body'>
                                                <div className='issue-table-content' style={{ overflowX: 'auto' }}>
                                                    <table id="issues-list" className="table table-bordered table-hover">
                                                        <thead>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.issuecode" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.subject" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.assignee" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.status" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.priority" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.createdAt" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.startDate" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.dueDate" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.estimateHours" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.actualHours" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.updatedAt" /></th>
                                                            <th><FormattedMessage id="project.detail.issues.table.column.name.createdBy" /></th>
                                                        </thead>
                                                        <tbody>
                                                            {renderRow(issue?.childIssues, projectId)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>)
                                        : <div className="mt-3"><h4 className='px-2'><FormattedMessage id="project.detail.new.label.notavailable" /></h4></div>}
                                </CustomTabPanel>
                                <CustomTabPanel className="p-2" value={tabPanel} index={0}>
                                    <label className='mt-3 pl-2 mb-0 d-block'>{TranslateHelper.getMessage('popup.issue.label.activity')}</label>
                                    <div className='d-flex' style={{ alignItems: 'baseline' }}>
                                        <span className='mt-3 pl-2 mb-0 mt-2'>{TranslateHelper.getMessage('popup.issue.label.activity.show')}</span>
                                        <div ref={btnCommentRef} className={`btn ${!isEdit?.comments ? 'btn-light' : 'btn-secondary'} btn-sm ml-2`} onClick={() => onShowComments()}><span>{TranslateHelper.getMessage('popup.issue.button.activity.show.comment')}</span></div>
                                        <div className={`btn ${!isEdit.history ? 'btn-light' : 'btn-secondary'} btn-sm ml-2`} onClick={() => onShowHistoryIssueDetail()}><span>{TranslateHelper.getMessage('popup.issue.button.activity.show.history')}</span></div>
                                    </div>
                                    {
                                        isEdit?.comments
                                            ? <>
                                                {
                                                    isEdit.typing === true
                                                        ? (
                                                            <div>
                                                                {
                                                                    mention &&
                                                                    <MentionEditor
                                                                        isPopup={true}
                                                                        mentions={mention}
                                                                        setIsEdit={setIsEdit}
                                                                        isShowing={isEdit.typing}
                                                                        issueId={id}
                                                                        fetchIssueDetail={() => fetchComments(true)}
                                                                        userId={user.userId}
                                                                        userInfo={user}
                                                                        setRefetch={setRefetch}
                                                                    />
                                                                }
                                                            </div>
                                                            )
                                                        : <div
                                                            className='ml-2 mt-2 mb-2 p-2'
                                                            onClick={() => { setIsEdit(prevState => ({ ...initialEdit, typing: true })); }}
                                                            style={{ cursor: 'text', opacity: '0.8', border: '1px solid #d3d9e6' }}
                                                        >
                                                            {TranslateHelper.getMessage('popup.issue.add.comment.isBlank')}
                                                        </div>
                                                }
                                                {comments && comments.map((comment, index) => (
                                                    <IssueCommentBox key={index} dataComment={comment} issueAuthor={issue?.createdBy?._id} projectId={projectId} issueCode={issue?.issueCode} />
                                                ))}
                                                <div className='d-flex justify-content-center pt-3'>
                                                    {!isFetchingComments && !comments.length && <div className='pl-2'>No comment!</div>}
                                                    {isFetchingComments && <div className='pl-1'>{TranslateHelper.getMessage('project.label.name.fetching')}</div>}
                                                    {!isFetchingComments && nextCommentsPage && totalComments - comments.length > 0 &&
                                                        <button className='btn btn-outline-primary mt-3' onClick={() => fetchComments(false)}>{TranslateHelper.getMessage('button.name.common.viewRemaining')} {totalComments - comments.length} {TranslateHelper.getMessage('button.name.common.comments')}</button>
                                                    }
                                                </div>
                                            </>
                                            : (
                                                <IssueDetailHistory isLoading={isLoading} data={issueHistory} issueInfor={projectData} />
                                                )
                                    }
                                </CustomTabPanel>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
function mapStateToProps (state) {
    const { role, user } = state.auth;

    return { role, user };
}

export default connect(mapStateToProps)(IssueDetail);
