import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Setting from './components/Setting';

export const settingMenuLinks = [
    {
        name: 'menu.admin.name.setting',
        classNames: 'nav-item',
        to: '/settings',
        icon: (<i className="nav-icon fas fa-cogs"></i>),
        exact: true,
        role: [ROLES.MANAGER],
        key: 10
    }
];

const routes = [
    {
        path: '/settings',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <Setting/>);
        }
    }
];

export default routes;
