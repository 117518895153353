import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROLES } from '../constants/user.constant';
import SetStore from '../../../index.store';

const store = SetStore();
const state = store.getState();

class RouterMiddleware {
    routeToDisplay (middlewares = [], routeToVisit, directedTo = '', extra = {}) {
        const mware = {
            clientIdActived: (routeToVisit) => this.clientIdActived(routeToVisit),
            memberAccess: (routeToVisit) => this.memberAccess(routeToVisit),
            managerAccess: (routeToVisit) => this.managerAccess(routeToVisit),
            partnerOwnerAccess: (routeToVisit) => this.partnerOwnerAccess(routeToVisit),
            partnerManagerAccess: (routeToVisit) => this.partnerManagerAccess(routeToVisit),
            partnerMemberAccess: (routeToVisit) => this.partnerMemberAccess(routeToVisit),
            guestAccess: (routeToVisit) => this.guestAccess(routeToVisit)
        };
        let ret = null;
        let status = false;
        let routeObject = {};
        try {
            for (let i = 0; i < middlewares.length; i++) {
                ret = mware[middlewares[i]](routeToVisit, directedTo, extra);
                if (ret.status === true || (ret.status === false && middlewares[i] === 'clientIdActived')) {
                    status = true;
                    routeObject = ret.routeObject;
                }
            }
            if (status === true) {
                return routeObject;
            }
        } catch (e) {
        }
    }

    clientIdActived (component) {
        return (state.memberClientId.actived ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to={{ pathname: '/sandboxes/client-ids', state: { isRedirect: true } }} />));
    }

    memberAccess (component) {
        return (state.auth.isLoggedIn && (state.auth.role === ROLES.MEMBER || state.auth.role === ROLES.HR) ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/dashboard" />));
    }

    managerAccess (component) {
        return (state.auth.isLoggedIn && state.auth.role === ROLES.MANAGER ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/dashboard" />));
    }

    partnerOwnerAccess (component) {
        return (state.auth.isLoggedIn && state.auth.role === ROLES.PARTNEROWNER ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/sandboxes/client-ids" />));
    }

    partnerManagerAccess (component) {
        return (state.auth.isLoggedIn && state.auth.role === ROLES.PARTNERMANAGER ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/dashboard" />));
    }

    partnerMemberAccess (component) {
        return (state.auth.isLoggedIn && state.auth.role === ROLES.PARTNERMEMBER ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/sandboxes/client-ids" />));
    }

    guestAccess (component) {
        return (state.auth.isLoggedIn && state.auth.role === ROLES.GUEST ? this._getRouteReturn(true, component) : this._getRouteReturn(false, <Redirect to="/projects" />));
    }

    _getRouteReturn (status, routeObject) {
        return { status, routeObject };
    }
}

export default new RouterMiddleware();
