import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Activity from './components/Activity';
import TimelineIcon from '@mui/icons-material/Timeline';

export const activityMenuLinks = [
    {
        name: 'menu.manager.name.activity',
        classNames: 'nav-item',
        to: '/activities',
        icon: (<TimelineIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER, ROLES.MEMBER, ROLES.HR],
        key: 3
    }
];

const routes = [
    {
        path: '/activities',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS, PERMISSION_ACCESS.MEMBER_ACCESS], <Activity/>);
        }
    }
];

export default routes;
