import { useState, useRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import recruitmentService from '../../Services/recruitment.service';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import UpdateInterviewForm from './UpdateInterViewForm';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import ReactPagination from '../../../ReactPagination';
import CreateCVForm from './CreateCVForm';
import FilterRecruitment from './FilterRecruitment';
import CreateInterviewForm from './CreateInterviewForm';
import RecruitmentContext from '../../../../context/RecruitmentContext';
import Button from '@atlaskit/button';
import { Box, Checkbox } from '@mui/material';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import SkeletonLoad from '../../../../components/SkeletonComponent';

export default function CVManagement () {
    const openModal = useRef();
    const [filterOptions, setFilterOptions] = useState({
        team: undefined,
        candidateTitle: undefined,
        statusInterview: undefined
    });

    const { forTeams, interviewers } = useContext(RecruitmentContext);

    const [filters, setFilters] = useState({ });
    const [page, setPage] = useState(1);

    const memoizedFilter = useDeepCompareMemoize(filterOptions);

    const { data: interviews, refetch } = useService(
        recruitmentService.getInterviews,
        { ...filters, page },
        (res) => res
    );
    const { data: CV, refetch: refetchCV } = useService(recruitmentService.getInterviews, undefined, (data) =>
        data.items.map((item) => ({
            label: `${item.candidateName}`,
            value: item._id
        }))
    );

    const { data: provinces, isLoading } = useService(recruitmentService.getAllProvinces);

    const [CVSelect, setCVSelect] = useState(null);

    const handleOpenToWork = async (id, openToWork) => {
        const payload = {
            id,
            openToWork: !openToWork
        };
        try {
            await recruitmentService.updateInterview(payload);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
            refetch();
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };
    return (
        <div>
             <Button
                ref={openModal}
                type="button"
                appearance='primary'
                data-toggle="modal"
                data-target="#modal-CV"
                >
                <FormattedMessage id="button.name.common.create" />
            </Button>
            <CreateCVForm refetch={refetch} isNew={false} refetchCV={refetchCV} provinces={provinces}/>
            <div className="card mt-4">
                <FilterRecruitment filterOptions={filterOptions} setFilterOptions={setFilterOptions} setFilters={setFilters} memoizedFilter={memoizedFilter} forTeams={forTeams} setPage={setPage}/>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className="py-4">
                        <div className='table-scrollX mb-3'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.openToWork" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.title" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.team" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.email" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.phoneNumber" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.linkedin" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.skype" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.tab.name.currentCompany" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.status" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.location" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.HR.recruitment.label.note" />
                                        </th>
                                        <th scope="col">
                                            <FormattedMessage id="HRM.detail.recruitment.table.name.action" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading
                                        ? <tr>
                                            <td colSpan='13'><SkeletonLoad rows={3} columns={1} space={1} h={50}/></td>
                                        </tr>
                                        : interviews?.items?.length > 0
                                            ? interviews.items.map((interview) => (
                                                <tr key={interview._id}>
                                                <th scope="row">{interview.candidateName}</th>
                                                <td>
                                                    <Box className='d-flex justify-content-center'>
                                                        <Checkbox checked={interview.openToWork} onChange={() => handleOpenToWork(interview._id, interview.openToWork)}/>
                                                    </Box>
                                                </td>
                                                <td>
                                                    {
                                                        recruitmentService.titles.find(
                                                            (item) => item.value === interview.candidateTitle
                                                        )?.label
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        forTeams.find(
                                                            (item) => item.value === interview.forTeam
                                                        )?.label
                                                    }
                                                </td>
                                                <td>
                                                    {interview.candidateEmail}
                                                </td>
                                                <td>
                                                    {interview.candidateMobileNumber}
                                                </td>
                                                <td>
                                                    {interview.candidateLinkedin}
                                                </td>
                                                <td>
                                                    {interview.candidateSkype}
                                                </td>
                                                <td>
                                                    {interview.candidateCurrentCompany}
                                                </td>
                                                <td>
                                                    <Box sx={{ backgroundColor: recruitmentService.statues.find((item) => item.value === interview.statusInterview)?.color, textAlign: 'center', color: 'white', p: 1 }}>
                                                        {recruitmentService.statues.find((item) => item.value === interview.statusInterview)?.label}
                                                    </Box>
                                                </td>
                                                <td>
                                                    {provinces.find(
                                                        (item) => item.value === interview.candidateLocale
                                                    )?.label || ''}
                                                </td>
                                                <td>
                                                    <p>{interview.candidateNote}</p>
                                                </td>
                                                <td style={{ minWidth: '130px' }}>
                                                    <Button
                                                        data-toggle="modal"
                                                        data-target={`#modal-interview-${interview._id}`}
                                                        className="mr-2"
                                                        title={TranslateHelper.getMessage('button.name.common.edit')}
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </Button>
                                                    <Button
                                                        onClick={() => setCVSelect(interview)}
                                                        title='Create interview calendar'
                                                        data-toggle="modal"
                                                        data-target="#modal-employees"
                                                    >
                                                        <i className="far fa-plus-square" aria-hidden="true"></i>
                                                    </Button>
                                                    <UpdateInterviewForm
                                                        refetch={refetch}
                                                        interview={interview}
                                                        forTeams={forTeams}
                                                        interviewers={interviewers}
                                                        provinces={provinces}
                                                    />
                                                </td>
                                                </tr>
                                            ))
                                            : (
                                                <td className="text-center" colSpan="9">
                                                    <FormattedMessage id="message.no.data.available" />
                                                </td>
                                                )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            interviews?.totalPages > 1 &&
                                <ReactPagination
                                    totalPages={interviews?.totalPages}
                                    handlePageChange={(e, page) => setPage(page)}
                                    pageNumber={interviews?.page}

                                />
                        }
                        <CreateInterviewForm CVSelect={CVSelect} refetch={refetch} CV={CV} />
                    </div>
                </div>
            </div>
        </div>
    );
}
