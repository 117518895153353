import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PartnerDetail from './partner-detail/PartnerDetail';
import Partner from './Partner';

class PartnerManagement extends Component {
    render () {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}`} component={Partner} />
                <Route path={`${match.url}/:partnerId/`} component={PartnerDetail} />
            </Switch>
        );
    }
}

export default PartnerManagement;
