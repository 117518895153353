import React from 'react';
import SandboxManagement from './components/operation/SandboxManagement';
import RealmManagement from './components/realm/RealmManagement';
import ClientIdMangement from './components/clientid/ClientIdMangement';
import { ROLES, PERMISSION_ACCESS } from '../../constants/user.constant';
import RouterMiddleware from '../../middlewares/RouterMiddleware';
import InboxIcon from '@mui/icons-material/Inbox';
import StatisticSandbox from './components/dashboard/StatisticSandbox';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export const sandboxMenuLinks = [
    {
        name: 'menu.member.name.sandboxes',
        classNames: 'nav-item',
        to: '/sandboxes/',
        icon: (<InboxIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.PARTNERMEMBER, ROLES.MANAGER],
        key: 4,
        children: [
            {
                name: 'menu.member.name.sandbox.clientid',
                classNames: 'nav-item',
                to: '/sandboxes/client-ids',
                icon: (<MoreVertIcon fontSize='small' sx={{ color: 'white' }}/>),
                exact: false,
                role: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.PARTNERMEMBER, ROLES.MANAGER],
                key: 5
            },
            {
                name: 'menu.member.name.sandbox.sandboxes',
                classNames: 'nav-item',
                to: '/sandboxes/operations',
                icon: (<TableRowsIcon fontSize='small' sx={{ color: 'white' }}/>),
                exact: false,
                role: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.PARTNERMEMBER, ROLES.MANAGER],
                key: 6
            },
            {
                name: 'menu.member.name.sandbox.realm',
                classNames: 'nav-item',
                to: '/sandboxes/realms',
                icon: (<ViewCarouselIcon fontSize='small' sx={{ color: 'white' }}/>),
                exact: false,
                role: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.PARTNERMEMBER, ROLES.MANAGER],
                key: 7
            },
            {
                name: 'menu.member.name.sandbox.statistic',
                classNames: 'nav-item',
                to: '/sandboxes/statistic',
                icon: (<SsidChartIcon fontSize='small' sx={{ color: 'white' }}/>),
                exact: false,
                role: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.PARTNERMEMBER, ROLES.MANAGER],
                key: 8
            }
        ]
    }
];
const routes = [
    {
        path: '/sandboxes/client-ids',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([
                PERMISSION_ACCESS.PARTNEROWNER_ACCESS,
                PERMISSION_ACCESS.PARTNERMANAGER_ACCESS,
                PERMISSION_ACCESS.PARTNERMEMBER_ACCESS,
                PERMISSION_ACCESS.MANAGER_ACCESS
            ], <ClientIdMangement match={ match } />);
        }
    },
    {
        path: '/sandboxes/statistic',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([
                PERMISSION_ACCESS.PARTNEROWNER_ACCESS,
                PERMISSION_ACCESS.PARTNERMANAGER_ACCESS,
                PERMISSION_ACCESS.PARTNERMEMBER_ACCESS,
                PERMISSION_ACCESS.MANAGER_ACCESS
            ], <StatisticSandbox match={ match } />);
        }
    },
    {
        path: '/sandboxes/operations',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([
                PERMISSION_ACCESS.PARTNEROWNER_ACCESS,
                PERMISSION_ACCESS.PARTNERMANAGER_ACCESS,
                PERMISSION_ACCESS.PARTNERMEMBER_ACCESS,
                PERMISSION_ACCESS.MANAGER_ACCESS,
                PERMISSION_ACCESS.CLIENTID_ACTIVED
            ], <SandboxManagement match={ match } />);
        }
    },
    {
        path: '/sandboxes/realms',
        exact: false,
        main: ({ match }) => {
            return RouterMiddleware.routeToDisplay([
                PERMISSION_ACCESS.PARTNEROWNER_ACCESS,
                PERMISSION_ACCESS.PARTNERMANAGER_ACCESS,
                PERMISSION_ACCESS.MANAGER_ACCESS,
                PERMISSION_ACCESS.CLIENTID_ACTIVED
            ], <RealmManagement match={match} />);
        }
    }
];
export default routes;
