import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class BussinessUnitsService {
    constructor () {
        serviceHelpers();
    }

    getBusinessUnits () {
        return axiosService.get(API_URL + 'businessUnits');
    }

    getOneBU (code) {
        const endpoint = `${API_URL}businessUnits/${code}`;
        return axiosService.get(endpoint).then(res => res.data.data);
    }

    createBusinessUnit (payload) {
        const endpoint = `${API_URL}businessUnits`;
        return axiosService.post(endpoint, payload).then(res => res.data.data);
    }

    editBusinessUnit (payload, isEdit) {
        const endpoint = `${API_URL}businessUnits/${isEdit}`;
        return axiosService.patch(endpoint, payload).then(res => res.data.data);
    }

    deteleBusinessUnit (code) {
        const endpoint = `${API_URL}businessUnits/${code}`;
        return axiosService.delete(endpoint).then(res => res.data.data);
    }
}

export default new BussinessUnitsService();
