import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import hierarchyService from './services/hierarchy.service';
import { useService } from '../../../../helpers/serviceHelpers';

function OrganizationChart () {
    const { data: hierarchy } = useService(hierarchyService.getDepartmentHierarchy);
    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 156) + 100;
        const g = Math.floor(Math.random() * 156) + 100;
        const b = Math.floor(Math.random() * 156) + 100;
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };
    const renderNode = children => {
        return children?.map(data => {
            const color = getRandomColor();
            return (
                <TreeNode
                    key={data._id}
                    label={
                        <div className="department-node mb-4" style={{ backgroundColor: color }}>
                            <div className='department-wrapper'>
                                <h6 title={data.name} className="department-title">{data.name}</h6>
                                {data?.departmentHead && <span title={data.departmentHead?.firstname + ' ' + data.departmentHead?.lastname} className="head-name">Head: {data.departmentHead?.firstname} {data.departmentHead?.lastname}</span>}
                            </div>
                        </div>
                    }
                >
                    {data?.children?.length !== 0 ? renderNode(data.children) : null}
                </TreeNode>
            );
        });
    };

    const renderhierarchyTree = (hierarchy) => {
        return hierarchy?.code && [hierarchy]?.map(item => {
            return (
                <Tree
                    key={item._id}
                    lineColor={'black'}
                    lineBorderRadius={'0px'}
                    lineWidth='2px'
                    label={
                        <div className="department-node my-4 parent-department">
                            <div className='department-wrapper'>
                                <h5 title={item.name} className="department-title">{item.name}</h5>
                            </div>
                        </div>
                    }
                    >
                    {item.children?.length !== 0 ? renderNode(item.children) : null}
                </Tree>
            );
        });
    };

    return (
        <div className='hierarchy-table container-fluid bg-white mb-3' style={{ overflowX: 'auto', scrollbarWidth: 'auto' }}>
            {hierarchy ? renderhierarchyTree(hierarchy) : null}
        </div>
    );
}

export default OrganizationChart;
