import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TranslateHelper from '../../../helpers/TranslateHelper';
import { setBreadcrumb } from '../../../actions/breadcrumb.action';
import { Link } from 'react-router-dom';
import projectListService from '../services/project-list.service';
import ReactPagination from '../../ReactPagination';
import Button from '@atlaskit/button';
import Default from '../images/default.jpg';
import recruitmentService from '../../hrm/Services/recruitment.service';
import { PROJECT_STATUS } from '../../../constants/project.constant';
import Select from '@atlaskit/select';
import { ROLES } from '../../../constants/user.constant';

const PROJECT_STATUS_OPTION = [
    {
        value: PROJECT_STATUS.IN_PROGRESS,
        label: 'In progress'
    },
    {
        value: PROJECT_STATUS.HOLDING,
        label: 'Holding'
    },
    {
        value: PROJECT_STATUS.COMPLETED,
        label: 'Completed'
    }
];

function ProjectList ({ role, dispatch, match }) {
    const [dataSet, setDataSet] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(false);

    useEffect(() => {
        document.title = TranslateHelper.getMessage('app.page.title.project');
        dispatch(setBreadcrumb({
            title: 'menu.member.name.project',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.project',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        localStorage.removeItem('projectFilter');
    }, []);

    const getListProjects = useCallback((page, status = undefined) => {
        projectListService.getAll(page, status).then((res) => {
            if (res.status === 200) {
                setDataSet(res.data.data);
                setLoadingStatus(true);
            }
        });
    }, []);

    const getListProjectsByOrg = (page, org, partnerId, status) => {
        projectListService.getProjectByOrg(page, org, partnerId, status).then((res) => {
            if (res.status === 200) {
                setDataSet(res.data.data);
                setLoadingStatus(true);
            }
        });
    };

    const [orgOptions, setOrgOptions] = useState([{
        value: '',
        label: ''
    }]);

    const [orgState, setOrgState] = useState({
        value: '',
        label: ''
    });

    useEffect(() => {
        const getOrgList = () => {
            projectListService.getAllOrg().then((res) => {
                if (res.status === 200) {
                    setOrgOptions(res.data.data);
                    const nextOrg = res.data.data[0];
                    setOrgState(nextOrg);
                    setLoadingStatus(true);
                }
            });
        };
        if (role === ROLES.MANAGER) {
            getOrgList();
        }
    }, []);

    useEffect(getListProjects, [getListProjects]);

    const countType = (type, id) => {
        const countTypes = dataSet.items.filter(item => item._id === id);
        return countTypes[0][type].length;
    };

    const renderProjects = (projectList) => {
        return projectList.map(item => {
            return (
                <div key={item._id} className="col-md-3">
                    <div className="card card-widget widget-user-2 shadow-sm">
                        <div id="bgColor" className='widget-user-header bg-project'>
                            <Link to={`${match.url}/${item.identifier}`} style={{ textDecoration: 'none', color: '#320c8a' }} >
                                <div className='circle-identifier'>
                                {item.files?.path
                                    ? <img src={item.files?.path ? recruitmentService.buildFileURL(item.files?.path) : Default} style={{ objectFit: 'cover', height: '65px', width: '65px' }}/>
                                    : <h5 style={{ color: '#050b7f' }} className='font-weight-bold center-identifier'>{item.identifier.toUpperCase()}</h5>
                                }
                                </div>
                                <h5 className="widget-user-username font-weight-bold" style={{ color: '#ffffff' }}>{item.name}</h5>
                            </Link>
                            <div id={item._id} className="widget-user-desc des-modify">{item.description}</div>
                            {item.description.length >= 49
                                ? (<div className='btn-group pointer d-flex justify-content-end'>
                                        <span title='Read more' data-toggle="dropdown" aria-expanded="true"><i className="nav-icon fas fa-ellipsis-h"></i></span>
                                        <ul className="dropdown-menu dropdown-menu-right p-3" style={{ border: 'none', borderRadius: '10px' }}>
                                            <div title={item.description}>{item.description}</div>
                                        </ul>
                                    </div>)
                                : null}
                        </div>
                        <Link to={`${match.url}/${item.identifier}`} style={{ textDecoration: 'none', color: '#477c74' }} >
                            <div className="card-footer p-0">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            Members <span className="float-right badge bg-primary">{countType('members', item._id)}</span>
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            Issues <span className="float-right badge bg-info">{countType('issues', item._id)}</span>
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            Milestones <span className="float-right badge bg-success">{countType('milestones', item._id)}</span>
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link">
                                            Status {item.status === PROJECT_STATUS.IN_PROGRESS ? <span className="float-right badge" style={{ backgroundColor: '#007bff8a', color: '#ffffff' }}>In-progress</span> : null}
                                                    {item.status === PROJECT_STATUS.HOLDING ? <span className="float-right badge" style={{ backgroundColor: '#ffc10778', color: '#ffffff' }}>Holding</span> : null}
                                                    {item.status === PROJECT_STATUS.COMPLETED ? <span className="float-right badge" style={{ backgroundColor: '#28a74587', color: '#ffffff' }}>Completed</span> : null}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        });
    };

    const updateProjByOrg = selected => {
        const nextOption = { ...selected };
        setLoadingStatus(false);
        setDataSet([]);
        if (role === ROLES.MANAGER) {
            getListProjectsByOrg(1, selected?.org, selected?.value, status?.value);
        } else {
            getListProjects(1, status?.value);
        }
        setOrgState(nextOption);
    };

    const updateProjByStatus = selected => {
        const nextStatus = { ...selected };
        setLoadingStatus(false);
        setDataSet([]);
        if (role === ROLES.MANAGER) {
            getListProjectsByOrg(1, orgState?.org, orgState?.value, selected?.value);
        } else {
            getListProjects(1, selected?.value);
        }
        setStatus(nextStatus);
    };
    const [status, setStatus] = useState({
        value: '',
        label: ''
    });
    const projectList = ((list) => {
        if (!list?.items?.length) {
            return [];
        }
        if (role !== ROLES.MANAGER) {
            return list.items;
        }
        if (!status.value) {
            return list.items;
        }
        const res = list.items.filter(ite => ite.status === status.value);
        return res;
    })(dataSet);

    return (
        <div className="container-fluid">
            <div className="d-flex">
                {(role === ROLES.PARTNEROWNER || role === ROLES.MANAGER) &&
                    <Link className='text-decoration-none' to={`${match.url}/create`} >
                        <Button appearance='primary'>{TranslateHelper.getMessage('button.name.common.create')}</Button>
                    </Link>
                }
                {role === ROLES.MANAGER && (
                    <div className='col-4 col-xl-2'>
                    <Select
                        options={orgOptions}
                        value={orgState}
                        onChange={updateProjByOrg}
                        placeholder='Search for organization'
                    />
                </div>
                )}
                <div className='col-4 col-xl-2'>
                    <Select
                        isClearable
                        options={PROJECT_STATUS_OPTION}
                        onChange={updateProjByStatus}
                        placeholder='Search for status'
                    />
                </div>
            </div>
            <div style={{ height: '20px' }}></div>
            <div className="row">
                {loadingStatus === true
                    ? projectList?.length > 0
                        ? renderProjects(projectList)
                        : (<p className='my-0 mx-auto text-xl'>Project unavailable...</p>)
                    : (<div className="fa-3x my-0 mx-auto">
                            <i className="fa-solid fa-circle-notch fa-spin"></i>
                        </div>)}
            </div>
            { dataSet?.totalPages > 1 &&
                <ReactPagination
                    totalPages={dataSet?.totalPages}
                    handlePageChange={(e, page) => {
                        if (role === ROLES.MANAGER) {
                            getListProjectsByOrg(page, orgState?.org, orgState?.value, status?.value);
                        } else {
                            getListProjects(page, status?.value);
                        }
                    }}
                    pageNumber={ dataSet?.page }
                />
            }
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(ProjectList);
