import React, { useCallback, useEffect, useState } from 'react';
import { setBreadcrumb } from '../../../../common/actions/breadcrumb.action';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import partnerService from '../../services/partner.service';
import moment from 'moment';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import Select from '@atlaskit/select';
import StatictisChart from './StatictisChart';
import Swal from 'sweetalert2';
import { ToastTopEnd } from '../../../../common/helpers/ToastTimer';
import ReactPagination from '../../../../common/components/ReactPagination';
import CurrencyFormat from 'react-currency-format';

function PartnerDetail ({ match, dispatch }) {
    const [dataSets, setDataSets] = useState([]);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [clientIdInfo, setClientIdInfo] = useState();
    const [filters, setFilters] = useState({
        page: 1
    });
    const [totalPages, setTotalPages] = useState(0);
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.admin.name.partner.detail',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.admin.name.partner',
                    haslink: false,
                    path: '/partners'
                },
                {
                    label: 'menu.admin.name.partner.detail',
                    haslink: false,
                    path: '/partners'
                }
            ]
        }));
    }, [dispatch]);

    const fetchDataSets = useCallback(() => {
        partnerService.getDataPartnerDetail(match.params.partnerId).then((res) => {
            if (res.status === 200) {
                setDataSets(res.data.data);
                const listClientIds = res.data.data.clientids.map(item => ({ value: item.clientId, label: item.name }));
                setClientIds(listClientIds);
            }
        });
    }, []);

    const fetchTransactionHistory = useCallback(() => {
        partnerService.getTransactionHistory(match.params.partnerId, filters).then((res) => {
            if (res.status === 200) {
                setTransactionHistory(res.data.data);
                setTotalPages(res.data.data.totalPages);
            }
        });
    }, [filters]);

    const handlePageChange = useCallback((e, page) => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, []);

    useEffect(fetchTransactionHistory, [fetchTransactionHistory]);
    useEffect(fetchDataSets, [fetchDataSets]);

    const handleSelectOptionChange = useCallback((option) => {
        Swal.fire({
            title: TranslateHelper.getMessage('loading.html.common'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
                partnerService.getStatiscticClientId(match.params.partnerId, option.value)
                    .then((res) => {
                        Swal.close();
                        setClientIdInfo(res.data.data);
                    })
                    .catch((error) => {
                        Swal.close();
                        if (error.response.data) {
                            ToastTopEnd.fire({
                                icon: 'error',
                                title: TranslateHelper.getMessage('respond.message.failed')
                            });
                        }
                    });
            }
        });
    });

    const renderMemberList = (data) => {
        let isEnable;
        if (data.status === 'enable') {
            isEnable = true;
        }
        return data.members?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.firstname + ' ' + item?.lastname}</td>
                    <td>{item?.username}</td>
                    <td>{isEnable ? <span>enable</span> : <span>disable</span>}</td>
                </tr>
            );
        });
    };

    const getNextCharge = (startDate) => {
        startDate = new Date();
        if (startDate.getMonth() === 11) {
            return new Date(startDate.getFullYear() + 1, 0, 1);
        } else {
            return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        }
    };

    const renderSubscription = (data) => {
        return data.items?.map((item, index) => {
            return (
                <tr key={item?._id}>
                    <td>{index + 1}</td>
                    <td>{item.createdBy?.firstname} {item.createdBy?.lastname}</td>
                    <td>{item.createdBy?.username}</td>
                    <td>{moment(item?.createdAt).format(TranslateHelper.getMessage('date.format'))}</td>
                    <td>{moment(item?.startDate).format(TranslateHelper.getMessage('date.format'))}</td>
                    <td>{moment(getNextCharge(item?.startDate)).format(TranslateHelper.getMessage('date.format'))}</td>
                    <td><CurrencyFormat value={item.packageId?.price} displayType={'text'} thousandSeparator={true} prefix={TranslateHelper.getMessage('prefix.price')} /></td>
                    <td>{item.packageId?.name}</td>
                    {/* <td><button className='btn btn-outline-secondary py-1' title='View' data-toggle="modal" data-target={'#transactionDetail-' + item.createdBy?._id} aria-hidden="true" onClick={() => viewDetailTransactionHistory(item.createdBy?._id)}><i className="fa fa-eye"></i></button></td>
                    <div className="modal fade" id={'transactionDetail-' + item.createdBy?._id}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title"><FormattedMessage id='partner.detail.modal.name.transaction.detail'/></h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body"></div>
                                <div className="modal-footer justify-content-between"></div>
                            </div>
                        </div>
                    </div> */}
                </tr>
            );
        });
    };

    // const viewDetailTransactionHistory = id => {
    //     partnerService.getDataPartnerDetail(id).then((res) => {
    //         if (res.status === 200) {
    //             console.log(res);
    //         }
    //     });
    //     return (
    //         <div className="modal fade" id={'transactionDetail-' + id}>
    //             <div className="modal-dialog modal-lg">
    //                 <div className="modal-content">
    //                     <div className="modal-header">
    //                         <h4 className="modal-title"><FormattedMessage id='hrm.services.myleave.tabs.name.edit'/></h4>
    //                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
    //                             <span aria-hidden="true">&times;</span>
    //                         </button>
    //                     </div>
    //                     <div className="modal-body"></div>
    //                     <div className="modal-footer justify-content-between"></div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    return (
        <div className="container-fluid">
            <div style={{ height: '10px' }}></div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><FormattedMessage id="member.table.title" /></h3>
                        </div>
                        <div className="card-body">
                            {dataSets.members?.length !== 0
                                ? (<div className='table-scrollX'>
                                        <table id="client-ids-list" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id="member.table.column.name.id" /></th>
                                                <th><FormattedMessage id="member.table.column.name.name" /></th>
                                                <th><FormattedMessage id="member.table.column.name.email" /></th>
                                                <th><FormattedMessage id="member.table.column.name.status" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderMemberList(dataSets)}</tbody>
                                    </table>
                                </div>)
                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>) }
                        </div>
                    </div>
                </div>
            </div>
            {/* subscription */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><FormattedMessage id="subscription.table.transaction.history" /></h3>
                        </div>
                        <div className="card-body">
                            {dataSets.subscription?.length !== 0
                                ? (<div className='table-scrollX mb-3'>
                                        <table id="client-ids-list" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <th><FormattedMessage id="partner.table.column.name.name" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.email" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.invitedDate" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.previousChangeDate" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.nextChargeDate" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.cost" /></th>
                                                <th><FormattedMessage id="partner.table.column.name.currentPackage" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderSubscription(transactionHistory)}</tbody>
                                    </table>
                                </div>)
                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>) }
                                {totalPages > 1 && (
                                    <ReactPagination
                                        totalPages={totalPages}
                                        handlePageChange={handlePageChange}
                                        pageNumber={filters.page}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {/* statistics */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><FormattedMessage id="statistics.table.title" /></h3>
                        </div>
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-3'>
                                    <label>Client id:</label>
                                    <Select
                                        options={clientIds}
                                        onChange={handleSelectOptionChange}
                                    />
                                </div>
                            </div>
                            {/* all charts */}
                            {clientIdInfo ? <StatictisChart clientIdInfo={clientIdInfo} /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(PartnerDetail);
