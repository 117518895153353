import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects';

class ProjectList {
    constructor () {
        serviceHelpers();
    }

    getAll (page = 1, status) {
        let params = { page };
        if (parseInt(status)) {
            params = {
                ...params,
                status
            };
        }
        return axiosService.get(API_URL, { params });
    }

    getOne (id) {
        return axiosService.get(API_URL + '/' + id).then(response => response.data.data);
    }

    updateOne (id, data) {
        const endpoint = API_URL + '/' + id;
        return axiosService.patch(endpoint, data).then(response => response.data.data);
    }

    createProject (data) {
        const endpoint = API_URL;
        return axiosService.post(endpoint, data).then(response => response.data.data);
    }

    getAllOrg () {
        return axiosService.get(API_URL + '/org/list');
    }

    getProjectByOrg (page, organizationType, partnerId, status) {
        let params = { page, organizationType, partnerId };
        if (parseInt(status)) {
            params = {
                ...params,
                status
            };
        }
        return axiosService.get(API_URL + '/org/projects', { params });
    }
}

export default new ProjectList();
