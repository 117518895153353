import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

export default function SnackBarNotify ({ text, type, position, open, setOpen }) {
    const [openNoti, setOpenNoti] = useState(false);

    useEffect(() => {
        setOpenNoti(open);
    }, [open]);

    const handleCloseNoti = (e, reason) => {
        if (reason === 'clickaway') return;
        setOpenNoti(false);
        setOpen(false);
    };

    return (
        <Snackbar anchorOrigin={position} open={openNoti} autoHideDuration={3000} onClose={handleCloseNoti}>
            <Alert onClose={handleCloseNoti} severity={type} sx={{ width: '100%' }}>
                {text}
            </Alert>
        </Snackbar>
    );
}
