import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../constants/user.constant';
import RouterMiddleware from '../../middlewares/RouterMiddleware';
import HRM from './components/HRM';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const hrmMenuLinks = [
    {
        name: 'menu.manager.name.hrm',
        classNames: 'nav-item',
        to: '/hrm',
        icon: (<PeopleAltIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER, ROLES.MEMBER, ROLES.HR],
        key: 8
    }
];

const routes = [
    {
        path: '/hrm',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS, PERMISSION_ACCESS.MEMBER_ACCESS], <HRM/>);
        }
    }
];

export default routes;
