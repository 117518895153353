import React, { useCallback, useEffect, useState } from 'react';
import structureService from './services/structure.service';
import styled from 'styled-components';
import { Tree, TreeNode } from 'react-organizational-chart';

export default function Structure () {
    const [organizationStructure, setOrganizationStructure] = useState();

    const fetchOS = useCallback(() => {
        structureService.getOrganizationStructure().then(res => {
            setOrganizationStructure(res.data.data);
        });
    }, []);

    useEffect(fetchOS, [fetchOS]);

    const StyledRoot = styled.div`
        text-align: center;
        width: 150px;
        margin-top: 30px;
        min-height: 70px;
        padding: 5px;
        display: inline-block;
        border: 1px solid #419ac4;
        border-top: 15px solid #419ac4;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(241,240,240,1) 99%);
    `;

    const StyledNode = styled.div`
        text-align: center;
        width: 150px;
        min-height: 70px;
        padding: 5px;
        display: inline-block;
        border: 1px solid #EA7C2E;
        border-top: 15px solid #EA7C2E;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(241,240,240,1) 99%);
    `;

    const renderRoot = (data) => {
        return data.map(item => {
            return (
                <Tree
                    lineColor={'#419ac4'}
                    lineBorderRadius={'0px'}
                    lineWidth='2px'
                    key={item._id}
                    label={<StyledRoot>{item.name}</StyledRoot>}>
                    {renderNode(item.department)}
                </Tree>
            );
        });
    };

    const renderNode = (data) => {
        return data.map(item => {
            return (
                <TreeNode key={item._id} label={<StyledNode>{item.name}</StyledNode>}></TreeNode>
            );
        });
    };

    return (
        <div className='container-fluid bg-white py-3' style={{ overflowX: 'auto', scrollbarWidth: 'auto' }}>
            {organizationStructure ? renderRoot(organizationStructure) : null}
        </div>
    );
}
