import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CustomInputDate } from '../../../../components/common/CustomInputDatepicker';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import moment from 'moment';
import axiosService from '../../../../services/axios.service';
import leaverequestService from '../organization-detail/services/leaverequest.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import ReactPagination from '../../../ReactPagination';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import { ServiceConstants, LEAVE_STATUS } from '../../../common/constants/service.constants';
import socket from '../../../common/Socket';
import Button from '@atlaskit/button';
import Avatar from '@atlaskit/avatar';
import { ROLES } from '../../../../constants/user.constant';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/';

const defaultValues = {
    leavetype: { value: '', label: '' },
    fromdate: undefined,
    todate: undefined,
    approveOrreject: null,
    comment: ''
};

const handleRenderLeaveType = (type) => {
    if (type) {
        const render = ServiceConstants.LEAVE_TYPE_CONTRACT.find(ele => ele.value === parseInt(type));
        return render.label;
    }
    return '';
};

function EmployeeLeave ({ role, kimeiMembers, user }) {
    const hideButtonArr = [LEAVE_STATUS.CANCELED];
    const { handleSubmit, control, register, formState: { errors }, watch, setValue, getValues } = useForm({ defaultValues });
    const onSubmit = (data) => {
        const { fromdate, todate, status, employeeId } = data;
        leaverequestService.filterLeaveRequest(status?.value, fromdate, todate, employeeId?.value).then((res) => {
            setMyLeave(res.data.data);
        });
    };
    const hideModalButtonRef = useRef();
    const buttonSubmitRef = useRef();
    const onChangeStatus = (receiverId, oldStatus) => {
        if (watch('approveOrreject.value') !== '') {
            const payload = {
                status: parseInt(watch('approveOrreject.value')),
                reasonReject: watch('approveOrreject.value') !== LEAVE_STATUS.APPROVED ? watch('comment') : '',
                oldStatus
            };
            leaverequestService.updateLeaveRequest(watch('_idInview'), payload).then((res) => {
                ToastTopEnd.fire({
                    icon: 'success',
                    title: payload.status === LEAVE_STATUS.APPROVED ? TranslateHelper.getMessage('respond.message.approve.successfully') : payload.status === LEAVE_STATUS.REJECTED ? TranslateHelper.getMessage('respond.message.reject.successfully') : ''
                });
                socket.emit('sendNotification', {
                    markAll: 1,
                    receiverId
                });
                fetchLR(myLeave.page, getValues('status.value'), getValues('fromdate'), getValues('todate'));
            }).catch((error) => {
                if (error.response?.data) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: error.response?.data?.message
                    });
                }
            });
            hideModalButtonRef.current?.click();
        }
    };
    const lang = localStorage.getItem('lang');
    const [myLeave, setMyLeave] = useState([]);
    const [detail, setDetail] = useState([]);
    const fetchLR = useCallback((page = 1) => {
        try {
            axiosService.get(API_URL + 'hrm/services/leaves', { params: { page, fromDate: getValues('fromdate'), toDate: getValues('todate'), status: getValues('status.value'), id: getValues('employeeId.value') } }).then((res) => {
                if (res.status === 200) {
                    setMyLeave(res.data.data);
                }
            });
        } catch (error) {
            console.warn(error);
        }
    }, []);
    useEffect(fetchLR, [fetchLR]);

    const viewDetailLR = useCallback((id) => {
        leaverequestService.viewLR(id).then((res) => {
            setDetail(res.data.data);
            setValue('_idInview', id);
            setValue('comment', res.data.data?.reasonReject);
            setValue('approveOrreject', { label: res.data.data.status === LEAVE_STATUS.APPROVED ? 'Approved' : res.data.data.status === LEAVE_STATUS.REJECTED ? 'Rejected' : res.data.data.status === LEAVE_STATUS.CANCELED ? 'Canceled' : 'Pending', value: res.data.data.status === LEAVE_STATUS.APPROVED ? LEAVE_STATUS.APPROVED : res.data.data.status === LEAVE_STATUS.REJECTED ? LEAVE_STATUS.REJECTED : res.data.data.status === LEAVE_STATUS.CANCELED ? LEAVE_STATUS.CANCELED : LEAVE_STATUS.PENDING });
        });
    }, [setValue, setDetail]);

    const handleCancelLQ = useCallback((id, oldStatus) => {
        const payload = {
            status: LEAVE_STATUS.CANCELED,
            oldStatus
        };
        leaverequestService.updateLeaveRequest(id, payload).then(() => {
            ToastTopEnd.fire({
                icon: 'success',
                title: 'Update successful'
            });
            fetchLR(myLeave.page, getValues('status.value'), getValues('fromdate'), getValues('todate'));
        });
        hideModalButtonRef.current?.click();
    }, []);

    const [employees, setEmployees] = useState();

    const getAllEmployee = useCallback(() => {
        setEmployees(() => {
            return kimeiMembers.map(employee => ({ value: employee._id, label: `${employee.firstname} ${employee.lastname}`, image: employee.picture }));
        });
    }, [kimeiMembers]);

    useEffect(getAllEmployee, [getAllEmployee]);
    console.log(user);

    return (
        <>
            <div className="modal fade" id="modal-employeeleave">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><FormattedMessage id='hrm.services.myleave.tabs.name.view' /></h4>
                            <button type="button" ref={hideModalButtonRef} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.employee') + ': ' + detail?.createdBy?.firstname + ' ' + detail?.createdBy?.lastname}</p>
                            <p>{TranslateHelper.getMessage('hrm.services.myleave.view.label.leavetype') + ': ' + handleRenderLeaveType(detail?.leaveType)}</p>
                            <p className='text-break'> {TranslateHelper.getMessage('hrm.services.myleave.view.label.reason') + ': ' + detail?.reasonLeave}</p>
                            <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.from') + ': ' + moment(detail?.fromDate).format(TranslateHelper.getMessage('date.format'))}</p>
                            <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.to') + ': ' + moment(detail?.toDate).format(TranslateHelper.getMessage('date.format'))}</p>
                            {detail?.leaveType !== ServiceConstants.ValWFH && <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.leavefor') + ': ' +
                                (detail?.leaveFor === ServiceConstants.ValhalfAM
                                    ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValhalfAM)[0].label
                                    : detail?.leaveFor === ServiceConstants.ValFULLDATE
                                        ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValFULLDATE)[0].label
                                        : detail?.leaveFor === ServiceConstants.ValhalfPM
                                            ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValhalfPM)[0].label
                                            : '')}
                            </p>}
                            <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.linemanager') + ': ' + detail?.approveBy?.firstname + ' ' + detail?.approveBy?.lastname}</p>
                            {detail?.isAssigned && (
                                <p>{TranslateHelper.getMessage('hrm.services.myleave.view.label.assignedBy') + ': ' + detail?.approveBy?.firstname + ' ' + detail?.approveBy?.lastname}</p>
                            )}
                            <p> {TranslateHelper.getMessage('hrm.services.myleave.view.label.days') + `: ${detail?.leaveType === ServiceConstants.ValWFH ? detail?.totalWfhDays : detail.leaveType === ServiceConstants.ValSummerLeave ? detail?.totalSummerDayLeave : detail?.totalDayLeave}`}</p>
                            {(role === ROLES.HR || role === ROLES.MANAGER || detail?.approveBy?._id === user?.userId) && (
                                <div className='row'>
                                    <div className='col col-12'>
                                        <Controller
                                            name='approveOrreject'
                                            control={control}
                                            render={({ field }) => (
                                                <div className='form-group'>
                                                    <label>{TranslateHelper.getMessage('hrm.services.myleave.view.label.status')}</label>
                                                    <div className='d-flex'>
                                                        <Select
                                                            className='mr-3 w-25'
                                                            isDisabled={detail.status === LEAVE_STATUS.CANCELED || detail.status === LEAVE_STATUS.APPROVED}
                                                            {...field}
                                                            onChange={
                                                                ((e) => {
                                                                    field.onChange(e);
                                                                })
                                                            }
                                                            options={[
                                                                { value: LEAVE_STATUS.APPROVED, label: 'Approved' },
                                                                { value: LEAVE_STATUS.REJECTED, label: 'Rejected' }
                                                            ]}
                                                        />
                                                        {(role === ROLES.HR && detail.status === LEAVE_STATUS.APPROVED) && (
                                                            <Button
                                                                onClick={() => handleCancelLQ(detail._id, detail.status)}
                                                                appearance='danger'
                                                                className='align-self-center'>{TranslateHelper.getMessage('hrm.services.myleave.view.label.cancelLeavRequest')}</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {(role === ROLES.HR || role === ROLES.MANAGER || detail?.approveBy?._id === user?.userId) && (
                            <div
                                className={hideButtonArr.indexOf(detail.status) === -1 ? 'modal-footer justify-content-between' : 'd-none'}>
                                <Button
                                    onClick={() => onChangeStatus(detail.createdBy?._id, detail.status)}
                                    isDisabled={(String(watch('approveOrreject')?.value).toString() === String(detail.status).toString())}
                                    appearance='primary'><FormattedMessage id="button.name.common.save"/></Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* TODO: Pending feature: Update Leave/WFH request form of other person */}
            {/* <div className="modal fade" id="modal-editEmployeeleave">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><FormattedMessage id='hrm.services.myleave.tabs.name.edit'/></h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <LeaveRequest isEdit = 'true' details = {detail}/>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <Button
                                onClick={() => onChangeStatus(detail.createdBy?._id, detail.status)}appearance='primary'><FormattedMessage id="button.name.common.save" /></Button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)} className="form" id='formSearch'>
                        <div className="form-horizontal">
                            <div className="row justify-content-between">
                            {role === ROLES.MANAGER && (
                                    <div className='col-xl-3 col-6'>
                                        <div className='form-group row justify-content-center'>
                                            <div className='col-12'>
                                                <Controller
                                                    control={control}
                                                    name="employeeId"
                                                    render={({ field }) => (
                                                        <Controller
                                                            control={control}
                                                            name="employeeId"
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <Select
                                                                    {...register('employeeId')}
                                                                    isClearable
                                                                    onChange={(e) => { onChange(e); buttonSubmitRef?.current?.click(); }}
                                                                    value={value}
                                                                    options={employees}
                                                                    formatOptionLabel={employee => (
                                                                        <div className='user-option d-flex'>
                                                                            <Avatar src={employee.image ? (String(employee.image).includes('uploads') ? recruitmentService.buildFileURL(employee.image) : employee.image) : '/images/default-avatar.png'} />
                                                                            <span className='ml-2 align-self-center'>{employee.label}</span>
                                                                        </div>
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <span className='error text-danger'> {errors.fromdate?.message} </span>
                                            </div>
                                        </div>
                                    </div>
                            )}
                                <div className='col-xl-3 col-6'>
                                    <div className='form-group row justify-content-center'>
                                        <div className='col-12'>
                                            <Controller
                                                control={control}
                                                name="fromdate"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                        className="input"
                                                        isClearable
                                                        maxDate={watch('todate') && watch('todate')}
                                                        placeholderText={TranslateHelper.getMessage('hrm.services.myleave.placeholder.from')}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            buttonSubmitRef?.current.click();
                                                        }}
                                                        selected={field.value}
                                                        locale={lang}
                                                        customInput={<CustomInputDate />}
                                                    />
                                                )}
                                            />
                                            <span className='error text-danger'> {errors.fromdate?.message} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-6'>
                                    <div className='form-group row'>
                                        <div className='col-12'>
                                            <Controller
                                                name="todate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                        className="input"
                                                        isClearable
                                                        placeholderText={TranslateHelper.getMessage('hrm.services.myleave.placeholder.to')}
                                                        minDate={watch('fromdate') && watch('fromdate')}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            buttonSubmitRef?.current.click();
                                                        }}
                                                        selected={field.value}
                                                        value={field.value}
                                                        locale={lang}
                                                        customInput={<CustomInputDate />}
                                                    />
                                                )}
                                            />
                                            <span className='error text-danger'> {errors.todate?.message} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-8'>
                                    <div className='form-group row'>
                                        <div className='col-3' style={{ alignSelf: 'end' }}>
                                            <label><FormattedMessage id="hrm.services.myleave.label.status" /></label>
                                        </div>
                                        <div className='col-9' >
                                            <Controller
                                                control={control}
                                                name="status"
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        {...register('status')}
                                                        isClearable
                                                        onChange={(e) => { onChange(e); buttonSubmitRef?.current?.click(); }}
                                                        value={value}
                                                        options={[
                                                            { value: LEAVE_STATUS.PENDING, label: 'Pending' },
                                                            { value: LEAVE_STATUS.APPROVED, label: 'Approved' },
                                                            { value: LEAVE_STATUS.REJECTED, label: 'Rejected' },
                                                            { value: LEAVE_STATUS.CANCELED, label: 'Canceled' }
                                                        ]}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-1 col-1' style={{ display: 'none' }}>
                                    <Button type='submit' appearance='primary' ref={buttonSubmitRef}>{TranslateHelper.getMessage('button.name.common.search')}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div style={{ overflowX: 'auto', scrollbarWidth: 'auto' }}>
                        <table id="leave-request-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.name" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.leaveApprover" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.from" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.to" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.type" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.unpaidLeave" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.partDay" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.halfDay" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.days" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.created" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.status" /></th>
                                    <th><FormattedMessage id="hrm.services.mydetail.table.column.name.action" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {myLeave.items && myLeave.items.map((leave) => {
                                    return (
                                        <tr key={leave._id}>
                                            <td>
                                                <div className='d-flex' style={{ gap: '5px' }}>
                                                    <img width={'30'} height={'30'}
                                                         className='img-circle img-bordered-sm' onError={(e) => {
                                                             e.target.src = '/images/default-avatar.png';
                                                         }}
                                                         src={leave.createdBy?.picture ? (String(leave.createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(leave.createdBy?.picture) : leave.createdBy?.picture) : '/images/default-avatar.png'}
                                                         alt='user-image'/>
                                                    <div
                                                        title={(leave.createdBy?.firstname && leave.createdBy?.lastname) ? leave.createdBy?.firstname + ' ' + leave.createdBy?.lastname : null}
                                                        className='employee-name'>{(leave.createdBy?.firstname && leave.createdBy?.lastname) ? leave.createdBy?.firstname + ' ' + leave.createdBy?.lastname : null}</div>
                                                </div>
                                            </td>
                                            <td>{leave.approveBy && leave.approveBy.firstname + ' ' + leave.approveBy.lastname}</td>
                                            <td>{moment(leave?.fromDate).format(TranslateHelper.getMessage('date.format'))}</td>
                                            <td>{moment(leave?.toDate).format(TranslateHelper.getMessage('date.format'))}</td>
                                            <td>{handleRenderLeaveType(leave?.leaveType)}</td>
                                            <td>{leave?.leaveType === 3 && leave?.totalDayLeave}</td>
                                            <td>{leave.leaveType !== 5 && leave?.leaveType !== 3 && leave?.totalDayLeave === 0.25 && leave?.totalDayLeave}</td>
                                            <td>{leave.leaveType !== 5 && leave?.leaveType !== 3 && leave?.totalDayLeave === 0.5 && leave?.totalDayLeave}</td>
                                            <td>{leave?.leaveType === ServiceConstants.ValWFH ? leave?.totalWfhDays : leave?.leaveType === ServiceConstants.ValSummerLeave ? leave?.totalSummerDayLeave : (leave?.leaveType !== 3 && leave?.totalDayLeave >= 1 && leave?.totalDayLeave)}</td>
                                            <td>{moment(leave?.createdAt).format(TranslateHelper.getMessage('date.format'))}</td>
                                            <td>
                                                {leave.status === LEAVE_STATUS.PENDING ? 'Pending' : leave.status === LEAVE_STATUS.APPROVED ? 'Approved' : leave.status === LEAVE_STATUS.CANCELED ? 'Canceled' : 'Rejected'}
                                            </td>
                                            <td>
                                                <Button data-toggle="modal" data-target="#modal-employeeleave"
                                                        aria-hidden="true" onClick={() => viewDetailLR(leave._id)}><i
                                                    className="fa fa-eye"></i></Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                myLeave?.totalPages > 1 &&
                <ReactPagination
                    totalPages={myLeave?.totalPages}
                    handlePageChange={(e, page) => fetchLR(page)}
                    pageNumber={myLeave?.page}
                />
            }
        </>
    );
}
function mapStateToProps (state) {
    const { role } = state.auth;
    const { kimeiMembers } = state.hrm;
    const { user } = state.auth;
    return {
        role, kimeiMembers, user
    };
}

export default connect(mapStateToProps)(EmployeeLeave);
