import { ACTION_CLIENTID } from '../constants/action.constant';
import Cookies from 'js-cookie';

const initialState = {
    actived: Cookies.get('actived') ?? ''
};

const clientIdReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ACTION_CLIENTID.SET_CLIENTIDACTIVED:
            return {
                ...state,
                actived: payload
            };

        default:
            return state;
    }
};

export default clientIdReducer;
