import { connect } from 'react-redux';
import { useLayoutEffect, useCallback, useEffect, useState } from 'react';
import { clearBreadcrumb, setBreadcrumb } from '../../../../common/actions/breadcrumb.action';
import { useForm } from 'react-hook-form';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import { FormattedMessage } from 'react-intl';
import UserService from '../../../../common/services/auth.service';
import Button from '@atlaskit/button';
import { updateUser } from '../../../../common/actions/auth.action';
import SnackBarNotify from '../../../../common/components/common/SnackBarNotify';

function HRM ({ dispatch, user }) {
    const { register, handleSubmit, setValue, formState: { errors, isDirty } } = useForm({
        mode: 'onChange'
    });
    const [open, setOpen] = useState(false);
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.setting',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.setting',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            clearBreadcrumb();
        };
    }, [dispatch]);

    const getSettingIntegration = useCallback(() => {
        UserService.getSettingInfo().then(res => {
            setValue('limitDayLeave', res.data.data.settingsDefault['limit-day-leave']);
            setValue('limitDayWFH', res.data.data.settingsDefault['limit-day-wfh']);
        });
    }, []);

    useEffect(getSettingIntegration, [getSettingIntegration]);

    const saveLimitDaysToLeave = (formData) => {
        const payload = {
            limitDayLeave: formData.limitDayLeave,
            limitDayWFH: formData.limitDayWFH,
            firstname: user.firstname,
            lastname: user.lastname
        };
        UserService.saveSetting(payload).then(() => {
            const userData = {
                ...user,
                limitDaysLeave: formData.limitDayLeave,
                limitDayWFH: formData.limitDayWFH
            };
            dispatch(updateUser({ user: userData }));
            localStorage.setItem('user', JSON.stringify(userData));
            setOpen(true);
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(saveLimitDaysToLeave)}>
                <div className="row">
                    <div className='col-sm-12'>
                        <div className="card card-warning card-outline">
                            <a className="d-block w-100">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                    <FormattedMessage id="account.form.label.HRM"/>
                                    </h4>
                                </div>
                            </a>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="inputSlackId" className="col-xl-3 col-12 col-form-label ">Maximum days to leave</label>
                                    <div className="col-xl-3 col-12 pl-3">
                                        <input
                                            className={errors.limitDayLeave ? 'is-invalid form-control  p-2' : 'form-control'}
                                            {...register('limitDayLeave', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                validate: value => value > 0 || TranslateHelper.getMessage('validation.error.negativeNumber')
                                            })}
                                            type='number'
                                        />
                                        {errors.limitDayLeave && <span className='error text-danger'>{errors.limitDayLeave.message}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputSlackId" className="col-xl-3 col-12 col-form-label ">Maximum days to WFH per month</label>
                                    <div className="col-xl-3 col-12 pl-3">
                                        <input
                                            className={errors.limitDayWFH ? 'is-invalid form-control  p-2' : 'form-control'}
                                            {...register('limitDayWFH', {
                                                required: TranslateHelper.getMessage('validation.error.required'),
                                                validate: value => value > 0 || TranslateHelper.getMessage('validation.error.negativeNumber')
                                            })}
                                            type='number'
                                        />
                                        {errors.limitDayWFH && <span className='error text-danger'>{errors.limitDayWFH.message}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button type="submit" className='justify-content-center col-xl-2 col-4' appearance='primary' isDisabled={ !isDirty }><FormattedMessage id='button.name.common.save' /></Button>
            </form>
            <SnackBarNotify open={open} setOpen={setOpen} position={{ vertical: 'bottom', horizontal: 'left' }} text={TranslateHelper.getMessage('respond.message.update.successfully')} type='success'/>
        </>
    );
}

function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(HRM);
