import React, { useCallback, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import CSelect from '../../../../shared/CSelect';
import 'react-datepicker/dist/react-datepicker.css';
import { vi } from 'date-fns/locale';
import ReactDatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import deviceService from '../../services/device.service';
import { useHistory } from 'react-router-dom';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Button from '@atlaskit/button';
import ModalAction from './ModalAction';
import moment from 'moment';
import dayjs from 'dayjs';
import { ConfigProvider, DatePicker } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale('vi');
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
function DeviceInformation ({ employees, data, deviceType, offices, hookForm, actions }) {
    const history = useHistory();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [isModalActionOpen, setIsModalActionOpen] = useState({
        open: false,
        actionEl: ''
    });
    const actionForm = useForm({
        mode: 'onChange'
    });
    const handleAction = (value) => {
        if (value === '1') {
            handleUpdateAction({ action: value });
        } else {
            if (!isModalActionOpen.open) {
                actionForm.reset();
                setIsModalActionOpen({ open: true, actionEl: value });
            }
        }
    };
    const actionItems = data?.status === '1' ? actions.filter(action => action.value !== '1') : (data?.status === '2' ? actions.filter(action => action.value !== '2') : [actions[0]]);
    const handleUpdate = async (value) => {
        const { _id } = data;
        const updatedValues = {
            ...value,
            status: data.status,
            warranty_date_from: fromDate,
            warranty_date_to: toDate
        };
        try {
            await deviceService.updateDevice(_id, updatedValues)
                .then((res) => {
                    if (res.success) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.update.successed')
                        });
                    }
                });
            history.push('/device');
        } catch (error) {
            console.log(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };
    const handleUpdateAction = async (value) => {
        const payload = {
            ...value,
            status: value.action === '1' ? value.action : isModalActionOpen.actionEl
        };
        try {
            await deviceService.updateDeviceStatus(data._id, payload)
                .then((res) => {
                    if (res.success) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.update.successed')
                        });
                        closeModal();
                        history.push('/device');
                    }
                });
        } catch (error) {
            console.log(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };
    const closeModal = useCallback(() => {
        setIsModalActionOpen({
            open: false,
            actionEl: ''
        });
    }, []);
    const onChangeRangeDate = (dates) => {
        dates[0] ? setFromDate(dates[0]) : setFromDate(undefined);
        dates[1] ? setToDate(dates[1]) : setToDate(undefined);
    };
    useEffect(() => {
        setFromDate(data?.warranty_date_from);
        setToDate(data?.warranty_date_to);
    }, [data]);
    return (
        <div className='pl-3 pb-4 pr-4'>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel className='custom-label-action'>Hành động</InputLabel>
                <Select
                    className='custom-select-action'
                >
                    {
                        actionItems?.map((item, i) => {
                            return (
                                <MenuItem onClick={() => handleAction(item.value)} value={item.value} key={i}>{item.label}</MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
            <div className='pt-4'>
                <form onSubmit={hookForm.handleSubmit(handleUpdate)}>
                    <div className='form-row'>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label='Tên máy'
                                {...hookForm.register('name', { required: TranslateHelper.getMessage('validation.error.required') })}
                            />
                            {hookForm.formState.errors.name &&
                                <p className="error text-danger">
                                    {
                                        hookForm.formState.errors.name.message
                                    }
                                </p>}
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label='Mã'
                                disabled
                                {...hookForm.register('code', { required: TranslateHelper.getMessage('validation.error.required') })}
                            />
                            {hookForm.formState.errors.code &&
                                <p className="error text-danger">
                                    {
                                        hookForm.formState.errors.code.message
                                    }
                                </p>}
                        </div>

                        <div className='form-device'>
                            <Controller
                                control={hookForm.control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <CSelect customSelect='custom-select-device' minWidth={290} handleSelect={(e) => onChange(e.target.value)} value={value || ''} label='Loại thiết bị'>
                                            {deviceType.map((item, i) => (
                                                <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                            ))}
                                        </CSelect>
                                    );
                                }}
                                {...hookForm.register('type', { required: TranslateHelper.getMessage('validation.error.required') })}
                                name='type'
                            />
                            {hookForm.formState.errors.type &&
                                <p className='error text-danger'>
                                    {
                                        hookForm.formState.errors.type.message
                                    }
                                </p>}
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Tình trạng máy"
                                disabled
                                {...hookForm.register('status', { required: TranslateHelper.getMessage('validation.error.required') })}
                            />
                            {hookForm.formState.errors.code &&
                                <p className="error text-danger">
                                    {
                                        hookForm.formState.errors.status.message
                                    }
                                </p>}
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-device'>
                            <Controller
                                control={hookForm.control}
                                render={({ field: { onChange, value } }) => (
                                    <CSelect customSelect="custom-select-device" minWidth={290} handleSelect={(e) => onChange(e.target.value)} value={value || ''} label='Văn phòng'>
                                        {offices.map((item, i) => (
                                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                        ))}
                                    </CSelect>
                                )}
                                {...hookForm.register('office', { required: TranslateHelper.getMessage('validation.error.required') })}
                                name="office"
                            />
                            {hookForm.formState.errors.office &&
                                <p className="error text-danger">
                                    {
                                        hookForm.formState.errors.office.message
                                    }
                                </p>}
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label='Số hóa đơn'
                                {...hookForm.register('order_number', {})}
                            />
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                type='number'
                                label='Giá trị'
                                {...hookForm.register('price', {
                                    min: {
                                        value: 0,
                                        message: 'Price must be at least 0'
                                    },
                                    max: {
                                        value: 1000000000
                                    }
                                })}
                            />
                            {hookForm.formState.errors.price &&
                                <p className='error text-danger'>
                                    {
                                        hookForm.formState.errors.price.message
                                    }
                                </p>}
                        </div>
                        <div className='form-device wrapper'>
                            <label className='custom-label'>Ngày mua</label>
                            <Controller
                                name="buy_date"
                                control={hookForm.control}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        {...field}
                                        className='custom-datepicker'
                                        selected={field.value ? moment.utc(field.value).toDate() : null}
                                        onChange={(date) => field.onChange(date)}
                                        locale={vi}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                )}
                            />
                            <span className='custom-calendar'><CalendarMonthIcon /></span>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                disabled
                                label='Công ty bảo hành'
                                {...hookForm.register('warranty_company', {})}
                            />
                        </div>
                        <div className='form-device wrapper'>
                                <label className='custom-label'>Ngày bảo hành</label>
                                <ConfigProvider locale={vi}>
                                    <RangePicker
                                        style={{ width: '290px', height: '48px' }}
                                        size="large"
                                        onCalendarChange={dates => onChangeRangeDate(dates)}
                                        value={[dayjs(dayjs(fromDate).format('DD/MM/YYYY'), dateFormat), dayjs(dayjs(toDate).format('DD/MM/YYYY'), dateFormat)]}
                                        format={dateFormat}
                                    />
                                </ConfigProvider>
                            </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                disabled
                                label='Số lần bảo hành'
                                {...hookForm.register('warranty_times', {})}
                            />
                        </div>
                        <div className='form-device custom-textarea'>
                            <label className='custom-label'>Ghi chú cấu hình máy</label>
                            <textarea
                                {...hookForm.register('device_note', {})}
                                rows="5"
                                cols="38"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className='form-device custom-textarea'>
                            <label className='custom-label'>Ghi chú</label>
                            <textarea
                                {...hookForm.register('note', {})}
                                rows="5"
                                cols="38"
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Button type="submit" className='confirm-btn justify-content-center'>Xác nhận</Button>
                    </div>
                </form>
            </div>
            <ModalAction
                employees={employees}
                handleUpdateAction={handleUpdateAction}
                open={isModalActionOpen.open}
                closeModal={closeModal}
                actionEl={isModalActionOpen.actionEl}
                actionForm={actionForm} />
        </div>
    );
}

export default DeviceInformation;
