import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import recruitmentService from '../../Services/recruitment.service';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import Button from '@atlaskit/button';
import InputField from '../../../common/InputField';
import DatePicker from 'react-datepicker';
import { DOBCustomInput } from '../../../common/CustomInputDatepicker';
import moment from 'moment';
import SelectBox from '../../../common/SelectBox';

export default function UpdateInterviewForm ({ interview, refetch, forTeams, interviewers, provinces }) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm({ mode: 'onChange', defaultValues: { flag: true, id: '' } });

    const hidenModal = useRef();

    const handleUpdateInterview = useCallback(async (data) => {
        const formData = new FormData();
        formData.append('flag', true);
        for (const [key, value] of Object.entries(data)) {
            // [file]
            if (key === 'file') {
                if (!hiddenFileInputRef.current?.files[0]) continue;
                formData.append(key, hiddenFileInputRef.current?.files[0]);
                continue;
            }

            if (value && typeof value === 'object' && 'value' in value) {
                formData.append(key, value.value);
                continue;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === 'object' && 'value' in item) {
                        formData.append(key, item.value);
                        continue;
                    }
                }
                continue;
            }

            // value
            if (key === 'id') {
                formData.append('id', interview._id);
                continue;
            }
            if (typeof value === 'string') {
                formData.append(key, value);
                continue;
            }

            if (key === 'candidateBirthday') {
                formData.append(key, value);
            }
        }
        try {
            await recruitmentService.updateInterview(formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successfully')
            });
            refetch();
            hidenModal.current?.click();
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    });

    const hiddenFileInputRef = useRef();
    const [file, setFile] = useState(null);
    const handleClick = event => {
        hiddenFileInputRef.current.click();
    };

    const handleOnChange = e => {
        setFile(e.target?.files[0]);
    };

    useEffect(() => {
        setValue('statusInterview', interview?.statusInterview ? recruitmentService?.statues.find((item) => item.value === interview?.statusInterview) : { value: 1, label: 'Pending' });
        setValue('forTeam', forTeams.find((item) => item.value === interview?.forTeam));
        setValue('candidateEmail', interview?.candidateEmail);
        setValue('candidateMobileNumber', interview?.candidateMobileNumber);
        setValue('candidateNote', interview?.candidateNote);
        setValue('candidateLinkedin', interview?.candidateLinkedin);
        setValue('candidateSkype', interview?.candidateSkype);
        setValue('candidateCurrentCompany', interview?.candidateCurrentCompany);
        setValue('candidateLocale', provinces?.find(item => item.value === interview?.candidateLocale));
        setValue('source', interview?.source);
        if (interview?.candidateBirthday) { setValue('candidateBirthday', new Date(moment(interview?.candidateBirthday).format(TranslateHelper.getMessage('date.format.en')))); }
    }, [interview, provinces]);

    return (
        <div
            className="modal fade"
            id={`modal-interview-${interview?._id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal-interview"
            aria-hidden="true"
        >
            <div className="d-flex justify-content-center align-items-center modal-dialog modal-lg modal-scrollable" id="interview-modal">
                <div className="modal-content">
                    <div className="modal-header text-uppercase">
                        <h4 className="modal-title ml-4">
                            <FormattedMessage id="HRM.detail.recruitment.title.updateCV" />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={hidenModal}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-5 ">
                        <form onSubmit={handleSubmit(handleUpdateInterview)}>
                            <div className='card'>
                                <div className='card-header row mx-1'>
                                    <div className='card-title'>
                                        <h3>{interview?.candidateName}&nbsp;&nbsp;<span className='title-level'>{recruitmentService.titles.find(item => item.value === interview?.candidateTitle)?.label}</span></h3>
                                        {
                                            recruitmentService.gender.find(
                                                (item) => item.value === interview?.candidateGender
                                            )?.label
                                        }<br/>
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    ref={ref}
                                                    selected={value}
                                                    onChange={onChange}
                                                    className="form-control"
                                                    placeholderText={TranslateHelper.getMessage('HRM.detail.HR.official.label.birth')}
                                                    popperPlacement="right-end"
                                                    css={{ zIndex: 99999 }}
                                                    customInput={<DOBCustomInput/>}
                                                    dateFormat={TranslateHelper.getMessage('date.format.datepicker')}
                                                />
                                            )}
                                            name="candidateBirthday"
                                        />
                                    </div>
                                    <div className='ml-3 w-50'>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage(
                                                    'validation.error.required'
                                                )
                                            }}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <SelectBox
                                                    value={value}
                                                    data={recruitmentService.statues}
                                                    onChange={onChange}
                                                    label={<FormattedMessage id="HRM.detail.recruitment.table.name.status" />}
                                                    error={errors.statusInterview}
                                                />
                                            )}
                                            name="statusInterview"
                                        />
                                    </div>
                                </div>
                                <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 col-xl-6'>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='candidateEmail'
                                                            control={control}
                                                            rules={{
                                                                required: TranslateHelper.getMessage(
                                                                    'validation.error.required'
                                                                )
                                                            }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.official.label.email" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateEmail}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='candidateMobileNumber'
                                                            control={control}
                                                            rules={{
                                                                required: TranslateHelper.getMessage(
                                                                    'validation.error.required'
                                                                ),
                                                                pattern: {
                                                                    value: /^(?!\s+$)[0-9(),  ]+$/gi,
                                                                    message: TranslateHelper.getMessage(
                                                                        'validation.error.numberic'
                                                                    )
                                                                },
                                                                maxLength: {
                                                                    value: 10,
                                                                    message: TranslateHelper.getMessage(
                                                                        'validation.error.notMobilePhone'
                                                                    )
                                                                },
                                                                minLength: {
                                                                    value: 9,
                                                                    message: TranslateHelper.getMessage(
                                                                        'validation.error.notMobilePhone'
                                                                    )
                                                                }
                                                            }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.official.label.mobile" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateMobileNumber}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='candidateLinkedin'
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.linkedin" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateLinkedin}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='candidateSkype'
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.skype" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateSkype}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            control={control}
                                                            rules={{
                                                                required: TranslateHelper.getMessage(
                                                                    'validation.error.required'
                                                                )
                                                            }}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <SelectBox
                                                                    value={value}
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.team" />}
                                                                    onChange={onChange}
                                                                    data={forTeams}
                                                                    error={errors.forTeam}
                                                                />
                                                            )}
                                                            name="forTeam"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6 col-xl-6'>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='candidateCurrentCompany'
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.currentCompany" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateCurrentCompany}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            control={control}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <SelectBox
                                                                    value={value}
                                                                    label={<FormattedMessage id="HRM.detail.recruitment.table.name.location" />}
                                                                    onChange={onChange}
                                                                    data={provinces}
                                                                    error={errors.candidateLocale}
                                                                />
                                                            )}
                                                            name="candidateLocale"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7">
                                                        <Controller
                                                            name='source'
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.source" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.source}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7 pl-3">
                                                        <div>
                                                            {interview?.fileCVsetting
                                                                ? (<a target="_blank" href={recruitmentService.buildFileURL(interview?.fileCVsetting.path)} rel="noreferrer"> {file?.name || interview.fileCVsetting.name}</a>)
                                                                : (<span className="text-muted">{<FormattedMessage id="HRM.detail.HR.recruitment.label.noCV" />}  </span>)}
                                                        <div >
                                                        </div>
                                                        <Button appearance='default' className='col-12 mt-3' onClick={handleClick}>{file?.name || TranslateHelper.getMessage('HRM.detail.HR.recruitment.label.chooseFile') }</Button>
                                                            <input
                                                                id="file"
                                                                type="file"
                                                                accept="application/pdf"
                                                                className='d-none'
                                                                {...register('file')}
                                                                ref={hiddenFileInputRef}
                                                                onChange={handleOnChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className="col-xl-12 col-7" htmlFor="interviewers">
                                                        <Controller
                                                            name='candidateNote'
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => (
                                                                <InputField
                                                                    label={<FormattedMessage id="HRM.detail.HR.recruitment.label.note" />}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    error={errors.candidateNote}
                                                                    multiline={true}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className=" d-flex w-100 justify-content-end px-4 mt-4">
                                        <Button className='col-xl-2 col-6 mr-2' data-dismiss='modal'>
                                            <FormattedMessage id="button.name.common.cancel" />
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="col-xl-2 col-6"
                                            appearance='primary'
                                        >
                                            <FormattedMessage id="button.name.common.update" />
                                        </Button>
                                    </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
    );
}
