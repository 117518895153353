import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Box } from '@mui/material';
const CSelect = ({ minWidth, value, label, handleSelect, children, props, customSelect }) => (
  <Box minWidth={minWidth}>
    <FormControl fullWidth className={customSelect} {...props}>
      <InputLabel id="label-select">{label}</InputLabel>
      <Select
        value={value}
        className='select-dropdown'
        onChange={handleSelect}
      >
        {children}
      </Select>
  </FormControl>
  </Box>
);
export default CSelect;
