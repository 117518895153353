import { ACTION_CLIENTID } from '../constants/action.constant';
/**
 * Set actived client id
 * @param {Object} payload
 * @returns Object
 */
export const setClientIdActived = (payload) => ({
    type: ACTION_CLIENTID.SET_CLIENTIDACTIVED,
    payload
});
