import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ManageAttendance from './components/ManageAttendance';

export const attendanceMenuLinks = [
    {
        name: 'menu.manager.name.attendance',
        classNames: 'nav-item',
        to: '/attendance',
        icon: (<HowToRegIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER, ROLES.MEMBER, ROLES.HR],
        key: 12
    }
];

const routes = [
    {
        path: '/attendance',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS, PERMISSION_ACCESS.MEMBER_ACCESS], <ManageAttendance/>);
        }
    }
];

export default routes;
