import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Employees from './Hr-detail/Employees';
import ListEmployeeLeave from './Hr-detail/ListEmployeeLeave';
import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { useLayoutEffect } from 'react';
import { ROLES } from '../../../constants/user.constant';
import { connect } from 'react-redux';
import { HrmProvider } from '../../../context/HrmContext';
import departmentService from './organization-detail/services/department.service';
import { useService } from '../../../helpers/serviceHelpers';

function HR ({ dispatch, role }) {
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.hrm',
                    haslink: true,
                    path: '/hrm'
                },
                {
                    label: 'app.breadcrumb.common.hrm.hr',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            clearBreadcrumb();
        };
    }, [dispatch]);

    const { data: departments } = useService(
        departmentService.getAllDepartment,
        { limit: 999 },
        (res) => res.data.data.items
    );

    return (
        <>
            <Tabs className="row">
                <TabList className='col-12 col-xl-2 d-flex flex-column'>
                    <Tab><FormattedMessage id='HRM.detail.HR.tab.name.employees' /></Tab>
                    {role === ROLES.MANAGER && (
                    <Tab><FormattedMessage id='HRM.detail.HR.tab.name.employeesleave' /></Tab>
                    )}
                </TabList>
                <div className="col-xl-10 col-12">
                    <HrmProvider value={departments}>
                        <TabPanel><Employees/></TabPanel>
                        <TabPanel><ListEmployeeLeave/></TabPanel>
                    </HrmProvider>
                </div>
            </Tabs>
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(HR);
