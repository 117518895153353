import { useEffect } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import TranslateHelper from '../../../../helpers/TranslateHelper';

export default function Gantt ({ tasks }) {
    useEffect(() => {
        gantt.clearAll();
        gantt.config.readonly = true;
        gantt.plugins({
            tooltip: true
        });
        gantt.templates.tooltip_text = function (start, end, task) {
            return task.text;
        };
        gantt.config.api_date = TranslateHelper.getMessage('api_date');
        gantt.config.xml_date = TranslateHelper.getMessage('xml_date');
        gantt.config.work_time = true;
        gantt.config.correct_work_time = true;
        gantt.config.date_grid = TranslateHelper.getMessage('date_grid');

        gantt.config.scales = [
            { unit: 'month', step: 1, format: '%F, %Y' },
            {
                unit: 'week',
                step: 1,
                format: function (date) {
                    return 'Week #' + gantt.date.getWeek(date);
                }
            },
            {
                unit: 'day',
                step: 1,
                format: '%D',
                css: function (date) {
                    if (!gantt.isWorkTime({ date, unit: 'day' })) {
                        return 'weekend';
                    }
                }
            }
        ];

        gantt.config.columns = [
            { name: 'key', width: 55, label: 'Key', align: 'center', tree: true },
            {
                name: 'text',
                label: 'Task name',
                align: 'left',
                width: 200
            },
            { name: 'start_date', label: 'Start time', align: 'center', width: 150 },
            { name: 'duration', label: 'Duration', align: 'center' }];
        gantt.init('gantt_here');
        gantt.parse(tasks);
    }, [tasks]);

    return (
        <div id="gantt_here" style={{ width: '100%', height: '100%' }}></div>
    );
}
