import DashboardRoutes, { dashboardMenuLinks } from './dashboard/routes';
import ActivityRoutes, { activityMenuLinks } from './activity/routes';
import HrmRoutes, { hrmMenuLinks } from '../common/components/hrm/routes';
import PackageRoutes, { packageMenuLinks } from './package/routes';
import ProjectRoutes, { projectMenuLinks } from '../common/components/project/routes';
import PartnerRoutes, { partnerMenuLinks } from './partner/routes';
import SandboxRoutes, { sandboxMenuLinks } from '../common/components/sandbox/routes';
import CronjobsRoute, { cronJobsMenuLinks } from './cronjobs/routes';
import AttendanceRoutes, { attendanceMenuLinks } from './attendance/routes';
import RecruitmentRoutes, { recruitmentMenuLinks } from './recruitment/routes';
import SettingRoute from './setting/routes';
import DeviceRoutes, { deviceMenuLinks } from '../common/components/device/routes';

export const managerMenuLinks = [
    ...dashboardMenuLinks,
    ...packageMenuLinks,
    ...partnerMenuLinks,
    ...activityMenuLinks,
    ...sandboxMenuLinks,
    ...hrmMenuLinks,
    ...deviceMenuLinks,
    ...recruitmentMenuLinks,
    ...projectMenuLinks,
    ...cronJobsMenuLinks,
    ...attendanceMenuLinks
];

const routes = [
    ...DashboardRoutes,
    ...PackageRoutes,
    ...PartnerRoutes,
    ...ActivityRoutes,
    ...SandboxRoutes,
    ...HrmRoutes,
    ...DeviceRoutes,
    ...RecruitmentRoutes,
    ...ProjectRoutes,
    ...SettingRoute,
    ...CronjobsRoute,
    ...AttendanceRoutes
];

export default routes;
