import React, { Component } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import SandboxService from '../../../services/sandbox.service';
import Swal from 'sweetalert2';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import ShowForPermission from '../../../../common/ShowForPermissionComponent';
import { withRouter } from 'react-router-dom';
import Button from '@atlaskit/button';
class GeneralSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            loadingButton: false,
            autoScheduled: null,
            autoScheduledPrev: null,
            differentFormInput: {
                autoScheduled: false
            },
            dataSet: null
        };
    }

    componentDidMount () {
        SandboxService.getSandboxId(this.props.sandboxId).then((res) => {
            this.setState({ dataSet: res.data.data, loadingStatus: true, loadingButton: true, autoScheduled: res.data.data.autoScheduled, autoScheduledPrev: res.data.data.autoScheduled });
        }).catch(() => {
            this.props.history.push({
                pathname: '/not-found',
                state: { isRedirect: true }
            });
        });
    }

    handleUpdateClick () {
        const payload = {
            autoScheduled: this.state.differentFormInput.autoScheduled
        };
        this.setState({ loadingButton: false });
        SandboxService.updateSandboxSchedule(this.props.sandboxId, payload).then((res) => {
            this.setState({ loadingButton: true });
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successed')
            });
        }).catch((err) => {
            this.setState({ loadingButton: true });
            if (err) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage('respond.message.update.failed')
                });
            }
        });
    }

    handleDeleteClick () {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete.sandbox.title') + this.state.dataSet.instance + '?',
            text: TranslateHelper.getMessage('alert.delete.sandbox.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: TranslateHelper.getMessage('alert.delete.sandbox.confirm'),
            cancelButtonText: TranslateHelper.getMessage('alert.delete.sandbox.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ loadingButton: false });
                const history = this.props.history;
                SandboxService.deleteSandbox(this.props.sandboxId).then((res) => {
                    history.goBack();
                }).catch((err) => {
                    if (err.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: TranslateHelper.getMessage('respond.message.operationsandbox.delete.failed')
                        });
                    }
                });
            }
        });
    }

    handleChangeInputForm (name, element) {
        const change = {};
        change[name] = element.target.value;
        this.setState(change);
        this.forceUpdate();
    }

    handleChangeInputFormUpdate (name, element) {
        this.setState(prev => ({
            differentFormInput: {
                ...prev.differentFormInput,
                autoScheduled: element.target.value
            }
        }));
    }

    render () {
        return (
            <div>
                {this.state.loadingStatus
                    ? (<div className="row">
                        <div className="col-12">
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                                <tbody>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                        <FormattedMessage id="sandbox.detail.general.realm"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                        {this.state.dataSet.realm}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.instance"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                        {this.state.dataSet.instance}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.url"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            <ul>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={this.state.dataSet.links.bm}>BM</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={this.state.dataSet.links.code}>Code</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={this.state.dataSet.links.impex}>Impex</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={this.state.dataSet.links.logs}>Logs</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={this.state.dataSet.links.ocapi}>Ocapi</a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.version"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                <tbody>
                                                <tr>
                                                    <td className="field-item-table" align="right">
                                                        <FormattedMessage id="sandbox.detail.general.app"/>:&nbsp;
                                                    </td>
                                                    <td className="field-detail-table" width="100%">
                                                        {this.state.dataSet.versions.app}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="field-item-table" align="right">
                                                        <FormattedMessage id="sandbox.detail.general.web"/>:&nbsp;
                                                    </td>
                                                    <td className="field-detail-table" width="100%">
                                                        {this.state.dataSet.versions.web}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.autoSchedule"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            <select style={{ width: '150px' }} className="form-control" value={this.state.differentFormInput.autoScheduled} onChange={this.handleChangeInputFormUpdate.bind(this, 'autoScheduled')}>
                                                <option value="true">{TranslateHelper.getMessage('option.common.yes')}</option>
                                                <option value="false">{TranslateHelper.getMessage('option.common.no')}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.state"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            {this.state.dataSet.state}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.createdAt"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            <span><FormattedDate
                                                value={this.state.dataSet.createdAt}
                                                year="numeric"
                                                month="numeric"
                                                day="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            /></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-item-table" align="right">
                                            <FormattedMessage id="sandbox.detail.general.createdBy"/>:&nbsp;
                                        </td>
                                        <td className="field-detail-table" width="100%">
                                            <span title={this.state.dataSet.createdBy} style={{ width: '100%' }} className='employee-name general-created-by'>{this.state.dataSet.createdBy}</span>
                                        </td>
                                    </tr>
                                    {this.state.dataSet.deletedAt
                                        ? (<tr>
                                            <td className="field-item-table" align="right">
                                                <FormattedMessage id="sandbox.detail.general.deletedAt"/>:&nbsp;
                                            </td>
                                            <td className="field-detail-table" width="100%">
                                                <span><FormattedDate
                                                    value={this.state.dataSet.deletedAt}
                                                    year="numeric"
                                                    month="numeric"
                                                    day="numeric"
                                                    hour="numeric"
                                                    minute="numeric"
                                                /></span>
                                            </td>
                                        </tr>)
                                        : (<></>)}
                                    {this.state.dataSet.deletedBy
                                        ? (<tr>
                                            <td className="field-item-table" align="right">
                                                <FormattedMessage id="sandbox.detail.general.deletedBy"/>:&nbsp;
                                            </td>
                                            <td className="field-detail-table" width="100%">
                                                <span>{this.state.dataSet.deletedBy}</span>
                                            </td>
                                        </tr>)
                                        : (<></>)}
                                    {this.state.loadingButton
                                        ? (<tr>
                                            <td className="field-item-table" align="right">
                                                <ShowForPermission permissionKey='deleteSandbox'>
                                                <Button onClick={() => { this.handleDeleteClick(); }} appearance='danger'><FormattedMessage id="button.name.common.delete"/></Button>
                                                </ShowForPermission>
                                            </td>
                                            <td width="100%">
                                                {this.state.differentFormInput.autoScheduled ? (<Button onClick={(e) => { this.handleUpdateClick(e); }} appearance='primary'><FormattedMessage id="button.name.common.update"/></Button>) : (<Button isDisabled onClick={() => { this.handleUpdateClick(); }} appearance='primary'><FormattedMessage id="button.name.common.update"/></Button>)}
                                            </td>
                                        </tr>)
                                        : (<tr><td align="right"><div className='pr-3'><i className="fas fa-2x fa-sync-alt fa-spin"></i></div></td></tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>)
                    : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
            </div>
        );
    }
}

export default withRouter(GeneralSbDetail);
