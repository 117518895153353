import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../constants/user.constant';
import RouterMiddleware from '../../middlewares/RouterMiddleware';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DeviceManagement from './components/DeviceManagement';

export const deviceMenuLinks = [
    {
        name: 'menu.manager.name.device',
        classNames: 'nav-item',
        to: '/device',
        icon: (<DevicesOtherIcon fontSize='medium' sx={{ color: 'white' }}/>),
        exact: false,
        role: [ROLES.MANAGER, ROLES.HR],
        key: 8
    }
];

const routes = [
    {
        path: '/device',
        exact: false,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <DeviceManagement />);
        }
    }
];

export default routes;
