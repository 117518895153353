import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Dashboard from './components/Dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const dashboardMenuLinks = [
    {
        name: 'menu.admin.name.dashboard',
        classNames: 'nav-item',
        to: '/dashboard',
        icon: (<DashboardIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER],
        key: 0
    }
];

const routes = [
    {
        path: '/dashboard',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <Dashboard/>);
        }
    },
    {
        path: '/',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <Dashboard/>);
        }
    }
];

export default routes;
