import DatePicker from 'react-datepicker';
import { CustomInputDate } from '../../../common/CustomInputDatepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import activitiesService from '../../services/activities.service';
import moment from 'moment';
import Button from '@atlaskit/button';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import ActivitiesComponent from '../../../common/Activities';

export default function Activities ({ projectId, setProjectTab, settingIndex }) {
    const lang = localStorage.getItem('lang');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState(new Date());

    // Change any field to refetch activities
    const [filters, setFilters] = useState({
        fromDate: undefined,
        toDate: moment().endOf('day').toISOString(),
        page: 1
    });

    const memoizedFilter = useDeepCompareMemoize(filters);

    const [data, setData] = useState({
        items: [],
        totalPages: 0,
        isLoading: true
    });

    const fetchActivities = useCallback(() => {
        setData((prevData) => ({ ...prevData, isLoading: true }));
        activitiesService
            .getActivities(
                projectId,
                memoizedFilter.page,
                memoizedFilter.fromDate,
                memoizedFilter.toDate
            )
            .then(({ items, totalPages }) => {
                setData((prevData) => ({ ...prevData, isLoading: false, items, totalPages }));
            })
            .catch(() => {
                setData((prevData) => ({ ...prevData, isLoading: false }));
            });
    }, [projectId, memoizedFilter]);

    const handlePageChange = useCallback((e, page) => {
        window.scrollTo(0, 0);
        setFilters((prevState) => ({ ...prevState, page }));
    }, []);

    const handleSearchClick = useCallback(() => {
        const fromDateSearch = fromDate ? moment(fromDate).startOf('day').toISOString() : undefined;
        const toDateSearch = moment(toDate).endOf('day').toISOString();
        setFilters({ fromDate: fromDateSearch, toDate: toDateSearch, page: 1 });
    }, [fromDate, toDate]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    return (
        <div className="container-fluid">
            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-xl-6 col-12">
                            <div className="row">
                                <div className="col-xl-5 col-6">
                                    <label>
                                        <FormattedMessage id="project.detail.activites.label.from" />
                                    </label>
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        selected={fromDate}
                                        onChange={setFromDate}
                                        locale={lang}
                                        customInput={<CustomInputDate />}
                                        maxDate={toDate}
                                    />
                                </div>
                                <div className="col-xl-5 col-6">
                                    <label>
                                        <FormattedMessage id="project.detail.activites.label.to" />
                                    </label>
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        selected={toDate}
                                        onChange={setToDate}
                                        locale={lang}
                                        customInput={<CustomInputDate />}
                                        minDate={fromDate}
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div className="col-xl-2 d-flex justify-content-end">
                                    <Button appearance='primary' className='search-btn-activities' onClick={handleSearchClick}><FormattedMessage id="button.name.common.search" /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ActivitiesComponent Activities={data.items} handlePageChange={handlePageChange} isLoading={data.isLoading} totalPages={data.totalPages} filters={filters} isFilter={true}/>
        </div>
    );
}
