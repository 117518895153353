import AxiosService from './axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class AuthService {
    loginByGoogle (tockenId, queryString = '') {
        return AxiosService.post(API_URL + '/users/google' + queryString, { token: tockenId });
    }

    loginByUsername (username, password) {
        return AxiosService.post(API_URL + '/users/login', { username, password });
    }

    logout () {
        localStorage.removeItem('user');
    }

    getSettingInfo () {
        return AxiosService.get(API_URL + '/users').then(res => res);
    }

    saveSetting (payload) {
        return AxiosService.patch(API_URL + '/users/setting', payload);
    }

    changeRole (role) {
        return AxiosService.patch(API_URL + '/users/change-role', { role });
    }

    checkHasPassword () {
        return AxiosService.get(API_URL + '/users/check-has-password');
    }

    changePassword (payload) {
        return AxiosService.post(API_URL + '/users/change-password', payload);
    }

    checkExpire () {
        return AxiosService.get(API_URL + '/subscriptions/expired').then(res => res.data.success);
    }
}

export default new AuthService();
