import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from '@atlaskit/select';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { ROLES, TITLE_GENDER } from '../../../../../common/constants/user.constant';
import HrmContext from '../../../../context/HrmContext';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { useService } from '../../../../helpers/serviceHelpers';
import hrService from '../../Services/hr.service';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

export default function EmployeesFilter ({ setFilters, setPage }) {
    const [expanded, setExpanded] = useState(false);
    const { data } = useService(hrService.getGenderStaffs);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const { control, register, watch, handleSubmit } = useForm({
        mode: 'onChange'
    });

    const departments = useContext(HrmContext);

    const searchButtonRef = useRef();

    const id = watch('id');
    const name = watch('lastname');
    const jobTitle = watch('jobTitle');
    const title = watch('title');
    const departmentId = watch('departmentId');
    const role = watch('role');
    const status = watch('status');

    const timeoutID = useRef();

    useEffect(() => {
        if (timeoutID.current) {
            clearTimeout(timeoutID.current);
            timeoutID.current = null;
        }
        timeoutID.current = setTimeout(handleFilter, 800);
    }, [departmentId, id, name, jobTitle, title, role, status]);

    const handleFilter = useCallback(() => {
        if (timeoutID.current) {
            clearTimeout(timeoutID.current);
            timeoutID.current = null;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            departmentId: departmentId?._id || undefined,
            id: id || undefined,
            lastname: name || undefined,
            jobTitle: jobTitle || undefined,
            title: title?.value || undefined,
            role: role?.value || undefined,
            status: status?.value || undefined
        }));
        setPage(1);
    }, [departmentId, id, name, jobTitle, title, role, status]);

    return (
        <div className="mt-4">
            <form onSubmit={handleSubmit(handleFilter)}>
                <div className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                    <div className='row mb-2'>
                        <div className='input-group col-xl-2 col-6'>
                            <input
                                type='search'
                                className="form-control border"
                                placeholder={TranslateHelper.getMessage(
                                    'HRM.detail.HR.official.label.id'
                                )}
                                {...register('id')}
                            />
                        </div>
                        <div className='input-group col-xl-2 col-6'>
                            <input
                                className='form-control border'
                                placeholder={TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.name')}
                                {...register('lastname')}
                            />
                        </div>
                        <div className='input-group col-xl-2 col-6'>
                            <input
                                className='form-control border'
                                placeholder={TranslateHelper.getMessage('HRM.detail.HR.official.label.jobTitle')}
                                {...register('jobTitle')}
                            />
                        </div>
                        <div className='col-xl-2'>
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                title='More'
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </div>
                    </div>
                    <div>
                        <span className='mr-4'>Male: {data?.male_count}</span>
                        <span>Female: {data?.female_count}</span>
                    </div>
                </div>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <div className='row'>
                        <div className='col-xl-3 col-6'>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        isClearable
                                        options={departments}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option._id}
                                        onChange={onChange}
                                        value={value}
                                        styles={selectStyle}
                                        placeholder="Departments"
                                    />
                                )}
                                name="departmentId"
                            />
                        </div>
                        <div className='col-xl-3 col-6'>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        isClearable
                                        options={titles}
                                        onChange={onChange}
                                        value={value}
                                        styles={selectStyle}
                                        placeholder="Title"
                                    />
                                )}
                                name="title"
                            />
                        </div>
                        <div className='col-xl-3 col-6'>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        isClearable
                                        options={roles}
                                        onChange={onChange}
                                        value={value}
                                        styles={selectStyle}
                                        placeholder="Role"
                                    />
                                )}
                                name="role"
                            />
                        </div>
                        <div className='col-xl-3 col-6'>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        isClearable
                                        options={statuses}
                                        onChange={onChange}
                                        value={value}
                                        styles={selectStyle}
                                        placeholder="Status"
                                    />
                                )}
                                name="status"
                            />
                        </div>
                    </div>
                </Collapse>
                <button ref={searchButtonRef} type='submit' className='invisible'>{TranslateHelper.getMessage('button.name.common.search')}</button>
            </form>
        </div>
    );
}

const roles = [
    {
        value: ROLES.MANAGER,
        label: 'Manager'
    },
    {
        value: ROLES.MEMBER,
        label: 'Member'
    },
    {
        value: ROLES.HR,
        label: 'HR Recruitment'
    },
    {
        value: ROLES.GUEST,
        label: 'Guest'
    }

];

const statuses = [
    {
        value: 'enable',
        label: 'Enable'
    },
    {
        value: 'disable',
        label: 'Disable'
    }
];

const titles = [
    {
        value: TITLE_GENDER.MR,
        label: 'MR'
    },
    {
        value: TITLE_GENDER.MS,
        label: 'MS'
    },
    {
        value: TITLE_GENDER.MRS,
        label: 'MRS'
    }
];

const selectStyle = {
    control: (base) => ({
        ...base,
        backgroundColor: 'white'
    })
};
