import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import { connect } from 'react-redux';
import { setOptionView } from '../../../../actions/hrm.action';

function OptionView ({ dispatch, changeView }) {
    const [view, setView] = useState('grid');

    const handleChange = (e) => {
        setView(e.target.value || 'grid');
        dispatch(setOptionView({ viewType: e.target.value || 'grid' }));
        changeView(e.target.value || 'grid');
    };
    return (
        <ToggleButtonGroup
          orientation="horizontal"
          value={view}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="grid" aria-label="module">
            <ViewModuleIcon /> GRID
          </ToggleButton>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon /> LIST
          </ToggleButton>
        </ToggleButtonGroup>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(OptionView);
