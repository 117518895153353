import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import businessunitsService from './services/businessunits.service';
import Button from '@atlaskit/button';

export default function BusinessDetail ({ businessUnit }) {
    const { data: BUDetail } = useService(businessunitsService.getOneBU, businessUnit.code);
    const displayTimezone = (number) => {
        switch (number) {
            case 1:
                return 'GTM +0';
            case 2:
                return 'UTC +7';
            default:
                break;
        }
    };
    const renderDepartment = (data) => {
        return data?.map((item) => {
            return (
                <tr key={item._id}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>
                        {moment(item.startedOn).format(TranslateHelper.getMessage('date.format'))}
                    </td>
                    <td>
                        {item.departmentHead?.firstname} {item.departmentHead?.lastname}
                    </td>
                </tr>
            );
        });
    };
    return (
        <div
            className="modal fade"
            id={`modal-businessUnit-${businessUnit.code}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal-businessUnit"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content" style={{ whiteSpace: 'break-spaces' }}>
                    <div className="modal-header ">
                        <h4 className="modal-title "><span className='d-inline text-break'>{BUDetail.name}</span></h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold ">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.name" />
                            </div>
                            <div className="col-xl-8">{BUDetail.name}</div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.code" />
                            </div>
                            <div className="col-xl-8">{BUDetail.code}</div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.startedOn" />
                            </div>
                            <div className="col-xl-8">
                                {moment(BUDetail.startedOn).format(
                                    TranslateHelper.getMessage('date.format')
                                )}
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.street" />
                            </div>
                            <div className="col-xl-8">{BUDetail.street}</div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.city" />
                            </div>
                            <div className="col-xl-8">{BUDetail.city}</div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.country" />
                            </div>
                            <div className="col-xl-8">{BUDetail.country}</div>
                        </div>
                        <div className="row my-2">
                            <div className="col-xl-3 font-weight-bold">
                                <FormattedMessage id="hrm.detail.organization.businessunit.form.label.timezone" />
                            </div>
                            <div className="col-xl-8">{displayTimezone(BUDetail.timezone)}</div>
                        </div>
                        <div className='table-scrollX'>
                            <table
                                id="department-units-list"
                                className="table table-bordered table-hover"
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.code" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.name" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.startedon" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="hrm.detail.organization.tab.businessunit.table.column.name.departmentHead" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{renderDepartment(BUDetail.department)}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-flex-end">
                        <Button type="button" appearance='primary' data-dismiss="modal">
                            <FormattedMessage id="button.name.common.close" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
