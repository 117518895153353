import { Badge, Divider, IconButton, List, Menu, MenuList, Tooltip, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useCallback, useEffect, useState } from 'react';
import TranslateHelper from '../helpers/TranslateHelper';
import { Box } from '@mui/system';
import NotificationItem from './NotificationItem';
import notificationService from './project/services/notification.service';
import socket from './common/Socket';
import { connect } from 'react-redux';

function Notifications ({ user, breadcrumb }) {
    const [markAll, setMarkAll] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [notifications, setNotification] = useState();
    const [intervalControl, setIntervalControl] = useState();

    useEffect(() => {
        socket.connect();
        socket.emit('addUser', user.userId);
        socket.on('getNotification', data => {
            if (user.userId === data.receiverId) {
                setMarkAll(markAll + data.markAll);
            }
        });
    }, [markAll]);

    useEffect(() => {
        clearInterval(intervalControl);
        if (markAll) {
            let activeAlert = false;
            const title = breadcrumb.titleVar || TranslateHelper.getMessage(breadcrumb.title);
            const msg = '(' + markAll + ') You have notify';
            setIntervalControl(setInterval(() => {
                document.title = activeAlert ? title : msg;
                activeAlert = !activeAlert;
            }, 1000));
        } else {
            document.title = breadcrumb.titleVar || TranslateHelper.getMessage(breadcrumb.title);
        }
    }, [markAll, breadcrumb]);

    const handleClick = (event) => {
        if (markAll) {
            notificationService.clearNotification().then(() => {
                setMarkAll(0);
            });
        }
        setOpen(prevState => !prevState);
        setAnchorEl(event.currentTarget);
        fetchNotifications();
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const getMarkAll = useCallback(() => {
        notificationService.getNotification().then(res => {
            if (res.total !== 0) {
                setMarkAll(res.markAll);
                if (res.markAll) {
                    document.title = '(' + res.markAll + ') You have notify';
                }
            }
        });
    }, []);

    useEffect(getMarkAll, [getMarkAll]);

    const fetchNotifications = useCallback(() => {
        notificationService.getNotification().then(res => {
            if (res.total !== 0) {
                setNotification(res);
            }
        });
    }, []);

    const readAllNotifications = () => {
        notificationService.readAllNotification();
        const items = notifications.items.map(obj => {
            if (!obj.isChecked) {
                return { ...obj, isChecked: true };
            }
            return obj;
        });
        setNotification(prevState => ({ ...prevState, items }));
    };
    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'notifications-box' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Badge badgeContent={markAll} color='error' max={10}>
                        <NotificationsIcon color="action" />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="notifications-box"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 8,
                    sx: {
                        overflow: 'visible',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        },
                        borderRadius: '12px'
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} padding='1rem'>
                    <Typography
                        variant="h6"
                        component='h6'
                        color="text.secondary"
                        sx={{
                            fontWeight: 'bold'
                        }}>
                        {TranslateHelper.getMessage('notifications')}
                    </Typography>
                    <Tooltip title={TranslateHelper.getMessage('mark.as.all.read')}>
                        <IconButton size='small' onClick={() => readAllNotifications()}>
                            <DoneAllIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider/>
                <MenuList sx={{ maxHeight: 500, overflow: 'auto' }}>
                    <List>
                        {notifications && <NotificationItem data={notifications}/>}
                    </List>
                </MenuList>
            </Menu>
        </>
    );
}

function mapStateToProps (state) {
    const { user } = state.auth;
    const { breadcrumb } = state.breadcrumb;
    return {
        user, breadcrumb
    };
}

export default connect(mapStateToProps)(Notifications);
