import './NotFound.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function NotFound () {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        document.title = 'Oops...';
    }, []);

    return (
        <>
            {location.state
                ? (
                    <section className="page_404">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="col-sm-12 col-sm-offset-1 text-center">
                                        <div className="four_zero_four_bg">
                                            <h1 className="text-center ">404</h1>
                                        </div>
                                        <div className="contant_box_404">
                                            <h3 className="h2">Sorry, page not found</h3>
                                            <a onClick={() => { history.push('/dashboard'); }} className="link_404">Go to Dashboard</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    )
                : <Redirect to={'/dashboard'}/>}
        </>
    );
}
