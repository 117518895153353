import './ProductSection.css';
import packageService from '../../manager/package/components/Services/package.service';
import TranslateHelper from '../../common/helpers/TranslateHelper';
import CurrencyFormat from 'react-currency-format';
import ContactForm from './ContactForm';
import { useEffect, useRef, useState } from 'react';

export default function ProductSection () {
    const [packageName, setPackageName] = useState('');
    const [packageList, setPackageList] = useState([]);
    const [clearForm, shouldClearForm] = useState(false);
    const hiddenContactFormRef = useRef();
    useEffect(() => {
        packageService.getAllPackage().then(res => {
            if (res) {
                setPackageList(res.items);
            }
        });
    }, []);
    return (
        <section id="product" className="product">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="section-heading text-center mb-5">
                            <h2>Pricing and Packages</h2>
                            <p className="lead">
                                Monotonectally grow strategic process improvements vis-a-vis
                                integrated resources.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mw-50">
                    {packageList.length > 0 && packageList.map((item) => (
                        <div className={`${packageList.length > 3 ? 'col-lg-3' : 'col-lg-4'} col-md`} key={item._id}>
                            <div className="card border-0 shadow pricing">
                                <div className="card-body py-8 px-6">
                                    <div className="text-center mb-5">
                                        {' '}
                                        <span className="badge shadow">
                                            <span className="h6 text-uppercase">{item.name}</span>
                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <img
                                            className="img-fluid"
                                            src="/images/package-2.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div style={{ width: '100%' }} className='package_des' dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-5">
                                        {' '}
                                        <span className="price display-4 text-primary">
                                            <CurrencyFormat
                                                value={item?.price}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={TranslateHelper.getMessage('prefix.price')}
                                            />
                                        </span>
                                    </div>
                                    <p className="text-center text-muted">Per user</p>{' '}
                                    <button
                                        className="btn btn-block btn-primary mt-5"
                                        data-toggle="modal"
                                        data-target="#modalContact"
                                        onClick={() => {
                                            setPackageName(item.name);
                                            shouldClearForm(true);
                                        }}
                                    >
                                        Choose Package
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className="modal fade"
                    id="modalContact"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalContactLongTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-scrollable modal-contact" role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="d-none"
                                data-dismiss="modal"
                                aria-label="Close"
                                ref={hiddenContactFormRef}
                            ></button>
                            <div className="modal-body">
                                <ContactForm packageName={packageName} clearForm={clearForm} shouldClearForm={shouldClearForm} hiddenContactFormRef={hiddenContactFormRef}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
