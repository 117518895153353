import TranslateHelper from '../../../helpers/TranslateHelper';

const ValFULLDATE = 1;
const ValWFHDATE = 1;
const ValhalfAM = 0;
const ValhalfPM = 2;
const Val2hoursAM = 3;
const Val2hoursPM = 4;
const ValLeaveAnual = 1;
const ValLeaveAssigned = 2;
const ValWFH = 2;
const ValLeaveUnpaid = 3;
const ValLeaveSpecial = 4;
const ValWorkingLeave = 5;
const ValWFHAssigned = 2;
const ValSummerLeave = 6;
const sickness = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.sickness');
const personalReason = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.personal');
const familyReason = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.family');
const otherReason = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.other');
const weddingReason = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.wedding');
const deathReason = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.death');
const annualLeave = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.annualLeave');
const specialLeave = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.specialLeave');
const unpaidLeave = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.unpaidLeave');
const fullDay = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.fullDay');
const halfDayAM = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.halfDayAM');
const halfDayPM = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.halfDayPM');
const first2hOfDay = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.first2hOfDay');
const last2hOfDay = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.last2hOfDay');
// const workingLeave = TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.workingLeave');
const consultingCustomer = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.consultingCustomer');
const signContract = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.signContract');
const participateSeminars = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.participateSeminars');
const summerLeave = TranslateHelper.getMessage('hrm.services.leaveRequest.label.reason.summerLeave');
const LEAVE_FOR = [
    { value: ValFULLDATE, label: fullDay },
    { value: ValhalfAM, label: halfDayAM },
    { value: ValhalfPM, label: halfDayPM }
];

const UNPAID_LEAVE = [
    ...LEAVE_FOR,
    { value: Val2hoursAM, label: first2hOfDay },
    { value: Val2hoursPM, label: last2hOfDay }
];

const LEAVE_TYPE = [
    { value: ValLeaveAnual, label: annualLeave },
    { value: ValWFH, label: 'WFH' },
    { value: ValLeaveUnpaid, label: unpaidLeave },
    { value: ValLeaveSpecial, label: specialLeave }
    // { value: ValWorkingLeave, label: workingLeave }
];

const LEAVE_TYPE_CONTRACT = [
    ...LEAVE_TYPE,
    { value: ValSummerLeave, label: summerLeave }
];

const WORKING_LEAVE_REASONS = [
    { value: consultingCustomer, label: consultingCustomer },
    { value: signContract, label: signContract },
    { value: participateSeminars, label: participateSeminars },
    { value: otherReason, label: otherReason }
];

const LEAVE_REASONS = [
    { value: sickness, label: sickness },
    { value: personalReason, label: personalReason },
    { value: familyReason, label: familyReason },
    { value: otherReason, label: otherReason }
];

const SPECIAL_LEAVE_REASONS = [
    { value: weddingReason, label: weddingReason },
    { value: deathReason, label: deathReason },
    { value: otherReason, label: otherReason }
];

export const ServiceConstants = {
    LEAVE_TYPE,
    LEAVE_TYPE_CONTRACT,
    LEAVE_FOR,
    UNPAID_LEAVE,
    LEAVE_REASONS,
    SPECIAL_LEAVE_REASONS,
    WORKING_LEAVE_REASONS,
    ValWorkingLeave,
    ValLeaveAnual,
    ValLeaveAssigned,
    ValhalfPM,
    ValhalfAM,
    Val2hoursAM,
    Val2hoursPM,
    ValFULLDATE,
    ValWFHDATE,
    ValWFH,
    ValWFHAssigned,
    ValLeaveSpecial,
    ValLeaveUnpaid,
    ValSummerLeave
};

export const LEAVE_STATUS = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    CANCELED: 4
};
