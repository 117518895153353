import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@atlaskit/select';
import recruitmentService from '../../Services/recruitment.service';

export default function FilterRecruitment ({ setFilterOptions, setFilters, memoizedFilter, filterOptions, forTeams, setPage }) {
    const handleSelectOptionChange = useCallback((option, target) => {
        setFilterOptions((prevState) => ({
            ...prevState,
            [target.name]: option
        }));
    }, []);

    useEffect(() => {
        const dataFilter = Object.keys(filterOptions).reduce((result, key) => {
            if (!filterOptions[key]) {
                return result;
            }
            if (typeof filterOptions[key] === 'object' && filterOptions[key].value) {
                result[key] = filterOptions[key].value;
            } else {
                result[key] = filterOptions[key];
            }
            return result;
        }, {});
        // setFilters({ ...dataFilter, t: Date.now() });
        setFilterOptions(dataFilter);
        setFilters(dataFilter);
        setPage(1);
    }, [memoizedFilter]);
    return (
        <form className="card-body">
            <div className="row">
                <div className="col-xl-12 col-12">
                    <div className="row">
                        <div
                            className="col-xl-3 col-3 candidates-sp"
                            style={{ alignSelf: 'flex-end' }}
                        >
                            <label>
                                <FormattedMessage id="HRM.detail.recruitment.table.name.team" />
                            </label>
                            <Select
                                isClearable
                                options={forTeams}
                                onChange={handleSelectOptionChange}
                                name="forTeam"
                                placeholder="Team"
                            />
                        </div>
                        <div
                            className="col-xl-3 col-3 candidates-sp"
                            style={{ alignSelf: 'flex-end' }}
                        >
                            <label>
                                <FormattedMessage id="HRM.detail.recruitment.table.name.title" />
                            </label>
                            <Select
                                isClearable
                                options={recruitmentService.titles}
                                onChange={handleSelectOptionChange}
                                name="candidateTitle"
                                placeholder="Type"
                            />
                        </div>
                        <div
                            className="col-xl-3 col-3 candidates-sp"
                            style={{ alignSelf: 'flex-end' }}
                        >
                            <label>
                                <FormattedMessage id="HRM.detail.recruitment.table.name.status" />
                            </label>
                            <Select
                                isClearable
                                options={recruitmentService.statues}
                                onChange={handleSelectOptionChange}
                                name="statusInterview"
                                placeholder="Type"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
