import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { LanguageDatatables } from '../../../../../../lang';
import $ from 'jquery';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { Link, withRouter } from 'react-router-dom';
import IssueService from '../../services/issue.service';
import serviceHelpers from '../../../../helpers/serviceHelpers';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactPagination from '../../../ReactPagination';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import FilterComponent from './FilterComponent';
import Folder from '../../images/folder_blue_1.svg';
import { ISSUE_PRIORITY } from '../../../../constants/project.constant';
import Skeleton from 'react-loading-skeleton';
import Button from '@atlaskit/button';
import NewIssue from './NewIssue';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';
class Issues extends Component {
    constructor (props) {
        super(props);
        serviceHelpers();
        this.state = {
            filterOption: {},
            paramsFilter: {},
            loadingStatus: false,
            isEmpty: true,
            isError: {
                defaultValue: '',
                maximum: '',
                startDate: '',
                dueDate: ''
            },
            form: {
                startDate: '',
                dueDate: ''
            },
            dataTable: [],
            dataIssue: [],
            type: [],
            status: [],
            priority: [
                {
                    value: ISSUE_PRIORITY.HIGH,
                    label: 'High'
                },
                {
                    value: ISSUE_PRIORITY.MEDIUM,
                    label: 'Medium'
                },
                {
                    value: ISSUE_PRIORITY.LOW,
                    label: 'Low'
                }
            ],
            option: {
                issueType: '',
                status: '',
                priority: '',
                assignTo: '',
                createdBy: '',
                milestone: '',
                category: '',
                startDate: '',
                dueDate: ''
            },
            createdBy: [],
            assignTo: [],
            milestone: [],
            category: [],
            selectedPage: 0,
            isCreate: false
        };
        this.initDatatable = this.initDatatable.bind(this);
        this.refetchIssue = this.refetchIssue.bind(this);
    }

    componentDidMount () {
        this.getAllIssue(1, false);
        this.props.history.push({ pathname: this.props.match.url, state: { issueListIdx: 1 } });
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#issues-list').DataTable({
                fixedHeader: true,
                paging: false,
                lengthChange: false,
                searching: false,
                info: true,
                autoWidth: true,
                responsive: true,
                scrollX: true,
                language: LanguageDatatables[lang],
                order: [6, 'decs'],
                fnDrawCallback: function (oSettings) {
                    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                    } else {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                    }
                }
            });
            this.setState({ table });
        }.bind(this));
    }

    getAllIssue (page, deleted) {
        const accesstoken = Cookies.get('accessToken');
        const { paramsFilter } = this.state;
        return axios.get(API_URL + this.props.projectId + '/issues', { headers: { 'x-access-token': 'Bearer ' + accesstoken }, params: { ...paramsFilter, page } }).then((res) => {
            if (res.status === 200) {
                this.setState({ dataTable: res.data.data, loadingStatus: true });
                this.forceUpdate();
            }
            res.data.data.total === 0 ? this.setState({ isEmpty: true }) : this.setState({ isEmpty: false });
        }).catch((err) => {
            if (err.response) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage(err)
                });
            }
        });
    }

    refetchIssue (id) {
        IssueService.getAllIssues(id, undefined, 12).then(res => {
            this.setState({ dataTable: res, loadingStatus: true });
            this.forceUpdate();
        }).catch(err => {
            console.log(err);
        });
    }

    getListFilter (projestId, filter) {
        return IssueService.getIssueByFilter(projestId, filter).then((res) => {
            if (res.data.data.items) {
                this.setState({ dataTable: res.data.data, loadingStatus: true });
                this.forceUpdate();
            }
            res.data.data.total === 0 ? this.setState({ isEmpty: true }) : this.setState({ isEmpty: false });
        });
    }

    handlePageChange (page) {
        this.setState({ selectedPage: page });
        this.getAllIssue(page);
    }

    setFilterOptions (filter) {
        this.setState({ filterOption: filter });
        this.getListFilter(this.props.projectId, filter);
    }

    setParamsFilter (params) {
        this.setState({ paramsFilter: params });
    }

    render () {
        const renderPriority = (priority) => {
            switch (priority) {
                case ISSUE_PRIORITY.LOW:
                    return <i title='Low' className="fas fa-angle-double-down text-primary"></i>;
                case ISSUE_PRIORITY.MEDIUM:
                    return <i title='Medium' className="fas fa-equals text-warning"></i>;
                case ISSUE_PRIORITY.HIGH:
                    return <i title='High' className="fas fa-angle-double-up text-danger"></i>;
                default:
                    break;
            }
        };
        const renderRow = data => {
            return data.map((item) => {
                return (
                    <tr key={item._id}>
                        <td>{item.issueTypeId?.issueType}</td>
                        <td>{item.issueCode}</td>
                        <td><Link style={{ color: '#4f96ac' }} to={{ pathname: this.props.projectId + '/issues/' + item.issueCode, state: { projectName: this.props.projectName, issueCode: item.issueCode, subject: item.subject } }}><p title={item.subject} style={{ textTransform: 'none' }}>{item.subject}</p></Link></td>
                        <td>
                            <div className='d-flex' style={{ gap: '5px' }}>
                                <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.assignTo?.picture ? (String(item.assignTo?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.assignTo?.picture) : item.assignTo?.picture) : '/images/default-avatar.png'} alt='user-image'/>
                                <div title={(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'} className='employee-name'>{(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'}</div>
                            </div>
                        </td>
                        <td>{item.status?.name}</td>
                        <td align='center'>
                            {renderPriority(item.priority)}
                        </td>
                        <td>{moment(item.createdAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                        <td>{item.startDate && item.startDate && moment(item.startDate).format(TranslateHelper.getMessage('date.format'))}</td>
                        <td>{item.dueDate && moment(item.dueDate).format(TranslateHelper.getMessage('date.format'))}</td>
                        <td>{item.estimateTime}</td>
                        <td>{item.actualTime}</td>
                        <td>{moment(item.updatedAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                        <td>
                            <div className='d-flex' style={{ gap: '5px' }}>
                                <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.createdBy?.picture ? (String(item.createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.createdBy?.picture) : item.createdBy?.picture) : '/images/default-avatar.png'} alt='user-image'/>
                                <div title={item.createdBy?.firstname + ' ' + item.createdBy?.lastname} className='employee-name'>{item.createdBy?.firstname} {item.createdBy?.lastname}</div>
                            </div>
                        </td>
                    </tr>
                );
            });
        };

        return (
            <div className='container-fluid' style={{ overflowX: 'hidden' }}>
                <Button data-toggle="modal" data-target=".createNewIssueBtn" onClick={() => this.setState(prevState => ({ ...prevState, isCreate: true }))} className='mb-2' appearance='primary'>Create</Button>
                <div className="modal fade createNewIssueBtn" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Create issue</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                {this.state.isCreate && <NewIssue projectId={this.props.projectId} getAllIssue={this.refetchIssue}/>}
                            </div>
                        </div>
                    </div>
                </div>
                <FilterComponent onSubmit={this.setFilterOptions.bind(this)} projectId={this.props.projectId} setParamsFilter = {this.setParamsFilter.bind(this)} />
                {!this.state.loadingStatus
                    ? <>
                            <Skeleton width={'100%'} height={'150px'}/>
                            <Skeleton width={'100%'} height={'150px'}/>
                        </>
                    : this.state.isEmpty
                        ? (
                        <div className='w-full text-center'>
                            <img style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '5%', paddingTop: '150px', minWidth: '50px' }} src={Folder} ></img>
                            <div className='text-muted mt-2 text-lg'>No issues here !!!</div>
                        </div>
                            )
                        : (
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='issue-table-content' style={{ overflowX: 'auto' }}>
                                        <table id="issues-list" className="table table-bordered table-hover">
                                            <thead>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.type" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.key" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.subject" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.assignee" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.status" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.priority" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.createdAt" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.startDate" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.dueDate" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.estimateHours" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.actualHours" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.updatedAt" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.createdBy" /></th>
                                            </thead>
                                            <tbody>
                                                {this.state.loadingStatus === true && renderRow(this.state.dataTable.items)}
                                            </tbody>
                                        </table>
                                        {
                                            (this.state.dataTable?.totalPages > 1) &&
                                        <ReactPagination totalPages={this.state.dataTable?.totalPages} handlePageChange={(e, page) => this.handlePageChange(page)} pageNumber={this.state.selectedPage + 1} />
                                        }
                                    </div>
                                </div>
                            </div>
                            )}
            </div>
        );
    }
}

export default withRouter(Issues);
