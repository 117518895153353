import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import SandboxDetail from './SandboxDetail';
import SandboxList from './SandboxList';
class SandboxManagement extends Component {
    render () {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}`} component={SandboxList} />
                <Route path={`${match.url}/:sandboxId/:sandboxName`} component={SandboxDetail} />
            </Switch>
        );
    }
}

export default (SandboxManagement);
