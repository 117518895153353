import { PROJECT_INFO } from '../constants/project.constant';

export const setProjectInfo = (payload) => ({
    type: PROJECT_INFO.SET_PROJECT_INFO,
    payload
});

export const setRoleInProject = (payload) => ({
    type: PROJECT_INFO.SET_ROLE_IN_PROJECT_INFO,
    payload
});
