import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import recruitmentService from '../../Services/recruitment.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import { useService } from '../../../../helpers/serviceHelpers';
import departmentService from '../organization-detail/services/department.service';
import Button from '@atlaskit/button';
import InputField from '../../../common/InputField';
import SelectBox from '../../../common/SelectBox';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { DOBCustomInput } from '../../../common/CustomInputDatepicker';

export default function CreateCVForm ({ refetch, refetchCV, provinces }) {
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isDirty, isValid },
        watch,
        clearErrors
    } = useForm({ mode: 'onChange', defaultValues: { flag: false } });
    const hideModalButtonRef = useRef();
    const hiddenFileInputRef = useRef();
    const file = watch('file');

    const defaultValues = {
        candidateName: '',
        candidateBirthday: '',
        candidateGender: null,
        candidateEmail: '',
        candidateMobileNumber: '',
        forTeam: null,
        candidateTitle: null,
        file: undefined,
        statusInterview: null,
        source: ''
    };

    const { data: forTeams } = useService(departmentService.getAllDepartment, undefined, (res) =>
        res.data.data.items.map((item) => ({
            value: item._id,
            label: item.name
        }))
    );

    const handleClick = event => {
        hiddenFileInputRef.current.click();
    };

    const onsubmit = async (data) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            // [file]
            if (key === 'file') {
                formData.append(key, hiddenFileInputRef.current?.files[0]);
                continue;
            }

            // {label: "Minh Anh", value: "_id"}
            if (value && typeof value === 'object' && 'value' in value) {
                formData.append(key, value.value);
                continue;
            }

            // [{label: "Minh Anh", value: "_id"}]
            if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === 'object' && 'value' in item) {
                        formData.append(key, item.value);
                        continue;
                    }
                }
                continue;
            }

            // value
            if (value) {
                formData.append(key, value);
            }
        }
        try {
            await recruitmentService.createRecruitmentCV(formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });

            reset(defaultValues);
            document.getElementById('file').value = null;
            hideModalButtonRef.current?.click();
            refetch();
            refetchCV();
        } catch (error) {
            console.warn(error);
            ToastTopEnd.fire({
                icon: 'error',
                title:
                    error.response?.data?.message ||
                    TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };
    return (
        <div
            className="modal fade"
            id="modal-CV"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal-CV"
            aria-hidden="true"
        >
            <div className="d-flex justify-content-center align-items-center modal-dialog modal-lg modal-scrollable" id="interview-modal">
                <div className="modal-content px-3">
                    <div className="modal-header text-uppercase">
                        <h4 className="modal-title">
                            <FormattedMessage id="HRM.detail.recruitment.title.createCV" />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={hideModalButtonRef}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <form className="row" onSubmit={handleSubmit(onsubmit)}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs='6'>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateName'
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.candidateName" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        name={'candidateName'}
                                                        error={errors.candidateName}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item sx='6'>
                                                    <Controller
                                                        control={control}
                                                        name='openToWork'
                                                        render={({ field: { onChange, value } }) => (
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox onChange={onChange}/>} label={<FormattedMessage id="HRM.detail.HR.recruitment.label.openToWork" />}/>
                                                            </FormGroup>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item sx='6'>
                                                    <Box mb={2} container>
                                                        <Controller
                                                            control={control}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <DatePicker
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode='select'
                                                                    ref={ref}
                                                                    selected={value}
                                                                    onChange={onChange}
                                                                    className="form-control"
                                                                    placeholderText={TranslateHelper.getMessage('HRM.detail.HR.official.label.birth')}
                                                                    popperPlacement="right-end"
                                                                    css={{ zIndex: 99999 }}
                                                                    customInput={<DOBCustomInput/>}
                                                                />
                                                            )}
                                                            name="candidateBirthday"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mb={1.5}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <SelectBox
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.gender" />}
                                                        onChange={onChange}
                                                        data={recruitmentService.gender}
                                                        error={errors.candidateGender}
                                                    />
                                                )}
                                                name="candidateGender"
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateEmail'
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.official.label.email" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateEmail}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateMobileNumber'
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    ),
                                                    pattern: {
                                                        value: /^(?!\s+$)[0-9(),  ]+$/gi,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.numberic'
                                                        )
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.notMobilePhone'
                                                        )
                                                    },
                                                    minLength: {
                                                        value: 9,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.notMobilePhone'
                                                        )
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.official.label.mobile" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateMobileNumber}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateLinkedin'
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.linkedin" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateLinkedin}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateSkype'
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.skype" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateSkype}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs='6'>
                                        <Box mb={1.5}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <SelectBox
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.team" />}
                                                        onChange={onChange}
                                                        data={forTeams}
                                                        error={errors.forTeam}
                                                    />
                                                )}
                                                name="forTeam"
                                            />
                                        </Box>
                                        <Box mb={1.5}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <SelectBox
                                                        label={<FormattedMessage id="HRM.detail.recruitment.table.name.title" />}
                                                        onChange={onChange}
                                                        data={recruitmentService.titles}
                                                        error={errors.candidateTitle}
                                                    />
                                                )}
                                                name="candidateTitle"
                                            />
                                        </Box>
                                        <Box mb={1.5}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    )
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <SelectBox
                                                        label={<FormattedMessage id="HRM.detail.recruitment.table.name.status" />}
                                                        onChange={onChange}
                                                        data={recruitmentService.statues}
                                                        error={errors.statusInterview}
                                                    />
                                                )}
                                                name="statusInterview"
                                            />
                                        </Box>
                                        <Box mb={1.5}>
                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <SelectBox
                                                        label={<FormattedMessage id="HRM.detail.recruitment.table.name.location" />}
                                                        onChange={onChange}
                                                        data={provinces}
                                                        error={errors.candidateLocale}
                                                    />
                                                )}
                                                name="candidateLocale"
                                            />
                                        </Box>
                                        <Box mb={2.5}>
                                            <Controller
                                                name='source'
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.source" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.source}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateCurrentCompany'
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.currentCompany" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateCurrentCompany}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2}>
                                            <Controller
                                                name='candidateNote'
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        label={<FormattedMessage id="HRM.detail.HR.recruitment.label.note" />}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors.candidateNote}
                                                        multiline={true}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box mb={2.5}>
                                            <Button className='col-12 w-100' onClick={handleClick}>{file || hiddenFileInputRef.current?.files[0]?.name || <FormattedMessage id="HRM.detail.HR.recruitment.label.CV" /> }</Button>
                                            <input
                                                id="file"
                                                type="file"
                                                accept="application/pdf"
                                                className='d-none'
                                                {...register('file')}
                                                ref={hiddenFileInputRef}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <div className="d-flex w-100 justify-content-end px-4 mt-4 ">
                                    <Button className="col-xl-2 col-4 mr-2" data-dismiss="modal" onClick={() => { reset(defaultValues); clearErrors(); }}>
                                        <FormattedMessage id="button.name.common.cancel" />
                                    </Button>
                                    <Button type="submit" className="col-xl-2 col-4" appearance='primary' disabled={!isDirty || !isValid}>
                                        <FormattedMessage id="button.name.common.create" />
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
