import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@atlaskit/select';
import { FilterInputDate } from '../../../common/CustomInputDatepicker';
import DatePicker from 'react-datepicker';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import { ISSUE_PRIORITY } from '../../../../constants/project.constant';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import { connect } from 'react-redux';
import issueService from '../../services/issue.service';
import dayjs from 'dayjs';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { Button } from 'antd';

function FilterComponent ({ setRefetch, onSubmit, filterOptions, projectId, setParamsFilter, project }) {
    const history = useHistory();
    const location = useLocation();
    const ButtonSubmitRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const initOptions = useMemo(() => (
        {
            status: [],
            issueTypeId: [],
            assignTo: [],
            createdBy: [],
            milestoneId: [],
            priority: [
                {
                    value: ISSUE_PRIORITY.HIGH,
                    label: 'High'
                },
                {
                    value: ISSUE_PRIORITY.MEDIUM,
                    label: 'Medium'
                },
                {
                    value: ISSUE_PRIORITY.LOW,
                    label: 'Low'
                }
            ],
            categoryId: []
        }
    ), []);
    const [options, setOptions] = useState(initOptions);
    const [filterExport, setFilterExport] = useState({});
    const fetchIssue = useCallback(() => {
        const categories = project.categories.map((item) => ({
            label: item.categoryName,
            value: item._id
        }));
        const status = project.statusTasks.map((item) => ({
            label: item.name,
            value: item._id
        }));
        const issueTypes = project.issueTypes.map((item) => ({
            label: item.issueType,
            value: item._id
        }));
        const milestones = project.milestones.map((item) => ({
            label: item.milestoneName,
            value: item._id
        }));
        const assignTo = project.members.reduce((result, member) => {
            result[member.user?._id] = member.user || {};
            return result;
        }, {});
        setOptions({
            ...initOptions,
            status,
            issueTypeId: issueTypes,
            categoryId: categories,
            milestoneId: milestones,
            assignTo,
            createdBy: assignTo
        });
    }, []);

    useEffect(() => {
        fetchIssue();
    }, [fetchIssue]);

    const initFilter = {
        ...filterOptions,
        assignTo: [],
        createdBy: []
    };

    const [filter, setFilter] = useState(initFilter);

    const handleFromDateChange = useCallback((date) => {
        setFilter(prevState => ({
            ...prevState,
            startDate: date,
            dueDate: undefined
        }));
    }, []);

    const handleToDateChange = useCallback((date) => {
        setFilter(prevState => ({
            ...prevState,
            dueDate: date
        }));
        ButtonSubmitRef.current?.click();
    }, []);

    const handleSelectOptionChange = useCallback(
        (option, target) => {
            setFilter(prevState => ({
                ...prevState,
                [target.name]: option
            }));
            ButtonSubmitRef.current?.click();
        },
        [filter, setFilter]
    );

    const handleCheckboxOptionChange = useCallback((option, target) => {
        const isExistedItem = filter[target].filter(obj => obj.value === option[0]);

        if (!isExistedItem?.length) {
            const nextArr = [...filter[target], { value: option[0] }];
            setFilter(prevState => ({
                ...prevState,
                [target]: nextArr
            }));
        } else {
            const removedArr = filter[target].filter(obj => obj.value !== option[0]);
            setFilter(prevState => ({
                ...prevState,
                [target]: removedArr
            }));
        }
        ButtonSubmitRef.current?.click();
    }, [filter, setFilter]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setFilter((state) => {
            let dataFilter = Object.keys(state).reduce((result, key) => {
                if (!state[key]) {
                    return result;
                }
                if (typeof state[key] === 'object') {
                    if (state[key]?.value) {
                        result[key] = state[key].value;
                    } else if (state[key].length) {
                        result[key] = state[key].map(obj => obj.value);
                    }
                } else {
                    result[key] = state[key];
                }
                return result;
            }, {});
            dataFilter = {
                ...dataFilter,
                startDate: state.startDate ? dayjs(state.startDate).format('YYYY-MM-DD') : undefined,
                dueDate: state.dueDate ? dayjs(state.dueDate).format('YYYY-MM-DD') : undefined
            };
            setRefetch && setRefetch(true);
            setParamsFilter && setParamsFilter(dataFilter);
            if (dataFilter) {
                const query = queryString.parse(location.search);
                const modifiedQuery = {
                    ...query,
                    milestone: dataFilter?.milestoneId,
                    assignee: dataFilter?.assignTo,
                    status: dataFilter?.status,
                    priority: dataFilter?.priority,
                    category: dataFilter?.categoryId,
                    type: dataFilter?.issueTypeId,
                    creator: dataFilter?.createdBy,
                    q: dataFilter?.q
                };
                history.replace({
                    pathname: location.pathname,
                    search: queryString.stringify(modifiedQuery)
                });
                setFilterExport(dataFilter);
                localStorage.setItem('projectFilter', JSON.stringify(dataFilter));
            }
            onSubmit(dataFilter);
            return state;
        });
    };

    const [deboundSearch, setDeboundSearch] = useState({
        name: '',
        typing: false,
        typingTimeout: 0
    });

    const [searchParams, setSearchParams] = useState({
        issue: undefined
    });

    useEffect(() => {
        setSearchParams({ issue: filter?.q });
    }, [filter]);

    const handleInputChange = e => {
        setSearchParams(e.target.value.trim());
        if (deboundSearch.typingTimeout) {
            clearTimeout(deboundSearch.typingTimeout);
        }

        setDeboundSearch({
            name: e.target.value.trim(),
            typing: false,
            typingTimeout: setTimeout(() => {
                setFilter(prevState => ({
                    ...prevState,
                    ...filter,
                    q: e.target.value.trim()
                }));
                ButtonSubmitRef.current?.click();
            }, 1000)
        });
    };

    const handleExportFile = () => {
        setIsLoading(true);
        issueService.exportExcel(projectId, filterExport).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectId}_export_${dayjs().format('DD/MM/YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const lang = localStorage.getItem('lang');
    return (
        <>
            <div className='card'>
                <form onSubmit={handleFormSubmit} className='card-body'>
                    <div className="d-flex justify-content-end">
                        <Button loading={isLoading} onClick={handleExportFile} type="primary" shape="round" icon={<i className="fa-regular fa-file-excel mr-2"></i>} size='large'>
                            <FormattedMessage id='button.name.common.export' />
                        </Button>
                    </div>
                        <div className= 'row col-xl-12 col-12' style={{ zIndex: 2 }}>
                            <div className='btn-group col-xl-4 col-12'>
                                <div className=''>
                                    <DatePicker
                                        selected={filter.startDate}
                                        onChange={handleFromDateChange}
                                        selectsStart
                                        showMonthDropdown
                                        showYearDropdown
                                        startDate={filter.startDate}
                                        endDate={filter.dueDate}
                                        dropdownMode='select'
                                        name={'startDate'}
                                        maxDate={filter.dueDate}
                                        customInput={<FilterInputDate />}
                                        locale={lang} />
                                </div>
                                <i className='fa fa-chevron-right' style={{ alignSelf: 'center', opacity: '0.3', margin: '0 10px' }}></i>
                                <div className=''>
                                    <DatePicker
                                        selected={filter.dueDate}
                                        onChange={handleToDateChange}
                                        selectsEnd
                                        showMonthDropdown
                                        showYearDropdown
                                        startDate={filter.startDate}
                                        endDate={filter.dueDate}
                                        minDate={filter.startDate}
                                        dropdownMode='select'
                                        name={'dueDate'}
                                        customInput={<FilterInputDate />}
                                        locale={lang} />
                                </div>
                            </div>
                            {
                                Object.entries(options).map((type, index) => {
                                    if (type[0] === 'assignTo' || type[0] === 'createdBy') {
                                        return (
                                            <div key = {index} className='col-xl-4 col-6' style={{ marginBottom: '10px', display: 'flex' }}>
                                                <label style={{ alignSelf: 'flex-end', marginRight: '10px' }}>{ type[0] === 'assignTo' ? 'Assignee' : 'Creator'}</label>
                                                {
                                                    Object.entries(type[1]).map((option, index) => {
                                                        const matchArr = filter[type[0]].map(obj => obj.value);
                                                        const selectedFlag = matchArr.includes(option[1]._id);
                                                        return (
                                                            <div key = {index} style={{ display: 'inline-block', position: 'relative', outline: '0px' }}>
                                                                {
                                                                    (index <= 5)
                                                                        ? <div key={index} className={'avatar-frame pointer' + (selectedFlag ? ' zIndex-up' : '')}>
                                                                                <div style={{ all: 'unset' }} onClick={() => handleCheckboxOptionChange(option, type[0])}>
                                                                                    <Avatar className={'avatar-box' + (selectedFlag ? ' avatar-select' : '')} title={option[1]?.firstname + ' ' + option[1]?.lastname} onError={(e) => { e.target.src = '/images/default-avatar.png'; } } src={option[1].picture ? (String(option[1].picture).includes('upload') ? recruitmentService.buildFileURL(option[1].picture) : option[1].picture) : '/images/default-avatar.png'}></Avatar>
                                                                                </div>
                                                                            </div>
                                                                        : (index === 6)
                                                                                ? <div className={'avatar-frame' + (selectedFlag ? ' zIndex-up' : '')}>
                                                                                        <div className="dropdown">
                                                                                            <Avatar className='avatar-box' style={{ backgroundColor: '#dbdbdb', textAlign: 'center', opacity: '0.7' }} type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                                { '+' + String(Object.entries(type[1]).length - index).toString() }
                                                                                            </Avatar>
                                                                                            <div className={'dropdown-menu' } aria-labelledby="dropdownMenuButton" style={{ width: '300px' }}>
                                                                                                {
                                                                                                    Object.entries(type[1]).map((option, index) => {
                                                                                                        if (index >= 6) {
                                                                                                            return (
                                                                                                                <div key={index} className='dropdown-item'>
                                                                                                                    <div style={{ all: 'unset', alignItems: 'center', display: 'flex' }} className='pointer' onClick={() => handleCheckboxOptionChange(option, type[0])}>
                                                                                                                        <img style= {{ maxWidth: '35px', display: 'inline-flex', borderRadius: '50%' }} title={option[1]?.firstname + ' ' + option[1]?.lastname} onError={(e) => { e.target.src = '/images/default-avatar.png'; } } src={option[1].picture ? (String(option[1].picture).includes('upload') ? recruitmentService.buildFileURL(option[1].picture) : option[1].picture) : '/images/default-avatar.png'}></img>
                                                                                                                        <span className='pl-2' style={{ overflow: 'hidden' }} title={option[1]?.firstname + ' ' + option[1]?.lastname}> {option[1]?.firstname + ' ' + option[1]?.lastname} </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                        return true;
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        );
                                    }
                                    return true;
                                })
                            }
                        </div>
                        <div className= 'row col-xl-12 col-12' style={{ zIndex: 1 }}>
                            <div className='col-xl-2 col-4'>
                                <div style={{ marginBottom: '10px' }}>
                                    <FormControl htmlFor='search-issue' variant='standard'>
                                        <InputLabel>Search issues</InputLabel>
                                        <Input
                                            id="search-issue"
                                            onChange={handleInputChange}
                                            autoFocus
                                            placeholder={searchParams?.issue}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <SearchIcon/>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {
                                Object.entries(options).map((option, index) => {
                                    if (option[0] !== 'assignTo' && option[0] !== 'createdBy') {
                                        return (
                                            <div key={index} className='col-xl-2 col-4'>
                                                <div style={{ marginBottom: '10px' }}>
                                                    {
                                                        <Select
                                                        isClearable
                                                        options={option[1]}
                                                        isMulti
                                                        onChange={handleSelectOptionChange}
                                                        name={option[0]}
                                                        placeholder={option[0].charAt(0).toUpperCase() + option[0].replace('Id', '').slice(1)}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                    return true;
                                })
                            }
                        </div>
                        <div className='col-xl-2 col-6 ml-auto p-3' style={{ alignSelf: 'center', display: 'none' }}>
                            <button ref={ButtonSubmitRef} style={{ width: '-webkit-fill-available' }} type='submit' className='btn btn-primary'><FormattedMessage id='button.name.common.search' /></button>
                        </div>
                    </form>
                </div>
        </>
    );
}
function mapStateToProps (state) {
    const project = state.projectInfo.data;
    return { project };
}

export default connect(mapStateToProps)(FilterComponent);
