import './ReviewSection.css';

export default function ReviewSection () {
    return (
        <section id="reviews" className="module carousel-section">
            <div className='container'>
            <div className="section-heading pb-5">
                <h2>What Our Customers Say</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati
                    dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                </p>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-md-offset-2 col-lg-4 res-margin">
                    <div className="single-review card">
                        <div className="card-top p-4">
                            <div className="review-icon">
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                            </div>
                            <h4 className="text-primary mt-4 mb-3">
                                Excellent service &amp; support!!
                            </h4>
                            <div className="review-text">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
                                    nam id facilis, provident doloremque placeat eveniet molestias
                                    laboriosam. Optio, esse.
                                </p>
                            </div>
                            <div className="quot-icon">
                                <img className="avatar-md" src="/img/quote.png" alt="" />
                            </div>
                        </div>
                        <div className="reviewer media bg-f6f9fe p-4">
                            <div className="reviewer-thumb">
                                <img
                                    className="avatar-lg radius-100"
                                    src="https://react.theme-land.com/img/avatar-1.png"
                                    alt=""
                                />
                            </div>
                            <div className="reviewer-meta media-body align-self-center ml-4">
                                <h5 className="reviewer-name color-primary mb-2">Junaid Hasan</h5>
                                <h6 className="text-secondary fw-6">CEO, Themeland</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-md-offset-2 col-lg-4 res-margin">
                    <div className="single-review card">
                        <div className="card-top p-4">
                            <div className="review-icon">
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star-half-alt fa-w-17 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star-half-alt"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 536 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star-half-alt text-warning"></i> */}
                            </div>
                            <h4 className="text-primary mt-4 mb-3">Nice work! Keep it up</h4>
                            <div className="review-text">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
                                    nam id facilis, provident doloremque placeat eveniet molestias
                                    laboriosam. Optio, esse.
                                </p>
                            </div>
                            <div className="quot-icon">
                                <img className="avatar-md" src="/img/quote.png" alt="" />
                            </div>
                        </div>
                        <div className="reviewer media bg-f6f9fe p-4">
                            <div className="reviewer-thumb">
                                <img
                                    className="avatar-lg radius-100"
                                    src="https://react.theme-land.com/img/avatar-2.png"
                                    alt=""
                                />
                            </div>
                            <div className="reviewer-meta media-body align-self-center ml-4">
                                <h5 className="reviewer-name color-primary mb-2">
                                    Jassica William
                                </h5>
                                <h6 className="text-secondary fw-6">Web Designer</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-md-offset-2 col-lg-4 res-margin">
                    <div className="single-review card">
                        <div className="card-top p-4">
                            <div className="review-icon">
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                                <svg
                                    className="svg-inline--fa fa-star fa-w-18 text-warning"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="star"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    data-fa-i2svg
                                >
                                    <path
                                        fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                    />
                                </svg>
                                {/* <i class="fas fa-star text-warning"></i> */}
                            </div>
                            <h4 className="text-primary mt-4 mb-3">Great support!!</h4>
                            <div className="review-text">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
                                    nam id facilis, provident doloremque placeat eveniet molestias
                                    laboriosam. Optio, esse.
                                </p>
                            </div>
                            <div className="quot-icon">
                                <img className="avatar-md" src="/img/quote.png" alt="" />
                            </div>
                        </div>
                        <div className="reviewer media bg-f6f9fe p-4">
                            <div className="reviewer-thumb">
                                <img
                                    className="avatar-lg radius-100"
                                    src="https://react.theme-land.com/img/avatar-3.png"
                                    alt=""
                                />
                            </div>
                            <div className="reviewer-meta media-body align-self-center ml-4">
                                <h5 className="reviewer-name color-primary mb-2">Johnson Smith</h5>
                                <h6 className="text-secondary fw-6">SEO Expert</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
}
