
import { connect } from 'react-redux';
import React, { useCallback, useState, useEffect, useMemo, Suspense, useLayoutEffect } from 'react';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import { setBreadcrumb } from '../../../common/actions/breadcrumb.action';
import dashboardService from './Services/dashboard.service';
import 'react-loading-skeleton/dist/skeleton.css';
import Calendar from '../../../common/components/common/Calendar';
import { useService } from '../../../common/helpers/serviceHelpers';
import recruitmentService from '../../../common/components/hrm/Services/recruitment.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import holidayService from '../../../common/components/hrm/Services/holiday.service';
import WelcomeDashboard from './WelcomeDashboard';
import DashboardSlider from './DashboardSlider';
import { ServiceConstants } from '../../../common/components/common/constants/service.constants';

const Activities = React.lazy(() => import('../../activity/components/Activity'));

function Dashboard ({ dispatch }) {
    const lang = localStorage.getItem('lang');

    const [projects, setPRJ] = useState([]);
    const [employees, setEMP] = useState([]);
    const [leaveRequests, setLR] = useState([]);
    const [birthdays, setBD] = useState([]);

    const { data: holidays } = useService(holidayService.getHoliday, {}, (res) => res.items);

    const fetchPRJ = useCallback(async () => {
        await dashboardService.getProjects().then(setPRJ);
    }, []);

    const fetchEMP = useCallback(() => {
        dashboardService.getEmployee().then(setEMP);
    }, []);

    const fetchLeaveToday = useCallback(() => {
        dashboardService.getAllLeaveRequestApproved().then(res => res.items).then(setLR);
    }, []);

    const fetchBD = useCallback(() => {
        dashboardService.getBirthday().then(setBD);
    }, []);

    const { data: interviews } = useService(
        recruitmentService.getInterviews,
        { limit: 999, isPage: 2 },
        (data) => data.items
    );

    useLayoutEffect(() => {
        document.title = TranslateHelper.getMessage('app.page.title.dashboard');
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.dashboard',
            breadcrumbItems: [
                {
                    label: 'menu.manager.name.dashboard',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.dashboard',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    });

    useEffect(fetchPRJ, [fetchPRJ]);
    useEffect(fetchEMP, [fetchEMP]);
    useEffect(fetchLeaveToday, [fetchLeaveToday]);
    useEffect(fetchBD, [fetchBD]);
    useEffect(() => {
        // Dong if vo nghia
        if (projects && employees && leaveRequests && birthdays) {
            const timeoutID = setTimeout(function () {
            }, 500);
            return () => {
                clearTimeout(timeoutID);
            };
        }
    }, []);

    const enumerateDaysBetweenDates = (fromDate, toDate) => {
        const dates = [];
        let currentDate = moment(fromDate);
        const endDate = moment(toDate);
        while (currentDate <= endDate) {
            dates.push(moment(currentDate).format(TranslateHelper.getMessage('date.format')));
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dates;
    };

    const events = useMemo(() => [
        ...interviews.map(item => ({
            title: item.candidateName,
            date: item.dateInterview,
            publicId: item._id,
            extendedProps: {
                icon: <i className='fas fa-user-clock pr-1'></i>,
                color: '#7593CE',
                type: 'Interview',
                date: item.dateInterview,
                interviews: item,
                iconText: 'fas fa-user-clock'
            }
        })),
        ...birthdays.map(item => ({
            title: item.firstname + ' ' + item.lastname,
            date: moment(item.birthDay).set('year', moment().year()).toDate(),
            publicId: item._id,
            extendedProps: {
                icon: <i className="fa-solid fa-birthday-cake pr-1"></i>,
                color: '#EDA6A6',
                type: 'Birthday',
                iconText: 'fa-solid fa-birthday-cake'
            },
            repeat: 2
        })),
        ...leaveRequests.reduce((current, item) => {
            const isWfhFlag = item.leaveType === ServiceConstants.ValWFH;
            const renderedTitle = isWfhFlag ? 'WFH Request' : 'Leave Request';
            current.push(
                ...enumerateDaysBetweenDates(item.fromDate, item.toDate).map((date) => ({
                    title: item.createdBy ? item.createdBy?.firstname + ' ' + item.createdBy?.lastname : renderedTitle,
                    date: moment(date, TranslateHelper.getMessage('date.format')).toDate(),
                    extendedProps: {
                        icon: <i className={`fa-solid ${isWfhFlag ? 'fa-house' : 'fa-calendar-xmark'} pr-1`}></i>,
                        color: '#FFCBA4',
                        type: isWfhFlag ? 'WFH' : 'Leave',
                        leaveFor: item.leaveFor,
                        iconText: `fa-solid ${isWfhFlag ? 'fa-house' : 'fa-calendar-xmark'} `
                    }
                }))
            );
            return current;
        }, []),
        ...holidays.reduce((current, item) => {
            current.push(
                ...enumerateDaysBetweenDates(item.fromDate, item.toDate).map((date) => ({
                    title: item.holiday,
                    date: new Date(date),
                    extendedProps: {
                        icon: <i className="fa-solid fa-plane-arrival pr-1"></i>,
                        type: 'Holiday',
                        color: '#CE494B',
                        iconText: 'fa-solid fa-plane-arrival'
                    }
                }))
            );

            return current;
        }, [])
    ], [interviews, birthdays, leaveRequests, holidays]);

    const handleDateClick = useCallback((calEvent) => {
        const event = events.reduce((current, item) => {
            if (moment(item.date).format('YYYY-MM-DD') === calEvent.dateStr) {
                current.push(item);
            }
            return current;
        }, []);

        let htmEvent = '';
        event.map(function (item) {
            const isWFH = ServiceConstants.ValWFH === item.leaveType;
            htmEvent += `<div key=${item.publicId} style="width: auto ;padding: 8px; margin: 10px; background: antiquewhite ; border-radius: 15px"><div style=" border-left: 5px solid #f0932b;  background: antiquewhite; margin: 10px">
                <div style="display: flex; flex-direction: row; text-align: initial; padding-left: 10px; justify-content: space-between">
                    <div>
                        <span style="color: #820404 ; font-weight: 600 ; text-transform: capitalize;">${item.title}</span>
                        <div>
                            <span style="font-style: italic; font-size: initial">${item.extendedProps.type}</span>
                            ${!isWFH && item.extendedProps.leaveFor === 0 ? '<span> - AM</span>' : !isWFH && item.extendedProps.leaveFor === 2 ? '<span> - PM</span>' : ''}
                        </div>
                    </div>
                    <div style="align-self: center; font-size: larger">
                        <i class="${item.extendedProps.iconText} " style="color: #f0932b"></i> 
                    </div>
                </div>
            </div></div>`;
            return htmEvent;
        });

        event.length > 0 && Swal.fire({
            title: moment(calEvent.date).format('MMM  DD, YYYY'),
            html: htmEvent,
            showConfirmButton: false
        });
    });

    return (
       <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-7 col-12'>
                    <WelcomeDashboard/>
                </div>
                <div className='col-lg-5 col-12'>
                    <DashboardSlider/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-7'>
                    <div className='card'>
                        <div className='card-body'>
                            <Calendar lang={lang} events={events} handleDateClick={handleDateClick} isShowRight='false' />
                        </div>
                    </div>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                    <div className='col-12 col-lg-5'>
                        <Activities isFilter={false}/>
                    </div>
                </Suspense>
            </div>
       </div>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(Dashboard);
