/* eslint-disable no-unused-vars */
import React, { Component, createRef } from 'react';
import issueService from '../services/issue.service';
import moment from 'moment';
import IssueDetailForm from './project-detail/issueDetailForm';
import {
    clearBreadcrumb,
    setBreadcrumb
} from '../../../actions/breadcrumb.action';
import FileUpload from './project-detail/FileUpload';
import { connect } from 'react-redux';
import recruitmentService from '../../hrm/Services/recruitment.service';
import socket from '../../common/Socket';
import MentionEditor from './MentionEditor';
import projectListService from '../services/project-list.service';
import { Link, withRouter } from 'react-router-dom';
import TranslateHelper from '../../../helpers/TranslateHelper';
import './IssueDetail.css';
import Editor from '../../EdittorToolbar';
import IssueCommentBox from './IssueCommentBox';
import Button from '@atlaskit/button';
import {
    ISSUE_PRIORITY,
    PROJECT_ROLE
} from '../../../constants/project.constant';
import IssueDetailHistory from './IssueDetailHistory';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import Avatar from '@atlaskit/avatar';
import CardHeader from '@mui/material/CardHeader';
import { Chip, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import CopyText from '../../common/CopyText';
import InlineEdit from '@atlaskit/inline-edit';
import Select from '@atlaskit/select';
import { xcss, Box } from '@atlaskit/primitives';
import $ from 'jquery';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ToastTopEnd } from '../../../helpers/ToastTimer';
import { LanguageDatatables } from '../../../../../lang';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';

const renderPriority = (priority) => {
    switch (priority) {
        case ISSUE_PRIORITY.LOW:
            return <i title='Low' className="fas fa-angle-double-down text-primary"></i>;
        case ISSUE_PRIORITY.MEDIUM:
            return <i title='Medium' className="fas fa-equals text-warning"></i>;
        case ISSUE_PRIORITY.HIGH:
            return <i title='High' className="fas fa-angle-double-up text-danger"></i>;
        default:
            break;
    }
};

const renderRow = (data, projectId) => {
    return data.map((item) => {
        return (
            <tr key={item._id}>
                <td>{item.issueCode}</td>
                <td><Link target="_blank" style={{ color: '#4f96ac' }} to={{ pathname: item.issueCode, state: { issueCode: item.issueCode, subject: item.subject } }}><p title={item.subject} style={{ textTransform: 'none' }}>{item.subject}</p></Link></td>
                <td>
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.assignTo?.picture ? (String(item.assignTo?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.assignTo?.picture) : item.assignTo?.picture) : '/images/default-avatar.png'} alt='user-image'/>
                        <div title={(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'} className='employee-name'>{(item.assignTo?.lastname && item.assignTo?.firstname) ? item.assignTo?.firstname + ' ' + item.assignTo?.lastname : 'Unassignee'}</div>
                    </div>
                </td>
                <td>{item.status?.name}</td>
                <td align='center'>
                    {renderPriority(item.priority)}
                </td>
                <td>{moment(item.createdAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                <td>{item.startDate && item.startDate && moment(item.startDate).format(TranslateHelper.getMessage('date.format'))}</td>
                <td>{item.dueDate && moment(item.dueDate).format(TranslateHelper.getMessage('date.format'))}</td>
                <td>{item.estimateTime}</td>
                <td>{item.actualTime}</td>
                <td>{moment(item.updatedAt).format(TranslateHelper.getMessage('date.format') + ' HH:mm')}</td>
                <td>
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <img width={'30'} height={'30'} className='img-circle img-bordered-sm' onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.createdBy?.picture ? (String(item.createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(item.createdBy?.picture) : item.createdBy?.picture) : '/images/default-avatar.png'} alt='user-image'/>
                        <div title={item.createdBy?.firstname + ' ' + item.createdBy?.lastname} className='employee-name'>{item.createdBy?.firstname} {item.createdBy?.lastname}</div>
                    </div>
                </td>
            </tr>
        );
    });
};

function CustomTabPanel (props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

class IssueDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showing: false,
            editorState: '',
            isEdit: {
                description: false,
                subject: false,
                issueType: false
            },
            editValue: {},
            errors: {
                subject: ''
            },
            form: {
                subject: '',
                description: ''
            },
            dataIssue: {},
            mentions: [],
            comments: [],
            nextPage: 1,
            totalComments: 0,
            isFetchingComments: false,
            isManager: false,
            isShowComments: true,
            isShowHistory: false,
            issueHistory: [],
            isLoading: false,
            resetContent: undefined,
            isHasProjectData: false,
            projectData: {},
            issueUrl: '',
            tabPanel: 0
        };
        this.editorRef = null;
        this.onEditorChange = this.onEditorChange.bind(this);
        this.getIssueDetail = this.getIssueDetail.bind(this);
        this.getComments = this.getComments.bind(this);
        this.user = props.user;
        this.onShowComments = this.onShowComments.bind(this);
        this.onShowHistory = this.onShowHistory.bind(this);
        this.wrapperRef = createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.getIssueTypes = this.getIssueTypes.bind(this);
        this.issueOptions = [{ value: null, label: '' }];
        this.handleChangeIssueType = this.handleChangeIssueType.bind(this);
        this.myInputRef = React.createRef();
        this.initDatatable = this.initDatatable.bind(this);
    }

    getIssueTypes () {
        issueService
            .getIssueTypes(this.props.match.params.projectSlug)
            .then((data) => {
                this.issueOptions = data.map((issue) => ({
                    value: issue._id,
                    label: issue.issueType
                }));
            });
    }

    getComments (reset = false) {
        const force = reset === true;
        if (!reset && !this.state.nextPage) {
            return;
        }
        this.setState({ isFetchingComments: true });
        issueService
            .getComments({
                id: this.props.match.params.issueId,
                page: force ? 1 : this.state.nextPage
            })
            .then((res) => {
                this.setState({
                    comments: force
                        ? res.data.data.items
                        : [...this.state.comments, ...res.data.data.items],
                    nextPage: res.data.data.nextPage,
                    totalComments: res.data.data.total
                });
            })
            .finally(() => {
                this.setState({ isFetchingComments: false });
            });
        this.forceUpdate();
    }

    componentDidMount () {
        this.getIssueTypes();
        this.getIssueDetail();
        socket.connect();
        socket.emit('addUser', this.props.user.userId);
        socket.on('getComment', (data) => {
            if (this.props.location.pathname.split('/')[4] === data.issueKey) {
                // this.setState({ dataComment: [...this.state.comments, data] });
                this.getComments(true);
            }
        });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#issues-list').DataTable({
                fixedHeader: true,
                paging: false,
                lengthChange: false,
                searching: false,
                info: true,
                autoWidth: true,
                responsive: true,
                scrollX: true,
                language: LanguageDatatables[lang],
                order: [6, 'decs'],
                fnDrawCallback: function (oSettings) {
                    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                    } else {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                    }
                }
            });
            this.setState({ table });
        }.bind(this));
    }

    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside (event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.current?.contains(event.target)
        ) {
            this.state.isEdit.subject &&
                this.setState((prevState) => ({
                    isEdit: { ...prevState.isEdit, subject: false }
                }));
            this.state.isEdit.issueType &&
                this.setState((prevState) => ({
                    isEdit: { ...prevState.isEdit, issueType: false }
                }));
        }
    }

    handleInputChange (name, event) {
        const { form, errors } = this.state;
        switch (name) {
            case 'subject':
            case 'description':
                form[name] = event.target.value;
                errors[name] = '';
                break;
            default:
                break;
        }
        this.setState({ errors, form });
    }

    handleUpdate () {
        const { isEdit } = this.state;
        const payload = {};

        if (isEdit.description) {
            payload.description = this.state.editorState;
        } else {
            this.state.form.subject === ''
                ? this.setState({
                    errors: {
                        subject: TranslateHelper.getMessage(
                            'validation.error.required'
                        )
                    }
                })
                : (payload.subject = this.state.form.subject);
        }
        issueService
            .updateIssue(
                this.props.match.params.projectSlug,
                this.props.match.params.issueId,
                payload
            )
            .then((res) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        form: {},
                        dataIssue: {
                            ...prevState.dataIssue,
                            description: res?.description,
                            subject: res?.subject
                        },
                        isEdit: { description: false, subject: false }
                    };
                });
                document.title = `[${this.state.dataIssue?.issueCode}] ${this.state.dataIssue.subject}`;
                this.setState({
                    issueUrl: `${this.props.match.params.issueId} ${this.state.dataIssue.subject}`
                });
            });
    }

    handleChangeIssueType (selected) {
        if (selected?.value) {
            const payload = {
                issueTypeId: selected.value
            };
            issueService
                .updateIssue(
                    this.props.match.params.projectSlug,
                    this.props.match.params.issueId,
                    payload
                )
                .then((data) => {
                    if (data) {
                        this.setState((prevState) => {
                            return {
                                ...prevState,
                                form: {},
                                dataIssue: {
                                    ...prevState.dataIssue,
                                    issueTypeId: data.issueTypeId
                                },
                                isEdit: {
                                    ...prevState.isEdit,
                                    issueType: false
                                }
                            };
                        });
                    }
                });
        }
    }

    onEditorChange (editorState) {
        this.setState({ editorState });
    }

    getIssueDetail () {
        issueService
            .getIssueDetail(this.props.match.params.issueId)
            .then((data) => {
                this.setState({ dataIssue: data[0], form: data[0] });
                this.state.resetContent &&
                    this.state.resetContent.setContents(data[0]?.description);
                document.title = `[${this.state.dataIssue.issueCode}] ${this.state.dataIssue.subject}`;
                this.setState({
                    issueUrl: `${this.props.match.params.issueId} ${this.state.dataIssue.subject}`
                });
                this.getProjectMember();
                this.forceUpdate();
            })
            .catch(() => {
                this.props.history.push({
                    pathname: '/not-found',
                    state: { isRedirect: true }
                });
            });
    }

    getProjectMember () {
        const { dispatch } = this.props;
        projectListService
            .getOne(this.props.match.params.projectSlug)
            .then((res) => {
                this.setState({ isHasProjectData: true, projectData: res });
                const list = res.members.map((item) => ({
                    id: item.user?._id,
                    name: `${item.user?.firstname} ${item.user?.lastname}`,
                    avatar: item.user?.picture
                        ? !String(item.user?.picture).includes('uploads')
                                ? item.user?.picture
                                : recruitmentService.buildFileURL(
                                    item.user?.picture
                                )
                        : window.location.origin + '/images/default-avatar.png'
                }));
                dispatch(
                    setBreadcrumb({
                        titleVar: `[${this.state.dataIssue?.issueCode}] ${this.state.dataIssue?.subject}`,
                        breadcrumbItems: [
                            {
                                label: 'app.breadcrumb.common.home',
                                haslink: true,
                                path: '/dashboard'
                            },
                            {
                                label: 'menu.member.name.project',
                                haslink: true,
                                path: '/projects'
                            },
                            {
                                varLabel: res.name,
                                haslink: true,
                                path: '/projects/' + res.identifier
                            },
                            {
                                varLabel: this.props.match.params.issueId,
                                haslink: true,
                                path: ''
                            }
                        ]
                    })
                );
                this.setState({ mentions: list });
                const user = res.members.filter(
                    (user) => user.user?._id === this.props.user.userId
                );
                if (
                    user &&
                    (user[0]?.role === PROJECT_ROLE.MANAGER ||
                        user[0]?.role === PROJECT_ROLE.REPORTER)
                ) {
                    this.setState({ isManager: true });
                }
                this.getComments();
                this.forceUpdate();
            })
            .catch(() => {
                this.props.history.push({
                    pathname: '/not-found',
                    state: { isRedirect: true }
                });
            });
    }

    setResetContent (quill) {
        this.setState({ resetContent: quill });
    }

    renderPriority (priority) {
        switch (priority) {
            case ISSUE_PRIORITY.LOW:
                return (
                    <span>
                        <i className="fas fa-angle-double-down text-primary"></i>{' '}
                        Low
                    </span>
                );
            case ISSUE_PRIORITY.MEDIUM:
                return (
                    <span>
                        <i className="fas fa-equals text-warning"></i> Medium
                    </span>
                );
            case ISSUE_PRIORITY.HIGH:
                return (
                    <span>
                        <i className="fas fa-angle-double-up text-danger"></i>{' '}
                        Hight
                    </span>
                );
            default:
                break;
        }
    }

    onShowComments () {
        this.setState({ totalComments: 0 });
        let { isShowComments, isShowHistory } = this.state;
        isShowComments = true;
        isShowHistory = false;
        this.setState({ isShowComments, isShowHistory });
        this.getComments(true);
    }

    onShowHistory () {
        let { isShowComments, isShowHistory, isLoading } = this.state;
        isShowComments = false;
        isShowHistory = true;
        isLoading = true;
        this.setState({ isShowComments, isShowHistory, isLoading });
        issueService
            .getIssueHistory(this.props.match.params.issueId)
            .then((res) => {
                this.setState({ issueHistory: res });
                this.setState({ isLoading: false });
            });
    }

    activitiesLoading () {
        return (
            <div>
                <div className="row pt-2 pl-3">
                    <div className="pr-2">
                        <Skeleton
                            height={'50px'}
                            width={'50px'}
                            circle={true}
                        />
                    </div>
                    <div>
                        <div>
                            <Skeleton width={'100px'} />
                        </div>
                        <div>
                            <Skeleton width={'200px'} />
                        </div>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="pr-2 col-8">
                        <Skeleton />
                    </div>
                    <div className="pr-2 col-3">
                        <Skeleton />
                    </div>
                </div>
            </div>
        );
    }

    render () {
        const { dataIssue, isEdit, errors, isShowComments, isShowHistory } =
            this.state;
        const currentIssueType = this.issueOptions.find(
            (item) => item.value === dataIssue.issueTypeId?._id
        );

        return (
            <>
                <div className="container-fluid">
                    {dataIssue.issueCode && (
                        <span
                            className="text-primary"
                            role="button"
                            onClick={() => {
                                this.props.history.push(
                                    `/projects/${
                                        dataIssue.issueCode.split('-')[0]
                                    }?mainTab=Issues`
                                );
                            }}
                        >
                            <i className="fas fa-arrow-left"></i> Back to issues
                        </span>
                    )}
                    <div className="d-flex header mt-4">
                        <div className="left" style={{ minWidth: '100px' }}>
                            <InlineEdit
                                xcss={xcss({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    margin: 0
                                })}
                                hideActionButtons
                                editView={({ errorMessage, ...fieldProps }) => (
                                    <Select
                                        isSearchable={false}
                                        {...fieldProps}
                                        defaultValue={currentIssueType}
                                        options={this.issueOptions}
                                        autoFocus
                                        openMenuOnFocus
                                        name="test"
                                        placeholder={'check'}
                                    />
                                )}
                                readView={() => (
                                    <Box
                                        xcss={xcss({
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            margin: 0
                                        })}
                                    >
                                        <Chip
                                            label={
                                                dataIssue.issueTypeId?.issueType
                                            }
                                            color="success"
                                        />
                                    </Box>
                                )}
                                onEdit={() =>
                                    this.setState((prevState) => ({
                                        isEdit: {
                                            ...prevState.isEdit,
                                            issueType: true
                                        }
                                    }))
                                }
                                onConfirm={(value) =>
                                    this.handleChangeIssueType(value)
                                }
                                readViewFitContainerWidth
                            />
                        </div>
                        {!isEdit.issueType && (
                            <div className="middle d-flex">
                                <span className="p-2">
                                    {this.props.match.params.issueId}
                                </span>
                                <span>
                                    <CopyText
                                        text={this.state.issueUrl}
                                        title="Copy issue key and title"
                                    />
                                </span>
                            </div>
                        )}
                        <div className="right ml-auto">
                            <p>
                                <span className="estimate-date">
                                    {TranslateHelper.getMessage(
                                        'project.label.name.startDate'
                                    )}
                                </span>{' '}
                                {dataIssue.startDate &&
                                    moment(dataIssue?.startDate).format(
                                        TranslateHelper.getMessage(
                                            'date.format'
                                        )
                                    )}{' '}
                                -{' '}
                                <span className="estimate-date">Due Date</span>{' '}
                                {dataIssue.dueDate &&
                                    moment(dataIssue?.dueDate).format(
                                        TranslateHelper.getMessage(
                                            'date.format'
                                        )
                                    )}
                                <span className="p-1 px-2">
                                    {' '}
                                    <Chip
                                        label={dataIssue.status?.name}
                                        color="info"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {isEdit.subject
                                ? (
                                <>
                                    <div ref={this.wrapperRef}>
                                        <input
                                            type="text"
                                            placeholder="Subject"
                                            value={this.state.form.subject}
                                            onChange={this.handleInputChange.bind(
                                                this,
                                                'subject'
                                            )}
                                            className={
                                                errors.subject.length > 0
                                                    ? 'is-invalid form-control'
                                                    : 'form-control p-2'
                                            }
                                        />
                                        <div className="d-inline-block float-right my-1">
                                            <div className="btn-group pointer">
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleUpdate()
                                                    }
                                                    appearance="primary"
                                                    className="btn-md mr-2"
                                                >
                                                    <span>
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.setState(
                                                            (prevState) => ({
                                                                isEdit: {
                                                                    ...prevState.isEdit,
                                                                    subject: false
                                                                }
                                                            })
                                                        );
                                                    }}
                                                    className="btn-md"
                                                >
                                                    <span>
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    )
                                : (
                                <p
                                    onDoubleClick={() => {
                                        this.setState((prevState) => ({
                                            form: {
                                                subject: dataIssue?.subject
                                            },
                                            isEdit: {
                                                ...prevState.isEdit,
                                                subject: true
                                            }
                                        }));
                                    }}
                                    className="issue-subject-detail text-break p-2"
                                >
                                    {dataIssue?.subject}
                                </p>
                                    )}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                size="large"
                                                src={
                                                    dataIssue.createdBy?.picture
                                                        ? String(
                                                            dataIssue
                                                                .createdBy
                                                                ?.picture
                                                        ).includes('uploads')
                                                            ? recruitmentService.buildFileURL(
                                                                dataIssue
                                                                    .createdBy
                                                                    ?.picture
                                                            )
                                                            : dataIssue
                                                                .createdBy
                                                                ?.picture
                                                        : '/images/default-avatar.png'
                                                }
                                            />
                                        }
                                        title={
                                            <Typography variant="h6">
                                                {dataIssue.createdBy?.firstname}{' '}
                                                {dataIssue.createdBy?.lastname}
                                            </Typography>
                                        }
                                        subheader={`${moment(
                                            dataIssue.createdAt
                                        ).format('MM/DD/YYYY hh:mm:ss A')}`}
                                    />
                                </div>
                                {!this.state.isEdit.description
                                    ? (
                                    <div
                                        className="mb-3 p-2 description-issue-detail"
                                        onDoubleClick={() => {
                                            this.setState((prevState) => ({
                                                isEdit: {
                                                    ...prevState.isEdit,
                                                    description: true
                                                }
                                            }));
                                        }}
                                        style={{
                                            cursor: 'text',
                                            opacity: '0.9'
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: issueService.markDownHTML(
                                                this.state.dataIssue
                                                    .description ||
                                                    TranslateHelper.getMessage(
                                                        'popup.issue.add.description.isBlank'
                                                    )
                                            )
                                        }}
                                    ></div>
                                        )
                                    : (
                                    <>
                                        <div ref={this.wrapperRef}>
                                            <Editor
                                                toolbarId={'descriptionEditor'}
                                                placeholder={
                                                    'Write description here !'
                                                }
                                                defaultValue={
                                                    this.state.dataIssue
                                                        .description
                                                }
                                                height="300px"
                                                onChange={this.onEditorChange}
                                            />
                                            <div className="btn-group pointer d-block mt-1">
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleUpdate()
                                                    }
                                                    appearance="primary"
                                                    className="btn-md mr-2 mb-3"
                                                >
                                                    <span>
                                                        {TranslateHelper.getMessage(
                                                            'button.name.common.save'
                                                        )}
                                                    </span>
                                                </Button>
                                                <Button
                                                    className="btn-md mb-3"
                                                    style={{ margin: '0 2px' }}
                                                    onClick={() => {
                                                        this.setState(
                                                            (prevState) => ({
                                                                isEdit: {
                                                                    ...prevState.isEdit,
                                                                    description: false
                                                                }
                                                            })
                                                        );
                                                    }}
                                                >
                                                    <span>
                                                        {TranslateHelper.getMessage(
                                                            'button.name.common.cancel'
                                                        )}
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                        )}
                                {this.state.isHasProjectData && (
                                    <IssueDetailForm
                                        isManager={this.state.isManager}
                                        projectId={
                                            this.props.match.params.projectSlug
                                        }
                                        issueDetail={this.state.dataIssue}
                                        issueId={
                                            this.props.match.params.issueId
                                        }
                                        refetchIssue={this.getIssueDetail}
                                        projectData={this.state.projectData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={this.state.tabPanel}
                            onChange={(e, val) => {
                                this.setState((prev) => ({
                                    ...prev,
                                    tabPanel: val
                                }));
                            }}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Comments" {...a11yProps(0)} />
                            <Tab label="Subtask" {...a11yProps(1)} />
                            <Tab label="Attachments" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel className="p-2" value={this.state.tabPanel} index={2}>
                        <FileUpload
                            projectId={this.props.match.params.projectSlug}
                            files={this.state.dataIssue.files}
                            issueId={this.props.match.params.issueId}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel className="p-2" value={this.state.tabPanel} index={1}>
                        {this.state.dataIssue.childIssues?.length
                            ? (<div className='card'>
                                <div className='card-body'>
                                    <div className='issue-table-content' style={{ overflowX: 'auto' }}>
                                        <table id="issues-list" className="table table-bordered table-hover">
                                            <thead>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.issuecode" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.subject" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.assignee" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.status" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.priority" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.createdAt" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.startDate" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.dueDate" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.estimateHours" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.actualHours" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.updatedAt" /></th>
                                                <th><FormattedMessage id="project.detail.issues.table.column.name.createdBy" /></th>
                                            </thead>
                                            <tbody>
                                                {renderRow(this.state.dataIssue.childIssues, this.props.match.params.projectSlug)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)
                            : <div className="mt-3"><h4 className='px-2'><FormattedMessage id="project.detail.new.label.notavailable" /></h4></div>}
                    </CustomTabPanel>
                    <CustomTabPanel className="p-2" value={this.state.tabPanel} index={0}>
                    <div
                        className="d-flex mb-2"
                        style={{ alignItems: 'baseline' }}
                    >
                        <span className="pl-2 mb-0 mt-2">
                            {TranslateHelper.getMessage(
                                'popup.issue.label.activity.show'
                            )}
                        </span>
                        <div
                            onClick={this.onShowComments}
                            className={`btn ${
                                isShowComments ? 'btn-secondary' : 'btn-light'
                            } btn-sm ml-2`}
                        >
                            <span>
                                {TranslateHelper.getMessage(
                                    'popup.issue.button.activity.show.comment'
                                )}
                            </span>
                        </div>
                        <div
                            onClick={this.onShowHistory}
                            className={`btn ${
                                isShowHistory ? 'btn-secondary' : 'btn-light'
                            } btn-sm ml-2`}
                        >
                            <span>
                                {TranslateHelper.getMessage(
                                    'popup.issue.button.activity.show.history'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="activity-content">
                        <div className="card">
                            <div className="card-body">
                                {isShowComments
                                    ? (
                                    <>
                                        <MentionEditor
                                            isClass={true}
                                            isPopup={false}
                                            mentions={this.state.mentions}
                                            fetchIssueDetail={this.getComments}
                                            issueId={
                                                this.props.match.params.issueId
                                            }
                                            userId={this.props.user.userId}
                                            userInfo={this.props.user}
                                        />
                                        <div className="comment-content">
                                            <div className="pb-3 pt-3 font-weight-bold comment-length">
                                                <FormattedMessage id="project.detail.new.label.comments" />
                                                <span>
                                                    {this.state.totalComments}
                                                </span>
                                            </div>
                                            {this.state.isFetchingComments
                                                ? (
                                                        new Array(2)
                                                            .fill(null)
                                                            .map(this.activitiesLoading)
                                                    )
                                                : this.state.totalComments > 0
                                                    ? (
                                                            this.state.comments.map(
                                                                (comment, index) => (
                                                        <IssueCommentBox
                                                            key={index}
                                                            mentions={
                                                                this.state
                                                                    .mentions
                                                            }
                                                            dataComment={
                                                                comment
                                                            }
                                                            issueAuthor={
                                                                this.state
                                                                    .dataIssue
                                                                    .createdBy
                                                                    ?._id
                                                            }
                                                            fetchDataComment={() =>
                                                                this.getComments(
                                                                    true
                                                                )
                                                            }
                                                            issueCode={
                                                                this.state
                                                                    .dataIssue
                                                                    .issueCode
                                                            }
                                                            projectId={
                                                                this.props.match
                                                                    .params
                                                                    .projectSlug
                                                            }
                                                        />
                                                                )
                                                            )
                                                        )
                                                    : (
                                                <></>
                                                        )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {this.state.nextPage &&
                                                this.state
                                                    .isFetchingComments && (
                                                    <span>
                                                        {TranslateHelper.getMessage(
                                                            'project.label.name.fetching'
                                                        )}
                                                    </span>
                                            )}
                                            {this.state.nextPage &&
                                                !this.state
                                                    .isFetchingComments && (
                                                    <Button
                                                        onClick={
                                                            this.getComments
                                                        }
                                                    >
                                                        {TranslateHelper.getMessage(
                                                            'button.name.common.viewRemaining'
                                                        )}{' '}
                                                        {this.state
                                                            .totalComments -
                                                            this.state.comments
                                                                .length}{' '}
                                                        {TranslateHelper.getMessage(
                                                            'button.name.common.comments'
                                                        )}
                                                    </Button>
                                            )}
                                        </div>
                                    </>
                                        )
                                    : (
                                    <div className="issue-detail-history">
                                        <IssueDetailHistory
                                            data={this.state.issueHistory}
                                            isLoading={this.state.isLoading}
                                            issueInfor={this.state.projectData}
                                        />
                                    </div>
                                        )}
                            </div>
                        </div>
                    </div>
                    </CustomTabPanel>
                </div>
            </>
        );
    }
}
function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default withRouter(connect(mapStateToProps)(IssueDetail));
