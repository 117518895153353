// import HeroSection from './components/Hero';
import HomeNavbar from './components/Navbar';
import { useEffect } from 'react';
import Featured from './components/FeaturedSection';
// import Header from './components/Header';
import HeroSection from './components/Hero';
import ProductSection from './components/ProductSection';
import './LandingPage.css';
import ReviewSection from './components/ReviewSection';
import Footer from './components/footer';

export default function LandingPage ({ connection, isLoggedIn, role, dispatch }) {
    useEffect(() => {
        return () => {
            window.location.reload();
        };
    }, []);
    return (
       <main id='landing'>
           <HomeNavbar connection={connection} isLoggedIn={isLoggedIn} role={role} dispatch={dispatch} />
           <HeroSection/>
           <Featured/>
           <ProductSection/>
           <ReviewSection/>
           <Footer/>
       </main>
    );
}
