import { useEffect, useState } from 'react';
import projectListService from '../../../services/project-list.service';
import CreateProjectForm from '../../CreateProjectForm';

export default function General ({ projectId }) {
    const [project, setProject] = useState(null);

    useEffect(() => {
        projectListService.getOne(projectId)
            .then((projectData) => {
                setProject(projectData);
            });
    }, []);
    return (
        <div className='container-fluid'>
            <CreateProjectForm project={project} projectId={projectId} />
        </div>
    );
}
