import Overview from './project-detail/Overview';
import Activities from './project-detail/Activities';
import Issues from './project-detail/Issues';
import Settings from './project-detail/Settings';
import Board from './project-detail/Board';
import GanttChartTab from './project-detail/GanntChart';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useEffect, useState } from 'react';
import { setBreadcrumb } from '../../../actions/breadcrumb.action';
import { connect, useDispatch } from 'react-redux';
import projectListService from '../services/project-list.service';
import { useLocation, useHistory } from 'react-router-dom';
import TranslateHelper from '../../../helpers/TranslateHelper';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import Wiki from './project-detail/Wiki';
import { setProjectInfo, setRoleInProject } from '../../../actions/project.action';

const listTabs = [
    TranslateHelper.getMessage('project.tabs.name.overview'),
    TranslateHelper.getMessage('project.tabs.name.issues'),
    TranslateHelper.getMessage('project.tabs.name.board'),
    TranslateHelper.getMessage('project.tabs.name.ganttChart'),
    TranslateHelper.getMessage('project.tabs.name.activities'),
    TranslateHelper.getMessage('project.tabs.name.wiki'),
    TranslateHelper.getMessage('project.tabs.name.settings')
];
const settingTabs = [
    TranslateHelper.getMessage('project.detail.settings.tabs.name.general'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.member'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.issuetype'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.category'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.status'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.milestone'),
    TranslateHelper.getMessage('project.detail.settings.tabs.name.tag')
];

const getRoleInProject = (members, userId) => {
    const user = members.find(user => user.user?._id === userId);
    return user?.role;
};

function ProjectDetail ({ match, user }) {
    const dispatch = useDispatch();
    const stateLocation = useLocation();
    const board = (new URLSearchParams(stateLocation.search)).get('mainTab');
    const history = useHistory();
    const [isAccess, setIsAccess] = useState(false);
    const [projectName, setProjectName] = useState('...');
    const [total, setTotal] = useState(0);
    const [tabIndex, setTabIndex] = useState(() => {
        const searchParams = new URLSearchParams(stateLocation.search);
        const mainTab = searchParams.get('mainTab');
        const tabIndex = listTabs.indexOf(mainTab);
        return tabIndex === -1 ? 0 : tabIndex;
    });

    const [settingIndex, setSettingIndex] = useState(() => {
        const searchParams = new URLSearchParams(stateLocation.search);
        const subTab = searchParams.get('subTab');
        const tabIndex = settingTabs.indexOf(subTab);
        return tabIndex === -1 ? 0 : tabIndex;
    });

    useEffect(() => {
        projectListService.getOne(match.params.projectSlug).then((res) => {
            let roleInProject;
            if (res?.members && user?.userId) {
                roleInProject = getRoleInProject(res.members, user.userId);
                dispatch(setRoleInProject(roleInProject || null));
            }
            dispatch(setProjectInfo(res));
            setProjectName(res.name);
            setTotal(res.issues.length);
            setIsAccess(true);
        }).catch(() => {
            history.push({
                pathname: '/not-found',
                state: { isRedirect: true }
            });
        });
        document.title = `[${match.params.projectSlug}] ${projectName} | Project Home | Kimei Console`;
        dispatch(setBreadcrumb({
            titleVar: `[${match.params.projectSlug}] ${projectName} | Project Home | Kimei Console`,
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.project',
                    haslink: true,
                    path: '/projects'
                },
                {
                    varLabel: projectName,
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch, projectName]);

    useEffect(() => {
        let url = '';
        const searchParams = new URLSearchParams(stateLocation.search);
        const query = {
            milestone: searchParams.get('milestone') || undefined,
            assignee: searchParams.get('assignee') || undefined,
            status: searchParams.get('status') || undefined,
            category: searchParams.get('category') || undefined,
            priority: searchParams.get('priority') || undefined,
            type: searchParams.get('type') || undefined,
            creator: searchParams.get('creator') || undefined,
            q: searchParams.get('q') || undefined,
            issueModal: searchParams.get('issueModal') || undefined
        };
        const q = queryString.stringify(query);
        if (q && board === 'Board') {
            url = `/projects/${match.params.projectSlug}?mainTab=${listTabs[tabIndex]}&${q}`;
        } else {
            url = `/projects/${match.params.projectSlug}?mainTab=${listTabs[tabIndex]}`;
            if (tabIndex === listTabs.length - 1) {
                url += `&subTab=${settingTabs[settingIndex]}`;
            }
        }
        history.push(url);
    }, [tabIndex, settingIndex, match.params.projectSlug, board]);

    return (
        <>
            {isAccess
                ? (
                <>
                    <Tabs className="column" id='projects' selectedIndex={tabIndex} onSelect={setTabIndex}>
                        <TabList className="d-flex tab-row">
                            {listTabs.map((tab, index) => (
                                <Tab key={index}>{tab}</Tab>
                            ))}
                        </TabList>
                        <div className="">
                            <TabPanel>
                                <Overview projectId={match.params.projectSlug}/>
                            </TabPanel>
                            <TabPanel>
                                <Issues projectId={match.params.projectSlug} projectName={projectName}/>
                            </TabPanel>
                            <TabPanel>
                                <Board projectId={match.params.projectSlug} projectName={projectName} total={total}/>
                            </TabPanel>
                            <TabPanel>
                                <GanttChartTab projectId={match.params.projectSlug}/>
                            </TabPanel>
                            <TabPanel>
                                <Activities projectId={match.params.projectSlug} setProjectTab={setTabIndex} settingIndex={setSettingIndex}/>
                            </TabPanel>
                            <TabPanel>
                                <Wiki projectId={match.params.projectSlug}/>
                            </TabPanel>
                            <TabPanel className="tab-row">
                                <Settings onSelect={setSettingIndex} tabs={settingTabs} projectId={match.params.projectSlug} tabIndex={settingIndex}/>
                            </TabPanel>
                        </div>
                    </Tabs>
                </>
                    )
                : (
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                            <div className='col-xl-2'>
                                <Skeleton width={'100%'} height={'50px'}/>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Skeleton width={'100%'} height={'200px'}/>
                        </div>
                        <div className='mt-4'>
                            <Skeleton width={'100%'} height={'200px'}/>
                        </div>
                    </div>
                    )}
        </>
    );
}

function mapStateToProps (state) {
    const { user } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        user,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(ProjectDetail);
