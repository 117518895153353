import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectList from './ProjectList';
import ProjectDetail from './ProjectDetail';
import NewProject from './NewProject';
import IssueDetail from './IssueDetail';

class ProjectManagement extends Component {
    render () {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}`} component={ProjectList} />
                <Route path={`${match.url}/create`} component={NewProject} />
                <Route path={`${match.url}/:projectSlug/issues/:issueId/`} component={IssueDetail} />
                <Route path={`${match.url}/:projectSlug/`} component={ProjectDetail} />
            </Switch>
        );
    }
}

export default ProjectManagement;
