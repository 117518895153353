import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import RealmDetail from './RealmDetail';
import RealmList from './RealmList';

class RealmManagement extends Component {
    render () {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}`} component={RealmList} />
                <Route path={`${match.url}/:realmId`} component={RealmDetail} />
            </Switch>
        );
    }
}

export default RealmManagement;
