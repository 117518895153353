import { ACTION_BREADCRUMB } from '../constants/action.constant';

export const setBreadcrumb = (breadcrumb) => ({
    type: ACTION_BREADCRUMB.SET_BREADCRUMB,
    payload: breadcrumb
});

export const clearBreadcrumb = () => ({
    type: ACTION_BREADCRUMB.CLEAR_BREADCRUMB,
    payload: {
        title: 'app.breadcrumb.common.dashboard',
        breadcrumbItems: []
    }
});
