import AxiosService from '../../../services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnermember/sandboxes';

class DashboardService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
    }

    getStatisticSandbox () {
        const clientIdActived = Cookies.get('actived');
        AxiosService.setHeader('authorization', 'Bearer ' + Cookies.get(Cookies.get('actived')));
        return AxiosService.get(API_URL + '/dashboard/' + clientIdActived + '/statistic');
    }
}

export default new DashboardService();
