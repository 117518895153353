import TranslateHelper from '../../../common/helpers/TranslateHelper';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export default function WelcomeDashboard () {
    return (
        <div className="card dashboard-welcome">
            <div className='card-body'>
                <Box marginTop={'20px'}>
                    <Typography component={'h1'} variant={'h4'} color='#ffffff' fontWeight='bold'>
                        {TranslateHelper.getMessage('app.page.common.dashboard.welcomeback')}
                    </Typography>
                    <Typography variant={'subtitle1'} color='#ffffff' component={'h4'} fontWeight='bold'>
                        {TranslateHelper.getMessage('app.page.title.default')}
                    </Typography>
                    <Typography color='#ffffff'>
                        Have a nice day!
                    </Typography>
                </Box>
                <Box position={'absolute'} bottom={'20px'}>
                    <Link to={'/projects'} className='text-decoration-none'>
                        <Button variant='contained' color='secondary'>
                            <Typography variant='body1'>
                                Go projects
                            </Typography>
                        </Button>
                    </Link>
                </Box>
            </div>
        </div>
    );
}
