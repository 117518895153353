import { regexFloat } from '../../../../constants/validation.constant';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Button from '@atlaskit/button';
export const UpdateLeaves = ({ setIsOpen, updateLeaveForm, handleUpdateLeaves }) => {
    return (
        <div className="modal fade" id="modal-employee-leaves" tabIndex="-1" role="dialog" aria-hidden="true">
            <div id="modal-employee-leaves" className='d-flex justify-content-center align-items-center modal-dialog w-50'>
                <div className='modal-content'>
                    <div className="d-flex align-items-center modal-header">
                        <h4 className="modal-title">Edit</h4>
                        <button className="close" aria-label="Close" onClick={() => setIsOpen(false)}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <form onSubmit={updateLeaveForm.handleSubmit(handleUpdateLeaves)} className='px-5 my-3'>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableleave"/>}
                                    {...updateLeaveForm.register('availableLeaves', {
                                        pattern: {
                                            value: regexFloat
                                        }
                                    })}
                                    fullWidth
                                    error={updateLeaveForm.formState.errors.availableLeaves}
                                />
                            </div>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableWFH"/>}
                                    {...updateLeaveForm.register('availableWfhDays', {
                                        pattern: {
                                            value: regexFloat
                                        }
                                    })}
                                    fullWidth
                                    error={updateLeaveForm.formState.errors.availableWfhDays}
                                />
                            </div>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableSummer"/>}
                                    {...updateLeaveForm.register('availableSummerLeave', {
                                        pattern: {
                                            value: regexFloat
                                        }
                                    })}
                                    fullWidth
                                    error={updateLeaveForm.formState.errors.availableWfhDays}
                                />
                            </div>
                            <div className="d-flex row justify-content-end mr-1 mt-2">
                                <div>
                                    <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                                </div>
                                <div>
                                    <Button className='ml-2 px-3' type='submit' appearance='primary'>Edit</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
