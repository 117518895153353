export const deviceType = [
    { label: 'Laptop', value: '1' },
    { label: 'Màn hình', value: '2' }
];
export const offices = [
    { label: 'HCM', value: '1' },
    { label: 'HN', value: '2' }
];
export const status = [
    { label: 'Tồn', value: '1' },
    { label: 'Đã cấp', value: '2' },
    { label: 'Đang sửa chữa', value: '3' }
];
export const actions = [
    {
        label: 'Nhập kho', value: '1'
    },
    {
        label: 'Xuất kho', value: '2'
    },
    {
        label: 'Sửa chữa', value: '3'
    }
];
