import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';

class ActivitiesService {
    constructor () {
        serviceHelpers();
    }

    getWikiPage (projectId) {
        return axiosService.get(API_URL + projectId + '/wiki').then(res => res.data.data);
    }

    createWikiPage (projectId, payload) {
        return axiosService.post(API_URL + projectId + '/wiki', payload).then(res => res.data.data);
    }

    editWikiPage (wikiId, payload) {
        return axiosService.patch(API_URL + 'wiki/' + wikiId, payload).then(res => res.data.data);
    }
}

export default new ActivitiesService();
