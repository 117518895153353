import { FormattedMessage } from 'react-intl';
import { setBreadcrumb } from '../../../actions/breadcrumb.action';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import CreateProjectForm from './CreateProjectForm';

function NewProject ({ dispatch }) {
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.project',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dasboard'
                },
                {
                    label: 'menu.member.name.project',
                    haslink: true,
                    path: '/projects'
                },
                {
                    label: 'menu.member.name.project.create',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch]);
    return (
    <div className="p-3">
        <h2>
          <FormattedMessage id="project.new.label.header" />
        </h2>
        <CreateProjectForm isNew/>
    </div>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(NewProject);
