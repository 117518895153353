import { React, useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from '@atlaskit/select';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import moment from 'moment';
import axiosService from '../../../../services/axios.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import LeaveRequestService from '../organization-detail/services/leaverequest.service';
import { ServiceConstants } from '../../../common/constants/service.constants';
import Button from '@atlaskit/button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ROLES } from '../../../../constants/user.constant';
import Avatar from '@atlaskit/avatar';
import recruitmentService from '../../Services/recruitment.service';
import hrService from '../../Services/hr.service';
import { setKimeiMembers } from '../../../../actions/hrm.action';
import { DatePicker, ConfigProvider } from 'antd';
import vi from 'antd/lib/locale/vi_VN';
import 'dayjs/locale/vi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale('vi');
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const getYearMonth = (date) => date.year() * 12 + date.month();
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/';

const checkWfhFlag = (val) => {
    if (val === ServiceConstants.ValWFH) {
        return true;
    }
    return false;
};

const checkSummerLeaveFlag = (val) => {
    if (val === ServiceConstants.ValSummerLeave) {
        return true;
    }
    return false;
};

const checkUnpaidAndSpecialLeave = (val) => {
    if (val === ServiceConstants.ValLeaveUnpaid || val === ServiceConstants.ValLeaveSpecial || val === ServiceConstants.ValWorkingLeave) {
        return true;
    }
    return false;
};

const checkLeaveFullDay = (val) => {
    if (val === ServiceConstants.ValFULLDATE) {
        return true;
    }
    return false;
};

// Calculate working days and exclude Friday and Sunday
const calculateWorkingDays = (startDate, endDate) => {
    const currentDate = moment(dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    const endDateMoment = moment(dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));

    if (!currentDate.isValid() || !endDateMoment.isValid()) {
        return 0;
    }

    let workingDays = 0;

    while (currentDate.isSameOrBefore(endDateMoment, 'day')) {
        if (currentDate.isoWeekday() !== 6 && currentDate.isoWeekday() !== 7) {
            workingDays++;
        }
        currentDate.add(1, 'days');
    }

    return workingDays;
};

function LeaveRequest ({ user, isEdit, details, refetch, closeModal, role, kimeiMembers }) {
    const dispatch = useDispatch();
    const defaultLeavetype = () => {
        return '';
    };

    const defaultLeaveFor = () => {
        return '';
    };

    const defaultValues = {
        availableleave: 0,
        leavetype: defaultLeavetype(),
        leavefor: defaultLeaveFor(),
        fromdate: null,
        todate: null,
        day: 0,
        reason: '',
        linemanager: '',
        assignedMember: ''
    };

    const HAFL_DAY = 0.5;
    const PART_DAY = 0.25;
    const handleDayLeaveForm = (days) => {
        const leaveFor = watch('leavefor')?.value;
        const fromDate = watch('fromdate');
        if (!fromDate) {
            return 0;
        }
        if (leaveFor === ServiceConstants.ValhalfAM || leaveFor === ServiceConstants.ValhalfPM) {
            return HAFL_DAY;
        } else if (leaveFor === ServiceConstants.Val2hoursAM || leaveFor === ServiceConstants.Val2hoursPM) {
            return PART_DAY;
        } else {
            return days;
        }
    };
    const { handleSubmit, control, formState: { errors }, watch, setValue, reset, getValues } = useForm({ defaultValues });
    const admission = watch('fromdate');
    const discharge = watch('todate');
    const days = calculateWorkingDays(admission, discharge);
    const dayLeave = handleDayLeaveForm(days);

    useEffect(() => {
        let nextAvailableDay;
        if (checkWfhFlag(watch('leavetype').value)) {
            nextAvailableDay = availableDay.wfh - dayLeave;
            setValue('availableleave', nextAvailableDay);
        } else if (checkSummerLeaveFlag(watch('leavetype').value)) {
            nextAvailableDay = availableDay.summerLeave - dayLeave;
            setValue('availableleave', nextAvailableDay);
        } else if (checkUnpaidAndSpecialLeave(watch('leavetype').value)) {
            setValue('availableleave', availableDay.leave);
        } else {
            nextAvailableDay = availableDay.leave - dayLeave;
            setValue('availableleave', nextAvailableDay);
        }
    }, [days]);

    const leaveType = watch('leavetype');
    const leaveReason = leaveType.value === 4 ? ServiceConstants.SPECIAL_LEAVE_REASONS : (leaveType.value === 5 ? ServiceConstants.WORKING_LEAVE_REASONS : ServiceConstants.LEAVE_REASONS);
    const leaveFor = leaveType.value === 3 || leaveType.value === 5 ? ServiceConstants.UNPAID_LEAVE : ServiceConstants.LEAVE_FOR;

    const [isDisabledLeaveFor, setDisabledLeaveFor] = useState(false);
    const [isFlagCheckSummerLeave, setIsFlagCheckSummerLeave] = useState(false);
    const resetForm = () => {
        reset();
        setValue('leavetype', defaultLeavetype);
        setValue('assignedMember', null);
        setValue('leavefor', defaultLeaveFor);
        getOneHr();
    };
    const onSubmitCreate = (dataForm) => {
        if (!dataForm.leavetype.value || !dataForm.reason || !dataForm.fromdate) {
            return;
        }
        if (isEdit !== 'true') {
            const payload = {
                leaveType: parseInt(dataForm.leavetype.value),
                leaveFor: parseInt(dataForm.leavefor.value),
                reasonLeave: dataForm.reason.value,
                fromDate: dataForm.fromdate,
                toDate: dataForm.leavefor.value !== ServiceConstants.ValFULLDATE ? dataForm.fromdate : dataForm.todate,
                totalDayLeave: parseFloat(dayLeave)
            };

            try {
                axiosService.post(API_URL + 'hrm/services/leaves', payload).then((res) => {
                    if (res.status === 200) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        reset();
                    }
                    getOneHr();
                }).catch((error) => {
                    if (error.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });
                    }
                });
            } catch (error) {
                console.warn(error);
            }
        } else {
            const payload = {
                leaveType: parseInt(dataForm.leavetype.value),
                leaveFor: parseInt(dataForm.leavefor.value),
                reasonLeave: dataForm.reason.value,
                fromDate: dataForm.fromdate,
                toDate: dataForm.leavefor.value !== ServiceConstants.ValFULLDATE ? dataForm.fromdate : dataForm.todate,
                totalDayLeave: parseFloat(dayLeave),
                approveBy: dataForm.linemanager.value
            };
            try {
                axiosService.patch(API_URL + 'hrm/services/myrequest/' + details._id + '/modify', payload).then((res) => {
                    if (res.status === 200) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: res.data.message
                        });
                        reset();
                        refetch();
                        closeModal();
                    }
                }).catch((error) => {
                    if (error.response?.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response?.data?.message
                        });
                    }
                });
            } catch (error) {
                console.warn(error);
            }
        }
    };

    const [availableDay, setAvailableDay] = useState({
        leave: 0,
        wfh: 0,
        summerLeave: 0
    });

    const getOneHr = () => {
        LeaveRequestService.getOneHR(user.id).then((res) => {
            const availableLeaves = res.data.data.availableLeaves;
            const availableWFH = res.data.data.availableWfhDays;
            const availableSummerLeave = res.data.data.availableSummerLeave;
            setAvailableDay(
                { wfh: availableWFH, leave: availableLeaves, summerLeave: availableSummerLeave }
            );
            if (res.data.data?.contract_2_date && moment().isBetween(moment('2024-06-01'), moment('2024-10-31').endOf('day'))) {
                setIsFlagCheckSummerLeave(true);
            } else {
                setIsFlagCheckSummerLeave(false);
            }
        });
    };

    const lineManager = (isMounted) => {
        try {
            if (isMounted) {
                getOneHr();
            }
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        let isMounted = true;
        lineManager(isMounted);

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (checkWfhFlag(watch('leavetype').value)) {
            setValue('availableleave', availableDay.wfh);
        } else if (checkSummerLeaveFlag(watch('leavetype').value)) {
            setValue('availableleave', availableDay.summerLeave);
        } else if (checkUnpaidAndSpecialLeave(watch('leavetype').value)) {
            setValue('availableleave', availableDay.leave);
        } else {
            setValue('availableleave', availableDay.leave);
        }
    }, [watch('leavetype'), availableDay]);

    useEffect(() => {
        if (details) {
            reset();
            setValue('availableleave', details.availableLeaves);
            setValue('leavetype', defaultLeavetype);
            setValue('reason', details?.reasonLeave);
            setValue('leavefor', defaultLeaveFor);
            setValue('fromdate', moment(details?.fromDate).toDate());
            setValue('todate', moment(details?.toDate).toDate());
            details.approveBy && setValue('linemanager', { value: details?.approveBy._id, label: details?.approveBy.firstname + ' ' + details?.approveBy.lastname });
        }
    }, [details]);

    const [isAssignLRChecked, setIsAssignLRChecked] = useState(false);
    const [allMemberToAssign, setAllMemberToAssign] = useState([]);

    useEffect(() => {
        setAllMemberToAssign(() => {
            const list = kimeiMembers.map(employee => ({ value: employee._id, label: `${employee.firstname} ${employee.lastname}`, image: employee.picture, isSummerLeave: !!employee?.contract_2_date, ...employee }));
            return list.filter(employee => employee.value !== user.userId);
        });
    }, [kimeiMembers]);

    const assignLRChecked = useCallback(() => {
        setIsAssignLRChecked(!isAssignLRChecked);
        hrService.getEmployees({ limit: 9999 }).then(res => {
            dispatch(setKimeiMembers(res.items));
        });
        setValue('assignedMember', null);
    }, [isAssignLRChecked, allMemberToAssign]);

    const handleAssignLR = dataForm => {
        if (!dataForm.leavetype.value || !dataForm.reason || !dataForm.fromdate) {
            return;
        }
        const payload = {
            leaveType: parseInt(dataForm.leavetype.value),
            leaveFor: parseInt(dataForm.leavefor.value),
            reasonLeave: dataForm.reason.value,
            fromDate: dataForm.fromdate,
            toDate: dataForm.leavefor.value !== ServiceConstants.ValFULLDATE ? dataForm.fromdate : dataForm.todate,
            approveBy: user.userId,
            assignedMember: dataForm.assignedMember.value,
            totalDayLeave: parseFloat(dayLeave)
        };

        LeaveRequestService.assignLRToMember(payload).then(res => {
            ToastTopEnd.fire({
                icon: 'success',
                title: res.data.message
            });
            hrService.getEmployees({ limit: 9999 }).then(res => {
                dispatch(setKimeiMembers(res.items));
            });
            setValue('assignedMember', null);
        }).catch(err => {
            ToastTopEnd.fire({
                icon: 'error',
                title: err.response?.data?.message
            });
        });
    };

    useEffect(() => {
        resetForm();
    }, [isAssignLRChecked]);

    useEffect(() => {
        if (watch('assignedMember')) {
            setAvailableDay(
                { wfh: watch('assignedMember')?.availableWfhDays, leave: watch('assignedMember')?.availableLeaves, summerLeave: watch('assignedMember')?.availableSummerLeave }
            );
        } else {
            getOneHr();
        }
    }, [watch('assignedMember')]);

    const onChangeRangeDate = (dates) => {
        dates[0] ? setValue('fromdate', dates[0]) : setValue('fromdate', null);
        dates[1] ? setValue('todate', dates[1]) : setValue('todate', null);
    };

    const disabled7DaysDate = (current, { from, type }) => {
        if (from) {
            const minDate = from.add(-6, 'days');
            let maxDate = from.add(1, 'days');
            if (watch('leavetype')?.value === ServiceConstants.ValLeaveSpecial) {
                return current.year() < minDate.year() || current.year() > maxDate.year();
            }
            if (watch('availableleave') >= 2) {
                maxDate = from.add(1, 'days');
            } else if (watch('availableleave') === 1) {
                maxDate = from.add(0, 'days');
            }
            switch (type) {
                case 'year':
                    return current.year() < minDate.year() || current.year() > maxDate.year();
                case 'month':
                    return getYearMonth(current) < getYearMonth(minDate) || getYearMonth(current) > getYearMonth(maxDate);
                default:
                    return current.year() < minDate.year() || current.isAfter(maxDate, 'day');
            }
        }
        return false;
    };

    const checkForm = (type) => {
        let leaveDays = 0;
        if (checkWfhFlag(watch('leavetype').value)) {
            leaveDays = availableDay.wfh;
        } else if (checkSummerLeaveFlag(watch('leavetype').value)) {
            leaveDays = availableDay.summerLeave;
        } else {
            leaveDays = availableDay.leave;
        }
        switch (type) {
            case ServiceConstants.ValLeaveSpecial:
                return [false, false];
            case ServiceConstants.ValLeaveUnpaid:
                return [false, !checkLeaveFullDay(watch('leavefor')?.value)];
            default:
                if (leaveDays === 0) {
                    return true;
                } else {
                    return [false, !checkLeaveFullDay(watch('leavefor')?.value)];
                }
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <form onSubmit={isAssignLRChecked ? handleSubmit(handleAssignLR) : handleSubmit(onSubmitCreate)} className="form">
                    <div className="form-horizontal">
                        {(role === ROLES.MANAGER || role === ROLES.HR) && (
                            <div className='form-group'>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox onChange={assignLRChecked} checked={isAssignLRChecked}/>}
                                        label={TranslateHelper.getMessage('hrm.services.myleave.view.label.assignLR')}/>
                                </FormGroup>
                            </div>
                        )}
                        {isAssignLRChecked && (
                            <div className="form-group row">
                                <label htmlFor='assignedMember' className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                    id='hrm.services.leaveRequest.label.assignForMember'/></label>
                                <div className='col-12 col-sm-5 col-md-4'>
                                    <section>
                                        <Controller
                                            name='assignedMember'
                                            control={control}
                                            rules={{
                                                required: TranslateHelper.getMessage('validation.error.required')
                                            }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        isClearable
                                                        value={field.value}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setIsFlagCheckSummerLeave(e?.isSummerLeave);
                                                            setValue('leavetype', defaultLeavetype);
                                                        }}
                                                        options={allMemberToAssign}
                                                        formatOptionLabel={employee => {
                                                            return (<div className='user-option d-flex'>
                                                                <Avatar
                                                                    src={employee.image ? (String(employee.image).includes('uploads') ? recruitmentService.buildFileURL(employee.image) : employee.image) : '/images/default-avatar.png'}/>
                                                                <span
                                                                    className='ml-2 align-self-center'>{employee.label}</span>
                                                            </div>);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.assignedMember &&
                                            <span className='error text-danger'>{errors.assignedMember.message}</span>}
                                    </section>
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label htmlFor="availableleave" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id={checkWfhFlag(watch('leavetype').value) ? 'hrm.services.leaveRequest.label.availableWFH' : checkSummerLeaveFlag(watch('leavetype').value) ? 'hrm.services.leaveRequest.label.availableSummer' : 'hrm.services.leaveRequest.label.availableleave'}/></label>
                            <div className="col-12 col-sm-5 col-md-4">
                                <section>
                                    <Controller
                                        name="availableleave"
                                        control={control}
                                        render={({ field }) => (
                                            <span>{field.value}</span>
                                        )}
                                    />
                                </section>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="leavetype" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id="hrm.services.leaveRequest.label.leavetype"/></label>
                            <div className='col-12 col-sm-5 col-md-4'>
                                <section>
                                    <Controller
                                        name="leavetype"
                                        control={control}
                                        rules={{
                                            required: TranslateHelper.getMessage('validation.error.required')
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('leavefor', ServiceConstants.LEAVE_FOR[0]);
                                                    if (checkWfhFlag(e.value)) {
                                                        reset({
                                                            ...getValues(),
                                                            fromdate: null,
                                                            todate: null
                                                        });
                                                        setDisabledLeaveFor(true);
                                                    } else {
                                                        reset({
                                                            ...getValues(),
                                                            fromdate: null,
                                                            todate: null
                                                        });
                                                        setDisabledLeaveFor(false);
                                                    }
                                                }}
                                                options={isFlagCheckSummerLeave ? ServiceConstants.LEAVE_TYPE_CONTRACT : ServiceConstants.LEAVE_TYPE}
                                            />
                                        )}
                                    />
                                    {errors.leavetype &&
                                        <span className='error text-danger'>{errors.leavetype.message}</span>}
                                </section>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="reason" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id="hrm.services.leaveRequest.label.reason"/></label>
                            <div className='col-12 col-sm-5 col-md-4'>
                                <section>
                                    <Controller
                                        control={control}
                                        name="reason"
                                        rules={{
                                            required: TranslateHelper.getMessage('validation.error.required')
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                                options={leaveReason}
                                            />
                                        )}
                                    />
                                </section>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="leavefor" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id="hrm.services.leaveRequest.label.leavefor"/></label>
                            <div className='col-12 col-sm-5 col-md-4'>
                                <section>
                                    <Controller
                                        control={control}
                                        name="leavefor"
                                        rules={{
                                            required: TranslateHelper.getMessage('validation.error.required')
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                value={field.value}
                                                isDisabled={isDisabledLeaveFor}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    reset({
                                                        ...getValues(),
                                                        fromdate: null,
                                                        todate: null
                                                    });
                                                }}
                                                options={leaveFor}
                                            />
                                        )}
                                    />
                                    {errors.leavefor &&
                                        <span className='error text-danger'>{errors.leavefor.message}</span>}
                                </section>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="from" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id="hrm.services.leaveRequest.label.from"/></label>
                            <div className='col-12 col-sm-5 col-md-4'>
                            <ConfigProvider locale={vi}>
                                <RangePicker
                                    style={{ width: '100%' }}
                                    size="large"
                                    required
                                    disabledDate={disabled7DaysDate}
                                    onCalendarChange={dates => onChangeRangeDate(dates)}
                                    disabled={checkForm(watch('leavetype')?.value)}
                                    value={[watch('fromdate'), !checkLeaveFullDay(watch('leavefor')?.value) ? watch('fromdate') : watch('todate')]}
                                    format="DD/MM/YYYY"
                                />
                            </ConfigProvider>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="day" className="col-12 col-sm-4 col-md-3"><FormattedMessage
                                id="hrm.services.leaveRequest.label.day"/></label>
                            <div className='col-12 col-sm-5 col-md-4'>
                                <span>
                                    {isNaN(dayLeave) ? 0 : dayLeave}
                                </span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 col-sm-4 col-md-3"></div>
                            <div className='col-12 col-sm-5 col-md-4 d-flex justify-content-end'>
                                {
                                    isEdit === 'true'
                                        ? <Button type='submit' appearance='primary'>Lưu</Button>
                                        : <Button type='submit' appearance='primary'>Tạo</Button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    const { kimeiMembers } = state.hrm;
    return {
        user, role, kimeiMembers
    };
}

export default connect(mapStateToProps)(LeaveRequest);
