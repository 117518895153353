import { Popover } from '@mui/material';

export default function PopoverHover ({ open, anchorEl, children, handlePopoverClose, handlePopoverOpen }) {
    return (
        <>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none'
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </>
    );
}
