import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import statusService from '../../../services/status.service';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import StatusDetail from './StatusDetail';
import Button from '@atlaskit/button';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ROLES } from '../../../../../constants/user.constant';
import { isAllowEditProject } from '../../../../../helpers/CheckRoleHelper';

function Status ({ projectId, role, userRoleInProject }) {
    const [isView, setIsView] = useState();
    const [status, setStatus] = useState([]);
    const hideModalButtonRef = useRef();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const fetchStatus = useCallback(() => {
        statusService.getStatus(projectId).then(setStatus);
    }, [projectId]);

    useEffect(fetchStatus, [fetchStatus]);

    const handleCreateStatus = useCallback(
        async (formData) => {
            const payload = {
                name: formData.name.trim()
            };
            if (payload.name) {
                try {
                    await statusService.createStatus(projectId, payload);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.addnew.successed')
                    });
                    hideModalButtonRef.current?.click();
                    reset();
                    return fetchStatus();
                } catch (error) {
                    console.warn(error);
                    ToastTopEnd.fire({
                        icon: 'error',
                        title:
                            error.response.data.message ||
                            TranslateHelper.getMessage('respond.message.failed')
                    });
                }
            } else {
                setError('name', { message: TranslateHelper.getMessage('validation.error.required') });
            }
        },
        [projectId, hideModalButtonRef]
    );

    const handleDelete = (id, name) => {
        console.log('event.target==', id, name);

        Swal.fire({
            title:
                TranslateHelper.getMessage('alert.delete') +
                ' "' +
                name +
                '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await statusService.deleteStatus(projectId, id);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.delete.successfully')
                });
                return fetchStatus();
            }
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = useCallback(async result => {
        if (!result.destination) return;

        const { source, destination, draggableId } = result;
        const payload = {
            oldIndex: source.index,
            newIndex: destination.index
        };

        if (payload.oldIndex !== payload.newIndex) {
            // call API
            const items = reorder(status, source.index, destination.index);
            setStatus(items);
            await statusService.updateStatus(projectId, draggableId, payload).then();
        }
    });
    const editFlag = isAllowEditProject(role, userRoleInProject);

    return (
        <div className="container-fluid">
            <div className='card'>
                <div className='card-body'>
                {editFlag && (
                <Button
                    type="button"
                    appearance='primary'
                    data-toggle="modal"
                    data-target="#modal-status"
                    style={{ margin: '10px 0px' }}
                >
                    {TranslateHelper.getMessage('button.name.common.create')}
                </Button>
                )}
                    <div className="modal fade" id="modal-status">
                        <div className="modal-dialog modal-sm">
                            <form onSubmit={handleSubmit(handleCreateStatus)} className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Status</h4>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group row">
                                        <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                            <FormattedMessage id="project.detail.settings.status.table.column.name.status" />
                                        </label>
                                        <div className="col-sm-8">
                                            <input
                                                placeholder="Name of status ?"
                                                className={
                                                    errors.name ? 'is-invalid form-control' : 'form-control'
                                                }
                                                {...register('name', {
                                                    required: TranslateHelper.getMessage(
                                                        'validation.error.required'
                                                    ),
                                                    pattern: {
                                                        value: /^[a-zÀ-ȕ0-9(),-_.,  ]+$/gi,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.containSpecialCharacter'
                                                        )
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: TranslateHelper.getMessage('validation.error.max30characters')
                                                    }
                                                })}
                                            />
                                            {errors.name && (
                                                <span className="error text-danger">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end">
                                    <button
                                        type="button"
                                        ref={hideModalButtonRef}
                                        className="btn btn-outline-danger"
                                        data-dismiss="modal"
                                    >
                                        <FormattedMessage id="button.name.common.cancel" />
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={!isDirty || !isValid}
                                    >
                                        {TranslateHelper.getMessage('button.name.common.create')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='table-scrollX'>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <table id="status-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.status.table.column.name.status" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdAt" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.createdBy" />
                                    </th>
                                    {editFlag && <th>
                                        <FormattedMessage id="project.detail.settings.issuetype.table.column.name.action" />
                                    </th>}
                                </tr>
                            </thead>
                                <Droppable droppableId='status-droppable'>
                                    {(provided) => {
                                        return (
                                            <tbody
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {status.length > 0
                                                    ? status.map((value, index) => {
                                                        return (
                                                            <Draggable
                                                                key={value._id}
                                                                index={index}
                                                                draggableId={value._id}
                                                                isDragDisabled={![ROLES.MANAGER, ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER].includes(role)}
                                                            >
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <tr
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                            style={{
                                                                                backgroundColor: snapshot.isDragging && 'rgba(0,0,0,.075)',
                                                                                ...provided.draggableProps.style
                                                                            }}
                                                                        >
                                                                            <td>{value.name}</td>
                                                                            <td>
                                                                                {moment(value.createdAt).format(
                                                                                    TranslateHelper.getMessage('date.format')
                                                                                )}
                                                                            </td>

                                                                            <td>
                                                                                {value.createdBy.firstname + ' ' + value.createdBy.lastname}
                                                                            </td>
                                                                            {editFlag && <td>
                                                                                <Button
                                                                                    title='Edit'
                                                                                    className="mr-2"
                                                                                    data-toggle="modal"
                                                                                    data-target={'#modal-status-' + value._id}
                                                                                    onClick={() => setIsView(value)}
                                                                                >
                                                                                    <i className='far fa-edit'></i>
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={() => handleDelete(value._id, value.name)}
                                                                                >
                                                                                    <i className='far fa-trash-alt'></i>
                                                                                </Button>
                                                                            </td>}
                                                                        </tr>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    })
                                                    : <td className='text-center' colSpan='4'><FormattedMessage id='message.no.data.available'/></td>}
                                                {provided.placeholder}
                                            </tbody>
                                        );
                                    }}
                                </Droppable>
                            </table>
                        </DragDropContext>
                    </div>
                </div>
            </div>
            { isView && <StatusDetail projectId={projectId} status={isView} fetchStatus={fetchStatus} /> }
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        role,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(Status);
