import { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import TranslateHelper from '../../helpers/TranslateHelper';
import hrService from '../hrm/Services/hr.service';
import recruitmentService from '../hrm/Services/recruitment.service';
import ReactPagination from '../ReactPagination';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

export default function ActivitiesComponent ({ Activities, isLoading, isFilter, totalPages, handlePageChange, filters }) {
    const history = useHistory();

    const upperCaseFirstLetter = useCallback((string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);

    const fetchEmployeeById = (id, index, picture) => {
        hrService.getEmployeeById(id).then(res => {
            const avt = picture ? !String(picture).includes('uploads') ? picture : recruitmentService.buildFileURL(picture) : '/images/default-avatar.png';
            const popup = document.getElementById('employee-popup-' + index);
            const html = `<div class="popup-container">
                            <div class='popup-img'>
                                <img onerror="this.src = '/images/default-avatar.png';" src=${avt}>
                            </div>
                            <div class="popup-employee-detail">
                                <span>${res.firstname} ${res.lastname}<br>
                                ${res.departmentId.name}<span><br>
                                <span><i class="far fa-envelope"></i> ${res.username}<br>
                                    <i class="far fa-id-badge"></i> ${res.id}
                                </span>
                            </div>
                        </div>`;
            popup.innerHTML = html;
        });
    };

    const navigateToRightPage = useCallback((activity) => {
        let url = '';
        let tabMenu = {};
        switch (activity.scope) {
            case 'business departments':
                url = '/hrm';
                tabMenu = { HrmIdx: 3, departmentsIdx: 1 };
                break;
            case 'business units':
                url = '/hrm';
                tabMenu = { HrmIdx: 3, departmentsIdx: 0 };
                break;
            case 'CV':
                url = '/hrm';
                tabMenu = { HrmIdx: 4, recruitmentIdx: 2 };
                break;
            case 'wiki':
                url = `/projects/${activity.projectId.identifier}`;
                break;
            case 'issue':
            case 'comment':
                url = `/projects/${activity.projectId.identifier}/issues/${activity.actionTo}`;
                break;
        }
        if (url) {
            if (activity.scope === 'comment') {
                history.push({ pathname: url, state: tabMenu, hash: 'comment-' + activity.commentId });
            } else {
                history.push({ pathname: url, state: tabMenu });
            }
        }
    }, []);

    const activitiesLoading = () => {
        return (
            <div>
                <div className="row pt-2 pl-3">
                        <div className="pr-2">
                            <Skeleton height={'50px'} width={'50px'} circle={true} />
                        </div>
                        <div>
                            <div>
                                <Skeleton width={'100px'} />
                            </div>
                            <div>
                                <Skeleton width={'200px'} />
                            </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className="pr-2 col-8">
                            <Skeleton />
                        </div>
                        <div className="pr-2 col-3">
                            <Skeleton />
                        </div>
                    </div>
            </div>
        );
    };

    return (
        <>
                <section>
                    <div className="card">
                    <div className="card-body">
                        {isLoading
                            ? (<div>
                                {isFilter ? new Array(10).fill(null).map(activitiesLoading) : new Array(6).fill(null).map(activitiesLoading)}
                </div>)
                            : (

                            <div className="row" style={{ minHeight: isFilter ? '1200px' : '' }}>
                                <div className="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                                    {Activities?.map((activity, index, arr) => {
                                        const previousDate = arr[index - 1];
                                        return (
                                            <div className="col-12" key={index}>
                                                {index === 0 ||
                                                (previousDate &&
                                                    moment(previousDate.createdAt).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    ) !==
                                                        moment(activity.createdAt).format(
                                                            TranslateHelper.getMessage(
                                                                'date.format'
                                                            )
                                                        ))
                                                    ? (
                                                    <h5
                                                        className="pt-4"
                                                        style={{
                                                            borderBottom: '1px solid #E7E8EC',
                                                            paddingBottom: '5px',
                                                            color: '#ADAEB6'
                                                        }}
                                                    >
                                                        {moment(activity.createdAt).format(
                                                            TranslateHelper.getMessage(
                                                                'date.format'
                                                            )
                                                        )}
                                                    </h5>
                                                        )
                                                    : null}
                                                <div className="post">
                                                    <div
                                                        className="user-block"
                                                        style={{ marginBottom: '5px' }}
                                                    >
                                                        <img
                                                            className="img-circle img-bordered-sm"
                                                            onError={(e) => { e.target.src = '/images/default-avatar.png'; }}
                                                            src={
                                                                activity.createdBy?.picture
                                                                    ? !String(
                                                                            activity.createdBy.picture
                                                                        ).includes('uploads')
                                                                            ? activity.createdBy.picture
                                                                            : recruitmentService.buildFileURL(
                                                                                activity.createdBy
                                                                                    .picture
                                                                            )
                                                                    : '/images/default-avatar.png'
                                                            }
                                                        />
                                                        <span className="username">
                                                            <a
                                                                className='pointer btn-group'
                                                                onClick={() => fetchEmployeeById(activity.createdBy.id, index, activity.createdBy.picture) }
                                                                style={{ color: '#85200A' }}
                                                            >
                                                                <span data-toggle="dropdown" aria-expanded="true">{activity.createdBy?.firstname}{' '}{activity.createdBy?.lastname}</span>
                                                                <ul className="dropdown-menu dropdown-menu-right p-2" style={{ zIndex: '9999', backgroundColor: 'transparent' }}>
                                                                    <div id={'employee-popup-' + index}></div>
                                                                </ul>
                                                            </a>
                                                        </span>
                                                        <span className="description">
                                                            {upperCaseFirstLetter(activity.action)}{' '}
                                                            -{' '}
                                                            {moment(activity.createdAt).format(
                                                                'HH:mm A'
                                                            )}{' '}
                                                            <i className="far fa-clock"></i>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={
                                                            activity.actionTo
                                                                ? { marginBottom: '10px' }
                                                                : null
                                                        }
                                                    >
                                                        <div className="col-md-10 col-8">
                                                            {upperCaseFirstLetter(activity.action)}{' '}
                                                            {activity.scope}{' '}
                                                                {activity.actionTo
                                                                    ? (
                                                                    <a role="button" onClick={() => navigateToRightPage(activity)}>
                                                                        <b className="hightlight-activities">
                                                                            {activity.actionTo}
                                                                        </b>
                                                                    </a>
                                                                        )
                                                                    : null}

                                                        </div>
                                                        <div className="col-md-2 col-4">
                                                            <p
                                                                title={moment(
                                                                    activity.creatAt
                                                                ).format(
                                                                    TranslateHelper.getMessage(
                                                                        'date.format'
                                                                    ) + ' hh:mm:ss'
                                                                )}
                                                                className="from-now"
                                                            >
                                                                {moment(
                                                                    activity.createdAt
                                                                ).fromNow()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {!isFilter && (
                                        <Link
                                            className=""
                                            to={{
                                                pathname: '/activities',
                                                state: { OrganizationIdx: 3, departmentsIdx: 1 }
                                            }}
                                        >
                                            <button
                                                className="button button-viewall"
                                                style={{ bottom: '10px', right: '10px' }}
                                            >
                                                <i className="fa fa-chevron-right"></i>{TranslateHelper.getMessage('button.name.common.viewAll')}
                                            </button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                                )}
                                {totalPages > 1 && isFilter && (
                                    <ReactPagination
                                        totalPages={totalPages}
                                        handlePageChange={handlePageChange}
                                        pageNumber={filters.page}
                                    />
                                )}
                        </div>
                    </div>
                </section>
        </>
    );
}
