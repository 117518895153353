import { useEffect, useState } from 'react';
import cronjobsService from '../Services/cronjobs.service';
import { LoadingButton } from '@atlaskit/button';
import moment from 'moment';
import TranslateHelper from '../../../common/helpers/TranslateHelper';

export default function RowTableCronJob ({ item }) {
    const cronJobsConstant = [
        { label: 'Job failed', value: 2 },
        { label: 'Job running', value: 1 }
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(item);

    useEffect(() => {
        setJob(item);
    }, []);

    const reRunJob = (data) => {
        setIsLoading(true);
        const payload = {
            jobUrl: data.jobUrl,
            jobsTask: data.jobTask
        };
        cronjobsService.reRunJob(payload).then(() => {
            setJob(prevState => ({ ...prevState, status: cronJobsConstant[1].value }));
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <tr key={job._id}>
            <td>{moment(job.jobtimestamps * 1000).format(TranslateHelper.getMessage('date.format') + ' HH:mm:ss')}</td>
            <td>{job.jobName}</td>
            <td>{job.jobUrl}</td>
            <td>{job.status === cronJobsConstant[0].value ? cronJobsConstant[0].label : cronJobsConstant[1].label }</td>
            <td>{job.status === cronJobsConstant[0].value ? <LoadingButton appearance='danger' onClick={() => reRunJob(job)} isLoading={isLoading}>Re-run</LoadingButton> : <LoadingButton style={{ backgroundColor: '#28a745', cursor: 'default' }} appearance='primary'>Success</LoadingButton> }</td>
        </tr>
    );
}
