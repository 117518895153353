import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/titles';

class TitleService {
    constructor () {
        serviceHelpers();
    }

    getTitles (params) {
        return axiosService.get(API_URL, { params });
    }

    deleteTitle (id) {
        return axiosService.delete(`${API_URL}/${id}`);
    }

    createTitle (data) {
        return axiosService.post(API_URL, data);
    }

    updateTitle (data, id) {
        return axiosService.patch(`${API_URL}/${id}`, data);
    }
}

export default new TitleService();
