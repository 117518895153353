
export const TYPE_AUTH = {
    REDIRECT_AFTER_SIGNUP_SUCCESS: '/login',
    REDIRECT_AFTER_LOGIN_SUCCESS: '/dashboard',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED'
};

export const ROLES = {
    REQUEST: 0,
    MANAGER: 1,
    MEMBER: 2,
    PARTNEROWNER: 3,
    PARTNERMANAGER: 4,
    PARTNERMEMBER: 5,
    GUEST: 6,
    HR: 7
};

export const TITLE_GENDER = {
    MR: 1,
    MS: 2,
    MRS: 3
};

export const PERMISSION_ACCESS = {
    CLIENTID_ACTIVED: 'clientIdActived',
    MEMBER_ACCESS: 'memberAccess',
    MANAGER_ACCESS: 'managerAccess',
    PARTNEROWNER_ACCESS: 'partnerOwnerAccess',
    PARTNERMANAGER_ACCESS: 'partnerManagerAccess',
    PARTNERMEMBER_ACCESS: 'partnerMemberAccess',
    GUEST_ACCESS: 'guestAccess'
};
