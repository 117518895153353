import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from './routes';

const mergeRoutes = routes;

class MainContentPartnerMember extends Component {
    showContentMenu (routes) {
        let result = null;
        if (routes.length > 0) {
            result = routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                    />
                );
            });
        }

        return result;
    }

    render () {
        return (
            <>
                <Switch>
                    {this.showContentMenu(mergeRoutes)}
                    <Route path='*'>
                        <Redirect to={{ pathname: '/not-found', state: { isRedirect: true } }}/>
                    </Route>
                </Switch>
            </>
        );
    }
}

export default MainContentPartnerMember;
