import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import statusService from '../../../services/status.service';
import Button from '@atlaskit/button';

export default function StatusDetail ({ projectId, status, fetchStatus }) {
    const hideModalButtonRef = useRef();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const handleSave = useCallback(async (formData) => {
        const payload = {
            name: formData.name.trim()
        };
        if (payload.name) {
            try {
                await statusService.updateStatus(projectId, status._id, payload);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                hideModalButtonRef.current?.click();
                return fetchStatus();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title: error.response.data.message || TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            setError('name', { message: TranslateHelper.getMessage('validation.error.required') });
        }
    }, [hideModalButtonRef, status._id]);

    useEffect(() => {
        setValue('name', status.name);
        return () => {
            clearErrors('name');
        };
    }, [status, setValue, fetchStatus]);
    return (
        <div className="modal fade" id={'modal-status-' + status._id}>
            <div className="modal-dialog modal-sm">
                <form onSubmit={handleSubmit(handleSave)} className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Status</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group row">
                            <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                <FormattedMessage id="project.detail.settings.status.table.column.name.status" />
                            </label>
                            <div className="col-sm-8">
                                <input
                                    placeholder="Name of status ?"
                                    className={
                                        errors.name ? 'is-invalid form-control' : 'form-control'
                                    }
                                    {...register('name', {
                                        required: TranslateHelper.getMessage(
                                            'validation.error.required'
                                        ),
                                        pattern: {
                                            value: /^[a-zÀ-ȕ0-9(),-_.,  ]+$/gi,
                                            message: TranslateHelper.getMessage(
                                                'validation.error.containSpecialCharacter'
                                            )
                                        },
                                        maxLength: {
                                            value: 30,
                                            message: TranslateHelper.getMessage('validation.error.max30characters')
                                        }
                                    })}
                                />
                                {errors.name && (
                                    <span className="error text-danger">
                                        {errors.name.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-end">
                        <Button
                            type="button"
                            ref={hideModalButtonRef}
                            data-dismiss="modal"
                        >
                            <FormattedMessage id="button.name.common.cancel" />
                        </Button>
                        <Button
                            type="submit"
                            appearance='primary'
                            disabled={!isDirty || !isValid}
                        >
                            {TranslateHelper.getMessage('button.name.common.save')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
