import AxiosService from '../../../services/axios.service';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/partnermember/sandboxes';

class SandboxService {
    constructor () {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            AxiosService.setHeader('x-access-token', 'Brearer ' + accessToken);
        }
    }

    createSchedule (payload) {
        return AxiosService.post(API_URL + '/schedules', payload);
    }

    destroySchedule (id) {
        return AxiosService.delete(API_URL + '/schedules/' + id);
    }

    getSchedule (sandboxId) {
        return AxiosService.get(API_URL + '/schedules/' + sandboxId);
    }

    updateSchedule (scheduleId, payload) {
        return AxiosService.patch(API_URL + '/schedules/' + scheduleId, payload);
    }
}

export default new SandboxService();
