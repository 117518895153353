import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CustomInputDate } from '../../../../components/common/CustomInputDatepicker';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import moment from 'moment';
import axiosService from '../../../../services/axios.service';
import leaverequestService from '../organization-detail/services/leaverequest.service';
import Swal from 'sweetalert2';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import LeaveRequest from './LeaveRequest';
import ReactPagination from '../../../ReactPagination';
import { ServiceConstants } from '../../../common/constants/service.constants';
import Button from '@atlaskit/button';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/';

const defaultValues = {
    leavetype: { value: '', label: '' },
    fromdate: undefined,
    todate: undefined
};

const handleRenderLeaveType = (type) => {
    if (type) {
        const render = ServiceConstants.LEAVE_TYPE_CONTRACT.find(ele => ele.value === parseInt(type));
        return render.label;
    }
    return '';
};

function MyLeave ({ user }) {
    const customSwal = Swal.mixin({
        customClass: {
            cancelButton: 'btn btn-outline-danger',
            confirmButton: 'btn btn-primary ml-2'
        },
        buttonsStyling: false
    });
    const { handleSubmit, control, register, formState: { errors }, watch, reset, getValues } = useForm({ defaultValues });
    const lang = localStorage.getItem('lang');
    const [myLeave, setMyLeave] = useState([]);
    const hideModalButtonRef = useRef();
    const buttonSubmitRef = useRef();
    const closeModal = () => {
        hideModalButtonRef.current?.click();
        reset();
    };
    const [detail, setDetail] = useState([]);
    const onSubmit = (data) => {
        const { fromdate, todate, status } = data;
        leaverequestService.filterMyLeave(status?.value, fromdate, todate).then((res) => {
            setMyLeave(res.data.data);
        });
    };
    const fetchLR = useCallback((page = 1) => {
        try {
            axiosService.get(API_URL + 'hrm/services/myrequest', { params: { page, fromDate: getValues('fromdate'), toDate: getValues('todate'), status: getValues('status.value') } }).then((res) => {
                if (res.status === 200) {
                    setMyLeave(res.data.data);
                }
            });
        } catch (error) {
            console.warn(error);
        }
    }, []);
    const viewDetailLR = (id) => {
        leaverequestService.viewLR(id).then((res) => {
            setDetail(res.data.data);
        });
    };

    const CancelLeaveRequest = (id) => {
        customSwal
            .fire({
                title: TranslateHelper.getMessage('alert.hrm.service.myleave.cancel'),
                text: TranslateHelper.getMessage('alert.detete.text'),
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#3085d6',
                cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
                confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    try {
                        leaverequestService
                            .cancelLR(id)
                            .then((res) => {
                                if (res.status === 200) {
                                    ToastTopEnd.fire({
                                        icon: 'success',
                                        title: TranslateHelper.getMessage(
                                            'respond.message.delete.successfully'
                                        )
                                    });
                                    fetchLR();
                                }
                            })
                            .catch((error) => {
                                if (error.response.data) {
                                    ToastTopEnd.fire({
                                        icon: 'error',
                                        title: error.response.data.message
                                    });
                                }
                            });
                    } catch (error) {
                        console.warn(error);
                    }
                }
            });
    };

    // const editLeaveRequest = (id) => {
    //     viewDetailLR(id);
    // };
    useEffect(fetchLR, [fetchLR]);

    return (
        <>
            <div className="modal fade" id="modal-editMyleave">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                <FormattedMessage id="hrm.services.myleave.tabs.name.edit" />
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <LeaveRequest
                                isEdit="true"
                                details={detail}
                                refetch={fetchLR}
                                closeModal={closeModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modal-myleave">
                <div className="modal-dialog modal-xs">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                <FormattedMessage id="hrm.services.myleave.tabs.name.view" />
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p >
                                {' '}
                                <span className='font-weight-bold'>{TranslateHelper.getMessage(
                                    'hrm.services.myleave.view.label.employee'
                                ) + ' : '}</span>  {
                                    detail?.createdBy?.firstname +
                                    ' ' +
                                    detail?.createdBy?.lastname}
                            </p>
                            <p>
                                {' '}
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.leavetype'
                                    ) + ' : '}
                                </span>
                                    {handleRenderLeaveType((detail?.leaveType))}
                            </p>
                            <p className='text-break'>
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.reason'
                                    ) + ' : '}
                                </span>
                                {
                                    detail?.reasonLeave}
                            </p>
                            <p>
                                {' '}
                                <span className='font-weight-bold'>
                                    {
                                        TranslateHelper.getMessage(
                                            'hrm.services.myleave.view.label.from'
                                        ) + ' : '
                                    }
                                </span>
                                {
                                    moment(detail?.fromDate).format(
                                        TranslateHelper.getMessage('date.format')
                                    )}
                            </p>
                            <p>
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage('hrm.services.myleave.view.label.to') + ' : '}
                                </span>
                                {
                                    moment(detail?.toDate).format(
                                        TranslateHelper.getMessage('date.format')
                                    )}
                            </p>
                            <p>
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.leavefor'
                                    ) + ' : '}
                                </span>
                                {
                                    (detail?.leaveFor === ServiceConstants.ValhalfAM
                                        ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValhalfAM)[0].label
                                        : detail?.leaveFor === ServiceConstants.ValFULLDATE
                                            ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValFULLDATE)[0].label
                                            : detail?.leaveFor === ServiceConstants.ValhalfPM
                                                ? ServiceConstants.LEAVE_FOR.filter(leave => leave.value === ServiceConstants.ValhalfPM)[0].label
                                                : ''
                                    )}
                            </p>
                            <p>
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.linemanager'
                                    ) + ' : '}
                                </span>
                                {
                                    detail?.approveBy?.firstname +
                                    ' ' +
                                    detail?.approveBy?.lastname}
                            </p>
                            { detail?.isAssigned && (
                                <p>
                                    <span className='font-weight-bold'>{TranslateHelper.getMessage('hrm.services.myleave.view.label.assignedBy') + ': '}</span>
                                    {detail?.approveBy?.firstname + ' ' + detail?.approveBy?.lastname}
                                </p>
                            ) }
                            <p>
                                <span className='font-weight-bold'>
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.days'
                                    ) + ' : '}
                                </span>
                                {
                                     detail?.totalWfhDays || detail?.totalDayLeave || detail?.totalSummerDayLeave}
                            </p>
                            {detail?.approveOn
                                ? (
                                <p>
                                    <span className='hightlight-activities p-1'>{TranslateHelper.getMessage('hrm.services.myleave.view.label.approveon')}</span>: {moment(detail?.approveOn).format(TranslateHelper.getMessage('date.format'))}
                                </p>
                                    )
                                : detail?.reasonReject
                                    ? (
                                <p>
                                    {' '}
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.status'
                                    ) +
                                        ': ' +
                                        'Rejected'}
                                </p>
                                        )
                                    : (
                                            ''
                                        )}
                            {detail?.reasonReject && (
                                <p>
                                    {' '}
                                    {TranslateHelper.getMessage(
                                        'hrm.services.myleave.view.label.comment'
                                    ) +
                                        ':' +
                                        detail?.reasonReject}
                                </p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <Button type="button" appearance='primary' data-dismiss="modal">Close</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                        <div className="form-horizontal">
                            <div className="row justify-content-between">
                                <div className="col-xl-3 col-6">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <Controller
                                                control={control}
                                                name="fromdate"
                                                render={({ field }) => (
                                                    <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    className="input"
                                                    isClearable
                                                    maxDate={watch('todate') && watch('todate')}
                                                    placeholderText={TranslateHelper.getMessage('hrm.services.myleave.placeholder.from')}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        buttonSubmitRef?.current.click();
                                                    }}
                                                    selected={field.value}
                                                    locale={lang}
                                                    customInput={<CustomInputDate />}
                                                    />
                                                )}
                                            />
                                            <span className="error text-danger">
                                                {' '}
                                                {errors.fromdate?.message}{' '}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-6">
                                    <div className="form-group row">
                                        <div className="col-12">
                                                <Controller
                                                    name="todate"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode='select'
                                                            className="input"
                                                            isClearable
                                                            placeholderText={TranslateHelper.getMessage('hrm.services.myleave.placeholder.to')}
                                                            minDate={watch('fromdate') && watch('fromdate')}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                buttonSubmitRef?.current.click();
                                                            }}
                                                            selected={field.value}
                                                            value={field.value}
                                                            locale={lang}
                                                            customInput={<CustomInputDate />}
                                                        />
                                                    )}
                                                />
                                                <span className="error text-danger">
                                                    {' '}
                                                    {errors.todate?.message}{' '}
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-8">
                                    <div className="form-group row">
                                        <div className='col-3' style={{ alignSelf: 'end' }}><label><FormattedMessage id="hrm.services.myleave.label.status" /></label></div>
                                        <div className="col-9">
                                            <Controller
                                                control={control}
                                                name="status"
                                                render={({ field }) => (
                                                    <Select
                                                        {...register('status')}
                                                        isClearable
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            buttonSubmitRef?.current.click();
                                                        }}
                                                        options={[
                                                            { value: '1', label: 'Pending' },
                                                            { value: '2', label: 'Approved' },
                                                            { value: '3', label: 'Rejected' }
                                                        ]}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-1" style={{ display: 'none' }}>
                                    <div className='form-group row'>
                                        <div className='col-12'>
                                            <Button type="submit" appearance='primary' ref={buttonSubmitRef}>
                                                {TranslateHelper.getMessage('button.name.common.search')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-scrollX'>
                        <table id="issue-type-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.type" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.from" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.to" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.days" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.created" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.status" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="hrm.services.mydetail.table.column.name.action" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* TODO: Improve in future because having bug in modify api */}
                                {myLeave.items &&
                                    myLeave.items.map((leave) => {
                                        return (
                                            <tr key={leave._id}>
                                                <td>
                                                    {handleRenderLeaveType(leave?.leaveType)}
                                                </td>
                                                <td>
                                                    {moment(leave?.fromDate).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    )}
                                                </td>
                                                <td>
                                                    {moment(leave?.toDate).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    )}
                                                </td>
                                                <td>{leave?.totalDayLeave || leave?.totalWfhDays || leave?.totalSummerDayLeave}</td>
                                                <td>
                                                    {moment(leave?.createdAt).format(
                                                        TranslateHelper.getMessage('date.format')
                                                    )}
                                                </td>
                                                <td>
                                                    {leave.status === 1 ? 'Pending' : leave.status === 2 ? 'Approved' : 'Rejected'}
                                                </td>
                                                <td style={{ minWidth: '200px' }}>
                                                    <Button
                                                        data-toggle="modal"
                                                        data-target="#modal-myleave"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                        onClick={() => viewDetailLR(leave._id)}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Button>
                                                    {/* {leave?.status === 1 && <Button
                                                        ref={hideModalButtonRef}
                                                        onClick={() => editLeaveRequest(leave._id)}
                                                        data-toggle="modal"
                                                        data-dimiss="modal"
                                                        data-target="#modal-editMyleave"
                                                        className="mr-2"
                                                        title={TranslateHelper.getMessage('button.name.common.edit')}
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </Button>} */}
                                                    {leave?.status === 1 && <Button
                                                        onClick={() => CancelLeaveRequest(leave._id)}
                                                        aria-hidden="true"
                                                        className=""
                                                    >
                                                        <i
                                                            className="far fa-trash-alt"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Button>}
                                                    <div className="row justify-content-sm-around"></div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                myLeave?.totalPages > 1 &&
                    <ReactPagination
                        totalPages={myLeave?.totalPages}
                        handlePageChange={(e, page) => fetchLR(page)}
                        pageNumber={myLeave?.page}
                    />
            }
        </>
    );
}
function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(MyLeave);
