import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryTable from './HistoryTable';
import { useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
function DeviceHistory ({ deviceId, actions }) {
    const [status, setStatus] = useState(1);
    const tableHeaders = [
        {
            headers: ['Từ ngày', 'Đến ngày', 'Trạng thái cũ']
        },
        {
            headers: ['Người sử dụng', 'Người cấp', 'Từ ngày', 'Đến ngày', 'Trạng thái cũ', '']
        },
        {
            headers: ['Công ty bảo hành', 'Từ ngày', 'Đến ngày', 'Trạng thái cũ', '']
        }
    ];
    return (
        <div className='pr-4 pb-5 pt-3'>
            <Tabs className="row">
                <TabList className='d-flex flex-column custom-history-tabs'>
                    <Tab className='available-tab' onClick={() => setStatus(1)}>
                        <ArrowRightIcon />
                        <h6>Tồn kho</h6>
                    </Tab>
                    <Tab className='used-tab' onClick={() => setStatus(2)}>
                        <ArrowRightIcon />
                        <h6>Xuất kho</h6>
                    </Tab>
                    <Tab className='broken-tab' onClick={() => setStatus(3)}>
                        <ArrowRightIcon />
                        <h6>Sửa chữa</h6>
                    </Tab>
                </TabList>
                <div className="col-xl-10 col-12 pl-0 history-list card">
                    {
                        tableHeaders.map((item, i) => {
                            return (
                                <TabPanel key={i}>
                                    <HistoryTable actions={actions} headers={item.headers} status={status} deviceId={deviceId} />
                                </TabPanel>
                            );
                        })
                    }
                </div>
            </Tabs>
        </div>
    );
}

export default DeviceHistory;
