import { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FilterComponent from './FilterComponent';
import boardService from '../../services/board.service';
import statusService from '../../services/status.service';
import IssueDetail from '../../../common/IssueDetailComponent';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import Folder from '../../images/folder_blue_1.svg';
import { ISSUE_PRIORITY } from '../../../../constants/project.constant';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import issueService from '../../services/issue.service';

export default function Board ({ projectId, projectName, total }) {
    const location = useLocation();
    const history = useHistory();
    const [columns, setColumns] = useState({});
    const [isView, setIsView] = useState();
    const [filterOptions, setFilterOptions] = useState({});
    const [refetch, setRefetch] = useState(true);
    const [isEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const priorities = [
        { id: ISSUE_PRIORITY.LOW, name: 'Low' },
        { id: ISSUE_PRIORITY.MEDIUM, name: 'Medium' },
        { id: ISSUE_PRIORITY.HIGH, name: 'High' }
    ];

    useEffect(() => {
        localStorage.removeItem('projectFilter');
    }, []);

    const fetchStatus = useCallback(async (query) => {
        setFilterOptions(query);
        try {
            setIsLoading(true);
            const statuses = await statusService.getStatus(projectId);
            statuses.map(status => {
                return setColumns((prev) => {
                    return {
                        ...prev,
                        [status._id]: {
                            name: status.name,
                            id: status._id,
                            items: [],
                            count: 0,
                            nextPage: 1
                        }
                    };
                });
            });
            const listStatus = query.status ? statuses.filter(s => query.status.includes(s._id)) : statuses;
            listStatus.map(async status => {
                const issues = await issueService.getAllIssues(projectId, { ...query, status: status._id }, 30);
                return setColumns((prev) => {
                    const copyItems = prev[status._id];
                    return {
                        ...prev,
                        [status._id]: {
                            ...copyItems,
                            items: issues.items,
                            count: issues.total,
                            nextPage: issues.nextPage
                        }
                    };
                });
            });
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [projectId, filterOptions, refetch]);

    const fetchMoreIssues = useCallback(async (e, status, nextPage) => {
        if (nextPage !== null) {
            const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 2;
            filterOptions.status = status;
            if (bottom) {
                const issues = await issueService.getAllIssues(projectId, filterOptions, 30, nextPage);
                const columnStatus = columns[status];
                setColumns({
                    ...columns,
                    [status]: {
                        ...columnStatus,
                        items: [...columnStatus.items, ...issues.items],
                        nextPage: issues.nextPage
                    }
                });
            }
        }
    }, [columns]);

    const onDragEnd = useCallback(async (result) => {
        if (!result.destination) return;
        const { source, destination, draggableId } = result;
        const oldStatus = source.droppableId;
        const newStatus = destination.droppableId;

        // If status of issue change
        if (oldStatus !== newStatus) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            // Call API
            const issueId = draggableId;
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                    count: sourceColumn.count - 1
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                    count: destColumn.count + 1
                }
            });
            try {
                const payload = {
                    status: newStatus,
                    changeStatus: true
                };
                await boardService.updateIssueStatus(projectId, issueId, payload);
            } catch (error) {
                console.warn(error.response.data.message);
                setColumns(columns);
            }
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    }, [columns]);

    useEffect(() => {
        if (refetch) {
            const query = queryString.parse(location.search);
            const payload = {
                milestoneId: query?.milestone,
                assignTo: query?.assignee,
                status: query.status,
                priority: query?.priority,
                categoryId: query?.category,
                issueTypeId: query?.type,
                createdBy: query?.creator,
                q: query?.q
            };
            fetchStatus(payload);
            setRefetch(false);
        }
    }, [refetch, isEmpty]);

    const skelatonLoading = () => {
        return (
            <div className='row'>
                <div className='col-3'><Skeleton width={'100%'} height={'250px'} /></div>
                <div className='col-3'><Skeleton width={'100%'} height={'100%'} /></div>
                <div className='col-3'><Skeleton width={'100%'} height={'100%'} /></div>
                <div className='col-3'><Skeleton width={'100%'} height={'100%'} /></div>
            </div>
        );
    };

    const handleOpenModalIssue = (issueCode) => {
        setIsView(issueCode);
        const query = queryString.parse(location.search);
        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ ...query, issueModal: issueCode })
        });
    };

    return (
        <div className='container-fluid'>
            <FilterComponent onSubmit={setFilterOptions} filterOptions={filterOptions} projectId={projectId} setRefetch={setRefetch} />
            {!isLoading
                ? total === 0
                    ? (<div className='w-full text-center'>
                        <img style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '5%', paddingTop: '150px', minWidth: '50px' }} src={Folder} ></img>
                        <div className='text-muted mt-2 text-lg'>No issues here !!!</div>
                    </div>)
                    : (
                        <div style={{ overflowX: 'auto', scrollbarWidth: 'auto' }}>
                            <div className='d-flex flex-nowrap'>
                                <DragDropContext
                                    onDragEnd={onDragEnd}
                                >
                                    {Object.entries(columns).map(([columnId, column]) => {
                                        return (
                                            <div
                                                className='col-3 pb-2 px-0'
                                                key={columnId}
                                                style={{ maxWidth: '350px' }}
                                            >
                                                <div style={{ backgroundColor: '#f5f7fb', height: '100%', padding: '0 10px' }}>
                                                    <div className='row ' style={{ padding: '15px' }}>
                                                        <div className=" font-weight-bold text-md px-3" style={{ color: '#5C5C5C' }}>{column.name}
                                                            {!isEmpty && <span className='pl-2 text-md align-self-center' style={{ color: '#ACACAC', fontSize: 'small' }}>{column.count}</span>}
                                                        </div>
                                                    </div>
                                                    {!isEmpty &&
                                                        <Droppable droppableId={columnId} key={columnId}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        className="card-body"
                                                                        style={column.items.length === 0 ? { border: 'solid 2px #CACACA', borderRadius: '10px', padding: '3px 5px', height: '600px' } : { backgroundColor: '#F4F5F7', padding: '3px 5px', overflowY: 'auto', height: '600px' }}
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        onScroll={(e) => fetchMoreIssues(e, columnId, column.nextPage)}
                                                                    >
                                                                        {column.items.map((item, index) => {
                                                                            return (
                                                                                <div key={index} onClick={() => handleOpenModalIssue(item?.issueCode)} data-toggle="modal" data-target={'#issueDetail-' + item.issueCode}>
                                                                                    <Draggable
                                                                                        draggableId={item?.issueCode}
                                                                                        index={index}
                                                                                        key={item?.issueCode}
                                                                                    >
                                                                                        {(provided, snapshot) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className='p-3  bg-white shadow-sm mb-2'
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={{
                                                                                                        cursor: 'all-scroll',
                                                                                                        borderRadius: '10px',
                                                                                                        userSelect: 'none',
                                                                                                        backgroundColor: snapshot.isDragging
                                                                                                            ? '#94b2c8'
                                                                                                            : '#456C86',
                                                                                                        color: 'white',
                                                                                                        ...provided.draggableProps.style
                                                                                                    }}
                                                                                                >
                                                                                                    <div className='row justify-content-between px-3'>
                                                                                                        <h5 className='text-md text-break' style={{ color: '#5C5C5C' }}>{item.subject}</h5>
                                                                                                        <div className='text-info '><u>{item.name}</u></div>
                                                                                                    </div>
                                                                                                    <div className='row justify-content-between px-3'>
                                                                                                        <div style={{ color: '#848484' }}>{item.comments.length} comments</div>
                                                                                                    </div>
                                                                                                    <div className='row justify-content-between  px-3'>
                                                                                                        <div className='row pt-3 px-3'>
                                                                                                            <div className='mr-1' style={{ alignSelf: 'center', fontWeight: 'lighter', cursor: 'pointer', fontSize: 'x-small', padding: '5px', borderRadius: '10px', background: '#FAAFBA' }}>{item?.issueTypeId?.issueType}</div>
                                                                                                            {item.categoryId && <div className='mr-1' style={{ alignSelf: 'center', fontWeight: 'lighter', cursor: 'pointer', fontSize: 'x-small', padding: '5px', borderRadius: '10px', background: '#E3E4FA' }}>{item?.categoryId?.categoryName}</div>}
                                                                                                            {item.priority && <div className='mr-1' style={{ alignSelf: 'center', fontWeight: 'lighter', cursor: 'pointer', fontSize: 'x-small', padding: '5px', borderRadius: '10px', background: '#75F5CA' }}>{priorities.find((i) => i.id === item.priority)?.name}</div>}
                                                                                                        </div>
                                                                                                        <div className='row justify-content-end pt-3 px-3'>
                                                                                                            <div className='mr-1' style={{ alignSelf: 'center', fontWeight: '500', cursor: 'pointer', color: '#838383' }}>{item?.issueCode}</div>
                                                                                                            <div style={{ overflow: 'unset' }}>
                                                                                                                <div>
                                                                                                                    <img width={'25px'} onError={(e) => { e.target.src = '/images/default-avatar.png'; }} src={item.assignTo?.picture ? (!String(item.assignTo?.picture).includes('uploads') ? item.assignTo?.picture : recruitmentService.buildFileURL(item.assignTo?.picture)) : '/images/default-avatar.png'} title={!item.assignTo?.picture ? 'Unassigned' : item.assignTo?.firstname + ' ' + item.assignTo?.lastname} alt="Avatar-user" className="img-circle " />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }}
                                                                                    </Draggable>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Droppable>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                </DragDropContext>
                            </div>
                        </div>)
                : skelatonLoading()}
            {isView && <IssueDetail id={isView} isPopup={true} projectId={projectId} projectName={projectName} setRefetch={setRefetch} />}
        </div>
    );
}
