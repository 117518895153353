import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Context from '../../../../common/context';
import UserService from '../../../../common/services/auth.service';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../../common/actions/breadcrumb.action';
import { updateUser } from '../../../../common/actions/auth.action';
import { ToastTopEnd } from '../../../../common/helpers/ToastTimer';
import AvatarComponent from '../../../../common/components/common/AvatarComponent';
import $ from 'jquery';
import validator from 'validator';
import { ROLES } from '../../../../common/constants/user.constant';
import recruitmentService from '../../../../common/components/hrm/Services/recruitment.service';
import Button from '@atlaskit/button';
import DatePicker from 'react-datepicker';
import NotifyPlatform from './NotifyPlatform';
import ModalUpdatePassword from './ModalUpdatePassword';

class Setting extends Component {
    constructor (props) {
        super(props);
        this.setEditorRef = (editor) => (this.editor = editor);
        this.role = props.role;
        this.state = {
            regx: {
                format: /[^A-Za-z 0-9]/g,
                addressRegex: /^\s*\S+(?:\s+\S+){2,}/,
                phoneRegx: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
            },
            loadingStatus: false,
            isError: {
                firstname: '',
                lastname: '',
                email: '',
                language: '',
                noSandboxDisplay: '',
                birthDay: '',
                phoneNumber: '',
                address: ''
            },
            form: {
                firstname: '',
                lastname: '',
                email: '',
                language: '',
                noSandboxDisplay: '',
                birthDay: '',
                phoneNumber: '',
                address: ''
            },
            notifyPlatform: {}
        };
    }

    async onClickSave () {
        if (this.editor?.current) {
            const datas = new FormData();
            const canvas = this.editor?.current?.getImageScaledToCanvas().toDataURL('image/jpeg', 0.9);
            const blob = await fetch(canvas).then(res => res.blob()).then(blob => blob);
            const { form } = this.state;
            const payload = {
                firstname: form.firstname.trim(),
                lastname: form.lastname.trim(),
                language: form.language.trim(),
                noSandboxDisplay: form.noSandboxDisplay,
                phoneNumber: form.phoneNumber.trim(),
                address: form.address.trim(),
                birthDay: form.birthDay
            };
            for (const key of Object.keys(payload)) {
                payload[key] && datas.append(key, payload[key]);
            }
            if (blob) {
                datas.append('file', new File([blob], 'avatar.jpeg', {
                    type: blob.type
                }));
            }
            await UserService.saveSetting(datas).then((res) => {
                const data = res.data.data;
                this.setState({
                    avatarUrl: recruitmentService.buildFileURL(data.picture),
                    form: {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.username,
                        language: data.setting.language,
                        noSandboxDisplay: data?.setting.member.sandbox.noSandboxDisplay
                    }
                });
                this.context.updateLanguage(form.language);
                const { dispatch } = this.props;
                const user = {
                    ...this.props.user,
                    name: data.firstname + ' ' + data.lastname,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.username,
                    imageUrl: data.picture,
                    switchRole: data.switchRole,
                    noSandboxDisplay: data.setting.member.sandbox.noSandboxDisplay,
                    userId: data._id
                };
                dispatch(updateUser({ user }));
                localStorage.setItem('user', JSON.stringify(user));
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
            }).catch((error) => {
                if (error.response) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: TranslateHelper.getMessage('respond.message.failed')
                    });
                }
            });
            $('.close').trigger('click');
        }
    }

    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.setting');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.member.name.setting',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.setting',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        this.getSettingInfo();
    }

    getSettingInfo () {
        UserService.getSettingInfo().then((res) => {
            const data = res.data.data;
            this.setState({
                form: {
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.username,
                    language: data.setting?.language,
                    noSandboxDisplay: data?.setting?.member?.sandbox?.noSandboxDisplay,
                    phoneNumber: data?.phoneNumber,
                    address: data?.address,
                    birthDay: new Date(data.birthDay || Date.now())
                }
            });
            this.setState({ notifyPlatform: data?.notifyPlatform });
        });
    }

    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    async handleSave () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                firstname: form.firstname.trim(),
                lastname: form.lastname.trim(),
                language: form.language,
                noSandboxDisplay: form.noSandboxDisplay.trim(),
                phoneNumber: form.phoneNumber,
                address: form.address,
                birthDay: form.birthDay
            };
            await UserService.saveSetting(payload).then((res) => {
                const data = res.data.data;
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                this.setState({
                    form: {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.username,
                        language: data.setting.language,
                        noSandboxDisplay: data?.setting?.member?.sandbox?.noSandboxDisplay,
                        phoneNumber: data.mobile,
                        address: data.address,
                        birthDay: new Date(data.birthDay)
                    }
                });
                this.context.updateLanguage(form.language);
                const { dispatch } = this.props;
                const user = {
                    ...this.props.user,
                    name: data.firstname + ' ' + data.lastname,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.username,
                    switchRole: data.switchRole,
                    noSandboxDisplay: data.setting.member.sandbox.noSandboxDisplay,
                    userId: data._id
                };
                dispatch(updateUser({ user }));
                localStorage.setItem('user', JSON.stringify(user));
            }).catch((error) => {
                if (error.response) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: TranslateHelper.getMessage('respond.message.failed')
                    });
                }
            });
        }
        this.forceUpdate();
    }

    handleChangeDatePicker (name, date) {
        const { form, isError } = this.state;
        form[name] = date;
        isError[name] = '';
        this.setState({ form });
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError, regx } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'address':
                    if (!value) {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    } else if (!regx.addressRegex.test(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.specialCharacter');
                        validation = false;
                    }
                    break;
                case 'firstName': case 'lastName':
                    if (!value) {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    } else if (regx.format.test(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.specialCharacter');
                        validation = false;
                    }
                    break;
                case 'noSandboxDisplay':
                    if (!value) {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    } else if (!validator.isInt(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.invalided');
                        validation = false;
                    }
                    break;
                case 'phoneNumber':
                    if (!regx.phoneRegx.test(value)) {
                        isError[key] = TranslateHelper.getMessage('validation.error.invalided');
                        validation = false;
                    } else if (!value) {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'firstname': case 'lastname': case 'language': case 'noSandboxDisplay': case 'address': case 'phoneNumber':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    render () {
        const { isError, form } = this.state;
        return (
            <div className="row">
                <div className='col-sm-12'>
                    <div className="card card-warning card-outline">
                        <a className="d-block w-100">
                            <div className="card-header">
                                <h4 className="card-title w-100">
                                <FormattedMessage id="account.form.label.information"/>
                                </h4>
                            </div>
                        </a>
                        <div>
                            <div className="card-body" style={{ display: 'flex' }}>
                                <div className='col-sm-8'>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="account.form.label.firstname"/></label>
                                        <div className="col-sm-10">
                                            <input
                                                value={form.firstname}
                                                type="text"
                                                name="firstname"
                                                onChange={this.handleInputChange.bind(this, 'firstname')}
                                                className={isError.firstname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                            />
                                            {isError.firstname.length > 0 && (<span className="error invalid-feedback">{isError.firstname}</span>)}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="account.form.label.lastname"/></label>
                                        <div className="col-sm-10">
                                            <input
                                                value={form.lastname}
                                                type="text"
                                                name="lastname"
                                                onChange={this.handleInputChange.bind(this, 'lastname')}
                                                className={isError.lastname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                            />
                                            {isError.lastname.length > 0 && (<span className="error invalid-feedback">{isError.lastname}</span>)}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="account.form.label.email"/></label>
                                        <div className="col-sm-10">
                                            <input type="email" value={form.email} className="form-control" disabled/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="HRM.detail.HR.official.label.mobile"/></label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                name="phoneNumber"
                                                onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                                                value={form.phoneNumber}
                                                className={isError.phoneNumber.length > 0 ? 'is-invalid form-control' : 'form-control'}/>
                                            {isError.phoneNumber.length > 0 && (<span className="error invalid-feedback">{isError.phoneNumber}</span>)}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="HRM.detail.HR.official.label.address"/></label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                name="address"
                                                onChange={this.handleInputChange.bind(this, 'address')}
                                                value={form.address}
                                                className={isError.address.length > 0 ? 'is-invalid form-control' : 'form-control'}/>
                                            {isError.address.length > 0 && (<span className="error invalid-feedback">{isError.address}</span>)}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="HRM.detail.HR.official.label.birth"/></label>
                                        <div className="col-sm-10">
                                            <DatePicker
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                selected={form.birthDay}
                                                onChange={this.handleChangeDatePicker.bind(this, 'birthDay')}
                                                className={
                                                    isError.birthDay.lenght > 0
                                                        ? 'is-invalid form-control p-2'
                                                        : 'form-control'
                                                }
                                                placeholderText='DD/MM/YYYY'
                                                popperPlacement="right-end"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="account.form.label.language"/></label>
                                        <div className="col-sm-10">
                                            <select name="language" disabled className="form-control" value={form.language} onChange={this.handleInputChange.bind(this, 'language')}>
                                                <FormattedMessage id='account.form.label.language.english' key={'op' + '-' + 'en'}>
                                                    {(message) => <option value='en'>{message}</option>}
                                                </FormattedMessage>
                                                <FormattedMessage id='account.form.label.language.japanese' key={'op' + '-' + 'ja'}>
                                                    {(message) => <option value='ja'>{message}</option>}
                                                </FormattedMessage>
                                                <FormattedMessage id='account.form.label.language.vietnamese' key={'op' + '-' + 'vi'}>
                                                    {(message) => <option value='vi'>{message}</option>}
                                                </FormattedMessage>
                                            </select>
                                        </div>
                                    </div>
                                    { (this.role === ROLES.PARTNERMEMBER || this.role === ROLES.PARTNEROWNER) &&
                                        <div className="form-group row">
                                            <label className="col-xl-2 col-form-label">No. Sandbox Display</label>
                                            <div className="col-xl-10">
                                                <input
                                                    defaultValue={form.noSandboxDisplay}
                                                    type="number"
                                                    min={1}
                                                    name="noSandboxDisplay"
                                                    onChange={this.handleInputChange.bind(this, 'noSandboxDisplay')}
                                                    className={isError.noSandboxDisplay.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                                />
                                                {isError.noSandboxDisplay.length > 0 && (<span className="error invalid-feedback">{isError.noSandboxDisplay}</span>)}
                                        </div>
                                    </div>
                                    }
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="account.form.label.platform"/></label>
                                        <div className="col-sm-10">
                                            <NotifyPlatform notifyPlatform={this.state.notifyPlatform} user={this.state.form}/>
                                        </div>
                                    </div>
                                </div>
                                <AvatarComponent setEditorRef = {this.setEditorRef.bind(this)} onClickSave = {this.onClickSave.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <Button type="button" className="col-sm-2 col-4" appearance='primary' onClick={() => { this.handleSave(); }}><FormattedMessage id="button.name.common.save"/></Button>
                    <ModalUpdatePassword/>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    return {
        user, role
    };
}
Setting.contextType = Context;

export default connect(mapStateToProps)(Setting);
