import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Candidates from './recruitment-detail/Candidates';
import Interview from './recruitment-detail/Interview';
import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { useLayoutEffect } from 'react';
import CVManagement from './recruitment-detail/CVManagement';
import { RecruitmentProvider } from '../../../context/RecruitmentContext';
import { useService } from '../../../helpers/serviceHelpers';
import departmentService from './organization-detail/services/department.service';
import hrService from '../Services/hr.service';

export default function Recruitment ({ dispatch, recruitmentIdx }) {
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'app.breadcrumb.common.hrm.recruitment',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            clearBreadcrumb();
        };
    }, [dispatch]);

    const { data: forTeams } = useService(departmentService.getAllDepartment, undefined, (res) =>
        res.data.data.items.map((item, index) => ({
            value: item._id,
            label: item.name
        }))
    );

    const { data: interviewers } = useService(hrService.getEmployees, undefined, (data) =>
        data.items.map((item) => ({
            label: `${item.firstname} ${item.lastname}`,
            value: item._id
        }))
    );
    return (
        <>
            <Tabs className="row" defaultIndex={recruitmentIdx}>
                <TabList className='col-12 col-xl-2 d-flex flex-column'>
                    <Tab><FormattedMessage id='HRM.detail.recruitment.tab.name.interview'/></Tab>
                    <Tab><FormattedMessage id='HRM.detail.recruitment.tab.name.candidates'/></Tab>
                    <Tab><FormattedMessage id='HRM.detail.recruitment.tab.name.CVManagement'/></Tab>
                </TabList>
                <div className="col-xl-10 col-12 ">
                    <RecruitmentProvider value={{ forTeams, interviewers }}>
                        <TabPanel><Interview/></TabPanel>
                        <TabPanel><Candidates/></TabPanel>
                        <TabPanel><CVManagement/></TabPanel>
                    </RecruitmentProvider>
                </div>
            </Tabs>
        </>
    );
}
