import { useState, useCallback, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import issueService from '../../services/issue.service';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';

export default function FileUpload ({ projectId, issueId, files }) {
    if (!files) {
        return (
            <div></div>
        );
    }
    const ref = useRef();
    const [listFiles, setListFiles] = useState();

    useEffect(() => {
        const result = Object.keys(files).map((key) => files[key]);
        setListFiles(result);
    }, [files]);

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const formData = new FormData();

        const files = e.target.files;

        for (const key of Object.keys(files)) {
            formData.append('files', files[key]);
        }
        issueService.updateIssue(projectId, issueId, formData)
            .then((data) => {
                if (data.files) {
                    const dataFiles = Object.keys(data.files).map((key) => data.files[key]);
                    setListFiles(dataFiles);
                }

                ref.current.value = '';
            })
            .catch(error => {
                if (error.response) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: error.response.data.message
                    });
                } else {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: 'Error'
                    });
                }
            });
    };

    const fileData = useCallback(() => {
        if (listFiles) {
            return (
                <div className='file-preview row'>
                    {
                        Object.keys(listFiles).map((key) => {
                            const file = listFiles[key];
                            return (
                                <div className='col-12' key={key}>
                                    <div className='wrapper-image'><a target='_blank' rel = 'noreferrer' href={recruitmentService.buildFileURL(file.path)}>{file.name}</a></div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }, [listFiles]);

    return (
        <div className='attacked-file'>
            <button className='font-weight-bold btn-attacked-file'>
                <i className="fas fa-paperclip"></i>&nbsp;
                <FormattedMessage id='project.detail.new.label.attachments' />
                <span> ({listFiles && listFiles.length})</span>
                <input type='file' multiple onChange={onSelectFile} ref={ref} title='' />
            </button>
            {fileData()}
        </div>
    );
}
