import './FeaturedSection.css';

export default function Featured () {
    return (
        <section id="feature">
            <div className="features container">
                <div className="feature nospy row p-3">
                    <div className="text">
                        <hgroup>
                            <h3>Reach users on every screen</h3>
                        </hgroup>
                        <p className='p'>
                            Responsive templates take the user easy to access by multiple devices like desktop, tablet, mobile.
                        </p>
                        <button className="btn btn-primary" href="/multi-platform">
                            Get started
                        </button>
                    </div>
                    <div className="media">
                        <img
                            alt="Multi-Platform"
                            src="https://storage.googleapis.com/cms-storage-bucket/ed2e069ee37807f5975a.jpg"
                        />
                    </div>
                </div>
                <div className="feature reverse nospy">
                    <div className="text">
                        <hgroup>
                            <h4 className="eyebrow text-green">Developer Experience</h4>
                            <h3>Transform your workflow</h3>
                        </hgroup>
                        <p className='p'>
                            Take control of your codebase with automated testing, developer tooling,
                            and everything else you need to build production-quality apps.
                        </p>
                        <button className="btn  btn-primary" href="/development">
                            Flutter for developers
                        </button>
                    </div>
                    <div className="media">
                        <img
                            alt="Developer Experience"
                            src="https://storage.googleapis.com/cms-storage-bucket/75c5b74c32dfd7b7e8f3.jpg"
                        />
                    </div>
                </div>
                <div className="feature nospy">
                    <div className="text">
                        <hgroup>
                            <h4 className="eyebrow text-coral">Stable &amp; Reliable</h4>
                            <h3>Trusted by many</h3>
                        </hgroup>
                        <p className='p'>
                            Flutter is supported and used by Google, trusted by well-known brands
                            around the world, and maintained by a community of global developers.
                        </p>
                        <button className="btn btn-primary" href="/ecosystem">
                            Explore the ecosystem
                        </button>
                    </div>
                    <div className="media">
                        <img
                            alt="Ecosystem"
                            src="https://storage.googleapis.com/cms-storage-bucket/3461c6a5b33c339001c5.jpg"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
