import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useService } from '../../../../helpers/serviceHelpers';
import ReactPagination from '../../../ReactPagination';
import hrService from '../../Services/hr.service';
import ModalEmployees, { modalEmployeesRef } from './ModalEmployees';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import EmployeesFilter from './employeesFilter';
import Button from '@atlaskit/button';
import { ROLES } from '../../../../constants/user.constant';
import OptionView from './OptionView';
import EmployeeCard from './EmployeeCard';
import UploadFile from './UploadFile';
import { DownloadOutlined } from '@mui/icons-material';

function Employees ({ role }) {
    const defaultOptions = {
        departmentId: undefined,
        id: undefined,
        lastname: undefined,
        jobTitle: undefined,
        title: undefined,
        role: undefined
    };
    const [filters, setFilters] = useState(defaultOptions);

    const [page, setPage] = useState(1);
    const { refetch, data, isLoading } = useService(hrService.getEmployees, { page, limit: 12, ...filters });
    const [isNew, setIsNew] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [viewType, setViewType] = useState('grid');
    const firstClear = useRef(false);

    const handlePageChange = useCallback((event, page) => {
        setPage(page);
    }, []);

    function cardLoading () {
        return (
            <div className='col-12 col-xl-4'>
                <div className='card radius10'>
                    <div className='card-body'>
                        <div className='row'>
                            <div>
                                <Skeleton height={'50px'} width={'50px'} circle={true} />
                            </div>
                            <div className='ml-2'>
                                <Skeleton width={'100%'} />
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                    <div className='card-text col px-0 pt-3'>
                        <div className='mx-3'>
                            <Skeleton width={'100%'} />
                            <Skeleton width={'100%'} />
                            <Skeleton width={'100%'} />
                        </div>
                    </div>
                    <div className='card-body pt-0 d-flex justify-content-end'>
                        <div className='mr-3'>
                            <Skeleton width={'20px'} height={'20px'} circle={true} />
                        </div>
                        <Skeleton width={'20px'} height={'20px'} circle={true} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                {(role === ROLES.MANAGER || role === ROLES.HR) && (
                    <Button
                        onClick={() => {
                            modalEmployeesRef.current?.clearForm();
                            setSelectedEmployee(null);
                            setIsNew(true);
                        }}
                        type="button"
                        appearance='primary'
                        data-toggle="modal"
                        data-target="#modal-employees"
                    >
                        <FormattedMessage id="button.name.common.create" />
                    </Button>
                )}
                <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                    {role === ROLES.MANAGER && (
                        <>
                            <div style={{ background: '#9098db', padding: '7px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                                <a href="./file/excel_file.xlsx" download className='d-flex align-items-center' style={{ color: 'white' }}>
                                    <div><DownloadOutlined style={{ fontSize: '20px' }} /></div>
                                    <span style={{ fontSize: '14px', fontWeight: '500' }}>
                                        <FormattedMessage id="button.name.common.download" />
                                    </span>
                                </a>
                            </div>
                            <UploadFile refetch={refetch} />
                        </>
                    )}
                    <OptionView changeView={setViewType} />
                </div>
            </div>
            <ModalEmployees
                firstClear={firstClear}
                refetch={refetch}
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
                isNew={isNew}
            />
            <EmployeesFilter setFilters={setFilters} setPage={setPage} />
            <div className="row">
                {isLoading
                    ? (
                        <div className='w-100 row'>
                            {new Array(9).fill(null).map(cardLoading)}
                        </div>
                        )
                    : (
                            data?.items?.map((employee, index) => {
                                return (
                                <div key={index} className={viewType === 'grid' ? 'col-12 col-xl-4' : 'col-12'} style={{ transition: '.5s ease' }}>
                                    <div className={employee.status === 'disable' ? 'pb-3 disabled-employee' : 'pb-3'}>
                                        <EmployeeCard data={employee} firstClear={firstClear} setIsNew={setIsNew} isShowEdit={role === ROLES.MANAGER || role === ROLES.HR} setSelectedEmployee={setSelectedEmployee} />
                                    </div>
                                </div>
                                );
                            })
                        )}
            </div>
            {data.totalPages > 1 && (
                <ReactPagination
                    totalPages={data.totalPages}
                    handlePageChange={handlePageChange}
                    pageNumber={page}
                />
            )}
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { viewType } = state.hrm;
    return {
        role, viewType
    };
}

export default connect(mapStateToProps)(Employees);
