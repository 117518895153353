import { ACTION_HRM } from '../constants/action.constant';

const initialState = {
    kimeiMembers: [],
    viewType: 'grid'
};

const hrmReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ACTION_HRM.SET_OPTIONVIEW:
            return {
                ...state,
                payload
            };
        case ACTION_HRM.SET_KIMEI_MEMBER:
            return {
                ...state,
                kimeiMembers: payload
            };

        default:
            return state;
    }
};

export default hrmReducer;
