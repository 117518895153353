import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setBreadcrumb } from '../../../../actions/breadcrumb.action';
import { useParams } from 'react-router-dom';
import deviceService from '../../services/device.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './DeviceDetail.css';
import DeviceInformation from './Information';
import { useForm } from 'react-hook-form';
import DeviceHistory from './DeviceHistory';
import { useService } from '../../../../helpers/serviceHelpers';
import hrService from '../../../hrm/Services/hr.service';
import moment from 'moment';
import { actions, deviceType, offices, status } from '../../../../constants/device.constant';
function DeviceDetail ({ role, dispatch }) {
    const { data: employees } = useService(hrService.getEmployees, { limit: 9999 });
    const { deviceId } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const hookForm = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await deviceService.getDeviceById(deviceId);
                const { data } = response;
                dispatch(setBreadcrumb({
                    title: 'menu.manager.name.hrm',
                    breadcrumbItems: [
                        {
                            label: 'app.breadcrumb.common.home',
                            haslink: true,
                            path: '/dashboard'
                        },
                        {
                            label: 'menu.manager.name.device',
                            haslink: true,
                            path: '/device'
                        },
                        {
                            label: data?.name,
                            haslink: false,
                            path: ''
                        }
                    ]
                }));

                setData(data);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [deviceId, dispatch]);

    useEffect(() => {
        if (data) {
            const statusLabel = status.find((item) => item.value === data.status);
            hookForm.reset({
                name: data?.name || '',
                code: data?.code || '',
                type: data?.type || '',
                office: data?.office || '',
                buy_date: data?.buy_date ? moment(data?.buy_date) : null,
                order_number: data?.order_number || '',
                price: data?.price || '',
                status: statusLabel ? statusLabel.label : '',
                warranty_company: data?.latestWarranty?.warranty_company || '',
                warranty_times: data?.warrantyCount || '',
                device_note: data?.device_note || '',
                note: data?.note || ''
            });
        }
    }, [data, hookForm, status]);

    if (isLoading) {
        return <div>...Loading</div>;
    }

    return (
        <div className='container-fluid'>
            <div className='detail-title'>
                <h6>Chi tiết thiết bị</h6>
            </div>
            <div className='card'>
                <Tabs className='column'>
                    <TabList className='d-flex tab-row custom-tab-list'>
                        <Tab className='tab'>
                            <i className="fa-solid fa-circle-info"></i>
                            Thông tin chi tiết
                        </Tab>
                        <Tab className='tab'>
                            <i className="fa-solid fa-clock-rotate-left"></i>
                            Lịch sử
                        </Tab>
                    </TabList>

                    <div>
                        <TabPanel>
                           <DeviceInformation employees={employees} actions={actions} hookForm={hookForm} data={data} deviceType={deviceType} status={status} offices={offices} />
                        </TabPanel>
                        <TabPanel>
                            <DeviceHistory actions={actions} deviceId={deviceId} />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    );
}
function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(DeviceDetail);
