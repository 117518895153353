import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ShowForPermission from '../../../common/ShowForPermissionComponent';

export default function MenuExpandClientId ({ onClickUpdate, handleDeleteClientId, handleOpenModelAssignMember }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Tooltip title='ClientId menu'>
                <IconButton
                    onClick={handleClick}
                    size='small'
                    aria-controls={open ? 'ClientId-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    >
                    <LinearScaleIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id='ClientId-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ShowForPermission permissionKey='updateAndDeleteClientId'>
                    <MenuItem onClick={onClickUpdate}>
                        <Box sx={{ display: 'flex' }}>
                            <ListItemIcon>
                                <EditIcon fontSize='small'/>
                            </ListItemIcon>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="button.name.common.update" />
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteClientId}>
                        <Box sx={{ display: 'flex' }}>
                            <ListItemIcon>
                                <DeleteIcon fontSize='small'/>
                            </ListItemIcon>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="button.name.common.delete" />
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={handleOpenModelAssignMember} data-toggle="modal" data-target="#modal-assign-member">
                        <Box sx={{ display: 'flex' }}>
                            <ListItemIcon>
                                <ContentPasteGoIcon fontSize='small'/>
                            </ListItemIcon>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="button.name.common.assign.member" />
                            </Typography>
                        </Box>
                    </MenuItem>
                </ShowForPermission>
            </Menu>
        </>
    );
}
