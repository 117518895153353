import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import hrService from '../../Services/hr.service';

export default function UserLoginByDayChart () {
    const [userLoginByDay, setUserLoginByDay] = useState();

    const fetchTrackingUser = useCallback(() => {
        hrService.getUserLoginByDay().then(res => {
            setUserLoginByDay(() => {
                const result = hrService.getDaysInMonth();
                res.items?.forEach((element, index, arr) => {
                    const previousDate = arr[index - 1];
                    const date = moment(element.timeTracking).format(TranslateHelper.getMessage('date.format'));
                    if (index === 0 || (previousDate && moment(previousDate.timeTracking).format(TranslateHelper.getMessage('date.format')) !== moment(element.timeTracking).format(TranslateHelper.getMessage('date.format')))) {
                        result[date] = 1;
                    } else {
                        result[date] = result[date] + 1;
                    }
                });
                return result;
            });
        });
    }, []);

    useEffect(fetchTrackingUser, [fetchTrackingUser]);

    return (
        <>
            <Bar
                options = {{
                    indexAxis: 'x',
                    elements: {
                        bar: {
                            borderWidth: 2
                        }
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        title: {
                            display: true,
                            text: 'User login by Day'
                        }
                    }
                }}
                data={{
                    labels: Object.keys(userLoginByDay || {}).map((_, index) => index + 1),
                    datasets: [{
                        label: 'User logged in',
                        data: Object.values(userLoginByDay || {}),
                        backgroundColor: [
                            'rgb(255, 99, 132)'
                        ],
                        hoverOffset: 4
                    }]
                }}
            />
        </>
    );
}
