import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import $ from 'jquery';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageDatatables } from '../../../../../../lang';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../../actions/breadcrumb.action';
import StatisticService from '../../services/statistic.service';
import Swal from 'sweetalert2';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DataUsageIcon from '@mui/icons-material/DataUsage';

class StatisticSandbox extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            loadingChartStatus: false,
            dataSetChart1: {},
            dataSetChart2: {},
            dataSet: null
        };
        this.initDatatable = this.initDatatable.bind(this);
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.statistic');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.member.name.sandbox.statistic',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.admin.name.dashboard',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        if (this.props.actived) {
            this.getDataStatistic();
        }
    }

    /**
     * Clear data component
     */
    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#sandbox-list-statistic').DataTable({
                paging: false,
                lengthChange: false,
                searching: false,
                ordering: true,
                info: false,
                autoWidth: true,
                responsive: false,
                columnDefs: [
                    { className: 'dt-center', targets: [4, 5] },
                    { orderable: false, targets: [1, 2, 3, 4, 6] }
                ],
                language: LanguageDatatables[lang]
            });
            this.setState({ table });
        }.bind(this));
    }

    getDataStatistic () {
        StatisticService.getStatisticSandbox().then((res) => {
            if (res.status !== 200) {
                let timerInterval;
                Swal.fire({
                    title: TranslateHelper.getMessage('respond.message.operationsandbox.refresh'),
                    html: TranslateHelper.getMessage('respond.message.operationsandbox.refreshcountdown'),
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                        window.location.reload();
                    }
                });
                return;
            }
            if (!res.data.data) {
                this.setState({ dataSet: { sandboxes: [] }, loadingStatus: true });
                return;
            }
            const result = res.data.data;
            this.setState({ dataSet: result, loadingStatus: true });
            this.initDatatable();
            let dataSetChart1 = {};
            const labelsChart1 = [];
            const dataMinuteDownChart1 = [];
            const dataMinuteUpChart1 = [];
            result.sandboxes.map((sandbox, index) => {
                labelsChart1.push(sandbox.instance);
                dataMinuteDownChart1.push(sandbox.usage.minutesDown);
                dataMinuteUpChart1.push(sandbox.usage.minutesUp);
                return 0;
            });
            dataSetChart1 = {
                labels: labelsChart1,
                datasets: [
                    {
                        label: '# Minutes Down',
                        data: dataMinuteDownChart1,
                        backgroundColor: 'rgb(255, 99, 132)'
                    },
                    {
                        label: '# Minutes Up',
                        data: dataMinuteUpChart1,
                        backgroundColor: 'rgb(54, 162, 235)'
                    }
                ]
            };
            this.setState({ dataSetChart1 });
            let dataSetChart2 = {};
            dataSetChart2 = {
                labels: ['Active Sandboxes', 'Created Sandboxes', 'Deleted Sandboxes'],
                datasets: [
                    {
                        label: '# of Sandboxes',
                        data: [result.realmUsage.activeSandboxes, result.realmUsage.createdSandboxes, result.realmUsage.deletedSandboxes],
                        backgroundColor: 'rgb(54, 162, 235)'
                    }
                ]
            };
            this.setState({ dataSetChart2 });
            setTimeout(function () {
                this.setState({ loadingChartStatus: true });
            }.bind(this), 1000);
        });
    }

    render () {
        let rowTables = null;
        if (this.state.dataSet) {
            const sandboxes = this.state.dataSet.sandboxes;
            sandboxes.sort((a, b) => (a.usageSandbox < b.usageSandbox) ? 1 : -1);
            rowTables = sandboxes.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.instance}</td>
                        <td>{item.realm}</td>
                        <td>
                            <span data-tip data-for="tooltip-url-sandbox">{item.hostName.split('.')[0]}</span>
                            <ReactTooltip id="tooltip-url-sandbox">
                                <span>{item.hostName}</span>
                            </ReactTooltip>
                        </td>
                        <td>{item.createdBy}</td>
                        <td>{item.state !== 'stopped' ? (<i className="fas fa-power-off status-online-icon"></i>) : (<i className="fas fa-power-off status-offline-icon-danger"></i>)}</td>
                        <td>{item.usageSandbox}%</td>
                        <td>{item.storage && item.storage.sharedata ? item.storage.sharedata.spaceUsed + '/' + item.storage.sharedata.spaceTotal : '--'}</td>
                    </tr>
                );
            });
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {this.state.loadingStatus
                                    ? (<span>Statistic time: <FormattedDate
                                        value={this.state.dataSet.timeSaveData}
                                        year="numeric"
                                        month="numeric"
                                        day="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                    /></span>)
                                    : null}
                                <div className='table-scrollX'>
                                    {this.state.loadingStatus
                                        ? (<div>
                                            <span></span>
                                            {this.state.dataSet.sandboxes.length
                                                ? (<table id="sandbox-list-statistic" className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th><FormattedMessage id="dashboard.table.column.instance" /></th>
                                                            <th><FormattedMessage id="dashboard.table.column.realm" /></th>
                                                            <th><FormattedMessage id="dashboard.table.column.url" /></th>
                                                            <th><FormattedMessage id="dashboard.table.column.createBy" /></th>
                                                            <th><FormattedMessage id="sandbox.table.column.status"/></th>
                                                            <th><FormattedMessage id="dashboard.table.column.usage" />(%)</th>
                                                            <th><FormattedMessage id="dashboard.table.column.storage" /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{rowTables}</tbody>
                                                </table>)
                                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                        </div>)
                                        : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                </div>
                                <div style={{ height: '30px' }}></div>
                                {this.state.loadingChartStatus
                                    ? (<div className="row">
                                        <div className="col-sm-6">
                                            <Bar width="auto" height="300"
                                                data={this.state.dataSetChart1}
                                                options={{
                                                    indexAxis: 'y',
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }
                                                        ]
                                                    }
                                                }}
                                            />
                                            <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                                <EqualizerIcon fontSize='large'/>
                                                <p><FormattedMessage id="dashboard.chart.sandboxStatistics"/></p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <Bar width="auto" height="300"
                                                data={this.state.dataSetChart2}
                                                options={{
                                                    indexAxis: 'x',
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }
                                                        ]
                                                    }
                                                }}
                                            />
                                            <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                                <DataUsageIcon fontSize='large'/>
                                                <p><FormattedMessage id="dashboard.chart.realmStatistics"/></p>
                                            </div>
                                        </div>
                                    </div>)
                                    : (<></>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { actived } = state.memberClientId;
    const { user } = state.auth;
    return { actived, user };
}

export default connect(mapStateToProps)(StatisticSandbox);
