import { useCallback, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import ActivityLogsChart from './ActivityLogsChart';
import UserLoginByDayChart from './UserLoginByDayChart';

function Statisctic ({ kimeiMembers }) {
    const [employeeStatus, setEmployeeStatus] = useState({
        enable: [],
        disable: []
    });
    const [employeeDepartment, setEmployeeDepartment] = useState([]);

    const fetchEmployee = useCallback(() => {
        const enableEmployee = kimeiMembers.filter(empl => empl.status === 'enable');
        const disableEmployee = kimeiMembers.filter(empl => empl.status === 'disable');
        setEmployeeStatus({ enable: enableEmployee, disable: disableEmployee });

        const emplArr = [];
        const departmentArr = [];
        kimeiMembers.forEach((empl, index) => {
            if (index === 0 || !departmentArr.includes(empl.departmentId?.name)) {
                departmentArr.push(empl.departmentId?.name);
                const obj = {
                    name: empl.departmentId?.name,
                    total: 1,
                    color: '#' + Math.floor(Math.random() * 16777215).toString(16)
                };
                emplArr.push(obj);
            } else {
                emplArr.forEach((employee) => {
                    if (employee.name === empl.departmentId?.name) {
                        employee.total += 1;
                    }
                });
            }
        });
        setEmployeeDepartment(emplArr);
    }, [kimeiMembers]);

    useEffect(fetchEmployee, [fetchEmployee]);
    return (
        <>
            <div className="container-fluid">
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-6'>
                                <Pie
                                    options = {{
                                        indexAxis: 'y',
                                        elements: {
                                            bar: {
                                                borderWidth: 2
                                            }
                                        },
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'right'
                                            },
                                            title: {
                                                display: true,
                                                text: 'Employees Status, ' + new Date().getFullYear()
                                            }
                                        }
                                    }}
                                    data={{
                                        labels: [
                                            'Enable',
                                            'Disable'
                                        ],
                                        datasets: [{
                                            data: [employeeStatus.enable.length, employeeStatus.disable.length],
                                            backgroundColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(54, 162, 235)'
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </div>
                            <div className='col-6'>
                                <Pie
                                    options = {{
                                        indexAxis: 'y',
                                        elements: {
                                            bar: {
                                                borderWidth: 2
                                            }
                                        },
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'right'
                                            },
                                            title: {
                                                display: true,
                                                text: 'Employess By Department, ' + new Date().getFullYear()
                                            }
                                        }
                                    }}
                                    data={{
                                        labels: employeeDepartment.map(item => item.name),
                                        datasets: [{
                                            data: employeeDepartment.map(item => item.total),
                                            backgroundColor: employeeDepartment.map(item => item.color),
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </div>
                            <div className='col-12'>
                                <UserLoginByDayChart/>
                            </div>
                            <div className='col-12'>
                                <ActivityLogsChart/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { kimeiMembers } = state.hrm;
    return { role, kimeiMembers };
}

export default connect(mapStateToProps)(Statisctic);
