import { MenuItem, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import TranslateHelper from '../../../helpers/TranslateHelper';
import deviceService from '../services/device.service';
import { ToastTopEnd } from '../../../helpers/ToastTimer';
import CSelect from '../../../shared/CSelect';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { vi } from 'date-fns/locale';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ConfigProvider, DatePicker, Modal } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale('vi');
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
export default function ModalDevice ({ refetch, hookForm, open, handleCancel, offices, deviceType }) {
    const { formatMessage } = useIntl();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const handleCreatedDevice = async (value) => {
        const deviceCreated = {
            ...value,
            status: '1',
            price: Number(value.price),
            warranty_date_from: fromDate,
            warranty_date_to: toDate
        };
        try {
            await deviceService.createDevice(deviceCreated)
                .then((res) => {
                    if (res.success) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.addnew.successed')
                        });
                    }
                });
            handleCancel();
            refetch();
        } catch (error) {
            console.log(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };

    const onChangeRangeDate = (dates) => {
        dates[0] ? setFromDate(dates[0]) : setFromDate(undefined);
        dates[1] ? setToDate(dates[1]) : setToDate(undefined);
    };

    return (
        <Modal title="Thêm thiết bị" open={open} footer={null} onClose={handleCancel} width={950}>
            <form onSubmit={hookForm.handleSubmit(handleCreatedDevice)}>
                    <div>
                        <div className='form-row'>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label={<FormattedMessage id="device.table.column.name.name" />}
                                    {...hookForm.register('name', { required: TranslateHelper.getMessage('validation.error.required') })}
                                />
                                {hookForm.formState.errors.name &&
                                    <p className="error text-danger">
                                        {
                                            hookForm.formState.errors.name.message
                                        }
                                    </p>}
                            </div>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label={<FormattedMessage id="device.table.column.name.code" />}
                                    {...hookForm.register('code', { required: TranslateHelper.getMessage('validation.error.required') })}
                                />
                                {hookForm.formState.errors.code &&
                                    <p className="error text-danger">
                                        {
                                            hookForm.formState.errors.code.message
                                        }
                                    </p>}
                            </div>
                            <div className='form-device'>
                                <Controller
                                    control={hookForm.control}
                                    render={({ field: { onChange, value } }) => (
                                        <CSelect customSelect="custom-select-device" minWidth={290} handleSelect={(e) => onChange(e.target.value)} value={value || ''} label={formatMessage({ id: 'device.table.column.name.type' })}>
                                            {deviceType.map((item, i) => (
                                                <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                            ))}
                                        </CSelect>
                                    )}
                                    {...hookForm.register('type', { required: TranslateHelper.getMessage('validation.error.required') })}
                                    name="type"
                                />
                                {hookForm.formState.errors.type &&
                                    <p className="error text-danger">
                                        {
                                            hookForm.formState.errors.type.message
                                        }
                                    </p>}
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-device'>
                                <Controller
                                    control={hookForm.control}
                                    render={({ field: { onChange, value } }) => (
                                        <CSelect customSelect="custom-select-device" minWidth={290} handleSelect={(e) => onChange(e.target.value)} value={value || ''} label={formatMessage({ id: 'device.table.column.name.office' })}>
                                            {offices.map((item, i) => (
                                                <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                            ))}
                                        </CSelect>
                                    )}
                                    {...hookForm.register('office', { required: TranslateHelper.getMessage('validation.error.required') })}
                                    name="office"
                                />
                                {hookForm.formState.errors.office &&
                                    <p className="error text-danger">
                                        {
                                            hookForm.formState.errors.office.message
                                        }
                                    </p>}
                            </div>
                            <div className='form-device wrapper'>
                                <label className='custom-label'>{formatMessage({ id: 'device.table.column.name.buyDate' })}</label>
                                <Controller
                                    name="buy_date"
                                    control={hookForm.control}
                                    render={({ field }) => (
                                        <ReactDatePicker
                                            {...field}
                                            className='custom-datepicker'
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            locale={vi}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    )}
                                />
                                <span className='custom-calendar'><CalendarMonthIcon /></span>
                            </div>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label={<FormattedMessage id="device.table.column.name.orderNumber" />}
                                    {...hookForm.register('order_number', {})}
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label='Công ty bảo hành'
                                    {...hookForm.register('warranty_company', {})}
                                />
                            </div>
                            <div className='form-device wrapper'>
                                <label className='custom-label'>Ngày bảo hành</label>
                                <ConfigProvider locale={vi}>
                                    <RangePicker
                                        style={{ width: '290px', height: '48px' }}
                                        size="large"
                                        onCalendarChange={dates => onChangeRangeDate(dates)}
                                        defaultValue={[dayjs(dayjs(fromDate).format('DD/MM/YYYY'), dateFormat), dayjs(dayjs(toDate).format('DD/MM/YYYY'), dateFormat)]}
                                        format={dateFormat}
                                    />
                                </ConfigProvider>
                            </div>
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    type="number"
                                    label={<FormattedMessage id="device.table.column.name.price" />}
                                    {...hookForm.register('price', {
                                        min: {
                                            value: 0,
                                            message: 'Price must be at least 0'
                                        },
                                        max: {
                                            value: 1000000000
                                        }
                                    })}
                                />
                                {hookForm.formState.errors.price &&
                                    <p className="error text-danger">
                                        {
                                            hookForm.formState.errors.price.message
                                        }
                                    </p>}
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='form-device custom-textarea'>
                                <label className='custom-label'><FormattedMessage id="device.table.column.name.note" /></label>
                                <textarea
                                    {...hookForm.register('note', {})}
                                    rows="5"
                                    cols="38"
                                />
                            </div>
                            <div className='form-device custom-textarea'>
                                <label className='custom-label'><FormattedMessage id="device.table.column.name.deviceNote" /></label>
                                <textarea
                                    {...hookForm.register('device_note', {})}
                                    rows="5"
                                    cols="38"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex w-100 justify-content-end align-items-center buttons'>
                        <button
                            type="button"
                            onClick={() => {
                                handleCancel();
                            }}
                            className="cancel-btn justify-content-center mr-2 px-4 py-2"
                        >
                            Hủy
                        </button>
                        <button type="submit" className='main-btn justify-content-center px-4 py-2'>Thêm mới</button>
                    </div>
                </form>
        </Modal>
    );
}
