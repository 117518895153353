import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { connect } from 'react-redux';
import { ROLES } from '../../common/constants/user.constant';
import SwitchingRole from './common/SwitchingRole';
import clientIdService from './sandbox/services/client-id.service';
import { useState, useEffect, useCallback } from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { FormattedMessage } from 'react-intl';
import recruitmentService from './hrm/Services/recruitment.service';
import { Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { logout } from '../actions/auth.action';

const renderSwitchRoleItem = (role) => {
    return (
        <MenuItem>
            <ListItemIcon>
                <SwapHorizIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant='subtitle2'><SwitchingRole role={role}></SwitchingRole></Typography>
        </MenuItem>
    );
};

function MenuAccount ({ role, switchRole, user, dispatch }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isClientIds, setIsClientIds] = useState(false);

    const getListClientId = useCallback(() => {
        if (role === ROLES.MEMBER) {
            clientIdService.getList().then((res) => {
                setIsClientIds(true);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    const handleLogout = () => {
        Cookies.remove('roleChanged');
        dispatch(logout());
    };

    useEffect(getListClientId, [getListClientId]);
    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>
                        <img onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={user.imageUrl ? (String(user.imageUrl).includes('uploads') ? recruitmentService.buildFileURL(user.imageUrl) : user.imageUrl) : '/images/default-avatar.png'} alt="Avatar-user"/>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                <Avatar>
                    <img onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={user.imageUrl ? (String(user.imageUrl).includes('uploads') ? recruitmentService.buildFileURL(user.imageUrl) : user.imageUrl) : '/images/default-avatar.png'} alt="Avatar-user"/>
                </Avatar>
                    {user.name}
                </MenuItem>
                <Divider />
                {(role === ROLES.MANAGER || role === ROLES.PARTNEROWNER) && switchRole && (renderSwitchRoleItem(role))}
                {role === ROLES.MEMBER && isClientIds && switchRole && (renderSwitchRoleItem(role))}
                {role === ROLES.PARTNERMEMBER && switchRole && (renderSwitchRoleItem(role))}
                <MenuItem>
                    <ListItemIcon>
                        <OpenInFullIcon fontSize='small'/>
                    </ListItemIcon>
                    <span href="#expand"data-widget="fullscreen" role="button">
                        <Typography variant='subtitle2'>
                            <FormattedMessage id='button.name.common.expand' />
                        </Typography>
                    </span>
                </MenuItem>
                <a href={'/settings'} title='setting' className='text-decoration-none'>
                    <MenuItem>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='subtitle2'>
                            <FormattedMessage id='app.page.title.setting' />
                        </Typography>
                    </MenuItem>
                </a>
                <MenuItem onClick={() => handleLogout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography variant='subtitle2'>Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}

function mapStateToProps (state) {
    const { role, user } = state.auth;
    const { switchRole } = state.auth.user;
    return {
        role, switchRole, user
    };
}

export default connect(mapStateToProps)(MenuAccount);
