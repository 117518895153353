import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import MainContentPartnerOwner from '../../partnerowner/MainContentPartnerOwner';
import MainContentPartnerMember from '../../partnermember/MainContentPartnerMember';
import MainContentManager from '../../manager/MainContentManager';
import MainContentMember from '../../member/MainContentMember';
import MainContentGuest from '../../guest/MainContentGuest';
import { ROLES } from '../constants/user.constant';

class MainContentSection extends Component {
    render () {
        const breadcrumbEl = this.props.breadcrumb.breadcrumbItems.map((item, index) => {
            if (item.haslink) {
                if (index === 0) {
                    return (
                        <li key={index}><a href={item.path}><i className='fas fa-home'></i></a></li>
                    );
                } else {
                    return (
                        <li key={index}><a href={item.path}>{item.label ? <FormattedMessage id={item.label}/> : item.varLabel}</a></li>
                    );
                }
            }
            return (<li key={index}><a href={null}>{item.label ? <FormattedMessage id={item.label} /> : item.varLabel}</a></li>);
        });
        const { role } = this.props;
        let contentRendering = '';
        switch (role) {
            case ROLES.MANAGER:
                contentRendering = <MainContentManager />;
                break;
            case ROLES.MEMBER: case ROLES.HR:
                contentRendering = <MainContentMember />;
                break;
            case ROLES.PARTNEROWNER: case ROLES.PARTNERMANAGER:
                contentRendering = <MainContentPartnerOwner />;
                break;
            case ROLES.PARTNERMEMBER:
                contentRendering = <MainContentPartnerMember />;
                break;
            case ROLES.GUEST:
                contentRendering = <MainContentGuest />;
                break;
            default:
                break;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 col-12">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-12">
                                <div className="bread-crums float-sm-left">
                                    <ul >
                                        {breadcrumbEl}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content" style={{ margin: '0' }}>
                    {contentRendering}
                </section>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { breadcrumb } = state.breadcrumb;
    const { role } = state.auth;
    return {
        breadcrumb,
        role
    };
}

export default connect(mapStateToProps)(MainContentSection);
