import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setBreadcrumb } from '../../../common/actions/breadcrumb.action';
import ReactPagination from '../../../common/components/ReactPagination';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import cronjobsService from '../Services/cronjobs.service';
import RowTableCronJob from './RowTableCronJob';

function CronJobs ({ dispatch }) {
    const [cronJobs, setCronJobs] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const fetchCronJobs = useCallback((page) => {
        setIsFetching(true);
        cronjobsService.getAllJobs(page).then(res => {
            setCronJobs(res);
            setIsFetching(false);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const renderCronJobsTable = (data) => {
        return data.items.map(item => {
            return (
                <RowTableCronJob key={item._id} item={item}/>
            );
        });
    };

    useEffect(fetchCronJobs, [fetchCronJobs]);

    useEffect(() => {
        document.title = TranslateHelper.getMessage('menu.admin.name.cronjobs');
        dispatch(setBreadcrumb({
            title: 'menu.admin.name.cronjobs',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.admin.name.cronjobs',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch]);

    return (
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <table className="table table-bordered table-hover mb-3">
                        <thead>
                            <th><FormattedMessage id='cronjobs.detail.table.column.name.timestamp'/></th>
                            <th><FormattedMessage id='cronjobs.detail.table.column.name.name'/></th>
                            <th><FormattedMessage id='cronjobs.detail.table.column.name.jobURL'/></th>
                            <th><FormattedMessage id='cronjobs.detail.table.column.name.status'/></th>
                            <th><FormattedMessage id='cronjobs.detail.table.column.name.action'/></th>
                        </thead>
                        <tbody>
                            {isFetching
                                ? <tr className='text-center'>
                                    <td colSpan={5}>
                                        <i className="fa-solid fa-sync fa-spin" style={{ fontSize: '2rem' }}></i>
                                    </td>
                                </tr>
                                : cronJobs.items?.length > 0
                                    ? renderCronJobsTable(cronJobs)
                                    : <tr>
                                        <td colSpan={5}>
                                                <p>No jobs are running</p>
                                        </td>
                                    </tr>}
                        </tbody>
                    </table>
                    { cronJobs?.totalPages > 1 &&
                        <ReactPagination
                            totalPages={cronJobs?.totalPages}
                            handlePageChange={(e, page) => fetchCronJobs(page)}
                            pageNumber={ cronJobs?.page }
                        />
                    }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(CronJobs);
