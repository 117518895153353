import SearchIcon from '@mui/icons-material/Search';
import { Button, debounce } from '@mui/material';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import CSelect from '../../../shared/CSelect';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
export function FilterDevice ({ refetch, setPage, filters, setFilters, offices, status, deviceType }) {
    const history = useHistory();
    const location = useLocation();
    const [search, setSearch] = useState('');
    useEffect(() => {
        history.push(location.pathname);
    }, []);
    useEffect(() => {
        if (filters) {
            const dataFilter = Object.keys(filters).reduce((result, key) => {
                if (filters[key]) {
                    if (typeof filters[key] === 'object') {
                        result[key] = filters[key]?.value || filters[key].map(obj => obj.value);
                    } else {
                        result[key] = filters[key];
                    }
                }
                return result;
            }, {});

            const query = queryString.parse(location.search);
            const modifiedQuery = {
                ...query,
                office: dataFilter.office,
                type: dataFilter.type,
                status: dataFilter.status,
                q: dataFilter.q
            };

            history.replace({
                pathname: location.pathname,
                search: queryString.stringify(modifiedQuery)
            });
            setPage(1);
        }
    }, [filters]);
    const handleSearch = useCallback((e) =>
        setFilters(prevState => ({
            ...prevState,
            q: e.target.value.trim()
        })
        ), []);

    const handleSelect = useCallback(debounce((name, e) => {
        setFilters(prevState => ({
            ...prevState,
            [name]: e.target.value
        }));
    }, 200), []);

    const clearFilter = () => {
        setFilters({ office: '', type: '', status: '', q: '' });
        setPage(1);
        setSearch('');
    };
    return (
        <>
            <div className='mt-4'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='filter'>
                            <div className='search'>
                                <input type='input' placeholder='Tìm kiếm..' onChange={(e) => { setSearch(e.target.value); handleSearch(e); } } value={search} />
                                <SearchIcon className='search-icon' />
                            </div>
                            <div className='filter-row'>
                                <div>
                                    <CSelect customSelect="select-filter" value={filters.office || ''} minWidth={160} handleSelect={(e) => handleSelect('office', e)} label={<FormattedMessage id="device.table.column.name.office" />}>
                                        {offices.map((item, i) => (
                                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                        ))}
                                    </CSelect>
                                </div>
                                <div>
                                    <CSelect customSelect="select-filter" value={filters.type || ''} minWidth={160} handleSelect={(e) => handleSelect('type', e)} label={<FormattedMessage id="device.table.column.name.type" />}>
                                        {deviceType.map((item, i) => (
                                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                        ))}
                                    </CSelect>
                                </div>
                                <div>
                                    <CSelect customSelect="select-filter" value={filters.status || ''} minWidth={160} handleSelect={(e) => handleSelect('status', e)} label={<FormattedMessage id="device.table.column.name.status" />}>
                                        {status.map((item, i) => (
                                            <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                        ))}
                                    </CSelect>
                                </div>
                                <div>
                                    <Button className="clear-filter" onClick={clearFilter}>
                                        Xóa bộ lọc
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
