import RecruitmentMainContent from '../../../common/components/hrm/components/Recruitment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function Recruitment ({ dispatch, role }) {
    const stateLocation = useLocation();
    return (
        <>
            <RecruitmentMainContent dispatch={dispatch} recruitmentIdx={ stateLocation.state?.recruitmentIdx ? stateLocation.state?.recruitmentIdx : 0 }/>
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(Recruitment);
