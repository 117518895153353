import { FormattedMessage } from 'react-intl';
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import EmployeeLeave from './service-detail/EmployeeLeave';
import LeaveRequest from './service-detail/LeaveRequest';
import MyLeave from './service-detail/MyLeave';
import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { useLayoutEffect } from 'react';

export default function Services ({ dispatch, servicesIdx }) {
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.hrm',
                    haslink: true,
                    path: '/hrm'
                },
                {
                    label: 'app.breadcrumb.common.hrm.service',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            clearBreadcrumb();
        };
    }, [dispatch]);
    return (
        <>
            <Tabs className="row" defaultIndex={servicesIdx}>
                <TabList className='col-12 col-xl-2 d-flex flex-column tab-list-sp-general'>
                    <Tab><FormattedMessage id='hrm.services.tab.name.leaveRequest'/></Tab>
                    <Tab selected><FormattedMessage id='hrm.services.tab.name.myLeave'/></Tab>
                    <Tab selected><FormattedMessage id='hrm.services.tab.name.employeeLeave'/></Tab>
                </TabList>
                <div className="col-xl-10 col-12">
                    <TabPanel><LeaveRequest/></TabPanel>
                    <TabPanel><MyLeave/></TabPanel>
                    <TabPanel><EmployeeLeave/></TabPanel>
                </div>
            </Tabs>
        </>
    );
}
