import { LANGUAGES } from '../../../lang';

const TranslateHelper = {
    getMessage (nameId) {
        const lang = localStorage.getItem('lang');
        if (Object.prototype.hasOwnProperty.call(LANGUAGES.en_US.messages, nameId)) {
            let message = '';
            switch (lang) {
                case 'en':
                    message = LANGUAGES.en_US.messages[nameId];
                    break;
                case 'ja':
                    message = LANGUAGES.ja_JP.messages[nameId];
                    break;
                case 'vi':
                    message = LANGUAGES.vi_VN.messages[nameId];
                    break;
                default:
                    message = LANGUAGES.en_US.messages[nameId];
                    break;
            }
            return message;
        } else {
            return 'undefined';
        }
    }
};

export default TranslateHelper;
