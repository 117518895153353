import { useState } from 'react';
import issueService from '../services/issue.service';
import { ToastTopEnd } from '../../../helpers/ToastTimer';
import TranslateHelper from '../../../helpers/TranslateHelper';
import socket from '../../common/Socket';
import { useLocation } from 'react-router-dom';
import Button from '@atlaskit/button';
import Editor from '../../EdittorToolbar';

export default function MentionEditor ({ isPopup, isClass, isShowing, setIsEdit, mentions, issueId, fetchIssueDetail, userId, userInfo, setRefetch }) {
    const location = useLocation();
    const [showing, setShowing] = useState(isShowing);
    const [comments, setComment] = useState('');
    const [defaultValue, setDefaultValue] = useState();
    const [mentionToUser, setMentionToUser] = useState([]);

    const addComment = (id) => {
        // match content in <p></p> to test null content editor
        const regx = /(<([^>k]+)>)/ig;
        if (comments.replace(regx, '').trim() !== '') {
            const payload = {
                comment: comments,
                mentionToUser: [...new Set(mentionToUser)]
            };
            const createdBy = {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                picture: userInfo.imageUrl,
                _id: userId
            };
            issueService.addComment(id, payload).then((res) => {
                for (let i = 0; i < payload.mentionToUser.length; i++) {
                    const receiverId = mentionToUser[i];
                    socket.emit('sendNotification', {
                        markAll: 1,
                        receiverId
                    });
                }
                socket.emit('sendComment', {
                    comment: payload.comment,
                    createdBy,
                    issueKey: location.pathname.split('/')[4]
                });
                setComment('');
                setDefaultValue();
                setDefaultValue('');
                !isClass && setRefetch(true);
                fetchIssueDetail(true);
                setMentionToUser([]);
            }).catch((error) => {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: error.response?.data.message || 'Images are overload, please resize them'
                });
            });
        } else {
            ToastTopEnd.fire({
                icon: 'error',
                title: TranslateHelper.getMessage('validation.error.required.comment')
            });
        }
    };

    const cancelComment = () => {
        setShowing(!showing);
    };

    return (
      <>
      {!isPopup && (<Button appearance='primary' style={{ display: (!showing ? 'block' : 'none') }} onClick={ () => { cancelComment(); } }>
                        {TranslateHelper.getMessage('project.label.name.writeComment')}
                    </Button>)}
        <div className='form-comment'
            style={{ display: (showing ? 'block' : 'none'), marginTop: '10px' }}
        >
          <div className={`${isPopup && 'mb-0 ml-2 mr-2'}`}>
            {
                mentions.length > 0 &&
                <Editor
                    toolbarId={'commentEditor'}
                    placeholder={'Write comment here !'}
                    onChange={setComment}
                    defaultValue={defaultValue}
                    mentionList={mentions?.filter(user => user.id !== userId)}
                    setMentionToUser={setMentionToUser}
                />
            }
          </div>
          {
            !isPopup
                ? <div className="btn-group pointer d-block mt-1">
                    <Button onClick={() => { addComment(issueId); }} className='btn-md mr-2' appearance='primary'><span>{TranslateHelper.getMessage('button.name.common.save')}</span></Button>
                    <Button className='btn-md' style={{ margin: '0 2px' }} onClick={() => cancelComment()}><span>{TranslateHelper.getMessage('button.name.common.cancel')}</span></Button>
                </div>
                : <div className="btn-group pointer d-block ml-2 mt-1">
                    <Button onClick={() => { addComment(issueId); }} className='btn-md mr-2' appearance='primary'><span>{TranslateHelper.getMessage('button.name.common.save')}</span></Button>
                    <Button className='btn-md' style={{ margin: '0 2px' }} onClick={() => { setIsEdit(prevState => ({ ...prevState, typing: false })); } }><span>{TranslateHelper.getMessage('button.name.common.cancel')}</span></Button>
                </div>
          }
        </div>
      </>
    );
}
