import React, { useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BusinessUnits from './organization-detail/BusinessUnits';
import Departments from './organization-detail/Departments';
import Structure from './organization-detail/Structure';
import Hierarchy from './organization-detail/Hierarchy';
import Statistics from './organization-detail/Statistics';
import { setBreadcrumb } from '../../../actions/breadcrumb.action';
import OrganizationChart from './organization-detail/OrganizationChart';
import Rank from './organization-detail/Rank';
import Title from './organization-detail/Title';

export default function Organization ({ dispatch, departmentsIdx }) {
    useLayoutEffect(() => {
        dispatch(setBreadcrumb({
            title: 'menu.member.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.hrm',
                    haslink: true,
                    path: '/hrm'
                },
                {
                    label: 'app.breadcrumb.common.hrm.organization',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch]);
    return (
        <>
            <Tabs className="row" defaultIndex={departmentsIdx}>
                <TabList className='col-12 col-xl-2 d-flex flex-column tab-list-sp-organization'>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.businessunits'/></Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.departments'/></Tab>
                    <Tab>Sơ đồ tổ chức</Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.structure'/></Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.hierarchy'/></Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.statistics' /></Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.ranks' /></Tab>
                    <Tab><FormattedMessage id='hrm.detail.organization.tabs.name.title' /></Tab>
                </TabList>
                <div className="col-xl-10 col-12 ">
                    <TabPanel><BusinessUnits /></TabPanel>
                    <TabPanel><Departments /></TabPanel>
                    <TabPanel><OrganizationChart /></TabPanel>
                    <TabPanel><Structure /></TabPanel>
                    <TabPanel><Hierarchy /></TabPanel>
                    <TabPanel><Statistics /></TabPanel>
                    <TabPanel><Rank /></TabPanel>
                    <TabPanel><Title /></TabPanel>
                </div>
            </Tabs>
        </>
    );
}
