import React, { Component } from 'react';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import DatePicker from 'react-datepicker';
import { CustomInputDate } from '../../../../common/CustomInputDatepicker';
import AxiosService from '../../../../../services/axios.service';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import { LanguageDatatables } from '../../../../../../../lang';
import issueService from '../../../services/issue.service';
import Select from '@atlaskit/select';
import serviceHelpers from '../../../../../helpers/serviceHelpers';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';
import { isAllowEditProject } from '../../../../../helpers/CheckRoleHelper';

const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager';
const selectError = {
    container: (provided, state) => ({
        ...provided,
        border: '2px solid red',
        borderRadius: '4px'
    })
};

const selectNormal = {
    container: (provided, state) => ({
        ...provided
    })
};
class Milestone extends Component {
    constructor (props) {
        super(props);
        serviceHelpers();
        this.state = {
            loadingStatus: false,
            isError: {
                defaultValue: '',
                startDate: '',
                endDate: '',
                name: '',
                issueOption: ''
            },
            suggestions: [],
            issuesOfProject: [],
            form: {
                name: '',
                startDate: '',
                endDate: ''
            },
            enable: false,
            dataTable: [
            ],
            issueOption: [],
            issuesOfMilestone: []
        };
        this.role = props.role;
        this.userRoleInProject = props.userRoleInProject;
    }

    componentDidMount () {
        this.getAll();
        this.getProjectIssues();
        $('#modal-milestone').on('click', '[data-dismiss="modal"]', () => {
            this.setState({
                form: {
                    name: '',
                    startDate: '',
                    endDate: ''
                },
                isError: {
                    defaultValue: '',
                    startDate: '',
                    endDate: '',
                    name: ''
                }
            });
        });
        $('#modal-milestone').on('keyup', function (e) {
            if (e.keyCode === 13) {
                $('#submitAdd').click();
            }
        });
    }

    initDatatable (id) {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#milestone-list').DataTable({
                paging: true,
                lengthChange: false,
                ordering: true,
                searching: false,
                info: true,
                autoWidth: true,
                responsive: true,
                language: LanguageDatatables[lang],
                columnDefs: [
                    { className: 'dt-head-center', targets: [3] }
                ],
                order: [1, 'decs'],
                fnDrawCallback: function (oSettings) {
                    // show hide paginate with condition
                    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                    } else {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                    }
                }
            });
            this.setState({ table });
        }.bind(this));
    }

    getAll () {
        return AxiosService.get(API_URL + '/projects/' + this.props?.projectId + '/milestones').then((res) => {
            if (res.data.success === true) {
                this.setState({ dataTable: res.data?.data, loadingStatus: true });
                this.forceUpdate();
            }
        }).catch((err) => {
            if (err?.response?.data) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage('respond.message.failed')
                });
            }
        });
    }

    getProjectIssues () {
        issueService.getProjectIssue(this.props.projectId).then((data) => {
            const issues = data.items.map(issue => ({ value: issue._id, label: issue.issueCode }));
            this.setState({ issuesOfProject: issues });
            this.forceUpdate();
        });
    }

    getIssuesMilestone (id) {
        this.setState({ issuesOfMilestone: [], issueOption: [], isError: Object.assign({}, this.state.isError, { issueOption: '' }) });
        this.setState({ suggestions: [...this.state.issuesOfProject] });
        return AxiosService.get(API_URL + '/projects/milestones/' + id + '/issues').then((res) => {
            if (res.data.success === true) {
                this.setState({ issuesOfMilestone: res.data.data[0].issues });
                // const suggestion = [...this.state.issuesOfProject];
                // res.data.data[0].issues.map((issue, index) =>
                //     issueService.getIssueDetail(issue).then((res) => {
                //         if (res) {
                //             if (suggestion.some((e) => e.value === issue)) {
                //                 const removeIndex = suggestion.map(function (item) { return item.value; }).indexOf(issue);
                //                 suggestion.splice(removeIndex, 1);
                //                 this.setState({ suggestions: suggestion });
                //             }
                //         }
                //     }));
                this.forceUpdate();
            }
        }).catch((err) => {
            if (err?.response?.data) {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: TranslateHelper.getMessage('respond.message.failed')
                });
            }
        });
    }

    handleInputChange (name, event) {
        this.setState({
            enable: true
        });
        const { form, isError } = this.state;
        switch (name) {
            case 'name': case 'startDate': case 'endDate':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleChangeDatePicker (name, date) {
        const { form, isError } = this.state;
        form[name] = date;
        isError[name] = '';
        this.setState({ form });
    }

    Alerterror (key, msg) {
        const { isError } = this.state;
        return (
            isError[key] = TranslateHelper.getMessage(msg)
        );
    }

    handleValidationForm () {
        let validation = true;
        const current = moment(new Date()).format(TranslateHelper.getMessage('date.format'));
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'name': case 'startDate': case 'endDate':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                    }
                    if ((form.endDate < form.startDate)) {
                        this.Alerterror('startDate', 'validation.error.date.orcur');
                        validation = false;
                    }
                    if (moment(moment(form.startDate).format(TranslateHelper.getMessage('date.format'))).isBefore(current)) {
                        this.Alerterror('startDate', 'validation.error.invalidstart');
                        validation = false;
                    }
                    if (moment(moment(form.endDate).format(TranslateHelper.getMessage('date.format'))).isBefore(current)) {
                        this.Alerterror('endDate', 'validation.error.invalidend');
                        validation = false;
                    }
                    if (!form.name.replace(/\s/g, '').length) {
                        this.Alerterror('name', 'validation.error.milestone.name');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleCreateMilestone () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                milestoneName: form.name,
                startDate: form.startDate,
                endDate: form.endDate
            };
            AxiosService.post(API_URL + '/projects/' + this.props.projectId + '/milestones', payload).then((res) => {
                this.getAll();
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
                this.setState({ form: { name: '' } });
                $('#modal-milestone').trigger('click');
            }).catch((err) => {
                if (err.response.data) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: err.response.data.message
                    });
                }
            });
        }
        this.forceUpdate();
    }

    handleDeteleClick (id, milestones) {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + milestones + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                AxiosService.delete(API_URL + '/projects/' + this.props.projectId + '/milestones/' + id).then((res) => {
                    this.getAll(true);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.delete.successfully')
                    });
                }).catch((error) => {
                    if (error.data?.success === false) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });
                    }
                });
            }
        });
    }

    handleUnAssign (milestoneId, Code) {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.unassign.title') + '"' + Code + '" ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const payload = {
                    issueCode: Code
                };
                AxiosService.delete(API_URL + '/projects/' + this.props.projectId + '/milestones/' + milestoneId + '/unassignIssue', payload).then((res) => {
                    this.getIssuesMilestone(milestoneId);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.unassign.successed')
                    });
                }).catch((error) => {
                    if (error.response.data) {
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: error.response.data.message
                        });
                    }
                });
            }
        });
    }

    handleAssign (id, milestones) {
        if (this.state.issueOption.label) {
            const payload = {
                issueCode: this.state.issueOption.label
            };
            return AxiosService.post(API_URL + '/projects/' + this.props.projectId + '/milestones/' + id + '/assignIssue', payload).then((res) => {
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.assign.successed')
                });
                this.getIssuesMilestone(id);
                this.setState({
                    isError: Object.assign({}, this.state.isError, {
                        issueOption: ''
                    }),
                    issueOption: []
                });
                $('#Id' + id).trigger('click');
            }).catch((err) => {
                if (err.response.data) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: err.response.data.message
                    });
                }
            });
        } else {
            this.Alerterror('issueOption', 'validation.error.required');
        }
        this.forceUpdate();
    }

    handleChangeIssueSelect (value) {
        if (value.label) {
            this.setState({
                issueOption: value,
                isError: Object.assign({}, this.state.isError, {
                    issueOption: ''
                })
            });
        }
        this.forceUpdate();
    }

    handleUpdateMilestone (id) {
        const { form } = this.state;
        const payload = {
            milestoneName: form.name,
            startDate: form.startDate,
            endDate: form.endDate
        };
        if (this.handleValidationForm()) {
            issueService.editMilestone(this.props.projectId, id, payload).then(res => {
                if (res.status === 200) {
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.addnew.successed')
                    });
                    this.setState({ form: { name: '' } });
                    $('#modal-milestone-' + id).trigger('click');
                    this.getAll();
                }
            });
        }
        this.forceUpdate();
    }

    renderMilestoneModal (data, isNew) {
        const isError = {
            defaultValue: '',
            startDate: '',
            endDate: '',
            name: '',
            issueOption: ''
        };
        const form = {
            name: isNew ? '' : data.milestoneName,
            startDate: isNew ? '' : new Date(data.startDate),
            endDate: isNew ? '' : new Date(data.endDate)
        };
        this.setState({ form, isError });
    }

    render () {
        const { isError, form } = this.state;
        const lang = localStorage.getItem('lang');
        const rowData = data => {
            return data.length > 0
                ? data.map((item, index) => {
                    return (
                     <tr key={item._id}>
                        <td>{item.milestoneName}</td>
                        <td>{moment(item.startDate).format(TranslateHelper.getMessage('date.format'))}</td>
                        <td>{moment(item.endDate).format(TranslateHelper.getMessage('date.format'))}</td>
                        <td>
                            <div className="row d-flex" style={{ gap: '10px', marginLeft: '5px' }}>
                                {isAllowEditProject(this.role, this.userRoleInProject) &&
                                        (<>
                                            <Button title='Edit' data-toggle="modal" data-target={'#modal-milestone-' + item._id} onClick={() => this.renderMilestoneModal(item)}>
                                                <i className='far fa-edit'></i>
                                            </Button>
                                            <Button data-toggle="tooltip" data-placement="top" title="Delete this milestone" onClick={() => { this.handleDeteleClick(item._id, item.milestoneName); }}>
                                                <i className='far fa-trash-alt'></i>
                                            </Button>
                                        </>)}
                                <Button data-toggle="modal" data-placement="top" title="Assign issues to milestone" data-target={'#Id' + item._id} onClick={() => { this.getIssuesMilestone(item._id); }}>
                                    <i className='far fa-clipboard'></i>
                                </Button>
                                <div className="modal hide fade" id={'modal-milestone-' + item._id}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Milestone</h4>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group row">
                                                    <label htmlFor="inputName" className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.name" /></label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            className={
                                                                isError.name.length > 0
                                                                    ? 'is-invalid form-control'
                                                                    : 'form-control'
                                                            }
                                                            id="inputName"
                                                            placeholder='Name of milestone'
                                                            value={form.name}
                                                            onChange={this.handleInputChange.bind(this, 'name')}
                                                        />
                                                        {isError.name.length > 0 && (
                                                        <span className="error invalid-feedback">
                                                        {isError.name}
                                                        </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.start" /></label>
                                                    <div className= 'col-sm-8'>
                                                        <DatePicker
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode='select'
                                                                selected={form.startDate}
                                                                onChange={this.handleChangeDatePicker.bind(this, 'startDate')}
                                                                locale={lang}
                                                                customInput={<CustomInputDate />}
                                                        />
                                                        {isError.startDate.length > 0 && (
                                                        <span className="error invalid-feedback d-block">
                                                        {isError.startDate}
                                                        </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.end" /></label>
                                                    <div className="col-sm-8">
                                                        <DatePicker
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode='select'
                                                            selected={form.endDate}
                                                            onChange={this.handleChangeDatePicker.bind(this, 'endDate')}
                                                            locale={lang}
                                                            customInput={<CustomInputDate />}
                                                        />
                                                        {isError.endDate.length > 0 && (
                                                        <span className="error invalid-feedback d-block">
                                                        {isError.endDate}
                                                        </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-end">
                                                <Button type="button" data-dismiss="modal"><FormattedMessage id="button.name.common.cancel" /></Button>
                                                <Button type="button" appearance='primary' isDisabled={!this.state.enable} onClick={() => this.handleUpdateMilestone(item._id)} className="btn btn-primary">{TranslateHelper.getMessage('button.name.common.save')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal hide fade" id={'Id' + item._id} data-keyboard="false" data-backdrop="static">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 className="modal-title"> Assign to {item.milestoneName }</h6>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {isAllowEditProject(this.role, this.userRoleInProject) && <div className="form-group row">
                                                <label htmlFor="Issue" className="col-sm-3 col-form-label"><FormattedMessage id="project.detail.settings.milestone.assign.label.name.name" /></label>
                                                <div className="col-sm-12">
                                                    <Select
                                                        options={this.state.suggestions}
                                                        placeholder="Choose issue name"
                                                        value={this.state.issueOption}
                                                        selected={this.state.issueOption}
                                                        onChange={(e) => this.handleChangeIssueSelect(e)}
                                                        styles={isError.issueOption?.length > 0 ? selectError : selectNormal}
                                                    />
                                                    {isError.issueOption?.length > 0 && (
                                                        <span className="error invalid-feedback d-block">
                                                            {isError.issueOption}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>}
                                            <div className="form-group row">
                                                <label htmlFor="Assigned Task" className="col-sm-3 col-form-label"><FormattedMessage id="project.detail.settings.milestone.assigned.label.name.assigned" /></label>
                                                <div className='table-scrollX'>
                                                    <table id="milestone.issue-list" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><FormattedMessage id="project.detail.settings.milestone.issue.table.column.name.ID" /></th>
                                                                <th><FormattedMessage id="project.detail.settings.milestone.issue.table.column.name.Name" /></th>
                                                                <th><FormattedMessage id="project.detail.settings.milestone.issue.table.column.name.Assignee" /></th>
                                                                {isAllowEditProject(this.role, this.userRoleInProject) && <th><FormattedMessage id="project.detail.settings.milestone.issue.table.column.name.Acction" /></th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.issuesOfMilestone.map((issue) => {
                                                                    return (
                                                                        <tr key={issue._id}>
                                                                            <td>{issue.issueCode}</td>
                                                                            <td>{issue.subject}</td>
                                                                            <td>{issue.assignTo && issue.assignTo?.firstname + ' ' + issue.assignTo?.lastname}</td>
                                                                            {isAllowEditProject(this.role, this.userRoleInProject) && <td>
                                                                                <Button type='button' onClick={() => { this.handleUnAssign(item._id, issue.issueCode); }} appearance='primary' data-toggle="tooltip" data-placement="top" title="Unassign issues to milestone">
                                                                                    <i className='fas fa-unlink'></i> Unassign
                                                                                </Button>
                                                                            </td>}
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-flex-end">
                                                <Button type="button" data-dismiss="modal"><FormattedMessage id="button.name.common.cancel" /></Button>
                                                {isAllowEditProject(this.role, this.userRoleInProject) && <Button type="button" onClick={() => { this.handleAssign(item._id, item.milestoneName); }} appearance='primary'><FormattedMessage id="button.name.common.assign" /></Button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                    );
                })
                : <td className='text-center' colSpan='4'><FormattedMessage id='message.no.data.available'/></td>;
        };
        return (
            <div className='container-fluid'>
                <div className='card'>
                    <div className='card-body'>
                    {isAllowEditProject(this.role, this.userRoleInProject) && <Button type="button" onClick={() => this.renderMilestoneModal(null, true)} appearance='primary' data-toggle="modal" data-target="#modal-milestone" style={{ margin: '10px 0px' }}>{TranslateHelper.getMessage('button.name.common.create')}</Button>
}
                        <div className="modal hide fade" id="modal-milestone" >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Milestone</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputName" className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.name" /></label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className={
                                                        isError.name.length > 0
                                                            ? 'is-invalid form-control'
                                                            : 'form-control'
                                                    }
                                                    id="inputName"
                                                    placeholder="Name of milestone"
                                                    value={this.state.form.name}
                                                    onChange={this.handleInputChange.bind(this, 'name')}
                                                />
                                                {isError.name.length > 0 && (
                                                <span className="error invalid-feedback">
                                                {isError.name}
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.start" /></label>
                                            <div className= 'col-sm-8'>
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    selected={this.state.form.startDate}
                                                    onChange={this.handleChangeDatePicker.bind(this, 'startDate')}
                                                    locale={lang}
                                                    customInput={<CustomInputDate />}
                                                />
                                                {isError.startDate.length > 0 && (
                                                <span className="error invalid-feedback d-block">
                                                {isError.startDate}
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label"><FormattedMessage id="project.detail.settings.milestone.add.label.name.end" /></label>
                                            <div className="col-sm-8">
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    selected={this.state.form.endDate}
                                                    onChange={this.handleChangeDatePicker.bind(this, 'endDate')}
                                                    locale={lang}
                                                    customInput={<CustomInputDate />}
                                                />
                                                {isError.endDate.length > 0 && (
                                                <span className="error invalid-feedback d-block">
                                                {isError.endDate}
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-end">
                                        <Button type="button" data-dismiss="modal"><FormattedMessage id="button.name.common.cancel" /></Button>
                                        <Button type="button" id="submitAdd" isDisabled={!this.state.enable} onClick={() => { this.handleCreateMilestone(); }} appearance='primary'>{TranslateHelper.getMessage('button.name.common.create')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='table-scrollX-clientId'>
                            <table id="milestone-list" className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="project.detail.settings.milestone.table.column.name.milestone" /></th>
                                        <th><FormattedMessage id="project.detail.settings.milestone.table.column.name.startdate" /></th>
                                        <th><FormattedMessage id="project.detail.settings.milestone.table.column.name.enddate" /></th>
                                        <th><FormattedMessage id="project.detail.settings.milestone.table.column.name.action" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loadingStatus === true && rowData(this.state.dataTable)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    const { role } = state.auth;
    const { userRoleInProject } = state.projectInfo;

    return {
        role,
        userRoleInProject
    };
}

export default connect(mapStateToProps)(Milestone);
