import { useCallback, useEffect, useState } from 'react';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import attendanceService from '../services/attendance.service';
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';
import ReactPagination from '../../../common/components/ReactPagination';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import './ManageAttendance.css';
import useDebounce from '../../../common/hooks/useDebounce';
import { setBreadcrumb } from '../../../common/actions/breadcrumb.action';
import recruitmentService from '../../../common/components/hrm/Services/recruitment.service';
import moment from 'moment';
import { DatePicker, ConfigProvider, Input, Select } from 'antd';
import vi from 'antd/lib/locale/vi_VN';
import 'dayjs/locale/vi';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('vi');
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function ManageAttendance () {
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.auth);
    const [attendances, setAttendances] = useState({ items: [], totalPages: 0 });
    const [businessUnits, setBusinessUnits] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const searchValue = useDebounce(search, 500);
    const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const [formDate, setFormDate] = useState(startOfMonth);
    const [toDate, setToDate] = useState(new Date());
    const [value, setValue] = useState({ label: '', id: '' });
    const [businessUnitId, setBusinessUnitId] = useState('');
    useEffect(() => {
        document.title = TranslateHelper.getMessage('menu.manager.name.attendance');
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.attendance',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.attendance',
                    haslink: false,
                    path: ''
                }
            ]
        }));
    }, [dispatch]);
    useEffect(() => {
        attendanceService.getAll({
            userId: value?.id,
            form: formDate && dayjs(formDate).format('YYYY-MM-DD'),
            to: toDate && dayjs(toDate).format('YYYY-MM-DD'),
            page,
            businessUnitId,
            search: searchValue
        }).then((response) => {
            setAttendances(response.data?.data);
        });
    }, [value, searchValue, toDate, formDate, page, businessUnitId]);

    useEffect(() => {
        if (role === 1 || role === 7) {
            attendanceService.getAllBusinessUnits().then((response) => {
                setBusinessUnits(response.data?.data);
            });
        }
    }, []);

    const onChangeRangeDate = (dates) => {
        dates[0] ? setFormDate(dates[0]) : setFormDate(undefined);
        dates[1] ? setToDate(dates[1]) : setToDate(undefined);
        setPage(1);
    };

    const handlePageChange = useCallback((event, page) => {
        setPage(page);
    }, []);

    useEffect(() => {
        setPage(1);
        setValue({ label: '', id: '' });
    }, [searchValue]);

    const checkWorkingType = (type) => {
        if (type === 'workingLocation') {
            return <FormattedMessage id='attendances.table.column.attendanceType.workingLocation' />;
        } else if (type === 'outsideWorkingLocation') {
            return <FormattedMessage id='attendances.table.column.attendanceType.outsideWorkingLocation' />;
        } else {
            return '—';
        }
    };

    return (
        <div className='container-fluid'>
            <div className='attendance-cs-filter'>
                {(role === 1 || role === 7) && <>
                    <Input style={{ width: '25%' }} size="large" allowClear value={search} onChange={(e) => setSearch(e.target.value)} placeholder={TranslateHelper.getMessage('attendances.label.search')} />
                    <Select
                        size="large"
                        allowClear
                        showSearch
                        style={{ width: '25%' }}
                        placeholder={<FormattedMessage id='attendances.table.column.bussinessUnit' />}
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={businessUnits.map((businessUnit) => {
                            return {
                                value: businessUnit._id,
                                label: businessUnit?.name
                            };
                        })}
                        onChange={(value) => {
                            setBusinessUnitId(value);
                            setPage(1);
                        }}
                    />
                </>}
                <div>
                    <ConfigProvider locale={vi}>
                        <RangePicker
                            size="large"
                            onCalendarChange={dates => onChangeRangeDate(dates)}
                            defaultValue={[dayjs(dayjs(formDate).format('YYYY-MM-DD'), dateFormat), dayjs(dayjs(toDate).format('YYYY-MM-DD'), dateFormat)]}
                            disabled={[false, false]}
                            format="DD/MM/YYYY"
                        />
                    </ConfigProvider>
                </div>
            </div>
            <div className='mt-2 attendance-cs'>
                <div>
                    <h3 className='card-title'>{TranslateHelper.getMessage('attendances.table.name')}</h3>
                </div>
                <div className='card-body p-0'>
                    <div className='table-responsive attendance-cs-table'>
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.user')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.id')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.bussinessUnit')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.date')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.attendanceTypeCheckIn')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.checkin')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.attendanceTypeCheckOut')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.checkout')}</th>
                                    <th scope="col">{TranslateHelper.getMessage('attendances.table.column.total')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendances.items.length > 0
                                    ? attendances.items.map((attendance, index) => (
                                        <tr key={attendance.id} className={attendance?.totalHours < (attendance?.verifyHours || 8) && attendance?.checkOutAt ? 'redBorder' : (!attendance?.totalHours && moment(attendance?.created_date).startOf('day').isBefore(moment().startOf('day')) ? 'yellowBorder' : 'whiteBorder')} style={{
                                        }}>
                                            <td scope="row">{index + 1 + (attendances.page - 1) * 10 }</td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <Avatar className='mr-3' alt={`${attendance?.user?.firstname} ${attendance?.user?.lastname}`} src={String(attendance?.user?.picture)?.includes('uploads') ? recruitmentService.buildFileURL(attendance?.user?.picture) : String(attendance?.user?.picture)} />
                                                    <div>
                                                        <p className='m-0'>{`${attendance?.user?.firstname} ${attendance?.user?.lastname}`}</p>
                                                        <p className='m-0' style={{
                                                            textTransform: 'lowercase'
                                                        }}>{attendance?.user?.username}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{attendance?.user?.id}</td>
                                            <td>
                                                {attendance?.businessUnit
                                                    ? <Tooltip placement="top" title={attendance?.businessUnit?.street}>
                                                        {attendance?.businessUnit?.name}
                                                    </Tooltip>
                                                    : '—'}

                                            </td>
                                            <td>{dayjs(attendance?.created_date).format('DD/MM/YYYY')}</td>
                                            <td>{attendance?.checkInAt ? checkWorkingType(attendance?.attendanceTypeCheckIn) : '—'}</td>
                                            <td>{attendance?.checkInAt ? dayjs(attendance?.checkInAt).format('HH:mm:ss') : '—'}</td>
                                            <td>{attendance?.checkOutAt ? checkWorkingType(attendance?.attendanceTypeCheckOut) : '—'}</td>
                                            <td>{attendance?.checkOutAt ? dayjs(attendance?.checkOutAt).format('HH:mm:ss') : '—'}</td>
                                            <td style={{ fontWeight: 'bold' }}>
                                                {(attendance?.checkOutAt && attendance?.totalHours) ? attendance?.totalHours?.toFixed(2) : '—'}
                                            </td>
                                        </tr>
                                    ))
                                    : <tr><td colSpan={10} style={{ textAlign: 'center' }}><FormattedMessage id='attendances.table.noRecord' /></td></tr>}

                            </tbody>
                        </table>
                    </div>
                    <div style={{
                        marginTop: '20px'
                    }}>
                        {attendances.totalPages > 1 && (
                            <ReactPagination
                                totalPages={attendances.totalPages}
                                handlePageChange={handlePageChange}
                                pageNumber={page}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>);
}

export default ManageAttendance;
