import React, { useState, useEffect, useCallback } from 'react';
import Select from '@atlaskit/select';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import memberService from '../../../services/member.service';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import Swal from 'sweetalert2';
import { connect, useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import { PROJECT_ROLE } from '../../../../../constants/project.constant';
import { isAllowEditProject } from '../../../../../helpers/CheckRoleHelper';
import { setRoleInProject } from '../../../../../actions/project.action';

const getRoleInProject = (members, userId) => {
    const user = members.find(obj => obj?.id === userId);
    return user?.role;
};

function Member ({ projectId, role, user, userRoleInProject }) {
    const dispatch = useDispatch();
    const [kimeiMember, setKimeiMember] = useState([]);
    const [members, setMember] = useState([]);
    const roles = [
        {
            value: PROJECT_ROLE.MANAGER,
            label: 'Manager'
        },
        {
            value: PROJECT_ROLE.DEVELOPER,
            label: 'Developer'
        },
        {
            value: PROJECT_ROLE.REPORTER,
            label: 'Reporter'
        },
        {
            value: PROJECT_ROLE.GUEST,
            label: 'Guest'
        }
    ];
    const methods = useForm({
        reValidateMode: 'onChange'
    });

    const { register, handleSubmit, formState: { errors } } = methods;
    // TODO: actual: danh sach mems trong project
    const fetchMembers = useCallback(() => {
        memberService.getMembers(projectId).then(res => {
            const list = res.map(item => ({ id: item?.user?._id, name: `${item.user?.firstname} ${item.user?.lastname}`, email: item.user?.username, role: item?.role }));
            setMember(list);
        });
    }, [projectId]);
    // TODO: actual: member list dc phep assign vao project
    const fetchAllMembers = useCallback(() => {
        memberService.getAllMembers(projectId).then((res) => {
            const list = res.map(member => ({ value: member?._id, label: `${member?.firstname} ${member?.lastname} (${member?.username})` }));
            setKimeiMember(list);
        });
    }, [projectId]);
    useEffect(fetchMembers, [fetchMembers]);

    useEffect(fetchAllMembers, [fetchAllMembers]);

    useEffect(() => {
        let roleInProject;
        if (members?.length && user?.userId) {
            roleInProject = getRoleInProject(members, user.userId);
            dispatch(setRoleInProject(roleInProject || null));
        }
    }, [members]);

    const displayRoleMember = roleId => {
        switch (roleId) {
            case PROJECT_ROLE.MANAGER:
                return 'Manager';
            case PROJECT_ROLE.DEVELOPER:
                return 'Developer';
            case PROJECT_ROLE.REPORTER:
                return 'Reporter';
            case PROJECT_ROLE.GUEST:
                return 'Guest';
            default:
                break;
        }
    };

    const handleAssignMember = useCallback(async (formData) => {
        try {
            await memberService.createMember(projectId, formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.addnew.successed')
            });
            fetchAllMembers();
            return fetchMembers();
        } catch (error) {
            console.warn(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: TranslateHelper.getMessage('respond.meesage.assignmember.failed')
            });
        }
    }, [projectId]);

    const handleDelete = (id, name) => {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + name + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await memberService.deleteMember(projectId, id);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.delete.successfully')
                });
                return fetchMembers();
            }
        });
    };
    const editFlag = isAllowEditProject(role, userRoleInProject);

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-body'>
                {editFlag && <form onSubmit={handleSubmit(handleAssignMember)}>
                    <div className='row my-3'>
                        <div className='col-xl-1'>
                            <label>Member</label>
                        </div>
                        <div className='col-xl-3'>
                            <Controller
                                control={methods.control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                    ref={ref}
                                    options={kimeiMember}
                                    placeholder={TranslateHelper.getMessage('placeholder.form.assignMember.member')}
                                    value={kimeiMember.find(c => c.value === value)}
                                    onChange={val => onChange(val.value)}
                                    />
                                )}
                                {...register('user', { required: true })}
                            />
                            {errors.status && <span className="error text-danger">This field is required</span>}
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-xl-1'>
                            <label>Role</label>
                        </div>
                        <div className='col-xl-3'>
                        <Controller
                            control={methods.control}
                            render={({ field: { onChange, value, ref } }) => (
                                <Select
                                isDisabled={!kimeiMember.length}
                                ref={ref}
                                options={roles}
                                value={roles.find(c => c.value === value)}
                                placeholder={TranslateHelper.getMessage('placeholder.form.assignMember.role')}
                                onChange={val => onChange(val.value)}
                                />
                            )}
                            {...register('role', { required: true })}
                        />
                        {errors.status && <span className="error text-danger">This field is required</span>}
                        </div>
                        <div className='col-xl-2 d-flex mt-3-sp'>
                            <Button appearance='primary' type="submit">{TranslateHelper.getMessage('button.name.common.add')}</Button>
                        </div>
                    </div>
                    </form>}
                    <div className='table-scrollX'>
                        <table id="status-list" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="project.detail.settings.member.table.column.name.name" /></th>
                                    <th><FormattedMessage id="project.detail.settings.member.table.column.name.email" /></th>
                                    <th><FormattedMessage id="project.detail.settings.member.table.column.name.role" /></th>
                                    {editFlag && <th><FormattedMessage id="project.detail.settings.member.table.column.name.action" /></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {members?.length
                                    ? members.map(mem => {
                                        return (
                                            <tr key={mem.id}>
                                                <td>{mem.name}</td>
                                                <td>{mem.email}</td>
                                                <td>{displayRoleMember(mem.role)}</td>
                                                {editFlag && <td><Button onClick={() => handleDelete(mem.id, mem.name)}><i className="far fa-trash-alt pointer"></i></Button></td>}
                                            </tr>
                                        );
                                    })
                                    : <tr>
                                        <td className='text-center' colSpan='4'><FormattedMessage id='message.no.data.available'/></td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    const { userRoleInProject } = state.projectInfo;
    const { user, role } = state.auth;

    return {
        userRoleInProject,
        user,
        role
    };
}

export default connect(mapStateToProps)(Member);
