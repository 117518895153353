import * as React from 'react';
import LaptopIcon from '@mui/icons-material/Laptop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useState, useEffect, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import authService from '../../../../common/services/auth.service';
import TranslateHelper from '../../../../common/helpers/TranslateHelper';
import SnackBarNotify from '../../../../common/components/common/SnackBarNotify';

function NotifyPlatform ({ notifyPlatform, user }) {
    const [platform, setPlatform] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const selectedPlatforms = [];
        for (const platform in notifyPlatform) {
            if (notifyPlatform[platform] === 'enable') {
                selectedPlatforms.push(platform);
            }
        }
        setPlatform(selectedPlatforms);
    }, [notifyPlatform]);

    const handlePlatforms = useCallback((event, newPlatform) => {
        if (newPlatform.length) {
            const payload = {
                ...user,
                notifyPlatform: {
                    slack: newPlatform.includes('slack') ? 'enable' : 'disable',
                    telegram: newPlatform.includes('telegram') ? 'enable' : 'disable',
                    zalo: newPlatform.includes('zalo') ? 'enable' : 'disable'
                }
            };
            authService.saveSetting(payload);
            setPlatform(newPlatform);
        }
        setOpen(true);
    }, [platform]);

    return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup
        value={platform}
        onChange={handlePlatforms}
        aria-label="device"
      >

            <ToggleButton value="slack" aria-label="slack">
                <Tooltip title='Slack'>
                    <LaptopIcon />
                </Tooltip>
                <Typography variant='subtitle2'>Slack</Typography>
            </ToggleButton>

            <ToggleButton value="telegram" aria-label="telegram">
                <Tooltip title='Telegram'>
                    <TelegramIcon />
                </Tooltip>
                <Typography variant='subtitle2'>Telegram</Typography>
            </ToggleButton>

            <ToggleButton value="zalo" aria-label="zalo">
                <Tooltip title='Zalo'>
                    <PhoneAndroidIcon />
                </Tooltip>
                <Typography variant='subtitle2'>Zalo</Typography>
            </ToggleButton>
      </ToggleButtonGroup>
      <SnackBarNotify open={open} setOpen={setOpen} position={{ vertical: 'bottom', horizontal: 'left' }} text={TranslateHelper.getMessage('respond.message.update.successfully')} type='success'/>
    </Stack>
    );
}

export default NotifyPlatform;
