import { FormattedMessage } from 'react-intl';
import CreateInterviewForm from './CreateInterviewForm';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useService } from '../../../../helpers/serviceHelpers';
import recruitmentService from '../../Services/recruitment.service';
import UpdateInterviewForm from './UpdateInterViewForm';
import Calendar from '../../../common/Calendar';
import RecruitmentContext from '../../../../context/RecruitmentContext';
import Button from '@atlaskit/button';

export default function Interview () {
    const lang = localStorage.getItem('lang');

    const { data: interviews, refetch } = useService(
        recruitmentService.getInterviews,
        { limit: 999, isPage: 2 },
        (data) => data.items
    );

    const { forTeams, interviewers } = useContext(RecruitmentContext);
    const { data: CV } = useService(recruitmentService.getInterviews, undefined, (data) =>
        data.items.map((item) => ({
            label: `${item.candidateName}`,
            value: item._id
        }))
    );

    const events = useMemo(() => [
        ...interviews.map((item) => ({
            ...item,
            title: item.candidateName,
            date: item.dateInterview,
            publicId: item._id,
            extendedProps: {
                icon: <i className="fas fa-user-clock pr-1"></i>,
                color: '#7593CE',
                type: 'Interview'
            }
        }))
    ], [interviews]);

    const openModal = useRef();
    const [interviewToUpdate, setInterviewToUpdate] = useState(null);

    const handleEvenClick = useCallback(
        (e) => {
            setInterviewToUpdate(e.event._def.extendedProps);
            openModal.current.click();
        },
        [interviewToUpdate]
    );

    return (
        <d>
            <div className="card">
                <div className="card-body">
                    <Button
                        ref={openModal}
                        type="button"
                        appearance='primary'
                        data-toggle="modal"
                        data-target="#modal-employees"
                    >
                        <FormattedMessage id="button.name.common.create" />
                    </Button>
                    <CreateInterviewForm refetch={refetch} CV={CV} isNew={true}/>
                    <Calendar events={events} lang={lang} handleEvenClick={handleEvenClick} />
                </div>
            </div>
        {interviewToUpdate && (
            <>
            <UpdateInterviewForm interview={interviewToUpdate} refetch={refetch} forTeams={forTeams} interviewers={interviewers}/>
        <Button
            ref={openModal}
            data-toggle="modal"
            data-target={`#modal-interview-${interviewToUpdate?._id}`}
            className="invisible"
         ></Button>
            </>)}
        </d>
    );
}
