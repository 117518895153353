import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/auth.action';
import Cookies from 'js-cookie';
import MenuAccount from './MenuAccount';
import { Box } from '@mui/material';
import Notifications from './Notifications';
class HeaderSection extends Component {
    constructor (props) {
        super(props);
        this.state = {};
    }

    handleLogout () {
        Cookies.remove('roleChanged');
        const { dispatch } = this.props;
        dispatch(logout());
    }

    render () {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <Box sx={{ display: 'flex', textAlign: 'center' }}>
                        <Notifications/>
                        <MenuAccount/>
                    </Box>
                </ul>
            </nav>
        );
    }
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(HeaderSection);
