import English from './en.json';
import Vietnam from './vi.json';
import Japan from './ja.json';
import enFullcalendar from '@fullcalendar/core/locales/en-au';
import jaFullcalendar from '@fullcalendar/core/locales/ja';
import viFullcalendar from '@fullcalendar/core/locales/vi';
import { registerLocale } from 'react-datepicker';
import viDatepicker from 'date-fns/locale/vi';
import jaDatepicker from 'date-fns/locale/ja';
import enDatepicker from 'date-fns/locale/en-US';

export const LANGUAGES = {
    en_US: {
        locale: 'en',
        messages: English
    },
    vi_VN: {
        locale: 'vi',
        messages: Vietnam
    },
    ja_JP: {
        locale: 'ja',
        messages: Japan
    }
};

export const LanguageDatatables = {
    en: {
        emptyTable: 'No data available in table',
        info: 'Showing _START_ to _END_ of _TOTAL_ entries',
        infoEmpty: 'Showing 0 to 0 of 0 entries',
        infoFiltered: '(filtered from _MAX_ total entries)',
        infoThousands: ',',
        lengthMenu: 'Show _MENU_ entries',
        loadingRecords: 'Loading...',
        processing: 'Processing...',
        search: 'Search:',
        zeroRecords: 'No matching records found',
        paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous'
        },
        aria: {
            sortAscending: ': activate to sort column ascending',
            sortDescending: ': activate to sort column descending'
        }
    },
    ja: {
        emptyTable: 'テーブルにデータがありません',
        info: ' _TOTAL_ 件中 _START_ から _END_ まで表示',
        infoEmpty: ' 0 件中 0 から 0 まで表示',
        infoFiltered: '（全 _MAX_ 件より抽出）',
        infoThousands: ',',
        lengthMenu: '_MENU_ 件表示',
        loadingRecords: '読み込み中...',
        processing: '処理中...',
        search: '検索:',
        zeroRecords: '一致するレコードがありません',
        paginate: {
            first: '先頭',
            last: '最終',
            next: '次',
            previous: '前'
        },
        aria: {
            sortAscending: ': 列を昇順に並べ替えるにはアクティブにする',
            sortDescending: ': 列を降順に並べ替えるにはアクティブにする'
        }
    },
    vi: {
        emptyTable: 'Không có dữ liệu',
        info: 'Hiển thị _START_ tới _END_ của _TOTAL_ dữ liệu',
        infoEmpty: 'Hiển thị 0 tới 0 của 0 dữ liệu',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoThousands: ',',
        lengthMenu: 'Hiển thị _MENU_ dữ liệu',
        loadingRecords: 'Đang tải...',
        processing: 'Đang xử lý...',
        search: 'Tìm kiếm:',
        zeroRecords: 'Không tìm thấy kết quả',
        paginate: {
            first: 'Đầu tiên',
            last: 'Cuối cùng',
            next: 'Sau',
            previous: 'Trước'
        },
        aria: {
            sortAscending: ': Sắp xếp thứ tự tăng dần',
            sortDescending: ': Sắp xếp thứ tự giảm dần'
        }
    }
};

export const LanguageFullcalendar = {
    en: enFullcalendar,
    ja: jaFullcalendar,
    vi: viFullcalendar
};

export const LanguageFormatDate = {
    en: 'MM/dd/yyyy',
    ja: 'yyyy/MM/dd',
    vi: 'dd/MM/yyyy'
};

export const LanguageFormatDateTime = {
    en: 'MM/dd/yyyy h:mm a',
    ja: 'yyyy/MM/dd h:mm a',
    vi: 'dd/MM/yyyy h:mm a'
};

export const LanguageDatepicker = {
    en: () => { registerLocale('en', enDatepicker); },
    ja: () => { registerLocale('ja', jaDatepicker); },
    vi: () => { registerLocale('vi', viDatepicker); }
};
