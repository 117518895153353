import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import recruitmentService from '../../Services/recruitment.service';
import EditIcon from '@mui/icons-material/Edit';

export default function dataCard ({ data, setSelectedEmployee, firstClear, setIsNew, isShowEdit = true }) {
    return (
        <Card>
        <CardHeader
            avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                <img
                    onError={(e) => {
                        e.target.src = '/images/default-avatar.png';
                    }}
                    src={
                        data?.picture
                            ? !String(data?.picture).includes(
                                    'uploads'
                                )
                                    ? data.picture
                                    : recruitmentService.buildFileURL(
                                        data.picture
                                    )
                            : '/images/default-avatar.png'
                    }
                ></img>
            </Avatar>
            }
            action={
            isShowEdit && <IconButton data-toggle="modal" data-target="#modal-employees" aria-label="settings" onClick={() => {
                firstClear.current = false;
                setIsNew(false);
                setSelectedEmployee(data);
            }}>
                <EditIcon />
            </IconButton>
            }
            title={
                data.firstname +
                ' ' +
                data.lastname
            }
            subheader={data.departmentId?.name}
        />
        <CardContent>
            <Typography variant="body2" color="text.secondary">
            <div className="row">
                <label className="ml-2">
                    <i className="fas fa-id-badge"></i>
                </label>
                <div className="ml-3">{data.id}</div>
            </div>
            <div className="row">
                <label className="ml-2">
                    <i className="fas fa-envelope"></i>
                </label>
                <div
                    style={{ width: '80%' }}
                    title={data.username}
                    className="ml-3 data-name text-lowercase"
                >
                    {data.username}
                </div>
            </div>
            <div className="row">
                <label className="ml-2">
                    <i className="fas fa-phone-alt"></i>
                </label>
                <div className="ml-3">{data.mobile}</div>
            </div>
            </Typography>
        </CardContent>
        </Card>
    );
}
