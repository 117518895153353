import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class HierarchyService {
    constructor () {
        serviceHelpers();
    }

    getOrganizationHierarchy () {
        return axiosService.get(API_URL + 'organizationHierarchy');
    }

    getDepartmentHierarchy () {
        const endpoint = `${API_URL}businessDepartments/hierarchy`;
        return axiosService.get(endpoint).then(res => res.data.data);
    }
}

export default new HierarchyService();
