import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import recruitmentService from './hrm/Services/recruitment.service';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import notificationService from './project/services/notification.service';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

export default function NotificationItem ({ data }) {
    const markNotification = (id) => {
        const payload = {
            isChecked: true
        };
        notificationService.markOneNotification(id, payload);
    };
    return data.items.map(item => {
        if (item.projectId) {
            return (
                <>
                    <a href={'/projects/' + item.projectId?.identifier + '/issues/' + item.issuesId?.issueCode} className='text-decoration-none'>
                        <MenuItem onClick={() => markNotification(item?._id)} sx={{ backgroundColor: item.isChecked ? 'white' : 'rgba(145, 158, 171, 0.16)' }}>
                            <List sx={{ width: '100%' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.createdBy?.picture ? (!String(item.createdBy?.picture).includes('uploads') ? item.createdBy?.picture : recruitmentService.buildFileURL(item.createdBy?.picture)) : '/images/default-avatar.png'} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={
                                        <>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography
                                                    sx={{ fontWeight: 'bold' }}
                                                    variant={'subtitle1'}
                                                    className='mr-2'
                                                >
                                                    {item.createdBy?.firstname} {item.createdBy?.lastname}
                                                </Typography>
                                                <Typography
                                                    variant='subtitle2'
                                                    className='align-self-center font-weight-bold'
                                                    sx={{ color: 'rgb(99, 115, 129);' }}
                                                >
                                                    {item?.action} to you
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <Typography
                                                variant='subtitle2'
                                                color="text.primary"
                                            >
                                                [{item.issuesId?.issueCode}] {item.issuesId?.subject}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="caption"
                                            >
                                                <AccessTimeIcon fontSize='small'/> {moment(item?.createdAt).fromNow()}
                                            </Typography>
                                        </>
                                    }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </List>
                        </MenuItem>
                    </a>
                </>
            );
        } else {
            return (
                <>
                <Link to={{ pathname: '/hrm', state: item.action === 'mention an interview' ? { HrmIdx: 4 } : item.action === 'send leave request' ? { HrmIdx: 1, servicesIdx: 2 } : { HrmIdx: 1, servicesIdx: 1 } }} className='text-decoration-none'>
                    <MenuItem onClick={() => markNotification(item?._id)} sx={{ backgroundColor: item.isChecked ? 'white' : 'rgba(145, 158, 171, 0.16)' }}>
                        <List sx={{ width: '100%' }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar>
                                        <img onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={item.createdBy?.picture ? (!String(item.createdBy?.picture).includes('uploads') ? item.createdBy?.picture : recruitmentService.buildFileURL(item.createdBy?.picture)) : '/images/default-avatar.png'} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography
                                            sx={{ fontWeight: 'bold' }}
                                            variant={'subtitle1'}
                                            className='mr-2'
                                        >
                                            {item.createdBy?.firstname} {item.createdBy?.lastname}
                                        </Typography>
                                        <Typography
                                            variant='subtitle2'
                                            className='align-self-center font-weight-bold'
                                            sx={{ color: 'rgb(99, 115, 129);' }}
                                        >
                                            {item?.action} to you
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                        >
                                            <AccessTimeIcon fontSize='small'/> {moment(item?.createdAt).fromNow()}
                                        </Typography>
                                    </>
                                }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </List>
                    </MenuItem>
                </Link>
                </>
            );
        }
    });
}
