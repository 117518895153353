import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import activityService from '../../../../../manager/activity/Services/activity.service';
import { ROLES } from '../../../../constants/user.constant';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import hrService from '../../Services/hr.service';

function ActivityLogsChart ({ role }) {
    const [activities, setActivities] = useState([]);
    const getFirstAndLastDate = () => {
        const dateObj = {};
        const date = new Date();
        dateObj.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        dateObj.lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return dateObj;
    };

    const activitesFilter = {
        limit: 999999999,
        fromDate: getFirstAndLastDate().firstDay,
        toDate: getFirstAndLastDate().lastDay
    };

    const getAllDaysOfMonth = () => {
        const result = hrService.getDaysInMonth();
        for (const property in result) {
            result[property] = {
                add: 0,
                update: 0,
                edit: 0,
                created: 0
            };
        }
        return result;
    };

    const fetchActivities = useCallback(() => {
        if (role === ROLES.MANAGER || role === ROLES.PARTNEROWNER) {
            activityService.getAllActivities(activitesFilter).then(res => {
                setActivities(() => {
                    const result = getAllDaysOfMonth();
                    res.items?.forEach((element, index, arr) => {
                        const previousDate = arr[index - 1];
                        const date = moment(element.createdAt).format(TranslateHelper.getMessage('date.format'));
                        if (index === 0 || (previousDate && moment(previousDate.createdAt).format(TranslateHelper.getMessage('date.format')) !== moment(element.createdAt).format(TranslateHelper.getMessage('date.format')))) {
                            result[date][element.action] = 1;
                        } else {
                            result[date][element.action] += 1;
                        }
                    });
                    return result;
                });
            }).catch(err => {
                console.log(err);
            });
        } else {
            activityService.getActivities(activitesFilter).then(res => {
                setActivities(() => {
                    const result = getAllDaysOfMonth();
                    res.items?.forEach((element, index, arr) => {
                        const previousDate = arr[index - 1];
                        const date = moment(element.createdAt).format(TranslateHelper.getMessage('date.format'));
                        if (index === 0 || (previousDate && moment(previousDate.createdAt).format(TranslateHelper.getMessage('date.format')) !== moment(element.createdAt).format(TranslateHelper.getMessage('date.format')))) {
                            result[date][element.action] = 1;
                        } else {
                            result[date][element.action] += 1;
                        }
                    });
                    return result;
                });
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    useEffect(fetchActivities, [fetchActivities]);

    const data = {
        labels: Object.keys(activities || {}).map((_, index) => index + 1),
        datasets: [
            {
                label: 'Add',
                data: Object.values(activities || {}).map(item => item.add),
                backgroundColor: [
                    'rgb(75, 192, 192)'
                ],
                hoverOffset: 4,
                lineTension: 0.5
            },
            {
                label: 'Update',
                data: Object.values(activities || {}).map(item => item.update),
                backgroundColor: [
                    '2D99FF'
                ],
                hoverOffset: 4,
                lineTension: 0.5
            },
            {
                label: 'Edit',
                data: Object.values(activities || {}).map(item => item.edit),
                backgroundColor: [
                    'rgb(130, 106, 249)'
                ],
                hoverOffset: 4,
                lineTension: 0.5
            },
            {
                label: 'Created',
                data: Object.values(activities || {}).map(item => item.created),
                backgroundColor: [
                    'rgb(255, 231, 0)'
                ],
                hoverOffset: 4,
                lineTension: 0.5
            }
        ]
    };
    return (
        <>
            <Line
                options = {{
                    indexAxis: 'x',
                    elements: {
                        bar: {
                            borderWidth: 2
                        }
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        title: {
                            display: true,
                            text: 'Activity logs'
                        }
                    }
                }}
                data={data}
            />
        </>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(ActivityLogsChart);
