import { ACTION_HRM } from '../constants/action.constant';

export const setOptionView = (payload) => ({
    type: ACTION_HRM.SET_OPTIONVIEW,
    payload
});

export const setKimeiMembers = (payload) => ({
    type: ACTION_HRM.SET_KIMEI_MEMBER,
    payload
});
