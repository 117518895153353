import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ROLES } from '../../../../../common/constants/user.constant';
import { regexName } from '../../../../constants/validation.constant';
import HrmContext from '../../../../context/HrmContext';
import { useService } from '../../../../helpers/serviceHelpers';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import businessunitsService from '../organization-detail/services/businessunits.service';
import LeftOfficial from './LeftOfficial';
import { modalEmployeesRef } from './ModalEmployees';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UpdateLeaves } from './UpdateLeaves';
import hrService from '../../Services/hr.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import Skeleton from 'react-loading-skeleton';
function OfficialForm ({ user, titles, status, roles, role, isNew, selectedEmployee, closeModal, refetch, handleUpdateEmployee, hookForm, handleCreateEmployee, kimeiMembers, isLoading }) {
    const { clearErrors } = useForm({
        mode: 'onChange'
    });
    const { data: businessUnits } = useService(businessunitsService.getBusinessUnits, null, res => res.data.data);
    const [lineManagers, setLineManagers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setLineManagers(kimeiMembers);
    }, [kimeiMembers]);

    const departments = useContext(HrmContext);

    const selectStyle = {
        control: base => ({
            ...base,
            borderColor: hookForm.formState.errors.status ? 'red' : '#ced4da',
            borderRadius: '0.3rem'
        })
    };
    const updateLeaveForm = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        if (selectedEmployee) {
            updateLeaveForm.setValue('availableLeaves', selectedEmployee.availableLeaves);
            updateLeaveForm.setValue('availableWfhDays', selectedEmployee.availableWfhDays);
            updateLeaveForm.setValue('availableSummerLeave', selectedEmployee.availableSummerLeave);
        }
    }, [selectedEmployee, isOpen]);
    const handleUpdateLeaves = async (value) => {
        try {
            const data = {
                availableLeaves: parseFloat(value.availableLeaves),
                availableWfhDays: parseFloat(value.availableWfhDays),
                availableSummerLeave: parseFloat(value.availableSummerLeave)
            };
            await hrService.adjustAvailableLeaves(selectedEmployee.id, data)
                .then((res) => {
                    if (res.success) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.update.successed')
                        });
                        setIsOpen(false);
                        closeModal();
                        refetch();
                    }
                });
        } catch (error) {
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    };
    return (
        <>
            {isLoading
                ? <div>
                    <Skeleton height={40} count={10}/>
                </div>
                : <div className='row'>
                    {!isNew && (
                        <div className='col-12 col-xl-4'>
                            <LeftOfficial avatar={selectedEmployee?.picture} status={status} id={selectedEmployee?.id}
                                          refetch={refetch}/>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableleave"/>}
                                    {...updateLeaveForm.register('availableLeaves', {})}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableWFH"/>}
                                    {...updateLeaveForm.register('availableWfhDays', {})}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            <div className='form-group'>
                                <TextField
                                    variant='outlined'
                                    label={<FormattedMessage id="hrm.services.leaveRequest.label.availableSummer"/>}
                                    {...updateLeaveForm.register('availableSummerLeave', {})}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            {
                                (role === ROLES.MANAGER || role === ROLES.HR) && (
                                    <div className='d-flex justify-content-end'>
                                        <Button data-toggle="modal" data-target="#modal-employee-leaves" className='w-25'
                                                type='submit' appearance='primary'
                                                onClick={() => setIsOpen(true)}>Edit</Button>
                                    </div>
                                )
                            }
                            {isOpen && <UpdateLeaves setIsOpen={setIsOpen} updateLeaveForm={updateLeaveForm}
                                                     handleUpdateLeaves={handleUpdateLeaves}/>}
                        </div>
                    )}
                    <div className={!isNew ? 'col-xl-8 col-12' : 'col-12'}>
                        <div className='card'>
                            <div className='card-body'>
                                <form className='row'
                                      onSubmit={hookForm.handleSubmit(isNew ? handleCreateEmployee : handleUpdateEmployee)}>
                                    <div className="form-horizontal col-xl-6 col-12">
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.id"/>}
                                                disabled={!isNew}
                                                {...hookForm.register('id', {
                                                    required: TranslateHelper.getMessage('validation.error.required'),
                                                    pattern: {
                                                        value: regexName,
                                                        message: TranslateHelper.getMessage(
                                                            'validation.error.containSpecialCharacter'
                                                        )
                                                    }
                                                })}
                                                fullWidth
                                                error={hookForm.formState.errors.id}
                                                helperText={hookForm.formState.errors.id?.message}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.firstName"/>}
                                                {...hookForm.register('firstname', {
                                                    required: TranslateHelper.getMessage('validation.error.required')
                                                })}
                                                fullWidth
                                                error={hookForm.formState.errors.firstname}
                                                helperText={hookForm.formState.errors.firstname?.message}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.lastName"/>}
                                                {...hookForm.register('lastname', {
                                                    required: TranslateHelper.getMessage('validation.error.required')
                                                })}
                                                fullWidth
                                                error={hookForm.formState.errors.lastname}
                                                helperText={hookForm.formState.errors.lastname?.message}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.email"/>}
                                                {...hookForm.register('username', {
                                                    required: TranslateHelper.getMessage('validation.error.required')
                                                })}
                                                error={hookForm.formState.errors.username}
                                                helperText={hookForm.formState.errors.username?.message}
                                                type='email'
                                                fullWidth
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.jobTitle"/>}
                                                {...hookForm.register('jobTitle', {
                                                    required: TranslateHelper.getMessage('validation.error.required')
                                                })}
                                                fullWidth
                                                error={hookForm.formState.errors.jobTitle}
                                                helperText={hookForm.formState.errors.jobTitle?.message}
                                                defaultValue=''
                                            />
                                        </div>
                                        {/* <div className="form-group"> */}
                                        {/*    <TextField */}
                                        {/*        variant='outlined' */}
                                        {/*        label={<FormattedMessage id="HRM.detail.HR.official.label.slackId"/>} */}
                                        {/*        {...hookForm.register('slackId')} */}
                                        {/*        fullWidth */}
                                        {/*    /> */}
                                        {/* </div> */}
                                        <div className="form-group">
                                            <TextField
                                                variant='outlined'
                                                label={<FormattedMessage id="HRM.detail.HR.official.label.larkId"/>}
                                                {...hookForm.register('larkId')}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="form-horizontal col-xl-6 col-12">
                                        <div className="form-group">
                                            <Controller
                                                control={hookForm.control}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        options={businessUnits}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option._id}
                                                        onChange={onChange}
                                                        value={value}
                                                        styles={selectStyle}
                                                        placeholder='Business Unit'
                                                    />
                                                )}
                                                name="businessId"
                                            />
                                            {hookForm.formState.errors.businessId &&
                                                <span className='error text-danger'><FormattedMessage
                                                    id="validation.error.select"/></span>}
                                        </div>
                                        <div className="form-group">
                                            <Controller
                                                control={hookForm.control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        isClearable
                                                        options={lineManagers}
                                                        getOptionLabel={option => option.firstname + ' ' + option.lastname}
                                                        getOptionValue={option => option._id}
                                                        onChange={onChange}
                                                        value={value}
                                                        styles={selectStyle}
                                                        placeholder='Line Manager'
                                                    />
                                                )}
                                                name="lineManager"
                                            />
                                            {hookForm.formState.errors.lineManager &&
                                                <span className='error text-danger'><FormattedMessage
                                                    id="validation.error.select"/></span>}
                                        </div>
                                        {/* <div className="form-group">
                                            <Controller
                                                control={hookForm.control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        isClearable
                                                        options={lineManagers}
                                                        getOptionLabel={option => option.firstname + ' ' + option.lastname}
                                                        getOptionValue={option => option._id}
                                                        onChange={onChange}
                                                        value={value}
                                                        styles={selectStyle}
                                                        placeholder='Leave Approver'
                                                    />
                                                )}
                                                name="leave_approver"
                                            />
                                        </div> */}
                                        <div className="form-group">
                                            <Controller
                                                control={hookForm.control}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        options={titles}
                                                        onChange={onChange}
                                                        value={value}
                                                        styles={selectStyle}
                                                        placeholder='Title'
                                                    />
                                                )}
                                                name="title"
                                            />
                                            {hookForm.formState.errors.title &&
                                                <span className='error text-danger'><FormattedMessage
                                                    id="validation.error.select"/></span>}
                                        </div>
                                        <div className="form-group">
                                            <Controller
                                                control={hookForm.control}
                                                rules={{
                                                    required: true
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Select
                                                        options={departments}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option._id}
                                                        onChange={onChange}
                                                        value={value}
                                                        styles={selectStyle}
                                                        placeholder='Departments'
                                                    />
                                                )}
                                                name="departmentId"
                                            />
                                            {hookForm.formState.errors.departmentId &&
                                                <p className='error text-danger'>{hookForm.formState.errors.departmentId.message}</p>}
                                        </div>
                                        {selectedEmployee?.username !== user.email && (
                                            <div className="form-group">
                                                <Controller
                                                    control={hookForm.control}
                                                    rules={{
                                                        required: true
                                                    }}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Select
                                                            options={roles}
                                                            onChange={onChange}
                                                            value={value}
                                                            styles={selectStyle}
                                                            placeholder='Role'
                                                        />
                                                    )}
                                                    name="role"
                                                />
                                                {hookForm.formState.errors.role &&
                                                    <p className='error text-danger'>{hookForm.formState.errors.role.message}</p>}
                                            </div>
                                        )}
                                        <div>
                                            <Controller
                                                control={hookForm.control}
                                                render={({
                                                    field: { onChange, value, ref }
                                                }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={onChange}
                                                                checked={value}
                                                            />
                                                        }
                                                        label="Support working leave"
                                                    />
                                                )}
                                                name="is_support_working_leave"
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 justify-content-end px-4'>
                                        <Button type='button' onClick={() => {
                                            closeModal();
                                            clearErrors();
                                            modalEmployeesRef.current?.clearForm();
                                        }} className='justify-content-center mr-2 col-xl-2 col-4'><FormattedMessage
                                            id='button.name.common.cancel'/></Button>
                                        <Button type="submit" appearance='primary'
                                                className='justify-content-center col-xl-2 col-4'
                                                isDisabled={!hookForm.formState.isDirty}><FormattedMessage
                                            id={isNew ? 'button.name.common.create' : 'button.name.common.save'}/></Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    const { kimeiMembers } = state.hrm;
    return { user, kimeiMembers, role };
}

export default connect(mapStateToProps)(OfficialForm);
