import serviceHelpers from '../../../../../helpers/serviceHelpers';
import axiosService from '../../../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';
const API_URL_USER_MANAGER = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/users/manager';

class DepartmentService {
    constructor () {
        serviceHelpers();
    }

    getAllDepartment (params) {
        return axiosService.get(API_URL + 'businessDepartments', { params });
    }

    getDepartmentHead () {
        return axiosService.get(API_URL_USER_MANAGER);
    }

    createDepartment (payload) {
        const endpoint = `${API_URL}businessDepartments`;
        return axiosService.post(endpoint, payload).then(res => res.data.data);
    }

    updateDepartment (payload, id) {
        const endpoint = `${API_URL}businessDepartments/${id}`;
        return axiosService.patch(endpoint, payload).then(res => res.data.data);
    }

    deleteDepartment (code) {
        const endpoint = `${API_URL}businessDepartments/${code}`;
        return axiosService.delete(endpoint).then(res => res.data.data);
    }
}

export default new DepartmentService();
