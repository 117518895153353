import { createRef, useCallback, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OfficialForm from './OfficialForm';
import Personal from './PersonalForm';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import hrService from '../../Services/hr.service';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { ROLES, TITLE_GENDER } from '../../../../constants/user.constant';
import { differenceInDays } from 'date-fns';

export const modalEmployeesRef = createRef();

export default function ModalEmployees ({ isNew, selectedEmployee, refetch, firstClear, setSelectedEmployee }) {
    const hideModalButtonRef = useRef();
    const [isClearable, setIsClearable] = useState(false);
    const hookForm = useForm({
        mode: 'onChange'
    });
    const [isLoading, setIsLoading] = useState(false);
    const closeModal = () => {
        hideModalButtonRef.current?.click();
    };

    const titles = [
        {
            value: TITLE_GENDER.MR,
            label: 'MR'
        },
        {
            value: TITLE_GENDER.MS,
            label: 'MS'
        },
        {
            value: TITLE_GENDER.MRS,
            label: 'MRS'
        }
    ];

    const roles = [
        {
            value: ROLES.MEMBER,
            label: 'Member'
        },
        {
            value: ROLES.MANAGER,
            label: 'Manager'
        },
        {
            value: ROLES.HR,
            label: 'HR Recruitment'
        },
        {
            value: ROLES.GUEST,
            label: 'Guest'
        }
    ];

    const internshipType = [
        {
            label: <FormattedMessage id='HRM.detail.HR.official.label.internshipNormal' />,
            value: '1'
        },
        {
            label: <FormattedMessage id='HRM.detail.HR.official.label.internshipCarpentryMark' />,
            value: '2'
        }
    ];

    const clearForm = useCallback(() => {
        hookForm.reset({
            id: '',
            firstname: '',
            lastname: '',
            username: '',
            title: null,
            lineManager: null,
            businessId: null,
            jobTitle: '',
            departmentId: null,
            slackId: '',
            larkId: '',
            role: null,
            joinedDate: '',
            address: '',
            mobile: '',
            birthDay: '',
            identify_id: '',
            identify_issued_date: '',
            identify_issued_by: '',
            temporary_address: '',
            bank_info: {},
            tax_code: '',
            bhxh_code: '',
            contract_no: '',
            contract_sign_date: '',
            contract_1_date: '',
            contract_2_date: '',
            contract_3_date: '',
            contract_sign_times: '',
            last_working_date: '',
            working_date: '',
            level_title: '',
            is_cv: '',
            is_syll: '',
            is_registration_book: '',
            is_identify_id: '',
            is_job_application: '',
            is_degree: '',
            is_health_certification: '',
            is_portrait: ''
        });
    }, [hookForm]);

    useImperativeHandle(modalEmployeesRef, () => ({
        clearForm
    }), [clearForm]);

    const handleCreateEmployee = useCallback(async (formData) => {
        setIsLoading(true);
        try {
            formData.businessId = formData.businessId?._id || null;
            formData.title = formData.title?.value || null;
            formData.role = formData.role?.value || null;
            formData.lineManager = formData.lineManager?._id || null;
            formData.internship_type = formData.internship_type?.value || null;
            if (isNew) {
                await hrService.createEmployee(formData);
                setIsClearable(true);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.addnew.successed')
                });
            } else {
                if (formData.id !== '') {
                    await hrService.updateEmployee(selectedEmployee.id, formData);
                    ToastTopEnd.fire({
                        icon: 'success',
                        title: TranslateHelper.getMessage('respond.message.update.successed')
                    });
                }
            }
            setIsLoading(false);
            closeModal();
            refetch();
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('validation.error.officialForm')
            });
        }
    }, [isNew, selectedEmployee, refetch, closeModal]);

    const handleUpdateEmployee = useCallback(async (formData) => {
        try {
            formData.businessId = formData.businessId?._id;
            formData.title = formData.title?.value;
            formData.role = formData.role?.value;
            formData.lineManager = formData.lineManager?._id || null;
            await hrService.updateEmployee(selectedEmployee.id, formData);
            ToastTopEnd.fire({
                icon: 'success',
                title: TranslateHelper.getMessage('respond.message.update.successed')
            });
            closeModal();
            refetch();
            setIsClearable(true);
        } catch (error) {
            console.log(error);
            ToastTopEnd.fire({
                icon: 'error',
                title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
            });
        }
    }, [selectedEmployee, refetch, closeModal]);

    useEffect(() => {
        if (isClearable) {
            clearForm();
            setIsClearable(false);
        }
    }, [isClearable, clearForm]);

    const employeeStatus = [
        {
            value: 'enable',
            label: 'Enable'
        },
        {
            value: 'disable',
            label: 'Disable'
        }
    ];
    const [status, setStatus] = useState();

    useEffect(() => {
        if (selectedEmployee) {
            const { id, firstname, lastname, username } = selectedEmployee;
            setIsLoading(true);
            hookForm.setValue('id', id);
            hookForm.setValue('firstname', firstname);
            hookForm.setValue('lastname', lastname);
            hookForm.setValue('username', username);
            hrService.getEmployeeById(id).then(employee => {
                hookForm.setValue('title', titles.find(title => title.value === employee.title));
                hookForm.setValue('role', roles.find(roles => roles.value === employee.role));
                hookForm.setValue('jobTitle', employee.jobTitle);
                hookForm.setValue('businessId', employee.businessId);
                hookForm.setValue('lineManager', employee.lineManager);
                hookForm.setValue('departmentId', employee.departmentId);
                hookForm.setValue('slackId', employee.slackId);
                hookForm.setValue('larkId', employee.larkId);
                hookForm.setValue('is_support_working_leave', employee.is_support_working_leave);
                hookForm.setValue('leave_approver', employee.leave_approver);
                setStatus(employeeStatus.find(status => status.value === employee.status));
                hookForm.setValue('mobile', employee.mobile);
                hookForm.setValue('address', employee.address);
                hookForm.setValue(
                    'birthDay',
                    employee.birthDay ? new Date(employee.birthDay) : ''
                );
                hookForm.setValue('identify_id', employee.identify_id);
                hookForm.setValue(
                    'identify_issued_date',
                    employee.identify_issued_date ? new Date(employee.identify_issued_date) : ''
                );
                hookForm.setValue(
                    'identify_issued_by',
                    employee.identify_issued_by
                );
                hookForm.setValue(
                    'temporary_address',
                    employee.temporary_address
                );
                hookForm.setValue('bank_info', employee.bank_info);
                hookForm.setValue('tax_code', employee.tax_code);
                hookForm.setValue('bhxh_code', employee.bhxh_code);
                hookForm.setValue('contract_no', employee.contract_no);
                hookForm.setValue(
                    'contract_sign_date',
                    employee.contract_sign_date
                );
                hookForm.setValue(
                    'contract_1_date',
                    employee.contract_1_date ? new Date(employee.contract_1_date) : null
                );
                hookForm.setValue(
                    'contract_2_date',
                    employee.contract_2_date ? new Date(employee.contract_2_date) : null
                );
                hookForm.setValue(
                    'contract_3_date',
                    employee.contract_3_date ? new Date(employee.contract_3_date) : null
                );
                hookForm.setValue(
                    'contract_sign_times',
                    employee.contract_sign_times
                );
                hookForm.setValue(
                    'last_working_date',
                    employee.last_working_date ? new Date(employee.last_working_date) : null
                );
                // Tính toán số ngày làm việc
                const joinedDate = employee.joinedDate ? new Date(employee.joinedDate) : null;
                const today = new Date();

                const workingDays = joinedDate ? differenceInDays(today, joinedDate) : null;
                let formattedDays;
                if (workingDays) {
                    if (workingDays < 365) {
                        formattedDays = `${workingDays} days`;
                    } else {
                        const years = Math.floor(workingDays / 365);
                        const days = workingDays % 365;
                        formattedDays = `${years} year${years > 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''}`;
                    }
                }
                hookForm.setValue(
                    'working_date',
                    employee.joinedDate ? formattedDays : null
                );
                hookForm.setValue('joinedDate', joinedDate);
                hookForm.setValue('level_title', employee.level_title);
                hookForm.setValue('is_cv', employee.is_cv);
                hookForm.setValue('is_syll', employee.is_syll);
                hookForm.setValue(
                    'is_registration_book',
                    employee.is_registration_book
                );
                hookForm.setValue('is_identify_id', employee.is_identify_id);
                hookForm.setValue(
                    'is_job_application',
                    employee.is_job_application
                );
                hookForm.setValue('is_degree', employee.is_degree);
                hookForm.setValue(
                    'is_health_certification',
                    employee.is_health_certification
                );
                hookForm.setValue('is_portrait', employee.is_portrait);
                hookForm.setValue(
                    'contractEndedDay',
                    employee.contractEndedDay ? new Date(employee.contractEndedDay) : null
                );
                hookForm.setValue(
                    'internship_date',
                    employee.internship_date ? new Date(employee.internship_date) : null
                );
                hookForm.setValue(
                    'internship_type',
                    employee.internship_type ? internshipType.find(internType => internType.value === employee.internship_type) : null
                );
                setIsLoading(false);
            });
        }
    }, [selectedEmployee, hookForm.setValue]);
    return (
        <div className="modal" id="modal-employees" aria-labelledby="modal-employees" tabIndex="-1" role="dialog" aria-hidden="true" >
            <div className="d-flex justify-content-center align-items-center modal-dialog modal-scrollable mw-100 w-75" id="modal-employees">
                <div className="modal-content">
                    <div className="modal-header text-uppercase">
                        <h4 className="modal-title"><FormattedMessage id={isNew ? 'HRM.detail.HR.tab.name.createEmployees' : 'HRM.detail.HR.tab.name.editEmployees'}/></h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={hideModalButtonRef} onClick={() => { setIsClearable(true); modalEmployeesRef.current?.clearForm(); setSelectedEmployee(null); }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" >
                        <Tabs className="row">
                            <TabList>
                                <Tab><FormattedMessage id='HRM.detail.HR.tab.name.official' /></Tab>
                                <Tab><FormattedMessage id='HRM.detail.HR.tab.name.personal' /></Tab>
                            </TabList>
                            <div className="col-xl-10 col-8">
                                <TabPanel><OfficialForm status={status} internshipType={internshipType} titles={titles} roles={roles} refetch={refetch} isNew={isNew} selectedEmployee={selectedEmployee} closeModal={closeModal} isClearable={isClearable} setIsClearable={setIsClearable} hookForm={hookForm} handleCreateEmployee={handleCreateEmployee} isLoading={isLoading} handleUpdateEmployee={handleUpdateEmployee}/></TabPanel>
                                <TabPanel><Personal internshipType={internshipType} refetch={refetch} isNew={isNew} selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} closeModal={closeModal} hookForm={hookForm} handleUpdateEmployee={handleUpdateEmployee} handleCreateEmployee={handleCreateEmployee} isLoading={isLoading}/></TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}
