import React, { Component } from 'react';
import $ from 'jquery';

class RightSidebar extends Component {
    closeSidebar () {
        $('.sidebar-right').css({ width: '0' });
    }

    render () {
        return (
            <aside className="sidebar-right">
                <header className="sidebar-header">
                    <span className="closebtn" onClick={() => { this.closeSidebar(); }}>×</span>
                    <span className="text-heading-small">{this.props.title}</span>
                </header>
                <div className="sidebar-content">
                    {this.props.children}
                </div>
            </aside>
        );
    }
}

export default RightSidebar;
