import React, { Component } from 'react';
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import { FormattedMessage } from 'react-intl';
import overviewService from '../../services/overview.service';
import ReactPagination from '../../../ReactPagination';
import { withRouter } from 'react-router-dom';
import { PROJECT_ROLE } from '../../../../constants/project.constant';
class Overview extends Component {
    constructor (props) {
        super(props);
        this.state = {
            issueTracking: [],
            members: []
        };
    }

    componentDidMount () {
        this.fetchIssueTracking();
        this.fetchMembers();
    }

    fetchIssueTracking (page) {
        overviewService.getIssueTracking(this.props.projectId, page).then((res) => {
            if (res.status === 200) {
                this.setState({ issueTracking: res.data.data });
                this.forceUpdate();
            }
        });
    }

    fetchMembers (page) {
        overviewService.getProjectMember(this.props.projectId, page).then((res) => {
            if (res.status === 200) {
                this.setState({ members: res.data.data });
                this.forceUpdate();
            }
        });
    }

    render () {
        const renderIssueTrackingTableName = (data) => {
            return data.map((item, index) => {
                return (
                    <th key={index}>{item?.statusName}</th>
                );
            });
        };

        const renderDataIssueTracking = data => {
            return data.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item?.typeName}</td>
                        {renderDataIssueTrackingLv2(item?.typeName, data)}
                        <td>{item?.total}</td>
                    </tr>
                );
            });
        };

        const renderDataIssueTrackingLv2 = (typeName, data) => {
            const list = data.filter(item => item.typeName === typeName);
            return list[0].status.map((item, index) => {
                return (
                    <td key={index}>{item.amount}</td>
                );
            });
        };

        const displayRoleMember = roleId => {
            switch (roleId) {
                case PROJECT_ROLE.MANAGER:
                    return 'Manager';
                case PROJECT_ROLE.DEVELOPER:
                    return 'Developer';
                case PROJECT_ROLE.REPORTER:
                    return 'Reporter';
                case PROJECT_ROLE.GUEST:
                    return 'Guest';
                default:
                    break;
            }
        };

        const renderMemberTable = data => {
            return data.map(item => {
                return (
                    <tr key={item?._id}>
                        <td>{item.user?.firstname} {item.user?.lastname}</td>
                        <td>{item.user?.username}</td>
                        <td>{displayRoleMember(item.role)}</td>
                    </tr>
                );
            });
        };

        const countMemberRole = (roleId) => {
            const count = this.state.members.filter(item => item.role === roleId);
            if (count.length === 0) {
                return 0;
            } else {
                return count.length;
            }
        };

        return (
            <div className='container-fluid'>
                <div className='card'>
                    <div className="card-header">
                        <div className='card-title'>
                            <p className='font-weight-bold' >Issue tracking</p>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='table-scrollX'>
                            <table id="issue-trachking-list" className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="project.detail.table.column.name.type" /></th>
                                        {this.state.issueTracking.length === 0 ? null : renderIssueTrackingTableName(this.state.issueTracking[0].status)}
                                        <th><FormattedMessage id="project.detail.table.column.name.total" />{this.state.issueTracking.total}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderDataIssueTracking(this.state.issueTracking)}
                                </tbody>
                            </table>
                        </div>
                            { this.state.issueTracking?.totalPages > 1 &&
                                <ReactPagination
                                    totalPages={this.state.issueTracking?.totalPages}
                                    handlePageChange={(e, page) => this.fetchIssueTracking(this.props.projectId, page)}
                                    pageNumber={ this.state.issueTracking?.page }
                                />
                            }
                    </div>
                </div>
                <br/>
                <div className='card'>
                    <div className="card-header">
                        <div className='card-title'>
                            <p className='font-weight-bold' >Members</p>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='table-scrollX'>
                            <table id="members-list" className="table table-bordered table-hover mb-3">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="project.detail.table.column.name.name" /></th>
                                        <th><FormattedMessage id="project.detail.table.column.name.email" /></th>
                                        <th><FormattedMessage id="project.detail.table.column.name.role" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderMemberTable(this.state.members)}
                                </tbody>
                            </table>
                        </div>
                        { this.state.members.totalPages > 1 &&
                            <ReactPagination
                                totalPages={this.state.members?.totalPages}
                                handlePageChange={(e, page) => this.getProjectMember(this.props.projectId, page)}
                                pageNumber={ this.state.members?.page }
                            />
                        }
                    </div>
                </div>
                <div className='card'>
                    <div className="card-header">
                        <div className='card-title'>
                            <p className='font-weight-bold' >Summary</p>
                        </div>
                    </div>
                    <div className='card-body'>
                        <p className='font-weight-bold'>Total: <span>{this.state.members?.length}</span></p>
                        <p className='font-weight-bold'>Manager: <span>{countMemberRole(PROJECT_ROLE.MANAGER)}</span></p>
                        <p className='font-weight-bold'>Developer: <span>{countMemberRole(PROJECT_ROLE.DEVELOPER)}</span></p>
                        <p className='font-weight-bold'>Reporter: <span>{countMemberRole(PROJECT_ROLE.REPORTER)}</span></p>
                        <p className='font-weight-bold'>Guest: <span>{countMemberRole(PROJECT_ROLE.GUEST)}</span></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Overview);
