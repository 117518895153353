import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SandboxService from '../../../services/sandbox.service';

class SettingsSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            dataSet: null
        };
    }

    renderRowWebdavItem (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.path}</td>
                    <td>{item.operations}</td>
                </tr>
            );
        });
    }

    renderRowWebdav (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                        <span className="wordWrap">{item.clientId}</span>
                    </td>
                    <td>
                        <div className='table-scrollX'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="sandbox.detail.settings.path"/></th>
                                        <th><FormattedMessage id="sandbox.detail.settings.operations"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRowWebdavItem(item.permissions)}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    renderRowOcapiItem (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.methods.join(', ')}</td>
                    <td>{item.read_attributes}</td>
                    <td>{item.write_attributes}</td>
                    <td>{item.resource_id}</td>
                </tr>
            );
        });
    }

    renderRowOcapi (data) {
        return data.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                        <span className="wordWrap">{item.clientId}</span>
                    </td>
                    <td>
                        <div className='table-scrollX'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="sandbox.detail.settings.methods"/></th>
                                        <th><FormattedMessage id="sandbox.detail.settings.read"/></th>
                                        <th><FormattedMessage id="sandbox.detail.settings.write"/></th>
                                        <th><FormattedMessage id="sandbox.detail.settings.resource"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.renderRowOcapiItem(item.resources)}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount () {
        SandboxService.getSandboxSettings(this.props.sandboxId).then((res) => {
            this.setState({ dataSet: res.data.data, loadingStatus: true });
        });
    }

    render () {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}><h4><FormattedMessage id="sandbox.detail.tab.settings"/></h4></div>
                    {this.state.loadingStatus
                        ? (<div className="row">
                            {this.state.dataSet.ocapi
                                ? (<div className="col-sm-6">
                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}><h5><FormattedMessage id="sandbox.detail.settings.ocapi"/></h5></div>
                                    <div className='table-scrollX'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{ backgroundColor: '#007bff' }}>
                                                    <th style={{ width: 10 }}>#</th>
                                                    <th style={{ width: 50 }}><FormattedMessage id="sandbox.detail.settings.clientid"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.settings.resources"/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderRowOcapi(this.state.dataSet.ocapi)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>)
                                : (<></>)}
                            {this.state.dataSet.webdav
                                ? (<div className="col-sm-6">
                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}><h5><FormattedMessage id="sandbox.detail.settings.webdav"/></h5></div>
                                    <div className='table-scrollX'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{ backgroundColor: '#007bff' }}>
                                                    <th style={{ width: 10 }}>#</th>
                                                    <th style={{ width: 50 }}><FormattedMessage id="sandbox.detail.settings.clientid"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.settings.permissions"/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderRowWebdav(this.state.dataSet.webdav)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>)
                                : (<></>)}
                            {this.state.dataSet.ocapi && this.state.dataSet.webdav ? (<></>) : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                        </div>)
                        : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                </div>
            </div>
        );
    }
}

export default SettingsSbDetail;
