import serviceHelpers from '../../../helpers/serviceHelpers';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/';

class ActivitiesService {
    constructor () {
        serviceHelpers();
    }

    getActivities (id, page = 1, fromDate, toDate) {
        return axiosService.get(API_URL + id + '/activities', {
            params: {
                page, fromDate, toDate
            }
        }).then(response => response.data.data);
    }
}

export default new ActivitiesService();
