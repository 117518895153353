import moment from 'moment';
import serviceHelpers from '../../../helpers/serviceHelpers';
import TranslateHelper from '../../../helpers/TranslateHelper';
import axiosService from '../../../services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/hrm/';

class EmployeesService {
    constructor () {
        serviceHelpers();
        this.getEmployeeDetailByUsername = this.getEmployeeDetailByUsername.bind(this);
    }

    importEmployee (payload) {
        return axiosService.post(API_URL + 'employees/import-employee', payload).then((res) => res.data);
    }

    getGenderStaffs () {
        return axiosService.get(API_URL + 'employees/api-staff').then(res => res.data);
    }

    createEmployee (payload) {
        return axiosService.post(API_URL + 'employees', payload).then(res => res.data.data);
    }

    getEmployees (params) {
        return axiosService.get(API_URL + 'employees', {
            params
        }).then(res => res.data.data);
    }

    getMyteam () {
        return axiosService.get(API_URL + 'employees/myteam?limit=99').then(res => res.data.data);
    }

    getEmployeeById (id) {
        return axiosService.get(API_URL + 'employees/' + id).then(res => res.data.data);
    }

    getEmployeeDetailByUsername (username) {
        return this.getEmployees({ username }).then(data => data.items[0] || {})
            .then(({ id }) => this.getEmployeeById(id));
    }

    updateEmployee (id, payload) {
        return axiosService.patch(API_URL + 'employees/' + id, payload).then(res => res.data.data);
    }

    adjustAvailableLeaves (id, payload) {
        return axiosService.put(API_URL + 'employees/adjust-avaiable-leaves/' + id, payload).then(res => res.data);
    }

    deleteEmployeeById (id) {
        return axiosService.delete(API_URL + 'employees/' + id).then(res => res.data.data);
    }

    getUserLoginByDay () {
        return axiosService.get(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/track', { params: { limit: 9999999 } }).then(res => res.data.data);
    }

    getDaysInMonth () {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            const day = moment(new Date(date)).format(TranslateHelper.getMessage('date.format'));
            days[day] = 0;
            date.setDate(date.getDate() + 1);
        }
        return days;
    }
}

export default new EmployeesService();
