import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Gantt from './Gantt';
import issueService from '../../services/issue.service';
import moment from 'moment';
import FilterComponent from './FilterComponent';
import TranslateHelper from '../../../../helpers/TranslateHelper';

function GanttChartTab ({ projectId }) {
    const [granttChartData, setGanttChartData] = useState({
        data: []
    });
    const [filterOptions, setFilterOptions] = useState({});

    const getAllIssues = () => {
        return issueService.getAllIssues(projectId, filterOptions).then((response) => {
            const categories = response.items.reduce((result, category) => {
                if (category.categoryId && result.findIndex((item) => item._id === category.categoryId._id) === -1) {
                    result.push(category.categoryId);
                }
                return result;
            }, []).map((category) => ({
                id: category._id,
                text: category.categoryName,
                start_date: null,
                open: false,
                duration: null,
                type: 'project'
            }));

            const issues = response.items.map((issue) => ({
                id: issue._id,
                key: issue.issueCode,
                text: issue.subject,
                start_date: issue.startDate
                    ? moment(issue.startDate).format(TranslateHelper.getMessage('date.format'))
                    : moment(issue.createdAt).format(TranslateHelper.getMessage('date.format')),
                end_date: issue.dueDate
                    ? moment(issue.dueDate).format(TranslateHelper.getMessage('date.format'))
                    : moment(issue.createdAt).format(TranslateHelper.getMessage('date.format')),
                parent: issue.categoryId?._id || 0
            }));
            return {
                categories,
                issues
            };
        });
    };

    useEffect(() => {
        Promise.all([getAllIssues()]).then(([{ issues, categories }]) => {
            setGanttChartData({
                data: [...categories, ...issues]
            });
        });
    }, [projectId, filterOptions]);

    return (
        <div>
            <FilterComponent onSubmit={setFilterOptions} projectId={projectId} />
            <div className="py-4" style={{ height: '1000px' }}>
                <Gantt tasks={granttChartData} />
            </div>
        </div>
    );
}

export default GanttChartTab;
