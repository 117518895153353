import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import TranslateHelper from '../../../../helpers/TranslateHelper';

export default function EmployeesLeaveFilter ({ setFilters, setPage }) {
    const { register, watch, handleSubmit } = useForm({
        mode: 'onChange'
    });

    const searchButtonRef = useRef();

    const id = watch('id');
    const name = watch('lastname');

    const timeoutID = useRef();

    useEffect(() => {
        if (timeoutID.current) {
            clearTimeout(timeoutID.current);
            timeoutID.current = null;
        }
        timeoutID.current = setTimeout(handleFilter, 500);
    }, [id, name]);

    const handleFilter = useCallback(() => {
        if (timeoutID.current) {
            clearTimeout(timeoutID.current);
            timeoutID.current = null;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            id: id || undefined,
            lastname: name || undefined
        }));
        setPage(1);
    }, [id, name]);

    return (
        <div className="">
            <form onSubmit={handleSubmit(handleFilter)}>
                <div className="row mb-2">
                    <div className='input-group'>
                            <input
                                className='form-control border'
                                placeholder={TranslateHelper.getMessage('hrm.services.mydetail.table.column.name.name')}
                                {...register('lastname')}
                            />
                    </div>
                </div>
                <button ref={searchButtonRef} type='submit' className='invisible'>{TranslateHelper.getMessage('button.name.common.search')}</button>
            </form>
        </div>
    );
}
