import { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import TranslateHelper from '../../../helpers/TranslateHelper';
import { clearBreadcrumb, setBreadcrumb } from '../../../actions/breadcrumb.action';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import General from './General';
import Services from './Services';
import HR from './HR';
import Organization from './Organization';
import { useLocation } from 'react-router-dom';

function HRM ({ dispatch, role }) {
    const stateLocation = useLocation();
    useLayoutEffect(() => {
        document.title = TranslateHelper.getMessage('app.page.title.hrm');
        dispatch(setBreadcrumb({
            title: 'menu.manager.name.hrm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.manager.name.hrm',
                    haslink: false,
                    path: '/hrm'
                },
                {
                    label: 'app.breadcrumb.common.hrm.general',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        return () => {
            dispatch(clearBreadcrumb());
        };
    }, []);

    return (
        <div className="container-fluid">
                <Tabs className="column " defaultIndex={stateLocation.state?.OrganizationIdx ? stateLocation.state?.OrganizationIdx : stateLocation.state?.HrmIdx ? stateLocation.state?.HrmIdx : 0}>
                    <TabList className="d-flex tab-row">
                        <Tab>{TranslateHelper.getMessage('app.breadcrumb.common.hrm.general')}</Tab>
                        <Tab>{TranslateHelper.getMessage('app.breadcrumb.common.hrm.service')}</Tab>
                        <Tab>{TranslateHelper.getMessage('app.breadcrumb.common.hrm.hr')}</Tab>
                        <Tab>{TranslateHelper.getMessage('app.breadcrumb.common.hrm.organization')}</Tab>
                    </TabList>
                    <div>
                        <TabPanel>
                            <General dispatch={dispatch}/>
                        </TabPanel>
                        <TabPanel>
                            <Services dispatch={dispatch} servicesIdx= {stateLocation.state?.servicesIdx ? stateLocation.state?.servicesIdx : 0}/>
                        </TabPanel>
                        <TabPanel>
                            <HR dispatch={dispatch}/>
                        </TabPanel>
                        <TabPanel>
                            <Organization dispatch={dispatch} departmentsIdx= {stateLocation.state?.departmentsIdx ? stateLocation.state?.departmentsIdx : 0}/>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return {
        role
    };
}

export default connect(mapStateToProps)(HRM);
