import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const TableComponent = ({ data, offices, deviceType, status }) => {
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(118 55 255 / 10%)',
            color: 'rgb(115, 66, 222)',
            fontSize: 14
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '&:last-child td, &:last-child th': {
            border: 0
        },
        '& th, & td': {
            borderBottom: '1px solid rgb(118 55 255 / 10%)'
        }
    }));

    const thElements = ['Mã', 'Loại thiết bị', 'Văn phòng', 'Ngày mua', 'Giá trị', 'Tình trạng máy', ''];
    return (
        <TableContainer className='device-table' component={Paper}>
            <Table sx={{ minWidth: 700 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Tên máy</StyledTableCell>
                        {thElements.map((item, i) => (
                            <StyledTableCell key={i} align="center">{item}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.items?.map((row, i) => {
                        const office = offices.find((item) => item.value === row.office);
                        const type = deviceType.find((item) => item.value === row.type);
                        const deviceStatus = status.find((item) => item.value === row.status);
                        return (
                            <StyledTableRow key={i}>
                                <StyledTableCell component="th" scope="row"><Link to={'/device/' + row._id}>{row.name}</Link></StyledTableCell>
                                <StyledTableCell align="center">{row.code}</StyledTableCell>
                                <StyledTableCell align="center">{type.label}</StyledTableCell>
                                <StyledTableCell align="center">{office.label}</StyledTableCell>
                                <StyledTableCell align="center">{row.buy_date ? moment(row.buy_date).format('DD/MM/YYYY') : ''}</StyledTableCell>
                                <StyledTableCell align="center">{numeral(row.price).format('0,0')}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <span className={
                                        deviceStatus.value === '1'
                                            ? 'red'
                                            : deviceStatus.value === '2'
                                                ? 'green'
                                                : 'blue'
                                    }>
                                        {deviceStatus.label}
                                    </span>
                                </StyledTableCell>

                                <StyledTableCell className="d-flex justify-content-center align-items-center">
                                    <Link to={'/device/' + row._id}>
                                        <button className='visible-btn'>
                                            <VisibilityIcon className='visible' />
                                        </button>
                                    </Link>
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
