import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';
import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
import issueService from '../../../services/issue.service';
import Button from '@atlaskit/button';

export default function IssueTypeDetail ({ projectId, issueType, fetchIssueTypes }) {
    const hideModalButtonRef = useRef();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'onChange'
    });

    const handleSave = useCallback(async (formData) => {
        const payload = {
            issueType: formData.issueType.trim()
        };
        if (payload.issueType) {
            try {
                await issueService.editIssueType(projectId, issueType._id, payload);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                hideModalButtonRef.current?.click();
                return fetchIssueTypes();
            } catch (error) {
                console.warn(error);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                        error.response.data.message.issueType ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
        } else {
            setError('issueType');
        }
    }, [hideModalButtonRef, issueType._id]);

    useEffect(() => {
        setValue('issueType', issueType.issueType);
        return () => {
            clearErrors('issueType');
        };
    }, [issueType, setValue, fetchIssueTypes]);

    return (
        <div className="modal fade" id={'modal-issuetype-' + issueType._id}>
            <div className="modal-dialog modal-sm">
                <form onSubmit={handleSubmit(handleSave)} className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <FormattedMessage id="project.detail.settings.tabs.name.issuetype" />
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group row">
                            <label htmlFor="inputType" className="col-sm-3 col-form-label">
                                <FormattedMessage id="project.detail.settings.issuetype.table.column.name.type" />
                            </label>
                            <div className="col-sm-9">
                                <input
                                    placeholder="Name of type ?"
                                    className={
                                        errors.issueType
                                            ? 'is-invalid form-control'
                                            : 'form-control'
                                    }
                                    {...register('issueType', { required: TranslateHelper.getMessage('validation.error.required'), maxLength: { value: 25, message: TranslateHelper.getMessage('validation.error.max25characters') } })}
                                />
                                {errors.issueType && (
                                    <span className="error text-danger text-wrap">
                                        {errors.issueType.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-end">
                        <Button
                            type="button"
                            ref={hideModalButtonRef}
                            data-dismiss="modal"
                        >
                            <FormattedMessage id="button.name.common.cancel" />
                        </Button>
                        <Button
                            type="submit"
                            appearance='primary'
                            disabled={!isDirty || !isValid}
                        >
                            {TranslateHelper.getMessage('button.name.common.save')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
