import { useCallback, useLayoutEffect, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
import { setBreadcrumb, clearBreadcrumb } from '../../../common/actions/breadcrumb.action';
import activityService from '../Services/activity.service';
import { ROLES } from '../../../common/constants/user.constant';
import { useService } from '../../../common/helpers/serviceHelpers';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomInputDate } from '../../../common/components/common/CustomInputDatepicker';
import { useLocation } from 'react-router-dom';
import ActivitiesComponent from '../../../common/components/common/Activities';
import Button from '@atlaskit/button';

function Activity ({ dispatch, isFilter = true, role }) {
    useLayoutEffect(() => {
        document.title = TranslateHelper.getMessage('app.page.title.activity');
        dispatch(
            setBreadcrumb({
                title: 'menu.manager.name.activity',
                breadcrumbItems: [
                    {
                        label: 'app.breadcrumb.common.home',
                        haslink: true,
                        path: '/dashboard'
                    },
                    {
                        label: 'menu.manager.name.activity',
                        haslink: false,
                        path: ''
                    }
                ]
            })
        );
        return () => {
            dispatch(clearBreadcrumb());
        };
    }, []);

    const lang = localStorage.getItem('lang');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState(new Date());

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        onTop();
    }, [routePath]);

    const [filters, setFilters] = useState({
        fromDate: undefined,
        toDate: undefined,
        page: 1,
        limit: !isFilter ? 6 : undefined
    });

    const { data = {}, isLoading } = ((role === ROLES.MANAGER || role === ROLES.PARTNEROWNER)
        ? useService(
            activityService.getAllActivities,
            filters
        )
        : useService(
            activityService.getActivities,
            filters
        ));

    const { items: activities = [], totalPages = 0 } = data || {};

    const handleSearchClick = useCallback(() => {
        const fromDateSearch = fromDate ? moment(fromDate).startOf('day').toISOString() : undefined;
        const toDateSearch = moment(toDate).endOf('day').toISOString();
        setFilters((prevState) => ({
            ...prevState,
            fromDate: fromDateSearch,
            toDate: toDateSearch,
            page: 1
        }));
    }, [fromDate, toDate]);

    const handlePageChange = useCallback((event, page) => {
        window.scrollTo(0, 0);
        setFilters((prevState) => ({ ...prevState, page }));
    }, []);

    return (
        <div className={isFilter ? 'container-fluid' : ''}>
            <div className={isFilter ? 'row mb-4 ' : 'row mb-4 d-none'}>
                <div className="col-xl-6 col-12">
                    <div className="row">
                        <div className="col-4 col-xl-5">
                            <label>
                                <FormattedMessage id="project.detail.activites.label.from" />
                            </label>
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={fromDate}
                                onChange={setFromDate}
                                locale={lang}
                                customInput={<CustomInputDate />}
                                maxDate={toDate}
                            />
                        </div>
                        <div className="col-4 col-xl-5">
                            <label>
                                <FormattedMessage id="project.detail.activites.label.to" />
                            </label>
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={toDate}
                                onChange={setToDate}
                                locale={lang}
                                customInput={<CustomInputDate />}
                                minDate={fromDate}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-2 col-xl-2">
                            <Button
                                className="search-btn-activities"
                                appearance='primary'
                                onClick={handleSearchClick}
                            >
                                <FormattedMessage id="button.name.common.search" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ActivitiesComponent Activities={activities} isLoading={isLoading} isFilter={isFilter} totalPages={totalPages} handlePageChange={handlePageChange} filters={filters}/>
        </div>
    );
}

function mapStateToProps (state) {
    const { role } = state.auth;
    return { role };
}

export default connect(mapStateToProps)(Activity);
