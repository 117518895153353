import serviceHelpers from '../../../../common/helpers/serviceHelpers';
import axiosService from '../../../../common/services/axios.service';
const API_URL_PK = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/packages';

class PackageService {
    constructor () {
        serviceHelpers();
    }

    getAllPackage (page) {
        return axiosService.get(API_URL_PK, { params: { page } }).then((res) => res.data.data);
    }

    getOnePackage (id) {
        return axiosService.get(API_URL_PK + '/' + id).then((res) => res.data.data);
    }

    createPackage (payload) {
        return axiosService.post(API_URL_PK, payload);
    }

    editPackage (id, payload) {
        return axiosService.patch(API_URL_PK + '/' + id, payload);
    }

    deletePackage (id) {
        return axiosService.delete(API_URL_PK + '/' + id);
    }
}

export default new PackageService();
