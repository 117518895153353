import { Skeleton, Stack } from '@mui/material';
function SkeletonLoad ({ rows, columns, space, w, h }) {
    return (
    <>
      <div className='row'>
        {new Array(rows).fill(null).map(row => (new Array(columns).fill(null).map(column => (
        <div className={`col-xl-${Math.ceil(12 / Number(columns))}`} key={column}>
          <Stack space={space} key={column}>
            <Skeleton style={{ width: w, height: h }}/>
          </Stack>
        </div>
        ))
        ))}
      </div>
    </>
    );
}
export default SkeletonLoad;
