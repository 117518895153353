import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import SetStore from './index.store';
import MasterWapper from './modules/common/containers/MasterWapper';

const store = SetStore();
const isLogin = store.getState().auth.isLoggedIn;
const domID = isLogin ? 'root' : 'login';

ReactDOM.render(
    <Provider store={store}>
        <MasterWapper></MasterWapper>
    </Provider>,
    document.getElementById(domID)
);

serviceWorker.unregister();
