import './ModalLogin.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import authService from '../../common/services/auth.service';
import { loginByGoogle } from '../../common/actions/auth.action';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import GoogleLogin from 'react-google-login';
import { ToastTopEnd } from '../../common/helpers/ToastTimer';
import {
    LIMIT_LEAVE_DAYS,
    LIMIT_WFH_DAYS
} from '../../common/constants/setting.constant';

export default function ModalLogin ({ dispatch }) {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        setIsSubmitting(true);
        setError('');
        authService
            .loginByUsername(data.username, data.password)
            .then((res) => {
                const data = res.data.data;
                const dataUser = {
                    name: data.user.firstname + ' ' + data.user.lastname,
                    firstname: data.user.firstname,
                    lastname: data.user.lastname,
                    email: data.user.username,
                    noSandboxDisplay:
                        data.user.setting.member.sandbox.noSandboxDisplay,
                    imageUrl: data.user.picture,
                    switchRole: data.user.switchRole,
                    userId: data.user._id,
                    id: data.user.id,
                    contract_2_date: data.user.contract_2_date,
                    contract_3_date: data.user.contract_3_date
                };
                const user = {
                    ...dataUser,
                    limitDaysLeave: data.settingsDefault[LIMIT_LEAVE_DAYS],
                    limitDayWFH: data.settingsDefault[LIMIT_WFH_DAYS]
                };
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('lang', data.user?.setting?.language || 'vi');
                Cookies.set('accessToken', res.data.accessToken, {
                    expires: 7
                });
                dispatch(
                    loginByGoogle({
                        user,
                        accessToken: res.data.accessToken
                    })
                );
            })
            .catch((err) => {
                if (err.response?.data) {
                    const data = err.response.data;
                    setError(data.message);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const responseGoogleFailure = (response) => {
    };

    const responseGoogleSuccess = (response) => {
        let tokenRegister = new URL(window.location.href).searchParams.get(
            'tokenRegister'
        );
        tokenRegister = tokenRegister ? '?tokenRegister=' + tokenRegister : '';
        if (response.tokenId) {
            const tokenId = response.tokenId;
            authService
                .loginByGoogle(tokenId, tokenRegister)
                .then((res) => {
                    const data = res.data.data;
                    const dataUser = {
                        name: data.user.firstname + ' ' + data.user.lastname,
                        firstname: data.user.firstname,
                        lastname: data.user.lastname,
                        email: data.user.username,
                        noSandboxDisplay:
                            data.user.setting.member.sandbox.noSandboxDisplay,
                        imageUrl: data.user.picture,
                        switchRole: data.user.switchRole,
                        userId: data.user._id,
                        id: data.user.id,
                        contract_2_date: data.user.contract_2_date,
                        contract_3_date: data.user.contract_3_date
                    };
                    const user = {
                        ...dataUser,
                        limitDaysLeave: data.settingsDefault[LIMIT_LEAVE_DAYS],
                        limitDayWFH: data.settingsDefault[LIMIT_WFH_DAYS]
                    };
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('lang', data.user?.setting?.language || 'vi');
                    Cookies.set('accessToken', res.data.accessToken, {
                        expires: 7
                    });
                    dispatch(
                        loginByGoogle({
                            user,
                            accessToken: res.data.accessToken
                        })
                    );
                })
                .catch((err) => {
                    if (err.response?.data) {
                        const data = err.response.data;
                        ToastTopEnd.fire({
                            icon: 'error',
                            title: data.message
                        });
                    }
                });
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        reset();
        setError('');
    };

    return (
        <React.Fragment>
            <button className='button-login' onClick={handleClickOpen}>
                Login
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <div className="modal-login">
                        <div>
                            <img
                                src={'/images/kimeilogo.png'}
                                alt="sticky brand-logo"
                                style={{
                                    height: '40px',
                                    display: 'block',
                                    margin: '0 auto'
                                }}
                            />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} id="login-form">
                            {error && (
                                <Alert style={{ marginBottom: '10px' }} severity="error">{error}</Alert>)}
                            <div className="input-group">
                                <label className="label">
                                    Username <span>*</span>
                                </label>
                                <input
                                    {...register('username', {
                                        required: true
                                    })}
                                    placeholder="Enter username"
                                    className="input"
                                />
                                {errors.username && (
                                    <span className="error-message">
                                        Username is required
                                    </span>
                                )}
                            </div>
                            <div className="input-group">
                                <label className="label">
                                    Password <span>*</span>
                                </label>
                                <input
                                    {...register('password', {
                                        required: true,
                                        minLength: 8
                                    })}
                                    placeholder="Enter password"
                                    type="password"
                                />
                                {errors.password && (
                                    <span className="error-message">
                                        Password is required and must be at
                                        least 8
                                    </span>
                                )}
                            </div>
                            <div>
                            <Button
                                disabled={isSubmitting}
                                className="submit-button"
                                variant="contained"
                                type="submit"
                            >
                                {isSubmitting ? 'Pending...' : 'Login'}
                            </Button>
                            <div className='devide'>
                                <span>Or</span>
                            </div>
                            <div className='btn-google'>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GG_CLIENT_ID}
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleFailure}
                                    cookiePolicy={'single_host_origin'}
                                    buttonText={
                                        <FormattedMessage id="app.button.login" />
                                    }
                                />
                            </div>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
