import axiosService from '../services/axios.service';
import Cookies from 'js-cookie';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';

const serviceHelpers = () => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
        axiosService.setHeader('x-access-token', 'Bearer ' + accessToken);
    }
};

export const useService = (method, params = {}, pipe = undefined, options = {}) => {
    const [data, setData] = useState({
        isLoading: true,
        data: ('defaultValue' in options) ? options.defaultValue : [],
        error: undefined
    });
    const memorizedOptions = useDeepCompareMemoize(options);
    const memorizedParams = useDeepCompareMemoize(params);

    const fetch = useCallback(async () => {
        if (memorizedOptions) {
            if ('enabled' in memorizedOptions && !memorizedOptions.enabled) {
                return;
            }
        }
        try {
            setData(prevData => ({
                ...prevData,
                isLoading: true,
                error: undefined
            }));
            let response = await method(memorizedParams);
            if (pipe) {
                response = await Promise.resolve(pipe(response));
            }
            setData(prevData => ({
                ...prevData,
                isLoading: false,
                data: response
            }));
        } catch (error) {
            console.warn(error);
            // setData(prevData => ({
            //     ...prevData,
            //     isLoading: false,
            //     error
            // }));
        }
    }, [memorizedParams, memorizedOptions]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return useMemo(() => ({
        ...data,
        refetch: fetch
    }), [data, fetch]);
};

const defaultDataValue = {
    isLoading: true,
    total: 0,
    items: [],
    error: undefined
};

export const useLoadMoreService = (method, params = {}, pipe = undefined, options = {}) => {
    const nextPage = useRef(1);
    const [data, setData] = useState(defaultDataValue);
    const memorizedOptions = useDeepCompareMemoize(options);
    const memorizedParams = useDeepCompareMemoize(params);

    const fetch = useCallback(async (force) => {
        if (memorizedOptions) {
            if ('enabled' in memorizedOptions && !memorizedOptions.enabled) {
                return;
            }
        }
        setData(prevData => ({
            ...prevData,
            isLoading: true,
            error: undefined,
            items: force === true ? [] : prevData.items
        }));
        try {
            let response = await method({
                ...memorizedParams,
                page: force === true ? 1 : nextPage.current
            });
            if (pipe) {
                response = await Promise.resolve(pipe(response));
            }
            nextPage.current = response.page + 1;
            setData(prevData => ({
                ...prevData,
                isLoading: false,
                items: [...prevData.items, ...response.items],
                total: response.total
            }));
        } catch (error) {
            console.warn(error);
            setData(prevData => ({
                ...prevData,
                isLoading: false,
                error
            }));
        }
    }, [memorizedParams, memorizedOptions]);

    useEffect(() => {
        fetch(true);
    }, [fetch]);

    return useMemo(() => ({
        ...data,
        nextPage,
        fetch
    }), [data, fetch]);
};

export default serviceHelpers;
