import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import Select from '@atlaskit/select';
import Swal from 'sweetalert2';
import { CustomInputDate, CustomInputTime } from '../../../common/CustomInputDatepicker';
import { setBreadcrumb } from '../../../../actions/breadcrumb.action';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import RealmService from '../../services/realm.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';

class RealmDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            dataSetConf: null,
            dataSetUsage: null,
            loadingConfStatus: false,
            loadingUsageStatus: false,
            isError: {
                defaultValue: '',
                maximum: '',
                fromDate: '',
                toDate: ''
            },
            form: {
                fromDate: '',
                toDate: new Date()
            },
            filterOptions: [
                { value: 'MONDAY', label: TranslateHelper.getMessage('option.common.monday') },
                { value: 'TUESDAY', label: TranslateHelper.getMessage('option.common.tuesday') },
                { value: 'WEDNESDAY', label: TranslateHelper.getMessage('option.common.wednesday') },
                { value: 'THURSDAY', label: TranslateHelper.getMessage('option.common.thursday') },
                { value: 'FRIDAY', label: TranslateHelper.getMessage('option.common.friday') },
                { value: 'SATURDAY', label: TranslateHelper.getMessage('option.common.saturday') },
                { value: 'SUNDAY', label: TranslateHelper.getMessage('option.common.sunday') }
            ]
        };
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
        this.handleShowClick = this.handleShowClick.bind(this);
        this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
        this.formValChange = this.formValChange.bind(this);
    }

    /**
     * Load API
     */
    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.sandbox.realm.detail');
        const { dispatch, match } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.member.name.sandbox.realm',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.member.name.sandbox.realm',
                    haslink: true,
                    path: '/realm-management'
                },
                {
                    label: 'menu.member.name.sandbox.realm.detail',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        RealmService.getRealmConfiguration(match.params.realmId).then((res) => {
            const { form } = this.state;
            const data = res.data.data;
            const formConf = {
                maximum: data.sandbox.sandboxTTL.maximum,
                defaultValue: data.sandbox.sandboxTTL.defaultValue,
                startWeekdays: this.generateOption(data.startScheduler.weekdays),
                startTime: this.generateTime(data.startScheduler.time),
                stopWeekdays: this.generateOption(data.stopScheduler.weekdays),
                stopTime: this.generateTime(data.stopScheduler.time)
            };
            this.setState({ dataSetConf: res.data.data, loadingConfStatus: true, form: { ...formConf, ...form } });
        }).catch((err) => {
            console.log(err.response);
        });
        RealmService.getRealmUsage(match.params.realmId).then((res) => {
            this.setState({ dataSetUsage: res.data.data, loadingUsageStatus: true });
        }).catch((err) => {
            console.log(err.response);
        });
    }

    generateTime (data) {
        const array = data.split(':');
        return new Date(null, null, null, array[0], array[1]);
    }

    generateOption (data) {
        const result = [];
        data.map((item, index) => {
            this.state.filterOptions.map((it, id) => {
                if (item === it.value) {
                    result.push(it);
                }
                return 0;
            });
            return 0;
        });
        return result;
    }

    handleMultiChange (name, option) {
        const { form, isError } = this.state;
        form[name] = option;
        isError[name] = '';
        this.setState({ form });
    }

    handleChangeDatePicker (name, date) {
        const { form, isError } = this.state;
        form[name] = date;
        isError[name] = '';
        this.setState({ form });
    }

    formValidation (section = 'conf') {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            if (section === 'conf') {
                switch (key) {
                    case 'defaultValue':
                        if (value === '') {
                            isError.defaultValue = TranslateHelper.getMessage('validation.error.required');
                            validation = false;
                        }
                        break;
                    case 'maximum':
                        if (value === '') {
                            isError.maximum = TranslateHelper.getMessage('validation.error.required');
                            validation = false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                switch (key) {
                    case 'toDate':
                        if (value === '') {
                            isError.toDate = TranslateHelper.getMessage('validation.error.required');
                            validation = false;
                        }
                        break;
                    case 'fromDate':
                        if (value === '') {
                            isError.fromDate = TranslateHelper.getMessage('validation.error.required');
                            validation = false;
                        }
                        break;
                    default:
                        break;
                }
            }
            return 0;
        });
        return validation;
    }

    formValChange (name, event) {
        const form = { ...this.state.form };
        const isError = { ...this.state.isError };
        form[name] = event.target.value.trim();
        switch (name) {
            case 'defaultValue':
                isError.defaultValue = '';
                break;
            case 'maximum':
                isError.maximum = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    handleShowClick () {
        if (this.formValidation('usage')) {
            const { form } = this.state;
            const toDate = new Date(form.toDate).toLocaleDateString('en-CA');
            const fromDate = new Date(form.fromDate).toLocaleDateString('en-CA');
            const queryString = `?from=${fromDate}&to=${toDate}`;
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                    RealmService.getRealmUsage(this.props.match.params.realmId, queryString).then((res) => {
                        Swal.close();
                        this.setState({ dataSetUsage: res.data.data });
                    }).catch((error) => {
                        Swal.close();
                        if (error.response.data) {
                            ToastTopEnd.fire({
                                icon: 'error',
                                title: TranslateHelper.getMessage('respond.message.failed')
                            });
                        }
                    });
                }
            });
        }
        this.forceUpdate();
    }

    async handleUpdateClick () {
        if (this.formValidation()) {
            const { form } = this.state;
            const { match } = this.props;
            const startWeekdays = [];
            const stoptWeekdays = [];
            form.startWeekdays.map((item, index) => {
                startWeekdays.push(item.value);
                return 0;
            });
            form.stopWeekdays.map((item, index) => {
                stoptWeekdays.push(item.value);
                return 0;
            });
            const payload = {
                sandbox: {
                    sandboxTTL: {
                        maximum: form.maximum,
                        defaultValue: form.defaultValue
                    },
                    stopScheduler: {
                        weekdays: stoptWeekdays,
                        // time: moment(form.stopTime).format('HH : mm') // format
                        time: form.stopTime.toTimeString().replace(/([\d:]+).*((\+|-)\d{2})(\d{2})(.*)/, '$1$2:$4')
                    },
                    startScheduler: {
                        weekdays: startWeekdays,
                        time: form.startTime.toTimeString().replace(/([\d:]+).*((\+|-)\d{2})(\d{2})(.*)/, '$1$2:$4')
                    }
                }
            };
            try {
                await RealmService.setRealmConfiguration(match.params.realmId, payload);
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
            } catch (err) {
                console.log(err);
                ToastTopEnd.fire({
                    icon: 'error',
                    title:
                    err.response?.data?.error?.message ||
                        TranslateHelper.getMessage('respond.message.failed')
                });
            }
            // RealmService.setRealmConfiguration(match.params.realmId, payload).then((res) => {
            // }).catch((err) => {
            //     console.log(err.response);
            // });
        }
        this.forceUpdate();
    }

    render () {
        const lang = localStorage.getItem('lang');
        const { match } = this.props;
        const { isError } = this.state;
        return (
            <div className="container-fluid">
                <h5 className="text-left" style={{ color: '#007bff' }}><FormattedMessage id="realm.detail.label.realmDetail"/>: {match.params.realmId}</h5>
                <form>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title"><FormattedMessage id="realm.detail.label.configurations"/></h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                            <i className="fas fa-minus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                {this.state.loadingConfStatus
                                    ? (<div>
                                        <p><FormattedMessage id="realm.detail.label.totalNoSandboxes"/>: <span>50</span></p>
                                        <p><FormattedMessage id="realm.detail.label.sandboxesTTL"/>:</p>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label" htmlFor="inputMaximun"><FormattedMessage id="realm.detail.label.Maximun"/></label>
                                            <div className="col-sm-9">
                                                <input
                                                    onChange={this.formValChange.bind(this, 'maximum')}
                                                    type="text"
                                                    defaultValue={this.state.form.maximum}
                                                    className={isError.maximum.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                                />
                                                {isError.maximum.length > 0 && (<span className="error invalid-feedback">{isError.maximum}</span>)}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label" htmlFor="inputDefautl"><FormattedMessage id="realm.detail.label.defaultValue"/></label>
                                            <div className="col-sm-9">
                                                <input
                                                    onChange={this.formValChange.bind(this, 'defaultValue')}
                                                    type="text"
                                                    className={isError.defaultValue.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                                    defaultValue={this.state.form.defaultValue}
                                                />
                                                {isError.defaultValue.length > 0 && (<span className="error invalid-feedback">{isError.defaultValue}</span>)}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label" htmlFor="inputStart"><FormattedMessage id="realm.detail.label.start"/></label>
                                            <div className="col-sm-9 row">
                                                <div className="col-8">
                                                <Select
                                                    name="startWeekdays"
                                                    placeholder="Filters"
                                                    value={this.state.form.startWeekdays}
                                                    options={this.state.filterOptions}
                                                    onChange={this.handleMultiChange.bind(this, 'startWeekdays')}
                                                    isMulti
                                                />
                                                </div>
                                                <div className="col-4">
                                                    <DatePicker
                                                        selected={this.state.form.startTime}
                                                        onChange={this.handleChangeDatePicker.bind(this, 'startTime')}
                                                        popperPlacement="right-start"
                                                        locale={lang}
                                                        name="startTime"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={5}
                                                        timeCaption="Gio"
                                                        dateFormat="h:mm"
                                                        customInput={<CustomInputTime/>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label" htmlFor="inputStop"><FormattedMessage id="realm.detail.label.stop"/></label>
                                            <div className="col-sm-9 row">
                                                <div className="col-8">
                                                <Select
                                                    name="stopWeekdays"
                                                    placeholder="Filters"
                                                    value={this.state.form.stopWeekdays}
                                                    options={this.state.filterOptions}
                                                    onChange={this.handleMultiChange.bind(this, 'stopWeekdays')}
                                                    isMulti
                                                />
                                                </div>
                                                <div className="col-4">
                                                    <DatePicker
                                                        selected={this.state.form.stopTime}
                                                        onChange={this.handleChangeDatePicker.bind(this, 'stopTime')}
                                                        popperPlacement="right-start"
                                                        locale={lang}
                                                        name="stopTime"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={5}
                                                        timeCaption="Gio"
                                                        dateFormat="h:mm"
                                                        customInput={<CustomInputTime/>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '100%', marginTop: '30px' }}>
                                            <button type="button" onClick={() => { this.handleUpdateClick(); }} className="btn btn-width-150 btn-success"><FormattedMessage id="button.name.common.update"/></button>
                                        </div>
                                    </div>)
                                    : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title"><FormattedMessage id="realm.detail.label.usage"/></h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                            <i className="fas fa-minus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                {this.state.loadingUsageStatus
                                    ? (<div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label className=""><FormattedMessage id="realm.detail.label.from"/></label>
                                                <div className="input-group date">
                                                    <DatePicker
                                                        selected={this.state.form.fromDate}
                                                        onChange={this.handleChangeDatePicker.bind(this, 'fromDate')}
                                                        popperPlacement="bottom"
                                                        locale={lang}
                                                        name="fromDate"
                                                        customInput={<CustomInputDate />}
                                                    />
                                                </div>
                                                <div className={isError.fromDate.length > 0 ? 'is-invalid' : ''}></div>
                                                {isError.fromDate.length > 0 && (<span className="error invalid-feedback">{isError.fromDate}</span>)}
                                            </div>
                                            <div className="col-sm-6">
                                                <label className=""><FormattedMessage id="realm.detail.label.to"/>:</label>
                                                <div className="input-group date">
                                                    <DatePicker
                                                        selected={this.state.form.toDate}
                                                        onChange={this.handleChangeDatePicker.bind(this, 'toDate')}
                                                        popperPlacement="bottom"
                                                        locale={lang}
                                                        name="toDate"
                                                        customInput={<CustomInputDate />}
                                                    />
                                                </div>
                                                <div className={isError.toDate.length > 0 ? 'is-invalid' : ''}></div>
                                                {isError.toDate.length > 0 && (<span className="error invalid-feedback">{isError.toDate}</span>)}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <label className="">&emsp;</label>
                                            <button type="button" onClick={() => { this.handleShowClick(); }} className="btn btn-primary"><FormattedMessage id="button.name.common.show" /></button>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.createdSandboxes"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.createdSandboxes}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.activeSandboxes"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.activeSandboxes}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.deletedSandboxes"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.deletedSandboxes}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.createdSeconds"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.sandboxSeconds}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.minutesUp"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.minutesUp}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-4"><FormattedMessage id="realm.detail.label.minutesDown"/>:</p>
                                            <div className="col-8">
                                                <span>{this.state.dataSetUsage.minutesDown}</span>
                                            </div>
                                        </div>
                                    </div>)
                                    : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(RealmDetail);
