import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import validator from 'validator';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PartnerService from '../services/partner.service';
import { LanguageDatatables } from '../../../../lang';
import TranslateHelper from '../../../common/helpers/TranslateHelper';
// import RightSidebar from '../../../common/components/common/RightSidebar';
import { setBreadcrumb, clearBreadcrumb } from '../../../common/actions/breadcrumb.action';
import { ToastTopEnd } from '../../../common/helpers/ToastTimer';
import Select from '@atlaskit/select';
import { countries } from '../../../../resources/country';
import ReactPagination from '../../../common/components/ReactPagination';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { regexName } from '../../../common/constants/validation.constant';
import Button from '@atlaskit/button';
class Partner extends Component {
    constructor (props) {
        super(props);
        this.state = {
            sidebarState: 'create',
            dataSet: [],
            loadingStatus: false,
            form: {
                id: '',
                longName: '',
                shortName: '',
                email: '',
                ownerEmail: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                country: '',
                address: ''
            },
            isError: {
                id: '',
                longName: '',
                shortName: '',
                email: '',
                ownerEmail: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                country: '',
                address: ''
            },
            package: []
        };
        this.initDatatable = this.initDatatable.bind(this);
        this.closeModal = createRef();
    }

    componentDidMount () {
        document.title = TranslateHelper.getMessage('app.page.title.partner');
        const { dispatch } = this.props;
        dispatch(setBreadcrumb({
            title: 'menu.admin.name.partner',
            breadcrumbItems: [
                {
                    label: 'app.breadcrumb.common.home',
                    haslink: true,
                    path: '/dashboard'
                },
                {
                    label: 'menu.admin.name.partner',
                    haslink: false,
                    path: ''
                }
            ]
        }));
        this.getListPartner();
    }

    componentWillUnmount () {
        const { dispatch } = this.props;
        dispatch(clearBreadcrumb());
    }

    openSidebar () {
        const width = $(window).width();
        if (width <= 786) {
            $('.sidebar-right').css({ width: '100%' });
        } else {
            $('.sidebar-right').css({ width: '450px' });
        }
    }

    onClickCreate () {
        this.setState({
            form: {
                id: '',
                longName: '',
                shortName: '',
                email: '',
                ownerEmail: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                country: '',
                address: ''
            },
            isError: {
                id: '',
                longName: '',
                shortName: '',
                email: '',
                ownerEmail: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                country: '',
                address: ''
            }
        });
        this.openSidebar();
    }

    initDatatable () {
        const lang = localStorage.getItem('lang');
        $(document).ready(function () {
            const table = $('#partner-list').DataTable({
                paging: true,
                lengthChange: false,
                searching: true,
                ordering: true,
                info: false,
                autoWidth: true,
                responsive: true,
                columns: [
                    null,
                    null,
                    null,
                    null,
                    { width: '5%' },
                    null
                ],
                columnDefs: [
                    { className: 'dt-center', targets: [4, 5] },
                    { orderable: false, targets: [2, 5] }
                ],
                language: LanguageDatatables[lang]
            });
            this.setState({ table });
        }.bind(this));
    }

    getListPartner (page = 1) {
        PartnerService.getListPartner(page).then((res) => {
            if (res.status === 200) {
                this.setState({ dataSet: res.data.data, loadingStatus: true });
                this.initDatatable();
                this.forceUpdate();
            }
        });
    }

    // getListPackage (page = 1) {
    //     packageService.getAllPackage
    // }

    handleCreatePartner () {
        const { form } = this.state;
        if (this.handleValidationForm()) {
            const payload = {
                longName: form.longName.trim(),
                shortName: form.shortName.trim(),
                email: form.email.trim(),
                phoneNumber: form.phoneNumber.trim(),
                country: form.country.value,
                address: form.address.trim(),
                ownerEmail: form.email.trim(),
                firstname: form.firstname.trim(),
                lastname: form.lastname.trim()
            };
            PartnerService.createPartner(payload).then((rep) => {
                this.state.table.destroy();
                this.getListPartner();
                $('.sidebar-right').css({ width: 0 });
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.create.successfully')
                });
                this.setState({ form: { id: '', longName: '', shortName: '', email: '', ownerEmail: '', firstname: '', lastname: '', phoneNumber: '', country: '', address: '' } });
                this.closeModal.current?.click();
            }).catch((error) => {
                ToastTopEnd.fire({
                    icon: 'error',
                    title: error.response?.data?.message || TranslateHelper.getMessage('respond.message.failed')
                });
            });
        }
        this.forceUpdate();
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'longName': case 'shortName': case 'email': case 'phoneNumber': case 'country': case 'address': case 'ownerEmail': case 'firstname': case 'lastname':
                this.setState({
                    form: {
                        ...form,
                        [name]: event.target.value
                    },
                    isError: {
                        ...isError,
                        [name]: ''
                    }
                });
                break;
            default:
                break;
        }
    }

    handleMultiSelectChange (name, option) {
        const { form, isError } = this.state;
        switch (name) {
            case 'country':
                this.setState({
                    isError: {
                        ...isError,
                        [name]: ''
                    },
                    form: {
                        ...form,
                        [name]: option
                    }
                });
                break;
            default:
                break;
        }
    }

    handleValidationForm () {
        let validation = true;
        const format = regexName;
        const { form, isError } = this.state;
        const newError = { ...isError };

        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'longName':
                case 'shortName':
                case 'firstname':
                case 'lastname':
                    if (value === '') {
                        newError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                        break;
                    }
                    if (value.match(format) === null) {
                        newError[key] = TranslateHelper.getMessage('validation.error.containSpecialCharacter');
                        validation = false;
                    }
                    break;

                case 'email':
                    if (value === '') {
                        newError[key] = TranslateHelper.getMessage('validation.error.required');
                        validation = false;
                        break;
                    }
                    if (!validator.isEmail(value)) {
                        newError[key] = TranslateHelper.getMessage('validation.error.email');
                        validation = false;
                    }

                    break;
                default:
                    break;
            }
            return 0;
        });
        this.setState({ isError: newError });
        return validation;
    }

    handleSetDefaultEmployee (id) {
        const payload = {
            defaultEmployee: true
        };
        PartnerService.updatePartner(id, payload).then((res) => {
            if (res.status === 200) {
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.update.successfully')
                });
                this.getListPartner();
            }
        });
        this.forceUpdate();
    }

    handleDeletePartner (id, name) {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' "' + name + '" ?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await PartnerService.deletePartner(id).then(res => {
                    if (res.status === 200) {
                        ToastTopEnd.fire({
                            icon: 'success',
                            title: TranslateHelper.getMessage('respond.message.delete.successfully')
                        });
                        this.getListPartner();
                    }
                });
            }
        });
        this.forceUpdate();
    }

    render () {
        const { isError } = this.state;
        const { match } = this.props;
        const rowData = this.state.dataSet?.items?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.shortName}</td>
                    <td><span className="wordWrap">{item.email}</span></td>
                    <td>{item.status}</td>
                    <td>{item.createdBy ? item.createdBy.firstname + ' ' + item.createdBy.lastname : ''}</td>
                    <td><FormattedDate
                        value={item.createdAt}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                    /></td>
                    <td>
                        {item.defaultEmployee
                            ? (<i className="far fa-check-square"></i>)
                            : (<i onClick={() => this.handleSetDefaultEmployee(item._id) } className="far fa-square pointer"></i>)}
                    </td>
                    <td>
                        <div className="btn-group pointer">
                            <span data-toggle="dropdown" aria-expanded="true"><i className="nav-icon fas fa-ellipsis-h"></i></span>
                            <ul className="dropdown-menu dropdown-menu-right" >
                                <li>
                                    <Link to={match.url + '/' + item._id} style={{ textDecoration: 'none' }}><span className="dropdown-item">View</span></Link>
                                </li>
                                <li>
                                    <span style={ item.defaultEmployee ? { display: 'none' } : { display: 'block' }} onClick={() => this.handleDeletePartner(item._id, item.shortName)} className="dropdown-item"><FormattedMessage id="button.name.common.delete" /></span>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            );
        });
        return (
            <div className="container-fluid" style={{ overflowX: 'hidden' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <Button type="button" data-toggle="modal" data-target="#invite-dialog" onClick={() => { this.onClickCreate(); }} appearance='primary'><FormattedMessage id="button.name.common.invite"/></Button>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title"><FormattedMessage id="partner.table.title" /></h3>
                            </div>
                            <div className="card-body">
                                <div style={{ overflowX: 'auto' }}>
                                    { this.state.loadingStatus
                                        ? (<div>
                                            {this.state.dataSet?.items?.length
                                                ? (<table id="client-ids-list" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><FormattedMessage id="partner.table.column.name.shortName" /></th>
                                                                <th><FormattedMessage id="partner.table.column.name.email" /></th>
                                                                <th><FormattedMessage id="partner.table.column.name.state" /></th>
                                                                <th><FormattedMessage id="partner.table.column.name.approvedBy" /></th>
                                                                <th><FormattedMessage id="partner.table.column.name.createdAt" /></th>
                                                                <th><FormattedMessage id="partner.table.column.name.defaultEmloyee" /></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{rowData}</tbody>
                                                    </table>
                                                    )
                                                : (<div style={{ width: '100%', textAlign: 'center' }}><p><strong><FormattedMessage id="message.no.data.available" /></strong></p></div>)}
                                            </div>
                                            )
                                        : (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>)}
                                    {
                                        (this.state.dataSet?.totalPages > 1) &&
                                            <ReactPagination totalPages={this.state.dataSet?.totalPages} handlePageChange={(e, page) => this.getListPartner(page)} pageNumber={this.state.dataSet.page} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id='invite-dialog' tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{TranslateHelper.getMessage('partner.modals.create.title')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="label-400 form-check-label"><FormattedMessage id="partner.form.lable.longName"/><span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        placeholder={TranslateHelper.getMessage('placeholder.form.longCompanyName')}
                                        value={this.state.form.longName}
                                        type="text"
                                        name="longName"
                                        onChange={this.handleInputChange.bind(this, 'longName')}
                                        className={isError.longName.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                    />
                                    {isError.longName.length > 0 && (<span className="error invalid-feedback">{isError.longName}</span>)}
                                </div>
                                <div className="form-group">
                                    <label className="label-400 form-check-label"><FormattedMessage id="partner.form.lable.shortName"/><span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        placeholder={TranslateHelper.getMessage('placeholder.form.shortCompanyName')}
                                        value={this.state.form.shortName}
                                        type="text"
                                        name="shortName"
                                        onChange={this.handleInputChange.bind(this, 'shortName')}
                                        className={isError.shortName.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                    />
                                    {isError.shortName.length > 0 && (<span className="error invalid-feedback">{isError.shortName}</span>)}
                                </div>
                                <div className="form-group">
                                    <label className="label-400 form-check-label"><FormattedMessage id="partner.form.lable.email"/><span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        placeholder={TranslateHelper.getMessage('placeholder.form.email')}
                                        value={this.state.form.email}
                                        type="email"
                                        name="email"
                                        onChange={this.handleInputChange.bind(this, 'email')}
                                        className={isError.email.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                    />
                                    {isError.email.length > 0 && (<span className="error invalid-feedback">{isError.email}</span>)}
                                </div>
                                <div className="row">
                                    <div className="col-6 form-group">
                                        <label className="label-400 form-check-label text-nowrap"><FormattedMessage id="partner.form.lable.representativeFirstname"/><span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            placeholder={TranslateHelper.getMessage('placeholder.form.firstname')}
                                            value={this.state.form.firstname}
                                            type="text"
                                            name="firstname"
                                            onChange={this.handleInputChange.bind(this, 'firstname')}
                                            className={isError.firstname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                        />
                                        {isError.firstname.length > 0 && (<span className="error invalid-feedback">{isError.firstname}</span>)}
                                    </div>
                                    <div className="col-6 form-group">
                                        <label className="label-400 form-check-label text-nowrap"><FormattedMessage id="partner.form.lable.representativeLastname"/><span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            placeholder={TranslateHelper.getMessage('placeholder.form.lastname')}
                                            value={this.state.form.lastname}
                                            type="text"
                                            name="lastname"
                                            onChange={this.handleInputChange.bind(this, 'lastname')}
                                            className={isError.lastname.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                        />
                                        {isError.lastname.length > 0 && (<span className="error invalid-feedback">{isError.lastname}</span>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 form-group">
                                        <label className="label-400 form-check-label"><FormattedMessage id="partner.form.lable.phoneNumber"/></label>
                                        <input
                                            placeholder={TranslateHelper.getMessage('placeholder.form.phone')}
                                            value={this.state.form.phoneNumber}
                                            type="text"
                                            name="phoneNumber"
                                            onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                                            className={isError.phoneNumber.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                        />
                                        {isError.phoneNumber.length > 0 && (<span className="error invalid-feedback">{isError.phoneNumber}</span>)}
                                    </div>
                                    <div className="col-6 form-group">
                                        <label className="label-400 form-check-label" htmlFor="country"><FormattedMessage id="partner.form.lable.country"/></label>
                                        <Select
                                            name="country"
                                            placeholder={TranslateHelper.getMessage('placeholder.form.country')}
                                            options={countries}
                                            onChange={this.handleMultiSelectChange.bind(this, 'country')}
                                        />
                                        <div className={isError.country.length > 0 ? 'is-invalid' : ''}></div>
                                        {isError.country.length > 0 && (<span className="error invalid-feedback">{isError.country}</span>)}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="label-400 form-check-label" htmlFor="address"><FormattedMessage id="partner.form.lable.address"/></label>
                                    <textarea
                                        placeholder={TranslateHelper.getMessage('placeholder.form.address')}
                                        value={this.state.form.address}
                                        type="text"
                                        name="address"
                                        onChange={this.handleInputChange.bind(this, 'address')}
                                        className={isError.address.length > 0 ? 'is-invalid form-control' : 'form-control'}
                                    />
                                    {isError.address.length > 0 && (<span className="error invalid-feedback">{isError.address}</span>)}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button type="button" ref={this.closeModal} data-dismiss="modal"><FormattedMessage id="button.name.common.close"/></Button>
                                <Button type="button" appearance='primary' onClick={() => { this.handleCreatePartner(); }}><FormattedMessage id="button.name.common.invite"/></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {};
}

export default connect(mapStateToProps)(Partner);
