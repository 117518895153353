import Category from './settings-detail/Category';
import General from './settings-detail/General';
import IssueType from './settings-detail/IssueType';
import Milestone from './settings-detail/Milestone';
import Status from './settings-detail/Status';
import Member from './settings-detail/Member';
import { React } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tag from './settings-detail/Tag';

export default function Settings ({ projectId, tabIndex, tabs, onSelect }) {
    return (
        <div className='container-fluid'>
            <Tabs className="row" id='projects_setting' selectedIndex={tabIndex} onSelect={onSelect}>

                <TabList className='col-12 col-xl-2 tab-list-sp d-flex flex-column'>
                    {tabs.map((tab, index) => (
                        <Tab key={index}>{tab}</Tab>
                    ))}
                </TabList>
                <div className="col-xl-10 col-12">
                    <TabPanel><General projectId={projectId}/></TabPanel>
                    <TabPanel><Member projectId={projectId}/></TabPanel>
                    <TabPanel><IssueType projectId={projectId}/></TabPanel>
                    <TabPanel><Category projectId={projectId}/></TabPanel>
                    <TabPanel><Status projectId={projectId}/></TabPanel>
                    <TabPanel><Milestone projectId={projectId}/></TabPanel>
                    <TabPanel><Tag projectId={projectId}/></TabPanel>
                </div>
            </Tabs>
        </div>
    );
}
