import React, { Component } from 'react';
import Select from '@atlaskit/select';
import DatePicker from 'react-datepicker';
import { CustomInputDate } from '../../../common/CustomInputDatepicker';
import TranslateHelper from '../../../../helpers/TranslateHelper';
import { FormattedMessage } from 'react-intl';
import issueService from '../../services/issue.service';
import { ToastTopEnd } from '../../../../helpers/ToastTimer';
import AddFile from '../AddFile';
import serviceHelpers from '../../../../helpers/serviceHelpers';
import recruitmentService from '../../../hrm/Services/recruitment.service';
import projectListService from '../../services/project-list.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import Button, { LoadingButton } from '@atlaskit/button';
import { PROJECT_ROLE, ISSUE_PRIORITY, MILESTONE_NAME } from '../../../../constants/project.constant';
import { connect } from 'react-redux';
import Editor from '../../../EdittorToolbar';
import socket from '../../../common/Socket';

const selectError = {
    container: (provided, state) => ({
        ...provided,
        border: '1px solid red',
        borderRadius: '4px'
    })
};

const selectNormal = {
    container: (provided, state) => ({
        ...provided
    })
};
class NewIssue extends Component {
    constructor (props) {
        super(props);
        serviceHelpers();
        this.state = {
            selectedFile: '',
            resultCreate: '',
            uploading: 'false',
            editorState: '',
            isCreated: false,
            issueCodeValidate: '',
            isError: {
                type: '',
                parentTask: '',
                subject: '',
                startDate: '',
                dueDate: '',
                estimateHours: '',
                actualHours: '',
                assignTo: '',
                milestone: '',
                description: ''
            },
            form: {
                type: {},
                parentTask: '',
                subject: '',
                description: '',
                startDate: '',
                dueDate: '',
                status: {},
                estimateHours: '',
                actualHours: '',
                assignTo: {},
                milestone: '',
                category: '',
                priority: {
                    value: ISSUE_PRIORITY.MEDIUM,
                    label: 'Medium'
                }
            },
            type: [],
            status: [],
            priority: [
                {
                    value: ISSUE_PRIORITY.HIGH,
                    label: 'High'
                },
                {
                    value: ISSUE_PRIORITY.MEDIUM,
                    label: 'Medium'
                },
                {
                    value: ISSUE_PRIORITY.LOW,
                    label: 'Low'
                }
            ],
            assignTo: [],
            parentTask: [],
            milestone: [],
            category: [],
            tag: [],
            isManager: false,
            done: false,
            mentionToUser: [],
            defaultValue: ''
        };
        this.onEditorChange = this.onEditorChange.bind(this);
        this.handleCreateIssue = this.handleCreateIssue.bind(this);
        this.setMentionToUser = this.setMentionToUser.bind(this);
        this.myRef = React.createRef();
    }

    componentDidMount () {
        this.getIssueTypes(this.props.projectId);
        this.getStatus(this.props.projectId);
        this.getProjectMember(this.props.projectId);
        this.getMileStone(this.props.projectId);
        this.getCategory(this.props.projectId);
        this.getTag(this.props.projectId);
        this.getParentTask(this.props.projectId);
        this.getIssueCode(this.props.projectId);
        this.props.history.push({ pathname: this.props.match.url, state: { issueListIdx: 2 } });
    }

    onEditorChange (editorState) {
        const { isError } = this.state;
        const inputLength = editorState.replace(/(<([^>]+)>)/gi, '').length;
        if (inputLength < 2000) {
            this.setState({
                isError: {
                    ...isError,
                    description: ''
                }
            });
            this.setState({ editorState });
        } else {
            this.setState({
                isError: {
                    ...isError,
                    description: 'Description is too long'
                }
            });
        }
    }

    setMentionToUser (id) {
        this.setState({ mentionToUser: id });
    }

    getIssueCode (id) {
        issueService.getProjectIssue(id).then((res) => {
            if (res.status === 200) {
                const iCode = res.data.data.items.map(issue => issue.issueCode);
                this.setState({ issueCodeValidate: iCode });
                this.forceUpdate();
            }
        });
    }

    getStatus (id) {
        const { form } = this.state;
        issueService.getProjectStatus(id).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.map(status => ({ value: status._id, label: status.name }));
                form.status = list[0];
                this.setState({ status: list, form });
                this.forceUpdate();
            }
        });
    }

    getProjectMember (id) {
        const { form } = this.state;
        projectListService.getOne(this.props.projectId).then(res => {
            const list = res.members.map(member => ({ name: member.user?.firstname + ' ' + member.user?.lastname, value: member.user?._id, label: member.user?.firstname + ' ' + member.user?.lastname + ' (' + member.user?.username + ')', image: member.user?.picture, avatar: recruitmentService.buildFileURL(member.user?.picture) }));
            form.assignTo = list[0];
            this.setState({ assignTo: list, form });
            const user = res.members.filter(user => user.user?._id === this.props.user?.userId);

            if (user && (user[0]?.role === PROJECT_ROLE.MANAGER || user[0]?.role === PROJECT_ROLE.REPORTER)) {
                this.setState({ isManager: true });
            }
            this.setState({ done: true });
            this.forceUpdate();
        });
    }

    getMileStone (id) {
        const { form } = this.state;
        issueService.getProjectMileStone(id).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.map(milestone => ({ value: milestone?._id, label: milestone?.milestoneName }));
                const defaultMilestone = list.find(ele => ele.label === MILESTONE_NAME.BACKLOG);

                if (defaultMilestone) {
                    form.milestone = defaultMilestone;
                }
                this.setState({ milestone: list });
                this.forceUpdate();
            }
        });
    }

    getCategory (id) {
        issueService.getProjectCategory(id).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.map(category => ({ value: category._id, label: category.categoryName }));
                this.setState({ category: list });
                this.forceUpdate();
            }
        });
    }

    getTag (id) {
        issueService.getProjectTag(id).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.map(tag => ({ value: tag._id, label: tag.tagName }));
                this.setState({ tag: list });
                this.forceUpdate();
            }
        });
    }

    getIssueTypes (id) {
        const { form } = this.state;
        issueService.getProjectIssueTypes(id).then((res) => {
            if (res.status === 200) {
                const list = res.data.data.map(issue => ({ value: issue._id, label: issue.issueType }));
                form.type = list[0];
                this.setState({ type: list, form });
                this.forceUpdate();
            }
        });
    }

    getParentTask (id) {
        issueService.getProjectIssue(id, 10000).then((res) => {
            if (res.items) {
                const list = res.items.map(issue => ({ value: issue._id, label: `${issue.issueCode} - ${issue.subject}` }));
                this.setState({ parentTask: list });
                this.forceUpdate();
            }
        });
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'estimateHours': case 'actualHours': case 'subject':
                form[name] = event.target.value;
                isError[name] = '';
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    setSelectFIle (files) {
        this.setState({
            selectedFile: files
        });
    }

    setResultCreate (value) {
        this.setState({ resultCreate: value });
    }

    handleChangeSelect (name, value) {
        const { form, isError } = this.state;
        form[name] = value;
        isError[name] = '';
        this.setState({ isError, form });
    }

    handleChangeDatePicker (name, date) {
        const { form, isError } = this.state;
        form[name] = date;
        isError[name] = '';
        this.setState({ form });
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;

        const regexp = /^\d+(\.\d{1,1})?$/; // get 1 decimal after '.'
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'estimateHours': case 'actualHours':
                    if (value && !regexp.test(value)) {
                        isError[key] = TranslateHelper.getMessage(
                            'validation.error.decimal'
                        );
                        validation = false;
                    }
                    break;
                case 'subject':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage(
                            'validation.error.required'
                        );
                        validation = false;
                    } else if (value.length > 255) {
                        isError[key] = TranslateHelper.getMessage(
                            'validation.error.maxLength'
                        );
                        validation = false;
                    }
                    break;
                case 'type':
                    if (value === '') {
                        isError[key] = TranslateHelper.getMessage(
                            'validation.error.required'
                        );
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleCreateIssue () {
        const { form, editorState } = this.state;
        if (this.handleValidationForm()) {
            const datas = new FormData();
            for (const i of this.state.selectedFile) {
                datas.append('files', i);
            }
            let formatedTagsPayload = [];
            if (form?.tag?.length) {
                formatedTagsPayload = form.tag.map(obj => obj.value);
            }

            const payload = {
                assignTo: form.assignTo?.value,
                parentId: form.parentTask?.value,
                subject: form.subject.trim(),
                priority: form.priority?.value,
                status: form.status?.value,
                startDate: form.startDate,
                dueDate: form.dueDate,
                estimateTime: form.estimateHours.trim(),
                actualTime: form.actualHours.trim(),
                description: editorState,
                milestoneId: form.milestone?.value,
                issueTypeId: form.type?.value,
                categoryId: form.category?.value,
                tagIds: formatedTagsPayload
            };
            for (const key of Object.keys(payload)) {
                payload[key] && datas.append(key, payload[key]);
            }
            const config = {
                onUploadProgress: async (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted < 100 && percentCompleted) { this.setState({ uploading: 'true' }); } else { this.setState({ uploading: 'false' }); }
                }
            };
            axios.defaults.headers.common['x-access-token'] = 'Bearer ' + Cookies.get('accessToken');
            axios.post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION + '/manager/projects/' + this.props.projectId + '/issues', datas, config).then(() => {
                ToastTopEnd.fire({
                    icon: 'success',
                    title: TranslateHelper.getMessage('respond.message.create.successfully')
                });
                for (let i = 0; i < this.state.mentionToUser.length; i++) {
                    const receiverId = this.state.mentionToUser[i];
                    socket.emit('sendNotification', {
                        markAll: 1,
                        receiverId
                    });
                }
                this.setState({ defaultValue: undefined });
                this.setState(prevState => ({
                    ...prevState,
                    form: {
                        ...form,
                        subject: '',
                        estimateHours: '',
                        actualHours: '',
                        startDate: '',
                        dueDate: ''
                    },
                    resultCreate: true,
                    editorState: '',
                    mentionToUser: [],
                    defaultValue: ''
                }));
                this.props.getAllIssue(this.props.projectId);
                this.myRef.current.click();
            }).catch((error) => {
                if (error.response) {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: error.response.data.message
                    });
                } else {
                    ToastTopEnd.fire({
                        icon: 'error',
                        title: 'Error'
                    });
                }
            });
        }
        this.forceUpdate();
    }

    render () {
        const lang = localStorage.getItem('lang');
        const { isError } = this.state;

        return (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3 col-6" style={{ zIndex: '999' }}>
                  <Select
                    options={this.state?.type}
                    defaultValue={this.form?.type}
                    value={this.state.form?.type}
                    onChange={this.handleChangeSelect.bind(
                        this,
                        'type'
                    )}
                    placeholder='Issue type'
                  />
                </div>
                <div className="col-xl-3 col-6" style={{ zIndex: '999', minWidth: '270px' }}>
                  <Select
                    styles={{ width: 'fit-content' }}
                    isClearable
                    options={this.state.parentTask}
                    placeholder="Parent task"
                    selected={this.state.form.parentTask}
                    onChange={this.handleChangeSelect.bind(
                        this,
                        'parentTask'
                    )}
                    formatOptionLabel={item => (
                        <i className='text-truncate' style={{ maxWidth: '250px' }}>
                            {item.label}
                        </i>
                    )}
                  />
                </div>
              </div>
              <div className="input-group my-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="subject-addon">
                    <i className="fas fa-exclamation"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Subject"
                  value={this.state.form.subject}
                  aria-describedby="subject-addon"
                  onChange={this.handleInputChange.bind(
                      this,
                      'subject'
                  )}
                  className={
                    isError.subject.length > 0
                        ? 'is-invalid form-control'
                        : 'form-control'
                  }
                  autoFocus={true}
                />
                {isError.subject.length > 0 && (
                  <span className="error invalid-feedback">
                    {isError.subject}
                  </span>
                )}
              </div>
              {/* editor */}
                {
                  this.state.done === true
                      ? <Editor
                    toolbarId={'descriptionEditor'}
                    placeholder={'Write description here !'}
                    defaultValue={this.state.defaultValue}
                    height="300px"
                    mentionList={this.state.assignTo}
                    setMentionToUser={this.setMentionToUser}
                    onChange={this.onEditorChange}
                  />
                      : <div className='col-md-3 mb-3' style={{ height: '345px', width: '100%' }}><Skeleton width={'500px'} height={'10%'} /></div>
                }
                <span className='text-danger'>{this.state.isError?.description}</span>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className="row">
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.status" />
                    </div>
                    <div className="col-xl-8">
                      <Select
                        options={this.state?.status}
                        defaultValue={this.form?.status}
                        value={this.state.form?.status}
                        onChange={this.handleChangeSelect.bind(
                            this,
                            'status'
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.assignee" />
                    </div>
                    <div className="col-xl-8">
                      <Select
                          isClearable
                          formatOptionLabel={user => (
                            <div className='user-option d-flex'>
                                <img className='img-circle elevation-2' style={{ height: '1.5rem', width: '1.5rem' }} onError={ (e) => { e.target.src = '/images/default-avatar.png'; }} src={user.image ? (String(user.image).includes('uploads') ? recruitmentService.buildFileURL(user.image) : user.image) : '/images/default-avatar.png'} alt='user-image' />
                                <span className='ml-2 employee-name' style={{ width: '100%' }}>{user.label}</span>
                            </div>
                          )}
                          selected={this.state.form.assignTo}
                          options={this.state.assignTo}
                          onChange={this.handleChangeSelect.bind(
                              this,
                              'assignTo'
                          )}
                          defaultValue={this.form?.assignTo}
                          value={this.state.form?.assignTo}
                          styles={isError.assignTo.length > 0 ? selectError : selectNormal}
                      />
                    </div>
                    {isError.assignTo.length > 0 && (
                        <span className='error error-select left'>{isError.assignTo}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.priority" />
                    </div>
                    <div className="col-xl-8">
                      <Select
                        options={this.state.priority}
                        formatOptionLabel={item => (
                          <>
                              <i className={item.value === ISSUE_PRIORITY.HIGH ? 'fas fa-angle-double-up text-danger' : item.value === ISSUE_PRIORITY.MEDIUM ? 'fas fa-equals text-warning' : 'fas fa-angle-double-down text-primary'}></i> {item.label}
                          </>
                        )}
                        defaultValue={this.state.form.priority}
                        value={this.state.form.priority}
                        onChange={this.handleChangeSelect.bind(this, 'priority')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.mileStone" />
                    </div>
                    <div className="col-xl-8">
                      <Select isDisabled={!this.state.isManager} options={this.state.milestone} styles={isError.milestone.length > 0 ? selectError : selectNormal} value={this.state.form.milestone} onChange={this.handleChangeSelect.bind(this, 'milestone')} placeholder={this.state.isManager ? 'Select milestone' : 'Manager/Reporter'}/>
                    </div>
                      {isError.milestone.length > 0 && (
                        <span className='error error-select left'>{isError.milestone}</span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.category" />
                    </div>
                    <div className="col-xl-8">
                      <Select
                        options={this.state.category}
                        defaultValue={this.state.category[0]}
                        onChange={this.handleChangeSelect.bind(this, 'category')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.tag" />
                    </div>
                    <div className="col-xl-8">
                      <Select
                        isClearable={false}
                        isSearchable={false}
                        isMulti
                        options={this.state.tag}
                        defaultValue={this.state.tag[0]}
                        onChange={this.handleChangeSelect.bind(this, 'tag')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4 col-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.estimateHours" />
                    </div>
                    <div className="col-xl-8 col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="subject-addon">
                            <i className="fas fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder="Estimate hours"
                          value={this.state.form.estimateHours}
                          name="estimateHours"
                          onChange={this.handleInputChange.bind(
                              this,
                              'estimateHours'
                          )}
                          className={
                            isError.estimateHours.length > 0
                                ? 'is-invalid form-control'
                                : 'form-control'
                          }
                        />
                        {isError.estimateHours.length > 0 && (
                          <span className="error invalid-feedback">
                            {isError.estimateHours}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4 col-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.actualHours" />
                    </div>
                    <div className="col-xl-8 col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="subject-addon">
                            <i className="fas fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder="Actual hours"
                          name="actualHours"
                          value={this.state.form.actualHours}
                          onChange={this.handleInputChange.bind(
                              this,
                              'actualHours'
                          )}
                          className={
                            isError.actualHours.length > 0
                                ? 'is-invalid form-control'
                                : 'form-control'
                          }
                        />
                        {isError.actualHours.length > 0 && (
                          <span className="error invalid-feedback">
                            {isError.actualHours}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4 col-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.startDate" />
                    </div>
                    <div className="col-xl-8 col-6">
                        <DatePicker
                            title='Start date'
                            placeholderText='Start date'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={this.state.form.startDate}
                            onChange={this.handleChangeDatePicker.bind(this, 'startDate')}
                            locale={lang}
                            customInput={<CustomInputDate />}
                        />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 my-2">
                  <div className="row">
                    <div className="col-xl-4 col-4">
                      <FormattedMessage id="project.detail.issues.table.column.name.dueDate" />
                    </div>
                    <div className="col-xl-8 col-6">
                        <DatePicker
                            title='Due date'
                            placeholderText='Due date'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={this.state.form.dueDate}
                            onChange={this.handleChangeDatePicker.bind(this, 'dueDate')}
                            locale={lang}
                            customInput={<CustomInputDate />}
                        />
                        {isError.dueDate.length > 0 && (
                            <span className="error">{isError.dueDate}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 my-3">
                  <AddFile selectedFile = {this.state.selectedFile} setSelectFIle = {this.setSelectFIle.bind(this)} resultCreate = {this.state.resultCreate} setResultCreate = {this.setResultCreate.bind(this) } />
                </div>
                <div className="col-xl-12 ml-0">
                  <div className="row">
                    <div className="col-xl-3 col-6 ml-auto p-2">
                      <Button data-dismiss="modal" ref={this.myRef} className='w-100'><FormattedMessage id="button.name.common.cancel" /></Button>
                    </div>
                    <div className="col-xl-3 col-6 mr-auto p-2">
                      <LoadingButton appearance='primary' className='w-100' isloading={this.state.uploading} onClick={() => this.handleCreateIssue()}>Create</LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
    }
}

function mapStateToProps (state) {
    const { user, role } = state.auth;
    return {
        user,
        role
    };
}

export default withRouter(connect(mapStateToProps)(NewIssue));
