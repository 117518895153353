import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import Subscription from './components/Subscription';
import AddCardIcon from '@mui/icons-material/AddCard';
export const subscriptionLinks = [
    {
        name: 'menu.admin.name.subscription',
        classNames: 'nav-item',
        to: '/subscription',
        icon: (<AddCardIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.PARTNEROWNER],
        key: 2
    }
];

const routes = [
    {
        path: '/subscription',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.PARTNEROWNER_ACCESS], <Subscription/>);
        }
    }
];

export default routes;
