
import serviceHelpers from '../../common/helpers/serviceHelpers';
import axiosService from '../../common/services/axios.service';
const API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH_VERSION;

class SubscriptionService {
    constructor () {
        serviceHelpers();
    }

    getSubscription () {
        return axiosService.get(API_URL + '/subscriptions').then(response => response.data.data);
    }

    subscription (data) {
        return axiosService.post(API_URL + '/subscriptions', data).then(response => response.data.data);
    }

    getAllPackage () {
        return axiosService.get(API_URL + '/manager/packages').then(response => response.data.data);
    }
}

export default new SubscriptionService();
