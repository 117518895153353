import { useEffect, useState } from 'react';
import Avatar from '@atlaskit/avatar';
import Comment, { CommentAuthor, CommentTime, CommentAction, CommentEdited } from '@atlaskit/comment';
import moment from 'moment';
import recruitmentService from '../../hrm/Services/recruitment.service';
import { connect } from 'react-redux';
import Editor from '../../EdittorToolbar';
import Button, { LoadingButton } from '@atlaskit/button';
import issueService from '../services/issue.service';
import socket from '../../common/Socket';
import IssueCommentReaction from './IssueCommentReaction';
import Swal from 'sweetalert2';
import TranslateHelper from '../../../helpers/TranslateHelper';
import CopyText from '../../common/CopyText';
import PopoverHover from '../../common/PopoverHover';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

function IssueCommentBox ({ dataComment, issueAuthor, mentions, user, fetchDataComment, projectId, issueCode }) {
    const [isEdit, setEdit] = useState(false);
    const [mentionList, setMentionList] = useState([]);
    const [comment, setComment] = useState('');
    const [mentionToUser, setMentionToUser] = useState([]);
    const [commentDisplay, setCommentDisplay] = useState('');
    const [isloadingButton, setLoadingButton] = useState(false);
    const [isEdited, setEdited] = useState(false);
    const commentHash = window.location.hash.split('#comment-')[1];
    const urlComment = `${window.location.protocol}//${window.location.host}/projects/${projectId}/issues/${issueCode}#comment-${dataComment._id}`;
    const [userReacted, setUserReacted] = useState();

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        setMentionList(mentions);
        setComment(dataComment.comment);
        setCommentDisplay(dataComment.comment);
        if (commentHash) {
            const elm = document.getElementById(commentHash);
            elm.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mentions, dataComment]);

    const handleUpdateComment = (commentId) => {
        setLoadingButton(true);
        const payload = {
            comment: comment.trim(),
            mentionToUser: [...new Set(mentionToUser)]
        };
        issueService.updateComment(commentId, payload).then(res => {
            if (res.status === 200) {
                for (let i = 0; i < payload.mentionToUser.length; i++) {
                    const receiverId = mentionToUser[i];
                    socket.emit('sendNotification', {
                        markAll: 1,
                        receiverId
                    });
                }
                setEdit(false);
                setCommentDisplay(payload.comment);
                setLoadingButton(false);
                setEdited(true);
                setMentionToUser([]);
            }
        });
    };

    const handleDeleteComment = (id) => {
        Swal.fire({
            title: TranslateHelper.getMessage('alert.delete') + ' this comment?',
            text: TranslateHelper.getMessage('alert.detete.text'),
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: TranslateHelper.getMessage('alert.detete.button.confirm'),
            showCancelButton: true,
            cancelButtonText: TranslateHelper.getMessage('alert.detete.button.cancel'),
            buttonsStyling: false,
            customClass: {
                cancelButton: 'btn btn-outline-danger',
                confirmButton: 'btn btn-primary ml-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await issueService.deleteComment(id).then(res => {
                    fetchDataComment();
                }).catch(err => console.log(err));
            }
        });
    };

    const renderUserReacted = (users) => {
        return users?.map(user => {
            return (
                <Typography key={user._id} variant='body2'>{user.firstname} {user.lastname}</Typography>
            );
        });
    };

    return (
        <>
            <PopoverHover open={open} handlePopoverClose={handlePopoverClose} handlePopoverOpen={handlePopoverOpen} anchorEl={anchorEl}>
                <Box p={1}>
                    {renderUserReacted(userReacted)}
                </Box>
            </PopoverHover>
            <Comment
                id={dataComment._id}
                highlighted={commentHash === dataComment._id}
                avatar={<Avatar src={dataComment.createdBy?.picture ? (String(dataComment.createdBy?.picture).includes('uploads') ? recruitmentService.buildFileURL(dataComment.createdBy?.picture) : dataComment.createdBy?.picture) : '/images/default-avatar.png'} />}
                author={<CommentAuthor>{dataComment.createdBy?.firstname} {dataComment.createdBy?.lastname}</CommentAuthor>}
                type={issueAuthor === dataComment.createdBy?._id ? 'author' : null}
                time={<CommentTime>{moment(dataComment.createdAt).format('LLL')}</CommentTime>}
                edited={(dataComment.createdAt !== dataComment.updatedAt) || isEdited ? <CommentEdited>Edited</CommentEdited> : null}
                content={
                    isEdit
                        ? <Editor
                            toolbarId={'editCommentEditor'}
                            placeholder={'Write comment here !'}
                            onChange={setComment}
                            defaultValue={commentDisplay}
                            mentionList={mentionList?.filter(item => item.id !== user.userId)}
                            setMentionToUser={setMentionToUser}
                        />
                        : <div dangerouslySetInnerHTML={{ __html: issueService.markDownHTML(commentDisplay) }}></div>
                }
                actions={
                    isEdit
                        ? [<CommentAction key={dataComment._id}><LoadingButton isLoading={isloadingButton} appearance='primary' onClick={() => handleUpdateComment(dataComment._id)}>Save</LoadingButton></CommentAction>,
                        <CommentAction key={dataComment._id} onClick={() => setEdit(false)}><Button>Cancel</Button></CommentAction>]
                        : [
                            <CommentAction onClick={() => setEdit(true)} key={dataComment._id}>Edit</CommentAction>,
                            <div key={dataComment._id} style={{ gap: '10px', display: 'flex' }}><IssueCommentReaction handlePopoverOpen={handlePopoverOpen} handlePopoverClose={handlePopoverClose} anchorEl={open} dataComment={dataComment} setUserReacted={setUserReacted}/></div>,
                            <CommentAction key={dataComment._id} onClick={() => handleDeleteComment(dataComment._id)}>Delete</CommentAction>,
                            <CommentAction key={dataComment._id}><CopyText text={urlComment} title={'Copy URL this comment'} /></CommentAction>
                            ]
                }
            />
        </>
    );
}

function mapStateToProps (state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(IssueCommentBox);
