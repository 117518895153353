import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { CustomInputDate } from '../../../../common/CustomInputDatepicker';
import SandboxService from '../../../services/sandbox.service';
import TranslateHelper from '../../../../../helpers/TranslateHelper';
// import { ToastTopEnd } from '../../../../../helpers/ToastTimer';
import Button from '@atlaskit/button';

const toDay = new Date();
const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
const oneMonthAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
const threeMonthsAgo = new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000);

class UsagesSbDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingStatus: false,
            isError: {
                fromDate: '',
                toDate: '',
                dateSet: ''
            },
            form: {
                fromDate: toDay,
                toDate: toDay,
                dateSet: toDay
            },
            dataSet: null
        };
    }

    handleShowClick () {
        if (this.handleValidationForm()) {
            const { form } = this.state;
            const toDate = new Date(form.toDate).toLocaleDateString('en-CA');
            const fromDate = new Date(form.fromDate).toLocaleDateString('en-CA');
            const queryString = `?from=${fromDate}&to=${toDate}`;
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                    SandboxService.getSandboxUsage(this.props.sandboxId, queryString).then((res) => {
                        Swal.close();
                        this.setState({ dataSet: res.data.data, loadingStatus: true });
                    }).catch(() => {
                        let timerInterval;
                        Swal.fire({
                            title: TranslateHelper.getMessage('respond.message.operationsandbox.refresh'),
                            html: TranslateHelper.getMessage('respond.message.operationsandbox.refreshcountdown'),
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                const b = Swal.getHtmlContainer().querySelector('b');
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft();
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                                window.location.reload();
                            }
                        });
                    });
                }
            });
        }
        this.forceUpdate();
    }

    handleValidationForm () {
        let validation = true;
        const { form, isError } = this.state;
        Object.entries(form).map(([key, value], index) => {
            switch (key) {
                case 'fromDate':
                    if (value > form.toDate) {
                        isError[key] = TranslateHelper.getMessage('validation.error.invalided');
                        validation = false;
                    }
                    break;
                default:
                    break;
            }
            return 0;
        });
        return validation;
    }

    handleChangeDatePicker (name, date) {
        const { isError, form } = this.state;
        form[name] = date;
        isError[name] = '';
        this.setState({ form, isError });
    }

    handleInputChange (name, event) {
        const { form, isError } = this.state;
        switch (name) {
            case 'dateSet':
                form[name] = new Date(event.target.value.trim());
                form.fromDate = new Date(event.target.value.trim());
                form.toDate = toDay;
                break;
            default:
                break;
        }
        this.setState({ isError, form });
    }

    renderRowHistory (history) {
        return history.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}.</td>
                    <td><FormattedDate
                        value={item.from}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                    /></td>
                    <td>
                    <FormattedDate
                        value={item.to}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                    /></td>
                    <td>{Math.round(item.sandboxSeconds / 60, 2)}</td>
                    <td>{item.resourceProfile}</td>
                    <td>{item.exceedsTimeframe
                        ? (<div className="custom-control custom-checkbox">
                            <input className="custom-control-input custom-control-input-danger" type="checkbox" defaultChecked/>
                            <label className="custom-control-label" />
                        </div>)
                        : (<></>)}</td>
                </tr>
            );
        });
    }

    render () {
        const { isError } = this.state;
        const lang = localStorage.getItem('lang');
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}><h4><FormattedMessage id="sandbox.detail.tab.usages"/></h4></div>
                    <div className="row">
                        <div className="col-sm-3 row mt-2">
                            <label className="col-4 col-xl-5 col-form-label"><FormattedMessage id="sandbox.detail.usages.interval"/>:</label>
                            <select name="dateSet" className="col-8 col-xl-7 form-control" value={this.state.form.dateSet} onChange={this.handleInputChange.bind(this, 'dateSet')}>
                                <option value={toDay}>{TranslateHelper.getMessage('option.common.ontoday')}</option>
                                <option value={oneDayAgo}>{TranslateHelper.getMessage('option.common.onedayago')}</option>
                                <option value={oneWeekAgo}>{TranslateHelper.getMessage('option.common.oneweekago')}</option>
                                <option value={oneMonthAgo}>{TranslateHelper.getMessage('option.common.onemonthago')}</option>
                                <option value={threeMonthsAgo}>{TranslateHelper.getMessage('option.common.threemonthago')}</option>
                            </select>
                        </div>
                        <div className="col-sm-4 col-12 row mt-2">
                            <div className="col-xl-2 col-4">
                                <label className="col-form-label"><FormattedMessage id="sandbox.detail.usages.from"/>:</label>
                            </div>
                            <div className="col-xl-10 col-8">
                                <DatePicker
                                    selected={this.state.form.fromDate}
                                    onChange={this.handleChangeDatePicker.bind(this, 'fromDate')}
                                    popperPlacement="bottom"
                                    locale={lang}
                                    name="fromDate"
                                    customInput={<CustomInputDate />}
                                />
                                <div className={isError.fromDate.length > 0 ? 'is-invalid' : ''}></div>
                                {isError.fromDate.length > 0 && (<span className="error invalid-feedback">{isError.fromDate}</span>)}
                            </div>
                        </div>
                        <div className="col-sm-4 row mt-2">
                            <div className="col-xl-2 col-4">
                                <label className="col-form-label"><FormattedMessage id="sandbox.detail.usages.to"/>:</label>
                            </div>
                            <div className="col-xl-10 col-8">
                                <DatePicker
                                    selected={this.state.form.toDate}
                                    onChange={this.handleChangeDatePicker.bind(this, 'toDate')}
                                    popperPlacement="bottom"
                                    locale={lang}
                                    name="toDate"
                                    customInput={<CustomInputDate />}
                                />
                            </div>
                        </div>
                        <div className="col-sm-1 col-12 mt-2">
                            <Button type="button" appearance='primary' onClick={() => { this.handleShowClick(); }}><FormattedMessage id="button.name.common.show"/></Button>
                        </div>
                    </div>
                    {this.state.loadingStatus
                        ? (<div>
                            <div className="row">
                                <label className="col-6 col-xl-3 col-form-label"><FormattedMessage id="sandbox.detail.usages.sandboxminutes"/></label>
                                <div className="col-6 col-xl-9" style={{ paddingTop: '8px' }}>
                                    <span>{Math.round(this.state.dataSet.sandboxSeconds / 60, 2)}</span>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-6 col-xl-3 col-form-label"><FormattedMessage id="sandbox.detail.usages.minutesUp"/>:</label>
                                <div className="col-6 col-xl-9" style={{ paddingTop: '8px' }}>
                                    <span>{this.state.dataSet.minutesUp}</span>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-6 col-xl-3 col-form-label"><FormattedMessage id="sandbox.detail.usages.minutesDown"/>:</label>
                                <div className="col-6 col-xl-9" style={{ paddingTop: '8px' }}>
                                    <span>{this.state.dataSet.minutesDown}</span>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-scrollX'>
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: 40 }}>#</th>
                                                    <th><FormattedMessage id="sandbox.detail.usages.from"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.usages.to"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.usages.sandboxminutes"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.usages.resource"/></th>
                                                    <th><FormattedMessage id="sandbox.detail.usages.exceeds"/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderRowHistory(this.state.dataSet.history)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        : (<></>)}
                </div>
            </div>
        );
    }
}

export default UsagesSbDetail;
