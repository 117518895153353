import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { actions } from '../../../../constants/device.constant';

function DeviceHistoryModal ({ setIsModalOpen, open, detail, status }) {
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const historyForm = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        if (detail) {
            const action = actions.find((act) => act.value === detail.old_status);
            historyForm.reset({
                created_date: detail?.created_date ? moment(detail?.created_date).format('DD/MM/YYYY') : null,
                updated_date: detail?.updated_date ? moment(detail?.updated_date).format('DD/MM/YYYY') : 'Hiện tại',
                old_status: action.label || '',
                note: detail?.note || '',
                assign_person: detail?.assign_person?.username || '',
                update_by: detail?.update_by?.username || '',
                warranty_company: detail?.warranty_company || ''
            });
        }
    }, [open, detail]);
    return (
        <Dialog open={open} footer={null} onClose={handleCancel} maxWidth={700}>
            <DialogTitle>Chi tiết</DialogTitle>
            <DialogContent>
                <div className='pb-5'>
                    {status === 2 && <div className='form-row'>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Người sử dụng"
                                {...historyForm.register('assign_person', {})}
                            />
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Người cấp máy"
                                {...historyForm.register('update_by', {})}
                            />
                        </div>
                    </div>}
                    <div className='form-row'>
                        {status === 3 &&
                            <div className='form-device'>
                                <TextField
                                    className='custom-input'
                                    variant='outlined'
                                    label="Công ty bảo hành"
                                    {...historyForm.register('warranty_company', {})}
                                />
                            </div>}
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Từ ngày"
                                {...historyForm.register('created_date', {})}
                            />
                        </div>
                        <div className='form-device'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Đến ngày"
                                {...historyForm.register('updated_date', {})}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row ml-2'>
                        <div className='form-device mr-2'>
                            <TextField
                                className='custom-input'
                                variant='outlined'
                                label="Trạng thái cũ"
                                {...historyForm.register('old_status', {})}
                            />
                        </div>
                        <div className='form-device custom-textarea'>
                            <label className='custom-label'>Ghi chú</label>
                            <textarea
                                {...historyForm.register('note', {})}
                                rows="5"
                                cols="38"
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex w-100 justify-content-end align-items-center'>
                    <button
                            type="button"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            className="cancel-btn justify-content-center mr-2 px-4 py-2"
                        >
                            Hủy
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default DeviceHistoryModal;
