import React from 'react';
import { ROLES, PERMISSION_ACCESS } from '../../common/constants/user.constant';
import RouterMiddleware from '../../common/middlewares/RouterMiddleware';
import CronJobs from './components/CronJobs';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

export const cronJobsMenuLinks = [
    {
        name: 'menu.admin.name.cronjobs',
        classNames: 'nav-item',
        to: '/cronjobs',
        icon: (<WorkHistoryIcon fontSize='small' sx={{ color: 'white' }}/>),
        exact: true,
        role: [ROLES.MANAGER],
        key: 11
    }
];

const routes = [
    {
        path: '/cronjobs',
        exact: true,
        main: () => {
            return RouterMiddleware.routeToDisplay([PERMISSION_ACCESS.MANAGER_ACCESS], <CronJobs/>);
        }
    }
];

export default routes;
