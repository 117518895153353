import { ROLES } from './modules/common/constants/user.constant';

const Permission = {
    addSandbox: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.MANAGER],
    addClientId: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.MANAGER],
    updateAndDeleteClientId: [ROLES.PARTNEROWNER, ROLES.MANAGER],
    deleteSandbox: [ROLES.PARTNEROWNER, ROLES.PARTNERMANAGER, ROLES.MANAGER],
    editGrantPermission: [ROLES.PARTNEROWNER, ROLES.MANAGER],
    assignClientId: [ROLES.PARTNEROWNER, ROLES.MANAGER, ROLES.PARTNERMANAGER],
    justForManager: [ROLES.MANAGER]
};

export default Permission;
