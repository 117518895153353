import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer, breadcrumbReducer, hrmReducer, projectReducer } from './modules/common/index.reducer';
import { partnerOwnerClientIdReducer } from './modules/partnerowner/index.reducer';

const rootReducer = (history) => combineReducers({
    projectInfo: projectReducer,
    auth: authReducer,
    breadcrumb: breadcrumbReducer,
    memberClientId: partnerOwnerClientIdReducer,
    hrm: hrmReducer,
    router: connectRouter(history)
});

export default rootReducer;
